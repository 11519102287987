import { FilterValuesType } from 'types/OpportunityType'

const initialValues: FilterValuesType = {
  opportunityKey: '',
  name: '',
  contactName: '',
  orderNo: '',
  order: 'desc',
}

export default initialValues
