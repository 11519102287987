import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  .Loading {
    text-align: center;
  }
  .Content {
    width: 100%;
    max-width: 736px;
    display: flex;
    flex-direction: column;
    margin: 40px auto;
  }
  .Content .Row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d2d4db;
    padding-bottom: 4px;
    padding-top: 16px;
  }
  .Content .Row:first-of-type {
    padding-top: 0px;
  }
  .Content .Row > p {
    width: 35%;
    text-align: right;
  }
  .Content > a {
    align-self: flex-end;
    margin-top: 24px;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
`
