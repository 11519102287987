import { configureStore, Action } from '@reduxjs/toolkit'
import { ThunkAction } from 'redux-thunk'

import rootReducer, { RootState } from './rootReducer'

const store = configureStore({
  reducer: rootReducer,
})

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>

export type ContactsDispatch = typeof store.dispatch
export type ContactsThunk = ThunkAction<void, RootState, null, Action<string>>

export type TasksDispatch = typeof store.dispatch
export type TasksThunk = ThunkAction<void, RootState, null, Action<string>>

export type EventsDispatch = typeof store.dispatch
export type EventsThunk = ThunkAction<void, RootState, null, Action<string>>

export type NotificationsDispatch = typeof store.dispatch
export type NotificationsThunk = ThunkAction<void, RootState, null, Action<string>>

export type PlanningDispatch = typeof store.dispatch
export type PlanningThunk = ThunkAction<void, RootState, null, Action<string>>

export type ProfileDispatch = typeof store.dispatch
export type ProfileThunk = ThunkAction<void, RootState, null, Action<string>>

export type DashboardDispatch = typeof store.dispatch
export type DashboardThunk = ThunkAction<void, RootState, null, Action<string>>

export type AnnouncementsDispatch = typeof store.dispatch
export type AnnouncementsThunk = ThunkAction<void, RootState, null, Action<string>>

export type ConsentsDispatch = typeof store.dispatch
export type ConsentsThunk = ThunkAction<void, RootState, null, Action<string>>

export type OpportunitiesDispatch = typeof store.dispatch
export type OpportunitiesThunk = ThunkAction<void, RootState, null, Action<string>>

export type MotorInsuranceDispatch = typeof store.dispatch
export type MotorInsuranceThunk = ThunkAction<void, RootState, null, Action<string>>

export type CommissionsDispatch = typeof store.dispatch
export type CommissionsThunk = ThunkAction<void, RootState, null, Action<string>>

export type ActDispatch = typeof store.dispatch
export type ActThunk = ThunkAction<void, RootState, null, Action<string>>

export type TravelInsuranceDispatch = typeof store.dispatch
export type TravelInsuranceThunk = ThunkAction<void, RootState, null, Action<string>>

export default store
