import React, { FC, FormEvent, useEffect } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useFormikContext } from 'formik'
import subDays from 'date-fns/subDays'
import { validator } from 'pages/contacts/utils'
import Gender from 'components/Inputs/Gender'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import { PrefixComponent } from 'pages/contacts/components/ContactForm'
import { useDispatch, useSelector } from 'react-redux'
import { getOptions } from 'store/contactSlice'
import { RootState } from 'store/rootReducer'

type MoreDetailPropsType = {
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void
  values: {
    prefixName?: string
    firstName?: string
    lastName?: string
    gender?: string
    birthdate?: string
  }
  isSubmitting: boolean
}
const Topic = styled.div`
  margin-bottom: 4px;
`
const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const FieldGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  .datepicker {
    width: 360px !important;
  }
`
const DatePickerField = styled(DatePicker)`
  height: 48px;
  width: 360px;
`
const StyledGender = styled(Gender)`
  width: 250px;
  height: 48px;
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
const today = new Date()
const yesterday = subDays(today, 1)

const MoreDetailForm: FC<MoreDetailPropsType> = (props) => {
  const { values } = props
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const options = useSelector((state: RootState) => state.contacts.options)
  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])
  return (
    <Form onSubmit={props.handleSubmit}>
      <FieldRow style={{ borderTop: '1px solid var(--agt-secondary-color-1)', paddingTop: '16px' }}>
        <FieldGroup>
          <Topic>คำนำหน้าชื่อ</Topic>
          <PrefixComponent name="prefixName" options={options.prefixNameOptions} />
          <Field hidden name="prefixName" validate={validator('กรุณาระบุคำนำหน้าชื่อ')} />
          <ErrorMessage component={Error} name="prefixName" />
        </FieldGroup>
        <FieldGroup>
          <Topic>ชื่อ</Topic>
          <Field
            className="fieldInput"
            name="firstName"
            type="string"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุชื่อ')}
          />
          <ErrorMessage component={Error} name="firstName" />
        </FieldGroup>
        <FieldGroup>
          <Topic>นามสกุล</Topic>
          <Field
            className="fieldInput"
            name="lastName"
            type="string"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุนามสกุล')}
          />
          <ErrorMessage component={Error} name="lastName" />
        </FieldGroup>
      </FieldRow>
      <FieldRow>
        <FieldGroup>
          <Topic>เพศ</Topic>
          <StyledGender name="gender" />
          <Field hidden name="gender" validate={validator('กรุณาระบุเพศ')} />
          <ErrorMessage component={Error} name="gender" />
        </FieldGroup>
        <FieldGroup>
          <Topic>วันเกิด</Topic>
          <DatePickerField
            value={values.birthdate || ''}
            maxDate={yesterday}
            showIcon
            showSelector
            onChange={(value) => setFieldValue('birthdate', value)}
          />
          <Field
            hidden={true}
            name="birthdate"
            type="string"
            validate={validator('กรุณาระบุวันเกิด')}
          />
          <ErrorMessage component={Error} name="birthdate" />
        </FieldGroup>
      </FieldRow>
      <FieldRow style={{ justifyContent: 'flex-end' }}>
        <button
          type="submit"
          className="secondary"
          style={{ marginBottom: '24px' }}
          disabled={props.isSubmitting}
        >
          {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
        </button>
      </FieldRow>
    </Form>
  )
}
export default MoreDetailForm
