import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import Select, { ValueType } from 'react-select'

import MultiRangeSlider from 'components/MultiRangeSlider'
import { ProductType, SearchStateType } from 'types/MotorInsuranceType'

import InsuranceCard from './InsuranceCard'

const SearchProduct: FC<ProductType> = (props) => {
  const {
    data,
    options,
    setFilter = () => {},
    filter,
    location,
    setCompareData,
    compareData,
    setIsCompare,
  } = props
  const [sortByOption, setSortByOption] = useState<ValueType<OptionType>>({
    label: 'ราคาน้อย-มาก',
    value: 'priceAsc',
  })
  const [newData, setNewData] = useState<Array<SearchStateType>>(data)
  const [filterSlider, setFilterSlider] = useState({ min: 0, max: 0 })
  const [netAmountMin, setNetAmountMin] = useState<number>(0)
  const [netAmountMax, setNetAmountMax] = useState<number>(0)

  useEffect(() => {
    setNewData([...data])
  }, [data])

  useEffect(() => {
    const getNetAmountMin = Math.min(...newData.map((d) => parseFloat(d.netAmount)))
    const getNetAmountMax = Math.max(...newData.map((d) => parseFloat(d.netAmount)))
    if (getNetAmountMin <= getNetAmountMax) {
      setNetAmountMin(Math.floor(getNetAmountMin / 1000) * 1000)
      setNetAmountMax(Math.ceil(getNetAmountMax / 1000) * 1000)
    } else {
      setNetAmountMin(0)
      setNetAmountMax(0)
    }
  }, [newData])

  const sortHandle = (value) => {
    switch (value) {
      case 'priceAsc':
        setNewData([...newData].sort((a, b) => parseFloat(a.netAmount) - parseFloat(b.netAmount)))
        break
      case 'priceDesc':
        setNewData([...newData].sort((a, b) => parseFloat(b.netAmount) - parseFloat(a.netAmount)))
        break
      case 'coverAsc':
        setNewData([...newData].sort((a, b) => parseFloat(a.cover) - parseFloat(b.cover)))
        break
      case 'coverDesc':
        setNewData([...newData].sort((a, b) => parseFloat(b.cover) - parseFloat(a.cover)))
        break
      case 'garageTotalAsc':
        setNewData(
          [...newData].sort((a, b) => parseFloat(a.garageTotal) - parseFloat(b.garageTotal)),
        )
        break
    }
  }
  const filteredData = useMemo(
    () =>
      newData.filter(
        (d) =>
          parseFloat(d.netAmount) >= filterSlider.min &&
          parseFloat(d.netAmount) <= filterSlider.max,
      ),
    [filterSlider.min, filterSlider.max, newData],
  )

  return (
    <Container>
      <FilterContainer>
        <label className="customer_detail_label">
          ข้อมูลลูกค้า : อายุผู้ขับขี่ {options?.contact?.age} ปี
        </label>
        <label className="header_selection_label">เลือกดูจาก</label>
        <label className="select_label">ทุนประกัน</label>
        <Selector
          value={options?.coverAmountOptions.find(
            (select) => parseInt(select.value) === filter?.coverCurrent,
          )}
          onChange={(option: OptionType) => {
            const value = (option as OptionType).value
            setFilter({ ...filter, coverCurrent: value })
          }}
          options={options?.coverAmountOptions || []}
          placeholder="เลือกทุนประกัน"
        />
        <label className="select_label">บริษัทประกัน</label>
        <Selector
          value={options?.insuranceCompanyOptions.find(
            (select) => select.value === filter?.companyCode,
          )}
          onChange={(option: OptionType) => {
            const value = (option as OptionType).value
            setFilter({ ...filter, companyCode: value })
          }}
          options={options?.insuranceCompanyOptions || []}
        />
        <label className="header_slider_label">ช่วงราคา</label>

        <MultiRangeSlider
          min={netAmountMin}
          max={netAmountMax}
          onChange={({ min, max }) => {
            if (min !== filterSlider.min || max !== filterSlider.max) setFilterSlider({ min, max })
          }}
        />
      </FilterContainer>
      <ProductContainer>
        <div className="product_header">
          <div className="sort_wrapper">
            <label>เรียงตาม</label>
            <Selector
              value={sortByOption}
              onChange={(option: OptionType) => {
                setSortByOption(option)
                const value = (option as OptionType).value
                sortHandle(value)
              }}
              options={SortByOptions}
            />
          </div>
          <div>
            <label style={{ marginRight: '16px' }}>สูงสุด 3 รายการ</label>
            <button
              type="button"
              onClick={() => setIsCompare(true)}
              disabled={compareData.length < 1}
            >
              เปรียบเทียบ ({compareData.length})
            </button>
          </div>
        </div>
        <div className="product_body">
          <div className="top">ทั้งหมด {filteredData.length || 0} รายการ</div>

          {newData &&
            newData.length > 0 &&
            filteredData.map((d, index) => (
              <InsuranceCard
                key={index}
                data={d}
                location={location}
                setIsCompare={setIsCompare}
                compareData={compareData}
                setCompareData={setCompareData}
              />
            ))}
        </div>
      </ProductContainer>
    </Container>
  )
}

export default memo(SearchProduct)

type OptionType = {
  value: string | null
  label: string
}
const SortByOptions: Array<OptionType> = [
  { label: 'ราคาน้อย-มาก', value: 'priceAsc' },
  {
    label: 'ราคามาก-น้อย',
    value: 'priceDesc',
  },
  { label: 'ทุนน้อย-มาก', value: 'coverAsc' },
  { label: 'ทุนมาก-น้อย', value: 'coverDesc' },
  { label: 'จำนวนอู่น้อย-มาก', value: 'garageTotalAsc' },
]

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  font-size: 17px;
`
const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 256px;
  height: 100%;
  background-color: var(--agt-background-color-4);
  padding: 0 8px;

  .customer_detail_label {
    margin: 32px 0 24px;
  }
  .header_selection_label {
    color: var(--agt-primary-color-1);
    font-weight: 700;
    margin-bottom: 8px;
  }
  .select_label {
    font-size: 13px;
  }
  .header_slider_label {
    color: var(--agt-primary-color-1);
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 13px;
  }
  .search_filter {
    margin: 0 auto;
    margin-top: 100px;
  }
`
const Selector = styled(Select)`
  border-radius: 4px;
  width: 220px;
  align-self: center;

  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    height: 48px;
  }
  .css-26l3qy-menu {
    z-index: 99;
  }
`
const ProductContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 24px;

  .product_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .sort_wrapper {
      display: flex;
      align-items: center;

      label {
        margin-right: 16px;
      }
    }
  }

  .product_body {
    .top {
      background-color: var(--agt-primary-color-1);
      color: var(--agt-white-color);
      text-align: right;
      padding: 4px 16px;
      margin-bottom: 8px;
    }
  }
`
