import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import Avatar from 'components/Avatar'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .labelArrow.bottom {
    top: unset;
    left: 16px;
    bottom: -8px;
    border-radius: 0px 4px 0px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const ClosedCard = styled.div`
  max-width: 930px;
  width: calc(930 / 1120 * 100vw);
  background-color: var(--agt-white-color);
  margin-left: 96px;
  @media (max-width: 1024px) {
    margin-left: unset;
    width: 930px;
  }
  .card {
    padding: 12px 0 16px 0;
    display: flex;
    border-bottom: 2px solid var(--agt-background-color-2);
  }
  .card .order {
    width: 390px;
    display: flex;
    column-gap: 24px;
    border-right: 1px solid var(--agt-background-color-2);
    padding-right: 36px;
  }
  .card .order .status {
    width: 88px;
    height: 16px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    text-align: center;
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 20px;
  }
  .card .order .status.error {
    background-color: var(--agt-error-color);
    color: var(--agt-white-color) !important;
  }
  .card .order .date {
    color: var(--agt-secondary-text-color);
  }
  .card .order u {
    color: var(--agt-primary-color-1);
  }
  .card .order .topic {
    margin-bottom: 4px;
  }
  .card .order .orderId {
    display: flex;
    column-gap: 8px;
  }
  .card .contact {
    width: 324px;
    display: flex;
    column-gap: 24px;
    padding: 0 24px;
  }
  .card .contact .contactId {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .card .contact .contactId .name {
    color: var(--agt-primary-color-1);
  }
  .card .contact .contactId small {
    color: var(--agt-secondary-text-color);
  }
  .card .summary {
    padding-left: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    width: 200px;
    border-left: 1px solid var(--agt-background-color-2);
  }
  .card .summary .title {
    color: var(--agt-primary-color-1);
  }
  .card .summary .sales {
    display: flex;
    justify-content: space-between;
    color: var(--agt-secondary-color-2);
  }
`

const WebTourCompleted = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 6
  const layout = [
    {
      top: '-143px',
      right: '672px',
      width: '258px',
      arrow: 'bottom',
    },
    {
      top: '127px',
      right: '672px',
      width: '258px',
      arrow: 'top',
    },
    {
      top: '127px',
      right: '575px',
      width: '246px',
      arrow: 'top',
    },
    {
      top: '127px',
      right: '276px',
      width: '240px',
      arrow: 'top',
    },
    {
      top: '127px',
      right: '-40px',
      width: '270px',
      arrow: 'top',
    },
    {
      top: '234px',
      right: '662px',
      width: '270px',
      arrow: 'top',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [closedCardPosition, setClosedCardPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('opportunity__close-card', setClosedCardPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>ตัวอย่างรายการโอกาสในการขายที่ขายไม่สำเร็จ</small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>แสดงรายการโอกาสในการขายที่เสร็จสิ้นแล้ว</small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>ตัวอย่างรายการโอกาสในการขายที่ขายสำเร็จ</small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงรายการและสถานะคำสั่งซื้อ โดยคลิก
            <b />
            เพื่อดูรายละเอียดคำสั่งซื้อได้
          </small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>แสดงข้อมูลลูกค้าของโอกาสในการขายนี้</small>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงยอดเบี้ยของคำสั่งซื้อทั้งหมดที่ขายสำเร็จ
            <br />
            และไม่สำเร็จของโอกาสในการขายนี้
          </small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1 || step === 2 || step === 3 || step === 4 || step === 5) {
      return (
        <ClosedCard>
          <div className="card">
            <div className="order">
              <div>
                <div className="status">ขายสำเร็จ</div>
                <small className="date">
                  <FontAwesomeIcon icon={faClock} color="var(--agt-secondary-text-color)" />{' '}
                  15/03/65
                </small>
              </div>
              <div>
                <div>ลูกค้าใหม่จากการออกบูธ</div>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u>{' '}
                  <span className="success">บริษัทประกันอนุมัติ</span>
                </small>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u> <span className="error">พนักงานยกเลิก</span>
                </small>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u> <span className="error">พนักงานยกเลิก</span>
                </small>
              </div>
            </div>
            <div className="contact">
              <Avatar
                avatar="https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png"
                width="48px"
                height="48px"
                type="circle"
              />
              <div className="contactId">
                <div className="name">โรแลนด์ แบงส์ (โรดี้)</div>
                <small>087-111-1111</small>
                <small>roland.b@gmail.com</small>
              </div>
            </div>
            <div className="summary">
              <div className="title">สรุปการขาย</div>
              <small className="sales">
                <small>สำเร็จ</small>
                <small>฿ 11,152,000</small>
              </small>
              <small className="sales">
                <small>ไม่สำเร็จ</small>
                <small>฿ 142,000</small>
              </small>
            </div>
          </div>
        </ClosedCard>
      )
    } else if (step === 6) {
      return (
        <ClosedCard>
          <div className="card">
            <div className="order">
              <div>
                <div className="status">ขายสำเร็จ</div>
                <small className="date">
                  <FontAwesomeIcon icon={faClock} color="var(--agt-secondary-text-color)" />{' '}
                  15/03/65
                </small>
              </div>
              <div>
                <div>ลูกค้าใหม่จากการออกบูธ</div>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u>{' '}
                  <span className="success">บริษัทประกันอนุมัติ</span>
                </small>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u> <span className="error">พนักงานยกเลิก</span>
                </small>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u> <span className="error">พนักงานยกเลิก</span>
                </small>
              </div>
            </div>
            <div className="contact">
              <Avatar
                avatar="https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png"
                width="48px"
                height="48px"
                type="circle"
              />
              <div className="contactId">
                <div className="name">โรแลนด์ แบงส์ (โรดี้)</div>
                <small>087-111-1111</small>
                <small>roland.b@gmail.com</small>
              </div>
            </div>
            <div className="summary">
              <div className="title">สรุปการขาย</div>
              <small className="sales">
                <small>สำเร็จ</small>
                <small>฿ 11,152,000</small>
              </small>
              <small className="sales">
                <small>ไม่สำเร็จ</small>
                <small>฿ 142,000</small>
              </small>
            </div>
          </div>
          <div className="card">
            <div className="order">
              <div>
                <div className="status error">ขายไม่สำเร็จ</div>
                <small className="date">
                  <FontAwesomeIcon icon={faClock} color="var(--agt-secondary-text-color)" />{' '}
                  15/03/65
                </small>
              </div>
              <div>
                <div>เพื่อนแนะนำต่อมา</div>
                <small className="orderId">
                  <u>รหัสคำสั่งซื้อ #12345678</u> <span className="error">พนักงานยกเลิก</span>
                </small>
              </div>
            </div>
            <div className="contact">
              <Avatar
                avatar="https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png"
                width="48px"
                height="48px"
                type="circle"
              />
              <div className="contactId">
                <div className="name">ชนัญญา มานะเลิศ (ชมพู่)</div>
                <small>087-111-1111</small>
                <small>roland.b@gmail.com</small>
              </div>
            </div>
            <div className="summary">
              <div className="title">สรุปการขาย</div>
              <small className="sales">
                <small>สำเร็จ</small>
                <small>฿ 0</small>
              </small>
              <small className="sales">
                <small>ไม่สำเร็จ</small>
                <small>฿ 11,152,000</small>
              </small>
            </div>
          </div>
        </ClosedCard>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1 || step === 2 || step === 3 || step === 4 || step === 5 || step === 6) {
      return closedCardPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTourCompleted
