import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Api from 'api'
import isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import SetStateType from 'types/SetStateType'

const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
`
const Header = styled.h3`
  margin-bottom: 16px;
`
const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 40px 0;
`
const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-gap: 24px;
  padding-bottom: 24px;
  div.row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  div.row-flex {
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 10px;
    label {
      flex: 1;
    }
    div {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .button-show {
      border-bottom: none;
    }
    .license-code {
      border-bottom: 1px solid var(--agt-secondary-color-2);
    }
  }
`
const Label = styled.div`
  margin-bottom: 8px;
  font-weight: bold;
`
const Value = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--agt-secondary-color-2);
`
const Diagram = styled.div`
  display: flex;
  justify-content: center;
  .container {
    width: 77.8%;
    background: var(--agt-background-color-3);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    color: var(--agt-primary-color-1);
    font-weight: bold;
    margin: 24px 0px;
  }
  .body {
    width: 82.8%;
    padding: 34px 0px;
    /* padding-left: 20.68%; */
    padding-left: 27.37%;
    margin-bottom: 80px;
    background: #ffffff;
    div:nth-of-type(1) {
      margin-left: 0px;
    }
  }
`
const UplineTreeStyles = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  margin-left: 53px;
`
type UplineLeafProps = {
  expanded: boolean
}
type UplineCircleProps = {
  isMe: boolean
}
const UplineLeaf = styled.div<UplineLeafProps>`
  position: absolute;
  z-index: 1;
  left: -62px;
  top: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;

  background: var(--agt-primary-color-1);
  border-radius: 50%;

  cursor: pointer;
  transform: ${(props) => (props.expanded ? 'rotate(0)' : 'rotate(180deg)')};
  transition: transform 0.5s;
`
const UplineLeave = styled(UplineLeaf)`
  background: var(--agt-background-color-3);
`
const UplineName = styled.div`
  font-weight: bold;
  color: ${(props) => props.color};
  margin-bottom: 8px;
`
const UplineLevel = styled.div`
  color: ${(props) => props.color};
  margin-bottom: 24px;
`
const ChervonIcon = styled(FontAwesomeIcon)``

const UplineCircle = styled.div<UplineCircleProps>`
  position: absolute;
  z-index: 1;
  left: -52px;
  top: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: var(--agt-white-color);
  border: ${(props) =>
    props.isMe
      ? '3px solid var(--agt-primary-color-1)'
      : '3px solid var(--agt-background-color-3)'};
  /* border: 3px solid var(--agt-primary-color-1); */
  border-radius: 50%;
  cursor: pointer;
`

const getUplineTreePlan = async (
  setTqmUser: SetStateType<tqmUserType>,
  setLoading: SetStateType<boolean>,
) => {
  try {
    setLoading(true)
    const response = await Api.get('profiles/upline_tree_plan')
    setTqmUser(response.data.data.tqmUser)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    setLoading(false)
  }
}

type childsType = {
  colorCode: string
  levelDisplay: string
  name: string
  positionLevel: number
}
type uplineTreePlanType = {
  childs: uplineTreePlanType
  value: childsType
}
type optionsType = {
  beginDate: string
  canEdit: boolean
  mkPlanName: string
  positionLevelName: string
  uplineTreePlan: uplineTreePlanType
}
type nameThInfoType = {
  firstName: string
  lastName: string
  prefixName: string
}
type tqmUserType = {
  nameThInfo: nameThInfoType
  salesCommissionPlans: Array<optionsType>
}
function changeFormatDate(date: string) {
  let formatDate: string = ''
  let arrayDate: Array<string> = date.split('-')
  formatDate = arrayDate[2] + '/' + arrayDate[1] + '/' + (parseInt(arrayDate[0]) + 543).toString()
  return formatDate
}

const UplineTreePlan: FC = (props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [tqmUser, setTqmUser] = useState<tqmUserType>({
    nameThInfo: { firstName: '', lastName: '', prefixName: '' },
    salesCommissionPlans: [],
  })

  useEffect(() => {
    if (currentAgent) {
      getUplineTreePlan(setTqmUser, setLoading)
      // let type = { agentType: '' }
      // if (currentAgent.agentType === 'tqm_staff') {
      //   type.agentType = 'staff_code'
      //   getUplineTreePlan(setTqmUser, setLoading, type)
      // } else {
      //   type.agentType = 'affiliate'
      //   getUplineTreePlan(setTqmUser, setLoading, type)
      // }
    }
  }, [currentAgent])

  function UplineTree({ root }) {
    const [expanded, setExpanded] = useState(true)

    const nestedLeaf = (root.childs || []).map((leaf) => {
      return <UplineTree root={leaf} />
    })
    const isMe =
      root.value.name.split(' ')[0] === tqmUser.nameThInfo.firstName.trim() &&
      root.value.name.split(' ')[1] === tqmUser.nameThInfo.lastName.trim()
    const color = isMe ? 'var(--agt-primary-color-1)' : 'var(--agt-primary-text-color)'
    const step = !isEmpty(root.childs) ? (
      isMe ? (
        <UplineLeaf expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <ChervonIcon icon={faChevronDown} color="white" />
        </UplineLeaf>
      ) : (
        <UplineLeave expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <ChervonIcon icon={faChevronDown} color="white" />
        </UplineLeave>
      )
    ) : (
      <UplineCircle isMe={isMe} />
    )

    return (
      <UplineTreeStyles>
        <>
          {step}
          <UplineName color={color}>{root.value.name}</UplineName>
          <UplineLevel color={color}>{root.value.levelDisplay}</UplineLevel>
        </>
        {expanded && nestedLeaf}
      </UplineTreeStyles>
    )
  }

  return (
    <Container>
      <Header>แผนผังสายงาน</Header>
      <hr />
      <Body>
        {loading ? (
          'Loading...'
        ) : (
          <InfoContainer>
            <div className="row">
              <Label>ชื่อแผนการตลาด</Label>
              <Value>
                {tqmUser.salesCommissionPlans.length > 0
                  ? tqmUser.salesCommissionPlans[0].mkPlanName
                  : '-'}
              </Value>
            </div>
            <div className="row">
              <Label>วันที่มีผลบังคับใช้</Label>
              <Value>
                {tqmUser.salesCommissionPlans.length > 0
                  ? changeFormatDate(tqmUser.salesCommissionPlans[0].beginDate.split('T')[0])
                  : '-'}
              </Value>
            </div>
            <div className="row">
              <Label>ตำแหน่ง</Label>
              <Value>
                {tqmUser.salesCommissionPlans.length > 0
                  ? tqmUser.salesCommissionPlans[0].positionLevelName
                  : '-'}
              </Value>
            </div>
            <div className="row">
              <Label>อัปไลน์</Label>
              {tqmUser.salesCommissionPlans.length > 0 &&
              tqmUser.salesCommissionPlans[0].uplineTreePlan !== null ? (
                <Value>
                  {tqmUser.salesCommissionPlans.length > 0 &&
                  tqmUser.salesCommissionPlans[0].uplineTreePlan.value.name.split(' ')[0] !==
                    tqmUser.nameThInfo.firstName &&
                  tqmUser.salesCommissionPlans[0].uplineTreePlan.value.name.split(' ')[1] !==
                    tqmUser.nameThInfo.lastName
                    ? tqmUser.salesCommissionPlans[0].uplineTreePlan.value.name
                    : '-'}
                </Value>
              ) : (
                <Value>-</Value>
              )}
            </div>
          </InfoContainer>
        )}
      </Body>
      {tqmUser.salesCommissionPlans.length > 0 && (
        <Diagram>
          <div className="container">
            <h4 className="header">แผนผังสายงาน</h4>
            <div className="body">
              {tqmUser.salesCommissionPlans[0].uplineTreePlan !== null && (
                <UplineTree root={tqmUser.salesCommissionPlans[0].uplineTreePlan} />
              )}
            </div>
          </div>
        </Diagram>
      )}
    </Container>
  )
}

export default UplineTreePlan
