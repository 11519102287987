import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import Avatar from 'components/Avatar'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const GroupIcon = styled.div`
  display: flex;
  column-gap: 16px;
  .iconBtn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  button:hover svg path {
    fill: var(--agt-dark-sky-color);
  }
`
const IconButton = styled(Icon)`
  margin-top: 4px;
`
const CustomButton = styled.button`
  &.offerBtn {
    width: 173px;
  }
  &.insurancesBtn {
    width: 173px;
  }
  &.planningBtn {
    width: 181px;
  }
  &.createAppointmentBtn {
    width: 160px;
  }
`
const TaskSidebar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 288px;
  max-width: 288px;
  background: var(--agt-background-color-2);
  .header {
    width: 100%;
    height: 76px;
    background: var(--agt-tqm-pantone-1);
    display: flex;
    flex-direction: row;
  }
  .header .label {
    width: 73.6%;
    color: white;
    text-align: center;
    height: 100%;
    border-right: 2px solid #003d73;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .header .icon {
    width: 26.4%;
    height: 100%;
    padding-top: 20px;
    padding-left: 18px;
  }
  .todo {
    width: 100%;
    margin-bottom: 40px;
  }
  .todo .nothingText {
    width: 100%;
    margin-top: 40px;
    text-align: center;
    color: var(--agt-secondary-text-color);
  }
  .createBtn {
    width: fit-content;
    padding: 0px 40px;
    margin-bottom: 40px;
  }
`
// eslint-disable-next-line
const TextCustom = styled.div`
  margin-left: 92px;
  background-color: var(--agt-white-color);
`
const Personal = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #a0a8bc;
  width: 100%;
  > div:first-of-type {
    display: flex;
    column-gap: 20px;
  }
  .detail {
    display: flex;
    flex-direction: column;
  }
  .detail .name {
    display: flex;
    column-gap: 16px;
    align-items: baseline;
    padding-bottom: 4px;
    border-bottom: 1px solid #a0a8bc;
    margin-bottom: 11px;
  }
  .detail .name b {
    font-size: 24px;
  }
  .detail .name span {
    color: #a0a8bc;
  }
  .detail .content {
    display: flex;
    column-gap: 60px;
    margin-bottom: 24px;
    @media (max-width: 1024px) {
      column-gap: 40px;
    }
  }
  .detail .content .right {
  }
  .detail .content .left span,
  .detail .content .right span {
    color: #a0a8bc;
  }
  .detail .content .linechamp {
    max-width: 350px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @media (max-width: 1024px) {
      max-width: 200px;
    }
  }
  .image .icons {
    padding-top: 20px;
  }
  .interest {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .interest .property {
    display: flex;
    column-gap: 16px;
  }
  .interest u {
    color: var(--agt-primary-color-1);
  }
`
const NavbarTable = styled.div`
  display: flex;
  > div {
    width: 384px;
    height: 48px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    @media (max-width: 1024px) {
      width: 300px;
    }
  }
  .select {
    font-weight: bold;
    color: var(--agt-primary-color-1);
    background: var(--agt-background-color-3);
    opacity: 1;
  }
`

const WebTour = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 13
  const layout = [
    {
      top: 'calc(100% - 142px)',
      right: 'calc(100% - 185px)',
      width: '242px',
      arrow: 'topRight',
    },
    {
      top: 'calc(100% - 142px)',
      right: 'calc(100% - 185px)',
      width: '250px',
      arrow: 'topRight',
    },
    {
      top: 'calc(100% - 142px)',
      right: 'calc(100% - 185px)',
      width: '278px',
      arrow: 'topRight',
    },
    {
      top: '56px',
      right: '24px',
      width: '225px',
      arrow: 'topRight',
    },
    {
      top: '0px',
      right: '189px',
      width: '260px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: '189px',
      width: '275px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: '197px',
      width: '310px',
      arrow: 'left',
    },
    {
      top: 'calc(100% + 20px)',
      right: 'calc(100% - 208px)',
      width: '206px',
      arrow: 'topRight',
    },
    {
      top: '0px',
      right: '176px',
      width: '200px',
      arrow: 'left',
    },
    {
      top: 'calc(100% + 16px)',
      right: 'calc(100% - 620px)',
      width: '236px',
      arrow: 'topRight',
    },
    {
      top: '0px',
      right: '304px',
      width: '206px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: '92px',
      width: '213px',
      arrow: 'left',
    },
    {
      top: '182px',
      right: '255px',
      width: '225px',
      arrow: 'left',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [statusPosition, setStatusPosition] = useState({
    top: '0',
    left: '0',
  })
  const [editIconPosition, setEditIconPosition] = useState({
    top: '0',
    left: '0',
  })
  const [offerSalePosition, setOfferSalePosition] = useState({ top: '0', left: '0' })
  const [insurancesPosition, setInsurancesPosition] = useState({ top: '0', left: '0' })
  const [planningPosition, setPlanningPosition] = useState({ top: '0', left: '0' })
  const [tableNavbarPosition, setTableNavbarPosition] = useState({ top: '0', left: '0' })
  const [createAppointmentPosition, setCreateAppointmentPosition] = useState({
    top: '0',
    left: '0',
  })
  const [taskSidebarPosition, setTaskSidebarPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('contact-id__personal', setStatusPosition)
      getPositionById('contact-id__edit-icon', setEditIconPosition)
      getPositionById('contact-id__offer-sale', setOfferSalePosition)
      getPositionById('contact-id__insurances', setInsurancesPosition)
      getPositionById('contact-id__planning', setPlanningPosition)
      getPositionById('contact-id__table', setTableNavbarPosition)
      getPositionById('contact-id__create-appointment', setCreateAppointmentPosition)
      getPositionById('contact-id__task-sidebar', setTaskSidebarPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    // document.addEventListener('scroll', getPosition)
    return () => {
      clearInterval()
      // document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>คลิกเพื่อสร้างงานที่ต้องทำให้กับลูกค้า</small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>
            เมื่อลูกค้าได้ให้ความยินยอมเรียบร้อยแล้ว
            <br />
            สถานะลูกค้าจะกลายเป็น รอเสนองานใหม่
          </small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>สามารถนำข้อมูลของลูกค้าไปใช้งานได้ทันที</small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>
            หากมีการปรับปรุงนโยบายฉบับใหม่ สถานะลูกค้า
            <br />
            จะกลับมาเป็นรอยินยอม
          </small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>คลิกเพื่อแก้ไขหรือลบข้อมูลลูกค้าคนนี้</small>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>
            คลิกเสนอขายทันทีเพื่อสร้างโอกาสในการขาย
            <br />
            ให้กับลูกค้า
          </small>
        </div>
      )
    } else if (step === 6) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>คลิกสรุปกรมธรรม์เพื่อจัดการพอร์ตให้กับลูกค้า</small>
        </div>
      )
    } else if (step === 7) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>
            คลิกวางแผนประกันเพื่อวางแผนการเงินและการประกัน
            <br />
            ให้ลูกค้า
          </small>
        </div>
      )
    } else if (step === 8) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>ดูรายละเอียดนัดหมายของลูกค้า</small>
        </div>
      )
    } else if (step === 9) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>คลิกสร้างนัดหมายสำหรับลูกค้า</small>
        </div>
      )
    } else if (step === 10) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>ดูรายละเอียดโอกาสในการขายของลูกค้า</small>
        </div>
      )
    } else if (step === 11) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>ดูและจัดการงานของลูกค้า</small>
        </div>
      )
    } else if (step === 12) {
      return (
        <div className="top">
          <b>จัดการลูกค้า</b>
          <small>คลิกเพื่อดูรายการงานที่ทำเสร็จแล้ว</small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1 || step === 2 || step === 3) {
      return (
        <Personal>
          <div>
            <div className="detail">
              <div className="name">
                <b>โรแลนด์ แบงส์ (โรดี้)</b>
                <span>(36 ปี) - เจ้าหน้าที่สืบสวน</span>
              </div>
              <div className="content">
                <div className="left">
                  <div>
                    <Icon name="phone_square_icon" />
                    {`  087-111-1111`}
                  </div>
                  <div>สถานะลูกค้า รอเสนองานใหม่</div>
                  <div>
                    หมายเหตุ <span>โทรไม่ติด</span>
                  </div>
                  <div>
                    โน้ต <span style={{ paddingLeft: 36 }}>หัวหน้าให้รายชื่อ...</span>
                  </div>
                </div>
                <div className="right">
                  <div>ที่อยู่สำหรับติดต่อ</div>
                  <span className="linechamp">
                    9 ซอยลาดปลาเค้า 14 แขวงจรเข้บัว เขตลาดพร้าว กรุงเทพมหานคร 10230
                  </span>
                </div>
              </div>
            </div>
            <div className="image">
              <Avatar
                avatar={'https://api-dev.agent-tools.tqld.xyz/images/avatar_contact.png'}
                width="100px"
                height="100px"
                type="circle"
              />
              <GroupIcon className="icons">
                <button type="button" className="iconBtn secondary">
                  <IconButton name="pencil_icon" color="var(--agt-primary-color-1)" />
                </button>
                <button type="button" className="iconBtn secondary">
                  <IconButton name="bin" color="var(--agt-primary-color-1)" />
                </button>
              </GroupIcon>
            </div>
          </div>
          <div className="interest">
            <div className="property">
              <button className="secondary" type="button">
                สินทรัยพ์ส่วนตัว
              </button>
              <button className="secondary" type="button">
                สิ่งที่ลูกค้าสนใจ
              </button>
            </div>
            <u>ดูข้อมูลแบบละเอียด</u>
          </div>
        </Personal>
      )
    } else if (step === 4) {
      return (
        <GroupIcon>
          <button type="button" className="iconBtn secondary">
            <IconButton name="pencil_icon" color="var(--agt-primary-color-1)" />
          </button>
          <button type="button" className="iconBtn secondary">
            <IconButton name="bin" color="var(--agt-primary-color-1)" />
          </button>
        </GroupIcon>
      )
    } else if (step === 5) {
      return (
        <CustomButton type="button" className="offerBtn">
          เสนอขายทันที
        </CustomButton>
      )
    } else if (step === 6) {
      return (
        <CustomButton type="button" className="insurancesBtn">
          สรุปกรมธรรม์
        </CustomButton>
      )
    } else if (step === 7) {
      return (
        <CustomButton type="button" className="planningBtn">
          วางแผนประกัน
        </CustomButton>
      )
    } else if (step === 8) {
      return (
        <NavbarTable>
          <div className="select">นัดหมาย</div>
          <div>โอกาสในการขาย</div>
        </NavbarTable>
      )
    } else if (step === 9) {
      return (
        <CustomButton type="button" className="createAppointmentBtn">
          + สร้างนัดหมาย
        </CustomButton>
      )
    } else if (step === 10) {
      return (
        <NavbarTable>
          <div>นัดหมาย</div>
          <div className="select">โอกาสในการขาย</div>
        </NavbarTable>
      )
    } else if (step === 11 || step === 12 || step === 13) {
      return (
        <TaskSidebar>
          <div className="header">
            <h4 className="label">งานที่ต้องทำ</h4>
            <div className="icon">
              <Icon name="history" />
            </div>
          </div>
          <div className="todo">
            <div className="nothingText">ยังไม่มีประวัติงานที่ต้องทำ</div>
          </div>
          <button type="button" className="createBtn">
            สร้างงานที่ต้องทำ
          </button>
        </TaskSidebar>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1) {
      return statusPosition
    } else if (step === 2) {
      return statusPosition
    } else if (step === 3) {
      return statusPosition
    } else if (step === 4) {
      return editIconPosition
    } else if (step === 5) {
      return offerSalePosition
    } else if (step === 6) {
      return insurancesPosition
    } else if (step === 7) {
      return planningPosition
    } else if (step === 8) {
      return tableNavbarPosition
    } else if (step === 9) {
      return createAppointmentPosition
    } else if (step === 10) {
      return tableNavbarPosition
    } else if (step === 11) {
      return taskSidebarPosition
    } else if (step === 12) {
      return taskSidebarPosition
    } else if (step === 13) {
      return taskSidebarPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTour
