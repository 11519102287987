import { initialValuesType } from './NewAct.type'
import { addDays, addYears } from 'date-fns'

const today = new Date()
const tomorrow = addDays(today, 1)

export const initialValues: initialValuesType = {
  email: '',
  phoneNumber: '',
  type: 'droplead',
  insureLevel: '',
  vehicleDetails: {
    id: null,
    name: '',
    year: '',
    vehicleBrand: '',
    vehicleModel: '',
    vehicleSubModel: '',
    redLicencePlate: false,
    vehicleProvince: '',
    vehicleLicense: '',
    vehicleChassis: '',
    // vehicleProvinceEdit: true,
    // vehicleLicenseEdit: true,
    // vehicleChassisEdit: true,
    expiryDate: '',
    cc: '',
    carUsage: '',
    makeCode: '',
    familyCode: '',
    vehicleKey: '',
  },
  order: {
    orderId: '',
    premium: 0,
    netPremium: '',
    prbDetails: {
      inspectionLocation: 'policy',
      vehicleInspectionDate: new Date(),
      insuranceRenewalNotification: false,
      policyForm: null,
      prbCarType: '',
      prbCode: '',
      oldCompanyName: '',
      policyAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      policyDeliveryAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      receiptAddress: {
        aaaaaa: 'ระบุเอง',
        housingNo: '',
        villageNo: '',
        building: '',
        lane: '',
        road: '',
        subDistrict: '',
        district: '',
        province: '',
        postcode: '',
      },
      startPolicyAt: tomorrow,
      endPolicyAt: addYears(new Date(tomorrow), 1),
      productDetails: {
        prbCode: '',
        prbName: '',
        amount: '',
        vat: '',
        duty: '',
        netAmount: '',
        vehicleType: '',
        seat: '',
        prbCarType: '',
        companyId: '',
        companyName: '',
        companyCode: '',
      },
    },
  },
  prefixName: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  idCard: '',
  companyCode: '',
}
