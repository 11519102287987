import React, { FC, useCallback, useState } from 'react'
import { Link } from 'routes'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import Api from 'api'
import { dateFormat, phoneNumberFormat } from 'utils/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { isObject } from 'lodash'
import {
  Container,
  ValuesName,
  InfoContainer,
  LinkTab,
  DetailLink,
  EditDisplay,
  CustomButton,
  IconButton,
  DeleteModal,
  CheckboxContainer,
  CheckboxWrapper,
  NameDisplay,
  Description,
  TimeDisplay,
  ClockStyle,
  DueDateDisplay,
  StarDisplay,
  ValuesDisplay,
  AvatarContainer,
  IconStyle,
} from './Task.styles'
import { TaskType } from './Task.type'

const Task: FC<TaskType> = (props) => {
  const { currentAgreement = null, isConsented = false } = props
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [deleteIsClicked, setStateDeleteClicked] = useState<boolean>(false)

  const toggleStatus = useCallback(async () => {
    if (props.id === undefined) {
      alert('Undefind task id')
    }
    try {
      await Api.patch(`/tasks/${props.id}/toggle_status`, {})
    } catch (err) {
      if (err && err.response) {
        alert(err.response)
      }
      throw err
    }
  }, [props.id])

  const deleteTask = useCallback(async () => {
    if (props.id === undefined) {
      alert('Undefind task id')
    } else {
      try {
        await Api.delete(`/tasks/${props.id}`, {})
        window.location.reload(false)
      } catch (err) {
        if (err && err.response) {
          alert(err.response)
        }
        throw err
      }
    }
  }, [props.id])

  return (
    <Container>
      <Modal
        isOpen={deleteIsClicked}
        onConfirm={() => deleteTask()}
        onCancel={() => setStateDeleteClicked(false)}
      >
        <DeleteModal>
          <p>ยืนยันการลบงานที่ต้องทำ</p>
          <p>{props?.name}</p>
        </DeleteModal>
      </Modal>
      <ValuesName>
        <CheckboxContainer>
          {currentAgent?.statusTh === 'ระงับ' ? (
            <div className="disabledCheckbox" />
          ) : (
            <CheckboxWrapper>
              <div className="round">
                <input type="checkbox" id={`checkoux_task_${props.id}`} onChange={toggleStatus} />
                <label htmlFor={`checkoux_task_${props.id}`}></label>
              </div>
            </CheckboxWrapper>
          )}
        </CheckboxContainer>
        <NameDisplay>
          <LinkTab to={`/tasks/${props.id}`} style={{ color: 'var(--agt-primary-text-color)' }}>
            <div className="name">{props.name}</div>
          </LinkTab>
          {props.description ? <Description>{props.description}</Description> : <br />}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <TimeDisplay>
              <ClockStyle
                name="calendarDatePicker"
                color={props.status === 'expire' ? 'red' : 'var(--agt-secondary-color-2)'}
              />
              <DueDateDisplay
                color={props.status === 'expire' ? 'red' : 'var(--agt-secondary-color-2)'}
              >
                {dateFormat(props.dueDate)}
              </DueDateDisplay>
            </TimeDisplay>
            <StarDisplay>
              {[...Array(props.priorityLevel)].map((_, i) => (
                <IconStyle key={i} name="star" color={'var(--agt-primary-text-color)'} />
              ))}
            </StarDisplay>
          </div>
        </NameDisplay>
      </ValuesName>
      <ValuesDisplay>
        <div style={{ display: 'flex' }}>
          <AvatarContainer>
            {props.contactId && (
              <Avatar type="circle" width="48px" height="48px" avatar={props.contactAvatar} />
            )}
          </AvatarContainer>
          <InfoContainer>
            <LinkTab to={`/contacts/${props.contactId}`}>
              <div className="contactName">{props.contactName}</div>
            </LinkTab>
            <small className="">{props.phoneNumber && phoneNumberFormat(props.phoneNumber)}</small>
            <small className="">{props.occupation && props.occupation}</small>
          </InfoContainer>
        </div>
        <div>
          {props.status === 'complete' ? (
            <DetailLink to={`/tasks/${props.id}`}>
              <div style={{ paddingTop: '20px' }}>รายละเอียด</div>
            </DetailLink>
          ) : (
            <EditDisplay>
              <Link
                to={{
                  pathname: `/tasks/${props.id}`,
                  state: {
                    edit: true,
                  },
                }}
                className={currentAgent?.statusTh === 'ระงับ' ? 'disabledLink' : ''}
              >
                <CustomButton
                  type="button"
                  className="secondary"
                  style={
                    isObject(currentAgreement) && isConsented === false
                      ? { display: 'none' }
                      : { right: '98px' }
                  }
                  disabled={currentAgent?.statusTh === 'ระงับ'}
                >
                  <IconButton name="pencil_icon" />
                </CustomButton>
              </Link>
              <CustomButton
                type="button"
                className={
                  currentAgent?.statusTh === 'ระงับ' ? 'disabledLink secondary' : 'secondary'
                }
                onClick={() => setStateDeleteClicked(true)}
                disabled={currentAgent?.statusTh === 'ระงับ'}
              >
                <IconButton name="bin" />
              </CustomButton>
            </EditDisplay>
          )}
        </div>
      </ValuesDisplay>
    </Container>
  )
}

export default Task
