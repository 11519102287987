import styled from '@emotion/styled'
import { Link } from 'routes'

import Dropdown from 'components/Dropdown'

import { UserProps } from 'types/AppType'
import Icon from 'components/Icon'

export type HeaderProps = {
  currentAgent?: UserProps | null
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
`

export const ContainerRight = styled.div`
  display: flex;
`

export const CustomDropdown = styled(Dropdown)`
  .agt-dropdown__button {
    width: fit-content;
    height: fit-content;
  }
  .agt-dropdown__content-wrapper {
    right: 0;
    width: 224px;
  }
  .main {
    width: 100%;
    height: fit-content;
    background-color: #ccdceb;
  }
  .column {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }
  .column p {
    font-size: 13px;
    line-height: 20px;
  }
  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .row p {
    font-size: 13px;
    line-height: 20px;
  }
  .column .row .position {
    display: flex;
    flex-direction: row;
  }
  .column .row .position .agentTypeTh {
    display: flex;
    flex-direction: column;
  }
  .column .row .codeNumber {
    font-size: 13px;
    line-height: 20px;
    color: #00529a;
  }
`

export const ArrowTop = styled.div`
  position: absolute;
  background-color: #ffff;
  border-radius: 4px 0px 0px 0px;
  transform: rotate(45deg);
  width: 16.97px;
  height: 16.97px;
  right: 32px;
  z-index: -16;
  top: -3px;
  border: 1px solid #a0a8bc;
  transform: rotate(45deg);
  border-bottom: 0px;
  border-right: 0px;
`

export const UserContainer = styled.div`
  .anonymous {
    display: flex;
  }
  .anonymous .login {
    padding-right: 8px;
    border-right: 1px solid var(--agt-primary-color-1);
  }
  .anonymous .register {
    display: flex;
    align-items: center;
    padding-left: 8px;
    span {
      padding-right: 15px;
    }
    &:hover {
      svg {
        path {
          stroke: var(--agt-dark-sky-color);
        }
      }
    }
  }
`

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
`

export const UserName = styled.div`
  margin-left: 14px;
`

export const LinkTabs = styled(Link)`
  color: var(--agt-primary-text-color);
`

export const Line = styled.div`
  border-top: 1px solid white;
  width: 21px;
  position: absolute;
  right: 30px;
  top: 5px;
`

export const Image = styled.img`
  width: 308px;
  margin-bottom: -8px;
  object-fit: cover;
  opacity: 1;
`

export const CustomIcon = styled(Icon)`
  width: 11px;
  height: 18px;
  svg {
    path {
      width: 11px;
      height: 18px;
    }
  }
`
