import React, { FC, useState, useCallback, useEffect } from 'react'
import styled from '@emotion/styled'

import { HeaderContainer, ArrowContainer, ArrowLeft, ArrowRight } from './DateSelector'
import { THIS_YEAR, getYearOptions } from './DatePicker.utils'
import { YearSelectorType } from 'types/DatePickerType'

const Container = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 0 auto 1.5rem;
  padding-top: 1rem;
`
const YearContainer = styled.div`
  > div:last-of-type {
    margin-bottom: 0;
  }
`
const Year = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 42px;
  margin: 0 auto 14px;
  border-radius: 20px;
  cursor: pointer;

  &.selected {
    background-color: var(--agt-primary-color-1);
    color: white;
  }
`

const YearSelector: FC<YearSelectorType> = ({ value = THIS_YEAR, onChange = () => {} }) => {
  const [selectedYear, setYear] = useState<number>(value)
  const [runningYear, setRunningYear] = useState<number>(value)
  const [yearOptions, setOptions] = useState<Array<number> | undefined>([])

  const decreaseYear = useCallback(() => {
    setRunningYear(runningYear - 5)
  }, [runningYear, setRunningYear])

  const increaseYear = useCallback(() => {
    setRunningYear(runningYear + 5)
  }, [runningYear, setRunningYear])

  useEffect(() => {
    setOptions(getYearOptions(runningYear))
  }, [runningYear, setOptions])

  const _handleSelectYear = useCallback(
    (index: number) => {
      setYear(index)
      onChange(index)
    },
    [setYear, onChange],
  )

  return (
    <Container>
      <HeaderContainer>
        <ArrowContainer onClick={decreaseYear}>
          <ArrowLeft name="vector_left" />
        </ArrowContainer>
        <div>
          {(yearOptions?.[0] || new Date().getFullYear()) + 543} -{' '}
          {(yearOptions?.[4] || new Date().getFullYear()) + 543}
        </div>
        <ArrowContainer onClick={increaseYear}>
          <ArrowRight name="vector_left" />
        </ArrowContainer>
      </HeaderContainer>
      <YearContainer>
        {yearOptions?.map((year: number, index: number) => (
          <Year
            key={index}
            className={year === selectedYear ? 'selected' : ''}
            onClick={() => _handleSelectYear(year)}
          >
            {year + 543}
          </Year>
        ))}
      </YearContainer>
    </Container>
  )
}

export default YearSelector
