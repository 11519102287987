import React, { FC, useState } from 'react'
import Avatar from 'components/Avatar'
import { currency } from 'utils/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import Prb from 'assets/images/Prb.png'
import { DetailType } from './InsuranceDetail.type'
import { Container, CustomIcon } from './InsuranceDetail.style'

const InsuranceDetail: FC<DetailType> = (props) => {
  const { data } = props
  const [isToggle, setIsToggle] = useState({
    details: true,
    vehicle: true,
    outsider: true,
    car: true,
  })
  return (
    <Container>
      <Avatar width="120px" height="120px" type="circle" avatar={data?.companyLogoUrl || Prb} />
      <label className="insurance_title">{data.productAbb || ''}</label>
      <label className="price_text">฿ {currency(data?.netAmount, 2, '') || ''}</label>
      <label className="insurance_age">
        คุ้มครองบุคคล
        <br />
        ที่มีอายุ ระหว่าง {data?.minAge ? parseInt(data?.minAge) : 0} ถึง{' '}
        {data?.maxAge ? parseInt(data?.maxAge) : 0} ปี
      </label>
      <label className="insurance_company">{data?.companyName || 'กรุงเทพประกันภัย'}</label>
      <div
        className="insurance_topic"
        onClick={() => setIsToggle({ ...isToggle, details: !isToggle.details })}
      >
        ความคุ้มครองค่ารักษาพยาบาล
        <CustomIcon icon={isToggle.details ? faChevronUp : faChevronDown} />
      </div>
      {isToggle.details && (
        <table>
          <tr>
            <th>
              การเสียชีวิต การสูญเสียอวัยวะ สายตา หรือ ทุพพลภาพ ถาวรสิ้นเชิง เนื่องจากอุบัติเหตุ
            </th>
            <td>{currency(data.coverDetail?.travelA01, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ค่ารักษาพยาบาลระหว่างการเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA02, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ค่ารักษาพยาบาลต่อเนื่องหลังจาก กลับถึงประเทศไทย</th>
            <td>{currency(data.coverDetail?.travelA03, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การรับการรักษาพยาบาลในประเทศไทย</th>
            <td>
              {data.coverDetail?.travelA53 === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : '-'}
            </td>
          </tr>
          <tr>
            <th>การเคลื่อนย้าย เพื่อการรักษาพยาบาลฉุกเฉิน</th>
            <td>{currency(data.coverDetail?.travelA04, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>เงินชดเชยรายวันระหว่างรักษาตัวใน โรงพยาบาล ในฐานะผู้ป่วยใน</th>
            <td>{currency(data.coverDetail?.travelA13, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ค่าศัลยกรรมอันเนื่องมาจากอุบัติเหตุ</th>
            <td>{currency(data.coverDetail?.travelA42, 0, '') || '-'}</td>
          </tr>
        </table>
      )}
      <div
        className="insurance_topic"
        onClick={() => setIsToggle({ ...isToggle, vehicle: !isToggle.vehicle })}
      >
        ความคุ้มครองสัมภาระและทรัพย์สิน
        <CustomIcon icon={isToggle.vehicle ? faChevronUp : faChevronDown} />
      </div>
      {isToggle.vehicle && (
        <table>
          <tr>
            <th>
              การสูญหายหรือเสียหายของกระเป๋าเดินทาง และ / หรือ ทรัพย์สินส่วนตัวภายใน กระเป๋าเดินทาง
            </th>
            <td>{currency(data.coverDetail?.travelA09, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การล่าช้าของกระเป๋าเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA10, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การสูญหายหรือเสียหายของเงินสดและ เช็คเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA14, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การสูญหายหรือเสียหายของเอกสาร ที่เกี่ยวข้องกับการเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA15, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ความสูญเสียหรือความเสียหาย ของคอมพิวเตอร์โน้ตบุ๊ค</th>
            <td>{currency(data.coverDetail?.travelA19, 0, '') || '-'}</td>
          </tr>
        </table>
      )}
      <div
        className="insurance_topic"
        onClick={() => setIsToggle({ ...isToggle, outsider: !isToggle.outsider })}
      >
        ความคุ้มครองในการเดินทาง
        <CustomIcon icon={isToggle.outsider ? faChevronUp : faChevronDown} />
      </div>
      {isToggle.outsider && (
        <table>
          <tr>
            <th>การยกเลิกการเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA06, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ความล่าช้าในการเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA08, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การพลาดการต่อเครื่องบิน</th>
            <td>{currency(data.coverDetail?.travelA16, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การจี้เครื่องบิน</th>
            <td>{currency(data.coverDetail?.travelA20, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การลดจำนวนวันเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA07, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การล่าช้าของระบบขนส่งมวลชน</th>
            <td>{currency(data.coverDetail?.travelA55, 0, '') || '-'}</td>
          </tr>
        </table>
      )}
      <div
        className="insurance_topic"
        onClick={() => setIsToggle({ ...isToggle, car: !isToggle.car })}
      >
        ความคุ้มครองเพิ่มเติม
        <CustomIcon icon={isToggle.car ? faChevronUp : faChevronDown} />
      </div>
      {isToggle.car && (
        <table>
          <tr>
            <th>ค่าใช้จ่ายในการส่งศพ อัฐิกลับประเทศ</th>
            <td>{currency(data.coverDetail?.travelA05, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ความรับผิดชอบกฎหมายต่อบุคคลภายนอก</th>
            <td>{currency(data.coverDetail?.travelA11, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ชดเชยค่าโทรศัพท์ในกรณีฉุกเฉิน</th>
            <td>{currency(data.coverDetail?.travelA24, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การเดินทางเพื่อเยี่ยมผู้ป่วยที่โรงพยาบาล</th>
            <td>{currency(data.coverDetail?.travelA12, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>รางวัลพิเศษสำหรับ โฮล-อิน-วัน</th>
            <td>{currency(data.coverDetail?.travelA17, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ความเสียหายส่วนแรกสำหรับรถเช่า</th>
            <td>{currency(data.coverDetail?.travelA18, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การไปเยี่ยมผู้เอาประกันภัย</th>
            <td>{currency(data.coverDetail?.travelA21, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การส่งผู้เยาว์เพื่อเดินทางกลับประเทศ</th>
            <td>{currency(data.coverDetail?.travelA22, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ผลประโยชน์การขยายระยะเวลาโดยอัตโนมัติ</th>
            <td>{currency(data.coverDetail?.travelA23, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ผลประโยชน์ความรับผิดต่อบัตรเครดิต</th>
            <td>{currency(data.coverDetail?.travelA25, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>ผลประโยชน์การสูญเสียหรือความเสียหาย ของทรัพย์สินภายในบ้าน</th>
            <td>{currency(data.coverDetail?.travelA26, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การบริการให้ความช่วยเหลือในการเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA31, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>
              โฮล-อิน-วัน / การสูญหายหรือความเสียหาย ของอุปกรณ์กอล์ฟ / การชดเชยค่า
              ธรรมเนียมสนามกอล์ฟ
            </th>
            <td>{currency(data.coverDetail?.travelA43, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การค้นหา การช่วยชีวิต และการกู้ภัย</th>
            <td>{currency(data.coverDetail?.travelA54, 0, '') || '-'}</td>
          </tr>
          <tr>
            <th>การสูญหายของหนังสือเดินทาง</th>
            <td>{currency(data.coverDetail?.travelA56, 0, '') || '-'}</td>
          </tr>
        </table>
      )}
    </Container>
  )
}

export default InsuranceDetail
