import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Setting from './Setting'
import ResetPasswordPage from './ResetPassword'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/setting" component={Setting} exact />
    <Route path="/setting/reset_password" component={ResetPasswordPage} />
  </Switch>
)

export default Pages
