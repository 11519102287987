import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-regular-svg-icons'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { clearAnnouncements, getAnnouncements } from 'store/announcementsSlice'
import { announcementType, initialStateType } from 'types/AnnouncementsType'

import newsImage from 'assets/images/news_banner_landing.jpg'
import Loader from 'components/Loader'

//#region STYLED
const Container = styled.div`
  width: 100%;
`
const Header = styled.h3`
  color: var(--agt-primary-color-1);
  padding: 32px 45px;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 2rem;
`
const CardContainer = styled.div`
  border: 1px solid var(--agt-disable-color-1);
  border-radius: 5px;
  overflow: hidden;
  :hover {
    cursor: pointer;
  }
`
const CardImg = styled.img`
  width: 100%;
  height: 150px;
`
const CardHeader = styled.p`
  margin: 10px;
  height: 45px;
`
const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px;

  font-size: 12px;
  color: var(--agt-disable-color-1);
`
const CustomIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`

//#endregion

const Announcements: FC<initialStateType> = (props) => {
  const history = useHistory()
  const today = moment(new Date()).format('DD/MM/YYYY')
  const [page, setPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const announcements = useSelector((state: RootState) => state.announcements.announcements)
  const paging = useSelector((state: RootState) => state.announcements.paging)
  const [moreData, setMoreData] = useState<Array<announcementType>>([])

  useEffect(() => {
    setIsLoading(true)
    dispatch(getAnnouncements(page, () => setIsLoading(false)))
  }, [dispatch, page])

  useEffect(() => {
    if (announcements) {
      setMoreData(moreData.concat(announcements))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements])

  useEffect(() => {
    return () => {
      dispatch(clearAnnouncements())
    }
  }, [dispatch])

  return (
    <Container>
      <Header>ข่าวสาร/ประกาศ</Header>
      <InfiniteScroll
        dataLength={moreData.length}
        next={() => (paging.totalPages > page ? setPage(page + 1) : null)}
        hasMore={true}
        loader={isLoading && <Loader />}
      >
        <Grid>
          {moreData.length > 0 &&
            moreData.map((val) => {
              return (
                <CardContainer
                  key={val?.id}
                  onClick={() => history.push(`/announcements/${val?.id}`)}
                >
                  <CardImg alt="mockup article banner" src={val.bannerImagePath || newsImage} />
                  <CardHeader>{val?.name}</CardHeader>
                  <CardFooter>
                    <span>
                      {moment(val.updatedAt).format('DD/MM/YYYY') === today
                        ? 'วันนี้'
                        : moment(val.updatedAt).format('DD/MM/YYYY')}
                    </span>
                    <span>
                      <CustomIcon icon={faEye} color="#ACB1BF" />
                      {val.numberOfRead} ครั้ง
                    </span>
                  </CardFooter>{' '}
                </CardContainer>
              )
            })}
        </Grid>
      </InfiniteScroll>
    </Container>
  )
}

export default Announcements
