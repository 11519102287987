import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import Avatar from 'components/Avatar'
import Api from 'api'
import Icon from 'components/Icon'
import { UserProps } from 'types/AppType'

type TaskRendererType = {
  header: string
  type: string
  data: TaskType[]
  forceExpand?: number
  forPortal?: boolean
  setObj: Function
  index: number
  currentAgent: UserProps | null
}
type TaskType = {
  id: number
  name: string
  dueDate: string | Date
  priorityLevel: number
  contactName: string
  contactId: number
  contactAvatar: string
  phoneNumber?: string
  email?: string
  description?: string
}
type TaskHeaderType = {
  forPortal?: boolean
  header?: string
}
const TaskContainer = styled.div`
  border-bottom: 1px solid white;
`
const TaskHeader = styled.div<TaskHeaderType>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  color: ${(props) => (props.forPortal ? 'var(--agt-tqm-pantone-1)' : '')};
  font-weight: ${(props) => (props.forPortal ? 'bold' : '')};
  /* background-color: ${(props) =>
    props.header === 'สัปดาห์กำลังมาถึง' ? '#ccdcec' : 'var(--agt-background-color-1)'}; */
  background-color: var(--agt-background-color-1);
  cursor: pointer;
  a {
    text-decoration: none;
  }
`
const More = styled(TaskHeader)`
  justify-content: flex-end;
`
const IconStyle = styled(Icon)<TaskHeaderType>`
  width: 17px;
  height: 17px;
  margin-bottom: 10px;
  svg {
    path {
      stroke: ${(props) => (props.forPortal ? 'var(--agt-tqm-pantone-1)' : '')};
    }
  }
`
const StarStyle = styled(Icon)`
  svg {
    path {
      width: 14px;
      height: 14px;
    }
  }
`
const Detail = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: white;
  > li:last-of-type {
    border-bottom: none;
  }
`
const DetailRow = styled.li`
  display: flex;
  flex-direction: row;
  padding: 15px 12px;
  border-bottom: 1px solid var(--agt-background-color-2);
`
const ValuesName = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  /* min-width: 256px; */
  padding-right: 15px;
  border-right: 1px solid var(--agt-background-color-2);
`
const CheckboxContainer = styled.div`
  height: 100%;
  margin-right: 12px;
  .disabledCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-color-1);
    background-color: var(--agt-disable-color-1);
    margin-top: 6px;
    margin-left: 12px;
    cursor: not-allowed;
  }
`
const CheckboxWrapper = styled.div`
  padding-top: 6px;
  padding-left: 6px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  :hover {
    background: var(--agt-background-color-1);
  }
  .round {
    position: relative;
  }
  .round label {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  .round label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 9px;
    height: 4px;
    opacity: 0;
    border: 2px solid var(--agt-primary-text-color);
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
  .round input[type='checkbox'] {
    visibility: hidden;
  }
  .round input[type='checkbox']:checked + label {
    background-color: white;
    border-color: var(--agt-primary-text-color);
  }
  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`
const ValuesDisplay = styled.div`
  display: flex;
  width: 50%;
  padding-left: 16px;
`
const AvatarContainer = styled.div`
  width: 48px;
  margin-right: 16px;
`
const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 180px;
  small {
    color: var(--agt-secondary-text-color);
    margin-bottom: 4px;
  }
  small:last-child {
    margin-bottom: 0px;
  }
  .one-lines {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 1120px) {
    min-width: 120px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`
const TopicContainer = styled.div`
  width: 100%;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  .description {
    color: var(--agt-secondary-text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ellipse.two-lines {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
  @media only screen and (max-width: 1120px) {
    min-width: 130px;
    svg {
      width: 14px;
      height: 14px;
    }
  }
`
const TopicDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const Clock = styled(Icon)`
  margin-right: 5px;
  svg {
    width: 14px;
    height: 14px;
  }
`
const LinkTab = styled(Link)`
  /* align-items: center; */
  text-decoration: none;
  color: var(--agt-primary-color-1);
`
const toggleStatus = async (id: number, callback: () => void) => {
  if (id === undefined) {
    alert('Undefind task id')
  }
  try {
    await Api.patch(`/tasks/${id}/toggle_status`, {})
    callback()
  } catch (err) {
    if (err && err.response) {
      alert(err.response)
    }
    throw err
  }
}

const Task: FC<TaskRendererType> = (props) => {
  const {
    header,
    type,
    data,
    forceExpand,
    forPortal = false,
    setObj = () => {},
    index,
    currentAgent,
  } = props

  const [tasks, setTasks] = useState<TaskType[]>(data)
  const toggleExpand = () => {
    setObj()
  }
  return (
    <TaskContainer>
      <TaskHeader onClick={toggleExpand} forPortal={forPortal} header={header}>
        <span>
          {header} ({tasks.length})
        </span>
        <IconStyle
          name={forceExpand === index ? 'vector_top' : 'vector_bottom'}
          forPortal={forPortal}
        />
      </TaskHeader>
      {forceExpand === index && (
        <Detail>
          {tasks.map((d: TaskType, index: number) => {
            if (index < 5) {
              return (
                <DetailRow key={index}>
                  <ValuesName>
                    <CheckboxContainer>
                      {currentAgent?.statusTh === 'ระงับ' ? (
                        <div className="disabledCheckbox" />
                      ) : (
                        <CheckboxWrapper>
                          <div className="round">
                            <input
                              type="checkbox"
                              id={`checkoux_task_${d.id}`}
                              onChange={() =>
                                toggleStatus(d.id, () => {
                                  setTimeout(function () {
                                    setTasks([])
                                    setTasks(
                                      tasks.filter(function (obj) {
                                        return obj.id !== d.id
                                      }),
                                    )
                                  }, 500)
                                })
                              }
                            />
                            <label htmlFor={`checkoux_task_${d.id}`}></label>
                          </div>
                        </CheckboxWrapper>
                      )}
                    </CheckboxContainer>
                    <TopicContainer>
                      <LinkTab
                        to={`/tasks/${d.id}`}
                        style={{ color: 'var(--agt-primary-text-color)' }}
                      >
                        <div className="ellipse two-lines">{d.name}</div>
                      </LinkTab>
                      {d.description ? (
                        <small className="description">{d.description}</small>
                      ) : (
                        <div style={{ marginTop: '26px' }}></div>
                      )}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                        }}
                      >
                        <TopicDate className={type === 'overdue' ? 'error' : 'success'}>
                          <Clock
                            name="clock"
                            color={
                              type === 'overdue'
                                ? 'var(--agt-error-color)'
                                : type === 'today'
                                ? 'var(--agt-secondary-text-color)'
                                : 'var(--agt-success-color)'
                            }
                          />
                          <small
                            style={
                              type === 'overdue'
                                ? { color: 'var(--agt-error-color)' }
                                : type === 'today'
                                ? { color: 'var(--agt-secondary-text-color)' }
                                : { color: 'var(--agt-success-color)' }
                            }
                          >
                            {new Date(d.dueDate).toLocaleDateString('th-TH')}
                          </small>
                        </TopicDate>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          {Array.from(Array(d.priorityLevel).keys()).map((s, i) => (
                            <StarStyle name="star" key={i} color="black" />
                          ))}
                        </div>
                      </div>
                    </TopicContainer>
                  </ValuesName>
                  <ValuesDisplay>
                    <AvatarContainer>
                      {d.contactId && (
                        <Avatar type="circle" width="48px" height="48px" avatar={d.contactAvatar} />
                      )}
                    </AvatarContainer>
                    <InfoContainer>
                      <LinkTab to={`/contacts/${d.contactId}`} style={{ marginBottom: '4px' }}>
                        <div className="one-lines">{d.contactName}</div>
                      </LinkTab>
                      <small>
                        {d.phoneNumber &&
                          d.phoneNumber.slice(0, 3) +
                            '-' +
                            d.phoneNumber.slice(3, 6) +
                            '-' +
                            d.phoneNumber.slice(6, 10)}
                      </small>
                      <small className="one-lines">{d.email}</small>
                    </InfoContainer>
                  </ValuesDisplay>
                </DetailRow>
              )
            } else {
              return null
            }
          })}
        </Detail>
      )}
      {forceExpand === index && tasks.length > 5 && (
        <More>
          <Link to="/tasks">ดูทั้งหมด</Link>
        </More>
      )}
    </TaskContainer>
  )
}

export default Task
