import styled from '@emotion/styled'

export const Container = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100%;
`

export const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`

export const Header = styled.h3`
  margin: 16px 0 10px;
`

export const SubHeader = styled.h4`
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`
