import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
`

export const Header = styled.h3`
  margin-bottom: 10px;
  text-align: center;
`

export const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 30px 0;
`

export const SubmitButton = styled.button`
  display: block;
  margin: 52px auto 0;
`

export const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--agt-primary-color-1);
  width: 100%;
  height: 461px;
  h4 {
    margin-bottom: 45px;
    color: var(--agt-white-color);
    text-align: center;
  }
  p {
    text-align: center;
    color: var(--agt-white-color);
  }
`
