import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useHistory, useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import HistoryType from 'types/HistoryType'
import { RootState } from 'store/rootReducer'
import { get } from 'lodash'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { Formik } from 'formik'
import queryString from 'querystring'
import TravelForm from './TravelForm'
import { addDays } from 'date-fns'
import { getTravelOptions, getSearchByTypeTa } from 'store/travelInsuranceSlice'
// import Loader from 'components/BackgroundLoading'
import { initialValuesType } from 'types/TravelInsuranceType'

const today = new Date()
const tomorrow = addDays(today, 1)

const initialValues: initialValuesType = {
  travelTypeCountry: 'IN',
  travelType: 'D',
  countryId: 999,
  travelPassenger: 1,
  src: '',
  dsc: '',
  travelDateFrom: tomorrow,
  travelDateTo: tomorrow,
  vehicleType: 'plane',
}

const Header = styled.h3`
  margin-bottom: 10px;
`

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
`

const TravelProduct: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const {
    options,
    optionsByTypeTa,
    // loading
  } = useSelector((state: RootState) => state.travel)
  const [searchByTypeTa, setSearchByTypeTa] = useState({
    travelTypeCountry: 'IN',
    travelType: 'D',
  })

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { label: 'ประกันเดินทาง' },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
    dispatch(getTravelOptions(id))
  }, [dispatch, id])

  useEffect(() => {
    dispatch(
      getSearchByTypeTa(id, {
        travelType: searchByTypeTa.travelType,
        travelTypeCountry: searchByTypeTa.travelTypeCountry,
      }),
    )
  }, [dispatch, id, searchByTypeTa.travelType, searchByTypeTa.travelTypeCountry])

  return (
    <Container>
      {/* {loading.search && <Loader />} */}
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const modifiedValues = {
            ...values,
            travelDateFrom: values.travelDateFrom.toString(),
            travelDateTo: values.travelDateTo.toString(),
          }
          const queryStr = queryString.stringify(modifiedValues)
          history.push({
            pathname: `/opportunities/${id}/catalog/travel/insurance_product`,
            search: `?${queryStr}`,
          })
        }}
      >
        {({ values, handleSubmit }) => (
          <TravelForm
            values={values}
            searchByTypeTa={searchByTypeTa}
            options={options}
            optionsByTypeTa={optionsByTypeTa}
            handleSubmit={handleSubmit}
            setSearchByTypeTa={setSearchByTypeTa}
          />
        )}
      </Formik>
    </Container>
  )
}

export default TravelProduct
