import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Notification from 'pages/notification/root'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/notifications" component={Notification} />
  </Switch>
)

export default Pages
