import styled from '@emotion/styled'

export const Act = styled.div`
  width: 32%;
  border-right: 1px solid var(--agt-secondary-color-1);
  .avatar {
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 8px;
    color: var(--agt-primary-color-1);
  }
  .avatar b:nth-child(3) {
    font-size: 24px;
  }
  .detail > div:first-of-type {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11px 40px;
    background-color: var(--agt-background-color-2);
  }
  /* .detail > div:first-of-type div:last-of-type > span {
    transform: rotate(90deg);
  } */
  .detail ul {
    padding: 18px 40px;
    list-style: none;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  .detail ul li {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`

export const ChevronDirect = styled.div<{ spin: boolean }>`
  > span {
    transform-origin: center;
    transform: ${(props) => (props.spin ? 'rotate(90deg)' : 'rotate(270deg)')};
    transition: all 0.5s ease;
  }
`
