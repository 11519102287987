export const POLICY_TYPE = {
  life: 'ประกันชีวิต',
  health: 'ประกันสุขภาพ',
  accident: 'ประกันอุบัติเหตุ',
  home: 'ประกันบ้าน(ประกันอัคคีภัย)',
  motor: 'ประกันรถยนต์',
  miscellaneous: 'ประกันภัยเบ็ดเตล็ด',
  ta: 'ประกันเดินทาง',
  prb: 'ประกันรถยนต์',
}

export const INSURANCE_TYPE = {
  term: 'แบบชั่วระยะเวลา',
  saving: 'แบบสะสมทรัพย์',
  whole_life: 'แบบตลอดชีพ',
  investment: 'แบบควบคุมการลงทุน',
  takaful: 'แบบตะกาฟุล',
  annuity: 'แบบบำนาญ',
  '1': 'ชั้น 1',
  '2+': 'ชั้น 2+',
  '2': 'ชั้น 2',
  '3+': 'ชั้น 3+',
  '3': 'ชั้น 3',
  act: 'พ.ร.บ.',
  WP: 'ยกเว้นค่าเบี้ยประกันของผู้เอาประกัน (WP)',
  'PB/PBCI': 'ยกเว้นค่าเบี้ยประกันของผู้ชำระเบี้ย (PB/PBCI)',
  PB: 'สัญญาเพิ่มเติมผลประโยชน์ผู้ชำระเบี้ย (PB)',
  HS: 'คุ้มครองค่ารักษาพยาบาลผู้ป่วยใน (HS)',
  'HS/ROOM': 'คุ้มครองค่าห้อง ค่าอาหาร ค่าบริการพยาบาลประจำวัน (ต่อวัน) (HS/ROOM)',
  HB: 'คุ้มครองค่าชดเชยรายวัน (HB)',
  'HC/CR': 'คุ้มครองค่ารักษาโรคมะเร็ง (HC/CR)',
  CR: 'สัญญาเพิ่มเติมโรคร้ายแรงกลุ่มมะเร็ง (CR)',
  CI: 'คุ้มครองค่ารักษาโรคร้ายแรง (CI)',
  LC: 'คุ้มครองค่ารักษาโรคร้ายแรงสตรี (LC)',
  OPD: 'คุ้มครองค่ารักษาพยาบาลผู้ป่วยนอก (OPD)',
  TPD: 'คุ้มครองการทุพพลภาพร้ายแรงถาวรสิ้นเชิง (TPD)',
  AI: 'สัญญาเพิ่มเติมกลุ่มอุบัติเหตุ (AI)',
  'AI/RCC': 'คุ้มครองอุบัติเหตุขั้น 3 (AI/RCC)',
  'ADD/RCC': 'คุ้มครองอุบัติเหตุขั้น 2 (ADD/RCC)',
  ADD: 'สัญญาเพิ่มเติมผลประโยชน์เนื่องจากมรณกรรมโดยอุบัติเหตุและการสูญเสียอวัยวะ (ADD)',
  'ADB/RCC': 'คุ้มครองอุบัติเหตุขั้น 1 (ADB/RCC)',
  ADB: 'สัญญาเพิ่มเติมผลประโยชน์เนื่องจากมรณกรรมโดยอุบัติเหตุ (ADB)',
  'PB/CI': 'สัญญาเพิ่มเติมผลประโยชน์ผู้ชำระเบี้ยกรณีเจ็บป่วยโรคร้ายแรง (PB/CI)',
  IN: 'ในประเทศ',
  OUT: 'ต่างประเทศ',
}

export const INSURANCE_PATTERN = {
  personal: 'ประกันภัยบุคคล',
  others: 'ประกันภัยทรัพย์สินและภัยอื่น',
}

export const INSURANCE_STATE = {
  completed: 'กรมธรรม์มีผลบังคับใช้',
  expired: 'กรมธรรม์หมดอายุ',
  draft: 'แบบร่าง',
}

export const INSURANCE_PURPOSE = {
  for_debt_protection: 'เพื่อคุ้มครองภาระหนี้สิน',
  for_income_protection: 'เพื่อคุ้มครองแหล่งรายได้',
  for_health_coverage: 'เพื่อคุ้มครองค่าใช้จ่ายสำหรับการรักษาพยาบาล',
  for_other_protection: 'เพื่อคุ้มครองความเสี่ยงอื่น ๆ',
  for_tax_allowance: 'เพื่อลดหย่อนภาษี',
  for_savings: 'เพื่อเก็บออมพร้อมความคุ้มครอง',
  for_child_education_protection: 'เพื่อคุ้มครองค่าใช้จ่ายสำหรับการศึกษาบุตร',
  for_retirement_income_protection: 'เพื่อเป็นกระแสเงินสดสำหรับการเกษียณ',
}

export const TRAVEL_TYPE = {
  D: 'รายเที่ยว',
  Y: 'รายปี',
}
