import styled from '@emotion/styled'
import React from 'react'
import { useField } from 'formik'

type GenderProps = {
  name: string
  className?: string
  disabled?: boolean
}
const Gender = styled.div`
  display: flex;
  color: var(--agt-primary-text-color);
  height: 48px;
  width: 351px;
  .male {
    border-radius: 4px 0px 0px 4px;
  }
  .female {
    border-radius: 0px 4px 4px 0px;
  }
`
const GenderButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;

  cursor: pointer;
  background: #ffffff;
  border: 1px solid var(--agt-secondary-color-1);
  text-align: center;

  &.selected {
    background-color: var(--agt-light-sky-color);
    border: 1px solid var(--agt-tqm-pantone-1);
    color: var(--agt-tqm-pantone-1);
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled.selected {
    color: var(--agt-primary-text-color);
    border-color: var(--agt-disable-color-2);
    background-color: var(--agt-background-color-2);
  }
`

const GenderComponent = (props: GenderProps) => {
  const { name = '', className = '', disabled = false } = props
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const isSelected = (v: 'M' | 'F') => (v === value ? 'selected' : '')
  const isDisabled = (d: boolean) => (d ? 'disabled' : '')

  return (
    <Gender className={className}>
      <GenderButton
        onClick={() => !disabled && setValue('M')}
        className={`male ${isSelected('M')} ${isDisabled(disabled)}`}
      >
        ชาย
      </GenderButton>
      <GenderButton
        onClick={() => !disabled && setValue('F')}
        className={`female ${isSelected('F')} ${isDisabled(disabled)}`}
      >
        หญิง
      </GenderButton>
    </Gender>
  )
}

export default GenderComponent
