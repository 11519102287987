import React, { FC, useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Formik } from 'formik'

import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Icon from 'components/Icon'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import CommissionTable from './components/CommissionTable'
import ReportModal from './components/ReportModal'
import { commissionColumns } from './columns'
import { exportCommission, getOptions, getSalesCommissions } from 'store/commissionsSlice'
import { RootState } from 'store/rootReducer'

type OptionsButtonProps = {
  radius?: string
  active?: boolean
}
type FilterValues = {
  opportunityKey: string
  orderNo: string
  receivedDate: object | null
  contactName: string
  installmentNo: string
  policyYear: string
  commissionStatus: string
  perPage: number
  orderBy: string
  order: string
}

const initSearchValues: FilterValues = {
  opportunityKey: '',
  orderNo: '',
  receivedDate: null,
  contactName: '',
  installmentNo: '',
  policyYear: '',
  commissionStatus: 'pending',
  perPage: 10,
  orderBy: 'received_date',
  order: 'desc',
}
const orderBy = [
  'opportunityKey',
  'policyYear',
  'installmentNo',
  'receivedDate',
  'affiliateSubmittedAt',
  'submittedAt',
]
const useTimeout = 1000
const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
  .optionBtn {
    padding: 24px 0;
  }
  .optionBtn .groupBtn {
    display: flex;
    justify-content: flex-end;
  }
  .optionBtn .groupBtn > button:last-child {
    margin-left: 24px;
  }
  .optionBtn .groupBtn .reportBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 196px;
  }
  .filterBar {
    padding: 20px 68px;
    background-color: var(--agt-background-color-1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    row-gap: 18px;
  }
  .filterBar .filterInput {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filterBar .filterInput label {
    margin-right: 8px;
  }
  .filterBar .filterInput .opportunityKey {
    width: 200px;
  }
  .filterBar .filterInput .orderNo {
    width: 170px;
  }
  .filterBar .filterInput .receivedDate {
    width: 200px;
  }
  .filterBar .filterInput.contactName {
    justify-content: flex-end;
    width: 470px;
  }
  .filterBar .filterInput .contactName {
    width: 326px;
  }
  .filterBar .filterInput .policyYear {
    width: 120px;
  }
  .filterBar .filterInput .installmentNo {
    width: 120px;
  }
  /* .filterBar .filterInput .selector {
    width: 167px;
  } */
  /* .filterBar .resetOption { */
  /* width: 200px; */
  /* } */
  .filterBar .resetOption label {
    color: var(--agt-primary-color-1);
    text-decoration: underline;
  }
  .filterBar .resetOption label:hover {
    cursor: pointer;
    color: var(--agt-dark-sky-color);
    text-decoration: underline;
  }
  .displayTable {
    margin: 24px 0;
  }
  @media (max-width: 1024px) {
    .filterBar .filterInput.contactName {
      justify-content: flex-end;
      width: fit-content;
    }
  }
`
const CustomDatePicker = styled(DatePicker)``

const Header = styled.h3`
  margin-bottom: 10px;
`
const CustomIcon = styled(Icon)`
  margin-right: 8px;
  div {
    width: 26px;
    height: 26px;
    svg {
      width: 26px;
      height: 26px;
    }
  }
`
const OptionButtonGroup = styled.div`
  display: none;
  margin-right: 24px;
`
const OptionsButton = styled.button<OptionsButtonProps>`
  width: 195px;
  background: ${(props) => (props.active ? 'var(--agt-light-sky-color)' : '#ffffff')};
  ${(props) =>
    props.active && props.radius === 'left'
      ? 'border-right: 1px solid var(--agt-secondary-color-1) !important'
      : 'border-left: 1px solid var(--agt-secondary-color-1) !important'};
  border: ${(props) =>
    props.active
      ? '1px solid var(--agt-primary-color-1)'
      : '1px solid var(--agt-secondary-color-1)'};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
  border-radius: ${(props) => (props.radius === 'left' ? ' 4px 0px 0px 4px ' : '0px 4px 4px 0px')};
  :hover {
    background: ${(props) => (props.active ? 'var(--agt-light-sky-color)' : '#ffffff')};
    border: ${(props) =>
      props.active
        ? '1px solid var(--agt-primary-color-1)'
        : '1px solid var(--agt-secondary-color-1)'};
    color: ${(props) =>
      props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
    border-radius: ${(props) =>
      props.radius === 'left' ? ' 4px 0px 0px 4px ' : '0px 4px 4px 0px'};
  }
`

const CommissionPage: FC = () => {
  const breadCrumbArr: Array<LinkValues> = [{ path: '/commission', label: 'รายการค่าตอบแทน' }]
  const [searchFilter, setSearchFilter] = useState<boolean>(false)
  const [isOpenReport, setIsOpenReport] = useState<boolean>(false)
  const [searchValues, setSearchValues] = useState(initSearchValues)
  const [displayValues, setDisplayValues] = useState(initSearchValues)
  const [timeoutId, setTimeoutId] = useState<any>(null)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const salesCommissions = useSelector((state: RootState) => state.commissions.salesCommissions)
  const paging = useSelector((state: RootState) => state.commissions.paging)
  const options = useSelector((state: RootState) => state.commissions.options)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getSalesCommissions(searchValues))
    dispatch(getOptions())
  }, [dispatch, searchValues])

  const columns = useMemo(
    () => commissionColumns(currentAgent),
    // eslint-disable-next-line
    [salesCommissions, currentAgent],
  )

  const handleChange = (e, key) => {
    const value = e.target.value
    setDisplayValues({ ...displayValues, [key]: value })
    if (useTimeout) {
      if (timeoutId) clearTimeout(timeoutId)
      setTimeoutId(
        setTimeout(() => {
          onChange({ [key]: value })
        }, useTimeout),
      )
    } else {
      onChange({ [key]: value })
    }
  }

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [timeoutId])

  const onChange = useCallback(
    (value) => {
      setSearchValues({ ...searchValues, ...value })
    },
    [searchValues, setSearchValues],
  )

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{ type: 'all' }}
        onSubmit={(values) => {
          dispatch(
            exportCommission(values, () => {
              setIsOpenReport(false)
            }),
          )
        }}
      >
        {(formikProps) => {
          return (
            <ReportModal
              isOpenReport={isOpenReport}
              setIsOpenReport={setIsOpenReport}
              options={options}
              {...formikProps}
            />
          )
        }}
      </Formik>

      <Header>รายการค่าตอบแทน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <section className="optionBtn">
        <div className="groupBtn">
          <OptionButtonGroup>
            <OptionsButton
              radius="left"
              active={searchValues.commissionStatus === 'pending'}
              onClick={() => onChange({ commissionStatus: 'pending' })}
            >
              ค่าตอบแทนที่จะได้รับ
            </OptionsButton>
            <OptionsButton
              radius="right"
              active={searchValues.commissionStatus === 'success'}
              onClick={() => onChange({ commissionStatus: 'success' })}
            >
              ค่าตอบแทนที่ได้รับแล้ว
            </OptionsButton>
          </OptionButtonGroup>
          <button
            className="secondary"
            type="button"
            onClick={() => {
              setSearchFilter(!searchFilter)
            }}
          >
            ปรับตัวเลือก
          </button>
          <button
            className="reportBtn primary"
            type="button"
            onClick={() => {
              setIsOpenReport(true)
            }}
          >
            <CustomIcon name="commissionReport" />
            <div>ออกรายงาน</div>
          </button>
        </div>
      </section>

      {searchFilter && (
        <section className="filterBar">
          <div className="filterInput">
            <label htmlFor="opportunityKey">รหัสโอกาสในการขาย</label>
            <input
              value={displayValues.opportunityKey}
              className="opportunityKey"
              onChange={(e) => handleChange(e, 'opportunityKey')}
              placeholder="โปรดระบุ"
            />
          </div>
          <div className="filterInput">
            <label htmlFor="orderNo">รหัสคำสั่งซื้อ</label>
            <input
              value={displayValues.orderNo}
              className="orderNo"
              onChange={(e) => handleChange(e, 'orderNo')}
              placeholder="โปรดระบุ"
            />
          </div>
          <div className="filterInput">
            <label htmlFor="receivedDate">วันที่ได้รับเงิน</label>
            <CustomDatePicker
              className="receivedDate"
              value={searchValues.receivedDate}
              onChange={(e) => onChange({ receivedDate: e })}
              popperPlacement="auto"
            />
          </div>
          <div className="filterInput contactName">
            <label htmlFor="contactName">รายชื่อลูกค้า</label>
            <input
              value={displayValues.contactName}
              className="contactName"
              onChange={(e) => handleChange(e, 'contactName')}
              placeholder="โปรดระบุ"
            />
          </div>
          <div className="filterInput">
            <label htmlFor="policyYear">ปี</label>
            <input
              value={displayValues.policyYear}
              className="policyYear"
              onChange={(e) => handleChange(e, 'policyYear')}
              placeholder="โปรดระบุ"
            />
          </div>
          <div className="filterInput">
            <label htmlFor="installmentNo">งวด</label>
            <input
              value={displayValues.installmentNo}
              className="installmentNo"
              onChange={(e) => handleChange(e, 'installmentNo')}
              placeholder="โปรดระบุ"
            />
          </div>
          <div className="resetOption">
            <label
              onClick={() => {
                onChange({ ...initSearchValues })
                setDisplayValues(initSearchValues)
              }}
            >
              รีเซ็ตค่าตัวเลือก
            </label>
          </div>
        </section>
      )}
      <section className="displayTable">
        <CommissionTable
          columns={columns}
          data={salesCommissions}
          onChange={onChange}
          paging={paging}
          searchValues={searchValues}
          orderBy={orderBy}
        />
      </section>
    </Container>
  )
}

export default CommissionPage
