import React, { useEffect, useState, FC } from 'react'
import { Redirect, Route } from 'routes'
import session from 'utils/session'

type ProtectedRouteProps = {
  path: string
  component: FC
  exact?: boolean
}
export const ProtectedRoute: FC<ProtectedRouteProps> = ({ path, component }) => {
  const [isRedirct, setIsRedirct] = useState(false)
  const [redirctTo, setRedirctTo] = useState<string>('')

  useEffect(() => {
    if (!session.isAuthTokenPresent()) {
      setIsRedirct(true)
      setRedirctTo('/landing')
    }
  }, [])

  if (isRedirct) return <Redirect to={redirctTo} />

  return <Route path={path} component={component} />
}
