import styled from '@emotion/styled'
import React from 'react'
import { useParams, useHistory } from 'routes'
import { Formik } from 'formik'
import EventForm from 'components/Form/EventForm'
import HistoryType from 'types/HistoryType'
import { useDispatch } from 'react-redux'
import { updateEvent } from 'store/eventsSlice'

type useParamsType = {
  id: string
}

const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`

const EditEventForm = ({ event }) => {
  let { id } = useParams<useParamsType>()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  return (
    <CustomForm>
      <Formik
        initialValues={event}
        onSubmit={(values) => {
          dispatch(
            updateEvent(id, values, () => {
              history.push('/calendar')
            }),
          )
        }}
      >
        {(props) => (
          <EventForm
            handleSubmit={props.handleSubmit}
            event={event}
            isSubmitting={props.isSubmitting}
          />
        )}
      </Formik>
    </CustomForm>
  )
}

export default EditEventForm
