import React, { FC, useState, useCallback } from 'react'
import styled from '@emotion/styled'

import { HeaderContainer, ArrowContainer, ArrowLeft, ArrowRight } from './DateSelector'
import { THIS_YEAR, MONTH_TH, THIS_MONTH } from './DatePicker.utils'
import { MonthSelectorType } from 'types/DatePickerType'

const Container = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 0 auto 2rem;
  padding-top: 1rem;
`
const MonthContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
`
const Month = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 20px;
  cursor: pointer;

  &.selected {
    background-color: var(--agt-primary-color-1);
    color: white;
  }
`

const MonthSelector: FC<MonthSelectorType> = ({
  value: { month = THIS_MONTH, year = THIS_YEAR },
  onChange = () => {},
}) => {
  const [selectedMonth, setMonth] = useState<number>(month)
  const [selectedYear, setYear] = useState<number>(year)

  const decreaseYear = useCallback(() => {
    setYear(selectedYear - 1)
    onChange({ year: selectedYear - 1, month: selectedMonth })
  }, [selectedYear, selectedMonth, setYear, onChange])

  const increaseYear = useCallback(() => {
    setYear(selectedYear + 1)
    onChange({ year: selectedYear + 1, month: selectedMonth })
  }, [selectedYear, selectedMonth, setYear, onChange])

  const _handleSelectMonth = useCallback(
    (index: number) => {
      setMonth(index)
      onChange({ year: selectedYear, month: index })
    },
    [setMonth, onChange, selectedYear],
  )

  return (
    <Container>
      <HeaderContainer>
        <ArrowContainer onClick={decreaseYear}>
          <ArrowLeft name="vector_left" />
        </ArrowContainer>
        <div>{selectedYear + 543}</div>
        <ArrowContainer onClick={increaseYear}>
          <ArrowRight name="vector_left" />
        </ArrowContainer>
      </HeaderContainer>
      <MonthContainer>
        {MONTH_TH.map((month, index) => (
          <Month
            key={index}
            className={selectedMonth === index ? 'selected' : ''}
            onClick={() => _handleSelectMonth(index)}
          >
            {month}
          </Month>
        ))}
      </MonthContainer>
    </Container>
  )
}

export default MonthSelector
