import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import isEmpty from 'lodash/isEmpty'
import { sendConsentDetail, verifyOtp } from 'store/consentsSlice'
import { CustomModal, ModalCustomStyle, ErrMessage } from './OTPComponent.styles'
import HistoryType from 'types/HistoryType'
import { useHistory } from 'react-router'
import { phoneNumberFormat } from 'utils/helper'
import Modal from 'react-modal'
import get from 'lodash/get'
import { OTPComponentType } from './OTPComponent.type'

const OTPComponent = (props: OTPComponentType) => {
  const {
    refOtp,
    isModalOTP,
    setIsModalOTP,
    isSuccesOTP,
    setIsSuccessOTP,
    isOTP,
    setIsOTP,
    setIsModalSuccess,
    counter,
    phoneNumber,
    values,
    contactId,
    jwtContactToken,
  } = props
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const [isVerify, setIsVerify] = useState<boolean>(false)

  const inputfocus = (elmnt, callback) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      callback('')
      const next = elmnt.target.tabIndex - 2
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    } else {
      if (isNaN(elmnt.key)) {
        callback('')
      } else {
        const next = elmnt.target.tabIndex
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
          callback(elmnt.key.toString())
        } else {
          callback(elmnt.key.toString())
          elmnt.target.blur()
        }
      }
    }
  }

  const otpCombine = useCallback(() => {
    let otpcombine = isOTP.otp1 + isOTP.otp2 + isOTP.otp3 + isOTP.otp4 + isOTP.otp5 + isOTP.otp6
    return otpcombine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOTP])

  useEffect(() => {
    if (isVerify && contactId && jwtContactToken) {
      let modifiedValues = {
        agreement_id: values.agreementId,
        consents: values.consents,
      }
      dispatch(
        sendConsentDetail(contactId, modifiedValues, jwtContactToken, () => {
          setIsModalSuccess(true)
          setTimeout(() => {
            history.push(`/contacts/${contactId}`)
          }, 3000)
        }),
      )
    }
    // eslint-disable-next-line
  }, [isVerify, dispatch, history, contactId, jwtContactToken, values])

  return (
    <Modal
      id="modal"
      style={ModalCustomStyle}
      isOpen={isModalOTP}
      onConfirm={() => {}}
      onCancel={() => {}}
    >
      <CustomModal bordercolor={isSuccesOTP ? 'none' : 'var(--agt-error-color)'}>
        <div>กรอก OTP เพื่อยืนยัน</div>
        <div style={{ marginBottom: 14 }}>ส่งไปที่เบอร์ {phoneNumberFormat(phoneNumber)}</div>
        <small style={{ color: 'var(--agt-primary-color-1)', marginBottom: 24 }}>
          Ref Code : {get(refOtp, 'otp_ref_code', '')}
        </small>
        <div className="OTP">
          <form>
            <input
              name="otp1"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp1}
              tabIndex={1}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp1: callback }))
              }}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp2}
              tabIndex={2}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp2: callback }))
              }}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp3}
              tabIndex={3}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp3: callback }))
              }}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp4}
              tabIndex={4}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp4: callback }))
              }}
            />
            <input
              name="otp5"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp5}
              tabIndex={5}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp5: callback }))
              }}
            />
            <input
              name="otp6"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp6}
              tabIndex={6}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp6: callback }))
              }}
              style={{ marginRight: 0 }}
            />
          </form>
        </div>
        {isSuccesOTP ? null : <ErrMessage>รหัสไม่ถูกต้องกรุณาระบุใหม่</ErrMessage>}
        <small style={{ color: 'var(--agt-primary-text-color)', marginBottom: 24 }}>
          สามารถขอรหัส OTP ได้ใหม่หลังจาก {counter} วินาที
        </small>
        <button
          type="button"
          style={{ marginBottom: 14 }}
          disabled={
            isEmpty(isOTP.otp1) ||
            isEmpty(isOTP.otp2) ||
            isEmpty(isOTP.otp3) ||
            isEmpty(isOTP.otp4) ||
            isEmpty(isOTP.otp5) ||
            isEmpty(isOTP.otp6)
          }
          onClick={() => {
            dispatch(
              verifyOtp(
                phoneNumber,
                otpCombine(),
                get(refOtp, 'otp_ref_code', ''),
                jwtContactToken,
                () => {
                  setIsSuccessOTP(true)
                  setIsModalOTP(false)
                  setIsVerify(true)
                },
                () => {
                  setIsSuccessOTP(false)
                },
              ),
            )
            setIsOTP({
              otp1: '',
              otp2: '',
              otp3: '',
              otp4: '',
              otp5: '',
              otp6: '',
            })
          }}
        >
          ยืนยัน
        </button>
      </CustomModal>
    </Modal>
  )
}

export default OTPComponent
