import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import queryString from 'querystring'

import Avatar from 'components/Avatar'
import { currency } from 'utils/helper'
import HistoryType from 'types/HistoryType'
import { SearchStateType } from 'types/MotorInsuranceType'

import { carUsage } from '../utils'

const InsuranceCard: FC<CardType> = ({
  data,
  location,
  setIsCompare,
  compareData,
  setCompareData,
}) => {
  let { id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const jsonStr = queryString.parse(location.replace('?', ''))

  return (
    <Container>
      <CardWrapper>
        <HeaderWrapper>
          <AvatarWrapper>
            <Avatar avatar={data.companyLogoUrl} width="58px" height="58px" type="circle" />
            <TextWrapper>
              <label className="insurance_name">
                {data.companyName} ชั้น{jsonStr.insureLevel}
              </label>
              <label className="installment_text">{`ผ่อน 0% นาน 6 เดือน`}</label>
            </TextWrapper>
          </AvatarWrapper>
          <div className="price_text">฿ {currency(data.netAmount, 2, '')}</div>
        </HeaderWrapper>

        <BodyWrapper>
          <InsuranceDetails>
            <div>
              <label>ประเภท</label>
              <label>ซ่อม</label>
              <label>ทุนประกัน</label>
              <label>ค่าเสียหายส่วนแรก</label>
              <label>จำนวนอู่</label>
            </div>
            <div>
              <label>: {carUsage(data.carUsage) || '-'}</label>
              <label>: {data.garage || '-'}</label>
              <label>: {currency(data.cover, 0, '') || '-'}</label>
              <label>: {data.coverDeduct === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : '-'}</label>
              <label>: {currency(data.garageTotal, 0, '') || '-'}</label>
            </div>
            <div>
              <table>
                <tr>
                  <td>{data.terrorism === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
                  <td>ภัยก่อการ้าย</td>
                </tr>
                <tr>
                  <td>{data.coverFlooding === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
                  <td>น้ำท่วม</td>
                </tr>
                <tr>
                  <td>{data.carCheck === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
                  <td>ตรวจสภาพ</td>
                </tr>
              </table>
            </div>
          </InsuranceDetails>
          <ButtonWrapper>
            <CheckboxWrapper>
              <input
                id={`compare-${data.matrixId}`}
                type="checkbox"
                checked={!!compareData.find((d) => d.matrixId === data.matrixId)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setCompareData([...compareData, { ...data }])
                  } else {
                    const removeData = compareData?.filter((d) => d.matrixId !== data.matrixId)
                    setCompareData(removeData)
                  }
                }}
                disabled={
                  compareData.length === 3 && !compareData.find((d) => d.matrixId === data.matrixId)
                }
              />
              <label htmlFor={`compare-${data.matrixId}`}>เปรียบเทียบ</label>
            </CheckboxWrapper>
            <button
              type="button"
              className="secondary"
              onClick={() => {
                setIsCompare(true)
                setCompareData([data])
              }}
            >
              รายละเอียด
            </button>
            <button
              type="button"
              onClick={() => {
                history.push({
                  pathname: `/opportunities/${id}/catalog/motor/insurance_product/droplead/${data.matrixId}`,
                  search: `${location}`,
                })
              }}
            >
              ซื้อเลย
            </button>
          </ButtonWrapper>
        </BodyWrapper>
      </CardWrapper>
    </Container>
  )
}

export default InsuranceCard

type CardType = {
  data: SearchStateType
  location: string
  setIsCompare: Function
  compareData: Array<SearchStateType>
  setCompareData: Function
}
type ParamsType = {
  id: string
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--agt-background-color-1);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 8px;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 14px;
  .price_text {
    color: var(--agt-primary-color-1);
    font-weight: 700;
    font-size: 24px;
    align-self: center;
  }
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  gap: 4px;
  .insurance_name {
    color: var(--agt-primary-color-1);
    font-weight: 700;
  }

  .installment_text {
    text-align: center;
    font-size: 13px;
    color: var(--agt-white-color);
    background-color: var(--agt-primary-color-1);
    border-radius: 20px;
    padding: 1px 16px;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const InsuranceDetails = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 35px;

  div {
    display: flex;
    flex-direction: column;
  }
  div:nth-child(2) {
    margin-left: 8px;
    margin-right: 100px;
  }

  table > tr > td:first-child {
    padding-right: 11px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: end;
`
const CheckboxWrapper = styled.div`
  label {
    margin-left: 8px;
  }
`
