import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import Api from 'api'
import { Link, useLocation } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import { ErrorMessage, Field as FormikField, Form, Formik } from 'formik'

import { url2Obj } from 'utils/helper'
import Validator, { validate } from './Validator'

type ErrorBarType = {
  hidden?: boolean
}
const ErrorBar = styled.div<ErrorBarType>`
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.hidden ? '0px' : '50px')};

  color: white;
  background: var(--agt-error-color);
  transition: height 0.5s;
`

const Container = styled.div`
  padding: 50px 0;
`
const Title = styled.h4`
  margin-bottom: 50px;
  text-align: center;
`
const Massage = styled.h4`
  margin-bottom: 50px;
  text-align: center;
`
const ConfirmTitle = styled.h4`
  color: var(--agt-primary-color-1);
  margin-bottom: 24px;
`
const MassageDetail = styled.div`
  font-size: 17px;
`
const FormValue = styled(Form)`
  position: relative;

  width: 100%;
  max-width: 352px;
`

type FieldValueProps = {
  isError?: boolean
}
const FieldContainer = styled.div`
  margin: 0 0 32px;
`
const Label = styled.div`
  margin: 0 0 8px;
`
const InputContainer = styled.div`
  position: relative;
`
const FieldValue = styled(FormikField)<FieldValueProps>`
  width: 352px;
  padding: 0 40px 0 16px !important;
  border: ${(props) =>
    props.isError
      ? '1px solid var(--agt-error-color)'
      : '1px solid var(--agt-secondary-color-1)'} !important;
`
const Icon = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;

  cursor: pointer;
`
const Error = styled.div`
  color: var(--agt-error-color);
`

const Field = (props) => {
  const { label, field, form } = props
  const { touched, errors } = form

  const [showPassword, setShowPassword] = useState<boolean>(false)

  const togglePassword = () => setShowPassword(!showPassword)

  return (
    <FieldContainer>
      <Label>{label}</Label>
      <InputContainer>
        <FieldValue
          {...field}
          type={showPassword ? 'text' : 'password'}
          placeholder="โปรดระบุ"
          isError={touched.password && errors.password}
        />
        <Icon onClick={() => togglePassword()}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            color="#ACB1BF"
            style={{ fontSize: '18px' }}
          />
        </Icon>
      </InputContainer>
      <ErrorMessage component={Error} name={field.name} />
    </FieldContainer>
  )
}

const SubmitButton = styled.button`
  width: 100%;
  margin-bottom: 24px;
`
const BackButton = styled.button`
  width: 352px;
`

type PasswordValues = {
  password: string
  passwordConfirmation: string
}
const SetupPassword: FC = () => {
  const location = useLocation()
  const { confirmation_token } = url2Obj(location.search.replace('?', ''))

  const [isLoading, setLoading] = useState<boolean>(false)
  const [isSetupSucess, setSetupSucess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  if (isSetupSucess) {
    return (
      <Container>
        <Massage>
          <ConfirmTitle>อีเมลของคุณได้รับการยืนยันแล้ว</ConfirmTitle>
          <MassageDetail>เข้าสู่ระบบเพื่อเริ่มใช้งาน</MassageDetail>
        </Massage>
        <Link to="/login">
          <BackButton className="primary">เข้าสู่ระบบ</BackButton>
        </Link>
      </Container>
    )
  }

  const setupPassword = async (value: PasswordValues, callback: () => void) => {
    try {
      setLoading(true)
      await Api.post('/auth/confirm', { ...value, confirmationToken: confirmation_token })
      callback()
    } catch (error) {
      console.error(error.response || error)
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 422) {
          setErrorMessage('token ไม่ถูกต้อง')
        }
        if (error.response.status === 401) {
          setErrorMessage(error.response.data.message)
        }
      } else {
        setErrorMessage('เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง')
      }
      setTimeout(() => {
        setErrorMessage('')
      }, 3000)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <ErrorBar hidden={!errorMessage}>
        <FontAwesomeIcon icon={faTimes} style={{ marginRight: '12px' }} />
        <span>{errorMessage}</span>
      </ErrorBar>
      <Container>
        <Title>ตั้งค่ารหัสผ่าน</Title>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validate={validate}
          onSubmit={(values: PasswordValues) => {
            setupPassword(values, () => setSetupSucess(true))
          }}
        >
          {(props) => {
            const { isValid, values, touched } = props
            return (
              <FormValue>
                <FormikField name="password" label="รหัสผ่าน" component={Field} />
                <FormikField name="passwordConfirmation" label="ยืนยันรหัสผ่าน" component={Field} />
                <SubmitButton
                  type="submit"
                  disabled={
                    !isValid || !touched.password || !touched.passwordConfirmation || isLoading
                  }
                >
                  ยืนยันรหัสผ่าน
                </SubmitButton>
                <Validator values={values} />
              </FormValue>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

export default SetupPassword
