import * as Yup from 'yup'

export let schema = Yup.object().shape({
  ownerName: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  ownerGender: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  ownerAge: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  insurancePattern: Yup.string().nullable().required('กรุณาระบุรูปแบบประกันภัย'),
  policyType: Yup.string().nullable().required('กรุณาระบุประเภทประกันภัย'),
  insuranceType: Yup.string().nullable().required('กรุณาระบุประเภทกรมธรรม์'),
  insuranceCompanyName: Yup.string().nullable().required('กรุณาระบุบริษัทประกัน'),
  name: Yup.string().nullable().required('กรุณาระบุชื่อแบบประกัน'),
  policyNo: Yup.string().nullable().required('กรุณาระบุหมายเลขกรมธรรม์'),
  startPolicyAt: Yup.string().nullable().required('กรุณาระบุวันที่เริ่มทำสัญญา'),
  endPolicyAt: Yup.string().nullable().required('กรุณาระบุวันที่ครบกำหนดสัญญา'),
  yieldToMaturityRate: Yup.string().nullable().required('กรุณาระบุเงินคืนเมื่อครบสัญญา'),
  sumInsured: Yup.string().nullable().required('กรุณาระบุทุนประกัน'),
  state: Yup.string().nullable().required('กรุณาระบุสถานะกรมธรรม์'),
  premium: Yup.string().nullable().required('กรุณาระบุเบี้ยประกัน'),
  // ----------------
  payPeriodValue: Yup.string()
    .nullable()
    .when(['policyType'], {
      is: (policyType) => ['life', 'health', 'accident', 'home'].includes(policyType),
      then: Yup.string().required('กรุณาระบุวิธีนับระยะเวลาจ่ายเบี้ย').nullable(),
    }),
  payPeriodType: Yup.string()
    .nullable()
    .when(['policyType'], {
      is: (policyType) => ['life', 'health', 'accident', 'home'].includes(policyType),
      then: Yup.string().required('กรุณาระบุวิธีนับระยะเวลาจ่ายเบี้ย').nullable(),
    }),
  paymentType: Yup.string()
    .nullable()
    .when(['policyType'], {
      is: (policyType) => ['life', 'health', 'accident', 'home'].includes(policyType),
      then: Yup.string().required('กรุณาระบุรูปแบบการจ่าย').nullable(),
    }),
  purpose: Yup.string()
    .nullable()
    .when(['policyType'], {
      is: (policyType) => ['life'].includes(policyType),
      then: Yup.string().required('กรุณาระบุวัตถุประสงค์ของกรมธรรม์').nullable(),
    }),
  specialFields: Yup.object().when(['policyType'], {
    is: (policyType) => ['motor'].includes(policyType),
    then: Yup.object().shape({
      drivers: Yup.array().of(
        Yup.object().shape({
          firstName: Yup.string().nullable().required('กรุณาระบุ'),
          lastName: Yup.string().nullable().required('กรุณาระบุ'),
          occupation: Yup.string().nullable().required('กรุณาระบุ'),
          birthdate: Yup.string().nullable().required('กรุณาระบุ'),
        }),
      ),
      productDetails: Yup.object().shape({
        coverCar: Yup.string().nullable().required('กรุณาระบุ'),
        coverDeduct: Yup.string().nullable().required('กรุณาระบุ'),
        coverLostFire: Yup.string().nullable().required('กรุณาระบุ'),
        coverMoreDetails: Yup.array().of(
          Yup.object().shape({
            name: Yup.string().nullable().required('กรุณาระบุ'),
            value: Yup.string().nullable().required('กรุณาระบุ'),
            unit: Yup.string().nullable().required('กรุณาระบุ'),
          }),
        ),
      }),
    }),
  }),
  // kkkk: Yup.array().when(['policyType'], {
  //   is: (policyType) => ['motor'].includes(policyType),
  //   then: Yup.array().of(
  //     Yup.object().shape({
  //       aa: Yup.string().nullable().required('กรุณาระบุ'),
  //       bb: Yup.string().nullable().required('กรุณาระบุ'),
  //       cc: Yup.string().nullable().required('กรุณาระบุ'),
  //       dd: Yup.string().nullable().required('กรุณาระบุ'),
  //     }),
  //   ),
  // }),
  // ----------------
})
