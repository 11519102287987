import React, { useCallback, useEffect, useState, useRef, FC, useMemo } from 'react'
import './style.css'

type MultiRangeSliderType = {
  min: number
  max: number
  onChange: Function
}

const MultiRangeSlider: FC<MultiRangeSliderType> = ({ min, max, onChange }) => {
  const [minVal, setMinVal] = useState<number>(min)
  const [maxVal, setMaxVal] = useState<number>(max)
  const minValRef = useRef(min)
  const maxValRef = useRef(max)
  const range = useRef<HTMLInputElement>(null)
  // Convert to percentage
  const getPercent = useCallback((value) => Math.round(((value - min) / (max - min)) * 100), [
    min,
    max,
  ])

  useEffect(() => {
    setMinVal(min)
    setMaxVal(max)
    if (minValRef) minValRef.current = min
    if (maxValRef) maxValRef.current = max
  }, [min, max])

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      range.current.style.left = `${minPercent}%`
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [minVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(maxVal)

    if (range.current) {
      range.current.style.width = `${maxPercent - minPercent}%`
    }
  }, [maxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({ min: minVal, max: maxVal })
  }, [minVal, maxVal, onChange])

  const step = useMemo(() => {
    const diff = (max - min) / 100

    return diff > 0 ? diff : 100
  }, [min, max])

  return (
    <>
      <div className="slider_container">
        <div className="slider-value_container">
          <div className="slider-min_value">฿ {minVal}</div>
          <div className="slider-max_value">฿ {maxVal}</div>
        </div>
        <input
          type="range"
          min={min}
          max={max}
          value={minVal}
          step={step}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), maxVal - 1)
            setMinVal(value)
            minValRef.current = value
          }}
          className="thumb thumb--left"
          style={minVal > max - 100 ? { zIndex: 5 } : {}}
        />
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={maxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), minVal + 1)
            setMaxVal(value)
            maxValRef.current = value
          }}
          className="thumb thumb--right"
        />

        <div className="slider">
          <div className="slider__track" />
          <div ref={range} className="slider__range" />
          <div className="slider__left-min_value">฿ {min}</div>
          <div className="slider__right-max_value">฿ {max}</div>
        </div>
      </div>
    </>
  )
}

export default MultiRangeSlider
