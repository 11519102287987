import { isValidId, phoneNumberRegex } from 'utils/helper'
import * as Yup from 'yup'

const DropleadSchema = Yup.object().shape({
  idCard: Yup.string()
    .nullable()
    .test('is-id', 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง', (value) => {
      if (value) return isValidId(value)
      return true
    })
    .required('กรุณาระบุข้อมูล'),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .nullable()
    .required('กรุณาระบุข้อมูล'),
  email: Yup.string().nullable().email('รูปแบบอีเมลไม่ถูกต้อง').required('กรุณาระบุอีเมล'),
  vehicleDetails: Yup.object().shape({
    name: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    year: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    makeCode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    familyCode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    vehicleSubModel: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    vehicleLicense: Yup.object().shape({
      value: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    }),
    vehicleProvince: Yup.object().shape({
      value: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    }),
    vehicleChassis: Yup.object().shape({
      value: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    }),
  }),
  order: Yup.object().shape({
    motorDetails: Yup.object().shape({
      policyDeliveryAddress: Yup.object().shape({
        housingNo: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        subDistrict: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        district: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        province: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        postcode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      }),
      policyAddress: Yup.object().shape({
        housingNo: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        subDistrict: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        district: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        province: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        postcode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      }),
      receiptAddress: Yup.object().shape({
        housingNo: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        subDistrict: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        district: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        province: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        postcode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      }),
      inspectionLocation: Yup.string().nullable().required('กรุณาระบุ'),
      vehicleInspectionDate: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      policyForm: Yup.string().nullable().required('กรุณาระบุ'),
      startPolicyAt: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      // prb: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
    }),
  }),
})

export default DropleadSchema
