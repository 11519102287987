import React, { useState, useCallback } from 'react'
import { Formik } from 'formik'
import validate from './validate'
import { useDispatch, useSelector } from 'react-redux'
import { checkAccount, createUser } from 'store/appSlice'
import Loader from 'components/BackgroundLoading'
import { RootState } from 'store/rootReducer'
import { Container } from './GuestForm.styles'
import {
  RegisterPageType,
  valueCheckAccountType,
  onSuccessCheckAccountType,
} from './GuestForm.type'
import { HeaderComponent, RegisterForm } from './GuestForm.components'

const RegisterPage = ({
  className = '',
  onSuccess,
  setEmail,
  termsAndConditions,
  pdpa,
  agreement,
  mockupTerm,
  mockupPDPA,
}: RegisterPageType) => {
  const dispatch = useDispatch()
  const { loading } = useSelector((state: RootState) => state.app)
  const [isSocial, setIsSocial] = useState<number>(0)

  const waitForCheckAccount = useCallback(
    (values: valueCheckAccountType, cb: onSuccessCheckAccountType) => {
      dispatch(checkAccount(values, cb))
    },
    [dispatch],
  )

  return (
    <Container className={className}>
      {(loading.options || loading.register) && <Loader />}
      <HeaderComponent isSocial={isSocial} />
      <Formik
        enableReinitialize
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          email: '',
          type: '',
          facebook: {},
          google: {},
          terms: mockupTerm,
          pdpa: mockupPDPA,
          agreements: agreement,
        }}
        onSubmit={(values) => {
          let newAgreements: Array<{ id: string; consents: [] }> = []
          let newTerms = values?.terms ? { ...values.terms } : {}
          let newPDPA = values?.pdpa ? { ...values.pdpa } : {}
          if (values.terms) newAgreements.push(newTerms)
          if (values.pdpa) newAgreements.push(newPDPA)
          let modifiedValues = { ...values, agreements: newAgreements }
          delete modifiedValues.pdpa
          delete modifiedValues.terms
          dispatch(createUser(modifiedValues, onSuccess, setEmail))
        }}
        validate={validate}
      >
        {(props) => {
          return (
            <RegisterForm
              {...props}
              isSocial={isSocial}
              setIsSocial={setIsSocial}
              termsAndConditions={termsAndConditions}
              pdpa={pdpa}
              waitForCheckAccount={waitForCheckAccount}
            />
          )
        }}
      </Formik>
    </Container>
  )
}

export default RegisterPage
