import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'routes'
import styled from '@emotion/styled'
import {
  Field as FormikField,
  Form,
  useField,
  FormikProps,
  FieldArray,
  useFormikContext,
  FormikValues,
  ErrorMessage,
} from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import Gender from 'components/Inputs/Gender'
import Upload from 'components/Inputs/Uploader'
import CustomAddress from 'components/Address'
import RegisterType from 'types/RegisterType'
import Icon from 'components/Icon'
import { isEqual } from 'lodash'
import { getProileOptions, sendOtp } from 'store/profileSlice'
import { useDispatch, useSelector } from 'react-redux'
import { OptionsType, OtpType } from 'types/RegisterType'
import { RootState } from 'store/rootReducer'
import {
  LicenseTypeComponent,
  InsuranceCompanyNameComponent,
  PrefixNameComponent,
} from './component/SelectorUserForm'
import ImageComponent from './component/ImageComponent'
import GroupAddress from './component/GroupAddress'
import OTPComponent from './component/OTPComponent'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import subDays from 'date-fns/subDays'

const today = new Date()
const yesterday = subDays(today, 1)

type UserFormProps = {
  className?: string
  disableList?: Array<string>
}
type UserForkmikType = FormikProps<FormikValues> & {
  values: RegisterType
}
type WaitOtpFieldType = {
  background?: string
  color?: string
}

const FormContainer = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  .name {
    width: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 32px;
  }
  .name .firstName {
    width: 280px;
  }
  .name .lastName {
    width: 280px;
  }
  .prefixName {
    width: 122px;
  }
  .icon {
    cursor: pointer;
  }
  .gender {
    width: 352px;
    margin-right: 32px;
    > div {
      height: 48px;
    }
  }
  .nickname {
    width: 352px;
  }
  .idCard {
    width: 352px;
    margin-right: 32px;
  }
  .idCardImageId {
    width: 352px;
  }
  .phoneNumber {
    width: 352px;
    margin-right: 32px;
  }
  .email {
    width: 352px;
  }
  .address {
    width: 100%;
    min-height: 96px;
    textarea {
      min-height: 96px;
      padding: 11px 16px;
      resize: vertical;
    }
  }
  .licenseCode {
    width: 352px;
    margin-right: -157px;
    margin-bottom: 0px;
  }
  .licenseType {
    position: static;
    width: 216px;
    height: 48px;
    left: 206px;
    top: 0px;
  }
  .licenseImageId {
    width: 352px;
    margin-bottom: 0px;
  }
  .error {
    input {
      border-color: var(--agt-error-color);
    }
    textarea {
      border-color: var(--agt-error-color);
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  .suggest {
    width: 226px;
    height: 48px;
    background: #00529a;
    border-radius: 8px;
    color: var(--agt-white-color);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .infoLicenses {
    display: flex;
    margin-bottom: 24px;
  }
  .infoLicenses b {
  }
  .birthdate.disabled input {
    background: var(--agt-background-color-1) !important;
    color: var(--agt-primary-text-color) !important;
  }
`
const RecruitContainer = styled.div<{ hidden: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'flex')};
  padding: 14px 24px;
  margin-bottom: 24px;
  /* display: flex; */
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: var(--agt-background-color-1);
  .type {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .type .affiliate {
    position: relative;
  }
  .type .affiliate .affiliateRadio {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--agt-white-color);
    column-gap: 16px;
    width: 310px;
    height: 48px;
    border: 1px solid var(--agt-secondary-color-1);
    padding: 0 16px;
    cursor: pointer;
  }
  .type .affiliate .affiliateRadio.checked {
    border: 1px solid var(--agt-primary-color-1);
    background-color: var(--agt-light-sky-color);
  }
  .type .radio {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-text-color);
  }
  .type .radio .dot {
    position: absolute;
    top: 1.3px;
    left: 1.3px;
    width: 9.6px;
    height: 9.6px;
    border-radius: 50%;
    background-color: var(--agt-white-color);
  }
  .type .radio.checked {
    border: 2px solid var(--agt-primary-text-color);
  }
  .type .radio.checked .dot {
    background-color: var(--agt-primary-text-color);
  }
  .type .affiliate .affiliateIcon {
    position: absolute;
    top: 10px;
    right: -42px;
    width: 30px;
    height: 30px;
  }
  .type .affiliate .affiliateIcon svg {
    width: 30px;
    height: 30px;
  }
  .type .affiliate .affiliateIcon:hover {
    cursor: pointer;
  }
  .type .affiliate .affiliateIcon:hover + .info {
    display: flex;
  }
  .type .affiliate .info {
    display: none;
    position: absolute;
    top: 0px;
    right: -292px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
  }
  .type .broker {
    position: relative;
  }
  .type .broker .brokerRadio {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--agt-white-color);
    column-gap: 16px;
    width: 310px;
    height: 48px;
    border: 1px solid var(--agt-secondary-color-1);
    padding: 0 16px;
    cursor: pointer;
  }
  .type .broker .brokerRadio.checked {
    border: 1px solid var(--agt-primary-color-1);
    background-color: var(--agt-light-sky-color);
  }
  .type .broker ul {
    margin-top: 16px;
  }
  .type .broker ul li {
    position: relative;
    display: flex;
    column-gap: 16px;
    align-items: center;
    width: fit-content;
    list-style: none;
    margin-bottom: 14px;
    cursor: pointer;
  }
  .type .broker ul li:last-of-type {
    margin-bottom: 0px;
  }
  .type .broker .brokerCheckbox .checkbox {
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--agt-secondary-color-2);
    background-color: var(--agt-white-color);
  }
  .type .broker .brokerCheckbox .checkbox.checked {
    background-color: var(--agt-primary-color-1);
  }
  .type .broker .brokerCheckbox .checkbox .result {
    position: absolute;
    top: 1.5px;
    left: 1.5px;
    font-size: 11px;
    color: var(--agt-white-color);
    visibility: hidden;
  }
  .type .broker .brokerCheckbox .checkbox.checked .result {
    visibility: unset;
  }
  .type .broker .brokerCheckbox .brokerLife .brokerLifeIcon {
    position: absolute;
    top: 0px;
    left: 170px;
    width: 30px;
    height: 30px;
  }
  .type .broker .brokerCheckbox .brokerLife .brokerLifeIcon svg {
    width: 30px;
    height: 30px;
  }
  .type .broker .brokerCheckbox .brokerLife .brokerLifeIcon:hover {
    cursor: pointer;
  }
  .type .broker .brokerCheckbox .brokerLife .brokerLifeIcon:hover + .info {
    display: flex;
  }
  .type .broker .brokerCheckbox .brokerLife .info {
    display: none;
    position: absolute;
    width: 247px;
    top: -6px;
    left: 214px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
  }
  .type .broker .brokerCheckbox .brokerAccident .brokerAccidentIcon {
    position: absolute;
    top: 0px;
    left: 170px;
    width: 30px;
    height: 30px;
  }
  .type .broker .brokerCheckbox .brokerAccident .brokerAccidentIcon svg {
    width: 30px;
    height: 30px;
  }
  .type .broker .brokerCheckbox .brokerAccident .brokerAccidentIcon:hover {
    cursor: pointer;
  }
  .type .broker .brokerCheckbox .brokerAccident .brokerAccidentIcon:hover + .info {
    display: flex;
  }
  .type .broker .brokerCheckbox .brokerAccident .info {
    display: none;
    position: absolute;
    width: 247px;
    top: -6px;
    left: 214px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    z-index: 1;
    border-radius: 8px;
    cursor: pointer;
  }
`
const Hr = styled.hr`
  width: 100%;
  margin-bottom: 24px;
  border: 0px;
  border-top: 1px solid var(--agt-secondary-color-1);
`
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const Label = styled.label`
  margin-bottom: 4px;
  color: var(--agt-primary-text-color) !important;
`
const FieldValue = styled(FormikField)`
  width: 100%;
  border: ${(props) =>
    props.isError ? '1px solid var(--agt-error-color)' : '1px solid var(--agt-secondary-color-1)'};
`
const Error = styled.div`
  margin-top: 8px;
`
const CustomError = styled.small`
  margin-top: 8px;
  color: var(--agt-error-color);
`
const LicenseContainer = styled.div`
  position: relative;
  display: flex;
`
const LicenseButton = styled.button`
  margin: 0 auto 24px;
`
const LicenseDelete = styled.div`
  position: absolute;
  width: 46.56px;
  height: 38px;
  left: 143px;
  top: 34px;
  cursor: pointer;
  background: var(--agt-background-color-3);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const CustomIcon = styled(Icon)`
  div {
    width: 18px;
    height: 18px;
    svg {
      width: 18px;
      height: 18px;
    }
  }
`
const WaitOtpField = styled.div<WaitOtpFieldType>`
  margin-top: 30px;
  align-self: flex-start;
  display: flex;
  justify-content: space-between;
  width: 352px;
  /* height: 48px; */
  margin-bottom: 24px;
  .status {
    display: flex;
    align-items: center;
    width: 200px;
    height: 48px;
    padding: 0 12px;
    border-radius: 5px;
    background: ${(props) => props.background && props.background};
  }
  .message {
    color: ${(props) => props.color && props.color};
  }
`
const DatePickerField = styled(DatePicker)`
  width: 352px;
  height: 48px;
`
const EmailComponent = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  .infoI {
    margin-left: 32px;
    margin-top: 40px;
  }
  .suggest {
    position: absolute;
    top: 28px;
    right: 85px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: all 0.1s;
  }
  .infoI:hover + .suggest {
    opacity: 1;
    cursor: pointer;
  }
`

const Input = (props) => {
  const { field, form, ...rest } = props
  return <input {...field} {...rest} />
}

const Textarea = (props) => {
  const { field, form, ...rest } = props
  return <textarea {...field} {...rest} />
}

const DatePickerComponent = (props) => {
  const { field, disabled = false } = props
  const [, , helpers] = useField(field.name)
  const { setValue } = helpers
  return (
    <DatePickerField
      className={disabled ? 'birthdate disabled' : 'birthdate'}
      value={field.value || ''}
      maxDate={yesterday}
      showSelector
      onChange={(value) => {
        setValue(value)
      }}
      disabled={disabled}
    />
  )
}

const Field = (props) => {
  const {
    className = '',
    label = '',
    placeholder = '',
    name = '',
    type = '',
    star = false,
    setwidth = false,
    onChange,
    selectType,
    ...rest
  } = props
  const [field, meta] = useField(name)
  let element: React.ReactNode
  switch (type) {
    case 'gender':
      element = <Gender name={name} {...rest} />
      break
    case 'textarea':
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          component={Textarea}
          rows="4"
          {...rest}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
      )
      break
    case 'upload':
      element = <Upload name={name} placeholder={placeholder} {...rest} selectType={selectType} />
      break
    case 'number':
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          component={Input}
          type="number"
          {...rest}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
      )
      break
    case 'datepicker':
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          component={DatePickerComponent}
          {...rest}
        />
      )
      break
    default:
      element = (
        <FieldValue
          name={name}
          placeholder={placeholder}
          style={setwidth ? { width: '194px', height: '47px' } : {}}
          component={Input}
          {...rest}
          onChange={(e) => {
            field.onChange(e)
            if (onChange) {
              onChange(e.target.value)
            }
          }}
        />
      )
      break
  }
  return (
    <FieldContainer className={`${className} ${name} ${meta.touched && meta.error && 'error'}`}>
      <Label>
        {label} {star && <label style={{ color: 'var(--agt-error-color)' }}>*</label>}
      </Label>
      {element}
      {meta.touched && meta.error && <Error className="small error">{meta.error}</Error>}
    </FieldContainer>
  )
}

const UserForm = ({ className = '', disableList = [] }: UserFormProps) => {
  const { values, setFieldValue }: UserForkmikType = useFormikContext()
  const [isWaitOTP, setIsWaitOTP] = useState<boolean>(false)
  const [isModalOTP, setIsModalOTP] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(60)
  const [isRuning, setisRuning] = useState<boolean>(false)
  const [isSuccesOTP, setIsSuccessOTP] = useState<boolean>(true)

  const dispatch = useDispatch()
  const options: OptionsType = useSelector((state: RootState) => state.profile.options)
  const refOtp: OtpType = useSelector((state: RootState) => state.profile.otp)
  const [isOTP, setIsOTP] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  })

  let flipInterval = useRef()

  function checkedDuplicateForm(currentAddress, idCardAddress) {
    return isEqual(idCardAddress, currentAddress)
  }

  const callTimeOut = () => {
    setTimeout(() => {
      setIsModalOTP(false)
      setisRuning(false)
      setCounter(60)
      setIsSuccessOTP(true)
    }, 60000)
  }

  useEffect(() => {
    dispatch(getProileOptions())
  }, [dispatch])

  useEffect(() => {
    const time: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    flipInterval.current = time
    return () => {
      clearInterval(flipInterval.current)
    }
  }, [counter])

  useEffect(() => {
    if (
      values.currentAddress &&
      values.idCardAddress &&
      !values.isSameIdCardAddress &&
      checkedDuplicateForm(values.currentAddress, values.idCardAddress)
    ) {
      setFieldValue('isSameIdCardAddress', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.currentAddress, values.idCardAddress])

  useEffect(() => {
    if (values.isSameIdCardAddress) {
      setFieldValue('currentAddress', values.idCardAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.idCardAddress])

  useEffect(() => {
    if (values.isSameIdCardAddress) {
      setFieldValue('currentAddress', values.idCardAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.isSameIdCardAddress])

  useEffect(() => {
    if (disableList.includes('isOTP')) {
      setIsWaitOTP(true)
    }
  }, [setIsWaitOTP, disableList])

  useEffect(() => {
    if (values.recruitType === 'affiliate') {
      setFieldValue('agentLicenses[0].licenseType', 'ตัวแทนประกันชีวิต')
      setFieldValue('brokerType', [])
    } else if (values.recruitType === 'broker') {
      setFieldValue('agentLicenses[0].licenseType', 'นายหน้าประกันชีวิต')
      setFieldValue('brokerType', ['life_broker'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.recruitType])

  useEffect(() => {
    if (values?.brokerType?.length === 2) {
      setFieldValue('agentLicenses', [
        {
          licenseCode: '',
          licenseImageId: '',
          status: '',
          licenseType: 'นายหน้าประกันชีวิต',
        },
        {
          licenseCode: '',
          licenseImageId: '',
          status: '',
          licenseType: 'นายหน้าประกันวินาศภัย',
        },
      ])
    } else if (
      (values?.brokerType || []).find((brokerType) => brokerType === 'life_broker') ===
      'life_broker'
    ) {
      setFieldValue('agentLicenses[0].licenseType', 'นายหน้าประกันชีวิต')
    } else if (
      (values?.brokerType || []).find((brokerType) => brokerType === 'non_life_broker') ===
      'non_life_broker'
    ) {
      setFieldValue('agentLicenses[0].licenseType', 'นายหน้าประกันวินาศภัย')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, values.brokerType])

  return (
    <FormContainer className={className}>
      <OTPComponent
        isModalOTP={isModalOTP}
        refOtp={refOtp}
        isSuccesOTP={isSuccesOTP}
        isOTP={isOTP}
        setIsOTP={setIsOTP}
        counter={counter}
        values={values}
        setIsSuccessOTP={setIsSuccessOTP}
        setIsWaitOTP={setIsWaitOTP}
        setIsModalOTP={setIsModalOTP}
      />
      <ImageComponent />
      <RecruitContainer hidden={disableList.includes('recruitType')}>
        <div style={{ marginBottom: 4 }}>เลือก</div>
        <div className="type">
          <div className="affiliate">
            <div
              className={
                values.recruitType === 'affiliate' ? 'affiliateRadio checked' : 'affiliateRadio '
              }
              onClick={() => {
                setFieldValue('recruitType', 'affiliate')
              }}
            >
              <div className={values.recruitType === 'affiliate' ? 'radio checked' : 'radio '}>
                <div className="dot" />
              </div>
              <div>สมัครผู้แนะนำ</div>
            </div>
            <div className="affiliateIcon">
              <Icon name="I" color={'var(--agt-primary-color-1)'} />
            </div>
            <div className="info">
              <small>โปรดเตรียมใบอนุญาตตัวแทนประกันชีวิต</small>
            </div>
          </div>
          <div className="broker">
            <div
              className={values.recruitType === 'broker' ? 'brokerRadio checked' : 'brokerRadio'}
              onClick={() => {
                setFieldValue('recruitType', 'broker')
              }}
            >
              <div className={values.recruitType === 'broker' ? 'radio checked' : 'radio '}>
                <div className="dot" />
              </div>
              <div>สมัครนายหน้า</div>
            </div>
            {values.recruitType === 'broker' && (
              <ul className="brokerCheckbox">
                <li
                  className="brokerLife"
                  onClick={() => {
                    if (
                      values.brokerType &&
                      !(values?.brokerType || []).find((brokerType) => brokerType === 'life_broker')
                    ) {
                      let brokerType = [...values.brokerType]
                      brokerType.push('life_broker')
                      setFieldValue('brokerType', brokerType)
                    } else {
                      setFieldValue(
                        'brokerType',
                        (values?.brokerType || []).filter(
                          (brokerType) => brokerType !== 'life_broker',
                        ),
                      )
                    }
                  }}
                >
                  <div
                    className={
                      (values?.brokerType || []).find(
                        (brokerType) => brokerType === 'life_broker',
                      ) === 'life_broker'
                        ? 'checkbox checked'
                        : 'checkbox'
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} className="result" />
                  </div>
                  <div>นายหน้าชีวิต</div>
                  <div className="brokerLifeIcon">
                    <Icon name="I" color={'var(--agt-primary-color-1)'} />
                  </div>
                  <div className="info">
                    <small>โปรดเตรียมใบอนุญาตนายหน้าประกันชีวิต</small>
                  </div>
                </li>
                <li
                  className="brokerAccident"
                  // onClick={() => {
                  //   if (
                  //     values.brokerType &&
                  //     !(values?.brokerType || []).find(
                  //       (brokerType) => brokerType === 'non_life_broker',
                  //     )
                  //   ) {
                  //     let brokerType = [...values.brokerType]
                  //     brokerType.push('non_life_broker')
                  //     setFieldValue('brokerType', brokerType)
                  //   } else {
                  //     setFieldValue(
                  //       'brokerType',
                  //       (values?.brokerType || []).filter(
                  //         (brokerType) => brokerType !== 'non_life_broker',
                  //       ),
                  //     )
                  //   }
                  // }}
                >
                  <div
                    className={
                      (values?.brokerType || []).find(
                        (brokerType) => brokerType === 'non_life_broker',
                      ) === 'non_life_broker'
                        ? 'checkbox checked'
                        : 'checkbox'
                    }
                    style={{ backgroundColor: 'var(--agt-disable-color-1)' }}
                  >
                    <FontAwesomeIcon icon={faCheck} className="result" />
                  </div>
                  <div style={{ color: 'var(--agt-secondary-text-color)' }}>นายหน้าวินาศภัย</div>
                  <div className="brokerAccidentIcon">
                    <Icon
                      name="I"
                      // color={'var(--agt-primary-color-1)'}
                      color={'var(--agt-secondary-text-color)'}
                    />
                  </div>
                  <div style={{ color: 'var(--agt-secondary-text-color)', marginLeft: 55 }}>
                    เร็วๆ นี้
                  </div>
                  {/* <div className="info">
                    <small>
                      โปรดเตรียมใบอนุญาตนายหน้าประกัน
                      <br />
                      วินาศภัย
                    </small>
                  </div> */}
                </li>
              </ul>
            )}
          </div>
        </div>
      </RecruitContainer>
      <div className="name">
        <FormikField
          label="คำนำหน้าชื่อ"
          name="prefixName"
          component={PrefixNameComponent}
          className="prefixName"
          options={[
            { label: 'นาย', value: 'นาย' },
            { label: 'นาง', value: 'นาง' },
            { label: 'นางสาว', value: 'นางสาว' },
            // { label: 'ด.ช.', value: 'ด.ช.' },
            // { label: 'ด.ญ.', value: 'ด.ญ.' },
          ]}
          isDisabled={disableList.includes('prefixName')}
        />
        <Field
          label="ชื่อ"
          star
          name="firstName"
          placeholder="โปรดระบุ"
          disabled={disableList.includes('firstName')}
        />
        <Field
          label="นามสกุล"
          star
          name="lastName"
          placeholder="โปรดระบุ"
          disabled={disableList.includes('lastName')}
        />
      </div>
      <Field
        label="เพศ"
        star
        name="gender"
        type="gender"
        disabled={disableList.includes('gender')}
      />
      <Field
        label="วันเกิด"
        star
        name="birthdate"
        type="datepicker"
        placeholder="โปรดระบุ"
        disabled={disableList.includes('birthdate')}
      />
      <Field
        label="เลขบัตรประชาชน"
        star
        name="idCard"
        maxLength="13"
        placeholder="โปรดระบุ"
        disabled={disableList.includes('idCard')}
      />
      <Field
        label=" "
        name="idCardImageId"
        type="upload"
        selectType={['PDF', 'JPG', 'JPEG', 'PNG']}
        placeholder="อัปโหลดภาพบัตรประจำตัวประชาชนเพื่อยืนยันตัวตน"
        uploadDocumentPlaceholder={'บัตรประจำตัวประชาชน'}
        licenseStatus={values.idCardImageStatus || ''}
        imageIdCard={true}
      />
      <EmailComponent>
        <Field
          label="เบอร์โทรศัพท์"
          star
          name="phoneNumber"
          placeholder="โปรดระบุ"
          maxLength="10"
          disabled={disableList.includes('phoneNumber')}
        />
        <WaitOtpField
          background={isWaitOTP ? 'var(--agt-success-color)' : 'var(--agt-background-color-1)'}
          color={isWaitOTP ? 'var(--agt-white-color)' : 'var(--agt-secondary-color-1)'}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <button
              style={{ width: 137 }}
              type="button"
              onClick={() => {
                setIsModalOTP(true)
                setisRuning(true)
                dispatch(sendOtp(values.phoneNumber))
                callTimeOut()
                setCounter(60)
              }}
              disabled={isWaitOTP || isRuning}
            >
              ขอรหัส OTP
            </button>
            <ErrorMessage name="isOTP" component={CustomError} />
          </div>
          {isWaitOTP ? (
            <div className="status">
              <CustomIcon name="correct-outline" color={'var(--agt-white-color)'} />
              <small className="message" style={{ marginLeft: 10 }}>
                ยืนยันเบอร์โทรศัพท์สำเร็จ
              </small>
            </div>
          ) : (
            <>
              <div className="status">
                <CustomIcon name="warning" color={'var(--agt-secondary-color-1)'} />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                  {isRuning ? (
                    <>
                      <small className="message">กรุณารอ {counter} วินาที </small>
                      <small className="message"> หรือ กรอกเบอร์ใหม่</small>
                    </>
                  ) : (
                    <>
                      <small className="message">กรอก OTP เพื่อยืนยัน</small>
                      <small className="message">เบอร์โทรศัพท์</small>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </WaitOtpField>
      </EmailComponent>
      <EmailComponent>
        <Field
          label="อีเมล"
          star
          name="email"
          placeholder="โปรดระบุ"
          disabled={disableList.includes('email')}
        />
        <Icon name="I" className="infoI" color={'var(--agt-primary-color-1)'} />
        <div className="suggest">
          <small>อีเมลสำหรับติอต่อและรับข้อมูลข่าวสาร</small>
        </div>
      </EmailComponent>
      <div style={{ width: '100%', marginBottom: '24px' }}>ที่อยู่ตามบัตรประชาชน</div>
      <GroupAddress name={'idCardAddress'} disabled={disableList.includes('idCardAddress')} />
      <CustomAddress
        addressType={'idCardAddress'}
        disabled={disableList.includes('idCardAddress')}
      />
      <div style={{ width: '100%', marginBottom: '24px' }}>ที่อยู่ในการส่งเอกสาร</div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '24px' }}>
        <FormikField
          name="isSameIdCardAddress"
          type="checkbox"
          checked={values.isSameIdCardAddress}
        />
        <label style={{ marginLeft: '16px' }}>เหมือนที่อยู่ตามบัตรประชาชน</label>
      </div>
      {!values.isSameIdCardAddress && (
        <>
          <GroupAddress
            name={'currentAddress'}
            disabled={values.isSameIdCardAddress || disableList.includes('currentAddress')}
          />
          <CustomAddress
            addressType={'currentAddress'}
            disabled={values.isSameIdCardAddress || disableList.includes('currentAddress')}
          />
        </>
      )}
      <Hr />
      <div style={{ width: 352, display: 'flex', flexDirection: 'column', marginBottom: 24 }}>
        <label>บริษัทประกันชีวิตต้นสังกัด</label>
        <FormikField
          name="insuranceCompanyName"
          component={InsuranceCompanyNameComponent}
          options={options.insuranceCompanyNameOptions}
          isDisabled={disableList.includes('insuranceCompanyName')}
        />
        <ErrorMessage name="insuranceCompanyName" component={CustomError} />
      </div>
      {disableList.includes('agentLicenses') ? (
        <div className="infoLicenses">
          <Icon name="I" color={'var(--agt-primary-color-1)'} />
          &nbsp;
          <label>คุณสามารถตรวจสอบและปรับปรุงใบอนุญาตได้ใน</label>
          &nbsp;
          <Link to="/profile/documents">
            <b>เมนูเอกสารตัวแทน</b>
          </Link>
        </div>
      ) : (
        <FieldArray
          name="agentLicenses"
          render={(arrayHelpers) => {
            function addLicense() {
              arrayHelpers.push({
                licenseCode: '',
                licenseImageId: '',
                status: '',
                licenseType: 'ตัวแทนประกันชีวิต',
              })
            }
            return (
              <>
                {(values.agentLicenses || []).map((license, index: number) => {
                  return (
                    <LicenseContainer key={index}>
                      <Field
                        setwidth
                        label={index === 0 ? 'เลขที่ใบอนุญาตตัวแทนประกันชีวิต' : ' '}
                        name={`agentLicenses[${index}].licenseCode`}
                        className="licenseCode"
                        placeholder="โปรดระบุ"
                        disabled={license.status}
                        maxLength="10"
                        onChange={(value) => {
                          if (value) {
                            const chars = [...value]
                            const modified = chars
                              .filter((element) => /^[0-9]*$/.test(element))
                              .join('')
                            setFieldValue(`agentLicenses[${index}].licenseCode`, modified)
                          }
                        }}
                      />
                      <FormikField
                        name={`agentLicenses[${index}].licenseType`}
                        component={LicenseTypeComponent}
                        className="licenseType"
                        options={options.licenseTypeOptions}
                        // options={
                        //   index === 0 && values?.brokerType?.length === 2
                        //     ? (options?.licenseTypeOptions || []).filter((licenseTypeOption) =>
                        //         ['นายหน้าประกันชีวิต', 'นายหน้าประกันวินาศภัย'].includes(
                        //           licenseTypeOption.value,
                        //         ),
                        //       )
                        //     : options.licenseTypeOptions
                        // }
                        isDisabled={
                          index === 0 && values?.brokerType?.length === 2
                            ? true
                            : index === 1 && values?.brokerType?.length === 2
                            ? true
                            : index === 0
                            ? true
                            : false
                        }
                      />
                      <Field
                        setWidthValueContainer
                        setWidthUploadbutton
                        setWidthlicenseStatus
                        setWidthWarning
                        label=" "
                        name={`agentLicenses[${index}].licenseImageId`}
                        className="licenseImageId"
                        type="upload"
                        selectType={['PDF', 'JPG', 'JPEG', 'PNG']}
                        placeholder="อัพโหลดภาพใบอนุญาตเพื่อยืนยัน"
                        uploadDocumentPlaceholder={'ใบอนุญาต'}
                        licenseImageId={license.licenseImageId}
                        licenseStatus={license.status}
                      />
                      {!license.status && index > 0 && (
                        <LicenseDelete onClick={() => arrayHelpers.remove(index)}>
                          <FontAwesomeIcon icon={faTimes} color="var(--agt-primary-text-color)" />
                        </LicenseDelete>
                      )}
                    </LicenseContainer>
                  )
                })}
                <Hr style={{ marginTop: 24 }} />
                {(values.agentLicenses || []).length < 5 && (
                  <LicenseButton type="button" className="secondary" onClick={() => addLicense()}>
                    + เพิ่มใบอนุญาต
                  </LicenseButton>
                )}
              </>
            )
          }}
        />
      )}
    </FormContainer>
  )
}

export default UserForm
