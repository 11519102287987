import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { CustomButtonType } from './ProfilePage.type'

export const CustomModal = styled.div`
  min-width: 380px;
  padding: 0 12px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #000000;
    margin: 0px;
  }
  .name {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
  .image-frame {
    margin: 0px;
    margin-bottom: 24px;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    border-radius: 50%;
    img {
      width: 100px;
      height: 100px;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  .Profile {
    display: flex;
    flex-direction: column;
    width: calc(100% - 288px);
    height: 100%;
    padding: 32px 32px 0;
  }
  .Profile .Personal {
    padding: 25px;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 5px;
  }
  .Profile .Personal .TopSide {
    display: flex;
  }
  .Profile .Personal .BottomSide {
    width: 100%;
  }
  .Profile .Personal .Consented {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .Profile .Personal .Consented .Interested {
    display: flex;
    gap: 16px;
  }
  .Profile .Personal .Consented .Interested .icon {
  }
  .Profile .Personal .Consented .Interested .icon:hover {
    cursor: pointer;
  }
  .Profile .Personal .Consented .Interested .icon:hover + .info {
    display: flex;
  }
  .Profile .Personal .Consented .Interested .info {
    display: none;
    position: absolute;
    top: 56px;
    left: -48px;
    background: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    min-width: 272px;
    z-index: 1;
    border-radius: 8px;
  }
  .Profile .Personal .Consented .Interested .Concede {
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .Profile .Personal .Consented > p {
    color: var(--agt-primary-color-1);
    text-decoration: underline;
    cursor: pointer;
  }
  /* .Profile .Personal .BottomSide .HideBtn {
    display: block;
    width: fit-content;
    margin: 17px auto 0;
    color: var(--agt-primary-color-1);
    text-decoration: underline;
    cursor: pointer;
  } */
  .Profile .Personal .BottomSide .ConsentHistories h4 {
    padding: 24px 0 11px 0;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .Profile .Personal .BottomSide .ConsentHistories .ShowHistories {
    width: 100%;
  }
`

export const DetailContentProfile = styled.div`
  width: 100%;
  .note {
    display: flex;
  }
  .note .topic {
    padding-right: 4px;
  }
  .note .hidden {
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--agt-secondary-text-color);
    @media only screen and (max-width: 1120px) {
      max-width: 400px;
    }
  }
`

export const DetailContentExtend = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 120px;
`

export const DetailContentImage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 0 71px 20px;
  position: relative;
  .warning {
    width: 24px;
    height: 24px;
    background: var(--agt-white-color);
    position: absolute;
    top: 0;
    left: 0px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warning .circle {
    width: 16px;
    height: 16px;
    background: var(--agt-error-color);
    border-radius: 50%;
  }
  .warning .icon {
    position: absolute;
    font-size: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--agt-white-color);
  }
`

export const DetailContentOption = styled.div`
  position: absolute;
  right: 0;
  top: 112px;
  width: 160px;
  height: 48px;
`

export const DetailContentHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  border-bottom: 1px solid #a0a8bc;
  h4 {
    margin-right: 10px;
  }
  label {
    color: var(--agt-secondary-color-1);
  }
`

export const DetailContentBody = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px 0 0;
`

export const CustomFormBodyPart = styled.div`
  display: flex;
  flex-direction: column;
  &.contact_detail-left {
    width: 40%;
    max-width: 240px;
    padding-right: 30px;
  }
  &.contact_detail-right {
    width: 60%;
    padding-right: 30px;
  }
  p {
    margin: 0px;
  }
  a {
    width: 100%;
    text-decoration: none;
    overflow-wrap: break-word;
  }
  .contact_detail-address {
    color: var(--agt-secondary-color-1);
  }
  .consentBtn {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .consentBtn .consent {
    margin-right: 10px;
  }
  .consentBtn .icon {
  }
  .consentBtn .icon:hover {
    cursor: pointer;
  }
  .consentBtn .icon:hover + .info {
    display: flex;
  }
  .consentBtn .info {
    display: none;
    position: absolute;
    top: 56px;
    left: -48px;
    background: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 14px;
    min-width: 272px;
    z-index: 1;
    border-radius: 8px;
  }
  @media (max-width: 1024px) {
    .consent {
      font-size: 13px;
    }
  }
`

export const ContactDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  .rightDetail {
    width: 35%;
    min-width: 70px;
  }
  .leftDetail {
    width: 65%;
    color: var(--agt-secondary-text-color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .note {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 135px;
  }
  .two-lines {
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
  }
`

export const MoreDetail = styled.div`
  h4 {
    border-bottom: 1px solid #a0a8bc;
  }
  .profile-title {
    padding: 0px 0px 11px 0px;
  }
  .interest-title {
    padding: 40px 0px 11px 0px;
  }
`

export const MoreDetailLayer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0px 15px 0px 15px;
  padding: 15px 0px 6px 0px;
  border-bottom: 1px solid #d2d4db;
`

export const MoreDetailTitle = styled.div`
  width: 50%;
  padding-right: 10px;
  align-self: left;
  text-align: left;
`

export const MoreDetailInfo = styled.div`
  width: 50%;
  padding-left: 10px;
  align-self: right;
  text-align: right;
  .office-address {
    margin: 0px;
  }
`

export const IconStyle = styled(Icon)`
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
  margin-right: 5px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 24px 0 0;
`

export const RegisterInfo = styled.div`
  width: 188px;
  height: 48px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  background: #00aeef;
  border-radius: 5px;
  padding-left: 10.85px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: default;
  .label {
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    text-align: right;
    margin-right: 12px;
  }
  .icon {
    padding-top: 5.5px;
  }
  @media (max-width: 1024px) {
    .icon {
      display: none;
    }
    .label .hidden {
      display: none;
    }
  }
`

export const PortButton = styled.button<CustomButtonType>`
  width: 173px;
  margin-right: 24px;
  :disabled {
    cursor: not-allowed;
    color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-2)' : 'var(--agt-white-color)'};
    background-color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-1)' : 'var(--agt-tqm-pantone-1)'};
    opacity: ${(props) => (props.status === 'รอยินยอม' ? 1 : 0.3)};
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`

export const PlanningButton = styled.button<CustomButtonType>`
  width: 181px;
  :disabled {
    cursor: not-allowed;
    color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-2)' : 'var(--agt-white-color)'};
    background-color: ${(props) =>
      props.status === 'รอยินยอม' ? 'var(--agt-disable-color-1)' : 'var(--agt-tqm-pantone-1)'};
    opacity: ${(props) => (props.status === 'รอยินยอม' ? 1 : 0.3)};
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
`

export const PencilIcon = styled(IconStyle)`
  margin-right: 8px;
`

export const CustomButton = styled.button`
  position: absolute;
  top: 0px;
  right: 0px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
  path {
    fill: var(--agt-primary-color-1);
  }
  &:hover {
    path {
      fill: var(--agt-dark-sky-color);
    }
  }
  &:disabled {
    path {
      fill: var(--agt-secondary-color-1);
    }
  }
  &:disabled:hover {
    path {
      fill: var(--agt-secondary-color-1);
    }
  }
`

export const IconButton = styled(Icon)`
  margin-top: 4px;
`

export const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
