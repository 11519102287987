import React from 'react'
import { ErrorMessage, Field, useField } from 'formik'
import { DatePickerComponent } from 'components/DatePicker'
import CustomAddress from './CustomAddress'
import {
  OtherComponent,
  Selector,
  AddressComponent,
  OptionsError,
  OptionsStyles,
  Error,
} from './Form.styles'

export const OptionsComponent = (props) => {
  const {
    field,
    options,
    className = '',
    label = '',
    disabled = false,
    onChange = () => {},
    placeholder = 'เลือก',
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find(
    (select: { value: string; label: string }) => select.value === value,
  )
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: { value: string; label: string; details: {} }) => {
          setValue(option.value)
          if (onChange) onChange(option)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, meta, helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
        isErrors={meta.error && meta.touched}
      />
      {meta.error && meta.touched && <small className="error">{meta.error}</small>}
    </div>
  )
}

export const SamePerson = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label
          htmlFor="xxxx__1"
          onClick={() => {
            setValue(true)
            // if (onClick) onClick()
          }}
        >
          <input id="xxxx__1" type="radio" name={name} checked={value === true} />
          <span className="checkmark" />
          ใช่
        </label>
        <label
          htmlFor="xxxx__2"
          onClick={() => {
            setValue(false)
            // if (onClick) onClick()
          }}
        >
          <input id="xxxx__2" type="radio" name={name} checked={value === false} />
          <span className="checkmark" />
          ไม่ใช่
        </label>
      </div>
    </OtherComponent>
  )
}

export const BeneficiaryType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor={`beneficiary__${name}__1`} onClick={() => setValue(true)}>
          <input id={`beneficiary__${name}__1`} type="radio" name={name} checked={value === true} />
          <span className="checkmark" />
          ใช่
        </label>
        <label htmlFor={`beneficiary__${name}__2`} onClick={() => setValue(false)}>
          <input
            id={`beneficiary__${name}__2`}
            type="radio"
            name={name}
            checked={value === false}
          />
          <span className="checkmark" />
          ไม่ใช่
        </label>
      </div>
    </OtherComponent>
  )
}

export const PolicyType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="policy__1" onClick={() => setValue('online')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          กรมธรรม์ออนไลน์
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('original')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          กรมธรรม์ฉบับจริง
        </label>
      </div>
    </OtherComponent>
  )
}

export const InputFlightComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    maxlength,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          let upperCaseStr = e.target.value.toUpperCase()
          setValue(upperCaseStr)
        }}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        maxLength={maxlength}
        // {...field}
        onKeyPress={(e) => {
          if (/[a-zA-Z0-9]$/.test(e.key)) {
          } else {
            e.preventDefault()
          }
        }}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const InputEngNameComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    engName = false,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        // {...field}
        onKeyPress={(e) => {
          if (engName) {
            if (/[a-zA-Z]$/.test(e.key)) {
            } else {
              e.preventDefault()
            }
          } else {
            if (
              /[กขฃคฅฆงจฉชซฌญฎฏฐฑฒณดตถทธนบปผฝพฟภมยรฤลฦวศษสหฬอฮฯะัาำิีึืฺุูเแโใไๅๆ็่้๊๋์]+$$/.test(
                e.key,
              )
            ) {
            } else {
              e.preventDefault()
            }
          }
        }}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const InputComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    maxlength,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        maxlength={maxlength}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const AddressOptionsComponent = (props) => {
  const {
    field,
    options,
    className = '',
    label = '',
    disabled = false,
    placeholder = 'เลือก',
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = (options || []).find((select) => select.label === value)
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option) => {
          setValue(option.label)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const CustomAddressComponent = (props) => {
  const { label = '', name = '', options, address } = props
  return (
    <AddressComponent>
      <div className="topic">
        <b>{label}</b>
      </div>
      <Field
        label="ใช้ข้อมูลที่อยู่จาก"
        name={`${name}.aaaaaa`}
        className="aaaaaa"
        component={AddressOptionsComponent}
        options={options}
        // disabled
      />
      <Field
        label="เลขที่"
        name={`${name}.housingNo`}
        className="housingNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่"
        name={`${name}.villageNo`}
        className="villageNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่บ้าน/อาคาร"
        name={`${name}.building`}
        className="building"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ซอย/ตรอก"
        name={`${name}.lane`}
        className="land"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ถนน"
        name={`${name}.road`}
        className="road"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <CustomAddress
        className="group"
        addressType={name}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
        validate
      />
    </AddressComponent>
  )
}
