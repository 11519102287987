import React, { useState } from 'react'
import Login from './Login'
import Forgot from './Forgot'

const Index = () => {
  const [state, setState] = useState<'login' | 'forgotPassword'>('login')
  if (state === 'forgotPassword') {
    return <Forgot onBackClick={() => setState('login')} />
  }
  return <Login onForgotPasswordClick={() => setState('forgotPassword')} />
}

export default Index
