import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import IframeDashboard from 'pages/landing/dashboard'
import Dashboard from 'pages/dashboard/dashboard'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/dashboard/iframe" component={IframeDashboard} />
    <ProtectedRoute path="/dashboard" component={Dashboard} />
  </Switch>
)

export default Pages
