import * as Yup from 'yup'
import { isValidId } from 'utils/helper'

let addressSchema = Yup.object().shape({
  housingNo: Yup.string().nullable().required('กรุณาระบุเลขที่'),
  subDistrict: Yup.string().nullable().required('กรุณาระบุตำบล/แขวง'),
  district: Yup.string().nullable().required('กรุณาระบุอำเภอ/เขต'),
  province: Yup.string().nullable().required('กรุณาระบุจังหวัด'),
  postcode: Yup.string().nullable().required('กรุณาระบุรหัสไปรษณีย์'),
})

let vehicalSchema = Yup.object().shape({
  name: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  year: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleBrand: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleModel: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleSubModel: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleLicense: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleProvince: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleChassis: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
})

let prbDetailsSchema = Yup.object().shape({
  policyAddress: addressSchema,
  policyDeliveryAddress: addressSchema,
  receiptAddress: addressSchema,
  policyForm: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
})

let orderSchema = Yup.object().shape({
  prbDetails: prbDetailsSchema,
})

export let validateSchema = Yup.object().shape({
  email: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  idCard: Yup.string()
    .nullable()
    .test('is-id', 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง', (value) => {
      if (value) return isValidId(value)
      return true
    })
    .required('กรุณาระบุข้อมูล'),
  phoneNumber: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
  vehicleDetails: vehicalSchema,
  order: orderSchema,
})
