import React, { FC, useState, useEffect, useCallback } from 'react'
import api from 'api'

import Link from 'components/TextLink'
import PagingType from 'types/PagingType'
import moment from 'moment'
import { dateFormat } from 'utils/helper'
import { Container, StyledTable, FilterType, Loading, Noting } from './utils'

type AppointmentType = {
  active?: boolean
  contactId?: number | null
}
const Appointment: FC<AppointmentType> = (props) => {
  const { active, contactId } = props

  const [events, setEvents] = useState([])
  const [paging, setPaging] = useState<PagingType>({
    page: 1,
    perPage: 10,
    totalCount: 0,
    totalPages: 0,
  })
  const [isLoading, setLoading] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<FilterType>({
    dataSearch: '',
    orderBy: ['date', 'times'],
    order: ['asc', 'asc'],
    page: 1,
    perPage: 10,
  })

  const getAppointments = useCallback(
    async (contactId: number) => {
      try {
        setLoading(true)
        const params = {
          contactId: contactId,
          orders: searchValue.order,
          ordersBy: searchValue.orderBy,
        }
        const response = await api.get(`/events`, params)
        const { events, paging } = response.data.data
        setEvents(events || [])
        setPaging(
          paging || {
            page: 1,
            perPage: 10,
            totalCount: 0,
            totalPages: 0,
          },
        )
      } catch (error) {
        console.error(error.response || error)
      } finally {
        setLoading(false)
      }
    },
    [searchValue],
  )

  useEffect(() => {
    if (contactId) {
      getAppointments(contactId)
    }
  }, [contactId, searchValue, getAppointments])

  const columns = [
    {
      Header: 'หัวข้อ',
      accessor: 'title',
      cansort: false,
    },
    {
      Header: 'วันที่',
      accessor: 'date',
      canSort: true,
      Cell: ({ row }: any) => {
        const { start, end } = row.original
        const startDate = dateFormat(new Date(start))
        const endDate = dateFormat(new Date(end))
        if (startDate === endDate) {
          return startDate
        } else {
          return `${startDate} - ${endDate}`
        }
      },
    },
    {
      Header: 'เวลา',
      accessor: 'times',
      canSort: true,
      Cell: ({ row }: any) => {
        const { start, end } = row.original
        const startTime = moment(start.toString()).locale('en').format('H:mm') + ' น.'
        const endTime = moment(end.toString()).locale('en').format('H:mm') + ' น.'
        if (startTime === endTime && start === end) {
          return 'ทั้งวัน'
        } else {
          return `${startTime} - ${endTime}`
        }
      },
    },
    {
      Header: '',
      accessor: 'id',
      canSort: false,
      Cell: ({ row }: any) => {
        return <Link label="รายละเอียด" link={`/calendar/${(row.values || {}).id || ''}`} />
      },
    },
  ]

  return (
    <Container active={active}>
      {events.length === 0 ? (
        <Noting>ยังไม่มีข้อมูลการนัดหมาย</Noting>
      ) : (
        <StyledTable
          columns={columns}
          data={events}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          paging={paging}
          sortType="multi"
        />
      )}
      {isLoading && <Loading>Loading ...</Loading>}
    </Container>
  )
}

export default Appointment
