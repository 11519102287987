import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import styled from '@emotion/styled'
import { dateFormat, phoneNumberFormat } from 'utils/helper'
import moment from 'moment'
import { Link } from 'routes'
import { isNull, get } from 'lodash'
import Api from 'api'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

type FilterButtonType = {
  small?: boolean
}

const CustomIcon = styled(Icon)`
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: var(--agt-secondary-color-1);
    }
  }
`
const FilterBody = styled.div`
  width: 638px;
  display: flex;
  flex-direction: column;
  padding: 0;
  .Header {
    height: 48px;
    padding: 0 24px;
    padding-top: 12px;
    background: var(--agt-background-color-3);
    color: var(--agt-primary-color-1);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .Filter {
    padding: 16px 36px;
    background: var(--agt-background-color-1);
    display: flex;
  }
  .Filter .FullDetial {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .Filter .ShortDetail {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .Filter .Row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .Filter .Row .Column {
    width: 272px;
    display: flex;
    flex-direction: column;
  }
  .Filter .contactName {
    background: var(--agt-white-color);
    border: 1px solid var(--agt-secondary-color-1);
    box-sizing: border-box;
    border-radius: 4px;
    height: 48px;
    width: 100%;
    padding: 8px;
    label {
      background: var(--agt-background-color-3);
      border-radius: 4px;
      font-size: 13px;
      padding: 6px 8px;
    }
  }
  .Body {
    max-height: 360px;
    overflow-y: auto;
  }
  .Body .taskcard,
  .Body .eventcard {
    display: flex;
    border-bottom: 1px solid var(--agt-background-color-2);
    height: 120px;
  }
  .Body .eventcard .maincontent {
    flex: ${361 / 638};
    padding: 12px 24px;
    padding-right: 0;
    max-width: ${361}px;
    display: flex;
    .select {
      display: flex;
      align-items: center;
      width: 16px;
      height: 100%;
      margin-right: 24px;
      .round {
        width: 16px;
        height: 16px;
        position: relative;
      }
      .round label {
        cursor: pointer;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid var(--agt-secondary-color-2);
        border-radius: 4px;
      }
      .round label:after {
        content: '';
        border: 2px solid var(--agt-white-color);
        border-top: none;
        border-right: none;
        opacity: 0;
        position: absolute;
        top: 4px;
        left: 2px;
        transform: rotate(-45deg);
        width: 8px;
        height: 3px;
      }
      .round input[type='checkbox'] {
        display: none;
        visibility: hidden;
      }
      .round input[type='checkbox']:checked + label {
        background-color: var(--agt-primary-color-1);
        border: 1px solid var(--agt-primary-color-1);
      }
      .round input[type='checkbox']:checked + label:after {
        opacity: 1;
      }
    }
    .event {
      width: 100%;
      height: 100%;
      border-right: 1px solid var(--agt-background-color-2);
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .event .title {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .event .colorCode {
      width: 20px;
      height: 14px;
      border-radius: 4px;
    }
    .event .description {
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--agt-secondary-color-1);
    }
    .event .clock {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .dueDate {
        display: flex;
        align-items: center;
        color: var(--agt-secondary-color-1);
      }
      .dueDate .app-icon {
        div {
          width: 13px;
          margin-right: 4px;
          svg {
            width: 13px;
            height: 13px;
            path {
              fill: var(--agt-secondary-color-2);
            }
          }
        }
      }
    }
  }
  .Body .eventcard .subcontent {
    flex: ${(638 - 361) / 638};
    max-width: ${638 - 361}px;
    height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .eventRelates {
      display: grid;
      grid-template-columns: 95px auto;
      margin-bottom: 8px;
    }
    .eventRelates .name {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--agt-primary-text-color);
    }
    .location {
      display: flex;
      align-items: center;
      .app-icon {
        div {
          width: 13px;
          margin-right: 4px;
          svg {
            width: 13px;
            height: 13px;
          }
        }
      }
      .name {
        max-width: 190px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media (max-width: 1024px) {
          max-width: 155px;
        }
      }
    }
  }
  .Body .taskcard .taskmangement {
    flex: ${361 / 638};
    padding: 12px 24px;
    padding-right: 0;
    max-width: ${361}px;
    display: flex;
    .select {
      display: flex;
      align-items: center;
      width: 16px;
      height: 100%;
      margin-right: 24px;
      .round {
        width: 16px;
        height: 16px;
        position: relative;
      }
      .round label {
        cursor: pointer;
        position: absolute;
        width: 16px;
        height: 16px;
        border: 1px solid var(--agt-secondary-color-2);
        border-radius: 4px;
      }
      .round label:after {
        content: '';
        border: 2px solid var(--agt-white-color);
        border-top: none;
        border-right: none;
        opacity: 0;
        position: absolute;
        top: 4px;
        left: 2px;
        transform: rotate(-45deg);
        width: 8px;
        height: 3px;
      }
      .round input[type='checkbox'] {
        display: none;
        visibility: hidden;
      }
      .round input[type='checkbox']:checked + label {
        background-color: var(--agt-primary-color-1);
        border: 1px solid var(--agt-primary-color-1);
      }
      .round input[type='checkbox']:checked + label:after {
        opacity: 1;
      }
    }
    .task {
      width: 100%;
      height: 100%;
      border-right: 1px solid var(--agt-background-color-2);
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .task .name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .task .description {
      max-width: 260px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: var(--agt-secondary-color-1);
    }
    .task .clock {
      display: flex;
      justify-content: space-between;
      .dueDate {
        display: flex;
        align-items: center;
        color: var(--agt-secondary-color-1);
      }
      .dueDate .app-icon {
        div {
          width: 13px;
          margin-right: 4px;
          svg {
            width: 13px;
            height: 13px;
            path {
              fill: var(--agt-secondary-color-2);
            }
          }
        }
      }
    }
  }
  .Body .taskcard .contact {
    flex: ${(638 - 361) / 638};
    max-width: ${638 - 361}px;
    padding: 12px 0px;
    padding-left: 24px;
    display: flex;
    .personal {
      max-width: 200px;
      display: flex;
      flex-direction: column;
      padding-left: 24px;
      padding-right: 12px;
    }
    .personal .contactName {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .Selected {
    padding: 16px 0;
    border-top: 1px solid var(--agt-background-color-2);
    text-align: center;
  }
  .colorCode__control {
    height: 48px;
  }
  .colorCode__value-container {
    padding-left: 10px;
  }
  .colorCod__menu {
  }
  .colorCod__menu-list {
  }
  .colorCode__option {
    color: var(--agt-primary-text-color);
    background: var(--agt-white-color);
    :hover {
      cursor: pointer;
      background: var(--agt-background-color-1);
      color: var(--agt-primary-text-color);
    }
  }
  .colorCode__option--is-selected {
    background: var(--agt-background-color-1);
  }
  .star__control {
    height: 48px;
  }
  .star__value-container {
    padding-left: 10px;
  }
  .star__menu {
  }
  .star__menu-list {
  }
  .star__option {
    color: var(--agt-primary-text-color);
    background: var(--agt-white-color);
    :hover {
      cursor: pointer;
      background: var(--agt-background-color-1);
      color: var(--agt-primary-text-color);
    }
  }
  .star__option--is-selected {
    background: var(--agt-background-color-1);
  }
`
const FilterButton = styled.button<FilterButtonType>`
  width: ${(props) => (props.small ? '100px' : '164px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .app-icon {
    margin-right: ${(props) => (props.small ? '0px' : '8px')};
    div {
      height: 25px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
  &:hover {
    svg {
      width: 25px;
      height: 25px;
      path {
        fill: var(--agt-dark-sky-color);
      }
    }
  }
  label {
    cursor: pointer;
  }
`
const CloseButton = styled.div`
  height: 20px;
  .app-icon {
    div {
      height: 20px;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &:hover {
    cursor: pointer;
    svg {
      path {
        fill: var(--agt-secondary-color-2);
      }
    }
  }
`
const CustomClock = styled(Icon)`
  svg {
    width: 12px !important;
    height: 12px !important;
    path {
      stroke: var(--agt-secondary-color-2);
    }
  }
`
const CustomCalendar = styled(Icon)`
  svg {
    width: 12px !important;
    height: 12px !important;
    path {
      fill: var(--agt-secondary-color-2) !important;
      stroke: unset !important;
    }
  }
`

const formatOptionLabel = ({ value, label }) => (
  <>
    {isNull(value) ? (
      <div>{label}</div>
    ) : (
      <div style={{ width: '50px', height: '32px', borderRadius: '4px', background: value }} />
    )}
  </>
)

const formatOptionSvg = ({ value, label }) => (
  <>
    {isNull(value) ? (
      <div>{label}</div>
    ) : (
      <small style={{ display: 'flex' }}>
        {[...Array(value)].map((e, index) => {
          return <CustomIcon key={index} name="star" color="var(--agt-primary-text-color)" />
        })}
      </small>
    )}
  </>
)

const StartDateEventPopup = ({ startDate, endDate, allDay }) => {
  let fact: boolean = false

  if (moment(startDate).format('DD/MM/YY') === moment(endDate).format('DD/MM/YY')) {
    fact = true
  }

  return (
    <>
      {fact && !allDay ? (
        <div className="clock">
          <div className="dueDate">
            <CustomCalendar name="calendarDatePicker" color={'var(--agt-secondary-color-2)'} />
            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>
              {dateFormat(startDate)}
            </small>
          </div>
          <div className="dueDate">
            <CustomClock name="clock_white" color={'var(--agt-secondary-color-2)'} />
            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>
              {moment(startDate).format('H:mm')} น.-
              {moment(endDate).format('H:mm')} น.
            </small>
          </div>
        </div>
      ) : fact && allDay ? (
        <div className="clock">
          <div className="dueDate">
            <CustomCalendar name="calendarDatePicker" color={'var(--agt-secondary-color-2)'} />
            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>
              {dateFormat(startDate)}
            </small>
          </div>
          <div className="dueDate">
            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>ทั้งวัน</small>
          </div>
        </div>
      ) : (
        <div className="clock">
          <div className="dueDate">
            <CustomClock name="clock_white" color={'var(--agt-secondary-color-2)'} />
            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>
              {moment(startDate).format('H:mm')} น. {dateFormat(startDate)} -{' '}
              {moment(endDate).format('H:mm')} น. {dateFormat(endDate)}
            </small>
          </div>
        </div>
      )}
    </>
  )
}

const FilterCard = ({
  header = '',
  setIsOpen = (e) => {},
  setIsFilter = (e: boolean) => {},
  isFilter = false,
  contactId = '',
  contactName = '',
  events = [],
  tasks = [],
}) => {
  const defaultValue = {
    contactId: '',
    dateSearch: '',
    priorityLevel: null,
    dataSearch: '',
    colorCode: null,
  }
  const [confirmFilter, setConfirmFilter] = useState(defaultValue)
  const [eventsOptions, setEventsOptions] = useState([])
  const [tasksOptions, setTasksOptions] = useState([])
  const [defualtChecked, setDefualtChecked] = useState([])
  const [isChecked, setIsChecked] = useState<any>([])
  const [timeoutId, setTimeoutId] = useState<any>(null)

  const optionsTask = [
    { value: null, label: 'ทั้งหมด' },
    { value: 1, label: 'น้อย' },
    { value: 2, label: 'ปานกลาง' },
    { value: 3, label: 'มาก' },
  ]

  const optionsEvent = [
    { value: null, label: 'ทั้งหมด' },
    { value: '#1BC47D', label: 'สีเขียว' },
    { value: '#428FD7', label: 'สีฟ้า' },
    { value: '#FF7862', label: 'สีแดง' },
    { value: '#F1C85D', label: 'สีเหลือง' },
  ]

  useEffect(() => {
    if (contactId) {
      setConfirmFilter({ ...confirmFilter, contactId: contactId })
      optionsOpportunities({ contactId: contactId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId])

  useEffect(() => {
    if (header === 'งานที่ต้องทำ') {
      setIsChecked(tasks)
      setDefualtChecked(tasks)
    } else {
      setIsChecked(events)
      setDefualtChecked(events)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const optionsOpportunities = async (value) => {
    try {
      const response = await Api.get('/opportunities/options_selector', {
        ...value,
      })
      setEventsOptions(response.data.data.events)
      setTasksOptions(response.data.data.tasks)
    } catch (err) {
      if (err && err.response) {
        alert(err.response.data.message)
        return err.response
      }
      throw err
    }
  }

  const onChangeText = (e) => {
    let val = e.target.value
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        setConfirmFilter({ ...confirmFilter, dataSearch: val })
      }, 1500),
    )
  }

  useEffect(() => {
    if (confirmFilter.contactId) optionsOpportunities(confirmFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    confirmFilter.colorCode,
    confirmFilter.priorityLevel,
    confirmFilter.dateSearch,
    confirmFilter.dataSearch,
  ])

  return (
    <FilterBody>
      <div className="Header">
        <b>{header}</b>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '32px' }}>
            {isChecked.length > 0 && `เลือก (${isChecked.length})`}
          </div>
          <CloseButton onClick={() => setIsOpen(defualtChecked)}>
            <Icon name="close_button" />
          </CloseButton>
        </div>
      </div>
      <div className="Filter">
        {isFilter ? (
          <div className="FullDetial">
            <div className="Row">
              <div className="Column">
                <label>รายชื่อลูกค้า</label>
                <div className="contactName">
                  <label>{contactName}</label>
                </div>
              </div>
              <div className="Column">
                <label>ค้นหา</label>
                <input
                  placeholder={
                    header === 'นัดหมาย' ? 'ชื่อ, โน้ตนัดหมาย' : 'ชื่อ, โน้ตงานที่ต้องทำ'
                  }
                  onChange={(e) => {
                    onChangeText(e)
                  }}
                />
              </div>
            </div>
            <div
              className="Row"
              style={{
                alignItems: 'flex-end',
                marginBottom: 0,
              }}
            >
              <div className="Column">
                <label>{header === 'นัดหมาย' ? 'วันที่เริ่มนัดหมาย' : 'วันครบกำหนด'}</label>
                <DatePicker
                  onChange={(e) => {
                    setConfirmFilter({
                      ...confirmFilter,
                      dateSearch: moment(e).format('DD/MM/YYYY').toString(),
                    })
                  }}
                />
              </div>
              <div className="Column" style={{ width: '145px' }}>
                <Select
                  value={
                    header === 'นัดหมาย'
                      ? optionsEvent.find((select) => select.value === confirmFilter.colorCode)
                      : optionsTask.find((select) => select.value === confirmFilter.priorityLevel)
                  }
                  classNamePrefix={header === 'นัดหมาย' ? 'colorCode' : 'star'}
                  formatOptionLabel={header === 'นัดหมาย' ? formatOptionLabel : formatOptionSvg}
                  options={header === 'นัดหมาย' ? optionsEvent : optionsTask}
                  onChange={(option: any) => {
                    if (header === 'นัดหมาย')
                      setConfirmFilter({ ...confirmFilter, colorCode: option.value })
                    else setConfirmFilter({ ...confirmFilter, priorityLevel: option.value })
                  }}
                />
              </div>
              <FilterButton
                className="secondary"
                small={true}
                style={{ width: '100px' }}
                onClick={() => setIsFilter(!isFilter)}
              >
                <Icon name="filter" />
              </FilterButton>
            </div>
          </div>
        ) : (
          <div className="ShortDetail">
            <div className="contactName" style={{ maxWidth: '272px' }}>
              <label>{contactName}</label>
            </div>
            <FilterButton
              className="secondary"
              small={false}
              onClick={() => setIsFilter(!isFilter)}
            >
              <Icon name="filter" />
              <label>ตัวกรอง</label>
            </FilterButton>
          </div>
        )}
      </div>
      <div className="Body">
        {header === 'นัดหมาย'
          ? eventsOptions.map(
              (
                {
                  colorCode,
                  description,
                  endDate,
                  eventRelates = [],
                  id,
                  location,
                  startDate,
                  title,
                  allDay = false,
                },
                index,
              ) => {
                return (
                  <div className="eventcard" key={index}>
                    <div className="maincontent">
                      <div className="select">
                        <div className="round">
                          <input
                            id={`checkbox-${index}`}
                            type="checkbox"
                            checked={
                              isChecked.filter(({ id: idIsChecked }) => idIsChecked === id)
                                .length === 1
                            }
                            onClick={() => {
                              if (
                                isChecked.filter(({ id: idIsChecked }) => idIsChecked === id)
                                  .length === 1
                              ) {
                                let newChecked = isChecked.filter(
                                  ({ id: idIsChecked }) => idIsChecked !== id,
                                )
                                setIsChecked(newChecked)
                              } else {
                                let newChecked = {
                                  id: id,
                                  title: title,
                                  eventRelates: eventRelates,
                                  description: description,
                                  location: location,
                                  startDate: startDate,
                                  endDate: endDate,
                                  colorCode: colorCode,
                                }
                                setIsChecked([...isChecked, newChecked])
                              }
                            }}
                          />
                          <label htmlFor={`checkbox-${index}`} />
                        </div>
                      </div>
                      <div className="event">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div className="title" style={{ fontWeight: 'bold', paddingRight: 8 }}>
                            {title}
                          </div>
                          <div
                            className="colorCode"
                            style={{ background: `${colorCode}`, minWidth: 20 }}
                          />
                        </div>
                        <small
                          className="description"
                          style={description ? { color: 'var(--agt-primary-text-color)' } : {}}
                        >
                          {description ? description : 'ไม่ระบุรายละเอียด'}
                        </small>
                        <StartDateEventPopup
                          startDate={startDate}
                          endDate={endDate}
                          allDay={allDay}
                        />
                      </div>
                    </div>
                    <div className="subcontent">
                      <div className="eventRelates">
                        <small>บุคคลผู้ที่เข้าร่วม :&nbsp;</small>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {eventRelates.map((item, index) => {
                            if (index > 1 && index === eventRelates.length - 1) {
                              return (
                                <small key={index} className="name">
                                  +{eventRelates.length - 2}
                                </small>
                              )
                            } else if (index > 1) {
                              return null
                            }
                            return (
                              <small key={index} className="name">
                                {get(item, 'label', '')}
                              </small>
                            )
                          })}
                        </div>
                      </div>
                      <div className="location">
                        <Icon name="location" color="var(--agt-secondary-color-2)" />
                        <small className="name" style={{ color: 'var(--agt-secondary-color-2)' }}>
                          {location ? location : 'ไม่ระบุสถานที่'}
                        </small>
                      </div>
                    </div>
                  </div>
                )
              },
            )
          : tasksOptions.map(
              (
                {
                  contactId,
                  contactName,
                  description,
                  dueDate,
                  id,
                  name,
                  priorityLevel,
                  contactAvatar,
                  phoneNumber,
                  email,
                },
                index,
              ) => {
                return (
                  <div className="taskcard" key={index}>
                    <div className="taskmangement">
                      <div className="select">
                        <div className="round">
                          <input
                            id={`checkbox-${index}`}
                            type="checkbox"
                            checked={
                              isChecked.filter(({ id: idIsChecked }) => idIsChecked === id)
                                .length === 1
                            }
                            onClick={() => {
                              if (
                                isChecked.filter(({ id: idIsChecked }) => idIsChecked === id)
                                  .length === 1
                              ) {
                                let newChecked = isChecked.filter(
                                  ({ id: idIsChecked }) => idIsChecked !== id,
                                )
                                setIsChecked(newChecked)
                              } else {
                                let newChecked = {
                                  id: id,
                                  name: name,
                                  contactId: contactId,
                                  contactName: contactName,
                                  description: description,
                                  dueDate: dueDate,
                                  priorityLevel: priorityLevel,
                                  contactAvatar: contactAvatar ? contactAvatar : null,
                                  phoneNumber: phoneNumber ? phoneNumber : null,
                                  email: email ? email : null,
                                }
                                setIsChecked([...isChecked, newChecked])
                              }
                            }}
                          />
                          <label htmlFor={`checkbox-${index}`} />
                        </div>
                      </div>
                      <div className="task">
                        <div className="name" style={{ fontWeight: 'bold' }}>
                          {name}
                        </div>
                        <small
                          className="description"
                          style={description ? { color: 'var(--agt-primary-text-color)' } : {}}
                        >
                          {description ? description : 'ไม่ระบุรายละเอียด'}
                        </small>
                        <div className="clock">
                          <div className="dueDate">
                            <CustomCalendar
                              name="calendarDatePicker"
                              color="var(--agt-secondary-color-2)"
                            />
                            <small style={{ color: 'var(--agt-secondary-color-2)', fontSize: 12 }}>
                              {dateFormat(dueDate)}
                            </small>
                          </div>
                          <small style={{ display: 'flex' }}>
                            {[...Array(priorityLevel)].map((e, index) => {
                              return (
                                <CustomIcon
                                  key={index}
                                  name="star"
                                  color="var(--agt-primary-text-color)"
                                />
                              )
                            })}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="contact">
                      <Avatar type="circle" width="48px" height="48px" avatar={contactAvatar} />
                      <div className="personal">
                        <div className="contactName">
                          <Link to={`/contacts/${contactId}`} className="ghost">
                            {contactName}
                          </Link>
                        </div>
                        <small style={{ color: 'var(--agt-secondary-color-2)' }}>
                          {phoneNumber ? phoneNumberFormat(phoneNumber) : '-'}
                        </small>
                        <small style={{ color: 'var(--agt-secondary-color-2)' }}>
                          {email ? email : '-'}
                        </small>
                      </div>
                    </div>
                  </div>
                )
              },
            )}
      </div>
      <div className="Selected">
        <button
          className="primary"
          onClick={() => setIsOpen(isChecked)}
          style={{ width: '100px' }}
          disabled={isChecked === defualtChecked}
        >
          เลือก
        </button>
      </div>
    </FilterBody>
  )
}

export default FilterCard
