import React from 'react'
import styled from '@emotion/styled'
import { useTable, useExpanded } from 'react-table'
import { decamelize } from 'humps'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Icon from 'components/Icon'

type TableType = {
  className?: string
}
type FilterValues = {
  dataSearch: string
  orderBy: string | Array<string>
  order: string | Array<string>
  page: number
  perPage: number
}
type Paging = {
  page: number
  perPage: number
  totalCount: number
  totalPages: number
}
type Columns = {
  id?: string
  Header: string
  accessor: string
  canSort?: boolean
  show?: boolean
}
type TableProps = {
  className?: string
  columns: Array<Columns>
  data: Array<object>
  setSearchValue: Function
  searchValue: FilterValues
  paging: Paging
  sortType?: 'single' | 'multi'
  renderRowSubComponent?: (params: object) => JSX.Element
}
const DownIcon = styled(Icon)`
  width: 12px;
  height: 26px;

  transform-origin: center;
`
const UpIcon = styled(DownIcon)`
  transform: rotate(180deg);
`
const TableContainer = styled.div<TableType>`
  table {
    background: #ffffff;
    border-spacing: 0px;
    width: 100%;
    thead {
      width: 100%;
      tr {
        > th:first-of-type {
          text-align: center;
        }
        th {
          height: 48px;
          font-weight: normal;
          text-align: left;
          background: var(--agt-background-color-2);
          > span:first-of-type {
            margin-right: 10px;
          }
        }
      }
    }
    tbody {
      tr {
        padding: 0px 16px 0px 16px;
      }
      td {
        /* padding-top: 12px; */
        vertical-align: top;
        background: #ffffff;
        border-bottom: 1px solid var(--agt-secondary-color-1) !important;
        &:first-of-type {
          padding-left: 16px;
          padding-right: 0;
          width: 256px;
        }
        &:nth-of-type(2) {
          width: 200px;
          vertical-align: middle;
          @media (max-width: 1180px) {
            width: 180px;
          }
        }
        &:nth-of-type(3),
        &:nth-of-type(4),
        &:nth-of-type(5) {
          width: 180px;
          @media (max-width: 1180px) {
            width: 155px;
          }
        }
        &:last-of-type {
          text-align: center;
          font-size: 13px;
          color: var(--agt-secondary-color-1);
          vertical-align: middle;
        }
        .column {
          display: flex;
          flex-direction: column;
        }
        .row {
          display: flex;
          flex-direction: row;
        }
        .lineclamp {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          white-space: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .linenowrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
`
const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 24px auto;
  text-align: center;
  .agt-pagination__item {
    margin-right: 10px;
  }
  .agt-pagination__nav {
    display: inline-block;
    align-items: baseline;
    width: 42px;
    height: 48px;
    padding: 0;
    text-align: center;
  }
  .agt-pagination__input {
    width: 60px;
    height: 48px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid var(--agt-secondary-color-1);
  }
  .agt-pagination__select {
    width: 100px;
    height: 32px;
    padding: 0 10px;
    border-radius: 5px;
    border: 1px solid var(--agt-primary-text-color);
  }
`
const generateSortingIndicator = (
  column: string,
  searchValue: FilterValues,
  sortType: 'single' | 'multi',
) => {
  if (sortType === 'single') {
    if (decamelize(column) !== searchValue.orderBy) return ''
    return searchValue.order === 'asc' ? (
      <DownIcon name="vector_bottom" />
    ) : (
      <UpIcon name="vector_bottom" />
    )
  }
  if (sortType === 'multi') {
    let index = -1
    if (typeof searchValue.orderBy === 'object') {
      index = searchValue.orderBy.indexOf(decamelize(column))
    }
    if (decamelize(column) !== searchValue.orderBy[index] || !searchValue.order[index]) return ''
    return searchValue.order[index] === 'asc' ? (
      <DownIcon name="vector_bottom" />
    ) : (
      <UpIcon name="vector_bottom" />
    )
  }
}

const Table = ({
  className = '',
  columns: userColumns,
  data,
  setSearchValue,
  searchValue,
  paging,
  sortType = 'single',
  renderRowSubComponent,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns: userColumns,
      data,
      initialState: {
        hiddenColumns: ['avatar', 'occupation', 'remark', 'taskDueDate'],
      },
    },
    useExpanded,
  )
  const { page, totalPages } = paging
  return (
    <TableContainer className={className || ''}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return column.render('Header') === 'id' ? null : (
                  <th
                    {...column.getHeaderProps()}
                    onClick={() => {
                      if (column.render('canSort')) {
                        if (sortType === 'single') {
                          setSearchValue({
                            ...searchValue,
                            page: 1,
                            orderBy:
                              decamelize(column.id) === 'name'
                                ? 'first_name'
                                : decamelize(column.id),
                            order: searchValue.order === 'desc' ? 'asc' : 'desc',
                          })
                        }
                        if (sortType === 'multi') {
                          let newOrder = new Array<string>()
                          let index = -1
                          if (typeof searchValue.order === 'object') {
                            newOrder = [...searchValue.order]
                          }
                          if (typeof searchValue.orderBy === 'object') {
                            index = searchValue.orderBy.indexOf(column.id)
                          }
                          newOrder[index] = searchValue.order[index] === 'desc' ? 'asc' : 'desc'
                          setSearchValue({
                            ...searchValue,
                            page: 1,
                            order: newOrder,
                          })
                        }
                      }
                    }}
                  >
                    <span>{column.render('Header')}</span>
                    {generateSortingIndicator(column.id, searchValue, sortType)}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row)
            const rowProps = row.getRowProps()
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...rowProps}>
                  {row.cells.map((cell) => {
                    if (cell.render('Header') === 'id') return null
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={
                          row.isExpanded
                            ? {
                                background: 'var(--agt-background-color-1)',
                                color: 'var(--agt-primary-color-1)',
                              }
                            : {}
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
                {row.isExpanded && renderRowSubComponent && (
                  <tr>
                    <td style={{ padding: '0px' }} colSpan={visibleColumns.length}>
                      {renderRowSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
      <Pagination className="agt-pagination agt-pagination__container">
        <div className="agt-pagination__item">หน้า</div>
        <input
          className="agt-pagination__item agt-pagination__input"
          type="number"
          defaultValue={page}
          value={page}
          onChange={(e) =>
            setSearchValue({
              ...searchValue,
              page: e.target.value,
            })
          }
          min="1"
          step="1"
          onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
        />
        <div className="agt-pagination__item">จาก {totalPages}</div>
        <button
          className="agt-pagination__item agt-pagination__nav"
          style={{ marginRight: '2px' }}
          onClick={() =>
            setSearchValue({
              ...searchValue,
              page: page - 1,
            })
          }
          disabled={page <= 1}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <button
          className="agt-pagination__item agt-pagination__nav"
          onClick={() =>
            setSearchValue({
              ...searchValue,
              page: page + 1,
            })
          }
          disabled={page >= totalPages}
        >
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
      </Pagination>
    </TableContainer>
  )
}

export default Table
