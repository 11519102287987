import React, { FC, useMemo } from 'react'
import { get } from 'lodash'

import Mask from './Mask'
import Header from './Header'
import Footer from 'components/Layout/Footer'
import Tabs from 'components/Layout/Tabs'
import { Body, BodyContainer, Container } from './index.styles'
import { LayoutProps } from './index.type'

const Layout: FC<LayoutProps> = ({ currentAgent, children }) => {
  const TabsSlide = useMemo(() => {
    let role: string = get(currentAgent, 'agentType', '')
    if (['guest', 'affiliate', 'tqm_staff', 'broker'].includes(role)) return <Tabs />
    else return null
  }, [currentAgent])

  return (
    <>
      <Mask />
      <Container>
        <BodyContainer>
          <Header currentAgent={currentAgent} />
          {TabsSlide}
          <Body>{children}</Body>
        </BodyContainer>
      </Container>
      <Footer />
    </>
  )
}

const defaultProps: LayoutProps = {
  appName: 'Agent Tools',
  currentAgent: null,
}

Header.defaultProps = defaultProps

export default Layout
