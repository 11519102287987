import React from 'react'
import { CustomModal } from './AssetDetail.styles'
import Avatar from 'components/Avatar'
import Modal from 'components/Modal'
import { DeleteModalComponentType } from './AssetDetail.type'

export const DeleteModalComponent = ({
  isDelete,
  setIsDelete,
  contact,
  confirmDelete,
}: DeleteModalComponentType) => {
  return (
    <Modal
      isOpen={isDelete}
      onConfirm={() => confirmDelete()}
      onCancel={() => setIsDelete(false)}
      hiddenButton={!contact?.canDeleted}
    >
      <CustomModal>
        <Avatar avatar={contact?.avatar || ''} width="100px" height="100px" type="circle" />
        {contact?.canDeleted ? (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลอื่น (นัดหมาย, งานถัดไป, โอกาสในการขาย)</p>
            <p>
              ที่เกี่ยวข้องกับ{' '}
              <span className="name">
                {contact?.name && contact?.name?.split(' ')[0]}{' '}
                {contact?.name && contact?.name.split(' ')[1]}
              </span>{' '}
              จะถูกลบไปด้วย
            </p>
            <p>คุณแน่ใจหรือไม่ว่าต้องการลบ?</p>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลติดต่อนี้มีข้อมูลคำสั่งซื้อแล้ว</p>
            <p className="name">
              {contact?.name && contact?.name?.split(' ')[0]}{' '}
              {contact?.name && contact?.name.split(' ')[1]}
            </p>
            <p>ไม่สามารถลบได้</p>
          </div>
        )}
      </CustomModal>
    </Modal>
  )
}
