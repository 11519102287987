import React, { FC, useState, useEffect, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Table from 'components/Table'
import { useDispatch, useSelector } from 'react-redux'
import { getContacts, getOptions, getWaitConsent, patchStatus } from 'store/contactSlice'
import { RootState } from 'store/rootReducer'
import Avatar from 'components/Avatar'
import { dateFormat, dayOfWeek, phoneNumberFormat } from 'utils/helper'
import { FilterValues, initialSearchValues, statusOptions } from './index.utils'
import WebTour from './WebTour'
import { isObject } from 'lodash'
import {
  Container,
  FirstColumn,
  NameAndOccupation,
  ResponsiveDiv,
  Selector,
  customSelectStyles,
  customSelectDisabledStyles,
} from './index.styles'
import { SearchComponent, SelectComponent } from './index.components'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ContactInfo: FC = () => {
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const { contacts, paging, options, initialized, haveConsented } = useSelector(
    (state: RootState) => state.contacts,
  )
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [isAgree, setIsAgree] = useState<boolean>(false)
  const [textData, setTextData] = useState<string>('')
  const [searchValue, setSearchValue] = useState<FilterValues>(initialSearchValues)

  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])

  useEffect(() => {
    dispatch(getContacts(searchValue))
  }, [dispatch, searchValue])

  useEffect(() => {
    if (isAgree) {
      dispatch(getWaitConsent())
    } else {
      dispatch(getContacts(initialSearchValues))
    }
  }, [dispatch, isAgree])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['contacts']['contacts'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({
            ...storage,
            contacts: { ...storage.contacts, contacts: true },
          }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: true, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  const columns = useMemo(
    () => [
      {
        Header: 'occupation',
        accessor: 'occupation',
        canSort: false,
      },
      {
        Header: 'id',
        accessor: 'id',
        canSort: false,
      },
      {
        Header: 'ชื่อลูกค้า',
        accessor: 'name',
        canSort: true,
        Cell: ({ row }: any) => {
          return (
            <FirstColumn>
              {isObject(row.original['currentAgreement']) && row.original['isConsented'] === true && (
                <div className="warning">
                  <span className="circle"></span>{' '}
                  <FontAwesomeIcon icon={faExclamation} className="icon" />
                </div>
              )}
              <Avatar avatar={row.original['avatar']} type="circle" />
              <NameAndOccupation>
                <Link to={`/contacts/${row.original['id']}`} className="ghost">
                  <div className="name">{row.original['name']}</div>
                </Link>
                <small
                  className="occupation"
                  style={
                    row.original['occupation']
                      ? { color: 'var(--agt-primary-text-color)' }
                      : { color: 'var(--agt-secondary-color-1)' }
                  }
                >
                  {row.original['occupation'] ? row.original['occupation'] : 'ไม่ระบุอาชีพ'}
                </small>
                <small>{phoneNumberFormat(row.original['phoneNumber'])}</small>
              </NameAndOccupation>
            </FirstColumn>
          )
        },
      },
      {
        Header: 'สถานะลูกค้า',
        accessor: 'status',
        canSort: false,
        Cell: ({ row }) => {
          let defaultSeletor = (options.statusOptions || statusOptions).find(
            (statusOption) => statusOption.value === row.values.status,
          )
          if (defaultSeletor) {
          } else {
            defaultSeletor = { value: 'รอเสนองาน', label: 'รอเสนองาน' }
          }
          return (
            <ResponsiveDiv>
              <div className="status">
                <Selector
                  value={defaultSeletor}
                  onChange={(option) => {
                    dispatch(patchStatus(row.original.id, { status: option.value }, searchValue))
                  }}
                  options={(options.statusOptions || statusOptions).filter(
                    (statusOption) => statusOption.value !== 'รอยินยอม',
                  )}
                  styles={
                    defaultSeletor.value === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'
                      ? customSelectDisabledStyles
                      : customSelectStyles
                  }
                  placeholder="โปรดระบุ"
                  isDisabled={
                    defaultSeletor.value === 'รอยินยอม' || currentAgent?.statusTh === 'ระงับ'
                  }
                />
              </div>
            </ResponsiveDiv>
          )
        },
      },
      {
        Header: 'โอกาสในการขาย',
        accessor: 'opportunity',
        canSort: true,
        Cell: ({ row }: any) => (
          <ResponsiveDiv>
            <div className="opportunity">
              {row.values['opportunity'] ? (
                <div className="column">
                  <small className="lineclamp" style={{ fontWeight: 'bold' }}>
                    {row.values['opportunity'].name ? row.values['opportunity'].name : '-'}
                  </small>
                  {row.values['opportunity'].otherCount > 0 && (
                    <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                      +{row.values['opportunity'].otherCount}
                    </small>
                  )}
                </div>
              ) : (
                <span
                  style={{
                    color: 'var(--agt-primary-text-color-1)',
                  }}
                >
                  -
                </span>
              )}
            </div>
          </ResponsiveDiv>
        ),
      },
      {
        Header: 'นัดหมายถัดไป',
        accessor: 'event',
        canSort: false,
        Cell: ({ row }: any) => (
          <ResponsiveDiv>
            <div className="event">
              <div className="column">
                <small className="linenowrap" style={{ fontWeight: 'bold' }}>
                  {row.original['event'].name ? row.original['event'].name : '-'}
                </small>
                {row.original['event'].startDate && (
                  <div className="row" style={{ alignItems: 'center' }}>
                    <small>
                      {`${dayOfWeek(row.original['event'].startDate)} ${dateFormat(
                        row.original['event'].startDate,
                      )}`}
                    </small>
                    <div
                      style={{
                        background: `${row.original['event'].colorCode}`,
                        borderRadius: '4px',
                        width: '20px',
                        height: '13px',
                        marginLeft: '8px',
                      }}
                    />
                  </div>
                )}
                {row.values['event'].otherCount > 0 && (
                  <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                    +{row.values['event'].otherCount}
                  </small>
                )}
              </div>
            </div>
          </ResponsiveDiv>
        ),
      },
      {
        Header: 'งานถัดไป',
        accessor: 'task',
        canSort: false,
        Cell: ({ row }: any) => {
          return (
            <ResponsiveDiv>
              <div className="task">
                {row.values['task'].name ? (
                  <div className="column">
                    <small
                      className="linenowrap"
                      style={{
                        color: 'var(--agt-primary-color-text-1)',
                        fontWeight: 'bold',
                      }}
                    >
                      {row.values['task'].name}
                    </small>
                    <small
                      className="lineclamp"
                      style={
                        row.values['task'].description
                          ? {
                              color: 'var(--agt-primary-text-color-1)',
                            }
                          : { color: 'var(--agt-secondary-color-1)' }
                      }
                    >
                      {row.values['task'].description
                        ? row.values['task'].description
                        : 'ไม่ระบุรายละเอียด'}
                    </small>
                  </div>
                ) : (
                  <span
                    style={{
                      color: 'var(--agt-primary-text-color-1)',
                    }}
                  >
                    -
                  </span>
                )}
              </div>
            </ResponsiveDiv>
          )
        },
      },
      {
        Header: 'วันที่ได้ข้อมูล',
        accessor: 'createdAt',
        canSort: true,
        Cell: ({ row }: any) => dateFormat(row.values['createdAt']),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, searchValue, options],
  )

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <SearchComponent
        setIsShowHint={setIsShowHint}
        setTextData={setTextData}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        textData={textData}
      />
      <SelectComponent
        paging={paging}
        currentAgent={currentAgent}
        isAgree={isAgree}
        setIsAgree={setIsAgree}
        haveConsented={haveConsented}
      />
      {initialized ? (
        <p className="isLoading">loading...</p>
      ) : (
        <Table
          columns={columns}
          data={contacts || []}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          paging={paging}
        />
      )}
    </Container>
  )
}

export default ContactInfo
