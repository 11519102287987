import * as Yup from 'yup'

const VehicleSchema = Yup.object().shape({
  insureLevel: Yup.string().required('กรุณาเลือก'),
  year: Yup.string().required('กรุณาเลือก'),
  makeCode: Yup.string().required('กรุณาเลือก'),
  familyCode: Yup.string().required('กรุณาเลือก'),
  vehicleSubModel: Yup.string().required('กรุณาเลือก'),
})

export default VehicleSchema
