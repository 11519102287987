import React, { FC, useEffect } from 'react'
import { Formik } from 'formik'
import { useParams, useHistory } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
// import OldCreateForm from 'pages/insurance_portfolio/components/InsurancePortForm'
import NewCreateForm from 'pages/insurance_portfolio/components/NewPortForm'

import HistoryType from 'types/HistoryType'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { createInsurance, getContactById } from 'store/contactSlice'
import initialValues from './newInsurance.utils'
import { Container, Header, SubHeader } from './index.styles'
import moment from 'moment'
import { schema } from './index.validation'

const CreatePortfolio: FC = () => {
  let { id }: { id: string } = useParams()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    {
      path: `/contacts/${id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: `/contacts/${id}/insurance_portfolio`,
      label: 'สรุปกรมธรรม์',
    },
    { label: 'เพิ่มข้อมูลประกันที่ถืออยู่' },
  ]
  useEffect(() => {
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Container>
      <Header>เพิ่มข้อมูลประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <SubHeader>ข้อมูลกรมธรรม์</SubHeader>
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          ownerName: contact?.name || '',
          ownerGender: contact?.gender || '',
          ownerAge: contact?.birthdate
            ? moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY').diff(
                moment(moment(contact.birthdate).format('DD-MM-YYYY'), 'DD-MM-YYYY'),
                'years',
              )
            : 0,
          birthdate: contact?.birthdate ? moment(contact.birthdate).format('DD-MM-YYYY') : null,
        }}
        onSubmit={(values) => {
          dispatch(
            createInsurance(id, values, () => {
              history.push(`/contacts/${id}/insurance_portfolio`)
            }),
          )
        }}
        validationSchema={schema}
      >
        {(formikProps) => {
          return (
            <>
              {/* Old Form */}
              {/* <OldCreateForm
                handleSubmit={props.handleSubmit}
                values={props.values}
                errors={props.errors}
                touched={props.touched}
                isSubmitting={props.isSubmitting}
              /> */}
              {/* Old Form */}

              {/* New Form */}
              <NewCreateForm
                handleSubmit={formikProps.handleSubmit}
                values={formikProps.values}
                errors={formikProps.errors}
                touched={formikProps.touched}
                isSubmitting={formikProps.isSubmitting}
              />
              {/* New Form */}
            </>
          )
        }}
      </Formik>
    </Container>
  )
}
export default CreatePortfolio
