import styled from '@emotion/styled'

export const CustomModal = styled.div`
  width: 100%;
  min-width: 736px;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0 34px;
    border-bottom: 1px solid var(--agt-background-color-2);
  }
  img {
    width: 230px;
    margin-bottom: -8px;
    object-fit: cover;
  }
  button {
    margin: 0 auto;
  }
  .content {
    width: 100%;
    padding: 24px 96px;
    display: flex;
    flex-direction: column;
  }
  .content .name {
    display: flex;
    margin-bottom: 24px;
    font-weight: bold;
    width: fit-content;
    color: var(--agt-primary-color-1);
  }
  .content .consents {
    width: 100%;
    height: 100%;
    max-height: 30vh;
    white-space: pre-wrap;
    overflow-y: auto;
    margin-bottom: 36px;
  }
  ul {
    list-style: none;
    margin-bottom: 16px;
  }
  ul li {
    margin-bottom: 8px;
  }
  ul li label {
    margin-bottom: 0;
  }
  ul li:last-of-type {
    margin-bottom: 0;
  }
  .checked {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: var(--agt-primary-color-1);
    overflow-wrap: anywhere;
  }
  .checked .square {
    width: 16px;
    min-width: 16px;
    height: 16px;
    /* background: var(--agt-primary-color-1); */
    border: 1px solid var(--agt-secondary-color-2);
    border-radius: 4px;
    position: relative;
  }
  .checked .square .faCheck {
    color: var(--agt-white-color);
    opacity: 0;
    position: absolute;
    font-size: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .checked input {
    display: none;
  }
  .checked input:checked ~ .square {
    background: var(--agt-primary-color-1);
  }
  .checked input:checked ~ .square .faCheck {
    opacity: 1;
  }
  .checked:last-of-type {
  }
`

export const Container = styled.div`
  width: 100%;
  section {
    padding: 16px 24px 0 24px;
  }
  .consents {
    margin-bottom: 24px;
  }
  .consents:last-of-type {
    margin-bottom: 0px;
  }
  .consents p {
    width: fit-content;
    text-decoration: underline;
    color: var(--agt-primary-color-1);
    cursor: pointer;
  }
  .consents p:hover {
    color: var(--agt-dark-sky-color);
  }
  .consents small {
    color: var(--agt-secondary-color-1);
  }
`
