import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  max-width: 736px;
  padding: 60px 0 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .name {
    align-self: flex-start;
    color: var(--agt-primary-color-1);
    margin-bottom: 40px;
  }
  .contact {
    display: flex;
    margin-bottom: 50px;
  }
  .contact .detail {
    padding: 0 14px;
    display: flex;
    flex-direction: column;
  }
  .contact .detail .contactName {
    color: var(--agt-primary-color-1);
  }
  form {
    width: 100%;
  }
  form .content {
    margin-bottom: 56px;
  }
  form .consent {
    color: var(--agt-primary-color-1);
    display: flex;
  }
  form .consent input {
    width: 16px;
    height: 16px;
    margin-top: 4px;
    margin-right: 16px;
  }
  form .consent .text {
    display: flex;
    flex-direction: column;
    margin-bottom: 48px;
  }
`

export const Error = styled.small`
  color: var(--agt-error-color);
`
