import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { get } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import Loader from 'components/BackgroundLoading'
import InsuranceDetail from '../InsuranceDetail'
import DropleadForm from '../form'
import { Formik } from 'formik'
import {
  createOrder,
  getOrder,
  getSearchByTypeTa,
  getTravelOptions,
} from 'store/travelInsuranceSlice'
import { Container, Header, Content } from './Edit.styles'
import { DropleadTypes } from '../index.type'
import { ParamsType } from './Edit.type'
import HistoryType from 'types/HistoryType'
import DropleadSchema from '../index.validate'

const EditTravel: FC = () => {
  let { id, order_id: orderId } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { optionsByTypeTa, loading, options, order } = useSelector(
    (state: RootState) => state.travel,
  )
  const dispatch = useDispatch()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    // { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    // { path: `/opportunities/${id}/catalog/travel`, label: 'ประกันเดินทาง' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  const onSaving = (values: DropleadTypes) => {
    let params = { ...values }
    // console.log('params....', params)
    dispatch(
      createOrder(id, params, () => {
        history.push('/opportunities')
      }),
    )
  }

  const insuranceData = useMemo(() => {
    if (order) {
      return { ...order.order.taInsurance.productDetails }
    } else {
      return {
        companyLogoUrl: '',
        companyName: '',
        netAmount: '',
        minAge: '',
        maxAge: '',
        coverDetail: null,
        insureLevel: '',
        travelTypeCountry: '',
      }
    }
  }, [order])

  const defaultOrder = useMemo(() => {
    if (order) {
      return {
        name: order.order?.name || '',
        taDetails: { ...order.order.taInsurance },
        orderId: order.order.id,
      }
    } else {
      return {
        name: '',
        taDetails: {
          countryId: '',
          travelCountryFrom: '',
          travelCountryTo: '',
          travelDateFrom: '',
          travelDateTo: '',
          travelPassenger: '',
          travelType: '',
          travelTypeCountry: '',
          travelBy: '',
          orderPolicyBeneficiary: [],
          policyForm: 'online',
          outboundFlightNumber: '',
          returnFlightNumber: '',
          productDetails: {},
        },
      }
    }
  }, [order])

  useEffect(() => {
    if (id && orderId) {
      dispatch(getNewOpportunityById(id))
      dispatch(getOrder(id, orderId))
      dispatch(getTravelOptions(id))
    }
  }, [dispatch, id, orderId])

  useEffect(() => {
    let travelType = defaultOrder?.taDetails?.travelType || null
    let travelTypeCountry = defaultOrder?.taDetails?.travelTypeCountry || null
    if (id && travelType && travelTypeCountry)
      dispatch(
        getSearchByTypeTa(id, {
          travelType: travelType,
          travelTypeCountry: travelTypeCountry,
        }),
      )
  }, [dispatch, id, defaultOrder.taDetails.travelType, defaultOrder.taDetails.travelTypeCountry])

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {(loading.options || loading.search || loading.submit) && <Loader />}
      <Content>
        <InsuranceDetail data={insuranceData} />
        <Formik
          enableReinitialize
          initialValues={{
            type: 'update',
            order: {
              ...defaultOrder,
              taDetails: {
                ...defaultOrder.taDetails,
                outboundFlightNumber: defaultOrder.taDetails?.outboundFlightNumber || '',
                returnFlightNumber: defaultOrder.taDetails?.returnFlightNumber || '',
                policyAddress: order?.order?.taInsurance.policyAddress
                  ? { ...order.order.taInsurance.policyAddress, aaaaaa: 'ระบุเอง' }
                  : {
                      aaaaaa: 'ระบุเอง',
                      housingNo: '',
                      villageNo: '',
                      building: '',
                      lane: '',
                      road: '',
                      subDistrict: '',
                      district: '',
                      province: '',
                      postcode: '',
                    },
              },
            },
          }}
          validationSchema={DropleadSchema}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              type: 'droplead',
            }
            // console.log('modifiedValues', modifiedValues)
            dispatch(
              createOrder(id, { ...modifiedValues }, () => history.push(`/opportunities/${id}/`)),
            )
          }}
        >
          {(props) => {
            return (
              <DropleadForm
                id={id}
                handleSubmit={props.handleSubmit}
                values={props.values}
                options={options}
                optionsByTypeTa={optionsByTypeTa}
                onSaving={onSaving}
              />
            )
          }}
        </Formik>
      </Content>
    </Container>
  )
}

export default EditTravel
