import React from 'react'
import AddressType from 'types/AddressType'
import { Label, Value } from './index.styles'

export const DisplayValue = ({ className, title, values }) => {
  return (
    <div className={className}>
      <Label>{title}</Label>
      <Value>{values}</Value>
    </div>
  )
}

export function displayAddress(address: AddressType) {
  return (
    <>
      {address?.housingNo && `${address.housingNo} `}
      {address?.villageNo && `หมู่ ${address.villageNo} `}
      {address?.building && `หมู่บ้าน/อาคาร ${address.building} `}
      {address?.lane && `ซอย/ตรอก ${address.lane} `}
      {address?.road && `ถนน ${address.road} `}
      {address?.subDistrict && `ตำบล/แขวง ${address.subDistrict} `}
      {address?.district && `อำเภอ/เขต ${address.district} `}
      {address?.province}
      {` `}
      {address?.postcode}
    </>
  )
}
