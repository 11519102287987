import React, { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import TableComponet from './work'
import TaskComponent from './Tasks'
import Avatar from 'components/Avatar'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { getAge } from 'utils/helper'
import { ProfilePageProps } from 'types/ContactType'
import ShowHistoriesConsent from 'components/ShowHistoriesConsent'
import { RootState } from 'store/rootReducer'
import { Link } from 'react-router-dom'
import {
  Container,
  Content,
  DetailContentProfile,
  DetailContentHeader,
  DetailContentBody,
  DetailContentExtend,
  DetailContentOption,
  DetailContentImage,
  CustomButton,
  IconButton,
  MoreDetail,
} from './ProfilePage.styles'
import {
  DeleteModalComponent,
  HeaderComponent,
  ContactDetailLeftSide,
  ContactDetailRightSide,
  MoreDetailProfile,
  MoreDetailCompoent,
  AuthorizeMenu,
} from './ProfilePage.components'
import { isObject } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/free-solid-svg-icons'

const ProfilePage: FC<ProfilePageProps> = ({
  contact,
  consentCurrentVersion,
  lastConsentHistory,
  getContactByIdAgain,
  setIsShowHint,
  confirmDelete,
}) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isMoreDetail, setIsMoreDetail] = useState<boolean>(false)
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
  ]

  return (
    <Container>
      <DeleteModalComponent
        isDelete={isDelete}
        setIsDelete={setIsDelete}
        contact={contact}
        confirmDelete={confirmDelete}
      />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <div className="Profile">
          <div id="contact-id__personal" className="Personal">
            <div className="TopSide">
              <DetailContentProfile>
                <DetailContentHeader>
                  <h4 id="contact-id__modal" className="name">
                    {contact?.name}
                  </h4>
                  <label className="occupation">
                    {contact?.birthdate &&
                      `(${getAge(moment(contact?.birthdate))} ปี) ${
                        contact?.occupation ? '-' : ''
                      } `}
                    {contact?.occupation}
                  </label>
                </DetailContentHeader>
                <DetailContentBody>
                  <ContactDetailLeftSide
                    contact={contact}
                    consentCurrentVersion={consentCurrentVersion}
                    getContactByIdAgain={getContactByIdAgain}
                  />
                  <ContactDetailRightSide contact={contact} />
                </DetailContentBody>
                {isObject(contact?.currentAgreement || null) &&
                contact?.isConsented === false ? null : (
                  <div className="note">
                    <div className="topic">โน้ต</div>
                    <span className="hidden">{contact && contact.note ? contact.note : '-'}</span>
                  </div>
                )}
              </DetailContentProfile>
              <DetailContentExtend>
                <DetailContentImage>
                  {isObject(contact?.currentAgreement) && contact?.isConsented && (
                    <div className="warning">
                      <span className="circle"></span>
                      <FontAwesomeIcon icon={faExclamation} className="icon" />
                    </div>
                  )}
                  <Avatar
                    avatar={contact?.avatar || ''}
                    width="100px"
                    height="100px"
                    type="circle"
                  />
                </DetailContentImage>
                <DetailContentOption>
                  <Link
                    to={`/contacts/${contact?.id}/edit`}
                    style={currentAgent?.statusTh === 'ระงับ' ? { pointerEvents: 'none' } : {}}
                  >
                    <CustomButton
                      id={'contact-id__edit-icon'}
                      type="button"
                      className="secondary"
                      style={{ right: '58px' }}
                      disabled={currentAgent?.statusTh === 'ระงับ'}
                    >
                      <IconButton name="pencil_icon" />
                    </CustomButton>
                  </Link>
                  <CustomButton
                    type="button"
                    className="secondary"
                    onClick={() => setIsDelete(true)}
                    disabled={currentAgent?.statusTh === 'ระงับ'}
                  >
                    <IconButton name="bin" />
                  </CustomButton>
                </DetailContentOption>
              </DetailContentExtend>
            </div>
            {isObject(contact?.currentAgreement || null) &&
            contact?.isConsented === false ? null : (
              <>
                {isMoreDetail ? (
                  <div className="BottomSide">
                    <MoreDetail>
                      <MoreDetailProfile contact={contact} />
                      {/* <MoreDetailInteresting contact={contact} /> */}
                    </MoreDetail>
                    {lastConsentHistory && (
                      <div className="ConsentHistories">
                        <h4>ข้อตกลงและการขออนุญาตที่ใช้งาน</h4>
                        <ShowHistoriesConsent
                          contactId={contact?.id || 0}
                          className="ShowHistories"
                          consents={[lastConsentHistory]}
                          type="contact"
                        />
                      </div>
                    )}
                    <MoreDetailCompoent
                      className=""
                      label="ซ่อนรายละเอียด"
                      setIsMoreDetail={setIsMoreDetail}
                      isMoreDetail={isMoreDetail}
                      currentAgreement={contact?.currentAgreement || null}
                      consentCurrentVersion={consentCurrentVersion || ''}
                      contact={contact}
                      getContactByIdAgain={getContactByIdAgain}
                    />
                  </div>
                ) : (
                  <MoreDetailCompoent
                    className="BottomSide"
                    label="ดูรายละเอียดเพิ่มเติม"
                    setIsMoreDetail={setIsMoreDetail}
                    isMoreDetail={isMoreDetail}
                    currentAgreement={contact?.currentAgreement || null}
                    consentCurrentVersion={consentCurrentVersion || ''}
                    contact={contact}
                    getContactByIdAgain={getContactByIdAgain}
                  />
                )}
              </>
            )}
          </div>
          <AuthorizeMenu
            contactId={contact?.id}
            contact={contact}
            getContactByIdAgain={getContactByIdAgain}
            currentAgent={currentAgent}
          />
          <TableComponet
            contactId={contact?.id || null}
            contactData={contact}
            currentAgent={currentAgent}
          />
        </div>
        <TaskComponent
          contactId={contact?.id || null}
          contactData={contact}
          currentAgent={currentAgent}
        />
      </Content>
    </Container>
  )
}

export default ProfilePage
