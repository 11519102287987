import merge from 'lodash/merge'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import th from 'date-fns/locale/th'

import { DAYS_IN_WEEK_TH, MONTH_TH } from 'components/Inputs/DatePicker/DatePicker.utils'
import { SummitedAtValue } from 'types/DatePickerType'
import { SearchValuesType } from 'types/DashboardType'
import camelCase from 'lodash/camelCase'

const INSURANCE_DATASETS = [
  {
    label: 'ชีวิต',
    value: 'life',
    backgroundColor: '#00529A',
    borderColor: '#00529A',
    maxBarThickness: 24,
    minBarLength: 16,
  },
  {
    label: 'สุขภาพ',
    value: 'health',
    backgroundColor: '#45DAAE',
    borderColor: '#45DAAE',
    maxBarThickness: 24,
    minBarLength: 16,
  },
  {
    label: 'รถยนต์',
    value: 'motor',
    backgroundColor: '#3ABAF8',
    borderColor: '#3ABAF8',
    maxBarThickness: 24,
    minBarLength: 16,
  },
  {
    label: 'อัคคีภัย',
    value: 'fire',
    backgroundColor: '#7E5FFF',
    borderColor: '#7E5FFF',
    maxBarThickness: 24,
    minBarLength: 16,
  },
]

function prepareData(labels: Array<any>, value: any, params: SearchValuesType) {
  try {
    const { insuranceType, premium, summary } = params
    const parsedValue: Array<any> = Object.keys(value)
      .sort()
      .map((e) => value[e])
    if (labels.length !== parsedValue.length) {
      return {
        labels,
        datasets: [],
      }
    }
    const filteredData =
      insuranceType === 'all'
        ? INSURANCE_DATASETS
        : INSURANCE_DATASETS.filter(
            (insuranceDatasets) => insuranceDatasets.value === insuranceType,
          )
    const datasets = filteredData.map((initialized) => ({
      ...initialized,
      data: labels.map((_, i) => {
        return (
          parseInt(
            parsedValue[i][`${initialized.value}`][camelCase(`${premium}`)][`${summary}`],
            10,
          ) || null
        )
      }),
    }))
    return {
      labels,
      datasets,
    }
  } catch (error) {
    return {
      labels,
      datasets: [],
    }
  }
}

const DEFAULT_OPTIONS = {
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      bodyFont: {
        family: "'NotoSansThai', 'NotoSans', sans-serif",
      },
      titleFont: {
        family: "'NotoSansThai', 'NotoSans', sans-serif",
      },
    },
  },
  responsive: true,
}

const dateBarOptions = merge({}, DEFAULT_OPTIONS, {
  scales: {
    x: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
  },
})

const monthlyBarOptions = merge({}, DEFAULT_OPTIONS, {
  scales: {
    x: {
      stacked: true,
      grid: {
        lineWidth: 0,
      },
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
  },
})

const quarterlyBarOptions = merge({}, DEFAULT_OPTIONS, {
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
    y: {
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
  },
})

const yearlyLineOptions = merge({}, DEFAULT_OPTIONS, {
  elements: {
    line: {
      tension: 0.4,
    },
  },
  scales: {
    x: {
      grid: {
        lineWidth: 0,
      },
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
    y: {
      min: 0,
      ticks: {
        font: {
          family: "'NotoSansThai', 'NotoSans', sans-serif",
        },
      },
    },
  },
})

function getDailyLabels(summittedAt: SummitedAtValue) {
  return DAYS_IN_WEEK_TH.map((day, index) => [
    day,
    format(addDays(summittedAt.min || new Date(), index), 'dd/MM'),
  ])
}

function getMonthlyLabels(summittedAt: SummitedAtValue) {
  const startYear = summittedAt?.min?.getFullYear() + ''
  const parsedYear = startYear ? parseInt(startYear, 10) : new Date().getFullYear()
  const startMonth = summittedAt?.min?.getMonth() + ''
  const parsedMonth = startMonth ? parseInt(startMonth, 10) : new Date().getMonth()

  const days = getDaysInMonth(new Date(parsedYear, parsedMonth))

  return Array.from(Array(days).keys()).map((_, i) => {
    let runningDate = addDays(summittedAt?.min || new Date(), i)
    return [format(runningDate, 'eeeeee', { locale: th }), format(runningDate, 'dd')]
  })
}

function getQuarterlyLabels(summittedAt: SummitedAtValue) {
  const quarterlyMonth = summittedAt?.min ? summittedAt?.min?.getMonth() : new Date().getMonth()
  return [MONTH_TH[quarterlyMonth], MONTH_TH[quarterlyMonth + 1], MONTH_TH[quarterlyMonth + 2]]
}

export {
  dateBarOptions,
  monthlyBarOptions,
  quarterlyBarOptions,
  yearlyLineOptions,
  prepareData,
  getDailyLabels,
  getMonthlyLabels,
  getQuarterlyLabels,
}
