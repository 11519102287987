import * as Yup from 'yup'

import { passwordValidator } from 'utils/helper'

const SetupPasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('กรุณากรอกข้อมูล'),
  newPassword: Yup.string()
    .required('กรุณากรอกข้อมูล')
    .min(8, 'รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร')
    .test('is-password', 'รูปแบบไม่ถูกต้อง', (value) => {
      const { hasLowercase, hasUppercase, hasDigit } = passwordValidator(value)
      return hasLowercase && hasUppercase && hasDigit
    }),
  newPasswordConfirmation: Yup.string()
    .required('กรุณากรอกข้อมูล')
    .test('is-confirmpassword', 'รหัสผ่านไม่ตรงกัน', (value, rest) => {
      const { newPassword } = rest.parent
      return value === newPassword
    }),
})

export default SetupPasswordSchema
