export const carUsage = (code) => {
  switch (code) {
    case '110':
      return 'เก๋ง'
    case '140A':
      return 'กระบะ'
    case '120A':
      return 'รถตู้'
    default:
      break
  }
}
