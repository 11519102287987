import React from 'react'
import { SelectorProps } from './Branches.type'
import { Selector, customSelectStyles } from './Branches.styles'
import { searchOptions } from './Branches.utils'

export const SelectorComponent = ({ sortBy, setSorting }: SelectorProps) => {
  return (
    <div>
      <Selector
        value={sortBy}
        styles={customSelectStyles}
        onChange={(option: any) => {
          setSorting(option)
        }}
        options={searchOptions}
      />
    </div>
  )
}
