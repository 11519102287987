import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { TabProps } from './InterestedEdit.type'

export const Container = styled.div`
  width: 100%;
  .Loading {
    text-align: center;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
`

export const Content = styled.div`
  width: 100%;
  max-width: 736px;
  display: flex;
  flex-direction: column;
  margin: 40px auto;
  .Assets {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 64px;
    margin-bottom: 16px;
    background-color: var(--agt-white-color);
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding-bottom: 16px;
  }
  .Increase {
    display: flex;
    align-self: flex-end;
    gap: 16px;
    align-items: flex-end;
    margin-bottom: 56px;
  }
  .Increase p {
    color: var(--agt-primary-color-1);
  }
  .Order {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .Order .Row {
    display: flex;
    justify-content: space-between;
  }
  .Order .Row .Column {
    width: 100%;
    max-width: 356px;
    display: flex;
    flex-direction: column;
    padding-bottom: 4px;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .Order .Row .Column.Name {
    max-width: 614px;
  }
  .Order .Row .Column b {
    margin-bottom: 8px;
  }
  .Order .Row .RedPlate {
    width: 100%;
    max-width: 356px;
    align-self: flex-end;
  }
  .Order .Row .Icons {
    display: flex;
    gap: 16px;
  }
  .Order .Row .Icons .Icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Order .Row .Icons .Icon svg {
    width: 16px;
    height: 16px;
  }
  .Order .Row .Icons .Icon path {
    fill: var(--agt-primary-color-1);
  }
  .Order .Row .Icons .Icon:hover path {
    fill: var(--agt-dark-sky-color);
  }
  .Order .Insurance {
    width: 100%;
    border: 1px solid var(--agt-secondary-color-1);
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  .Order .Insurance .InsuranceAct {
    margin-top: 16px;
    border-top: 1px solid var(--agt-secondary-color-1);
    display: flex;
    gap: 16px;
    align-items: center;
    padding: 16px 16px 0;
  }
  .Order .Insurance .InsuranceOrder {
    display: flex;
    justify-content: space-between;
  }
  .Order .Insurance .InsuranceOrder .Name {
    display: flex;
    gap: 8px;
  }
  .Order .Insurance .InsuranceOrder .Name .InsuranceCompany {
    display: flex;
    flex-direction: column;
  }
  .Order .Insurance .InsuranceOrder .Status {
    display: flex;
    flex-direction: column;
  }
  .Order .Insurance .InsuranceOrder .Status .OrderStatus {
    background: var(--agt-primary-color-1);
    border-radius: 20px;
    font-size: 13px;
    color: var(--agt-white-color);
    padding: 3px 16px;
    margin-bottom: 10px;
  }
  .Order .Insurance .InsuranceOrder .Status .OrderNo {
    font-size: 13px;
    color: var(--agt-primary-color-1);
    margin-bottom: 16px;
    text-decoration: underline;
  }
  .Order .Insurance .InsuranceOrder .Status .OrderNo:hover {
    color: var(--agt-dark-sky-color);
  }
  .Order .Insurance .InsuranceOrder .Status .OrderType {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  .Order .Insurance .InsuranceOrder .Status .OrderType b {
    color: #3abaf8;
  }
`

export const Tab = styled.button<TabProps>`
  width: 100%;
  height: 100%;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-text-color)'};
  background-color: ${(props) => (props.active ? 'var(--agt-background-color-3)' : 'transparent')};
  :hover {
    background-color: ${(props) =>
      props.active ? 'var(--agt-background-color-3)' : 'transparent'};
  }
`

export const IconButton = styled(Icon)``

export const CustomModal = styled.div`
  min-width: 380px;
  padding: 0 12px;
  height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: #000000;
  }
  .name {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
`
