import React, { FC, useState, useEffect, useCallback } from 'react'
import { useHistory, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import ProfilePage from './ProfilePage'
import { RootState } from 'store/rootReducer'
import { deleteContactById, getContactById, getOptions } from 'store/contactSlice'
import WebTour from './WebTour'
import WebTourNonConsent from './WebTourNonConsent'
import { Container } from './index.styles'
import HistoryType from 'types/HistoryType'
import SetStateType from 'types/SetStateType'
import { isObject } from 'lodash'

const ContactDetail: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { contact, consentCurrentVersion, lastConsentHistory, initialized } = useSelector(
    (state: RootState) => state.contacts,
  )
  const history: HistoryType = useHistory()
  const [isShowHint, setIsShowHint] = useState<boolean>(false)

  const getContactByIdAgain = useCallback(() => {
    dispatch(getContactById(id))
  }, [id])

  const confirmDelete = useCallback(
    (contactId: number | string, setIsDelete: SetStateType<boolean>) => {
      setIsDelete(false)
      dispatch(
        deleteContactById(contactId, () => {
          history.push('/contacts')
        }),
      )
    },
    [id],
  )

  useEffect(() => {
    dispatch(getContactById(id))
    dispatch(getOptions())
  }, [dispatch, id])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['contacts']['contactById'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({
            ...storage,
            contacts: { ...storage.contacts, contactById: true },
          }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: true },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      {isObject(contact?.currentAgreement || null) && contact?.isConsented === false ? (
        <WebTourNonConsent visible={isShowHint} setVisible={setIsShowHint} />
      ) : (
        <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      )}
      {initialized ? (
        <p className="isLoading">loading...</p>
      ) : (
        <ProfilePage
          contact={contact}
          consentCurrentVersion={consentCurrentVersion}
          lastConsentHistory={lastConsentHistory}
          getContactByIdAgain={getContactByIdAgain}
          setIsShowHint={setIsShowHint}
          confirmDelete={confirmDelete}
        />
      )}
    </Container>
  )
}

export default ContactDetail
