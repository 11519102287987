import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'routes'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import { RootState } from 'store/rootReducer'
import { useSelector } from 'react-redux'
import { NewButton, NewForm, ShieldIcon, ActForm } from './InsurancePort.styles'
import {
  getFullNameInsurancePattern,
  getFullNameInsuranceType,
  getFullNamePolicyType,
  getFullNameState,
} from './InsurancePort.components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { PolicyMotorInsuranceType } from './PolicyMotorInsurance.type'

const PolicyMotorInsurance: FC<PolicyMotorInsuranceType> = (props) => {
  const { instead = {}, onEdit = () => {}, setIsImageFile, setIsDelete } = props
  let { id }: { id: string } = useParams()
  const { portOptions } = useSelector((state: RootState) => state.contacts)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isCarDetail, setIsCarDetail] = useState<boolean>(false)

  return (
    <>
      <NewForm>
        {instead.createdSource === 'tqm' ? (
          <div className="Row">
            <div className="Group">
              <b>เจ้าของกรมธรรม์</b>
              <p>{instead.ownerName}</p>
            </div>
            <div className="orderNoTqm">
              <div className="Group orderNo">
                <b>รหัสคำสั่งซื้อ</b>
                <p>{instead?.orderNo || '-'}</p>
              </div>
              <Link
                to={`/opportunities/${instead.opportunityId}/motor/droplead/${instead.orderId}`}
              >
                <button type="button" style={{ width: 176 }}>
                  รายละเอียดคำสั่งซื้อ
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="Row">
            <div className="Group">
              <b>เจ้าของกรมธรรม์</b>
              <p>{instead.ownerName}</p>
            </div>
            <div className="Group">
              <b>รหัสคำสั่งซื้อ</b>
              <p>{instead?.orderNo || '-'}</p>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <b>เพศ</b>
            <p>{instead.ownerGender === 'M' ? 'ชาย' : 'หญิง'}</p>
          </div>
          <div className="Group">
            <b>อายุ</b>
            <p>{instead.ownerAge}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>รูปแบบการประกันภัย</b>
            <p>{getFullNameInsurancePattern(instead.insurancePattern || '')}</p>
          </div>
          <div className="Group">
            <b>ประเภทประกัน</b>
            <p>{getFullNamePolicyType(instead.policyType || '')}</p>
          </div>
        </div>
        {instead?.specialFields?.vehicle && (
          <div className="CarDetail">
            <div className="Icon">
              <b>รายละเอียดรถยนต์</b>
              <div onClick={() => setIsCarDetail(!isCarDetail)}>
                {isCarDetail ? (
                  <FontAwesomeIcon icon={faChevronUp} color={'var(--agt-secondary-text-color)'} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} color={'var(--agt-secondary-text-color)'} />
                )}
              </div>
            </div>
            <div className="CarForm">
              <div className="Row">
                <div className="Group carName">
                  <b>รถยนต์</b>
                  <p>{instead?.specialFields?.vehicle?.name || '-'}</p>
                </div>
              </div>
              {isCarDetail && (
                <>
                  <div className="Row">
                    <div className="Group">
                      <b>ปีรถยนต์</b>
                      <p>{instead?.specialFields?.vehicle?.year || '-'}</p>
                    </div>
                    <div className="Group">
                      <b>ยี่ห้อรถยนต์</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleBrand || '-'}</p>
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Group">
                      <b>รุ่น</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleModel || '-'}</p>
                    </div>
                    <div className="Group">
                      <b>รุ่นย่อย</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleSubModel || '-'}</p>
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Group">
                      <b>เลขทะเบียนรถยนต์</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleLicense || '-'}</p>
                    </div>
                    <div className="Group">
                      <b>จังหวัดที่จดทะเบียน</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleProvince || '-'}</p>
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Group">
                      <b>เลขตัวถังรถ</b>
                      <p>{instead?.specialFields?.vehicle?.vehicleChassis || '-'}</p>
                    </div>
                    {instead?.specialFields?.vehicle?.redLicencePlate && (
                      <div className="Group redPlate">
                        <b></b>
                        <p>เป็นรถป้ายแดง</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <b>ประเภทกรมธรรม์</b>
            <p>{getFullNameInsuranceType(instead.insuranceType || '')}</p>
          </div>
          <div className="Group">
            <b>บริษัทประกัน</b>
            <p>
              {portOptions.insuranceCompanyNameOptions.find(
                (e) => e.value === instead.insuranceCompanyName,
              )?.label || '-'}
            </p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>แบบประกัน</b>
            <p>{instead.name}</p>
          </div>
          <div className="Group">
            <b>หมายเลขกรมธรรม์</b>
            <p>{instead?.policyNo || '-'}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>สถานะกรมธรรม์</b>
            <p>{instead.state ? getFullNameState(instead.state) : 'ไม่ต้องระบุสถานะกรมธรรม์'}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>วันที่เริ่มทำสัญญา</b>
            <p>{new Date(instead.startPolicyAt || '').toLocaleDateString('th-TH')}</p>
          </div>
          <div className="Group">
            <b>วันที่ครบกำหนดสัญญา (คุ้มครองถึง)</b>
            <p>
              {instead.endPolicyAt
                ? new Date(instead.endPolicyAt || '').toLocaleDateString('th-TH')
                : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
            </p>
          </div>
        </div>
        <div className="Row remark">
          <div className="Group">
            <b>รายละเอียดเพิ่มเติม</b>
            <p>{instead?.remark || '-'}</p>
          </div>
        </div>
        {(instead?.specialFields?.drivers || []).map((e, i) => (
          <div className="DiverDetail">
            <p>ข้อมูลผู้ขับขี่ {i + 1}</p>
            <div className="Row">
              <div className="Group">
                <b>ชื่อจริง</b>
                <p>{e.firstName}</p>
              </div>
              <div className="Group">
                <b>นามสกุล</b>
                <p>{e.lastName}</p>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <b>อาชีพ</b>
                <p>{e.occupation}</p>
              </div>
              <div className="Group">
                <b>วันเกิด</b>
                <p>{new Date(e.birthdate || '').toLocaleDateString('th-TH')}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="Row sumInsured">
          <div className="Group">
            <b>ทุนประกัน (บาท)</b>
            <p>{instead.sumInsured ? financial(instead.sumInsured) : '-'}</p>
          </div>
          <div className="Group">
            <b>เบี้ยประกัน (ต่อปี)</b>
            <p>{instead.netPremium && financial(instead.netPremium)}</p>
          </div>
        </div>
        <div className="LiabilityInsurance">
          <p>ความรับผิดต่อบุคคลภายนอก</p>
          <div className="Row">
            <div className="Group">
              <b>
                ความเสียหายต่อชีวิต ร่างกาย หรืออนามัยเฉพาะ ส่วนเกินวงเงินสูงสุดตาม พ.ร.บ. (บาท/คน)
              </b>
              <p>
                {instead?.specialFields?.productDetails?.cover3Rd &&
                  financial(instead.specialFields.productDetails.cover3Rd.toString())}
              </p>
            </div>
            <div className="Group">
              <b>
                ความเสียหายต่อชีวิต ร่างกาย หรืออนามัยเฉพาะ ส่วนเกินวงเงินสูงสุดตาม พ.ร.บ.
                (บาท/ครั้ง)
              </b>
              <p>
                {instead?.specialFields?.productDetails?.cover3RdTime &&
                  financial(instead.specialFields.productDetails.cover3RdTime.toString())}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ความเสียหายต่อทรัพย์สิน (บาท/ครั้ง)</b>
              <p>
                {instead?.specialFields?.productDetails?.cover3RdAsset &&
                  financial(instead.specialFields.productDetails.cover3RdAsset.toString())}
              </p>
            </div>
            <div className="Group">
              <b>ความเสียหายส่วนแรก (บาท/ครั้ง)</b>
              <p>
                {instead?.specialFields?.productDetails?.coverEarly
                  ? financial(instead.specialFields.productDetails.coverEarly.toString())
                  : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className="DamagedCar">
          <p>รถยนต์เสียหาย สูญหาย ไฟไหม้</p>
          <div className="Row">
            <div className="Group">
              <b>ความเสียหายต่อรถยนต์ (บาท/ครั้ง)</b>
              <p>
                {instead?.createdSource === 'tqm' &&
                instead?.specialFields?.productDetails?.coverLostFire
                  ? financial(instead.specialFields.productDetails.coverLostFire.toString())
                  : instead?.specialFields?.productDetails?.coverCar
                  ? financial(instead.specialFields.productDetails.coverCar.toString())
                  : '-'}
              </p>
            </div>
            <div className="Group">
              <b>ความเสียหายส่วนแรก (บาท/ครั้ง)</b>
              <p>
                {instead?.specialFields?.productDetails?.coverDeduct &&
                  financial(instead.specialFields.productDetails.coverDeduct.toString())}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>รถยนต์สูญหาย ไฟไหม้ (บาท)</b>
              <p>
                {instead?.specialFields?.productDetails?.coverLostFire &&
                  financial(instead.specialFields.productDetails.coverLostFire.toString())}
              </p>
            </div>
          </div>
        </div>
        <div className="PermanentDisability">
          <p>
            ความคุ้มครองตามเอกสารแนบท้าย
            <br />
            อุบัติเหตุส่วนบุคคล
            <br />
            การเสียชีวิต สูญเสียอวัยวะ ทุพพลภาพถาวร
          </p>
          <div className="Row">
            <div className="Group">
              <b>ผู้ขับขี่</b>
              <p>{instead?.specialFields?.productDetails?.coverAccNum && 1}</p>
            </div>
            <div className="Group">
              <b>
                มูลค่า{' '}
                {instead?.createdSource === 'tqm'
                  ? '(บาท/คน)'
                  : instead?.specialFields?.productDetails?.coverDriverDeceasedUnit
                  ? instead?.specialFields?.productDetails?.coverDriverDeceasedUnit
                  : '(บาท/คน)'}
              </b>
              <p>
                {instead?.createdSource === 'tqm' &&
                instead?.specialFields?.productDetails?.coverAcc
                  ? financial(instead.specialFields.productDetails.coverAcc.toString())
                  : instead?.specialFields?.productDetails?.coverDriverDeceased
                  ? financial(instead.specialFields.productDetails.coverDriverDeceased.toString())
                  : '-'}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ผู้โดยสาร</b>
              <p>
                {instead?.specialFields?.productDetails?.coverAccNum &&
                  instead.specialFields.productDetails.coverAccNum - 1}
              </p>
            </div>
            <div className="Group">
              <b>
                มูลค่า{' '}
                {instead?.createdSource === 'tqm'
                  ? '(บาท/คน)'
                  : instead?.specialFields?.productDetails?.coverPassengerDeceasedUnit
                  ? instead?.specialFields?.productDetails?.coverPassengerDeceasedUnit
                  : '(บาท/คน)'}
              </b>
              <p>
                {instead?.createdSource === 'tqm' &&
                instead?.specialFields?.productDetails?.coverAcc
                  ? financial(instead.specialFields.productDetails.coverAcc.toString())
                  : instead?.specialFields?.productDetails?.coverPassengerDeceased
                  ? financial(
                      instead.specialFields.productDetails.coverPassengerDeceased.toString(),
                    )
                  : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className="TemporaryDisability">
          <p>ทุพพลภาพชั่วคราว</p>
          <div className="Row">
            <div className="Group">
              <b>ผู้ขับขี่</b>
              <p>{instead?.specialFields?.productDetails?.coverAccNum && 1}</p>
            </div>
            <div className="Group">
              <b>
                มูลค่า{' '}
                {instead?.createdSource === 'tqm'
                  ? '(บาท/คน/สัปดาห์)'
                  : instead?.specialFields?.productDetails?.coverDriverTemporaryUnit
                  ? instead?.specialFields?.productDetails?.coverDriverTemporaryUnit
                  : '(บาท/คน/สัปดาห์)'}
              </b>
              <p>
                {instead?.createdSource === 'tqm'
                  ? '-'
                  : instead?.specialFields?.productDetails?.coverDriverTemporary
                  ? financial(instead.specialFields.productDetails.coverDriverTemporary.toString())
                  : '-'}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ผู้โดยสาร</b>
              <p>
                {instead?.specialFields?.productDetails?.coverAccNum &&
                  instead.specialFields.productDetails.coverAccNum - 1}
              </p>
            </div>
            <div className="Group">
              <b>
                มูลค่า{' '}
                {instead?.createdSource === 'tqm'
                  ? '(บาท/คน/สัปดาห์)'
                  : instead?.specialFields?.productDetails?.coverPassengerTemporaryUnit
                  ? instead?.specialFields?.productDetails?.coverPassengerTemporaryUnit
                  : '(บาท/คน/สัปดาห์)'}
              </b>
              <p>
                {instead?.createdSource === 'tqm'
                  ? '-'
                  : instead?.specialFields?.productDetails?.coverPassengerTemporary
                  ? financial(
                      instead.specialFields.productDetails.coverPassengerTemporary.toString(),
                    )
                  : '-'}
              </p>
            </div>
          </div>
        </div>
        <div className="MedicalFee">
          <p>ค่ารักษาพยาบาล</p>
          <div className="Row">
            <div className="Group">
              <b>จำนวนคนในรถ</b>
              <p>
                {instead?.specialFields?.productDetails?.coverAccPassNum &&
                  instead.specialFields.productDetails.coverAccPassNum}
              </p>
            </div>
            <div className="Group">
              <b>
                มูลค่า{' '}
                {instead?.createdSource === 'tqm'
                  ? '(บาท/คน)'
                  : instead?.specialFields?.productDetails?.coverAccPassUnit
                  ? instead?.specialFields?.productDetails?.coverAccPassUnit
                  : '(บาท/คน)'}
              </b>
              <p>
                {instead?.specialFields?.productDetails?.coverAccPass &&
                  financial(instead.specialFields.productDetails.coverAccPass.toString())}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ประกันตัวผู้ขับขี่ (บาท/ครั้ง)</b>
              <p>
                {instead?.specialFields?.productDetails?.coverLegal &&
                  financial(instead.specialFields.productDetails.coverLegal.toString())}
              </p>
            </div>
          </div>
          <div className="Row MoreDetails">
            {(instead?.specialFields?.productDetails?.coverMoreDetails || []).map((e, i) => (
              <div className="Group" key={i}>
                <b>
                  {e.name} ({e.unit})
                </b>
                <p>{e.value ? financial(e.value.toString()) : '-'}</p>
              </div>
            ))}
          </div>
        </div>
        {/* 
        cover_more_details: [{
          name: "ความคุ้มครอง",
          value: "มูลค่า ",
          unit: "หน่วย"
      }]
        */}
        <button
          className="ImageButton"
          type="button"
          onClick={() => setIsImageFile(true)}
          disabled={!instead.policyDocumentFullPath}
        >
          <ShieldIcon
            className="EditIcon"
            name="Vector"
            color={
              instead.policyDocumentFullPath
                ? 'var(--agt-white-color)'
                : 'var(--agt-disable-color-2)'
            }
          />
          <div style={{ marginLeft: '14px' }}>ดูหน้ากรมธรรม์</div>
        </button>
      </NewForm>
      <ActForm>
        {instead?.specialFields?.prbInsurance?.policyNo ? (
          <>
            <p>พ.ร.บ.</p>
            <div className="Detail">
              <div className="Row">
                <p>
                  เบี้ยประกันต่อปี :{' '}
                  {instead?.specialFields?.prbInsurance?.annualPremium &&
                    financial(instead.specialFields.prbInsurance.annualPremium)}
                </p>
                <p>เลขกรมธรรม์ : {instead?.specialFields?.prbInsurance?.policyNo}</p>
              </div>
              <div className="Row">
                <p>
                  เริ่มการคุ้มครอง :{' '}
                  {new Date(
                    instead?.specialFields?.prbInsurance?.startPolicyAt || '',
                  ).toLocaleDateString('th-TH')}
                </p>
                <p>
                  สิ้นสุดคุ้มครอง :{' '}
                  {new Date(
                    instead?.specialFields?.prbInsurance?.endPolicyAt || '',
                  ).toLocaleDateString('th-TH')}
                </p>
              </div>
            </div>
          </>
        ) : (
          <p>ไม่รวมประกัน พ.ร.บ.</p>
        )}
      </ActForm>
      <NewButton>
        <button
          className="secondary"
          type="button"
          style={{ marginRight: '24px' }}
          onClick={() => setIsDelete(true)}
          disabled={instead?.createdSource === 'tqm' || currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button
          className="secondary"
          type="button"
          onClick={() => onEdit()}
          // disabled={instead.createdSource === 'tqm' || currentAgent?.statusTh === 'ระงับ'}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          แก้ไข
        </button>
      </NewButton>
    </>
  )
}

export default PolicyMotorInsurance
