import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'routes'
import { RootState } from 'store/rootReducer'
import { useSelector } from 'react-redux'
import { NewButton, NewForm, ShieldIcon } from './InsurancePort.styles'
import {
  getFullNameInsurancePattern,
  getFullNameInsuranceType,
  getFullNamePolicyType,
  getFullNameState,
  getFullNameTravelType,
} from './InsurancePort.components'
import { PolicyTAInsuranceType } from './PolicyTAInsurance.type'
import { financial } from '../root/Summary'

const PolicyTAInsurance: FC<PolicyTAInsuranceType> = (props) => {
  const { instead = {}, onEdit = () => {}, setIsImageFile, setIsDelete } = props
  let { id }: { id: string } = useParams()
  const { portOptions } = useSelector((state: RootState) => state.contacts)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  return (
    <>
      <NewForm>
        {instead.createdSource === 'tqm' ? (
          <div className="Row">
            <div className="Group">
              <b>เจ้าของกรมธรรม์</b>
              <p>{instead.ownerName}</p>
            </div>
            <div className="orderNoTqm">
              <div className="Group orderNo">
                <b>รหัสคำสั่งซื้อ</b>
                <p>{instead?.orderNo || '-'}</p>
              </div>
              <Link
                to={`/opportunities/${instead?.opportunityId}/travel/droplead/${instead?.orderId}`}
              >
                <button type="button" style={{ width: 176 }}>
                  รายละเอียดคำสั่งซื้อ
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="Row">
            <div className="Group">
              <b>เจ้าของกรมธรรม์</b>
              <p>{instead.ownerName}</p>
            </div>
            <div className="Group">
              <b>รหัสคำสั่งซื้อ</b>
              <p>{instead?.orderNo || '-'}</p>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <b>เพศ</b>
            <p>{instead.ownerGender === 'M' ? 'ชาย' : 'หญิง'}</p>
          </div>
          <div className="Group">
            <b>อายุ</b>
            <p>{instead.ownerAge}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>รูปแบบการประกันภัย</b>
            <p>{getFullNameInsurancePattern(instead.insurancePattern || '')}</p>
          </div>
          <div className="Group">
            <b>ประเภทประกัน</b>
            <p>{getFullNamePolicyType(instead.policyType || '')}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>บริษัทประกัน</b>
            <p>
              {portOptions.insuranceCompanyNameOptions.find(
                (e) => e.value === instead.insuranceCompanyName,
              )?.label || '-'}
            </p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>ชื่อแบบประกัน</b>
            <p>{instead.name}</p>
          </div>
          <div className="Group">
            <b>หมายเลขกรมธรรม์</b>
            <p>{instead?.policyNo || '-'}</p>
          </div>
        </div>
        <div className="TravelType">
          <p>ประเภทการเดินทาง</p>
          <div className="Row Title">
            <div className="Group">
              <p>{getFullNameInsuranceType(instead?.insuranceType || '')}</p>
            </div>
            <div className="Group">
              <p>{getFullNameTravelType(instead.specialFields?.travelType || '')}</p>
            </div>
          </div>
          <div className="Row">
            {instead.specialFields?.travelTypeCountry === 'IN' && (
              <div className="Group">
                <b>จังหวัดต้นทาง</b>
                <p>{instead?.specialFields?.startProvince}</p>
              </div>
            )}
            {instead.specialFields?.travelTypeCountry === 'IN' ? (
              <div className="Group">
                <b>จังหวัดปลายทาง</b>
                <p>{instead?.specialFields?.destinationProvince}</p>
              </div>
            ) : (
              <div className="Group">
                <b>ประเทศปลายทาง</b>
                <p>{instead?.specialFields?.destinationCountry || '-'}</p>
              </div>
            )}
          </div>
          <div className="Row">
            <div className="Group">
              <b>จำนวนผู้เดินทาง</b>
              <p>{instead?.specialFields?.travelPassenger} คน</p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>วันที่ออกเดินทาง</b>
              <p>
                {new Date(instead?.specialFields?.travelDateFrom || '').toLocaleDateString('th-TH')}
              </p>
            </div>
            <div className="Group">
              <b>วันที่กลับถึงไทย</b>
              <p>
                {new Date(instead?.specialFields?.travelDateTo || '').toLocaleDateString('th-TH')}
              </p>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>สถานะกรมธรรม์</b>
            <p>{instead.state ? getFullNameState(instead.state) : 'ไม่ต้องระบุสถานะกรมธรรม์'}</p>
          </div>
        </div>
        <div className="Row remark">
          <div className="Group">
            <b>รายละเอียดเพิ่มเติม</b>
            <p>{instead?.remark || '-'}</p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>
              เบี้ยประกันภัย (บาท)
              <br />
              <br />
            </b>
            <p>{instead?.netPremium ? financial(instead.netPremium) : '-'}</p>
          </div>
          <div className="Group">
            <b>ความคุ้มครองกรณีเสียชีวิต สูญเสียอวัยวะ ทุพพลภาพ</b>
            <p>
              {instead?.specialFields?.productDetails?.coverDetail?.travelA01
                ? financial(instead?.specialFields?.productDetails?.coverDetail?.travelA01)
                : '-'}
            </p>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <b>
              ค่ารักษาพยาบาลกรณีอุบัติเหตุและเจ็บป่วย
              <br />
              <br />
            </b>
            <p>
              {instead?.specialFields?.productDetails?.coverDetail?.travelA02
                ? financial(instead?.specialFields?.productDetails?.coverDetail?.travelA02)
                : '-'}
            </p>
          </div>
          <div className="Group">
            <b>ค่าใช้จ่ายการเคลื่อนย้ายผู้ป่วยฉุกเฉินหรือ ส่งศพกลับประเทศ</b>
            <p>
              {instead?.specialFields?.productDetails?.coverDetail?.travelA05
                ? financial(instead?.specialFields?.productDetails?.coverDetail?.travelA05)
                : '-'}
            </p>
          </div>
        </div>
        <button
          className="ImageButton"
          type="button"
          onClick={() => setIsImageFile(true)}
          disabled={!instead.policyDocumentFullPath}
        >
          <ShieldIcon
            className="EditIcon"
            name="Vector"
            color={
              instead.policyDocumentFullPath
                ? 'var(--agt-white-color)'
                : 'var(--agt-disable-color-2)'
            }
          />
          <div style={{ marginLeft: '14px' }}>ดูหน้ากรมธรรม์</div>
        </button>
      </NewForm>
      <NewButton>
        <button
          className="secondary"
          type="button"
          style={{ marginRight: '24px' }}
          onClick={() => setIsDelete(true)}
          disabled={instead?.createdSource === 'tqm' || currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button className="secondary" type="button" onClick={() => onEdit()} disabled>
          แก้ไข
        </button>
      </NewButton>
    </>
  )
}

export default PolicyTAInsurance
