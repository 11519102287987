import React, { FC } from 'react'
import styled from '@emotion/styled'

const Button = styled.button<ButtonStyleProps>`
  width: ${(props) => (props.width ? props.width : '160px')};
`

type ButtonStyleProps = {
  color?: string
  hoverColor?: string
  width?: string
}

type ButtonProps = {
  className?: string
  label?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
}

const PimaryButton: FC<ButtonProps & ButtonStyleProps> = (props) => {
  const { className, color, hoverColor, type = 'button', width, disabled } = props
  return (
    <Button
      className={className}
      type={type}
      color={color}
      hoverColor={hoverColor}
      onClick={props.onClick}
      width={width}
      disabled={disabled}
    >
      {props.label}
    </Button>
  )
}
export default PimaryButton
