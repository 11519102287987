import styled from '@emotion/styled'
import { Form } from 'formik'
import Gender from 'components/Inputs/Gender'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

export const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '32px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  p {
    color: var(--agt-primary-color-1);
  }
`

export const CloseButton = styled.div`
  svg {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: #393d43;
      }
    }
  }
`

export const FormContact = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 736px;
  padding-top: 24px;
  border-top: 1px solid var(--agt-secondary-color-1);
  .groupField {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .groupField .groupRow {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .groupField .groupRow .inputField {
    width: 254px;
  }
`

export const SubmitButton = styled.button`
  width: 160px;
  height: 48px;
  align-self: flex-end;
`

export const StyledGender = styled(Gender)`
  width: 290px;
  height: 48px;
`

export const DatePickerField = styled(DatePicker)`
  width: 360px;
  height: 48px;
`

export const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
  display: flex;
`
