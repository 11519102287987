import * as Yup from 'yup'
import isEmpty from 'lodash/isEmpty'

import { phoneNumberRegex, isValidId } from 'utils/helper'

let addressSchema = Yup.object().shape({
  housingNo: Yup.string().nullable().required('กรุณาระบุเลขที่'),
  // villageNo: Yup.string().nullable().required('กรุณาระบุหมู่'),
  // building: Yup.string().nullable().required('กรุณาระบุหมู่บ้าน/อาคาร'),
  // lane: Yup.string().nullable().required('กรุณาระบุซอย/ตรอก'),
  // road: Yup.string().nullable().required('กรุณาระบุถนน'),
  subDistrict: Yup.string().nullable().required('กรุณาระบุตำบล/แขวง'),
  district: Yup.string().nullable().required('กรุณาระบุอำเภอ/เขต'),
  province: Yup.string().nullable().required('กรุณาระบุจังหวัด'),
  postcode: Yup.string().nullable().required('กรุณาระบุรหัสไปรษณีย์'),
})

export let schemaStageAgent = Yup.object().shape({
  firstName: Yup.string().nullable().required('กรุณาระบุชื่อ'),
  lastName: Yup.string().nullable().required('กรุณาระบุชื่อ'),
  gender: Yup.string().nullable().required('กรุณาเลือกเพศ'),
  prefixName: Yup.string().nullable().required('กรุณาระบุคำนำหน้าชื่อ'),
  birthdate: Yup.date().nullable().required('กรุณาระบุวันเกิด'),
  idCard: Yup.string()
    .nullable()
    .test('is-id', 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง', (value) => {
      if (value) return isValidId(value)
      return true
    })
    .required('กรุณาระบุเลขบัตรประชาชน'),
  phoneNumber: Yup.string()
    .nullable()
    .matches(phoneNumberRegex, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณาระบุเบอร์โทรศัพท์'),
  email: Yup.string().nullable().email('รูปแบบอีเมลไม่ถูกต้อง').required('กรุณาระบุอีเมล'),
  idCardAddress: addressSchema,
  currentAddress: addressSchema,
  insuranceCompanyName: Yup.string().nullable().required('กรุณาระบุบริษัทประกันชีวิตต้นสังกัด'),
  isOTP: Yup.string().nullable().required('กรุณาขอรหัส OTP'),
  agentLicenses: Yup.array().of(
    Yup.object()
      .shape({
        licenseCode: Yup.string().nullable().required('กรุณากรอกเลขที่ใบอนุญาต'),
        licenseImageId: Yup.string().nullable().required('กรุณาอัพโหลดใบอนุญาต'),
        licenseType: Yup.string().nullable().required('กรุณากรอกตัวแทนประกันชีวิต'),
      })
      .test('is-license-valid', 'กรุณากรอกข้อมูล', (value, context) => {
        if (isEmpty(value.licenseImageId)) {
          return context.createError({
            path: `${context.path}.licenseCode`,
            message: 'กรุณาอัพโหลดใบอนุญาต',
          })
        }

        return true
      }),
  ),
})

let schema = Yup.object().shape({
  firstName: Yup.string().nullable().required('กรุณาระบุชื่อ'),
  lastName: Yup.string().nullable().required('กรุณาระบุชื่อ'),
  gender: Yup.string().nullable().required('กรุณาเลือกเพศ'),
  prefixName: Yup.string().nullable().required('กรุณาระบุคำนำหน้าชื่อ'),
  birthdate: Yup.date().nullable().required('กรุณาระบุวันเกิด'),
  idCard: Yup.string()
    .nullable()
    .test('is-id', 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง', (value) => {
      if (value) return isValidId(value)
      return true
    })
    .required('กรุณาระบุเลขบัตรประชาชน'),
  idCardImageId: Yup.string().nullable().required('กรุณาอัพโหลดรูปบัตรประชาชน'),
  phoneNumber: Yup.string()
    .nullable()
    .matches(phoneNumberRegex, 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
    .required('กรุณาระบุเบอร์โทรศัพท์'),
  email: Yup.string().nullable().email('รูปแบบอีเมลไม่ถูกต้อง').required('กรุณาระบุอีเมล'),
  idCardAddress: addressSchema,
  currentAddress: addressSchema,
  insuranceCompanyName: Yup.string().nullable().required('กรุณาระบุบริษัทประกันชีวิตต้นสังกัด'),
  isOTP: Yup.string().nullable().required('กรุณาขอรหัส OTP'),
  // consent: Yup.boolean()
  //   .required('The terms and conditions must be accepted.')
  //   .oneOf([true], 'The terms and conditions must be accepted.'),
  // policy: Yup.boolean()
  //   .required('The terms and conditions must be accepted.')
  //   .oneOf([true], 'The terms and conditions must be accepted.'),
  agentLicenses: Yup.array().of(
    Yup.object()
      .shape({
        licenseCode: Yup.string().nullable().required('กรุณากรอกเลขที่ใบอนุญาต'),
        licenseImageId: Yup.string().nullable().required('กรุณาอัพโหลดใบอนุญาต'),
        // licenseImagePath: Yup.string().nullable().required('กรุณาอัพโหลดใบอนุญาต'),
        // status: Yup.string().nullable().required('กรุณาอัพโหลดใบอนุญาต'),
        licenseType: Yup.string().nullable().required('กรุณากรอกตัวแทนประกันชีวิต'),
      })
      .test('is-license-valid', 'กรุณากรอกข้อมูล', (value, context) => {
        if (
          isEmpty(value.licenseImageId)
          // &&isEmpty(value.licenseImagePath)
          // &&isEmpty(value.status)
        ) {
          return context.createError({
            path: `${context.path}.licenseCode`,
            message: 'กรุณาอัพโหลดใบอนุญาต',
          })
        }

        return true
      }),
  ),
})

export default schema
