import React, { FC, useEffect, useMemo, useState, useRef } from 'react'
import { phoneNumberFormat } from 'utils/helper'
import Avatar from 'components/Avatar'
import { useDispatch, useSelector } from 'react-redux'
import { getConsentDetail, sendOtp } from 'store/consentsSlice'
import { RootState } from 'store/rootReducer'
import useQuery from 'hooks/useQuery'
import { ErrorMessage, Field, FieldArray, Formik } from 'formik'
import { get } from 'lodash'
import OTPComponent from './OTPComponent'
import { schema } from './index.validation'
import { OtpType } from 'types/ConsentsType'
import SuccessModalComponent from './SuccessModal'
import { Container, Error } from './index.styles'
import { OTP } from './OTPComponent.type'

const Consents: FC = () => {
  const jwtContactToken = useQuery().get('jwt_contact_token') || ''
  const agreementId = useQuery().get('agreement_id') || ''
  const contactId = useQuery().get('contact_id') || ''
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.consents.contact)
  const agreement = useSelector((state: RootState) => state.consents.agreement)
  const refOtp: OtpType = useSelector((state: RootState) => state.consents.otp)

  const [isModalOTP, setIsModalOTP] = useState<boolean>(false)
  const [isModalSuccess, setIsModalSuccess] = useState<boolean>(false)

  const [isSuccesOTP, setIsSuccessOTP] = useState<boolean>(true)
  const [counter, setCounter] = useState<number>(60)
  const [isOTP, setIsOTP] = useState<OTP>({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  })
  let flipInterval = useRef()

  useEffect(() => {
    if (agreementId && contactId && jwtContactToken) {
      let keyValues = {
        agreement_id: agreementId,
        contact_id: contactId,
        jwt_contact_token: jwtContactToken,
      }
      dispatch(getConsentDetail(keyValues))
    }
  }, [dispatch, agreementId, contactId, jwtContactToken])

  useEffect(() => {
    const time: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000)
    flipInterval.current = time
    return () => {
      clearInterval(flipInterval.current)
    }
  }, [counter])

  const callTimeOut = () => {
    setTimeout(() => {
      setIsModalOTP(false)
      setCounter(60)
      setIsSuccessOTP(true)
    }, 60000)
  }

  const modifiedValues = useMemo(() => {
    if (agreement?.consents) {
      let array: Array<{
        name: string
        key: string
        forced_consent: boolean
        value: boolean
      }> = []
      agreement.consents.forEach((consent) => {
        array.push({ ...consent, value: false })
      })
      return array
    } else return null
  }, [agreement])

  return (
    <Container>
      <b className="name">{agreement?.name || 'นโยบายความเป็นส่วนตัว'}</b>
      <div className="contact">
        <Avatar avatar={contact?.avatar || '-'} type="circle" width="48px" height="48px" />
        <div className="detail">
          <label className="contactName">{contact?.name || ''}</label>
          <small>{contact?.occupation || 'ไม่ระบุอาชีพ'}</small>
          <small>{phoneNumberFormat(get(contact, 'phone_number', ''))}</small>
        </div>
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          agreementId: agreementId,
          consents: modifiedValues || [],
        }}
        onSubmit={() => {
          dispatch(
            sendOtp(
              get(contact, 'phone_number', ''),
              jwtContactToken,
              () => {
                setIsModalOTP(true)
                callTimeOut()
                setCounter(60)
              },
              () => {
                alert('กรุณาลองใหม่อีกครั้ง')
              },
            ),
          )
        }}
        validationSchema={schema}
      >
        {({ handleSubmit, values, isSubmitting }) => (
          <>
            <form id="agreementForm" onSubmit={handleSubmit}>
              <SuccessModalComponent isModalSuccess={isModalSuccess} />
              <OTPComponent
                refOtp={refOtp}
                isModalOTP={isModalOTP}
                setIsModalOTP={setIsModalOTP}
                isSuccesOTP={isSuccesOTP}
                setIsSuccessOTP={setIsSuccessOTP}
                isOTP={isOTP}
                setIsOTP={setIsOTP}
                setIsModalSuccess={setIsModalSuccess}
                counter={counter}
                phoneNumber={get(contact, 'phone_number', '')}
                contactId={contactId}
                jwtContactToken={jwtContactToken}
                values={values}
              />
              <p
                className="content"
                dangerouslySetInnerHTML={{
                  __html: agreement?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
                }}
              />
              <div className="consents">
                <FieldArray
                  name="consents"
                  render={() => {
                    if (values?.consents.length > 0)
                      return (
                        <>
                          {values.consents.map((consents, index) => (
                            <div className="consent" key="index">
                              <Field name={`consents[${index}].value`} type="checkbox" />
                              <div className="text">
                                <label>{consents.name || ''}</label>
                                <ErrorMessage
                                  name={`consents[${index}].value`}
                                  className="errorMessage"
                                  component={Error}
                                />
                              </div>
                            </div>
                          ))}
                        </>
                      )
                    return null
                  }}
                />
              </div>
            </form>
            <button type="submit" form="agreementForm" disabled={isSubmitting}>
              ยืนยันข้อมูล
            </button>
          </>
        )}
      </Formik>
    </Container>
  )
}

export default Consents
