import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import authToken from 'utils/session'

import {
  getSetting,
  linkAccount,
  changeEmail,
  unlinkAccount,
  resendConfirmation,
} from 'store/profileSlice'
import { SettingType } from 'store/profileSlice.utils'
import { RootState } from 'store/rootReducer'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Icon from 'components/Icon'
import ModalContainer from 'components/Modal'

import ButtonLink from 'components/ButtonLink'
import ShowHistoriesConsent from 'components/ShowHistoriesConsent'
// import HistoryType from 'types/HistoryType'
// import { useHistory } from 'react-router'

type EditEmailType = {
  email: string
  password: string
}
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 17px;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const BodyContainer = styled.div`
  padding: 40px 150px;
`
const DetailContainer = styled.div`
  margin-bottom: 24px;
`
const HeadDetail = styled.div`
  display: flex;
  font-weight: bold;
  align-items: center;
  margin-bottom: 24px;

  justify-content: space-between;
  button {
    margin-right: 160px;
  }
`
const SubDetail = styled.div`
  display: flex;
  margin-bottom: 35px;
  align-items: center;

  justify-content: space-between;
  button {
    margin-right: 160px;
  }
  > div small {
    color: var(--agt-secondary-text-color);
  }
`
const StyledButtonLink = styled(ButtonLink)`
  button {
    color: var(--agt-primary-color-1);
    background-color: white;
    border: 1px solid var(--agt-primary-color-1);
  }
  button:hover {
    color: var(--agt-dark-sky-color);
    border-color: var(--agt-dark-sky-color);
  }
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
`
const IconCustom = styled(Icon)`
  padding-right: 10px;
`
const Line = styled.hr`
  margin: 40px 0;
`
// const LinkDetail = styled.div`
//   margin-bottom: 24px;
//   a {
//     text-decoration: underline;
//   }
//   p {
//     font-size: 13px;
//     color: #acb1bf;
//   }
// `
const EditEmailContainer = styled.div`
  margin: 64px 32px 24px;
  line-height: 30px;
  text-align: center;
  h4 {
    font-size: 17px;
  }
`
const PopupContainer = styled.div`
  margin: 56px 20px 48px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 352px;
  p {
    text-align: center;
  }
`
const PopupForm = styled.div`
  margin-top: 24px;
  p {
    margin-bottom: 8px;
  }
`
const EmailTextPopup = styled.div`
  color: var(--agt-primary-color-1);
  font-weight: bold;
`

const SettingPage: FC<SettingType> = (props) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const breadCrumbArr: Array<LinkValues> = [{ label: 'ตั้งค่าบัญชี' }]
  const [isConfirmEmailModalOpen, setConfirmEmailModalOpen] = useState<boolean>(false)
  const [isEditEmailModalOpen, setEditEmailModalOpen] = useState<boolean>(false)
  const [isBindGoogleAccountModalOpen, setBindGoogleAccountModalOpen] = useState<boolean>(false)
  const [isBindFacebookAccountModalOpen, setBindFacebookAccountModalOpen] = useState<boolean>(false)
  const [isTempEmailConfirm, setTempEmailConfirm] = useState<boolean>(false)
  const [editEmailValue, setEditEmailValue] = useState<EditEmailType>({
    email: '',
    password: '',
  })

  // let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const setting: SettingType = useSelector((state: RootState) => state.profile.setting)
  useEffect(() => {
    dispatch(getSetting())
  }, [dispatch])
  return (
    <Container>
      <Header>ตั้งค่าบัญชี</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />

      <BodyContainer>
        <DetailContainer>
          <HeadDetail>Email address</HeadDetail>
          <SubDetail>
            {setting.email}
            <button
              className="secondary"
              onClick={() => setEditEmailModalOpen(true)}
              hidden={currentAgent?.agentType === 'guest'}
            >
              แก้ไข
            </button>
          </SubDetail>
          {setting.tempEmail && (
            <SubDetail>
              <div>
                <p>{setting.tempEmail}</p>
                <small>อยู่ระหว่างแก้ไขอีเมลและรอการยืนยันที่อยู่อีเมลนี้</small>
              </div>
              <button
                onClick={() => {
                  setTempEmailConfirm(true)
                  setConfirmEmailModalOpen(true)
                }}
                hidden={currentAgent?.agentType === 'guest'}
              >
                ยืนยันที่อยู่อีเมล
              </button>
            </SubDetail>
          )}
        </DetailContainer>
        <DetailContainer>
          <HeadDetail>
            Password
            <StyledButtonLink link="/setting/reset_password" label="แก้ไข" />
          </HeadDetail>
        </DetailContainer>
        <Line />
        <DetailContainer>
          <HeadDetail>Connected social account</HeadDetail>
          <SubDetail>บัญชีที่คุณสามารถใช้เข้าสู่ระบบเพื่อใช้งาน Agent Portal</SubDetail>
          <SubDetail>
            <IconContainer>
              <IconCustom name="google" />
              ผูกบัญชี Google
            </IconContainer>
            {setting.googleEmail ? (
              <button onClick={() => setBindGoogleAccountModalOpen(true)}>ยกเลิกผูกบัญชี</button>
            ) : (
              <GoogleLogin
                clientId={`${process.env.REACT_APP_APPID_GOOGLE}`}
                render={(renderProps) => (
                  <button className="google secondary" type="button" onClick={renderProps.onClick}>
                    ผูกบัญชี
                  </button>
                )}
                onSuccess={(res) => {
                  let values: { type: string; email: string; token: string } = {
                    type: 'google',
                    email: get(res, 'profileObj.email', ''),
                    token: get(res, 'tokenId', ''),
                  }
                  dispatch(linkAccount(values))
                }}
                onFailure={(err) => console.log('onFailure : ', err)}
              />
            )}
          </SubDetail>
          <SubDetail>
            <IconContainer>
              <IconCustom name="facebook-signin" />
              ผูกบัญชี Facebook
            </IconContainer>
            {setting.facebookEmail ? (
              <button onClick={() => setBindFacebookAccountModalOpen(true)}>ยกเลิกผูกบัญชี</button>
            ) : (
              <FacebookLogin
                appId={`${process.env.REACT_APP_APPID_FACEBOOK}`}
                autoLoad={false}
                callback={(res) => {
                  if (res.userID) {
                    let values: { type: string; email: string; token: string } = {
                      type: 'facebook',
                      email: get(res, 'email', ''),
                      token: get(res, 'accessToken', ''),
                    }
                    dispatch(linkAccount(values))
                  }
                }}
                fields="email"
                render={(renderProps) => (
                  <button
                    className="facebook secondary"
                    type="button"
                    onClick={renderProps.onClick}
                  >
                    ผูกบัญชี
                  </button>
                )}
              />
            )}
          </SubDetail>
        </DetailContainer>

        {setting?.consentHistories && setting.consentHistories.length > 0 && (
          <>
            <Line />
            <DetailContainer>
              <HeadDetail>ข้อตกลงและการขออนุญาตที่ใช้งาน</HeadDetail>
              <ShowHistoriesConsent
                contactId={0}
                className="showHistories"
                consents={setting.consentHistories}
                type="setting"
              />
            </DetailContainer>
          </>
        )}
      </BodyContainer>

      {/* Edit Email Modal */}
      <ModalContainer
        isOpen={isEditEmailModalOpen}
        onCancel={setEditEmailModalOpen}
        onConfirm={() => {
          dispatch(
            changeEmail({ ...editEmailValue, category: 'valid_password' }, () => {
              setEditEmailModalOpen(false)
              setConfirmEmailModalOpen(true)
            }),
          )
        }}
        confirmText="แก้ไขอีเมล"
      >
        <EditEmailContainer>
          <h4>แก้ไขอีเมล</h4>
          <p>เราจะส่งขอการยืนยันอีเมลให้อีเมลใหม่ของคุณ</p>
          <PopupForm>
            <p>อีเมลใหม่</p>
            <input
              type="text"
              style={{ width: '445px' }}
              onChange={(e) =>
                setEditEmailValue({
                  ...editEmailValue,
                  email: e.target.value,
                })
              }
            />
          </PopupForm>
          <PopupForm>
            <p>ยืนยันรหัสผ่าน Agent Tool Platform</p>
            <input
              type="password"
              style={{ width: '445px' }}
              onChange={(e) =>
                setEditEmailValue({
                  ...editEmailValue,
                  password: e.target.value,
                })
              }
            />
          </PopupForm>
        </EditEmailContainer>
      </ModalContainer>

      {/* Confirm Email Modal */}
      <ModalContainer
        isOpen={isConfirmEmailModalOpen}
        onCancel={() => {
          setTempEmailConfirm(false)
          setConfirmEmailModalOpen(false)
        }}
        onConfirm={() => {
          isTempEmailConfirm
            ? dispatch(
                resendConfirmation({ email: setting.tempEmail }, () => {
                  setTempEmailConfirm(false)
                  setConfirmEmailModalOpen(false)
                }),
              )
            : dispatch(
                changeEmail({ ...editEmailValue, category: 'change_email' }, () => {
                  setConfirmEmailModalOpen(false)
                  window.location.reload()
                }),
              )
        }}
        confirmText="ตกลง"
      >
        <PopupContainer>
          <p>ระบบจะส่งอีเมลยืนยันไปยัง</p>
          <EmailTextPopup>
            {isTempEmailConfirm ? setting.tempEmail : editEmailValue.email}
          </EmailTextPopup>
          <p>กรุณายืนยันอีเมลเพื่อบันทึกการเปลี่ยนแปลง</p>
        </PopupContainer>
      </ModalContainer>

      {/* Bind Google Account Modal */}
      <ModalContainer
        isOpen={isBindGoogleAccountModalOpen}
        onCancel={setBindGoogleAccountModalOpen}
        onConfirm={() => dispatch(unlinkAccount({ type: 'google', email: setting.googleEmail }))}
        confirmText="ตกลง"
      >
        <PopupContainer>
          <p>คุณต้องการยืนยันยกเลิกการผูกบัญชี</p>
          <IconContainer>
            <IconCustom name="google" /> Google หรือไม่
          </IconContainer>
          <p>ใช้อีเมลและรหัสผ่านที่ตั้งไว้ในระบบเพื่อเข้าใช้งานในครั้งถัดไป</p>
        </PopupContainer>
      </ModalContainer>

      {/* Bind Facebook Account Modal */}
      <ModalContainer
        isOpen={isBindFacebookAccountModalOpen}
        onCancel={setBindFacebookAccountModalOpen}
        onConfirm={() =>
          dispatch(unlinkAccount({ type: 'facebook', email: setting.facebookEmail }))
        }
        confirmText="ตกลง"
      >
        <PopupContainer>
          <p>คุณต้องการยืนยันยกเลิกการผูกบัญชี</p>
          <IconContainer>
            <IconCustom name="facebook-signin" /> Facebook หรือไม่
          </IconContainer>
          <p>ใช้อีเมลและรหัสผ่านที่ตั้งไว้ในระบบเพื่อเข้าใช้งานในครั้งถัดไป</p>
        </PopupContainer>
      </ModalContainer>
    </Container>
  )
}

export default SettingPage
