import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Consents from 'pages/consents/root'

const Pages: FC = () => (
  <Switch>
    <Route path="/consent" component={Consents} />
  </Switch>
)

export default Pages
