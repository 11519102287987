import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from 'components/Icon'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const CustomButton = styled.button`
  &.today {
    width: 111px;
  }
  &.createNew {
  }
`
const CustomSelect = styled.div`
  &.datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--agt-white-color);
    height: 48px;
    width: 160px;
    border-radius: 4px;
    border: 1px solid var(--agt-secondary-color-2);
    padding: 0 16px;
  }
  &.month {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--agt-white-color);
    height: 48px;
    width: 110px;
    border-radius: 4px;
    border: 1px solid var(--agt-secondary-color-2);
    padding: 0 16px;
  }
  &.tasks {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 190px;
    height: 48px;
    background: var(--agt-background-color-4);
    padding: 0 20px;
    border-radius: 4px;
  }
`
const ChangePage = styled.div`
  display: flex;
  justify-content: space-between;
  height: 48px;
  align-items: center;
  padding: 0px 20px;
  column-gap: 24px;
  .prev,
  .next {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--agt-primary-color-1);
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: var(--agt-white-color);
    border-radius: 4px;
  }
`
const LeftIcon = styled(Icon)`
  height: 20px;
  div {
    height: 20px;
  }
  path {
    stroke: var(--agt-primary-color-1);
  }
`
const RightIcon = styled(LeftIcon)`
  margin-top: -3px;
  transform-origin: center;
  transform: rotate(180deg);
`
const ClockIcon = styled(Icon)`
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: var(--agt-secondary-color-2);
    }
  }
`
const CustomEvent = styled.div`
  margin-top: 112px;
  display: flex;
  flex-direction: column;
  background-color: var(--agt-white-color);
  .task {
    width: 120px;
    height: 24px;
    display: flex;
    background-color: #7d828e40;
  }
  .task .bar {
    width: 24px;
    height: 100%;
    background-color: var(--agt-secondary-color-2);
  }
  .task .label {
    display: flex;
    align-items: center;
    padding: 0 3px;
    color: var(--agt-primary-text-color);
  }
  .event {
    width: 120px;
    height: 24px;
    display: flex;
  }
  .event .bar {
    width: 10px;
    height: 100%;
  }
  .event .label {
    display: flex;
    align-items: center;
    padding: 0 3px;
    color: var(--agt-primary-text-color);
  }
  .event.green {
    background-color: #1bc47d40;
  }
  .event .bar.green {
    background-color: #1bc47d;
  }
  .event.red {
    background-color: #ff786240;
  }
  .event .bar.red {
    background-color: #ff7862;
  }
  .event.yellow {
    background-color: #f1c85d40;
  }
  .event .bar.yellow {
    background-color: #f1c85d;
  }
`
const Sidebar = styled.div`
  width: calc(1120px * 0.25 - 24px);
  border-left: 1px solid var(--agt-background-color-2);
  border-right: 1px solid var(--agt-background-color-2);
  border-bottom: 1px solid var(--agt-background-color-2);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .today {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    padding: 8px 0;
    text-align: center;
  }
  .type {
    color: var(--agt-primary-color-1);
    display: flex;
    align-items: center;
    padding: 0 18px;
    padding-right: 6px;
    height: 48px;
    background-color: var(--agt-background-color-3);
    border-bottom: 1px solid var(--agt-white-color);
  }
  .content {
    background: #ffffff;
    padding: 8px 11px 6px 14px;
  }
  .content.border {
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .content .time {
    display: flex;
    color: var(--agt-secondary-color-2);
    justify-content: flex-end;
    column-gap: 4px;
    align-items: center;
  }
  .content .title {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
  .content .title .color {
    background: #f1c85d;
    border-radius: 4px;
    width: 22px;
    height: 14px;
  }
  .content .data {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
  }
  .content .location {
    color: #7d828e;
    margin-bottom: 10px;
  }
  .content .contact {
    display: flex;
    justify-content: space-between;
  }
  .content .contact .name {
    font-size: 13px;
    border-radius: 4px;
    background: var(--agt-background-color-3);
    padding: 6px 8px;
  }
  .content .topic {
    display: flex;
    align-items: flex-start;
    column-gap: 6px;
  }
  .content .topic .circle {
    border: 1px solid #7d828e;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .content .writer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .content .writer small {
    color: var(--agt-primary-color-1);
  }
  .content .writer .star {
    display: flex;
  }
  .content .writer .star span div > div {
    display: flex;
  }
`

const WebTour = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 9
  const layout = [
    {
      top: '64px',
      right: '-80px',
      width: '191px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '236px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '276px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: '-180px',
      width: '262px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '261px',
      arrow: 'top',
    },
    {
      top: '0px',
      right: '176px',
      width: '195px',
      arrow: 'right',
    },
    {
      top: '112px',
      right: '-236px',
      width: '210px',
      arrow: 'left',
    },
    {
      top: '112px',
      right: '-248px',
      width: '229px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: '282px',
      width: '296px',
      arrow: 'right',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [todayBtnPosition, setTodayBtnPosition] = useState({
    top: '0',
    left: '0',
  })
  const [datepickerPosition, setDatepickerPosition] = useState({
    top: '0',
    left: '0',
  })
  const [selectMonthPosition, setSelectMonthPosition] = useState({
    top: '0',
    left: '0',
  })
  const [changePagePosition, setChangePagePotition] = useState({
    top: '0',
    left: '0',
  })
  const [showTaskPosition, setShowTaskPotition] = useState({
    top: '0',
    left: '0',
  })
  const [createNewPosiotion, setCreateNewPotition] = useState({
    top: '0',
    left: '0',
  })
  const [bigCalendarPosiotion, setBigCalendarPotition] = useState({
    top: '0',
    left: '0',
  })
  const [sidebarPosiotion, setSidebarPotition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('calendar__today-btn', setTodayBtnPosition)
      getPositionById('calendar__datepicker', setDatepickerPosition)
      getPositionById('calendar__select-month', setSelectMonthPosition)
      getPositionById('calendar__change-page', setChangePagePotition)
      getPositionById('calendar__show-task', setShowTaskPotition)
      getPositionById('calendar__create-new', setCreateNewPotition)
      getPositionById('calendar__big-calendar', setBigCalendarPotition)
      getPositionById('calendar__sidebar', setSidebarPotition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>แสดงรายการนัดหมายและงานที่ต้องทำในวันปัจจุบัน</small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>คลิกเพื่อกลับมาวันปัจจุบัน</small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>คลิกเพื่อเลือกเดือนที่ต้องการดูนัดหมาย</small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>คลิกเพื่อปรับมุมมองปฏิทินเป็นเดือนหรือสัปดาห์</small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>
            เลื่อนดูปฏิทิน ก่อนหน้าหรือถัดไป ตามมุมมอง
            <br />
            เดือนหรือสัปดาห์
          </small>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>คลิกเพื่อแสดงหรือซ่อนงานที่ต้องทำในปฏิทิน</small>
        </div>
      )
    } else if (step === 6) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>คลิกเพื่อสร้างนัดหมายกับลูกค้า</small>
        </div>
      )
    } else if (step === 7) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>ตัวอย่างนัดหมายที่ปรากฎในปฏิทิน</small>
        </div>
      )
    } else if (step === 8) {
      return (
        <div className="top">
          <b>นัดหมาย</b>
          <small>ตัวอย่างงานที่ต้องทำที่ปรากฎในปฏิทิน</small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <CustomButton type="button" className="today">
          วันนี้
        </CustomButton>
      )
    } else if (step === 2) {
      return (
        <CustomSelect className="datepicker">
          <div>มกราคม 2563</div>
          <FontAwesomeIcon icon={faChevronDown} color="var(--agt-primary-text-color)" />
        </CustomSelect>
      )
    } else if (step === 3) {
      return (
        <CustomSelect className="month">
          <div>เดือน</div>
          <FontAwesomeIcon icon={faChevronDown} color="var(--agt-primary-text-color)" />
        </CustomSelect>
      )
    } else if (step === 4) {
      return (
        <ChangePage>
          <div className="prev">
            <LeftIcon name="vector_left" />
          </div>
          <div className="next">
            <RightIcon name="vector_left" />
          </div>
        </ChangePage>
      )
    } else if (step === 5) {
      return (
        <CustomSelect className="tasks">
          <input type="checkbox" checked />
          แสดงงานที่ต้องทำ
        </CustomSelect>
      )
    } else if (step === 6) {
      return (
        <CustomButton type="button" className="createNew">
          + สร้างนัดหมาย
        </CustomButton>
      )
    } else if (step === 7) {
      return (
        <CustomEvent>
          <div className="event green">
            <div className="bar green" />
            <div className="label">
              <small>
                <u>นัดหมาย</u>
              </small>
            </div>
          </div>
          <div className="event red">
            <div className="bar red" />
            <div className="label">
              <small>
                <u>นัดหมาย</u>
              </small>
            </div>
          </div>
          <div className="event yellow">
            <div className="bar yellow" />
            <div className="label">
              <small>
                <u>นัดหมาย</u>
              </small>
            </div>
          </div>
        </CustomEvent>
      )
    } else if (step === 8) {
      return (
        <CustomEvent>
          <div className="task">
            <div className="bar" />
            <div className="label">
              <small>
                <u>1 งานที่ต้อ...</u>
              </small>
            </div>
          </div>
        </CustomEvent>
      )
    } else if (step === 9) {
      return (
        <Sidebar>
          <div className="today">
            <b>วันจันทร์ที่ 21 มี.ค. 2565</b>
          </div>
          <div className="type">
            <b>นัดหมาย</b>
          </div>
          <div className="content border">
            <div className="time">
              <ClockIcon name="clock_white" />
              <div>{` 14:00 น.-15:00 น.`}</div>
            </div>
            <div className="title">
              <b>นัดคุยเก็บข้อมูล</b>
              <div className="color" />
            </div>
            <div className="data">
              นัดคุยเพื่อเก็บข้อมูลเพิ่มเติมนัดคุยเพื่อเก็บข้อมูลเพิ่มเติมนัดคุยเพื่อเก็บข้อมูลเพิ่มเติม...
            </div>
            <div className="location">
              <Icon name="location" />
              {` เซนทรัลลาดพร้าว`}
            </div>
            <div className="contact">
              <div className="name">เดซี่ วอล์คเกอร์</div>
              <div className="name">โรดี้แลนด์ (โรดี้)</div>
              <div className="name">+ 2</div>
            </div>
          </div>
          <div className="content">
            <div className="time">
              <ClockIcon name="clock_white" />
              <div>{` 14:00 น.-15:00 น.`}</div>
            </div>
            <div className="title">
              <b>นัดคุยเก็บข้อมูล</b>
              <div className="color" />
            </div>
            <div className="data">
              นัดคุยเพื่อเก็บข้อมูลเพิ่มเติมนัดคุยเพื่อเก็บข้อมูลเพิ่มเติมนัดคุยเพื่อเก็บข้อมูลเพิ่มเติม...
            </div>
            <div className="location">
              <Icon name="location" />
              {` เซนทรัลลาดพร้าว`}
            </div>
            <div className="contact">
              <div className="name">เดซี่ วอล์คเกอร์</div>
              <div className="name">โรดี้แลนด์ (โรดี้)</div>
              <div className="name">+ 2</div>
            </div>
          </div>
          <div className="type">
            <b>งานที่ต้องทำ</b>
          </div>
          <div className="content border">
            <div className="topic">
              <div className="circle" />
              <b>โทรตามเอกสารโทรตามเอ...</b>
            </div>
            <div className="data">
              เตรียมเอกสารเตรียมเอกสารเตรียมเอกสารเตรียม...เตรียมเอกสารเตรียมเอกสารเตรียมเ...
            </div>
            <div className="writer">
              <small>โรแลนด์ แบงส์ (โรดี้)</small>
              <div className="star">
                <Icon name="star" color="var(--agt-primary-text-color)" />
                <Icon name="star" color="var(--agt-primary-text-color)" />
                <Icon name="star" color="var(--agt-primary-text-color)" />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="topic">
              <div className="circle" />
              <b>โทรตามเอกสารโทรตามเอ...</b>
            </div>
            <div className="data">
              เตรียมเอกสารเตรียมเอกสารเตรียมเอกสารเตรียม...เตรียมเอกสารเตรียมเอกสารเตรียมเ...
            </div>
            <div className="writer">
              <small>โรแลนด์ แบงส์ (โรดี้)</small>
              <div className="star">
                <Icon name="star" color="var(--agt-primary-text-color)" />
                <Icon name="star" color="var(--agt-primary-text-color)" />
                <Icon name="star" color="var(--agt-primary-text-color)" />
              </div>
            </div>
          </div>
        </Sidebar>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1) {
      return todayBtnPosition
    } else if (step === 2) {
      return datepickerPosition
    } else if (step === 3) {
      return selectMonthPosition
    } else if (step === 4) {
      return changePagePosition
    } else if (step === 5) {
      return showTaskPosition
    } else if (step === 6) {
      return createNewPosiotion
    } else if (step === 7 || step === 8) {
      return bigCalendarPosiotion
    } else if (step === 9) {
      return sidebarPosiotion
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTour
