import Loader from 'components/Loader'
import useQuery from 'hooks/useQuery'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sendNewEmail } from 'store/appSlice'
import styled from '@emotion/styled'
import { useHistory } from 'react-router'
import { RootState } from 'store/rootReducer'
import { Link } from 'react-router-dom'
import authToken from 'utils/session'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const SuccessContainer = styled.div`
  padding: 50px 0;
`
const BackButton = styled.button`
  width: 352px;
`
const Massage = styled.h4`
  margin-bottom: 50px;
  text-align: center;
`
const ConfirmTitle = styled.h4`
  color: var(--agt-primary-color-1);
  margin-bottom: 24px;
`
const MassageDetail = styled.div`
  font-size: 17px;
`

const ChangeEmail: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const confirmationToken = useQuery().get('confirmation_token')
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  useEffect(() => {
    if (
      confirmationToken &&
      ['tqm_staff', 'affiliate', 'broker', 'guest'].includes(currentAgent?.agentType || '')
    ) {
      authToken.removeAuthToken()
      authToken.removeAuthTqmLifeToken()
      localStorage.removeItem('isFirstSignIn')
      window.location.reload()
    } else if (confirmationToken) {
      setIsLoading(true)
      dispatch(
        sendNewEmail(
          confirmationToken,
          () => {
            setIsSuccess(true)
          },
          () => {
            setIsLoading(false)
          },
          () => {
            history.push('/')
            // http://localhost:52201/change_email?confirmation_token=GG1pyx1uhYg1BCH29F28
          },
        ),
      )
    } else {
      alert('ไม่มี token เพื่อใช้ในการเข้าถึงหน้านี้')
      history.push('/')
    }
  }, [dispatch, confirmationToken, history, currentAgent])

  if (isLoading) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  } else if (isSuccess) {
    return (
      <SuccessContainer>
        <Massage>
          <ConfirmTitle>อีเมลของคุณได้รับการยืนยันแล้ว</ConfirmTitle>
          <MassageDetail>เข้าสู่ระบบเพื่อเริ่มใช้งาน</MassageDetail>
        </Massage>
        <Link to="/login">
          <BackButton className="primary">เข้าสู่ระบบ</BackButton>
        </Link>
      </SuccessContainer>
    )
  } else {
    return <div />
  }
}

export default ChangeEmail
