export const QUESTION = [
  {
    question: `ใช้ระยะเวลานานเท่าไหร่ในการสมัครและขึ้นทะเบียนเป็นตัวแทนกับ TQM`,
    answer: `ไม่เกิน 30 วันหลังจากที่ตัวแทนส่งเอกสารไปยังสาขาใกล้บ้านได้ครบถ้วนเรียบร้อย ทั้งนี้ ตัวแทนจะได้รับ User Account ในการเข้าใช้งาน เพื่อทดลองใช้งานระบบก่อนการอนุมัติตัวแทนได้ในบางฟีเจอร์`,
  },
  {
    question: `จะได้รับส่วนแบ่งรายได้จากการแนะนำผลิตภัณฑ์ของ TQM เป็นสัดส่วนเท่าไหร่`,
    answer: `ไม่เกิน 30 วันหลังจากที่ตัวแทนส่งเอกสารไปยังสาขาใกล้บ้านได้ครบถ้วนเรียบร้อย ทั้งนี้ ตัวแทนจะได้รับ User Account ในการเข้าใช้งาน เพื่อทดลองใช้งานระบบก่อนการอนุมัติตัวแทนได้ในบางฟีเจอร์`,
  },
]
