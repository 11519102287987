import React, { FC, useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById, updateAssets } from 'store/contactSlice'
import { Container, Header, Content, Tab, IconButton } from './AssetDetail.styles'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'routes'
import HistoryType from 'types/HistoryType'
import { DeleteModalComponent } from './AssetDetail.components'

const TAB_MENU = [
  { label: 'รถยนต์', value: 'car' },
  { label: 'บ้าน', value: 'home' },
]

const AssetDetail: FC = () => {
  let { id } = useParams<{ id: string }>()
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const [currentTab, setCurrentTab] = useState<string>('car')
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [assetId, setAssetId] = useState<number | null>(null)

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      path: `/contacts/${contact?.id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      label: 'สินทรัพย์ส่วนตัว',
    },
  ]

  useEffect(() => {
    if (id) dispatch(getContactById(id))
  }, [dispatch, id])

  const confirmDelete = useCallback(
    () => {
      setIsDelete(false)
      if (id && assetId) {
        dispatch(
          updateAssets(id, assetId, () => {
            history.push(`/contacts/${id}/asset`)
          }),
        )
      }
    }, // eslint-disable-next-line
    [dispatch, id, assetId],
  )

  return (
    <Container>
      <Header>
        <h3>สินทรัพย์ส่วนตัว</h3>
      </Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <div className="Assets">
          {TAB_MENU.map((tab, index) => (
            <Tab
              key={index}
              type="button"
              onClick={() => setCurrentTab(tab.value)}
              active={currentTab === tab.value}
            >
              {tab.label}
            </Tab>
          ))}
        </div>
        {currentTab === 'car' && (
          <>
            <DeleteModalComponent
              isDelete={isDelete}
              setIsDelete={setIsDelete}
              contact={contact}
              confirmDelete={confirmDelete}
            />
            <div className="Increase">
              <p>ทั้งหมด 2 คัน</p>
              <Link to={`/contacts/${id}/asset/new`}>
                <button className="" type="button">
                  เพิ่มรถยนต์
                </button>
              </Link>
            </div>
            <div className="Order">
              <div className="Row">
                <div className="Column Name">
                  <b>รถยนต์</b>
                  <p>สีแดงคันใหม่</p>
                </div>
                <div className="Icons">
                  <Link to={`/contacts/${id}/asset/${1}`}>
                    <button className="Icon secondary" type="button">
                      <IconButton name="pencil_icon" />
                    </button>
                  </Link>
                  <button
                    className="Icon secondary"
                    type="button"
                    onClick={() => {
                      setAssetId(1)
                      setIsDelete(true)
                    }}
                  >
                    <IconButton name="bin" />
                  </button>
                </div>
              </div>
              <div className="Row">
                <div className="Column">
                  <b>ปีรถยนต์</b>
                  <p>2562</p>
                </div>
                <div className="Column">
                  <b>ยี่ห้อรถยนต์</b>
                  <p>HONDA</p>
                </div>
              </div>
              <div className="Row">
                <div className="Column">
                  <b>รุ่น</b>
                  <p>CR-V</p>
                </div>
                <div className="Column">
                  <b>รุ่นย่อย</b>
                  <p>Auto/1.6/DT E/4dr/1,500,000</p>
                </div>
              </div>
              <div className="Row">
                <div className="Column">
                  <b>เลขทะเบียนรถยนต์</b>
                  <p>ป้ายแดง</p>
                </div>
                <div className="Column">
                  <b>จังหวัดที่จดทะเบียน</b>
                  <p>เชียงใหม่</p>
                </div>
              </div>
              <div className="Row">
                <div className="Column">
                  <b>เลขตัวถังรถ</b>
                  <p>KFKAOOLGFVDF87785</p>
                </div>
                <div className="RedPlate">
                  <b>เป็นรถป้ายแดง</b>
                </div>
              </div>
              <div className="Insurance">
                <div className="InsuranceOrder">
                  <div className="Name">
                    <Avatar type="circle" avatar={''} width="60px" height="60px" />
                    <div className="InsuranceCompany">
                      <b>ประกันภัย ชั้น1</b>
                      <p>ประกันภัยไทยวิวัฒน์</p>
                    </div>
                  </div>
                  <div className="Status">
                    <div className="OrderStatus">กรมธรรม์มีผลบังคับ</div>
                    <small className="OrderNo">รหัสคำสั่งซื้อ #12345678</small>
                    <div className="OrderType">
                      <Icon name="car" />
                      <b>รถยนต์</b>
                    </div>
                  </div>
                </div>
                <div className="InsuranceAct">
                  <FontAwesomeIcon icon={faCheck} />
                  พ่วง พ.ร.บ.
                </div>
              </div>
            </div>
          </>
        )}
        {currentTab === 'home' && (
          <>
            <div className="Increase">
              <p>ทั้งหมด 0 หลัง</p>
              <button className="" type="button" disabled>
                เพิ่มบ้าน
              </button>
            </div>
          </>
        )}
      </Content>
    </Container>
  )
}

export default AssetDetail
