import styled from '@emotion/styled'

export const CustomModal = styled.div`
  width: 100%;
  min-width: 736px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 34px;
  border-bottom: 1px solid var(--agt-background-color-2);
`

export const Image = styled.img`
  width: 230px;
  margin-bottom: -8px;
  object-fit: cover;
`

export const Content = styled.div`
  width: 100%;
  padding: 24px 96px;
  display: flex;
  flex-direction: column;
  .name {
    display: flex;
    margin-bottom: 16px;
    font-weight: bold;
    width: fit-content;
    color: var(--agt-primary-color-1);
  }
  > p {
    width: 100%;
    height: 100%;
    max-height: 30vh;
    white-space: pre-wrap;
    overflow-y: auto;
    margin-bottom: 16px;
  }
  ul {
    list-style: none;
    margin-bottom: 16px;
  }
  ul li {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }
  ul li label {
    color: var(--agt-primary-color-1);
    text-align: center;
  }
  ul li input {
    margin-top: 4px;
    margin-right: 16px;
  }
  ul li:last-of-type {
    margin-bottom: 0;
  }
  .submit {
    align-self: center;
  }
`

export const CheckedBox = styled.div`
  width: fit-content;
  margin: 14px auto 0px;
  color: var(--agt-primary-color-1);
  input {
    margin-right: 10px;
  }
  u {
    cursor: pointer;
  }
`
