import styled from '@emotion/styled'
import Icon from 'components/Icon'

export const Container = styled.div`
  width: 100%;
  /* max-width: 736px; */
  padding: 50px 0;
  .submit {
    display: block;
    width: 352px;
    margin: 25px auto 0;
  }
`

export const Header = styled.div`
  width: 100%;
  height: 36px;
  margin-bottom: 46px;
  display: flex;
  justify-content: center;
  .headerRegister {
    width: 48%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .headerRegister .secondary {
    color: var(--agt-secondary-color-1) !important;
    text-decoration: none;
  }
  .headerRegister .center {
    border-left: 1px solid var(--agt-secondary-color-1);
    height: 100%;
  }
  .headerWithSocial {
    display: flex;
    align-items: center;
  }
`

export const IconSignIn = styled(Icon)`
  margin-top: 10px;
  margin-right: 10px;
`

export const CustomIcon = styled(Icon)`
  div {
    width: 28px;
    height: 28px;
    margin-right: 24px;
    svg {
      width: 28px;
      height: 28px;
    }
  }
`

export const ContainerSignIn = styled.div`
  width: 352px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 24px;
  .suggest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }
  .suggest .line {
    border: 0px;
    border-top: 1px solid var(--agt-secondary-color-1);
    height: 1px;
    width: 150px;
  }
  .google {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
  .facebook {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
`
