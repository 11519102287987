import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link, useParams } from 'routes'
import api from 'api'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { currency } from 'utils/helper'
import { OpportunityByContact } from '../../opportunities/modal/New'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById, getOptions } from 'store/contactSlice'

type useParamsType = {
  id: string
}
type Protection = {
  label: string
  occupied: number
  suggest: number
}
type PlansType = {
  assetProtection: Array<Protection>
  disabilityProtection: Array<Protection>
  healthProtection: Array<Protection>
  lifeProtection: Array<Protection>
  othersProtection: Array<Protection>
}
type BarType = {
  percent?: number
}
type IndicatorType = {
  suggest?: number
  occupied?: number
}
type BuyType = {
  onClick?: () => void
  disabled?: boolean
}
const displayNumber = (input?: number) => {
  if (!input) return '0'
  return currency(input, 0, '')
}
const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const Topic = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85px;
  background-color: var(--agt-background-color-1);
`
const CoverageTable = styled.table`
  table-layout: fixed;
  width: 100%;
  padding: 20px 0 30px;
  margin-bottom: 48px;
  border-bottom: 1px solid var(--agt-disable-color-1);
  th,
  td {
    padding: 5px 0;
    text-align: left;
    overflow: hidden;
  }
  td > button {
    width: 127px;
  }
  th,
  td.topic {
    height: 48px;
    font-weight: bold;
  }
  th:nth-of-type(2),
  td:nth-of-type(2) {
    padding-left: 33px;
  }
  th:nth-of-type(3),
  td:nth-of-type(3) {
    padding-left: 21px;
  }
  th:nth-of-type(4),
  td:nth-of-type(4) {
    padding-left: 21px;
  }
`
const Remaining = styled.div`
  text-align: right;
`
const BarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--agt-background-color-1);
`
const Bar = styled(BarContainer)<BarType>`
  background-color: var(--agt-success-color);
  transition: transform 0.5s;
  transform: ${(props) =>
    props.percent ? `translateX(-${100 - props.percent}%)` : 'translateX(-100%)'};
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`
const Indicator: FC<IndicatorType> = (props) => {
  const { suggest = 0, occupied = 0 } = props
  const [percent, setPercent] = useState(0)
  const [diff, setDiff] = useState(0)
  const intSuggest = typeof suggest === 'string' ? parseInt(suggest, 10) : suggest
  const intOccupied = typeof occupied === 'string' ? parseInt(occupied, 10) : occupied
  useEffect(() => {
    if (intSuggest <= 0) {
      setPercent(0)
      setDiff(-1)
    }
    if (intSuggest > 0) {
      if (intSuggest > intOccupied) {
        setPercent((intOccupied / intSuggest) * 100)
        setDiff(intSuggest - intOccupied)
      }
      if (intSuggest <= intOccupied) {
        setPercent(100)
        setDiff(0)
      }
    }
  }, [intSuggest, intOccupied, setPercent, setDiff])
  return (
    <>
      <Remaining className="agt-small ghost">
        {diff > -1 && `ขาดอีก ${displayNumber(diff)}`}
      </Remaining>
      <BarContainer title={`${percent}%`}>
        <Bar percent={percent} />
      </BarContainer>
    </>
  )
}
const Buy = authorize(
  (props: BuyType) => {
    const { onClick = () => {}, disabled } = props
    return (
      <button onClick={() => onClick()} className="secondary" disabled={disabled}>
        ซื้อเพิ่ม
      </button>
    )
  },
  { roles: ['tqm_staff', 'affiliate'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)
const getSummary = async (id: number, callback: (plans: PlansType, pipelineId: number) => void) => {
  const response = await api.get(`/contacts/${id}/plans/summary`)
  callback(response.data.data.planData, response.data.data.pipelineId)
}

const Summary: FC = () => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  // eslint-disable-next-line
  const [pipelineId, setPipelineId] = useState<number>()
  const [plans, setPlans] = useState<PlansType>({
    assetProtection: [],
    disabilityProtection: [],
    healthProtection: [],
    lifeProtection: [],
    othersProtection: [],
  })
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    {
      path: `/contacts/${id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: { pathname: `/contacts/${id}/planning`, state: 'planning' },
      label: 'วางแผนประกัน',
    },
    { label: 'สรุปความคุ้มครอง' },
  ]
  useEffect(() => {
    if (id) {
      getSummary(parseInt(id), (plans, pipelineId) => {
        setPlans(plans)
        setPipelineId(pipelineId)
      })
      dispatch(getContactById(id))
      dispatch(getOptions())
    }
  }, [id, dispatch])
  return (
    <Container>
      {/* {isModalOpen && (
        <OpportunitySummary
          isModalOpen={isModalOpen}
          contactId={parseInt(id)}
          pipelineStateId={pipelineId}
          setModalOpen={setModalOpen}
        />
      )} */}
      {isModalOpen && (
        <OpportunityByContact
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          contactId={parseInt(id)}
          contactData={contact}
        />
      )}
      <Header>วางแผนประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Topic>
        <h3>สรุปการวางแผนการจัดการความเสี่ยง</h3>
      </Topic>
      <CoverageTable>
        <col width="245px" />
        <col width="384px" />
        <col width="192px" />
        <col width="156px" />
        <col width="127px" />
        <thead>
          <tr>
            <th>ระดับความคุ้มครอง</th>
            <th>ความคุ้มครอง</th>
            <th>ควรมี(บาท)</th>
            <th>มีแล้ว(บาท)</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="topic" colSpan={5}>
              ความคุ้มครองชีวิต
            </td>
          </tr>
          {plans.lifeProtection.map((life, index) => {
            return (
              <tr key={index}>
                <td>
                  <Indicator suggest={life.suggest} occupied={life.occupied} />
                </td>
                <td>{life.label}</td>
                <td>{displayNumber(life.suggest)}</td>
                <td>{displayNumber(life.occupied)}</td>
                <td>
                  <Buy
                    onClick={() => setModalOpen(!isModalOpen)}
                    disabled={life.occupied >= life.suggest ? true : false}
                  />
                </td>
              </tr>
            )
          })}
          <tr>
            <td className="topic" colSpan={5}>
              ความคุ้มครองทุพพลภาพ
            </td>
          </tr>
          {plans.disabilityProtection.map((disability, index) => {
            return (
              <tr key={index}>
                <td>
                  <Indicator suggest={disability.suggest} occupied={disability.occupied} />
                </td>
                <td>{disability.label}</td>
                <td>{displayNumber(disability.suggest)}</td>
                <td>{displayNumber(disability.occupied)}</td>
                <td>
                  <Buy
                    onClick={() => setModalOpen(!isModalOpen)}
                    disabled={disability.occupied >= disability.suggest ? true : false}
                  />
                </td>
              </tr>
            )
          })}
          <tr>
            <td className="topic" colSpan={5}>
              ความคุ้มครองการเจ็บป่วย
            </td>
          </tr>
          {plans.healthProtection.map((health, index) => {
            return (
              <tr key={index}>
                <td>
                  <Indicator suggest={health.suggest} occupied={health.occupied} />
                </td>
                <td>{health.label}</td>
                <td>{displayNumber(health.suggest)}</td>
                <td>{displayNumber(health.occupied)}</td>
                <td>
                  <Buy
                    onClick={() => setModalOpen(!isModalOpen)}
                    disabled={health.occupied >= health.suggest ? true : false}
                  />
                </td>
              </tr>
            )
          })}
          <tr>
            <td className="topic" colSpan={5}>
              ความคุ้มครองทรัพย์สิน
            </td>
          </tr>
          {plans.assetProtection.map((asset, index) => {
            return (
              <tr key={index}>
                <td>
                  <Indicator suggest={asset.suggest} occupied={asset.occupied} />
                </td>
                <td>{asset.label}</td>
                <td>{displayNumber(asset.suggest)}</td>
                <td>{displayNumber(asset.occupied)}</td>
                <td>
                  <Buy
                    onClick={() => setModalOpen(!isModalOpen)}
                    disabled={asset.occupied >= asset.suggest ? true : false}
                  />
                </td>
              </tr>
            )
          })}
          {/* <tr>
            <td className="topic" colSpan={5}>
              ความคุ้มครองอื่นๆ
            </td>
          </tr>
          {plans.othersProtection.map((others, index) => {
            return (
              <tr key={index}>
                <td>
                  <Indicator suggest={others.suggest} occupied={others.occupied} />
                </td>
                <td>{others.label}</td>
                <td>{displayNumber(others.suggest)}</td>
                <td>{displayNumber(others.occupied)}</td>
                <td>
                  <Buy
                    onClick={() => setModalOpen(!isModalOpen)}
                    disabled={others.occupied >= others.suggest ? true : false}
                  />
                </td>
              </tr>
            )
          })} */}
        </tbody>
      </CoverageTable>
      <ButtonContainer>
        <Link className="ghost" to={`/contacts/${id}/insurance_portfolio`}>
          <button>แก้ไขพอร์ต</button>
        </Link>
      </ButtonContainer>
    </Container>
  )
}

export default Summary
