import React from 'react'
import NumberFormat from 'react-number-format'
import styled from '@emotion/styled'
import { Caption, InputAndUnitContainer, Unit } from './Input'
const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 24px;
`
const CustomInput = styled(NumberFormat)`
  margin-top: 0px;
  padding: 0px;
  padding-left: 16px;
  background: #ffffff;
  border: ${(props) =>
    props.errors ? '1px solid #f45a49 !important' : '1px solid #a0a8bc !important'};
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 214px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  position: relative;
  flex-direction: column;
  color: #393d43;
  background: ${(props) => (props.disabled ? '#EFF1F5' : '')};
  ::placeholder {
    color: #acb1bf;
  }
`

const Currency = (props) => {
  const { min = 0, max = 10000000 } = props
  return (
    <MainLayout className={props.className}>
      {props.caption && <Caption>{props.caption}</Caption>}
      <InputAndUnitContainer>
        <CustomInput
          thousandSeparator={true}
          {...props.field}
          onChange={(e) => {
            props.form.setFieldValue(props.field.name, e.target.value.replace(/(,|-)/g, ''))
          }}
          onBlur={(e) => {
            props.form.setFieldValue(props.field.name, e.target.value.replace(/(,|-)/g, ''))
          }}
          {...props}
          className
          style={{ ...props.style }}
          decimalScale={2}
          isAllowed={(values) => {
            const { floatValue } = values
            if (!floatValue) {
              return true
            } else {
              return floatValue >= min && floatValue <= max
            }
          }}
        />
        <Unit>{props.unit}</Unit>
      </InputAndUnitContainer>
    </MainLayout>
  )
}

export default Currency
