import React, { FC, FormEvent, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Field, Form, useField, Formik } from 'formik'
import Select from 'react-select'
import Api from 'api'
import { Link } from 'routes'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import SetStateType from 'types/SetStateType'
import { dateFormat, currency } from 'utils/helper'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import MoreDetailForm from 'pages/opportunities/components/MoreDetail'
import moment from 'moment'
import isEmpty from 'lodash/isEmpty'
type SearchButtonType = {
  opportunityId?: number
  value?: string
  disabled?: boolean
}
type OrderType = {
  companyName: string
  id: number
  lastUpdatedAt: string
  name: string
  orderNo: string
  premium: string
  status: string
  companyLogoUrl: string
}
type OpportunityType = {
  contactAge: number
  contactAvatar: string
  contactGender: string
  contactId: number
  contactName: string
  id: number
  name: string
  note: string
  orders: OrderType[]
  state: string
  status: null
  updatedAt: string
  createdAt: string
  pipelineStateId: number
}
type OpportunityPropsType = {
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  opportunity: OpportunityType
  setModalOpen: Function
  pipelineStateNameTh: string
  isValid?: any
  dirty?: any
  getOpportunity: Function
  destroyOpp?: Function
}
type ContactType = {
  value: number
  label: string
}
type OrderProps = {
  companyName: string
  name: string
  status: string
  orderNo: string
  premium: string
  lastUpdatedAt: string
  companyLogoUrl: string
}
type OrderListProps = {
  orders: Array<OrderProps>
  opportunityId?: number
  destroyOpp?: Function
}
type ContactComponentType = {
  name: string
  contacts: Array<ContactType>
  opportunityId?: number
  setContact: Function
  isDisabledContact?: boolean
  isDisabledSearch?: boolean
}
type StateButtonProps = {
  stateColor?: string
  disabled?: boolean
}
const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (parseFloat(value) === 0) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
  .more-detail {
    height: 96px;
    width: 352px;
    resize: none;
  }
  .detail-topic {
    display: flex;
    justify-content: space-between;
  }
`
const FieldContainer = styled.div``
const Error = styled.div`
  font-size: 13px;
  color: var(--agt-error-color);
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 32px;
  padding: 32px 0;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 36px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
`
const Topic = styled.div`
  margin-bottom: 4px;
`
const GenderAndAge = styled.div`
  -webkit-box-align: center;
  align-items: center;
`
const ValuesDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  .showMore {
    color: var(--agt-primary-color-1);
    text-decoration: underline;
    cursor: pointer;
  }
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 278px;
  padding: 32px 37px 40px 37px;
  p {
    margin: 0px;
  }
  .normal-text {
    margin-bottom: 8px;
    margin-top: 24px;
  }
  .warning-text {
    margin-top: 10px;
  }
  span {
    p {
      margin: 0px;
    }
  }
`
const OportunitiesName = styled.p`
  font-weight: bold;
  max-width: 255px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const NameInModal = styled.span`
  font-weight: bold;
  color: var(--agt-primary-color-1);
`
const StateText = styled.span`
  color: var(--agt-error-color);
  font-weight: bold;
  .won-text {
    color: var(--agt-primary-color-1);
  }
  .fail-text {
    color: var(--agt-error-color);
  }
`
const Selector = styled(Select)``
const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: var(--agt-primary-text-color);
      }
    }
  }
`
const IconStyle = styled(Icon)`
  margin-right: 18px;
  div {
    height: 32px;
  }
  svg {
    height: 32px;
    path {
      height: 32px;
    }
  }
`
const DetailsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 25px;
  margin-bottom: 30px;
`
const DetailContent = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: right;
  .created-at {
    color: var(--agt-secondary-text-color);
  }
`
const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}
const CustomerStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  button {
    margin: 0px;
  }
`
const StateGroup = styled.div``
const StateButton = styled.button<StateButtonProps>`
  margin-right: 16px;
  &.fail {
    color: var(--agt-error-color);
    border-color: var(--agt-error-color);
    :hover {
      color: ${(props) =>
        props.disabled ? 'var(--agt-disable-color-1)' : 'var(--agt-error-color)'};
      border-color: ${(props) =>
        props.disabled ? 'var(--agt-disable-color-1)' : 'var(--agt-error-color)'};
    }
  }
`
const ContacBox = styled.div`
  background: var(--agt-background-color-1);
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 10px;
`
const OrderContainer = styled.div`
  padding: 0 32px;
  background: var(--agt-background-color-1);
  .sum-premium {
    color: var(--agt-primary-color);
    font-weight: bold;
  }
`
const OrderListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 160px;
  max-height: 160px;
  border-top: 1px solid var(--agt-secondary-color-1);
  border-bottom: 1px solid var(--agt-secondary-color-1);
  .order-name {
    margin-left: 16px;
  }
`
const OrderHeader = styled.div`
  margin-top: 16px;
  font-weight: bold;
`
const OrderFooter = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  justify-content: space-between;
  p {
    margin: 0px;
  }
`
const OrderListContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`
const OrderListFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
  p {
    margin: 0px;
  }
`
const OrderLeftSide = styled.div`
  display: flex;
`
const OrderRightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
const SecondaryText = styled.span`
  color: var(--agt-secondary-text-color);
`
const OrderList = (props: OrderListProps) => {
  const { orders, opportunityId } = props
  let sumPremium = 0
  const sumOrderPremium = (premium: string) => {
    sumPremium = sumPremium + parseInt(premium)
  }
  return (
    <>
      {orders && orders.length > 0 && (
        <OrderContainer>
          <OrderListContainer>
            <OrderHeader>รายการคำสั่งซื้อ</OrderHeader>
            {orders &&
              orders.map((order, key) => (
                <div key={key}>
                  <OrderListContent>
                    <OrderLeftSide>
                      <Avatar avatar={order.companyLogoUrl} />
                      <div className="order-name">
                        <div>{order.name}</div>
                        <SecondaryText>{order.companyName}</SecondaryText>
                      </div>
                    </OrderLeftSide>
                    <OrderRightSide>
                      <div className="agt-small">
                        <span
                          style={
                            order.status === 'ยกเลิก' || order.status === 'พนักงานยกเลิก'
                              ? { color: 'var(--agt-error-color)' }
                              : order.status === 'บริษัทประกันอนุมัติ'
                              ? { color: 'var(--agt-success-color)' }
                              : { color: 'var(--agt-primary-text-color)' }
                          }
                        >
                          {order.status}
                        </span>
                        <SecondaryText>
                          &nbsp; ข้อมูลเมื่อ&nbsp;{dateFormat(order.lastUpdatedAt)}
                        </SecondaryText>
                      </div>
                      <div>฿&nbsp;{currency(order.premium, 0, '')}</div>
                    </OrderRightSide>
                  </OrderListContent>
                  <OrderListFooter>
                    <p
                      className="agt-small"
                      style={
                        order.status === 'ยกเลิก' || order.status === 'พนักงานยกเลิก'
                          ? { color: 'var(--agt-error-color)' }
                          : order.status === 'บริษัทประกันอนุมัติ'
                          ? { color: 'var(--agt-success-color)' }
                          : { color: 'var(--agt-secondary-text-color)' }
                      }
                    >
                      รหัสการขาย&nbsp;
                      {order.orderNo}
                    </p>
                    {order.status !== 'ยกเลิก' && (
                      <Link
                        to={`/opportunities/${opportunityId}/order-detail/${order.orderNo}`}
                        onClick={() => {
                          props.destroyOpp && props.destroyOpp()
                        }}
                      >
                        รายละเอียดคำสั่งซื้อ
                      </Link>
                    )}
                    {sumOrderPremium(order.premium)}
                  </OrderListFooter>
                </div>
              ))}
          </OrderListContainer>
          <OrderFooter>
            <p>ค่าเบี้ยประกันรวม</p>
            <p className="sum-premium">฿&nbsp;{currency(sumPremium, 0, '')}</p>
          </OrderFooter>
        </OrderContainer>
      )}
    </>
  )
}
const getContacts = async (setContacts: SetStateType<Array<ContactType>>) => {
  try {
    const response = await Api.get('/tasks/configs')
    setContacts(response.data.data.contacts)
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
const getContactById = async (id: number, setGetFullContact: any) => {
  try {
    const response = await Api.get(`/contacts/${id}`)
    setGetFullContact(response.data.data.contact)
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
const updateContact = async (id: number, values: any) => {
  try {
    const response = await Api.put(`/contacts/${id}`, values, { isFormData: true })
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
const updateStatus = async (id, status) => {
  try {
    await Api.put(`/opportunities/${id}/update_status`, { status: status })
    window.location.reload(true)
  } catch (err) {
    if (err && err.response) {
      const errorMessages = err.response.data.errors.map((error) => {
        return error.message
      })
      alert(errorMessages.join('\n'))
    }
    throw err
  }
}
const SearchButton = authorize(
  (props: SearchButtonType) => {
    const { opportunityId = '', value = '', disabled = false } = props
    if (disabled) {
      return (
        <button type="button" disabled>
          เพิ่มคำสั่งซื้อ
        </button>
      )
    }
    return (
      <Link to={`/opportunities/${opportunityId}/search-product/${value}`}>
        <button type="button">เพิ่มคำสั่งซื้อ</button>
      </Link>
    )
  },
  { roles: ['tqm_staff'], params: { unauthorizedAction: UnauthorizedAction.DISABLED } },
)
const ContactComponent = ({
  name,
  contacts,
  opportunityId,
  setContact,
  isDisabledContact = false,
  isDisabledSearch = false,
}: ContactComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = contacts.find((select) => select.value === value)
  return (
    <CustomerStyle>
      <Selector
        styles={customSelectStyles}
        value={defaultSeletor}
        onChange={(option: ContactType) => {
          const value = (option as ContactType).value
          setValue(value)
          setContact(option as ContactType)
        }}
        options={contacts}
        isDisabled={isDisabledContact}
      />
      <div hidden={!defaultSeletor}>
        <SearchButton opportunityId={opportunityId} value={value} disabled={isDisabledSearch} />
      </div>
    </CustomerStyle>
  )
}
const getText = (toState: string, name: string, contactName: string, contactAvatar: string) => {
  const contactNameInfo = contactName && contactName.split(' ')
  return (
    <ModalBody>
      <Avatar type="circle" width="100px" height="100px" avatar={contactAvatar} />
      <p className="normal-text">เปลี่ยนสถานะของโอกาสในการขาย</p>
      <OportunitiesName>{name}</OportunitiesName>
      <span>
        <span>ซึ่งเกี่ยวข้องกับ&nbsp;</span>
        {!isEmpty(contactNameInfo) ? (
          <NameInModal>
            <span>{contactNameInfo[0]}</span>
            {!isEmpty(contactNameInfo[1]) ? <span>&nbsp;{contactNameInfo[1]}</span> : null}
            &nbsp;
          </NameInModal>
        ) : null}
        <StateText>
          {toState === 'won' ? (
            <span className="won-text">เป็น ขายสำเร็จ</span>
          ) : (
            <span className="fail-text">เป็น ขายไม่สำเร็จ</span>
          )}
        </StateText>
      </span>
    </ModalBody>
  )
}
function moreDetailContact(getFullContact: any, setUseContact: any, useContact: any) {
  let fact = false
  setUseContact({
    ...useContact,
    id: getFullContact.id,
    nickname: getFullContact.nickname,
    phoneNumber: getFullContact.phoneNumber,
    prefixName: getFullContact.prefixName,
    firstName: getFullContact.firstName,
    lastName: getFullContact.lastName,
    gender: getFullContact.gender,
    birthdate: getFullContact.birthdate,
  })
  if (
    getFullContact.id &&
    getFullContact.prefixName &&
    getFullContact.firstName &&
    getFullContact.lastName &&
    getFullContact.gender &&
    getFullContact.birthdate
  ) {
    fact = true
  }
  return fact
}
function getAgeContact(birthdate: string) {
  let age: number = 0
  let dateToday = moment().format('YYYY-MM-DD')
  let initial = moment(`${birthdate.split('T')[0]}`, 'YYYY-MM-DD')
  let final = moment(`${dateToday}`, 'YYYY-MM-DD')
  age = final.diff(initial, 'years')
  return age
}

const OpportunityForm: FC<OpportunityPropsType> = (props) => {
  const [contacts, setContacts] = useState<Array<ContactType>>([])
  const [isMore, setIsMore] = useState(false)
  const [complete, setComplete] = useState(false)
  const defaultConfirm = {
    isOpen: false,
    name: '',
    contactName: '',
    toState: '',
  }
  const [confirmUpdateStatus, setConfirmOpen] = useState(defaultConfirm)
  const [changedContact, setChangedContact] = useState({
    value: '',
    age: '',
    gender: '',
    label: '',
  })
  const [getFullContact, setGetFullContact] = useState({})
  const [newContact, setNewContact] = useState({
    id: '',
    nickname: '',
    phoneNumber: '',
    prefixName: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthdate: '',
  })

  const [, metaName] = useField('name')
  const [, metaNote] = useField('note')

  const opportunity = props.opportunity
  const orders = props.opportunity && props.opportunity.orders

  useEffect(() => {
    getContacts(setContacts)
  }, [])

  useEffect(() => {
    if (opportunity && changedContact.value === '') {
      getContactById(opportunity.contactId, setGetFullContact)
    } else if (opportunity && changedContact.value !== '') {
      getContactById(parseInt(changedContact.value), setGetFullContact)
    }
  }, [opportunity, changedContact])

  useEffect(() => {
    if (getFullContact) {
      setComplete(moreDetailContact(getFullContact, setNewContact, newContact))
      getContacts(setContacts)
      setIsMore(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFullContact])

  function checkFieldChange() {
    let fact = false
    if (metaName.value !== metaName.initialValue) {
      fact = true
    } else if (metaNote.value !== metaNote.initialValue) {
      fact = true
    }
    return fact
  }

  function checkSelectChange() {
    let fact = false
    if (newContact && opportunity && parseInt(newContact.id) !== opportunity.contactId) {
      fact = true
    }
    return fact
  }

  return (
    <FormValue onSubmit={props.handleSubmit}>
      <div>
        <Header>
          <FieldContainer style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <IconStyle name="note_opps" color={'var(--agt-primary-color-1)'} />
              <Field
                name="name"
                type="text"
                validate={validator('กรุณาระบุหัวข้อ')}
                placeholder={'โปรดระบุ'}
                style={{
                  width: '340px',
                  fontSize: '28px',
                  color: 'var(--agt-primary-color-1)',
                  fontWeight: 'bold',
                }}
              />
            </div>
            <ErrorMessage component={Error} name="name" />
          </FieldContainer>
          {confirmUpdateStatus.isOpen && (
            <Modal
              isOpen={confirmUpdateStatus.isOpen}
              onConfirm={() => updateStatus(opportunity.id, confirmUpdateStatus.toState)}
              onCancel={() => setConfirmOpen(defaultConfirm)}
            >
              {getText(
                confirmUpdateStatus.toState,
                confirmUpdateStatus.name,
                confirmUpdateStatus.contactName,
                opportunity.contactAvatar,
              )}
            </Modal>
          )}
          <CloseButton onClick={() => props.setModalOpen(false)}>
            <Icon name="close_button" />
          </CloseButton>
        </Header>
        <DetailsBox>
          <Topic className="detail-topic">
            <div>โน้ตเพิ่มเติม</div>
            <div>
              {'อัปเดตล่าสุด'}&nbsp;
              {opportunity && dateFormat(opportunity.updatedAt)}
            </div>
          </Topic>
          <DetailContent>
            <Field
              className="more-detail"
              name="note"
              type="text"
              component="textarea"
              placeholder="รายละเอียดเพิ่มเติม"
            />
            <SecondaryText>
              สร้างเมื่อ&nbsp;
              {opportunity && dateFormat(opportunity.createdAt)}
            </SecondaryText>
          </DetailContent>
        </DetailsBox>
        <ContacBox>
          <Topic>ลูกค้าที่เกี่ยวข้อง</Topic>
          <ContactComponent
            name="contactId"
            contacts={contacts}
            setContact={setChangedContact}
            opportunityId={opportunity && opportunity.id}
            isDisabledContact={orders?.length > 0}
            isDisabledSearch={
              checkFieldChange() === true || checkSelectChange() === true || !complete
                ? true
                : false
            }
          />
          {complete ? (
            <ValuesDisplay>
              <GenderAndAge>
                อายุ {getAgeContact(newContact.birthdate)}
                &nbsp; เพศ {newContact.gender === 'M' ? 'ชาย' : 'หญิง'}
              </GenderAndAge>
              <Link
                to={`/contacts/${newContact.id}`}
                onClick={() => {
                  props.destroyOpp && props.destroyOpp()
                }}
              >
                รายละเอียดลูกค้า
              </Link>
            </ValuesDisplay>
          ) : (
            <>
              <ValuesDisplay>
                <div className="showMore" onClick={() => setIsMore(!isMore)}>
                  กรอกรายละเอียดเพื่อค้นหาแบบประกัน
                </div>
              </ValuesDisplay>
              {isMore && (
                <Formik
                  initialValues={{
                    nickname: newContact.nickname,
                    phoneNumber: newContact.phoneNumber,
                    prefixName: newContact.prefixName,
                    firstName: newContact.firstName,
                    lastName: newContact.lastName,
                    gender: newContact.gender,
                    birthdate: newContact.birthdate,
                  }}
                  enableReinitialize
                  onSubmit={(values) => {
                    const modifiedValues = {
                      ...values,
                      birthdate: values.birthdate ? values.birthdate.toString() : null,
                    }
                    updateContact(parseInt(newContact.id), modifiedValues)
                    setTimeout(function () {
                      getContactById(parseInt(newContact.id), setGetFullContact)
                      props.getOpportunity()
                    }, 3000)
                  }}
                >
                  {(props) => {
                    return (
                      <MoreDetailForm
                        handleSubmit={props.handleSubmit}
                        values={props.values}
                        isSubmitting={props.isSubmitting}
                      />
                    )
                  }}
                </Formik>
              )}
            </>
          )}
        </ContacBox>
      </div>
      {orders && orders.length > 0 && (
        <OrderList
          orders={orders}
          opportunityId={opportunity && opportunity.id}
          destroyOpp={props.destroyOpp && props.destroyOpp}
        />
      )}
      {opportunity && !isMore && (
        <ButtonContainer>
          <StateGroup>
            {orders.length > 0 && changedContact.value === '' && checkFieldChange() === false && (
              <StateButton
                className="secondary"
                onClick={() =>
                  setConfirmOpen({
                    isOpen: true,
                    name: opportunity.name,
                    contactName: opportunity.contactName,
                    toState: 'won',
                  })
                }
                type="button"
                disabled={
                  orders?.length === 0
                    ? false
                    : props.pipelineStateNameTh !== 'แจ้งงานแล้ว' ||
                      opportunity.state !== 'completed'
                }
              >
                ขายสำเร็จ
              </StateButton>
            )}
            <StateButton
              className="secondary fail"
              onClick={() =>
                setConfirmOpen({
                  isOpen: true,
                  name: opportunity.name,
                  contactName: opportunity.contactName,
                  toState: 'lose',
                })
              }
              disabled={
                opportunity?.state === 'completed' ||
                opportunity?.state === 'processing' ||
                checkSelectChange() === true ||
                checkFieldChange() === true
              }
              type="button"
            >
              ขายไม่สำเร็จ
            </StateButton>
          </StateGroup>
          <button type="submit" disabled={!props.dirty || !props.isValid}>
            บันทึก
          </button>
        </ButtonContainer>
      )}
    </FormValue>
  )
}

export default OpportunityForm
