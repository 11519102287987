import styled from '@emotion/styled'
import { OtpinputField } from './OTPComponent.type'

export const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
}

export const CustomModal = styled.div<OtpinputField>`
  width: 416px;
  padding: 24px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .OTP {
    margin-bottom: 10px;
    display: flex;
    /* justify-content: space-around; */
    .otpInput {
      width: 40px;
      padding: 0;
      text-align: center;
      margin-right: 14px;
      border-color: ${(props) => props.bordercolor && props.bordercolor};
    }
  }
`

export const SuccessModal = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 24px;
  > p {
    color: var(--agt-primary-color-1);
  }
`

export const ChangePhoneNumber = styled.small`
  color: var(--agt-primary-color-1);
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: var(--agt-dark-sky-color);
  }
`

export const ErrMessage = styled.small`
  color: var(--agt-error-color);
  margin-bottom: 10px;
`
