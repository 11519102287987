import styled from '@emotion/styled'
import { Field, Form } from 'formik'
import Icon from 'components/Icon'
import { FieldValueProps } from './Login.types'

export const AnotherSignIn = styled.div`
  display: flex;
  flex-direction: column;
  .suggest {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    .line {
      border: 0px;
      border-top: 1px solid var(--agt-secondary-color-1);
      height: 1px;
      width: 150px;
    }
  }

  .google {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
  .facebook {
    width: 100%;
    height: 48px;
    margin-bottom: 24px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .label {
      color: var(--agt-primary-color-1);
    }
    :disabled {
      border: 1px solid var(--agt-disable-color-1);
      background: var(--agt-white-color);
      cursor: no-drop;
      .label {
        color: var(--agt-disable-color-2);
      }
      .icon {
        opacity: 0.5;
      }
    }
  }
`

export const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const ErrorBar = styled.div`
  position: absolute;
  z-index: 1;
  top: 80px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  height: 50px;
  color: var(--agt-white-color);
  background: var(--agt-error-color);
  .isTimes {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .isTimes:hover {
    cursor: pointer;
  }
  .resend {
    color: white;
    cursor: pointer;
  }
`

export const IconStyles = styled(Icon)`
  > div > div {
    display: flex;
  }
`

export const IconSignIn = styled(Icon)`
  margin-top: 10px;
  margin-right: 10px;
`

export const SelectTitle = styled.h4`
  width: 100%;
  height: 36px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  .primary {
  }
  .secondary {
    color: var(--agt-secondary-color-1) !important;
    text-decoration: none;
  }
  .center {
    border-left: 1px solid var(--agt-secondary-color-1);
    height: 100%;
  }
`

export const Container = styled.div`
  padding: 50px 0;
`

export const FormValue = styled(Form)`
  width: 100%;
  max-width: 352px;
`

export const FormContainer = styled.div`
  margin: 0 0 24px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 0 8px;
  }
  .error {
    color: var(--agt-error-color);
  }
`

export const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 8px;
`

export const FieldValue = styled(Field)<FieldValueProps>`
  width: 352px;
  border: ${(props) =>
    props.isError
      ? '1px solid var(--agt-error-color) !important'
      : '1px solid var(--agt-secondary-color-1) !important'};
`

export const ErrorContainer = styled.div`
  margin-top: 8px;
`

export const InputContainer = styled.div`
  position: relative;
`

export const IconContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`

export const ForgetPassword = styled.div`
  color: var(--agt-secondary-text-color);
  cursor: pointer;
  :hover {
    color: var(--agt-disable-color-1);
  }
`

export const SubmitButton = styled.button`
  width: 100%;
  margin-bottom: 24px;
`
