import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'routes'
import Icon from './Icon'
type LinkProps = {
  id?: string
  label?: string
  color?: string
  hoverColor?: string
  link?: string
  isIcon?: boolean
  iconName?: string
  size?: string
}

export const LinkContainer = styled(Link)<LinkProps>`
  cursor: pointer;
  font-size: ${(props) => (props.size ? props.size : '17px')};
  align-items: center;
  color: ${(props) => props.color};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.hoverColor};
    span {
      path {
        stroke: ${(props) => props.hoverColor};
      }
    }
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    margin-right: 18px;
  }
`

export default (props: LinkProps) => {
  const { id, label, color, hoverColor, link = '', isIcon, iconName, size }: LinkProps = props

  return (
    <LinkContainer id={id} to={link} size={size} color={color} hoverColor={hoverColor}>
      {isIcon && iconName && <Icon name={iconName} />}
      {label}
    </LinkContainer>
  )
}
