import React, { FC } from 'react'
import Modal from 'react-modal'
import Icon from '../Icon'
import { ContactOpportunityTypes } from './ContactOppModal.type'
import { ModalCustomStyle, Header, CloseButton } from './ContactOppModal.styles'
import { NewContact } from './ContactOppModal.components'

const ContactOpportunity: FC<ContactOpportunityTypes> = (props) => {
  const { isOpen, onCancel, setModalOpen, contactData, getContactByIdAgain } = props
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <Header>
        <h4 style={{ color: 'var(--agt-primary-color-1)' }}>
          กรอกข้อมูลผู้มุ่งหวังเพื่อดำเนินการต่อ
        </h4>
        <CloseButton onClick={() => onCancel(false)}>
          <Icon name="close_button" />
        </CloseButton>
      </Header>
      <NewContact
        contactData={contactData}
        forOpps={true}
        isClose={() => onCancel(false)}
        isOpps={() => setModalOpen(true)}
        getContactByIdAgain={getContactByIdAgain}
      />
    </Modal>
  )
}

export default ContactOpportunity
