import React, { FC, useState, useCallback, useRef, useMemo } from 'react'
import styled from '@emotion/styled'
import useClickOutside from 'hooks/useClickOutside'

import DateSelector from './DateSelector'
import MonthSelector from './MonthSelector'
import QuarterSelector from './QuarterSelector'
import YearSelector from './YearSelector'
import Icon from 'components/Icon'

import {
  TODAY,
  THIS_YEAR,
  THIS_MONTH,
  THIS_QUARTER,
  PERIOD_OPTIONS,
  modifiedDatePickerValue,
  getDatePickerValue2String,
} from './DatePicker.utils'
import {
  MonthlyType,
  QuarterlyType,
  DatePickerType,
  DatePickerPopupType,
  DatePickerValueType,
} from 'types/DatePickerType'

const Container = styled.div`
  position: relative;
  width: 100%;
`
const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 4px;
  border: 1px solid var(--agt-secondary-color-1);
`
const Text = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Label = styled.label`
  color: var(--agt-primary-color-1);
  font-weight: bold;
`
const IconCalendar = styled(Icon)`
  width: 23px;
  height: 23px;
`

const InitialValues: DatePickerValueType = {
  periodType: 'daily',
  date: TODAY,
  monthly: { month: THIS_MONTH, year: THIS_YEAR },
  quarterly: { quarter: THIS_QUARTER, year: THIS_YEAR },
  yearly: THIS_YEAR,
}
const DatePicker: FC<DatePickerType> = ({ className = '', onSubmit = () => {} }) => {
  const [submitValue, setSubmitValue] = useState<DatePickerValueType>(InitialValues)
  const [showPopup, setShowPopup] = useState<boolean>(false)
  const [value, setValue] = useState<DatePickerValueType>(InitialValues)
  const [disabledButton, setDisabledButton] = useState<boolean>(true)

  const _handleChange = useCallback(
    (nextValue) => {
      setValue({ ...value, ...nextValue })
      setDisabledButton(false)
    },
    [value, setValue],
  )

  const _handleOnClick = useCallback(() => {
    setShowPopup(true)
  }, [setShowPopup])

  const _handleOnClose = useCallback(() => {
    setShowPopup(false)
    setDisabledButton(true)
  }, [setShowPopup])

  const _handleSubmit = useCallback(() => {
    setSubmitValue(value)
    setShowPopup(false)
    setDisabledButton(true)
    onSubmit({ periodType: value.periodType, summittedAt: modifiedDatePickerValue(value) })
  }, [value, setShowPopup, setSubmitValue, onSubmit])

  const textValue = useMemo(() => {
    return getDatePickerValue2String(submitValue)
  }, [submitValue])

  return (
    <Container className={className}>
      <ValueContainer onClick={_handleOnClick}>
        <Text>
          <Label>{textValue.periodText}</Label>
          {` `}
          <span>{textValue.periodValue}</span>
        </Text>
        <IconCalendar name="calendarDatePicker" color="var(--agt-primary-text-color);" />
      </ValueContainer>
      <DatePickerPopup
        isShow={showPopup}
        onClose={_handleOnClose}
        onSubmit={_handleSubmit}
        onChange={_handleChange}
        value={value}
        disabledButton={disabledButton}
      />
    </Container>
  )
}

export default DatePicker

const DatePickerContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: calc(100% + 10px);
  left: 0;
  width: 364px;
  min-height: 416px;
  padding: 1rem 1rem 1.5rem;
  border-radius: 4px;
  border: 1px solid var(--agt-secondary-color-1);
  background-color: white;
`
const SelectorContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
  border-radius: 5px;
  overflow: hidden;
`
const Selector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 40px;
  color: var(--agt-primary-color-1);
  font-weight: bold;
  background-color: var(--agt-background-color-3);
  cursor: pointer;

  &.selected {
    background-color: var(--agt-primary-color-1);
    color: white;
  }
`
const SelectButton = styled.button`
  display: block;
  margin: 0 auto;
`

const DatePickerPopup: FC<DatePickerPopupType> = ({
  value,
  isShow = false,
  onClose = () => {},
  onSubmit = () => {},
  onChange = () => {},
  disabledButton = true,
}) => {
  const { periodType, date, monthly, quarterly, yearly } = value
  const popupRef = useRef(null)

  useClickOutside(popupRef, () => {
    if (isShow) onClose()
  })

  return (
    <DatePickerContainer ref={popupRef} style={{ visibility: isShow ? 'visible' : 'hidden' }}>
      <SelectorContainer>
        {PERIOD_OPTIONS.map((option, index) => (
          <Selector
            key={index}
            className={periodType === option.value ? 'selected' : ''}
            onClick={() => {
              onChange({ periodType: option.value })
            }}
          >
            {option.label}
          </Selector>
        ))}
      </SelectorContainer>
      {periodType === 'daily' && (
        <DateSelector value={date} onChange={(value: Date) => onChange({ date: value })} />
      )}
      {periodType === 'monthly' && (
        <MonthSelector
          value={monthly}
          onChange={(value: MonthlyType) => onChange({ monthly: value })}
        />
      )}
      {periodType === 'quarterly' && (
        <QuarterSelector
          value={quarterly}
          onChange={(value: QuarterlyType) => onChange({ quarterly: value })}
        />
      )}
      {periodType === 'yearly' && (
        <YearSelector value={yearly} onChange={(value: number) => onChange({ yearly: value })} />
      )}
      <SelectButton type="button" onClick={() => onSubmit()} disabled={disabledButton}>
        เลือก
      </SelectButton>
    </DatePickerContainer>
  )
}
