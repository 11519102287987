import styled from '@emotion/styled'
import Icon from 'components/Icon'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .GetImage {
    max-height: 840px;
    max-width: 620px;
  }
`

export const ShieldIcon = styled(Icon)`
  margin-top: 7px;
  svg {
    width: 17px;
    height: 20px;
  }
`

export const NewForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  &.ta {
    max-width: unset;
    margin: 16px 0 24px;
    border-bottom: 0;
  }
  &.ta .detail {
    width: 100%;
    max-width: 756px;
    margin: 0 auto;
  }
  &.ta .button {
    width: 100%;
    max-width: 756px;
    margin: 0 auto;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  &.ta .button .ImageButton {
    margin: 0 auto 24px;
  }
  &.ta .table {
    margin-bottom: 48px;
  }
  &.ta .table > p {
    text-align: center;
    font-weight: bold;
    color: var(--agt-primary-color-1);
    margin: 24px 0 8px;
  }
  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    column-gap: 24px;
  }
  .Row .Group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 356px;
    padding-bottom: 4px;
    border-bottom: 1px solid #d2d4db;
  }
  .Row .Group b {
    color: var(--agt-primary-text-color);
    margin-bottom: 8px;
  }
  .remark {
    margin-bottom: 48px;
  }
  .remark .Group {
    max-width: 756px;
  }
  .sumInsured {
    margin-bottom: 48px;
  }
  .Row .orderNoTqm {
    width: 100%;
    max-width: 356px;
    display: flex;
    column-gap: 24px;
  }
  .Row .orderNoTqm .orderNo {
    max-width: 158px;
  }
  .CarDetail {
    background: var(--agt-background-color-1);
    border-radius: 8px;
    padding: 24px;
    margin-bottom: 32px;
  }
  .CarDetail .Icon {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .CarDetail .CarForm .Group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 356px;
    padding-bottom: 4px;
    border-bottom: 1px solid #d2d4db;
  }
  .CarDetail .CarForm .carName {
    max-width: 756px;
  }
  .CarDetail .CarForm .Row .redPlate {
    border-bottom: unset;
    justify-content: space-between;
  }
  .CarDetail .CarForm .Row .redPlate {
    border-bottom: unset;
    justify-content: space-between;
  }
  .CarDetail .CarForm .Row .redPlate p {
    font-weight: bold;
    margin-bottom: 0;
  }
  .DiverDetail,
  .LiabilityInsurance,
  .DamagedCar,
  .MedicalFee,
  .TemporaryDisability,
  .PermanentDisability {
    margin-bottom: 12px;
  }
  .DiverDetail > p,
  .LiabilityInsurance > p,
  .DamagedCar > p,
  .MedicalFee > p,
  .TemporaryDisability > p,
  .PermanentDisability > p {
    font-weight: bold;
    margin-bottom: 16px;
  }
  .MedicalFee .Row.MoreDetails {
    flex-wrap: wrap;
    gap: 24px;
  }
  .ImageButton {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .TravelType {
    margin-bottom: 12px;
  }
  .TravelType > p {
    font-weight: bold;
    margin-bottom: 16px;
  }
  .TravelType .Title .Group {
    border-bottom: unset;
  }
`

export const ActForm = styled.div`
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  margin-bottom: 24px;
  > p {
    margin-bottom: 24px;
    font-weight: bold;
  }
  .Detail {
    background: var(--agt-white-color);
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 4px;
    padding: 16px 28px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .Detail .Row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Detail .Row p {
    width: 40%;
  }
`

export const NewButton = styled.div`
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Table = styled.table`
  width: 100%;
  max-width: 928px;
  margin: 0 auto 24px;
  border: 1px solid #e6e8ec;
  border-radius: 5px;
  border-collapse: collapse;
  /* table,
  th,
  td {
    border: 1px solid #e6e8ec;
    border-collapse: collapse;
  } */
  thead {
    width: 100%;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
  }
  thead tr td {
    text-align: center;
    padding: 10px 0;
  }
  thead tr > td:first-of-type {
    width: 62%;
    max-width: 578px;
  }
  thead tr > td:last-of-type {
    width: 38%;
  }
  tbody tr td {
    padding: 10px 24px;
    border: 1px solid #e6e8ec;
  }
  tbody .group {
    text-align: center;
    vertical-align: center;
  }
`

export const SmallText = styled.div`
  text-align: center;
`
