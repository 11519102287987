import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationsThunk } from 'store/store'
import Api from 'api'
import { NotificationState } from 'types/NotificationType'

const initialState: NotificationState = {
  initialized: false,
  error: null,
  notifications: [],
}

const contactsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    initializeNotifications(state) {
      state.initialized = true
    },
    getNotificationsSuccess(state, action: PayloadAction<NotificationState>) {
      state.notifications = action.payload.notifications
      state.error = null
    },
    getNotificationsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  initializeNotifications,
  getNotificationsSuccess,
  getNotificationsFailed,
} = contactsSlice.actions

export default contactsSlice.reducer

export const getNotification = (): NotificationsThunk => async (dispatch) => {
  try {
    const response = await Api.get('/notifications')
    dispatch(getNotificationsSuccess(response.data.data))
  } catch (err) {
    dispatch(getNotificationsSuccess(err.toString()))
  }
}

export const updateRead = (ids: number[]): NotificationsThunk => async (dispatch) => {
  try {
    await Api.patch(`/notifications/mark_as_read`, { ids })
    // dispatch(getNotificationsSuccess(response.data.data))
  } catch (err) {
    dispatch(getNotificationsSuccess(err.toString()))
  }
}
export const updateCheck = (ids: number[]): NotificationsThunk => async (dispatch) => {
  try {
    const response = await Api.patch(`/notifications/mark_as_check`, { ids })
    dispatch(getNotificationsSuccess(response.data.data))
    // dispatch(getNotificationsSuccess(response.data.data))
  } catch (err) {
    dispatch(getNotificationsSuccess(err.toString()))
  }
}
