import React, { FC, FormEvent, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useField } from 'formik'
import Select from 'react-select'
import Api from 'api'
import SetStateType from 'types/SetStateType'

type Tasks = {
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  isDisabledContact?: boolean
  isSubmitting?: boolean
}
type OpportunityType = {
  value: number
  label: string
}
type OpportunityComponentType = {
  name: string
  contacts: Array<OpportunityType>
  isDisabledContact?: boolean
}
const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100vw;
`
const GroupFrom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 32px;
  .topic-field {
    width: 352px;
  }
  .more-detail {
    height: 96px;
    width: 352px;
  }
`
const Div = styled.div`
  padding: 20px;
`
const ButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #a0a8bc;
  width: 100%;
  padding-top: 24px;
`
const FieldValue = styled(Field)`
  resize: none;
`
const Error = styled.div`
  color: var(--agt-error-color);
`
const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
`
const Topic = styled.div`
  margin-bottom: 4px;
  margin-top: 24px;
`
const Selector = styled(Select)`
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid #A0A8BC',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid #A0A8BC',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}
function validateTopic(value) {
  let error
  if (!value) {
    error = 'กรุณาระบุหัวข้อ'
  }
  return error
}
function validateInvolved(value) {
  let error
  if (!value) {
    error = 'กรุณาระบุลูกค้าที่เกี่ยวข้อง'
  }
  return error
}
const getOpportunity = async (setOpportunitys: SetStateType<Array<OpportunityType>>) => {
  try {
    const response = await Api.get('/tasks/configs')
    setOpportunitys(response.data.data.contacts)
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
const OpportunityComponent = ({
  name,
  contacts,
  isDisabledContact = false,
}: OpportunityComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = contacts.find((select) => select.value === value)
  return (
    <div>
      <Selector
        value={defaultSeletor}
        onChange={(option: OpportunityType) => {
          const value = (option as OpportunityType).value
          setValue(value)
        }}
        isDisabled={isDisabledContact}
        options={contacts}
        styles={customSelectStyles}
        placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
      />
    </div>
  )
}

const OpportunityForm: FC<Tasks> = (props) => {
  const [contacts, setOpportunitys] = useState<Array<OpportunityType>>([])
  useEffect(() => {
    getOpportunity(setOpportunitys)
  }, [])
  return (
    <FormValue onSubmit={props.handleSubmit}>
      <GroupFrom>
        <FieldGroup>
          <Topic>หัวข้อ</Topic>
          <FieldValue
            className="topic-field"
            name="name"
            type="string"
            placeholder="โปรดระบุ"
            validate={validateTopic}
          />
          <ErrorMessage component={Error} name="name" />
        </FieldGroup>
        <FieldGroup>
          <Topic>โน้ตเพิ่มเติม</Topic>
          <FieldValue
            className="more-detail"
            name="note"
            type="string"
            component="textarea"
            placeholder="รายละเอียดเพิ่มเติม"
          />
          <ErrorMessage component={Error} name="note" />
        </FieldGroup>
        <FieldGroup>
          <Topic>ลูกค้าที่เกี่ยวข้อง</Topic>
          <OpportunityComponent
            name="contactId"
            contacts={contacts}
            isDisabledContact={props?.isDisabledContact}
          />
          <FieldValue
            hidden={true}
            name="contactId"
            type="string"
            placeholder="โปรดระบุ"
            validate={validateInvolved}
          />
          <ErrorMessage component={Error} name="contactId" />
        </FieldGroup>
      </GroupFrom>
      <Div>
        <ButtonBlock>
          <button type="submit" disabled={props.isSubmitting}>
            {props.isSubmitting ? 'กำลังดำเนินการ' : 'สร้าง'}
          </button>
        </ButtonBlock>
      </Div>
    </FormValue>
  )
}

export default OpportunityForm
