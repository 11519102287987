import moment from 'moment'
import React, { FC } from 'react'
import {
  diffTravelDate,
  displayAddress,
  formatPolicyForm,
  formatTravelType,
  formatTravelTypeCountry,
} from './Detail.utils'
import { Container } from './ShowDetail.styles'
import { InputComponent } from './ShowDetail.components'
import { ShowDetailType } from './ShowDetail.type'

const ShowDetail: FC<ShowDetailType> = (props) => {
  const { order, options, optionsByTypeTa } = props

  return (
    <Container>
      <div className="topic">กรอกข้อมูลผู้เอาประกันภัย</div>
      <div className="padding">
        {order?.order.taInsurance.travelTypeCountry === 'OUT' && (
          <div className="travel">
            <b>รายละเอียดการเดินทาง</b>
            <label>
              การเดินทาง :{' '}
              {order?.order.taInsurance.travelTypeCountry
                ? formatTravelTypeCountry(order.order.taInsurance.travelTypeCountry)
                : '-'}
            </label>
            <label>
              ประเทศปลายทาง :{' '}
              {(optionsByTypeTa?.taCountries || []).find(
                (e) => e.value === order?.order?.taInsurance?.countryId.toString(),
              )?.label || '-'}
            </label>
            <label>จำนวนผู้เดินทาง : {order?.order.taInsurance.travelPassenger || ''}</label>
            <label>
              ประกันภัยท่องเที่ยว :{' '}
              {order?.order.taInsurance.travelType
                ? formatTravelType(order.order.taInsurance.travelType)
                : '-'}
            </label>
            <label>
              วันที่ออกเดินทาง :{' '}
              {moment(order.order.taInsurance.travelDateFrom).format('DD/MM/YYYY')}
            </label>
            <label>
              วันที่กลับถึงไทย : {moment(order.order.taInsurance.travelDateTo).format('DD/MM/YYYY')}{' '}
              (
              {diffTravelDate(
                moment(order.order.taInsurance.travelDateFrom),
                moment(order.order.taInsurance.travelDateTo),
              )}{' '}
              วัน)
            </label>
          </div>
        )}
        {order?.order.taInsurance.travelTypeCountry === 'IN' && (
          <div className="travel">
            <b>รายละเอียดการเดินทาง</b>
            <label>
              การเดินทาง :{' '}
              {order?.order.taInsurance.travelTypeCountry
                ? formatTravelTypeCountry(order.order.taInsurance.travelTypeCountry)
                : '-'}
            </label>
            <label> </label>
            <label>จำนวนผู้เดินทาง : {order?.order.taInsurance.travelPassenger || ''}</label>
            <label>
              ประกันภัยท่องเที่ยว : {formatTravelType(order.order.taInsurance.travelType)}
            </label>
            <label>
              จังหวัดต้นทาง :{' '}
              {(options?.provinceOptions || []).find(
                (e) => e.value === order.order.taInsurance.travelCountryFrom,
              )?.label || '-'}
            </label>
            <label>
              จังหวัดปลายทาง :{' '}
              {(options?.provinceOptions || []).find(
                (e) => e.value === order.order.taInsurance.travelCountryTo,
              )?.label || '-'}
            </label>
            <label>
              วันที่ออกเดินทาง :{' '}
              {moment(order.order.taInsurance.travelDateFrom).format('DD/MM/YYYY')}
            </label>
            <label>
              วันที่กลับถึงไทย : {moment(order.order.taInsurance.travelDateTo).format('DD/MM/YYYY')}{' '}
              (
              {diffTravelDate(
                moment(order.order.taInsurance.travelDateFrom),
                moment(order.order.taInsurance.travelDateTo),
              )}{' '}
              วัน)
            </label>
          </div>
        )}
        {(order?.order.taInsurance.orderPolicyBeneficiary || []).map((a, index: number) => (
          <div className="assured">
            <b>
              {order?.order.taInsurance.orderPolicyBeneficiary.length === 1
                ? 'ข้อมูลผู้เดินทาง (ผู้เอาประกัน)'
                : `ข้อมูลผู้เดินทาง (ผู้เอาประกัน) ลำดับที่ ${index + 1}`}
            </b>
            {index === 0 && (
              <div className="half">
                <InputComponent
                  title={'คุณเป็นบุคคลเดียวกับผู้ซื้อ ใช่หรือไม่'}
                  content={a.isSameOwner ? 'ใช่' : 'ไม่ใช่'}
                />
              </div>
            )}
            <InputComponent title={'คำนำหน้าชื่อ'} content={a.prefixName} width={160} />
            <InputComponent title={'ชื่อจริง'} content={a.firstName} width={272} />
            <InputComponent title={'นามสกุล'} content={a.lastName} width={272} />
            <InputComponent
              title={'วันเกิด'}
              content={moment(a.birthdate).format('DD/MM/YYYY')}
              width={230}
            />
            <InputComponent title={'เลขบัตรประชาชน'} content={a.idCard} width={230} />
            <InputComponent
              title={'สัญชาติ'}
              content={
                (options?.nationalityOptions || []).find((e) => e.value === a.nationality)?.label ||
                '-'
              }
              width={230}
            />
            <InputComponent title={'อีเมล'} content={a.email} width={352} />
            <InputComponent title={'เบอร์โทรศัพท์'} content={a.phoneNumber} width={352} />
            <div className="half">
              <InputComponent
                title={'ผู้รับผลประโยชน์ ทายาทโดยธรรม'}
                content={a.statutoryHeir ? 'ใช่' : 'ไม่ใช่'}
              />
            </div>
            {a.statutoryHeir ? null : (
              <>
                <InputComponent title={'ผู้รับผลประโยชน์'} content={a.beneficiary} width={352} />
                <InputComponent
                  title={'ความสัมพันธ์'}
                  content={
                    (options?.familyRelationshipOptions || []).find((e) => e.value === a.relations)
                      ?.label || '-'
                  }
                  width={352}
                />
              </>
            )}
          </div>
        ))}
        <div className="address">
          <InputComponent
            title={'ที่อยู่บนกรมธรรม์'}
            content={
              order?.order?.taInsurance?.policyAddress
                ? displayAddress(order.order.taInsurance.policyAddress)
                : '-'
            }
          />
          <InputComponent
            title={'รูปแบบกรมธรรม์'}
            content={
              order?.order.taInsurance.policyForm
                ? formatPolicyForm(order.order.taInsurance.policyForm)
                : '-'
            }
            width={352}
          />
        </div>
        {order?.order.taInsurance.travelBy === 'plane' && (
          <div className="flight">
            <b>ข้อมูลเที่ยวบัน (กรณีมี)</b>
            <InputComponent
              title={'วันที่เดินทางไป'}
              content={
                order?.order.taInsurance.travelDateFrom
                  ? moment(order.order.taInsurance.travelDateFrom).format('DD/MM/YYYY')
                  : '-'
              }
              width={352}
            />
            <InputComponent
              title={'หมายเลขเที่ยวบินขาไป'}
              content={order?.order.taInsurance.outboundFlightNumber || '-'}
              width={352}
            />
            <InputComponent
              title={'วันที่เดินทางกลับ'}
              content={
                order?.order.taInsurance.travelDateTo
                  ? moment(order.order.taInsurance.travelDateTo).format('DD/MM/YYYY')
                  : '-'
              }
              width={352}
            />
            <InputComponent
              title={'หมายเลขเที่ยวบินขากลับ'}
              content={order?.order.taInsurance.returnFlightNumber || '-'}
              width={352}
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default ShowDetail
