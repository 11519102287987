import styled from '@emotion/styled'
import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .Header {
    margin: 16px 0 10px;
  }
  .Body {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .Body .Content {
    width: ${(764 / 1120) * 100}%;
    max-width: 764px;
    padding-top: 24px;
    padding-right: 32px;
  }
  .Body .Content .Submit {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .Body .Sub {
    border-left: 1px solid var(--agt-secondary-color-1);
    border-right: 1px solid var(--agt-secondary-color-1);
    width: ${(254 / 1120) * 100}%;
    max-width: 254px;
    height: 100%;
  }
  .Body .RowLayout {
    display: flex;
    justify-content: space-between;
  }
  .Body .RowLayout .Column {
    display: flex;
    flex-direction: column;
    width: 352px;
    margin-bottom: 16px;
  }
  .Body .RowLayout .SearchInsurance {
    width: 352px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .Body .Orders {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    background: var(--agt-background-color-1);
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .hint {
    position: absolute;
    top: 64px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`

export const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
