import React, { FC, memo, useMemo } from 'react'
import { Bar, Line } from 'react-chartjs-2'

import {
  dateBarOptions,
  monthlyBarOptions,
  quarterlyBarOptions,
  yearlyLineOptions,
  prepareData,
  getDailyLabels,
  getMonthlyLabels,
  getQuarterlyLabels,
} from './DashboardGraph.utils'
import { MONTH_SHORT_TH } from 'components/Inputs/DatePicker/DatePicker.utils'
import { DashboardGraphType } from 'types/DashboardType'

const DashboardGraph: FC<DashboardGraphType> = (props) => {
  const { searchValue, graph } = props

  const renderGraph = useMemo(() => {
    const { periodType, summittedAt } = searchValue.date || {}
    switch (periodType) {
      case 'daily':
        const dailyLabels = getDailyLabels(summittedAt || {})
        const prepareDailyData = prepareData(dailyLabels, graph, searchValue)
        return <Bar data={prepareDailyData} options={dateBarOptions} />
      case 'monthly':
        const monthlyLabels = getMonthlyLabels(summittedAt || {})
        const prepareMonthlyData = prepareData(monthlyLabels, graph, searchValue)
        return <Bar data={prepareMonthlyData} options={monthlyBarOptions} />
      case 'quarterly':
        const quarterlyLabels = getQuarterlyLabels(summittedAt || {})
        const prepareQuarterlyData = prepareData(quarterlyLabels, graph, searchValue)
        return <Bar data={prepareQuarterlyData} options={quarterlyBarOptions} />
      case 'yearly':
        const prepareYearlyData = prepareData(MONTH_SHORT_TH, graph, searchValue)
        return <Line data={prepareYearlyData} options={yearlyLineOptions} />
      default:
        return <div />
    }
  }, [graph, searchValue])

  return <div>{renderGraph}</div>
}

export default memo(DashboardGraph)
