import React, { FC, FormEvent, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useField, useFormikContext } from 'formik'
import makeAnimated from 'react-select/animated'
import DatePickerTime from 'react-datepicker'
import subDays from 'date-fns/subDays'
import Select from 'react-select'
import Api from 'api'

import { DatePickerComponent as DatePicker } from 'components/DatePicker'

import SetStateType from 'types/SetStateType'
import { validator } from 'pages/calendar/utils'

const animatedComponents = makeAnimated()
const FormValue = styled(Form)`
  display: grid;
  justify-content: center;
`
const FieldContainer = styled.div`
  width: 736px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  margin-bottom: 24px;
  padding-bottom: 24px;
`
const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const FieldValue = styled(Field)`
  width: 100%;
`
const ColorFieldValue = styled.div`
  width: 145px;
  margin-top: 25px;
`
const RepeatOption = styled.div`
  display: flex;
  background: #ffffff;
  mix-blend-mode: normal;
  height: 48px;
  width: 120px;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  align-items: center;
  margin-top: 58px;
  margin-right: 24px;
  input[type='checkbox'] {
    margin: 15px;
  }
`
const NotEndDateOption = styled.div`
  display: flex;
  background: #ffffff;
  mix-blend-mode: normal;
  height: 48px;
  width: 180px;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  align-items: center;
  margin-top: 58px;
  margin-right: 24px;
  input[type='checkbox'] {
    margin: 15px;
  }
`
const DatePickerStyle = styled(DatePicker)`
  width: 172px;
`
const DatePickerTimeStyle = styled(DatePickerTime)`
  width: 172px;
  :disabled {
    background: #ffffff;
    color: var(--agt-secondary-color-1);
  }
`
const Error = styled.div`
  color: var(--agt-error-color);
`
const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  .file-name {
    justify-content: space-between;
    width: 340px;
  }
  .more-detail {
    padding-top: 11px;
    height: 208px;
    left: 0px;
    top: 0px;
    border-radius: 4px;
  }
`
const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: flex;
`
const RowCustomGroup = styled.div`
  display: flex;
  padding-right: 24px;
`
const Topic = styled.div`
  .disabled {
    color: var(--agt-disable-color-1) !important;
  }
`
const TimePicker = styled.div`
  .react-datepicker {
    padding: 0px;
    border-radius: 0px;
  }
  .react-datepicker.react-datepicker--time-only {
    border: none;
  }
  .react-datepicker--time-only .react-datepicker__time {
    border-radius: 0;
    border: none;
    width: 172px;
  }
  .react-datepicker--time-only .react-datepicker__time-box {
    width: 172px;
    border-radius: 4px;
    border: 1px solid #a0a8bc;
  }
  .react-datepicker__header--time {
    display: none;
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: var(--agt-primary-color-1);
  }
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    padding-left: 24px;
    height: 48px;
    font-size: 17px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

const Selector = styled(Select)`
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '172px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '172px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
}
const colorSelectStyles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: data.value,
      backgroundColor: data.value,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor: data.value,
      },
    }
  },
  singleValue: (styles, { data }) => ({
    ...styles,
    color: data.value,
    backgroundColor: data.value,
    width: '50px',
    height: '32px',
    borderRadius: '4px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '145px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '145px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
}
const ConstactSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
}
type EventProps = {
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  event?: any
  isSubmitting?: boolean
}
type ValueOptionType = {
  value: number
  label: string
}
type OptionType = {
  contacts: ValueOptionType[]
  colorOptions: ValueOptionType[]
  repeatOptions: ValueOptionType[]
  typeAlertOptions: ValueOptionType[]
}

const getOptions = async (setOptions: SetStateType<OptionType>) => {
  try {
    const response = await Api.get('/events/options')
    setOptions(response.data.data)
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

type SelectorComponentType = {
  name: string
  options: Array<any>
  disable?: boolean
}
const SelectorComponent = ({ name, options, disable = false }: SelectorComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <div>
      <Selector
        value={defaultSeletor}
        styles={customSelectStyles}
        onChange={(option: any) => {
          const value = (option as any).value
          setValue(value)
        }}
        options={options}
        isDisabled={disable}
      />
    </div>
  )
}
const ContactComponent = ({ name, options }: SelectorComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div>
      <Selector
        closeMenuOnSelect={true}
        styles={ConstactSelectStyles}
        components={animatedComponents}
        defaultValue={value}
        isMulti
        options={options}
        onChange={(value: any) => {
          setValue(value)
        }}
        placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
      />
    </div>
  )
}
const SelectorColorCode = ({ name, options }: SelectorComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <div>
      <Selector
        value={defaultSeletor}
        styles={colorSelectStyles}
        onChange={(option: any) => {
          const value = (option as any).value
          setValue(value)
        }}
        options={options}
      />
    </div>
  )
}

const TimeComponent = ({ name, allDay, hasNotEndDate, startTime }) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const newFormat = value ? new Date(value) : null
  const filterStartTime = new Date()
  filterStartTime.setHours(0, 0, 0, 0)
  const FilterEndTime = new Date()
  FilterEndTime.setHours(23, 59, 59, 999)

  function checkDate(newFormat, startTime) {
    const compareValue = newFormat ? new Date(newFormat) : new Date()
    compareValue.setHours(0, 0, 0, 0)
    const comparestartTime = new Date(startTime)
    comparestartTime.setHours(0, 0, 0, 0)
    if (compareValue <= comparestartTime) {
      const returnTime = new Date(startTime)
      returnTime.setMinutes(startTime.getMinutes() + 1)
      return returnTime
    }
    return filterStartTime
  }
  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { value: curr, onClick } = props
    return (
      <>
        <input
          value={curr}
          onClick={onClick}
          placeholder="เวลา"
          readOnly
          disabled={props.disabled}
          style={{ width: '172px' }}
        />
      </>
    )
  }
  return (
    <TimePicker>
      <DatePickerTimeStyle
        selected={newFormat}
        onChange={(date: Date) => {
          setValue(date)
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="H:mm น."
        disabled={allDay || hasNotEndDate}
        minTime={checkDate(newFormat, startTime)}
        maxTime={FilterEndTime}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        locale="th"
      />
    </TimePicker>
  )
}

const EventForm: FC<EventProps> = (props) => {
  const { setFieldValue } = useFormikContext()
  const [options, setOptions] = useState<OptionType>({
    contacts: [],
    colorOptions: [],
    repeatOptions: [],
    typeAlertOptions: [],
  })
  const [metaStartDate] = useField('startDate')
  const [metaEndDate] = useField('endDate')
  const [metaHasNotEndDate] = useField('hasNotEndDate')
  const [metaAllDay] = useField('allDay')

  const getDateValue = (date: any) => {
    return date ? new Date(date) : null
  }

  let newFormatStartDate = getDateValue(metaStartDate.value)
  let newFormatEndDate = getDateValue(metaEndDate.value)

  useEffect(() => {
    getOptions(setOptions)
  }, [setOptions])

  useEffect(() => {
    if (metaAllDay.value) {
      if (metaStartDate.value !== '') {
        let newFormat = new Date(metaStartDate.value)
        let newFormat2 = new Date(metaStartDate.value)
        newFormat.setHours(0, 0, 0)
        newFormat2.setHours(23, 59, 59)
        setFieldValue('startDate', newFormat)
        setFieldValue('endDate', newFormat2)
      } else if (metaEndDate.value !== '') {
        let newFormat = new Date(metaEndDate.value)
        let newFormat2 = new Date(metaEndDate.value)
        newFormat2.setHours(23, 59, 59)
        newFormat.setHours(0, 0, 0)
        setFieldValue('endDate', newFormat2)
        setFieldValue('startDate', newFormat)
      } else if (metaStartDate.value === '') {
        let newFormat = new Date()
        let newFormat2 = new Date()
        newFormat2.setHours(23, 59, 59)
        newFormat.setHours(0, 0, 0)
        setFieldValue('endDate', newFormat2)
        setFieldValue('startDate', newFormat)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaAllDay.value, setFieldValue])

  useEffect(() => {
    if (metaHasNotEndDate.value && metaStartDate.value !== '') {
      let newFormat = new Date(metaStartDate.value)

      newFormat.setHours(23, 59, 59)
      setFieldValue('endDate', newFormat)
    } else if (metaHasNotEndDate.value && metaStartDate.value === '') {
      let newFormat = new Date()
      newFormat.setHours(23, 59, 59)
      setFieldValue('endDate', newFormat)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaHasNotEndDate.value, setFieldValue])

  useEffect(() => {
    if (newFormatEndDate && newFormatStartDate && newFormatStartDate >= newFormatEndDate) {
      let newDate = new Date(newFormatStartDate)

      if (metaHasNotEndDate) {
        newDate.setHours(23, 59, 59)
      } else {
        newDate.setHours(newFormatStartDate.getHours(), newFormatStartDate.getMinutes() + 15)
      }
      setFieldValue('endDate', newDate)
    } else if (
      metaAllDay.value &&
      newFormatEndDate &&
      newFormatStartDate &&
      newFormatStartDate < newFormatEndDate
    ) {
      let newFormatStart = new Date(newFormatStartDate)
      newFormatStart.setHours(23, 59, 59)

      setFieldValue('endDate', newFormatStart)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaStartDate.value, setFieldValue])

  const onDateChange = (fieldname: string) => (value: Date) => {
    switch (fieldname) {
      case 'startDate':
        if (newFormatStartDate) {
          value.setHours(newFormatStartDate.getHours(), newFormatStartDate.getMinutes())
        }
        break
      case 'endDate':
        const pureEndDate = new Date(value)
        pureEndDate.setHours(0, 0, 0, 0)
        if (newFormatStartDate) {
          const pureStartDate = new Date(newFormatStartDate)
          pureStartDate.setHours(0, 0, 0, 0)
          if (pureStartDate >= pureEndDate && !metaHasNotEndDate.value) {
            value.setHours(newFormatStartDate.getHours(), newFormatStartDate.getMinutes() + 15)
          }
        }
        break
    }
    setFieldValue(fieldname, value)
  }

  function getValidateDate(date: any) {
    var myDate = new Date(date)
    var validateDate = subDays(myDate, 0)
    return new Date(validateDate)
  }

  return (
    <FormValue onSubmit={props.handleSubmit}>
      <FieldContainer>
        <FieldGroup>
          <Topic id="event-new__topic">หัวข้อ</Topic>
          <FieldValue
            name="title"
            type="string"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุหัวข้อ')}
          />
          <ErrorMessage component={Error} name="title" />
        </FieldGroup>
        <FieldGroup>
          <Topic>เพิ่มบุคคลที่เข้าร่วม</Topic>
          <ContactComponent name="eventRelates" options={options.contacts} />
        </FieldGroup>
        <RowGroup>
          <FieldGroup style={{ marginRight: '24px' }}>
            <Topic id="event-new__start-date">เริ่ม วันที่</Topic>
            <DatePickerStyle
              name="startDate"
              value={newFormatStartDate}
              onChange={onDateChange('startDate')}
            />
            <FieldValue
              hidden
              name="startDate"
              type="string"
              validate={validator('กรุณาระบุวันที่')}
            />
            <ErrorMessage component={Error} name="startDate" />
          </FieldGroup>
          <FieldGroup style={{ marginRight: '24px' }}>
            <Topic>เวลา</Topic>
            <TimeComponent
              allDay={metaAllDay.value}
              hasNotEndDate={false}
              name="startDate"
              startTime={null}
            />
          </FieldGroup>
          {metaHasNotEndDate.value === true ? (
            <RepeatOption style={{ borderColor: 'var(--agt-disable-color-1)' }}>
              <Field name="allDay" type="checkbox" placeholder="โปรดระบุ" disabled={true} />
              <Topic>
                <label className="disabled">ทั้งวัน</label>
              </Topic>
            </RepeatOption>
          ) : (
            <RepeatOption>
              <Field name="allDay" type="checkbox" placeholder="โปรดระบุ" />
              <Topic>ทั้งวัน</Topic>
            </RepeatOption>
          )}
          <FieldGroup>
            <ColorFieldValue>
              <SelectorColorCode name="colorCode" options={options.colorOptions} />
              <FieldValue hidden name="colorCode" type="string" validate={validator('')} />
            </ColorFieldValue>
            <ErrorMessage component={Error} name="colorCode" />
          </FieldGroup>
        </RowGroup>
        <RowGroup>
          <FieldGroup style={{ marginRight: '24px' }}>
            <Topic>จบ วันที่</Topic>
            <DatePickerStyle
              name="endDate"
              value={newFormatEndDate}
              onChange={onDateChange('endDate')}
              minDate={getValidateDate(metaStartDate.value)}
              disabled={metaAllDay.value}
            />
            <FieldValue
              hidden={true}
              name="endDate"
              type="string"
              validate={validator('กรุณาระบุวันที่')}
            />
            <ErrorMessage component={Error} name="endDate" />
          </FieldGroup>
          <FieldGroup style={{ marginRight: '24px' }}>
            <Topic>เวลา</Topic>
            <TimeComponent
              allDay={metaAllDay.value}
              hasNotEndDate={metaHasNotEndDate.value === true ? true : false}
              name="endDate"
              startTime={newFormatStartDate}
            />
          </FieldGroup>
          {metaAllDay.value ? (
            <NotEndDateOption style={{ borderColor: 'var(--agt-disable-color-1)' }}>
              <Field name="hasNotEndDate" type="checkbox" placeholder="โปรดระบุ" disabled />
              <Topic>
                <label className="disabled">ไม่ระบุเวลาจบ</label>
              </Topic>
            </NotEndDateOption>
          ) : (
            <NotEndDateOption>
              <Field
                name="hasNotEndDate"
                type="checkbox"
                placeholder="โปรดระบุ"
                onChange={() => {
                  setFieldValue('hasNotEndDate', !metaHasNotEndDate.value)
                  setFieldValue('allDay', false)
                  setFieldValue('endDate', metaStartDate.value)
                }}
              />
              <Topic>ไม่ระบุเวลาจบ</Topic>
            </NotEndDateOption>
          )}
        </RowGroup>
        <RowCustomGroup>
          <FieldGroup style={{ paddingRight: '24px', display: 'none' }}>
            <Topic>ทำซ้ำ</Topic>
            <SelectorComponent
              name="repeatSchedule"
              options={options.repeatOptions}
              disable={true}
            />
          </FieldGroup>
          <FieldGroup>
            <Topic>แจ้งเตือนล่วงหน้า</Topic>
            <SelectorComponent name="typeAlert" options={options.typeAlertOptions} />
          </FieldGroup>
        </RowCustomGroup>
        <FieldGroup>
          <Topic>เพิ่มสถานที่</Topic>
          <FieldValue name="location" type="string" placeholder="โปรดระบุ" />
          <ErrorMessage component={Error} name="location" />
        </FieldGroup>
        <FieldGroup>
          <Topic>รายละเอียดเพิ่มเติม</Topic>
          <FieldValue
            className="more-detail"
            name="description"
            type="string"
            component="textarea"
            placeholder="รายละเอียดเพิ่มเติม"
          />
        </FieldGroup>
      </FieldContainer>
      <SubmitContainer>
        <button type="submit" disabled={props.isSubmitting}>
          {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
        </button>
      </SubmitContainer>
    </FormValue>
  )
}

export default EventForm
