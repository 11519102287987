import React, { FC } from 'react'
import styled from '@emotion/styled'
import { useLocation } from 'routes'

import { LinkContainer } from '../TextLink'

const MENU = [
  { to: 'contacts', label: 'รายชื่อลูกค้า' },
  // { to: 'workflows', label: 'Workflows' },
  { to: 'tasks', label: 'งานที่ต้องทำ' },
  { to: 'calendar', label: 'นัดหมาย' },
  { to: 'opportunities', label: 'โอกาสในการขาย' },
  // { to: 'tools', label: 'Tools' },
  // { to: 'documents', label: 'Documents' },
]

const Container = styled.ul`
  position: relative;

  display: flex;
  height: 56px;
  width: 100%;
  justify-content: space-around;
  align-items: center;

  list-style-type: none;
`
const Tab = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: var(--agt-background-color-3);
  color: var(--agt-primary-color-1);
`
const LinkTab = styled(LinkContainer)`
  align-items: center;
  width: 100%;
  height: 100%;

  text-decoration: none;
`
type IndicatorProps = {
  active?: number | null
}
const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  left: 0;
  bottom: 0;

  width: calc(100% / ${MENU.length});
  height: 4px;

  background-color: var(--agt-primary-color-1);
  transform: ${(props) => (props.active ? `translateX(calc(100% * ${props.active}))` : ``)};
  transition: 0.5s transform;
`

const WHITE_LIST = ['/profile/recruitment/form', '/notifications']

const Navbar: FC = () => {
  const location = useLocation()
  const indexPage = location.pathname.split('/')[1]
  let index = MENU.map((m) => m.to).indexOf(indexPage)

  if (WHITE_LIST.some((whitelist) => whitelist === location.pathname)) {
    return null
  }

  return (
    <Container>
      {MENU.map((menu, index: number) => (
        <LinkTab key={index} to={'/' + menu.to}>
          <Tab>{menu.label}</Tab>
        </LinkTab>
      ))}
      {index >= 0 && <Indicator active={index} />}
    </Container>
  )
}

export default Navbar
