import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import axios, { AxiosRequestConfig } from 'axios'
import {
  agreementType,
  consentsType,
  initialStateType,
  sendNewConsentType,
  setConsentDetailType,
  OtpType,
} from 'types/ConsentsType'
import { initialContact } from './contactSlice.utils'
import { ConsentsThunk } from './store'

const baseURL = `${process.env.REACT_APP_AGENT_TOOLS_API_HOST}/api/frontend/v1`
const modifiedUrlPilot = true

const initialState: initialStateType = {
  initialized: false,
  error: null,
  agreement: null,
  consentUrl: '',
  contact: initialContact,
  otp: {},
}

const consentsSlice = createSlice({
  name: 'consents',
  initialState,
  reducers: {
    setInitialize(state) {
      state.initialized = true
    },
    setHistoriesByAgent(state, action: PayloadAction<agreementType | null>) {
      state.agreement = action.payload
    },
    setConsentURL(state, action: PayloadAction<string>) {
      state.consentUrl = action.payload
      state.error = null
    },
    setErrorMessage(state, action: PayloadAction<string | null>) {
      state.error = action.payload
    },
    setConsentByContact(state, action: PayloadAction<agreementType | null>) {
      state.agreement = action.payload
      state.error = null
    },
    setConsentDetail(state, action: PayloadAction<setConsentDetailType>) {
      state.agreement = action.payload.agreement
      state.contact = action.payload.contact
      state.error = null
    },
    setOtp(state, action: PayloadAction<OtpType>) {
      state.otp = action.payload
    },
    setOtpFaile(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  setInitialize,
  setHistoriesByAgent,
  setConsentURL,
  setErrorMessage,
  setConsentByContact,
  setConsentDetail,
  setOtp,
  setOtpFaile,
} = consentsSlice.actions

export default consentsSlice.reducer

export const getHistoriesConsentsByAgent = (
  id: string | number,
  cb: Function,
): ConsentsThunk => async (dispatch) => {
  try {
    // const response = await Api.get(`/consents/agents/${id}`)
    const response = await Api.get(`/auth/consents/${id}`)
    dispatch(setHistoriesByAgent(response.data.data.agreement))
    cb()
  } catch (error) {
    console.error(error.response || error)
    alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
    dispatch(setHistoriesByAgent(initialState.agreement))
  }
}

export const getReHistoriesConsentsByAgent = (
  id: string | number,
  fb: Function,
): ConsentsThunk => async (dispatch) => {
  try {
    // const response = await Api.get(`/consents/agents/${id}`)
    const response = await Api.get(`/auth/consents/${id}`)
    dispatch(setHistoriesByAgent(response.data.data.agreement))
  } catch (error) {
    console.error(error.response || error)
    alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
    dispatch(setHistoriesByAgent(initialState.agreement))
    fb()
  }
}

export const getConsentURL = (id: number | string): ConsentsThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/consents/contacts/${id}/consent_url`)
    let modifiedUrl = response.data.data.consentUrl
    if (modifiedUrlPilot) {
      dispatch(setConsentURL(modifiedUrl))
    } else {
      console.log(
        modifiedUrl.replace('https://dev.agent-tools.tqld.xyz/', 'http://localhost:52201/'),
      )
      dispatch(
        setConsentURL(
          modifiedUrl.replace('https://dev.agent-tools.tqld.xyz/', 'http://localhost:52201/'),
        ),
      )
    }
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const getConsentByContact = (id: number | string, cb: Function): ConsentsThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get(`/consents/${id}`)
    dispatch(setConsentByContact(response.data.data.consentHistory))
    cb()
  } catch (err) {
    console.error(err.response || err)
    alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
    dispatch(setErrorMessage(err.toString()))
  }
}

export const getConsentDetail = (
  keyValues: {
    agreement_id: string
    contact_id: string
    jwt_contact_token: string
  },
  type: string = 'default',
): ConsentsThunk => async (dispatch) => {
  try {
    let contactId = keyValues.contact_id
    let jwtContactToken = keyValues.jwt_contact_token
    let config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Agent-Tools-Auth-Token': localStorage.getItem('authToken'),
        'Agent-Tools-Contact-Token': jwtContactToken,
      },
      params: { agreement_id: keyValues.agreement_id },
    }
    const response = await axios.get(`${baseURL}/consents/contacts/${contactId}`, config)
    if (type === 'default') dispatch(setConsentDetail(response.data.data))
    else if (type === 'contact') dispatch(setConsentByContact(response.data.data.agreement))
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
    if (type === 'contact') dispatch(setConsentByContact(initialState.agreement))
  }
}

export const sendConsentDetail = (
  contactId: string,
  params: {
    agreement_id: string
    consents: Array<consentsType>
  },
  jwtContactToken: string,
  cb: Function,
): ConsentsThunk => async (dispatch) => {
  try {
    let config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Agent-Tools-Auth-Token': localStorage.getItem('authToken'),
        'Agent-Tools-Contact-Token': jwtContactToken,
      },
    }
    // const response = await axios.post(`${baseURL}/consents/contacts/${contactId}`, params, config)
    // dispatch(setConsentDetail(response.data))
    await axios.post(`${baseURL}/consents/contacts/${contactId}`, params, config)
    cb()
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const sendNewConsent = (
  values: sendNewConsentType,
  cb: Function = () => {},
): ConsentsThunk => async (dispatch) => {
  try {
    await Api.post(`/consents/agents`, values)
    cb()
  } catch (err) {
    console.error(err.response || err)
    dispatch(setErrorMessage(err.toString()))
  }
}

export const sendOtp = (
  phone_number: string,
  jwtContactToken: string,
  cb: Function,
  err: Function,
): ConsentsThunk => async (dispatch) => {
  try {
    let config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Agent-Tools-Auth-Token': localStorage.getItem('authToken'),
        'Agent-Tools-Contact-Token': jwtContactToken,
      },
    }
    let params = { phone_number }
    const response = await axios.post(`${baseURL}/consents/contacts/send_otp`, params, config)
    dispatch(setOtp(response.data.data))
    cb()
  } catch (error) {
    console.error(error.response || error)
    err()
  }
}

export const verifyOtp = (
  phone_number: string = '',
  otp_number: string = '',
  otp_ref_code: string = '',
  jwtContactToken: string = '',
  cb = () => {},
  err = () => {},
): ConsentsThunk => async () => {
  try {
    let config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
        'Agent-Tools-Auth-Token': localStorage.getItem('authToken'),
        'Agent-Tools-Contact-Token': jwtContactToken,
      },
    }
    let params = { phone_number, otp_number, otp_ref_code }
    await axios.post(`${baseURL}/consents/contacts/verify_otp`, params, config)
    cb()
  } catch (error) {
    console.error(error.response || error)
    err()
  }
}
