import React, { FC, useState } from 'react'
import Appointment from './Appointment'
import Opportunities from './Opportunities'
import Files from './Files'
import { QuickOpportunityByContact } from 'pages/opportunities/modal/New'
import { Link } from 'routes'
import { WorkType, currentTabType } from './index.type'
import { TAB_OPTIONS } from './index.utils'
import { Container, NavWorkDetail, Indicator, Header } from './index.styles'
import { isObject } from 'lodash'

const Work: FC<WorkType> = (props) => {
  const { contactId, contactData, currentAgent } = props
  const [currentTab, setCurrentTab] = useState<currentTabType>('Appointment')
  const [isCursor, setIsCursor] = useState<number>(0)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  return (
    <Container>
      {isModalOpen && (
        <QuickOpportunityByContact
          isModalOpen={isModalOpen}
          contactId={contactId}
          setIsModalOpen={setIsModalOpen}
          setReload={setReload}
        />
      )}
      <NavWorkDetail id={'contact-id__table'}>
        {TAB_OPTIONS.map(
          ({ label, value }: { label: string; value: currentTabType }, index: number) => (
            <li
              key={index}
              onClick={() => {
                setCurrentTab(value)
                setIsCursor(index)
              }}
            >
              {label}
            </li>
          ),
        )}
        {isCursor >= 0 && <Indicator active={isCursor} />}
      </NavWorkDetail>
      <Header id={'contact-id__table-navbar'}>
        {currentTab === 'Appointment'
          ? 'นัดหมาย'
          : currentTab === 'Opportunities'
          ? 'โอกาสในการขาย'
          : 'ไฟล์'}
      </Header>
      <Appointment contactId={props.contactId} active={currentTab === 'Appointment'} />
      <Opportunities
        contactId={props.contactId}
        active={currentTab === 'Opportunities'}
        reload={reload}
        setReload={setReload}
      />
      <Files contactId={props.contactId} active={currentTab === 'Files'} />
      <div className="btn">
        {currentTab === 'Appointment' ? (
          <Link to="/calendar" className="ghost">
            <button
              id="contact-id__create-appointment"
              type="button"
              className="createBtn"
              disabled={
                currentAgent?.statusTh === 'ระงับ' ||
                (isObject(contactData?.currentAgreement || null) &&
                  contactData?.isConsented === false)
              }
            >
              + สร้างนัดหมาย
            </button>
          </Link>
        ) : (
          currentTab === 'Opportunities' && (
            <button
              type="button"
              className="createBtn"
              onClick={() => setIsModalOpen(true)}
              style={{ display: 'none' }}
              disabled={
                currentAgent?.statusTh === 'ระงับ' ||
                (isObject(contactData?.currentAgreement || null) &&
                  contactData?.isConsented === false)
              }
            >
              สร้างโอกาสในการขาย
            </button>
          )
        )}
      </div>
    </Container>
  )
}
export default Work
