import styled from '@emotion/styled'
import React from 'react'

const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 24px;
`

export const InputAndUnitContainer = styled.div`
  width: 55%;
  display: flex;
  justify-content: flex-end;
`

export const Caption = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #393d43;
  width: 45%;
`

export const Unit = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #393d43;
  margin-left: 24px;
  width: 20%;
`

export const RequiredStar = styled.div`
  color: red;
`

const CustomInput = styled.input`
  margin-top: 0px;
  padding: 0px;
  padding-left: 16px;
  background: #ffffff;
  border: 1px solid #a0a8bc !important;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 214px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  position: relative;
  flex-direction: column;
  color: #393d43;
  background: ${(props) => (props.disabled ? '#EFF1F5' : '')};
  ::placeholder {
    color: #acb1bf;
  }
`

const CustomInputError = styled.input`
  margin-top: 0px;
  padding: 0px;
  padding-left: 16px;
  background: #ffffff;
  border: 1px solid #f45a49 !important;
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 214px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  position: relative;
  flex-direction: column;
  color: #393d43;
  background: ${(props) => (props.disabled ? '#EFF1F5' : '')};
  ::placeholder {
    color: #acb1bf;
  }
`

const Input = (props) => (
  <MainLayout className={props.className}>
    {props.caption && <Caption>{props.caption}</Caption>}
    <InputAndUnitContainer>
      {props.errors ? (
        <CustomInputError {...props.field} {...props} value={props.field.value || ''} className />
      ) : (
        <CustomInput {...props.field} {...props} value={props.field.value || ''} className />
      )}
      <Unit>{props.unit}</Unit>
    </InputAndUnitContainer>
  </MainLayout>
)

export default Input
