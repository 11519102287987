import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

import Icon from 'components/Icon'

const Container = styled.div`
  display: flex;
`
const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin: 0px;
  }
`
const StarColor = styled(Icon)`
  cursor: pointer;
  width: 32px;
  height: 33px;
  margin-right: 2px;
  svg {
    width: 32px;
    height: 33px;
    path {
      stroke: #000000;
      stroke-width: 2px;
    }
  }
`
type StarRatingProps = {
  className?: string
  setValue?: Function
  valueName?: string
  star?: number
  value?: number
  disabled?: boolean
}
const StarRating = (props: StarRatingProps) => {
  const [rating, setRating] = useState(0)
  const [zeroRating, setZeroRating] = useState(false)
  const { star = 3 } = props
  useEffect(() => setRating(props.value || 0), [props.value])
  const handleClick = (ratingValue: number) => {
    if (!props.disabled) {
      if (ratingValue === 1) {
        setZeroRating(!zeroRating)
        if (zeroRating) {
          setRating(0)
          props.setValue && props.setValue(props.valueName, 0)
        } else {
          setRating(ratingValue)
          props.setValue && props.setValue(props.valueName, ratingValue)
        }
      } else {
        setZeroRating(false)
        setRating(ratingValue)
        props.setValue && props.setValue(props.valueName, ratingValue)
      }
    }
  }

  return (
    <Container className={props.className || ''}>
      {[...Array(star)].map((star, i) => {
        const ratingValue = i + 1
        return (
          <RatingContainer>
            <label>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => handleClick(ratingValue)}
                hidden={true}
              />
              <StarColor name="star" color={ratingValue <= rating ? '#000000' : '#ffffff'} />
            </label>
            {ratingValue === props.star && <p>มาก</p>}
            {ratingValue === 1 && <p>น้อย</p>}
          </RatingContainer>
        )
      })}
    </Container>
  )
}

export default StarRating
