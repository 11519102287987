import { InsurancePortType } from 'types/InsurancePortType'

const initialValues: InsurancePortType = {
  ownerName: '',
  ownerGender: '',
  ownerAge: 0,
  insurancePattern: 'others',
  insuranceType: '',
  policyType: 'motor',
  insuranceCompanyName: '',
  name: '',
  policyNo: '',
  startPolicyAt: '',
  endPolicyAt: '',
  sumInsured: '',
  premium: '',
  netPremium: '',
  paymentType: null,
  yieldToMaturityRate: '0',
  payPeriodType: null,
  payPeriodValue: null,
  remark: '',
  policyDocumentId: '',
  specialFields: {
    premiumAllowance: 0,
    residualValue: 0,
    rateOfReturn: 0,
    drivers: [{ firstName: '', lastName: '', occupation: '', birthdate: new Date() }],
    productDetails: {
      cover3Rd: 0,
      cover3RdTime: 0,
      cover3RdAsset: 0,
      coverEarly: 0,
      coverCar: 0,
      coverDeduct: 0,
      coverLostFire: 0,
      coverAccNum: 0,
      coverAccPassNum: 0,
      coverDriverDeceased: 0,
      coverPassengerDeceased: 0,
      coverDriverTemporary: 0,
      coverPassengerTemporary: 0,
      coverDriverDeceasedUnit: 'บาท/คน',
      coverPassengerDeceasedUnit: 'บาท/คน',
      coverDriverTemporaryUnit: 'บาท/คน/สัปดาห์',
      coverPassengerTemporaryUnit: 'บาท/คน/สัปดาห์',
      coverAccPass: 0,
      coverAccPassUnit: 'บาท/คน',
      coverLegal: 0,
      //
      driverNum: 0,
      passengerNum: 0,
      //
      coverMoreDetails: [],
    },
    prbInsurance: {
      annualPremium: '',
      policyNo: '',
      startPolicyAt: new Date(),
      endPolicyAt: new Date(),
    },
  },
  insuranceRiders: [{ riderType: '', compensation: 0, premium: 0, peroidYearNumber: 0 }],
  purpose: '',
  state: '',
}

export default initialValues
