import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Announcements from 'pages/announcements/root'
import AnnouncementId from 'pages/announcements/_id'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/announcements/:id" component={AnnouncementId} />
    <Route path="/announcements" component={Announcements} />
  </Switch>
)

export default Pages
