import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { dateFormat } from 'utils/helper'
import moment from 'moment'
import { Link } from 'routes'
import { isNull, get } from 'lodash'
import Api from 'api'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import CustomModal from './TabModal'
import FilterCard from './FilterCard'

type HeaderTabsType = {
  isExpand?: boolean
}

const Container = styled.div`
  width: 100%;
  .TabScroll {
    max-height: 390px;
    overflow-y: auto;
  }
  .BodyTabs {
    padding: 8px 16px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--agt-background-color-2);
    /* min-height: 130px;
    max-height: 160px; */
    height: 130px;
    justify-content: space-between;
  }
  .BodyTabs .Row {
    display: flex;
    justify-content: space-between;
  }
  .title,
  .name {
    max-width: 190px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 155px;
    }
  }
  .nameRelated {
    max-width: 85px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 1024px) {
      max-width: 75px;
    }
  }
  .colorCode {
    width: 20px;
    height: 14px;
    border-radius: 4px;
  }
  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .startDate,
  .dueDate {
    display: flex;
    align-items: center;
    svg {
      width: 13px;
      height: 13px;
      path {
      }
    }
    small {
      margin-left: 4px;
    }
  }
  .dateTime {
    display: flex;
    align-items: center;
    svg {
      width: 13px;
      height: 13px;
      path {
        stroke: var(--agt-secondary-color-2);
      }
    }
    small {
      margin-left: 4px;
    }
  }
  .location {
    display: flex;
    align-items: center;
    svg {
      width: 12px;
      height: 12px;
      path {
      }
    }
    small {
      margin-left: 4px;
    }
    .name {
      max-width: 190px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 1024px) {
        max-width: 155px;
      }
    }
  }
`
const CustomIcon = styled(Icon)`
  svg {
    width: 20px;
    height: 20px;
    path {
      stroke: var(--agt-secondary-color-1);
    }
  }
`
const HeaderTabs = styled.div<HeaderTabsType>`
  padding: 0 18px;
  padding-right: 6px;
  height: 48px;
  background: var(--agt-background-color-3);
  border-bottom: 1px solid var(--agt-white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .onClick {
    display: flex;
    align-items: center;
  }
  .onClick div {
    font-weight: bold;
    color: var(--agt-primary-color-1);
    padding-left: 16px;
  }
  .onClick svg {
    font-size: 20px;
    font-weight: bold;
    color: var(--agt-primary-color-1);
    transition: all 0.5s ease-in-out;
    transform: ${(props) => (props.isExpand ? 'rotate(180deg)' : 'rotate(0deg);')};
  }
  .addition {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    div {
      width: 13px;
      display: flex;
      svg {
        width: 13px;
        height: 13px;
      }
    }
  }
`
const CheckboxName = styled.div`
  display: flex;
  align-items: center;
  .round {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 4px;
    position: relative;
  }
  .round .circle {
    background-color: var(--agt-white-color);
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
  }
  .round .circle:after {
    content: '';
    border: 2px solid var(--agt-primary-text-color);
    border-top: none;
    border-right: none;
    opacity: 0;
    position: absolute;
    top: 6px;
    left: 4px;
    transform: rotate(-45deg);
    width: 8px;
    height: 3px;
  }
  .round input[type='checkbox'] {
    visibility: hidden;
    display: none;
  }
  .round input[type='checkbox']:checked + .circle {
    background-color: var(--agt-white-color);
    border: 1px solid var(--agt-secondary-color-2);
  }
  .round input[type='checkbox']:checked + .circle:after {
    opacity: 1;
  }
  .round .disabledCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-color-1);
    background-color: var(--agt-disable-color-1);
    cursor: not-allowed;
  }
`
const CustomClock = styled(Icon)`
  svg {
    width: 12px !important;
    height: 12px !important;
    path {
      stroke: var(--agt-secondary-color-2);
    }
  }
`
const CustomCalendar = styled(Icon)`
  svg {
    width: 12px !important;
    height: 12px !important;
    path {
      fill: var(--agt-secondary-color-2) !important;
      stroke: unset !important;
    }
  }
`

const TabSideBar = (props) => {
  const {
    header = '',
    events = [],
    tasks = [],
    addition = true,
    contactId = null,
    contactName = '',
    onSelected = () => {},
    addEventRelates = false,
    forceExpand = true,
    selectedDate = moment(new Date()).format('DD/MM/YY'),
    currentAgent,
  } = props
  const [isExpand, setIsExpand] = useState(forceExpand)
  const [isOpen, setIsOpen] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [isCheckedStatus, setIsCheckedStatus] = useState<any>([])

  const toggleStatus = async (id: number) => {
    try {
      await Api.patch(`/tasks/${id}/toggle_status`, {})
    } catch (err) {
      if (err && err.response) {
        alert(err.response)
      }
      throw err
    }
  }

  useEffect(() => {
    if (header === 'งานที่ต้องทำ') {
      let a = tasks.filter(({ status }) => status === 'complete')
      setIsCheckedStatus(a)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks])

  const StartDateEvent = ({
    startDate,
    endDate,
    addEventRelates,
    selectedDate = '25/08/2021',
    allDay,
  }) => {
    let fact: boolean = false
    let textCalendar: number = 0
    if (moment(startDate).format('DD/MM/YY') === moment(endDate).format('DD/MM/YY')) {
      fact = true
    }
    if (moment(selectedDate).format('DD/MM/YY') === moment(startDate).format('DD/MM/YY')) {
      textCalendar = 1
    } else if (moment(selectedDate).format('DD/MM/YY') === moment(endDate).format('DD/MM/YY')) {
      textCalendar = 2
    } else {
    }

    return (
      <>
        {addEventRelates ? (
          <>
            {fact && !allDay ? (
              <div
                className="Row"
                style={{
                  color: 'var(--agt-secondary-color-1)',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="dateTime">
                  <CustomClock name="clock_white" />
                  <small style={{ color: 'var(--agt-secondary-color-2)' }}>
                    {moment(startDate).format('H:mm')}น.-{moment(endDate).format('H:mm')}
                    น.
                  </small>
                </div>
              </div>
            ) : fact && allDay ? (
              <div
                className="Row"
                style={{
                  color: 'var(--agt-secondary-color-1)',
                  justifyContent: 'flex-end',
                }}
              >
                <div className="dateTime">
                  <small style={{ color: 'var(--agt-secondary-color-2)' }}>ทั้งวัน</small>
                </div>
              </div>
            ) : (
              <div
                className="Row"
                style={{
                  color: 'var(--agt-secondary-color-2)',
                  justifyContent: 'flex-end',
                }}
              >
                {textCalendar === 1 ? (
                  <div className="dateTime">
                    <CustomClock name="clock_white" />
                    <small>เริ่ม {moment(startDate).format('H:mm')} น.</small>
                  </div>
                ) : textCalendar === 2 ? (
                  <div className="dateTime">
                    <CustomClock name="clock_white" />
                    <small>จบ {moment(endDate).format('H:mm')} น.</small>
                  </div>
                ) : (
                  <small>ทั้งวัน</small>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            {fact && !allDay ? (
              <div className="Row" style={{ color: 'var(--agt-secondary-color-2)' }}>
                <div className="startDate">
                  <CustomCalendar name="calendarDatePicker" />
                  <small>{dateFormat(startDate)}</small>
                </div>
                <div className="dateTime">
                  <CustomClock name="clock_white" />
                  <small>
                    {moment(startDate).format('H:mm')}น.-{moment(endDate).format('H:mm')}
                    น.
                  </small>
                </div>
              </div>
            ) : fact && allDay ? (
              <div className="Row" style={{ color: 'var(--agt-secondary-color-2)' }}>
                <div className="startDate">
                  <CustomCalendar name="calendarDatePicker" />
                  <small>{dateFormat(startDate)}</small>
                </div>
                <div className="dateTime">
                  <small>ทั้งวัน</small>
                </div>
              </div>
            ) : (
              <div>
                <div className="Row" style={{ color: 'var(--agt-secondary-color-2)' }}>
                  <div className="startDate">
                    <CustomCalendar name="calendarDatePicker" />
                    <small>เริ่ม {dateFormat(startDate)}</small>
                  </div>
                  <div className="dateTime">
                    <CustomClock name="clock_white" />
                    <small>{moment(startDate).format('H:mm')} น.</small>
                  </div>
                </div>
                <div className="Row" style={{ color: 'var(--agt-secondary-color-2)' }}>
                  <div className="startDate">
                    <CustomCalendar name="calendarDatePicker" />
                    <small>จบ {dateFormat(endDate)}</small>
                  </div>
                  <div className="dateTime">
                    <CustomClock name="clock_white" />
                    <small>{moment(endDate).format('H:mm')} น.</small>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    )
  }

  function largeItem(startDate: string, endDate: string) {
    let fact: boolean = false
    if (moment(startDate).format('DD/MM/YY') !== moment(endDate).format('DD/MM/YY')) {
      fact = true
    }
    return fact
  }

  return (
    <Container>
      {isOpen && isNull(contactId) ? (
        <Modal
          isOpen={isOpen}
          onConfirm={() => {}}
          onCancel={() => {
            setIsOpen(false)
          }}
          hiddenButton
          hiddenClose
        >
          <div style={{ padding: '60px 64px', paddingBottom: '24px', textAlign: 'center' }}>
            <p>
              กรุณาระบุ&nbsp;
              <span style={{ color: 'var(--agt-error-color)' }}>รายชื่อลูกค้าที่เกี่ยวข้อง</span>
            </p>
            <p>ก่อนเลือกนัดหมาย/งานที่ต้องทำ</p>
            <button
              className="primary"
              onClick={() => setIsOpen(false)}
              style={{ width: '100px', marginTop: '32px' }}
            >
              ปิด
            </button>
          </div>
        </Modal>
      ) : isOpen && header === 'นัดหมาย' ? (
        <CustomModal
          isOpen={isOpen}
          onConfirm={() => {}}
          onCancel={() => {
            setIsOpen(false)
          }}
        >
          <FilterCard
            header="นัดหมาย"
            contactName={contactName}
            setIsOpen={(e) => {
              onSelected(e)
              setIsOpen(false)
              setIsFilter(false)
            }}
            setIsFilter={(e) => {
              setIsFilter(e)
            }}
            isFilter={isFilter}
            contactId={contactId}
            events={events}
          />
        </CustomModal>
      ) : (
        <CustomModal
          isOpen={isOpen}
          onConfirm={() => {}}
          onCancel={() => {
            setIsOpen(false)
          }}
        >
          <FilterCard
            header="งานที่ต้องทำ"
            contactName={contactName}
            setIsOpen={(e) => {
              onSelected(e)
              setIsOpen(false)
              setIsFilter(false)
            }}
            setIsFilter={(e) => {
              setIsFilter(e)
            }}
            isFilter={isFilter}
            contactId={contactId}
            tasks={tasks}
          />
        </CustomModal>
      )}
      <HeaderTabs isExpand={isExpand}>
        <div
          className="onClick"
          onClick={() => setIsExpand(!isExpand)}
          style={{ cursor: 'pointer' }}
        >
          {header === 'นัดหมาย' && events.length > 0 ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : header === 'งานที่ต้องทำ' && tasks.length > 0 ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : null}
          <div
            style={
              (header === 'นัดหมาย' && events.length > 0) ||
              (header === 'งานที่ต้องทำ' && tasks.length > 0)
                ? {}
                : { paddingLeft: 0 }
            }
          >
            {header}&nbsp;
            {header === 'นัดหมาย' && events.length > 0
              ? `(${events.length})`
              : header === 'งานที่ต้องทำ' && tasks.length > 0 && `(${tasks.length})`}
          </div>
        </div>
        {addition && (
          <button className="secondary addition" type="button" onClick={() => setIsOpen(true)}>
            {(header === 'นัดหมาย' && events.length > 0) ||
            (header === 'งานที่ต้องทำ' && tasks.length > 0) ? (
              <Icon name="pencil_icon" color={'var(--agt-primary-color-1)'} />
            ) : (
              '+'
            )}
          </button>
        )}
      </HeaderTabs>
      {header === 'นัดหมาย' ? (
        <>
          {isExpand && events.length > 0 && (
            <div className="TabScroll" style={addEventRelates ? { maxHeight: 525 } : {}}>
              {events.map(
                (
                  {
                    id,
                    title,
                    colorCode,
                    location,
                    description,
                    startDate,
                    endDate,
                    eventRelates = [],
                    allDay = false,
                  },
                  index,
                ) => {
                  return (
                    <div
                      key={index}
                      className="BodyTabs"
                      style={
                        addEventRelates
                          ? { height: 175, paddingBottom: 8 }
                          : largeItem(startDate, endDate)
                          ? { height: 160 }
                          : {}
                      }
                    >
                      <StartDateEvent
                        addEventRelates={addEventRelates}
                        startDate={startDate}
                        endDate={endDate}
                        selectedDate={selectedDate}
                        allDay={allDay}
                      />
                      <div className="Row">
                        <Link
                          to={`/calendar/${id}`}
                          className="ghost"
                          style={{ color: 'var(--agt-primary-text-color)' }}
                        >
                          <div className="title" style={{ fontWeight: 'bold' }}>
                            {title}
                          </div>
                        </Link>
                        <div className="colorCode" style={{ background: `${colorCode}` }} />
                      </div>
                      <small
                        className="description"
                        style={
                          description
                            ? { color: 'var(--agt-primary-text-color)' }
                            : { color: 'var(--agt-secondary-color-1)' }
                        }
                      >
                        {description ? description : 'ไม่ระบุรายละเอียด'}
                      </small>
                      <div className="location">
                        <Icon name="location" />
                        <small className="name" style={{ color: 'var(--agt-secondary-color-2)' }}>
                          {location ? location : 'ไม่ระบุสถานที่'}
                        </small>
                      </div>
                      {addEventRelates && (
                        <div style={{ display: 'flex' }}>
                          {eventRelates.map((item, index) => {
                            if (eventRelates.length === 1) {
                              return (
                                <div
                                  key={index}
                                  className="nameRelated"
                                  style={{
                                    background: 'var(--agt-background-color-3)',
                                    padding: '0 6px',
                                    borderRadius: 4,
                                    fontSize: 13,
                                    maxWidth: 'unset',
                                  }}
                                >
                                  {get(item, 'label', '')}
                                </div>
                              )
                            } else if (eventRelates.length === 2) {
                              return (
                                <div
                                  key={index}
                                  className="nameRelated"
                                  style={
                                    index === 0
                                      ? {
                                          background: 'var(--agt-background-color-3)',
                                          padding: '0 6px',
                                          borderRadius: 4,
                                          fontSize: 13,
                                          maxWidth: '50%',
                                          marginRight: 6,
                                        }
                                      : {
                                          background: 'var(--agt-background-color-3)',
                                          padding: '0 6px',
                                          borderRadius: 4,
                                          fontSize: 13,
                                          maxWidth: '50%',
                                        }
                                  }
                                >
                                  {get(item, 'label', '')}
                                </div>
                              )
                            } else {
                              if (index < 2) {
                                return (
                                  <div
                                    key={index}
                                    className="nameRelated"
                                    style={{
                                      background: 'var(--agt-background-color-3)',
                                      padding: '0 6px',
                                      borderRadius: 4,
                                      fontSize: 13,
                                      maxWidth: '40%',
                                      marginRight: 6,
                                    }}
                                  >
                                    {get(item, 'label', '')}
                                  </div>
                                )
                              } else if (index === eventRelates.length - 1) {
                                return (
                                  <div
                                    key={index}
                                    className="nameRelated"
                                    style={{
                                      background: 'var(--agt-background-color-3)',
                                      padding: '0 6px',
                                      borderRadius: 4,
                                      fontSize: 13,
                                    }}
                                  >
                                    +{eventRelates.length - 2}
                                  </div>
                                )
                              }

                              return <></>
                            }
                          })}
                        </div>
                      )}
                    </div>
                  )
                },
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {isExpand && tasks.length > 0 && (
            <div className="TabScroll" style={addEventRelates ? { maxHeight: 300 } : {}}>
              {tasks.map(
                (
                  {
                    contactName,
                    description,
                    dueDate,
                    name,
                    priorityLevel,
                    contactId,
                    id,
                    contactAvatar,
                    phoneNumber,
                    email,
                  },
                  index: number,
                ) => {
                  return (
                    <div
                      key={index}
                      className="BodyTabs"
                      style={addEventRelates ? { height: 100 } : {}}
                    >
                      {!addEventRelates && (
                        <div className="dueDate" style={{ color: 'var(--agt-secondary-color-2)' }}>
                          <Icon name="calendarDatePicker" />
                          <small>{dateFormat(dueDate)}</small>
                        </div>
                      )}
                      <CheckboxName>
                        {!addition && (
                          <div className="round">
                            {currentAgent?.statusTh === 'ระงับ' ? (
                              <div className="disabledCheckbox" />
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  id={`checkbox-${index}`}
                                  checked={
                                    isCheckedStatus.filter(
                                      ({ id: idIsCheckedStatus }) => idIsCheckedStatus === id,
                                    ).length === 1
                                  }
                                />
                                <label
                                  className="circle"
                                  htmlFor={`checkbox-${index}`}
                                  onClick={() => {
                                    toggleStatus(id)
                                    if (
                                      isCheckedStatus.filter(
                                        ({ id: idIsCheckedStatus }) => idIsCheckedStatus === id,
                                      ).length === 1
                                    ) {
                                      setIsCheckedStatus(
                                        isCheckedStatus.filter(
                                          ({ id: idIsCheckedStatus }) => idIsCheckedStatus !== id,
                                        ),
                                      )
                                    } else {
                                      let newChecked = {
                                        id: id,
                                        name: name,
                                        contactId: contactId,
                                        contactName: contactName,
                                        description: description,
                                        dueDate: dueDate,
                                        priorityLevel: priorityLevel,
                                        contactAvatar: contactAvatar ? contactAvatar : null,
                                        phoneNumber: phoneNumber ? phoneNumber : null,
                                        email: email ? email : null,
                                      }
                                      setIsCheckedStatus([...isCheckedStatus, newChecked])
                                    }
                                  }}
                                />
                              </>
                            )}
                          </div>
                        )}
                        <div className="name">
                          <label
                            htmlFor={currentAgent?.statusTh === 'ระงับ' ? '' : `checkbox-${index}`}
                            style={{ cursor: 'pointer', fontWeight: 'bold' }}
                          >
                            {name}
                          </label>
                        </div>
                      </CheckboxName>
                      <small
                        className="description"
                        style={
                          description
                            ? { color: 'var(--agt-primary-text-color)' }
                            : { color: 'var(--agt-secondary-color-1)' }
                        }
                      >
                        {description ? description : 'ไม่ระบุรายละเอียด'}
                      </small>
                      <div className="Row" style={{ alignItems: 'center' }}>
                        <small
                          className="name"
                          style={{ width: '150px', color: 'var(--agt-primary-color-1)' }}
                        >
                          <Link to={`/contacts/${contactId}`} className="ghost">
                            {contactName}
                          </Link>
                        </small>
                        <small style={{ display: 'flex' }}>
                          {[...Array(priorityLevel)].map((e, index) => {
                            return (
                              <CustomIcon
                                key={index}
                                name="star"
                                color="var(--agt-primary-text-color)"
                              />
                            )
                          })}
                        </small>
                      </div>
                    </div>
                  )
                },
              )}
            </div>
          )}
        </>
      )}
    </Container>
  )
}
export default TabSideBar
