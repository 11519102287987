import styled from '@emotion/styled'
import { Form } from 'formik'
import Select from 'react-select'

export const Container = styled(Form)`
  width: 100%;
  max-width: 736px;
  margin: 44px auto 40px;
  display: flex;
  flex-direction: column;
  .topic {
    text-align: center;
    margin: 0px 0 40px;
  }
  .domestic,
  .international {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    column-gap: 32px;
    margin-bottom: 24px;
  }
  .domestic .selector,
  .international .selector,
  .domestic .datepicker,
  .international .datepicker {
    width: 100%;
    max-width: 352px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .domestic .selector label span,
  .international .selector label span,
  .domestic .datepicker label span,
  .international .datepicker label span {
    color: var(--agt-error-color);
  }
  .domestic .travelType {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .travelSelector {
    display: flex;
    gap: 24px;
  }
  .domestic .travelType label span {
    color: var(--agt-error-color);
  }
  .international .roundType {
    width: 100%;
    display: flex;
  }
  .international .roundType .item {
    width: 50%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #a0a8bc;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .international .roundType .item.left {
    border-radius: 4px 0px 0px 4px;
  }
  .international .roundType .item.right {
    border-radius: 0px 4px 4px 0px;
  }
  .international .roundType .item.active {
    background: #cae6ff;
    border: 1px solid #00529a;
    color: #00529a;
  }
  button {
    align-self: center;
  }
`

export const FlightSelecter = styled.section`
  width: 100%;
  max-width: 344px;
  display: flex;
  column-gap: 24px;
  margin: 0 auto 48px;
  label {
    width: 100%;
    padding: 24px 6px;
    border: 2px solid var(--agt-secondary-text-color);
    color: var(--agt-secondary-text-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  label input {
    display: none;
  }
  label.active {
    color: var(--agt-primary-color-1);
    font-weight: bold;
    border: 2px solid var(--agt-primary-color-1);
  }
  label > span:first-of-type {
    height: 70px;
  }
  label > span:last-of-type {
    margin-top: 18px;
  }
  label .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--agt-white-text-color);
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 50%;
    /* margin-top: 18px; */
  }
  label:hover {
    cursor: pointer;
  }
  label input:checked ~ .checkmark {
    border: 2px solid var(--agt-primary-color-1);
  }
  label .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  label input:checked ~ .checkmark:after {
    display: block;
  }
  label .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--agt-primary-color-1);
  }
`

export const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

export const customSelectDisabledStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-background-color-1)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: () => ({ color: 'var(--agt-primary-text-color)' }),
}

export const Selector = styled(Select)`
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

export const OtherComponent = styled.div`
  &.travelType {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
`
