import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'

type DropdownStyleProps = {
  offset?: number
}
const Dropdown = styled.div<DropdownStyleProps>`
  position: relative;
  display: inline-block;

  .agt-dropdown__button {
    width: unset;
    height: unset;
    padding: 0;

    background-color: white;
    color: var(--agt-primary-text-color);
    :hover {
      background-color: white;
      color: var(--agt-primary-text-color);
    }
  }

  .agt-dropdown__content-wrapper {
    position: absolute;
    z-index: 99;

    display: none;
    width: 100%;

    padding-top: ${(props) => `${props.offset}px`};

    background-color: transparent;
  }
  .agt-dropdown__content {
    width: 100%;
    overflow: hidden;

    list-style-type: none;
    background-color: white;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 5px;
  }

  &.agt-dropdown__container-hover:hover {
    .agt-dropdown__content-wrapper {
      display: block;
    }
  }

  &.agt-dropdown__show {
    .agt-dropdown__children-arrow {
      transform-origin: center;
      transform: rotate(180deg);
    }
    .agt-dropdown__content-wrapper {
      display: block;
    }
  }
`
export const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 48px;
  padding: 0 20px;

  border-radius: 5px;
  border: 1px solid var(--agt-secondary-color-1);
`
export const DropdownList = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 24px;

  cursor: pointer;

  :hover {
    background-color: var(--agt-background-color-1);
  }
`
export const DropdownLink = styled(Link)`
  color: var(--agt-primary-text-color);
  text-decoration: none;
  :hover {
    color: var(--agt-primary-text-color);
  }
`
type DropdownType = {
  className?: string
  children: React.ReactNode
  content: React.ReactNode
  mode?: 'hover' | 'trigger'
  offset?: number
  forceOpen?: boolean
}
const DropdownMenu: FC<DropdownType> = (props) => {
  const { className = '', children, content, mode = 'hover', offset = 5, forceOpen = false } = props
  const [isShow, setShowDropdown] = useState<boolean>(false)

  useEffect(() => {
    if (forceOpen) {
      setShowDropdown(forceOpen)
    }
  }, [forceOpen])

  return (
    <Dropdown
      className={
        `agt-dropdown__container agt-dropdown__container-${mode} ${
          isShow ? 'agt-dropdown__show' : ''
        } ` + className
      }
      offset={offset}
    >
      <button
        type="button"
        className="agt-dropdown__button"
        onClick={() => {
          if (mode === 'trigger') setShowDropdown(!isShow)
        }}
        onFocus={() => {
          if (!forceOpen && mode === 'hover') setShowDropdown(true)
        }}
        onBlur={(event) => {
          event.stopPropagation()
          setTimeout(() => {
            if (!forceOpen) setShowDropdown(false)
          }, 500)
        }}
      >
        {children}
      </button>
      <div className="agt-dropdown__content-wrapper">
        <ul className="agt-dropdown__content">{content}</ul>
      </div>
    </Dropdown>
  )
}

export default DropdownMenu
