import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import Avatar from 'components/Avatar'
import { currency } from 'utils/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { carUsage } from '../utils'
import Icon from 'components/Icon'

const InsuranceDetail: FC<DetailType> = ({ data }) => {
  const [isToggle, setIsToggle] = useState({
    details: true,
    vehicle: true,
    outsider: true,
    car: true,
    insider: true,
  })
  return (
    <Container>
      <div className="insurance_wrapper">
        <Avatar width="120px" height="120px" type="circle" avatar={data?.companyLogoUrl || ''} />
        <label className="insurance_name">
          {data?.companyName || ''} ชั้น {data?.insureLevel || ''}
        </label>
        <label className="price_text">฿ {currency(data?.netAmount, 2, '') || ''}</label>
        <label className="installment_text">{`ผ่อน 0% นาน 6 เดือน`}</label>

        <>
          <div
            className="insurance_topic"
            onClick={() => setIsToggle({ ...isToggle, details: !isToggle.details })}
          >
            รายละเอียด
            <CustomIcon icon={isToggle.details ? faChevronUp : faChevronDown} />
          </div>
          {isToggle.details && (
            <table>
              <tr>
                <th>
                  ประเภท
                  <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>ประเภทความคุ้มครอง</p>
                  </div>
                </th>
                <td>
                  ชั้น {data?.insureLevel || ''} {carUsage(data?.carUsage) || ''}{' '}
                  {data?.garage || ''}
                </td>
              </tr>
              <tr>
                <th>
                  จำนวนอู่ <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>จำนวนอู่ภายใต้การคุ้มครองของกรมธรรม์นี้</p>
                  </div>
                </th>
                <td>{currency(data?.garageTotal, 0, '') || ''}</td>
              </tr>
              <tr>
                <th>
                  ทุนประกัน <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ในการประกันภัยรถยนต์ คำว่า ทุนประกัน จะหมายถึง
                      <br />
                      สินไหมทดแทนที่บริษัทประกัน จะจ่ายให้กับผู้เอาประกัน
                      <br />
                      กรณีรถยนต์สูญหาย หรือกรณีเสียหายโดยสิ้นเชิง
                    </p>
                  </div>
                </th>
                <td>{currency(data?.cover, 0, '') || ''}</td>
              </tr>
              <tr>
                <th>
                  ตรวจสภาพ <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>ตรวจสภาพรถยนต์ฟรี</p>
                  </div>
                </th>
                <td>{data?.carCheck === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
              </tr>
            </table>
          )}

          <div
            className="insurance_topic"
            onClick={() => setIsToggle({ ...isToggle, vehicle: !isToggle.vehicle })}
          >
            ความคุ้มครองรถยนต์
            <CustomIcon icon={isToggle.vehicle ? faChevronUp : faChevronDown} />
          </div>
          {isToggle.vehicle && (
            <table>
              <tr>
                <th>
                  ภัยก่อการร้าย <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>คุ้มครองภัยจากการก่อการร้าย</p>
                  </div>
                </th>
                <td>{data?.terrorism === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
              </tr>
              <tr>
                <th>
                  น้ำท่วม <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>คุ้มครองอุทกภัย</p>
                  </div>
                </th>
                <td>{data?.coverFlooding === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
              </tr>
              <tr>
                <th>
                  ค่าเสียหายส่วนแรก <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ค่าความเสียหายส่วนแรก แปลง่าย ๆ ก็คือ ค่าสินไหม
                      <br />
                      ทดแทนส่วนแรกที่ผู้เอาประกันภัยตกลง (ปลงใจ - สมัคร
                      <br />
                      ใจ) รับภาระเอง โดยจะระบุไว้เป็นข้อตกลงในกรมธรรม์
                      <br />
                      นั่นเอง
                    </p>
                  </div>
                </th>
                <td>{data?.coverDeduct === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : <></>}</td>
              </tr>
            </table>
          )}

          <div
            className="insurance_topic"
            onClick={() => setIsToggle({ ...isToggle, outsider: !isToggle.outsider })}
          >
            คุ้มครองบุคคลภายนอก
            <CustomIcon icon={isToggle.outsider ? faChevronUp : faChevronDown} />
          </div>
          {isToggle.outsider && (
            <table>
              <tr>
                <th>
                  ชีวิต <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ความเสียหายต่อชีวิต ร่างกาย หรืออนามัยเฉพาะส่วนเกิน
                      <br />
                      วงเงินสูงสุดตามพ.ร.บ.
                    </p>
                  </div>
                </th>
                <td>{`฿ ${currency(data?.cover3Rd, 0, '')}` || ''}</td>
              </tr>
              <tr>
                <th>
                  ต่อชีวิต/ครั้ง <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ความรับผิดต่อบุคคลภายนอก จะชดใช้ความเสียหายต่อ
                      <br />
                      ชีวิต ร่างกาย หรืออนามัย ของบุคคลภายนอก ตาม
                      <br />
                      ความเสียหายที่แท้จริงที่ผู้เอาประกันจะต้องรับผิดชอบตาม
                      <br />
                      กฎหมายต่อบุคคลภายนอกนั้น เฉพาะส่วนเกินวงเงินสูงสุด
                      <br />
                      ตาม พ.ร.บ.
                    </p>
                  </div>
                </th>
                <td>{`฿ ${currency(data?.cover3RdTime, 0, '')}` || ''}</td>
              </tr>
              <tr>
                <th>
                  ทรัพย์สิน <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ความเสียหายต่อทรัพย์สินของคู่กรณีซึ่งเป็นผลมาจากคุณ
                      <br />
                      หรือผู้ขับขี่ที่ได้รับอนุญาตและเป็นฝ่ายผิด
                    </p>
                  </div>
                </th>
                <td>{`฿ ${currency(data?.cover3RdAsset, 0, '')}` || ''}</td>
              </tr>
            </table>
          )}

          <div
            className="insurance_topic"
            onClick={() => setIsToggle({ ...isToggle, car: !isToggle.car })}
          >
            คุ้มครองตัวรถ
            <CustomIcon icon={isToggle.car ? faChevronUp : faChevronDown} />
          </div>
          {isToggle.car && (
            <table>
              <tr>
                <th>
                  รถยนต์ <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ความเสียหายของตัวรถยนต์ที่เอาประกันภัยจากการชน
                      <br />
                      ความเสียหายต่อตัวรถยนต์ของคุณซึ่งเป็นผลมาจากการ
                      <br />
                      ชนกับรถยนต์คันอื่น หรือผู้ขับขี่ที่ได้รับอนุญาตและเป็นฝ่ายผิด
                    </p>
                  </div>
                </th>
                <td>฿ {currency(data?.coverLostFire, 0, '') || ''}</td>
              </tr>
              <tr>
                <th>
                  สูญหาย/ไฟไหม้ <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ความเสียหายต่อตัวรถยนต์ของคุณซึ่งมีสาเหตุจากไฟไหม้หรือ
                      <br />
                      หรือสูญหายจากโจรกรรม
                    </p>
                  </div>
                </th>
                <td>฿ {currency(data?.coverLostFire, 0, '') || ''}</td>
              </tr>
            </table>
          )}

          <div
            className="insurance_topic"
            onClick={() => setIsToggle({ ...isToggle, insider: !isToggle.insider })}
          >
            คุ้มครองคนในรถ
            <CustomIcon icon={isToggle.insider ? faChevronUp : faChevronDown} />
          </div>
          {isToggle.insider && (
            <table>
              <tr>
                <th>
                  ค่าอุบัติเหตุ <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ด้วยความคุ้มครองเสริมนี้ เราจะจ่ายให้ตามจำนวนเงินที่
                      <br />
                      เอาประกันให้กับผู้ขับขี่และผู้โดยสาร ในกรณีที่เสียชีวิต
                      <br />
                      สูญเสียมือ เท้า สายตา ทุพพลภาพถาวร ทุพพลภาพ
                      <br />
                      ชั่วคราว อันเนื่องจากการเกิดอุบัติเหตุครั้งนั้น
                    </p>
                  </div>
                </th>
                <td>
                  {`฿ ${currency(data?.coverAcc, 0, '')} x ${currency(
                    data?.coverAccNum,
                    0,
                    '',
                  )} คน` || ''}
                </td>
              </tr>
              <tr>
                <th>
                  ค่ารักษา <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      เราชดเชยค่าใช้จ่ายในการรักษาพยาบาล ค่าบริการทางการ
                      <br />
                      แพทย์ ค่าผ่าตัด และค่าบริการอื่นๆ ที่เกิดขึ้น
                      <br />
                      จากอุบัติเหตุ โดยจ่ายชดเชยตามค่าเสียหายจริง เมื่อมีการ
                      <br />
                      เรียกร้องค่าเสียหายจากอุบัติเหตุครั้งนั้น เพื่อบุคคลใดๆ ที่
                      <br />
                      ได้รับบาดเจ็บจากอุบัติเหตุครั้งนั้น
                    </p>
                  </div>
                </th>
                <td>
                  {`฿ ${currency(data?.coverAccPass, 0, '')} x ${currency(
                    data?.coverAccPassNum,
                    0,
                    '',
                  )} 
                  คน` || ''}
                </td>
              </tr>
              <tr>
                <th>
                  ค่าประกันตัว <HintIcon className="icon" name="I" />
                  <div className="info">
                    <p>
                      ค่าประกันตัว เราจะประกันตัวผู้เอาประกันภัยหรือบุคคลใด
                      <br />
                      ซึ่งขับขี่รถยนต์โดยได้รับความยินยอมจากผู้เอาประกันภัย
                      <br />
                      ในกรณีเกิดอุบัติเหตุ เป็นเหตุให้ถูกควบคุมตัวในคดีอาญา
                    </p>
                  </div>
                </th>
                <td>{`฿ ${currency(data?.coverLegal, 0, '')}` || ''}</td>
              </tr>
            </table>
          )}
        </>
      </div>
    </Container>
  )
}

export default InsuranceDetail

type DataType = {
  companyLogoUrl: string | null
  companyName: string | null
  netAmount: string | null
  carUsage: string | null
  garage: string | null
  garageTotal: string | null
  cover: string | null
  carCheck: string | null
  terrorism: string | null
  coverFlooding: string | null
  coverDeduct: string | null
  cover3Rd: string | null
  cover3RdTime: string | null
  cover3RdAsset: string | null
  coverLostFire: string | null
  coverAcc: string | null
  coverAccNum: string | null
  coverAccPass: string | null
  coverAccPassNum: string | null
  coverLegal: string | null
  insureLevel: number | string | null
}
type DetailType = {
  data: DataType
}
const Container = styled.div`
  min-width: 352px;
  border-right: 1px solid var(--agt-secondary-color-1);

  .insurance_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 56px;

    .insurance_name {
      color: var(--agt-primary-color-1);
      font-weight: 700;
      margin-top: 9px;
    }
    .price_text {
      color: var(--agt-primary-color-1);
      font-weight: 700;
      font-size: 24px;
      align-self: center;
      margin: 16px 0;
    }
    .installment_text {
      text-align: center;
      font-size: 13px;
      color: var(--agt-white-color);
      padding: 1px 15px;
      background-color: var(--agt-primary-color-1);
      border-radius: 20px;
      margin-bottom: 56px;
    }
    .insurance_topic {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 11px 40px;
      width: 100%;
      background-color: var(--agt-background-color-2);

      &:hover {
        cursor: pointer;
      }
    }

    table {
      width: 100%;
      padding-left: 40px;
      padding-top: 17px;

      tr {
        line-height: 35px;
      }

      th {
        text-align: left;
        width: 55%;
        padding-right: 0.5rem;
        position: relative;
      }
      th .info {
        font-weight: normal;
        position: absolute;
        left: 144px;
        top: 32px;
        color: var(--agt-white-color);
        background: var(--agt-primary-color-1);
        border-radius: 8px;
        padding: 14px;
        font-size: 13px;
        line-height: 26px;
        opacity: 0;
        transition: all 0.5s;
        width: fit-content;
        white-space: nowrap;
      }
      th .icon {
        position: absolute;
        top: 2px;
        right: 8px;
      }
      th .icon:hover {
        cursor: pointer;
      }
      th .icon:hover ~ .info {
        opacity: 1;
        z-index: 1;
      }
    }
  }
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: var(--agt-secondary-text-color);
`
const HintIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: var(--agt-primary-color-1);
  }
`
