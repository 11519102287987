import React, { FC, useEffect } from 'react'
import Banner from '../assets/images/agent_portal.png'
import { useFormikContext } from 'formik'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { consentsType } from 'types/AppType'
import { CustomModal, Header, Image, Content } from './NewConsentModal.styles'
import { FormModalType } from './NewConsentModal.type'

export const FormModal: FC<FormModalType> = (props) => {
  const { handleSubmit, agreement, values, setIsChecked, isChecked } = props
  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (agreement && agreement?.consents && values && values?.consents) {
      let checkedArrays = true
      agreement.consents.forEach((array: consentsType, index: number) => {
        if (values.consents[index]) {
          if (array.forcedConsent && !values.consents[index].value) {
            checkedArrays = false
            return null
          }
        } else if (array.forcedConsent) {
          checkedArrays = false
          return null
        }
      })
      setIsChecked(checkedArrays)
    }
  }, [setIsChecked, agreement, values])

  return (
    <CustomModal onSubmit={handleSubmit}>
      <Header>
        <Image alt="agentProtal" src={Banner} />
      </Header>
      <Content>
        <div className="name">
          {agreement?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
        </div>
        <p
          className="consents"
          dangerouslySetInnerHTML={{
            __html: agreement?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
          }}
        />
        <ul>
          {(agreement?.consents || []).map((consent: { name: string }, index: number) => {
            return (
              <li>
                <label className="checked">
                  <input
                    type="checkbox"
                    checked={values?.consents[index]?.value}
                    onClick={() => {
                      if (values?.consents[index]?.value) {
                        setFieldValue(`consents.${index}.value`, false)
                      } else {
                        setFieldValue(`consents.${index}.value`, true)
                      }
                    }}
                  />
                  <div className="square">
                    <FontAwesomeIcon icon={faCheck} className="faCheck" />
                  </div>
                  {consent?.name || ''}
                </label>
              </li>
            )
          })}
        </ul>
        <button type="submit" className="submit" disabled={!isChecked}>
          ยืนยันข้อมูล
        </button>
      </Content>
    </CustomModal>
  )
}
