import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { MotorInsuranceThunk } from 'store/store'
import Api from 'api'
import {
  initialContact,
  initialOrder,
  initialState,
  initialVehicle,
} from './motorInsuranceSlice.utils'
import {
  LoadingPayloadType,
  OptionStateType,
  OrderStateType,
  SearchStateType,
} from 'types/MotorInsuranceType'

const motorInsuranceSlice = createSlice({
  name: 'motor',
  initialState,
  reducers: {
    initializeMotor(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    setMotorOptions(state, action: PayloadAction<OptionStateType>) {
      state.options = action.payload
      state.error = null
    },
    setSearch(state, action: PayloadAction<Array<SearchStateType>>) {
      state.motorInsurances = action.payload
      state.error = null
    },
    setOrder(state, action: PayloadAction<OrderStateType>) {
      state.order = action.payload
      state.error = null
    },
    setLoading(state, action: PayloadAction<LoadingPayloadType>) {
      state.loading[action.payload.key] = action.payload.value
    },
  },
})

export const {
  initializeMotor,
  setMotorOptions,
  setSearch,
  setOrder,
  setLoading,
} = motorInsuranceSlice.actions
export default motorInsuranceSlice.reducer

export const getMotorOptions = (id: number | string, params: object): MotorInsuranceThunk => async (
  dispatch,
) => {
  try {
    dispatch(setLoading({ key: 'options', value: true }))
    const response = await Api.get(`/opportunities/${id}/product/motor_insurances/options`, params)
    dispatch(setMotorOptions(response.data.data))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'options', value: false }))
  }
}

export const getSearch = (id: number | string, params: object): MotorInsuranceThunk => async (
  dispatch,
) => {
  try {
    dispatch(setLoading({ key: 'search', value: true }))
    const response = await Api.get(`/opportunities/${id}/product/motor_insurances/search`, params, {
      timeout: 40000,
    })
    dispatch(setSearch(response.data.data.motorInsurances))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'search', value: false }))
  }
}

export const createOrder = (
  id: number | string,
  params: object,
  cb: Function,
): MotorInsuranceThunk => async (dispatch) => {
  dispatch(setLoading({ key: 'submit', value: true }))
  try {
    await Api.post(`/opportunities/${id}/product/motor_insurances/`, params)
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'submit', value: false }))
  }
}

export const getOrder = (
  id: number | string,
  orderId: number | string,
): MotorInsuranceThunk => async (dispatch) => {
  try {
    dispatch(setOrder({ contact: initialContact, order: initialOrder, vehicle: initialVehicle }))
    dispatch(setLoading({ key: 'order', value: true }))
    const response = await Api.get(`/opportunities/${id}/product/motor_insurances/order`, {
      orderId,
    })
    dispatch(setOrder(response.data.data))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'order', value: false }))
  }
}
