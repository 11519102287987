import { Moment } from 'moment'

export const mockUpAddress = [
  { label: 'ระบุเอง', value: null },
  {
    label: 'ที่อยู่ตามทะเบียนบ้าน',
    value: {
      building: 'dsfad',
      district: 'afdadfadfad',
      housingNo: 'adfafaadf',
      lane: 'asdfaffsaa',
      postcode: 'safafadsad',
      province: 'dsafa',
      road: 'dsfa',
      subDistrict: 'adfasf',
      villageNo: 'dasfaasfaa',
    },
  },
]

export function formatTravelType(travelType: string) {
  switch (travelType) {
    case 'D':
      return 'รายเที่ยว'
    case 'Y':
      return 'รายปี'
    default:
      return '-'
  }
}

export function diffTravelDate(form: Moment, to: Moment) {
  if (to.diff(form, 'days')) return to.diff(form, 'days')
  else return 1
}
