import React, { FC, useEffect, useMemo } from 'react'
import { useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'
import { Container, Header, AssetForm } from './EditAsset.styles'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { Formik } from 'formik'
import { ContactType } from 'types/ContactType'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  RedLicencePlateComponent,
  VehicleChassisComponent,
  Column,
  OptionsComponent,
} from './EditAsset.components'

const EditAsset: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      path: `/contacts/${contact?.id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: `/contacts/${contact?.id}/asset`,
      label: 'สินทรัพย์ส่วนตัว',
    },
    {
      label: 'แก้ไขรถยนต์',
    },
  ]

  useEffect(() => {
    if (id) dispatch(getContactById(id))
  }, [dispatch, id])

  const initialValue: ContactType = useMemo(
    () => ({
      age: contact?.age || '',
      status: contact?.status ? contact?.status : 'รอเสนองาน',
      remark: contact?.remark || '',
      note: contact?.note || '',
      firstName: contact?.firstName || '',
      avatar: contact?.avatar || '',
      lastName: contact?.lastName || '',
      gender: contact?.gender || '',
      nickname: contact?.nickname ? contact.nickname : `${contact?.firstName} ${contact?.lastName}`,
      phoneNumber: contact?.phoneNumber || '',
      email: contact?.email || '',
      name: contact?.name || '',
      birthdate: contact?.birthdate || '',
      maritalStatus: contact?.maritalStatus || '',
      prefixName: contact?.prefixName || '',
      idCard: contact?.idCard || '',
      occupation: contact?.occupation || '',
      position: contact?.position || '',
      company: contact?.company || '',
      facebook: contact?.facebook || '',
      lineId: contact?.lineId || '',
      canDeleted: contact?.canDeleted || true,
      homeAddress: {
        housingNo: contact?.homeAddress?.housingNo || '',
        villageNo: contact?.homeAddress?.villageNo || '',
        building: contact?.homeAddress?.building || '',
        lane: contact?.homeAddress?.lane || '',
        road: contact?.homeAddress?.road || '',
        subDistrict: contact?.homeAddress?.subDistrict || '',
        district: contact?.homeAddress?.district || '',
        province: contact?.homeAddress?.province || '',
        postcode: contact?.homeAddress?.postcode || '',
      },
      currentAddress: {
        housingNo: contact?.currentAddress?.housingNo || '',
        villageNo: contact?.currentAddress?.villageNo || '',
        building: contact?.currentAddress?.building || '',
        lane: contact?.currentAddress?.lane || '',
        road: contact?.currentAddress?.road || '',
        subDistrict: contact?.currentAddress?.subDistrict || '',
        district: contact?.currentAddress?.district || '',
        province: contact?.currentAddress?.province || '',
        postcode: contact?.currentAddress?.postcode || '',
      },
      officeAddress: {
        housingNo: contact?.officeAddress?.housingNo || '',
        villageNo: contact?.officeAddress?.villageNo || '',
        building: contact?.officeAddress?.building || '',
        lane: contact?.officeAddress?.lane || '',
        road: contact?.officeAddress?.road || '',
        subDistrict: contact?.officeAddress?.subDistrict || '',
        district: contact?.officeAddress?.district || '',
        province: contact?.officeAddress?.province || '',
        postcode: contact?.officeAddress?.postcode || '',
      },
      idCardAddress: {
        housingNo: contact?.idCardAddress?.housingNo || '',
        villageNo: contact?.idCardAddress?.villageNo || '',
        building: contact?.idCardAddress?.building || '',
        lane: contact?.idCardAddress?.lane || '',
        road: contact?.idCardAddress?.road || '',
        subDistrict: contact?.idCardAddress?.subDistrict || '',
        district: contact?.idCardAddress?.district || '',
        province: contact?.idCardAddress?.province || '',
        postcode: contact?.idCardAddress?.postcode || '',
      },
      entertainment: contact?.entertainment || {},
      health: contact?.health || {},
      travel: contact?.travel || {},
      food: contact?.food || {},
      isSameHomeAddress: contact?.isSameHomeAddress || false,
      isSameCurrentAddress: contact?.isSameCurrentAddress || false,
      isSameIdCardAddress: contact?.isSameIdCardAddress || false,
      currentAgreement: contact?.currentAgreement || null,
    }),
    [contact],
  )

  return (
    <Container>
      <Header>
        <h3>แก้ไขรถยนต์</h3>
      </Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Formik
        enableReinitialize
        initialValues={{ ...initialValue, year: 2023, redLicencePlate: true }}
        onSubmit={(values) => {
          console.log('test... ', values)
          // dispatch(
          //   sendNewConsent(values, () => {
          //     setIndexAgreement(indexAgreement + 1)
          //     setIsChecked(false)
          //     if (indexAgreement === currentAgent.newAgreements.length - 1) {
          //       setIsOpen(false)
          //     }
          //   }),
          // )
        }}
      >
        {(props) => {
          return (
            <AssetForm onSubmit={props.handleSubmit}>
              <div className="Row">
                <Column className="name" topic={'ตั้งชื่อ'} name={'name'} />
                <button type="submit" className="Increase secondary">
                  เพิ่มข้อมูลประกันที่ถืออยู่
                </button>
              </div>
              <div className="Row">
                <OptionsComponent
                  topic={'ปีรถยนต์'}
                  name={'year'}
                  options={[
                    { label: '2022', value: 2022 },
                    { label: '2023', value: 2023 },
                  ]}
                />
                <OptionsComponent
                  topic={'ยี่ห้อรถยนต์'}
                  name={'vehicleBrand'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
              </div>
              <div className="Row">
                <OptionsComponent
                  topic={'รุ่น'}
                  name={'vehicleModel'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
                <OptionsComponent
                  topic={'รุ่นย่อย'}
                  name={'vehicleSubModel'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
              </div>
              <div className="Row">
                <Column
                  topic={'เลขทะเบียนรถยนต์ (ตัวอย่าง 1กก8888)'}
                  name={'vehicleLicense'}
                  star={false}
                />
                <Column topic={'จังหวัดที่จดทะเบียน'} name={'vehicleProvince'} star={false} />
              </div>
              <div className="Row">
                <VehicleChassisComponent topic={'เลขตัวถัง'} name={'vehicleChassis'} star={false} />
                <RedLicencePlateComponent
                  name={'redLicencePlate'}
                  label="เป็นรถป้ายแดง"
                  className="redLabel"
                />
              </div>
              <div className="Insurance">
                <div className="InsuranceOrder">
                  <div className="Name">
                    <Avatar type="circle" avatar={''} width="60px" height="60px" />
                    <div className="InsuranceCompany">
                      <b>ประกันภัย ชั้น1</b>
                      <p>ประกันภัยไทยวิวัฒน์</p>
                    </div>
                  </div>
                  <div className="Status">
                    <div className="OrderStatus">กรมธรรม์มีผลบังคับ</div>
                    <small className="OrderNo">รหัสคำสั่งซื้อ #12345678</small>
                    <div className="OrderType">
                      <Icon name="car" />
                      <b>รถยนต์</b>
                    </div>
                  </div>
                </div>
                <div className="InsuranceAct">
                  <FontAwesomeIcon icon={faCheck} />
                  พ่วง พ.ร.บ.
                </div>
              </div>
            </AssetForm>
          )
        }}
      </Formik>
    </Container>
  )
}

export default EditAsset
