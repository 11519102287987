export function formatChangeDate(myDate: string) {
  var today = new Date(myDate)
  var dd = String(today.getDate())
  var mm = ''
  switch (today.getMonth() + 1) {
    case 1:
      mm = 'ม.ค.'
      break
    case 2:
      mm = 'ก.พ.'
      break
    case 3:
      mm = 'มี.ค.'
      break
    case 4:
      mm = 'เม.ย.'
      break
    case 5:
      mm = 'พ.ค.'
      break
    case 6:
      mm = 'มิ.ย.'
      break
    case 7:
      mm = 'ก.ค.'
      break
    case 8:
      mm = 'ส.ค.'
      break
    case 9:
      mm = 'ก.ย.'
      break
    case 10:
      mm = 'ต.ค.'
      break
    case 11:
      mm = 'พ.ย.'
      break
    case 12:
      mm = 'ธ.ค'
      break
    default:
      mm = String(today.getMonth() + 1).padStart(2, '0')
  }
  var yyyy = today.getFullYear() + 543
  return dd + ' ' + mm + ' ' + yyyy
}
