import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TravelInsuranceThunk } from 'store/store'
import Api from 'api'
import { initialState } from './travelInsuranceSlice.utils'
import {
  LoadingPayloadType,
  OptionStateType,
  OptionByTypeTaStateType,
  OrderStateType,
  SearchStateType,
} from 'types/TravelInsuranceType'

const travelInsuranceSlice = createSlice({
  name: 'travel',
  initialState,
  reducers: {
    setInitialized(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    setOptions(state, action: PayloadAction<OptionStateType>) {
      state.options = action.payload
      state.error = null
    },
    setOptionsByTypeTa(state, action: PayloadAction<OptionByTypeTaStateType>) {
      state.optionsByTypeTa = action.payload
      state.error = null
    },
    setSearch(state, action: PayloadAction<Array<SearchStateType>>) {
      state.travelInsurances = action.payload
      state.error = null
    },
    setOrder(state, action: PayloadAction<OrderStateType>) {
      state.order = action.payload
      state.error = null
    },
    setLoading(state, action: PayloadAction<LoadingPayloadType>) {
      state.loading[action.payload.key] = action.payload.value
    },
  },
})

export const {
  setInitialized,
  setOptions,
  setOptionsByTypeTa,
  setSearch,
  setOrder,
  setLoading,
} = travelInsuranceSlice.actions
export default travelInsuranceSlice.reducer

export const getTravelOptions = (id: number | string): TravelInsuranceThunk => async (dispatch) => {
  try {
    dispatch(setLoading({ key: 'options', value: true }))
    const response = await Api.get(`/opportunities/${id}/product/ta_insurances/options`)
    dispatch(setOptions(response.data.data))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'options', value: false }))
  }
}

export const getSearchByTypeTa = (
  id: number | string,
  params: { travelTypeCountry: string; travelType: string },
): TravelInsuranceThunk => async (dispatch) => {
  try {
    dispatch(setLoading({ key: 'search', value: true }))
    const response = await Api.get(
      `/opportunities/${id}/product/ta_insurances/search_ta_country`,
      params,
    )
    dispatch(setOptionsByTypeTa(response.data.data))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'search', value: false }))
  }
}

export const getSearch = (id: number | string, params: object): TravelInsuranceThunk => async (
  dispatch,
) => {
  try {
    dispatch(setLoading({ key: 'search', value: true }))
    const response = await Api.get(
      `/opportunities/${id}/product/ta_insurances/search_ta_insurances`,
      params,
    )
    dispatch(setSearch(response.data.data.taInsurances))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'search', value: false }))
  }
}

export const createOrder = (
  id: number | string,
  params: object,
  cb: Function,
): TravelInsuranceThunk => async (dispatch) => {
  dispatch(setLoading({ key: 'submit', value: true }))
  try {
    await Api.post(`/opportunities/${id}/product/ta_insurances/`, params)
    cb()
    // await Api.get(`/opportunities/${id}/product/ta_insurances/order`, {})
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'submit', value: false }))
  }
}

export const getOrder = (
  id: number | string,
  orderId: number | string,
): TravelInsuranceThunk => async (dispatch) => {
  try {
    // dispatch(setOrder({ contact: initialContact, order: initialOrder, vehicle: initialVehicle }))
    dispatch(setLoading({ key: 'order', value: true }))
    const response = await Api.get(`/opportunities/${id}/product/ta_insurances/order`, {
      orderId,
    })
    dispatch(setOrder(response.data.data))
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    dispatch(setLoading({ key: 'order', value: false }))
  }
}
