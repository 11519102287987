import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DashboardThunk } from 'store/store'
import Api from 'api'
import format from 'date-fns/format'

import {
  DashboardState,
  DashboardSummaryType,
  SearchValuesType,
  CommissionType,
  DashboardOptionsType,
  DashboardDailySummaryType,
  DashboardGraphResponseType,
} from 'types/DashboardType'

const initialState: DashboardState = {
  initialized: false,
  policyTypeOptions: [],
  memberOptions: [],
  orderCount: 0,
  policies: [],
  commission: {
    commissionAmount: 0,
    incentiveAmount: 0,
  },
  opportunityStats: [],
  orderStats: [],
  graph: {},
}

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setInitialize(state) {
      state.initialized = true
    },
    setDashboardSummary(state, action: PayloadAction<DashboardSummaryType>) {
      state.orderCount = action.payload.orderCount
      state.policies = action.payload.policies
    },
    setDashboardCommission(state, action: PayloadAction<CommissionType>) {
      state.commission = action.payload
    },
    setDashboardDailySummary(state, action: PayloadAction<DashboardDailySummaryType>) {
      state.opportunityStats = action.payload.opportunityStats
      state.orderStats = action.payload.orderStats
    },
    setDashboardOptions(state, action: PayloadAction<DashboardOptionsType>) {
      state.policyTypeOptions = action.payload.policyTypeOptions
      state.memberOptions = action.payload.memberOptions || []
    },
    setDashboardGraph(state, action: PayloadAction<DashboardGraphResponseType>) {
      state.graph = action.payload.graph
    },
  },
})

export const {
  setInitialize,
  setDashboardSummary,
  setDashboardOptions,
  setDashboardCommission,
  setDashboardDailySummary,
  setDashboardGraph,
} = dashboardSlice.actions

export default dashboardSlice.reducer

export const getDashboardOptions = (): DashboardThunk => async (dispatch) => {
  try {
    const response = await Api.get('/dashboard/options')
    dispatch(setDashboardOptions(response.data.data))
  } catch (error) {}
}

export const getDashboardSummary = (params: SearchValuesType): DashboardThunk => async (
  dispatch,
) => {
  try {
    const modifiedsubmittedAt = params.date
      ? {
          min: format(params.date.summittedAt.min || new Date(), 'dd/MM/yyyy'),
          max: format(params.date.summittedAt.max || new Date(), 'dd/MM/yyyy'),
        }
      : {}
    const response = await Api.get('/dashboard/summary', {
      'submittedAt[min]': modifiedsubmittedAt.min,
      'submittedAt[max]': modifiedsubmittedAt.max,
      ownerType: params.view,
      policyType: params.insuranceType !== 'all' ? params.insuranceType : null,
    })
    dispatch(setDashboardSummary(response.data.data))
  } catch (error) {
    console.error(error)
  }
}

export const getDashboardCommission = (): DashboardThunk => async (dispatch) => {
  try {
    const response = await Api.get('/dashboard/commission')
    dispatch(setDashboardCommission(response.data.data.commission))
  } catch (error) {}
}

export const getDashboardDailySummary = (): DashboardThunk => async (dispatch) => {
  try {
    const response = await Api.get('/dashboard/daily_summary')
    dispatch(setDashboardDailySummary(response.data.data))
  } catch (error) {}
}

export const getDashboardGraph = (params: SearchValuesType): DashboardThunk => async (dispatch) => {
  try {
    const modifiedParams = params.date
      ? {
          periodType: params.date.periodType,
          'submittedAt[min]': format(params.date.summittedAt.min || new Date(), 'dd-MM-yyyy'),
          'submittedAt[max]': format(params.date.summittedAt.max || new Date(), 'dd-MM-yyyy'),
          policyType: params.insuranceType !== 'all' ? params.insuranceType : null,
          ownerType: params.view,
          agentCode: params.member !== 'all' ? params.member : null,
        }
      : {}
    const response = await Api.get('/dashboard/graph', modifiedParams)
    dispatch(setDashboardGraph(response.data.data))
  } catch (error) {}
}
