import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { ErrorMessage, Field, Form, useField, useFormikContext } from 'formik'
import Select from 'react-select'
import isNull from 'lodash/isNull'

import { RootState } from 'store/rootReducer'
import { getMotorOptions } from 'store/motorInsuranceSlice'
import { SelectorComponentType } from 'types/MotorInsuranceType'
import { OptionType } from 'types/GeneralType'
import Avatar from 'components/Avatar'
import Loader from 'components/BackgroundLoading'

const SelectorComponent = ({
  name,
  options,
  disable = false,
  cb = () => {},
  ...props
}: SelectorComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSelector = options.find((select) => select.value === value)
  return (
    <>
      <div>
        <Selector
          value={defaultSelector}
          menuPortalTarget={document.body}
          onChange={(option: OptionType) => {
            const value = (option as OptionType).value
            setValue(value)
            cb(option)
          }}
          options={options}
          isDisabled={disable}
          placeholder="เลือก"
          {...props}
        />
      </div>
      <ErrorMessage component={Error} name={name} />
    </>
  )
}

const VehicleForm = (props) => {
  const { values, isValid } = props
  const { setFieldValue } = useFormikContext()
  let { id } = useParams<ParamsType>()
  const dispatch = useDispatch()
  const { options, loading } = useSelector((state: RootState) => state.motor)
  const [isDisable, setIsDisable] = useState<boolean>(false)

  useEffect(() => {
    setFieldValue('vehicleId', null)
    dispatch(getMotorOptions(id, {}))
  }, [dispatch, id])

  useEffect(() => {
    if (values.year !== '' && values.makeCode !== '' && values.familyCode !== '') {
      dispatch(
        getMotorOptions(id, {
          year: values.year,
          makeCode: values.makeCode,
          familyCode: values.familyCode,
        }),
      )
    } else if (values.year !== '' && values.makeCode !== '') {
      dispatch(
        getMotorOptions(id, {
          year: values.year,
          makeCode: values.makeCode,
        }),
      )
    } else if (values.year !== '') {
      dispatch(
        getMotorOptions(id, {
          year: values.year,
        }),
      )
    }
  }, [values.year, values.makeCode, values.familyCode])

  useEffect(() => {
    if (values.vehicleSubModel !== '') {
      const details = options?.carSubModelOptions.find((o) => o.value === values.vehicleSubModel)
      setFieldValue('carSubModelDetails', details?.details)
    }
  }, [options?.carSubModelOptions, values.vehicleSubModel])

  return (
    <>
      {loading.options && <Loader />}
      <VehicleHeader>ประกันรถยนต์</VehicleHeader>
      <Container onSubmit={props.handleSubmit}>
        <FieldGroup>
          <Topic>ข้อมูลลูกค้า</Topic>
          <ContactContainer>
            <StyledAvatar
              type="circle"
              width="32px"
              height="32px"
              avatar={options?.contact?.avatar || ''}
            />
            <ContactName>{options?.contact?.name || ''}</ContactName>
          </ContactContainer>
        </FieldGroup>
        <FieldGroup>
          <Topic>เลือกจากข้อมูลรถของลูกค้า</Topic>
          <SelectorComponent
            name="vehicleId"
            options={options?.vehicleOptions || []}
            cb={(option) => {
              if (!isNull(option.value)) {
                setIsDisable(true)
                Object.keys(option.vehicle).map((key) => {
                  setFieldValue(key, option.vehicle[key])
                })
              } else {
                setIsDisable(false)
              }
            }}
          />
        </FieldGroup>
        <hr />

        <FieldGroup>
          <Topic>ประเภทประกัน</Topic>
          <SelectorComponent name="insureLevel" options={options?.insureLevelOptions || []} />
        </FieldGroup>
        <FieldGroup>
          <Topic>ปีรถยนต์</Topic>
          <SelectorComponent
            name="year"
            options={options?.carYearOptions || []}
            cb={() => {
              setFieldValue('makeCode', '')
              setFieldValue('familyCode', '')
              setFieldValue('vehicleSubModel', '')
            }}
            disable={isDisable}
          />
        </FieldGroup>

        <FieldGroup>
          <Topic>ยี่ห้อรถยนต์</Topic>
          <SelectorComponent
            name="makeCode"
            options={options?.makeCodeOptions || []}
            cb={() => {
              setFieldValue('familyCode', '')
              setFieldValue('vehicleSubModel', '')
            }}
            disable={isDisable}
          />
        </FieldGroup>
        <FieldGroup>
          <Topic>รุ่น</Topic>
          <SelectorComponent
            name="familyCode"
            options={options?.familyCodeOptions || []}
            disable={isDisable}
            cb={() => {
              setFieldValue('vehicleSubModel', '')
            }}
          />
        </FieldGroup>

        <FieldGroup>
          <Topic>รุ่นย่อย</Topic>
          <SelectorComponent
            name="vehicleSubModel"
            options={options?.carSubModelOptions || []}
            disable={isDisable}
          />
          <Field name="carSubModelDetails" hidden={true} type="string" />
        </FieldGroup>
        <FieldGroup>
          <Topic>หมดประกันหรือต้องการให้คุ้มครอง</Topic>
          <SelectorComponent name="expiryDate" options={options?.expiryDateOptions || []} />
        </FieldGroup>

        <button type="submit" disabled={!isValid}>
          ค้นหาแบบประกัน
        </button>
      </Container>
    </>
  )
}

export default VehicleForm

type ParamsType = {
  id: string
}
const Container = styled(Form)`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 32px;
  justify-content: center;

  button {
    grid-column: 1 / span 2;
    justify-self: center;
    margin-top: 35px;
  }

  hr {
    grid-column: 1 / span 2;
    border-top: 1px solid var(--agt-secondary-color-1);
    margin-top: 20px;
  }
`
const VehicleHeader = styled.p`
  font-size: 17px;
  text-align: center;
  padding: 59px 0 40px;
`
const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 352px;
`
const Topic = styled.div`
  .disabled {
    color: var(--agt-disable-color-1) !important;
  }
`
const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
`
const ContactName = styled.p`
  color: var(--agt-primary-color-1);
`
const StyledAvatar = styled(Avatar)`
  min-width: 32px;
  margin-right: 8px;
`
const Selector = styled(Select)`
  .css-yk16xz-control {
    height: 48px;
  }
  .css-1pahdxg-control {
    height: 48px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
