import moment from 'moment'
import 'moment/locale/th'

import { NotificationType } from 'types/NotificationType'

export const setMaxframe = (length) => {
  return 5 - length > 0 ? 5 - length : 0
}

export const getTask = (notifications) => {
  let currentDayNotificatons: NotificationType[] = []
  let latedNotificatons: NotificationType[] = []
  let isRead = true
  let alertAt = '1920-01-01'
  let idTask = 0
  let actionId = 0

  notifications.forEach((item) => {
    if (item.actionType === 'task') {
      idTask = item.id ? item.id : 0
      if (moment(item?.info?.eventStartDate).isSame(new Date(), 'day')) {
        currentDayNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      } else if (moment(item?.info?.eventStartDate).isBefore(new Date(), 'day')) {
        latedNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      }
      if (!item.isRead) {
        isRead = item.isRead
      }
    }
  })
  const task = {
    id: idTask,
    actionType: 'task',
    info: null,
    isRead: isRead,
    isChecked: null,
    contactName: 'contactName',
    alertAt: alertAt,
    actionId: actionId,
    currentDayTask: currentDayNotificatons.length,
    latedTask: latedNotificatons.length,
  }
  return task
}
