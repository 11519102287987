import styled from '@emotion/styled'
import Icon from 'components/Icon'
import Select from 'react-select'

export const Container = styled.div`
  width: 100%;
  .Loading {
    text-align: center;
  }
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
`

export const AssetForm = styled.form`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 64px 24px;
  .xxx {
    width: 100%;
    max-width: 352px;
  }
  .Row {
    display: flex;
    justify-content: space-between;
  }
  .Row .Column {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 328px;
  }
  .Row .Column span {
    color: var(--agt-error-color);
  }
  .Row .Column.name {
    max-width: 414px;
  }
  .Row .VehicleClassis {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 328px;
  }
  .Row .VehicleClassis .Icon {
    position: absolute;
    top: 40px;
    right: -40px;
  }
  .Row .Increase {
    width: 100%;
    max-width: 212px;
    margin-top: 30px;
  }
  .Record {
    align-self: flex-end;
  }
`

export const IconButton = styled(Icon)``

export const Error = styled.small`
  color: var(--agt-error-color);
`

export const Selector = styled(Select)`
  width: 100%;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

export const CheckboxGroup = styled.div`
  display: flex;
  gap: 16px;
  input[type='checkbox'],
  label {
    cursor: pointer;
  }
`

export const OtherComponent = styled.div`
  &.redLabel {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
    @media (max-width: 1024px) {
      /* max-width: 316px; */
      max-width: 270px;
    }
    > input {
      display: none;
    }
    > .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    > .checkmark .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      font-size: 10px;
      color: var(--agt-white-color);
    }
    > input:checked ~ .checkmark {
      background-color: var(--agt-primary-color-1);
    }
    > input:checked ~ .checkmark .icon {
      display: block;
    }
  }
`

export const OptionsStyles = {
  // container: (styles, { isDisabled }) => ({
  //   cursor: isDisabled ? 'not-allowed' : 'default',
  // }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: (styles, { isDisabled }) => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    color: isDisabled ? 'var(--agt-primary-text-color)' : 'var(--agt-primary-text-color)',
  }),
}

export const OptionsError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}
