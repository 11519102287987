import { createSlice } from '@reduxjs/toolkit'
import Api from 'api'
import moment from 'moment'
import { EventType } from 'types/EventType'
import SetStateType from 'types/SetStateType'
import { EventsThunk } from './store'

const initialState = {
  initialized: false,
  error: null,
}

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    setInitialize(state) {
      state.initialized = true
    },
  },
})

export const { setInitialize } = eventsSlice.actions

export default eventsSlice.reducer

export const getEvents = (cb: Function): EventsThunk => async () => {
  try {
    const response = await Api.get('/events/')
    cb(response)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

export const getEventsByDate = (today: string, cb: Function): EventsThunk => async () => {
  try {
    const response = await Api.get('/events/select_dated', {
      dateSearch: moment(today).format('DD/MM/YYYY'),
    })
    cb(response)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

export const getTasks = (cb: Function): EventsThunk => async () => {
  try {
    const response = await Api.get('/tasks/')
    cb(response)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

export const getEventById = (
  id: string | number,
  setEvents: SetStateType<EventType>,
): EventsThunk => async () => {
  try {
    const response = await Api.get(`/events/${id}`)
    setEvents(response.data.data.event)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
      switch (err.response.status) {
        case 404:
          window.open('/calendar', '_self')
          break
        default:
          break
      }
    }
    throw err
  }
}

export const confirmDelete = (id: string | number, cb: Function): EventsThunk => async () => {
  try {
    await Api.delete(`/events/${id}`, {})
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const updateEvent = (
  id: string | number,
  newEvent: EventType,
  cb: Function,
): EventsThunk => async () => {
  try {
    await Api.put(`/events/${id}`, newEvent)
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
