import React, { FC } from 'react'
import styled from '@emotion/styled'

import Icon from 'components/Icon'

type HintType = {
  className?: string
  onClick?: Function
}

const Container = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  margin-left: 24px;
  cursor: pointer;
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
const Text = styled.span`
  font-size: 17px;
  color: var(--agt-primary-color-1);
`
const Hint: FC<HintType> = ({ className = '', onClick = () => {} }) => {
  return (
    <Container className={className} onClick={() => onClick()}>
      <HintIcon name="hint" />
      <Text>คำแนะนำ</Text>
    </Container>
  )
}

export default Hint
