import styled from '@emotion/styled'
import Select from 'react-select'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  table,
  th,
  td {
    border: 1px solid #e6e8ec;
    border-collapse: collapse;
  }
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const Detail = styled.div`
  width: 100%;
  max-width: 528px;
  margin: 44px auto 40px;
  display: flex;
  flex-direction: column;
  .title {
    margin-bottom: 40px;
    text-align: center;
  }
  .customer {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 24px;
  }
  .customer > div:last-of-type {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: var(--agt-primary-color-1);
  }
  .select {
    width: 100%;
    display: flex;
    column-gap: 24px;
    margin-bottom: 24px;
  }
  .select label {
    width: calc(100% / 3);
    padding: 24px 6px;
    border: 2px solid var(--agt-secondary-text-color);
    color: var(--agt-secondary-text-color);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .select label input {
    display: none;
  }
  .select label.active {
    color: var(--agt-primary-color-1);
    font-weight: bold;
    border: 2px solid var(--agt-primary-color-1);
  }
  .select label > span:first-of-type {
    height: 70px;
  }
  .select label > span:last-of-type {
    margin-top: 18px;
  }
  .select label .checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--agt-white-text-color);
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 50%;
    /* margin-top: 18px; */
  }
  .select label:hover {
    cursor: pointer;
  }
  .select label input:checked ~ .checkmark {
    border: 2px solid var(--agt-primary-color-1);
  }
  .select label .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .select label input:checked ~ .checkmark:after {
    display: block;
  }
  .select label .checkmark:after {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--agt-primary-color-1);
  }
  .insurance {
    margin-bottom: 24px;
  }
  .insurance > div:first-of-type {
    margin-bottom: 4px;
  }
  /* a {
    align-self: center;
  }
  a > button {
    width: 193px;
  } */
  button {
    width: 193px;
    align-self: center;
  }
`

export const Table = styled.table`
  width: 100%;
  max-width: 928px;
  margin: 0 auto 24px;
  border: 1px solid #e6e8ec;
  border-radius: 5px;
  thead {
    width: 100%;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
  }
  thead tr td {
    text-align: center;
    padding: 10px 0;
  }
  thead tr > td:first-of-type {
    width: 62%;
    max-width: 578px;
  }
  thead tr > td:last-of-type {
    width: 38%;
  }
  tbody tr td {
    padding: 10px 24px;
  }
  tbody .group {
    text-align: center;
    vertical-align: center;
  }
`

export const SmallText = styled.div`
  text-align: center;
`

export const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

export const Selector = styled(Select)`
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
