import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Commission from './Commission'

const Pages: FC = () => (
  <Switch>
    <ProtectedRoute path="/commission" component={Commission} />
  </Switch>
)

export default Pages
