import React from 'react'
import { Field } from 'formik'
import { ContactType } from 'types/ContactType'
import { ListProps } from './InterestedEdit.type'

export const DistributeList = (props: ListProps) => {
  return (
    <Field
      name={`${props.currentTab}.${props.lifeStyleKey}.values`}
      type="checkbox"
      value={props.value}
    />
  )
}

export const removeAvatar = (props: ContactType) => {
  if (typeof props.avatar === 'string') {
    delete props.avatar
  }
}
