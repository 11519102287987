import React from 'react'
import { InputCustom } from './ShowDetail.styles'

export const InputComponent = ({ title, content, width = 0 }) => {
  return (
    <InputCustom width={width}>
      <b>{title}</b>
      <p>{content}</p>
    </InputCustom>
  )
}
