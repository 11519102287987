import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
