import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { useFormikContext } from 'formik'

import Modal from './CustomModal'
import { verifyOtp } from 'store/profileSlice'

type OtpinputField = {
  bordercolor?: string
}

const CustomModal = styled.div<OtpinputField>`
  width: 416px;
  padding: 24px;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .OTP {
    margin-bottom: 10px;
    display: flex;
    /* justify-content: space-around; */
    .otpInput {
      width: 40px;
      padding: 0;
      text-align: center;
      margin-right: 14px;
      border-color: ${(props) => props.bordercolor && props.bordercolor};
    }
  }
`
const ChangePhoneNumber = styled.small`
  color: var(--agt-primary-color-1);
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: var(--agt-dark-sky-color);
  }
`
const OTPComponent = (props) => {
  const {
    isModalOTP,
    refOtp,
    isSuccesOTP,
    isOTP,
    setIsOTP,
    counter,
    values,
    setIsSuccessOTP,
    setIsWaitOTP,
    setIsModalOTP,
  } = props
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()
  const inputfocus = (elmnt, callback) => {
    if (elmnt.key === 'Delete' || elmnt.key === 'Backspace') {
      callback('')
      const next = elmnt.target.tabIndex - 2
      if (next > -1) {
        elmnt.target.form.elements[next].focus()
      }
    } else {
      if (isNaN(elmnt.key)) {
        callback('')
      } else {
        const next = elmnt.target.tabIndex
        if (next < 6) {
          elmnt.target.form.elements[next].focus()
          callback(elmnt.key.toString())
        } else {
          callback(elmnt.key.toString())
          elmnt.target.blur()
        }
      }
    }
  }
  const otpCombine = useCallback(() => {
    let otpcombine = isOTP.otp1 + isOTP.otp2 + isOTP.otp3 + isOTP.otp4 + isOTP.otp5 + isOTP.otp6

    return otpcombine
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOTP])

  return (
    <Modal isOpen={isModalOTP} onConfirm={() => {}} onCancel={() => {}}>
      <CustomModal bordercolor={isSuccesOTP ? 'none' : 'var(--agt-error-color)'}>
        <div>กรอก OTP เพื่อยืนยัน</div>
        <div style={{ marginBottom: 14 }}>เบอร์โทรศัพท์</div>
        <small style={{ color: 'var(--agt-primary-color-1)', marginBottom: 24 }}>
          Ref Code : {refOtp.otpRefCode}
        </small>
        <div className="OTP">
          <form>
            <input
              name="otp1"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp1}
              tabIndex={1}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp1: callback }))
              }}
            />
            <input
              name="otp2"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp2}
              tabIndex={2}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp2: callback }))
              }}
            />
            <input
              name="otp3"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp3}
              tabIndex={3}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp3: callback }))
              }}
            />
            <input
              name="otp4"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp4}
              tabIndex={4}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp4: callback }))
              }}
            />
            <input
              name="otp5"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp5}
              tabIndex={5}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp5: callback }))
              }}
            />
            <input
              name="otp6"
              type="text"
              autoComplete="off"
              className="otpInput"
              value={isOTP.otp6}
              tabIndex={6}
              maxLength={1}
              onKeyUp={(e) => {
                inputfocus(e, (callback) => setIsOTP({ ...isOTP, otp6: callback }))
              }}
              style={{ marginRight: 0 }}
            />
          </form>
        </div>
        <small style={{ color: 'var(--agt-primary-text-color)', marginBottom: 24 }}>
          สามารถขอรหัส OTP ได้ใหม่หลังจาก {counter} วินาที
        </small>
        <button
          type="button"
          style={{ marginBottom: 14 }}
          disabled={
            isEmpty(isOTP.otp1) ||
            isEmpty(isOTP.otp2) ||
            isEmpty(isOTP.otp3) ||
            isEmpty(isOTP.otp4) ||
            isEmpty(isOTP.otp5) ||
            isEmpty(isOTP.otp6)
          }
          onClick={() => {
            dispatch(
              verifyOtp(
                values.phoneNumber,
                otpCombine(),
                refOtp.otpRefCode,
                setIsSuccessOTP,
                setIsWaitOTP,
                setIsModalOTP,
                () => setFieldValue('isOTP', otpCombine()),
              ),
            )
            setIsOTP({
              otp1: '',
              otp2: '',
              otp3: '',
              otp4: '',
              otp5: '',
              otp6: '',
            })
          }}
        >
          ยืนยัน
        </button>
        <>
          <ChangePhoneNumber
            onClick={() => {
              setIsModalOTP(false)
              setIsSuccessOTP(true)
            }}
          >
            เปลี่ยนเบอร์มือถือ
          </ChangePhoneNumber>
        </>
      </CustomModal>
    </Modal>
  )
}

export default OTPComponent
