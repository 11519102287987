import React, { useState } from 'react'
import Modal from 'react-modal'
import Icon from 'components/Icon'
import styled from '@emotion/styled'
import Select from 'react-select'
import moment from 'moment'

const THIS_MONTH = new Date().getMonth() + 1
const THIS_YEAR = new Date().getFullYear()
type OptionType = {
  value: string
  label: string
}
const initDate = {
  month: '',
  year: '',
}
const monthOptions: Array<OptionType> = [
  { value: '01', label: 'มกราคม' },
  { value: '02', label: 'กุมภาพันธ์' },
  { value: '03', label: 'มีนาคม' },
  { value: '04', label: 'เมษายน' },
  { value: '05', label: 'พฤษภาคม' },
  { value: '06', label: 'มิถุนายน' },
  { value: '07', label: 'กรกฎาคม' },
  { value: '08', label: 'สิงหาคม' },
  { value: '09', label: 'กันยายน' },
  { value: '10', label: 'ตุลาคม' },
  { value: '11', label: 'พฤศจิกายน' },
  { value: '12', label: 'ธันวาคม' },
]
const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
}
const YearsSelector = styled(Select)`
  border-radius: 4px;
  width: 352px;
  .css-yk16xz-control {
    height: 48px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const MonthSelector = styled(Select)`
  border-radius: 4px;
  width: 180px;
  .css-yk16xz-control {
    height: 48px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const MonthYearSelector = styled(Select)`
  border-radius: 4px;
  width: 180px;
  .css-yk16xz-control {
    height: 48px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const CustomModal = styled.div`
  width: 100%;
  min-width: 638px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 13px 13px 24px;
    border-bottom: 1px solid var(--agt-background-color-2);
    background-color: var(--agt-background-color-3);
    color: var(--agt-primary-color-1);
  }
  .body {
    padding: 24px 0 0 0;
    li {
      list-style: none;
    }
    li:last-of-type {
      padding-bottom: 24px;
    }
  }
  .body .title {
    font-weight: bold;
    padding: 0 24px;
    margin-bottom: 24px;
  }

  .body .customChecked .dotChecked {
    width: 8px;
    height: 8px;
    border-radius: 4.5px;
    background-color: var(--agt-primary-color-text);
  }
  .body .total {
    padding: 0 24px;
  }
  .body .total .divTotal {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .body .total .divTotal:hover {
    cursor: pointer;
  }
  .body .total .divTotal input {
    display: none;
  }
  .body .total .divTotal .customChecked {
    width: 16px;
    height: 16px;
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 8px;
    background-color: var(--agt-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .body .total .divTotal .customChecked .dotChecked {
    width: 8px;
    height: 8px;
    border-radius: 4.5px;
    background-color: var(--agt-primary-color-text);
  } */
  .body .total .divTotal input:checked ~ .customChecked {
    border: 2px solid var(--agt-primary-color-1);
  }
  .body .total .divTotal input:checked ~ .customChecked .dotChecked {
    background-color: var(--agt-primary-color-1);
  }
  .body .total .divTotal .label {
    margin-left: 24px;
  }
  .body .total .divTotal input:checked ~ .label {
    color: var(--agt-primary-color-1);
    font-weight: bold;
  }
  .body .month {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
  }
  .body .month .selectsMonth {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .body .month .selectsMonth:hover {
    cursor: pointer;
  }
  .body .month .selectsMonth input {
    display: none;
  }
  .body .month .selectsMonth .customChecked {
    width: 16px;
    height: 16px;
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 8px;
    background-color: var(--agt-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .body .month .selectsMonth .customChecked .dotChecked {
    width: 8px;
    height: 8px;
    border-radius: 4.5px;
    background-color: var(--agt-primary-color-text);
  } */
  .body .month .selectsMonth input:checked ~ .customChecked {
    border: 2px solid var(--agt-primary-color-1);
  }
  .body .month .selectsMonth input:checked ~ .customChecked .dotChecked {
    background-color: var(--agt-primary-color-1);
  }
  .body .month .selectsMonth .label {
    margin-left: 24px;
  }
  .body .month .selectsMonth input:checked ~ .label {
    color: var(--agt-primary-color-1);
    font-weight: bold;
  }
  .body .month .optionsMonth {
    display: flex;
    align-items: center;
    padding: 0 0 0 40px;
    column-gap: 24px;
  }
  .body .month .optionsMonth .options {
    display: flex;
    align-items: center;
  }
  .body .month .optionsMonth .options label {
    margin-right: 16px;
  }
  .body .year {
    padding: 0 24px;
  }
  .body .year .divYear {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  .body .year .divYear:hover {
    cursor: pointer;
  }
  .body .year .divYear input {
    display: none;
  }
  .body .year .divYear .customChecked {
    width: 16px;
    height: 16px;
    border: 2px solid var(--agt-secondary-color-2);
    border-radius: 8px;
    background-color: var(--agt-white-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .body .year .divYear .customChecked .dotChecked {
    width: 9px;
    height: 9px;
    border-radius: 4.5px;
    background-color: var(--agt-primary-color-text);
  } */
  .body .year .divYear input:checked ~ .customChecked {
    border: 2px solid var(--agt-primary-color-1);
  }
  .body .year .divYear input:checked ~ .customChecked .dotChecked {
    background-color: var(--agt-primary-color-1);
  }
  .body .year .divYear .label {
    margin-left: 24px;
  }
  .body .year .divYear input:checked ~ .label {
    color: var(--agt-primary-color-1);
    font-weight: bold;
  }
  .body .year .optionsYear {
    padding: 0 40px;
    display: flex;
    align-items: center;
  }
  .body .year .optionsYear label {
    margin-right: 24px;
  }
  .footer {
    border-top: 1px solid var(--agt-background-color-2);
    display: flex;
    justify-content: center;
  }
  .footer button {
    margin: 14px 0;
  }
`
const CustomIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0px;
  padding: 14px;
`

const ReportModal = (props) => {
  const {
    values,
    isOpenReport = false,
    setIsOpenReport,
    handleSubmit,
    setFieldValue,
    options,
  } = props
  const [date, setDate] = useState(initDate)
  if (isOpenReport)
    return (
      <Modal isOpen={isOpenReport} style={ModalCustomStyle}>
        <CustomModal>
          <form id={'reportForm'} onSubmit={handleSubmit}>
            <section className="header">
              <b>ออกรายงาน</b>
              <div
                onClick={() => {
                  setIsOpenReport(false)
                }}
              >
                <CustomIcon name="close_button" />
              </div>
            </section>
            <section className="body">
              <p className="title">เลือกช่วงเวลาตามวันที่ได้รับเงินเพื่อออกรายงาน</p>
              <ul>
                <li className="total">
                  <div
                    className="divTotal"
                    onClick={() => {
                      setFieldValue('type', 'all')
                    }}
                  >
                    <input id="total" type="checkbox" checked={values.type === 'all'} />
                    <div className="customChecked">
                      <div className="dotChecked" />
                    </div>
                    <div className="label">ทั้งหมด</div>
                  </div>
                </li>
                <li
                  className="month"
                  style={
                    values.type === 'month'
                      ? {
                          backgroundColor: 'var(--agt-background-color-1)',
                          paddingTop: 16,
                          paddingBottom: 24,
                        }
                      : { paddingTop: 16 }
                  }
                >
                  <div
                    className="selectsMonth"
                    onClick={() => {
                      setFieldValue('type', 'month')
                      setDate(initDate)
                    }}
                  >
                    <input id="month" type="checkbox" checked={values.type === 'month'} />
                    <div className="customChecked">
                      <div className="dotChecked" />
                    </div>
                    <div className="label">รายเดือน</div>
                  </div>
                  {values.type === 'month' && (
                    <div className="optionsMonth">
                      <div className="options">
                        <label>เลือกปี</label>
                        <MonthYearSelector
                          options={options.yearOptions}
                          placeholder="เลือก"
                          onChange={(e) => setDate({ ...date, year: e.value })}
                        />
                      </div>
                      <div className="options">
                        <label>เดือน</label>
                        <MonthSelector
                          options={
                            THIS_YEAR === parseFloat(date.year)
                              ? monthOptions.filter(
                                  (option) => parseFloat(option.value) <= THIS_MONTH,
                                )
                              : monthOptions
                          }
                          placeholder="เลือก"
                          onChange={(e) => setDate({ ...date, month: e.value })}
                        />
                      </div>
                    </div>
                  )}
                </li>
                <li
                  className="year"
                  style={
                    values.type === 'year'
                      ? {
                          backgroundColor: 'var(--agt-background-color-1)',
                          paddingTop: 16,
                          paddingBottom: 24,
                        }
                      : { paddingTop: 16 }
                  }
                >
                  <div
                    className="divYear"
                    onClick={() => {
                      setFieldValue('type', 'year')
                      setDate(initDate)
                    }}
                  >
                    <input id="year" type="checkbox" checked={values.type === 'year'} />
                    <div className="customChecked">
                      <div className="dotChecked" />
                    </div>
                    <div className="label">รายปี</div>
                  </div>
                  {values.type === 'year' && (
                    <div className="optionsYear">
                      <label>เลือกปี</label>
                      <YearsSelector
                        options={options.yearOptions}
                        placeholder="เลือก"
                        onChange={(e) => setDate({ ...date, month: '01', year: e.value })}
                      />
                    </div>
                  )}
                </li>
              </ul>
            </section>
            <section className="footer">
              <button
                type="submit"
                disabled={
                  (values.type === 'month' && (date.month === '' || date.year === '')) ||
                  (values.type === 'year' && date.month === '')
                }
                form="reportForm"
                onClick={() => {
                  let newDate = new Date(`${date.month}/01/${date.year}`)
                  if (values.type !== 'all') setFieldValue('exportDate', moment(newDate).format())
                }}
              >
                ออกรายงาน
              </button>
            </section>
          </form>
        </CustomModal>
      </Modal>
    )
  return null
}

export default ReportModal
