import * as Yup from 'yup'

const DropleadSchema = Yup.object().shape({
  order: Yup.object().shape({
    taDetails: Yup.object().shape({
      orderPolicyBeneficiary: Yup.array().of(
        Yup.object().shape({
          birthdate: Yup.string().nullable().required('กรุณาระบุ'),
          email: Yup.string().nullable().required('กรุณาระบุ'),
          firstName: Yup.string().nullable().required('กรุณาระบุ'),
          idCard: Yup.string().nullable().required('กรุณาระบุ'),
          lastName: Yup.string().nullable().required('กรุณาระบุ'),
          nationality: Yup.string().nullable().required('กรุณาระบุ'),
          phoneNumber: Yup.string().nullable().required('กรุณาระบุ'),
          prefixName: Yup.string().nullable().required('กรุณาระบุ'),
          relations: Yup.string()
            .nullable()
            .when(['statutoryHeir'], {
              is: (statutoryHeir) => statutoryHeir === false,
              then: Yup.string().required('กรุณาระบุ').nullable(),
            }),
          beneficiary: Yup.string()
            .nullable()
            .when(['statutoryHeir'], {
              is: (statutoryHeir) => statutoryHeir === false,
              then: Yup.string().required('กรุณาระบุ').nullable(),
            }),
        }),
      ),
      policyAddress: Yup.object().shape({
        housingNo: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        subDistrict: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        district: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        province: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
        postcode: Yup.string().nullable().required('กรุณาระบุข้อมูล'),
      }),
    }),
  }),
})

export default DropleadSchema
