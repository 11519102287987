import React from 'react'
import { isUndefined, get, isEmpty, isObject } from 'lodash'
import { dateFormat } from 'utils/helper'
import TabSideBar from './TabSideBar'
import OrdersCard from './OrdersCard'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { Header, HintIcon } from './OpportunityCard.styles'
import { DisplayCardType, HeaderComponentType } from './OpportunityCard.type'

export const DisplayCard = ({ id, setIsEdit }: DisplayCardType) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const { opportunity: data, canEdit } = useSelector((state: RootState) => state.opportunities)

  return (
    <div className="Body">
      <div className="Content">
        <div className="Detail">
          <div className="RowLayout" style={{ marginBottom: '16px', alignItems: 'center' }}>
            <div className="Column" style={{ marginBottom: '0' }}>
              <b>{get(data, 'name', '')}</b>
            </div>
            <small style={{ color: 'var(--agt-secondary-color-1)' }}>
              {get(data, 'opportunityKey', '')}
            </small>
          </div>
          <div
            className="RowLayout"
            style={{
              marginBottom: '16px',
              alignItems: 'flex-start',
              paddingBottom: '24px',
              borderBottom: '1px solid var(--agt-secondary-color-1)',
            }}
          >
            <div>รายชื่อลูกค้า : {get(data, 'contactName', '')}</div>
            <div className="Column" style={{ marginBottom: '0', alignItems: 'flex-end' }}>
              <small>อัปเดตล่าสุด {dateFormat(get(data, 'updatedAt', ''))}</small>
              <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                สร้างเมื่อ {dateFormat(get(data, 'createdAt', ''))}
              </small>
            </div>
          </div>
          <div className="Column" id="opportunity-id__note">
            <div style={{ marginBottom: '16px' }}>โน้ตเพิ่มเติม</div>
            <div style={{ marginBottom: '16px' }}>
              {isEmpty(get(data, 'note', '')) ? '-' : get(data, 'note')}
            </div>
            {!isUndefined(id) && <OrdersCard orders={data?.orders || []} id={id} />}
          </div>
        </div>
        <div className="Submit" style={{ justifyContent: 'flex-end' }}>
          {data && data.orders && data.orders.length === 0 && (
            <button
              type="button"
              className="secondary"
              disabled={currentAgent?.statusTh === 'ระงับ'}
              style={{
                marginRight: '24px',
                display: 'none',
              }}
            >
              ลบ
            </button>
          )}
          <button
            type="button"
            className="secondary"
            onClick={() => setIsEdit(true)}
            disabled={
              currentAgent?.statusTh === 'ระงับ' ||
              canEdit === false ||
              (isObject(data?.currentAgreement || null) && data?.isConsented === false)
            }
          >
            แก้ไข
          </button>
        </div>
      </div>
      <div className="Sub">
        <TabSideBar
          header="นัดหมาย"
          events={data?.events}
          addition={false}
          currentAgent={currentAgent}
        />
        <TabSideBar
          header="งานที่ต้องทำ"
          tasks={data?.tasks}
          addition={false}
          currentAgent={currentAgent}
        />
      </div>
    </div>
  )
}

export const HeaderComponent = ({ setIsShowHint, id }: HeaderComponentType) => {
  return (
    <Header>
      <h3 className="Header">{id ? 'โอกาสในการขาย' : 'สร้างโอกาสในการขาย'}</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}
