import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import Select, { StylesConfig } from 'react-select'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const customSelectStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
  }),
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  background-color: var(--agt-white-color);
  padding: 4px;
  border-radius: 8px;
  &.firstState {
    margin-top: -30px;
  }
  &.secondState {
    margin-top: 0px;
  }
  .column {
    background-color: var(--agt-white-color);
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }
  .column .inputForm {
    width: 356px;
  }
  .column .textAreaForm {
    width: 736px;
    height: 142px;
    resize: none;
  }
`
const CustomDatePicker = styled(DatePicker)`
  width: 356px;
`

const WebTour = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 2
  const layout = [
    {
      top: '0px',
      // right: '16px',
      right: '376px',
      width: '239px',
      arrow: '',
    },
    {
      // top: '0px',
      // right: '16px',
      // right: '376px',
      top: '32px',
      right: '756px',
      width: '270px',
      arrow: '',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [labelNamePosition, setLabelNamePosition] = useState({
    top: '0',
    left: '0',
  })
  const [labelContactPosition, setLabelContactPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('task-id__name', setLabelNamePosition)
      getPositionById('task-id__contact', setLabelContactPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    // document.addEventListener('scroll', getPosition)
    return () => {
      // document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>สร้างงานที่ต้องทำ</b>
          <small>ระบุชื่อลูกค้าหากเป็นงานที่เกี่ยวข้องกับลูกค้าได้</small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>สร้างงานที่ต้องทำ</b>
          <small>
            ระบุชื่องานและวันครบกำหนดเพื่อกำหนด
            <br />
            ระยะเวลาสิ้นสุดของงานนี้
          </small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <CustomForm className="firstState">
          <div className="column">
            <div>ชื่องาน *</div>
            <input className="inputForm" placeholder="โปรดระบุ" />
          </div>
          <div className="column">
            <div>วันครบกำหนด *</div>
            <CustomDatePicker name="" disabled />
          </div>
        </CustomForm>
      )
    } else if (step === 2) {
      return (
        <CustomForm className="secondState">
          <div className="column">
            <div>ลูกค้าที่เกี่ยวข้อง</div>
            <Select
              styles={customSelectStyles}
              isClearable
              placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
              isDisabled={true}
            />
          </div>
          <div className="column">
            <div>รายละเอียด</div>
            <textarea className="textAreaForm" placeholder="โปรดระบุ" />
          </div>
        </CustomForm>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1) {
      return labelNamePosition
    } else if (step === 2) {
      return labelContactPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTour
