import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { camelizeKeys, decamelize, decamelizeKeys } from 'humps'

import jsonToFormData from 'lib/jsonToFormData'
import session from 'utils/session'

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
  isFormData?: boolean
}

const setAppError = (e: any, message: any) => {
  // alert(message)
  return Promise.reject({ ...e, message })
}

class Api {
  private http: AxiosInstance

  constructor() {
    this.http = axios.create({
      baseURL: `${process.env.REACT_APP_AGENT_TOOLS_API_HOST}/api/frontend/v1`,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    })

    this.http.interceptors.request.use(
      async (config: AxiosRequestConfigCustom) => ({
        ...config,
        headers: this.prepareRequestHeaders(config.headers),
        params: this.prepareRequestParams(config.params, config.isFormData || false),
        data: this.prepareRequestParams(config.data, config.isFormData || false),
      }),
      null || undefined,
    )

    this.http.interceptors.response.use(
      (response: AxiosResponse) => this.handleSuccess(response),
      (error: AxiosError) => this.handleError(error),
    )
  }

  prepareRequestHeaders(headers: AxiosRequestConfig) {
    return { ...headers, 'Agent-Tools-Auth-Token': localStorage.getItem('authToken') }
  }
  prepareRequestParams = (params: AxiosRequestConfig, isFormData: boolean) => {
    if (!params) return
    if (isFormData)
      return jsonToFormData(params, {
        transfromPropName: (key: string) => decamelize(key),
        showLeafArrayIndexes: false,
      })

    return decamelizeKeys(params)
  }

  prepareResponseData(data: AxiosResponse) {
    return camelizeKeys(data)
  }

  handleSuccess(response: AxiosResponse) {
    if (response.headers['content-type'] === 'application/octet-stream') {
      return response
    } else {
      return { ...response, data: this.prepareResponseData(response.data) }
    }
  }

  async onUnauthorized(e: AxiosError) {
    await session.removeAuthToken()
    await session.removeAuthTqmLifeToken()
    const { response } = e
    return setAppError(e, response?.data.message)
  }

  onNotFound(e: AxiosError) {
    const { response } = e
    return setAppError(e, response?.data.message)
  }

  onBadRequest(e: AxiosError) {
    const { response } = e
    return setAppError(e, response?.data.message)
  }

  onServerError(e: AxiosError) {
    return setAppError(e, 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
  }

  onRequestTimeout(e: AxiosError) {
    return setAppError(e, 'INTERNET ล่าช้า กรุณาลองใหม่อีกครั้ง')
  }

  onNetworkError(e: AxiosError) {
    return setAppError(e, 'การเชื่อมต่อกับเซิฟเวอร์มีปัญหา กรุณาลองใหม่อีกครั้งภายหลัง')
  }

  handleError(e: AxiosError) {
    const { response } = e
    if (response) {
      const { code } = response.data
      if (code === 'unauthorized') return this.onUnauthorized(e)
      if (code === 'not_found') return this.onNotFound(e)
      if (code === 'server_error') return this.onServerError(e)
      if (code === 'bad_request') return this.onBadRequest(e)

      const error = { ...e, response: this.prepareResponseData(response) }

      return Promise.reject(error)
    } else {
      if (/timeout/.test(e.message)) return this.onRequestTimeout(e)
      if (/Network/.test(e.message)) return this.onNetworkError(e)

      return Promise.reject(e)
    }
  }

  get(path: string, params?: object, options = {}) {
    return this.http.get(path, { params, ...options })
  }

  post(path: string, params: object, options = {}) {
    return this.http.post(path, params, options)
  }

  put(path: string, params: object, options = {}) {
    return this.http.put(path, params, options)
  }

  patch(path: string, params: object, options = {}) {
    return this.http.patch(path, params, options)
  }

  delete(path: string, params: object) {
    return this.http.delete(path, { params })
  }
}

export default new Api()
