import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import { setCommissionsOptionType, setCommissionsType } from 'types/CommissionsType'
import { CommissionsThunk } from './store'
import downloadjs from 'downloadjs'
import mime from 'mime-types'

const initialState: setCommissionsType = {
  initialized: false,
  salesCommissions: [],
  error: null,
  options: { yearOptions: [] },
  paging: {
    page: 1,
    perPage: 10,
    totalCount: 0,
    totalPages: 0,
  },
}

const commissionsSlice = createSlice({
  name: 'commissions',
  initialState,
  reducers: {
    setInitialize(state) {
      state.initialized = true
    },
    setSalesCommissions(state, action: PayloadAction<setCommissionsType>) {
      state.salesCommissions = action.payload.salesCommissions
      state.paging = action.payload.paging
    },
    setOptions(state, action: PayloadAction<setCommissionsOptionType>) {
      state.options = action.payload
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  setInitialize,
  setSalesCommissions,
  setOptions,
  setErrorMessage,
} = commissionsSlice.actions

export default commissionsSlice.reducer

export const getSalesCommissions = (values): CommissionsThunk => async (dispatch) => {
  try {
    const response = await Api.get('/sales_commissions', values)
    dispatch(setSalesCommissions(response.data.data))
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const getOptions = (): CommissionsThunk => async (dispatch) => {
  try {
    const response = await Api.get('sales_commissions/options')
    dispatch(setOptions(response.data.data))
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const exportCommission = (values, callback = () => {}) => async (dispatch) => {
  try {
    const response = await Api.get('/sales_commissions/export_sales_commissions', values, {
      responseType: 'blob',
    })
    const { data, headers } = response
    const filename = headers['content-disposition'].match(/filename*="?(.*)"?/)[1].split(';')[0]
    const fileType = mime.lookup(filename)
    downloadjs(data, decodeURI(filename), fileType)
    callback()
  } catch (error) {
    console.error(error.response || error)
  }
}
