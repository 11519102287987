import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PlanningThunk } from 'store/store'
import Api from 'api'
import { PlanState, IncomeType, HealthType } from 'types/planningType'

const initialState: PlanState = {
  initialized: false,
  error: null,
  plan: {},
  options: {
    socialInsuranceInsuredTypeOptions: [],
    incomeCoveredTypeOptions: [],
    investmentRiskOptions: [],
    assetTypeOptions: [],
    debtTypeOptions: [],
    incomeTypeOptions: [],
    hospitalNameOptions: [],
    roomTypeOptions: [],
    freeAssetOptions: [],
  },
  debtData: [],
  incomeData: {
    calculatedData: {
      expensePerYear: '',
      incomeGrowthRateWithInflation: 0,
      incomePerYear: '',
      investmentRiskRate: 0,
      providentFundSumInsured: '',
      rorWithIncomeGrowthRate: 0,
      rorWithInflation: 0,
      socialInsuraceSumInsured: '',
      totalRemaining: '',
      totalTaxPerYear: '',
      yearRemaining: 0,
    },
    incomeProtections: {
      freeAssets: [{ assetType: '', assetValue: 0 }],
      incomeCoveredType: '',
      incomeCoveredValue: 0,
      othersCoveredValue: '',
    },
  },
  healthData: {
    healthcareProtections: { hospitalId: 0, hospitalName: '', roomType: '', admissionNumber: 0 },
  },
  freeAssetData: [],
  nonLifeData: [],
  planData: [],
}

const contactsSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    initializeNotifications(state) {
      state.initialized = true
    },
    getPersonalInformationSuccess(state, action: PayloadAction<PlanState>) {
      state.plan = action.payload.plan
      state.error = null
    },
    getOptionSuccess(state, action: PayloadAction<PlanState>) {
      state.options = action.payload.options
      state.error = null
    },
    getDebtProtectionsSuccess(state, action: PayloadAction<PlanState>) {
      state.debtData = action.payload.debtData
      state.error = null
    },
    getDebtProtectionOptionsSuccess(state, action: PayloadAction<PlanState>) {
      state.options = action.payload.options
      state.error = null
    },
    getDebtProtectionFreeAssetDatasSuccess(state, action: PayloadAction<PlanState>) {
      state.freeAssetData = action.payload.freeAssetData
      state.error = null
    },

    getIncomeProtectionsSuccess(state, action: PayloadAction<IncomeType>) {
      state.incomeData = action.payload
      state.error = null
    },

    getIncomeProtectionOptionsSuccess(state, action: PayloadAction<PlanState>) {
      state.options = action.payload.options
      state.error = null
    },

    getIncomeProtectionFreeAssetDatasSuccess(state, action: PayloadAction<PlanState>) {
      state.freeAssetData = action.payload.freeAssetData
      state.error = null
    },

    getHealthCareProtectionsSuccess(state, action: PayloadAction<HealthType>) {
      state.healthData = action.payload
      state.error = null
    },

    getHealthCareProtectionOptionsSuccess(state, action: PayloadAction<PlanState>) {
      state.options = action.payload.options
      state.error = null
    },

    getNonLifeProtectionsOptionsSuccess(state, action: PayloadAction<PlanState>) {
      state.options = action.payload.options
      state.error = null
    },
    getNonLifeProtectionsSuccess(state, action: PayloadAction<PlanState>) {
      state.nonLifeData = action.payload.nonLifeData
      state.error = null
    },
    getSummarySuccess(state, action: PayloadAction<PlanState>) {
      state.planData = action.payload.planData
      state.error = null
    },
    apiFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  initializeNotifications,
  getPersonalInformationSuccess,
  getOptionSuccess,
  getDebtProtectionsSuccess,
  getDebtProtectionOptionsSuccess,
  getDebtProtectionFreeAssetDatasSuccess,
  getIncomeProtectionsSuccess,
  getIncomeProtectionOptionsSuccess,
  getIncomeProtectionFreeAssetDatasSuccess,
  getHealthCareProtectionsSuccess,
  getHealthCareProtectionOptionsSuccess,
  getNonLifeProtectionsOptionsSuccess,
  getNonLifeProtectionsSuccess,
  getSummarySuccess,
  apiFailed,
} = contactsSlice.actions

export default contactsSlice.reducer

export const getPersonalInformation = (id: number | string): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans`)
    dispatch(getPersonalInformationSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createPersonalInformation = (id: number | string, data): PlanningThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans`, { ...data })
    dispatch(getPersonalInformationSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getOptions = (id: number | string): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/options`)
    dispatch(getOptionSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createDebtProtections = (id: number, data): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/debt_protections`, { ...data })
    dispatch(getDebtProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getDebtProtections = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/debt_protections`)
    dispatch(getDebtProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getDebtProtectionOptions = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/debt_protections/options`)
    dispatch(getDebtProtectionOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createDebtProtectionFreeAssetDatas = (id: number, data): PlanningThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/debt_protections/free_assets`, {
      ...data,
    })
    dispatch(getDebtProtectionFreeAssetDatasSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getDebtProtectionFreeAssetDatas = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/debt_protections/free_assets`)
    dispatch(getDebtProtectionFreeAssetDatasSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createIncomeProtections = (id: number, data): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/income_protections`, { ...data })
    dispatch(getIncomeProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getIncomeProtections = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/income_protections`)
    dispatch(getIncomeProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getIncomeProtectionOptions = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/income_protections/options`)
    dispatch(getIncomeProtectionOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createIncomeProtectionFreeAssetDatas = (id: number, data): PlanningThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/income_protections/free_assets`, {
      ...data,
    })
    dispatch(getIncomeProtectionFreeAssetDatasSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getIncomeProtectionFreeAssetDatas = (id: number): PlanningThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/income_protections/free_assets`)
    dispatch(getIncomeProtectionFreeAssetDatasSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createHealthCareProtections = (id: number, data): PlanningThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/healthcare_protections`, { ...data })
    dispatch(getHealthCareProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getHealthCareProtections = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/healthcare_protections`)
    dispatch(getHealthCareProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getHealthCareProtectionOptions = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/healthcare_protections/options`)
    dispatch(getHealthCareProtectionOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getNonLifeProtectionsOptions = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/non_life_protections/options`)
    dispatch(getNonLifeProtectionsOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getNonLifeProtections = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/non_life_protections`)
    dispatch(getNonLifeProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const createNonLifeProtections = (id: number, data): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.post(`/contacts/${id}/plans/non_life_protections`, data)
    dispatch(getNonLifeProtectionsSuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}

export const getSummarys = (id: number): PlanningThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/plans/summary`)
    dispatch(getSummarySuccess(response.data.data))
  } catch (err) {
    dispatch(apiFailed(err.toString()))
  }
}
