import styled from '@emotion/styled'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.section`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
`

export const BackWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
`

export const CustomIcon = styled(FontAwesomeIcon)`
  color: var(--agt-primary-text-color);
  margin-right: 18px;
`

export const Remark = styled.p`
  text-align: right;
`

export const ProductContainer = styled.div`
  padding: 1rem 0;
  width: 100%;
  overflow: auto;
`

export const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px repeat(3, 284px);
  grid-template-rows: 1fr;
  column-gap: 3px;
  width: 100%;
  padding-inline: 40px;
`

export const BuyButton = styled.button`
  margin: 0.5rem auto 1rem;
`

export const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 40px;
  background-color: var(--agt-background-color-2);
`

export const DetailWrapper = styled.div`
  display: grid;
  grid-template-columns: 25% repeat(3, 25%);
  padding: 1rem 40px;
  row-gap: 1rem;
  label {
    text-align: right;
  }
`

export const Label = styled.p`
  font-weight: bold;
`
