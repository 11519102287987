import * as Yup from 'yup'

let addressSchema = Yup.object().shape({
  housingNo: Yup.string().nullable().required('กรุณาระบุเลขที่'),
  // villageNo: Yup.string().nullable().required('กรุณาระบุหมู่'),
  // building: Yup.string().nullable().required('กรุณาระบุหมู่บ้าน/อาคาร'),
  // lane: Yup.string().nullable().required('กรุณาระบุซอย/ตรอก'),
  // road: Yup.string().nullable().required('กรุณาระบุถนน'),
  subDistrict: Yup.string().nullable().required('กรุณาระบุตำบล/แขวง'),
  district: Yup.string().nullable().required('กรุณาระบุอำเภอ/เขต'),
  province: Yup.string().nullable().required('กรุณาระบุจังหวัด'),
  postcode: Yup.string().nullable().required('กรุณาระบุรหัสไปรษณีย์'),
})

let schema = Yup.object().shape({
  currentAddress: addressSchema,
})

export default schema
