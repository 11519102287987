import React, { FC, useEffect } from 'react'
import Icon from 'components/Icon'
import { useFormikContext, Field } from 'formik'
import { addDays, addYears } from 'date-fns'
import { TravelFormType } from './TravelForm.type'
import { today, fixedOptions } from './TravelForm.utils'
import {
  Container,
  FlightSelecter,
  Selector,
  customSelectStyles,
  customSelectDisabledStyles,
} from './TravelForm.styles'
import { TravelType, DatePicker } from './TravelForm.components'

const TravelForm: FC<TravelFormType> = (props) => {
  const {
    values,
    searchByTypeTa,
    options,
    optionsByTypeTa,
    handleSubmit,
    setSearchByTypeTa,
  } = props
  const { setFieldValue } = useFormikContext()

  useEffect(
    () => {
      setFieldValue('dsc', '')
      setFieldValue('vehicleType', 'plane')
      setFieldValue('travelType', 'D')
      if (values.travelTypeCountry === 'IN') {
        setFieldValue('src', '')
        setFieldValue('countryId', 999)
        setFieldValue('travelDateFrom', addDays(today, 1))
        setFieldValue('travelDateTo', addDays(today, 1))
      } else if (values.travelTypeCountry === 'OUT') {
        setFieldValue('src', 999)
        setFieldValue('countryId', '')
        setFieldValue('travelDateFrom', addDays(today, 1))
        setFieldValue('travelDateTo', addDays(today, 1))
      }
    }, // eslint-disable-next-line
    [values.travelTypeCountry],
  )

  useEffect(
    () => {
      if (values.travelTypeCountry === 'OUT') {
        setFieldValue('countryId', '')
        if (values.travelType === 'D') {
          setFieldValue('travelDateTo', addDays(today, 1))
        } else if (values.travelType === 'Y') {
          setFieldValue('travelDateFrom', addDays(today, 1))
          setFieldValue('travelDateTo', addYears(addDays(today, -1), 1))
        }
      }
    }, // eslint-disable-next-line
    [values.travelType],
  )

  useEffect(
    () => {
      if (values.travelTypeCountry === 'IN') {
        setFieldValue('travelDateTo', addDays(values.travelDateFrom, 0))
      } else if (values.travelTypeCountry === 'OUT' && values.travelType === 'D') {
        setFieldValue('travelDateTo', addDays(values.travelDateFrom, 0))
      } else if (values.travelTypeCountry === 'OUT' && values.travelType === 'Y') {
        setFieldValue('travelDateTo', addYears(addDays(values.travelDateFrom, -1), 1))
      }
    }, // eslint-disable-next-line
    [values.travelDateFrom],
  )

  return (
    <Container onSubmit={handleSubmit}>
      <p className="topic">ประกันเดินทาง</p>
      <FlightSelecter>
        <label
          className={values.travelTypeCountry === 'IN' ? 'active' : ''}
          htmlFor="radio__1"
          onClick={() => {
            setFieldValue('travelType', 'D')
            setFieldValue('travelTypeCountry', 'IN')
            setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D', travelTypeCountry: 'IN' })
          }}
        >
          <Icon
            name="domestic"
            color={
              values.travelTypeCountry === 'IN'
                ? 'var(--agt-primary-color-1)'
                : 'var(--agt-secondary-color-1)'
            }
          />
          <input
            id="radio__1"
            type="radio"
            name="radio"
            checked={values.travelTypeCountry === 'IN'}
          />
          เดินทางในประเทศ
          <span className="checkmark"></span>
        </label>
        <label
          className={values.travelTypeCountry === 'OUT' ? 'active' : ''}
          htmlFor="radio__2"
          onClick={() => {
            setFieldValue('travelType', 'D')
            setFieldValue('travelTypeCountry', 'OUT')
            setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D', travelTypeCountry: 'OUT' })
          }}
        >
          <Icon
            name="international"
            color={
              values.travelTypeCountry === 'OUT'
                ? 'var(--agt-primary-color-1)'
                : 'var(--agt-secondary-color-1)'
            }
          />
          <input
            id="radio__2"
            type="radio"
            name="radio"
            checked={values.travelTypeCountry === 'OUT'}
          />
          เดินทางนอกประเทศ
          <span className="checkmark"></span>
        </label>
      </FlightSelecter>
      {values.travelTypeCountry === 'IN' && (
        <section className="domestic">
          <div className="selector">
            <label>เดินทางในประเทศ</label>
            <Selector
              value={(optionsByTypeTa?.taCountries || [{ label: 'Thailand', value: 999 }]).find(
                (option) => option.value === values.countryId,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('countryId', option.value)
              }}
              options={optionsByTypeTa?.taCountries || []}
              styles={customSelectDisabledStyles}
              placeholder="โปรดระบุ/เลือก"
              isDisabled={true}
            />
          </div>
          <div className="selector">
            <label>
              จำนวนผู้เดินทาง <span>*</span>
            </label>
            <Selector
              value={(fixedOptions?.travelPassenger || []).find(
                (option) => option.value === values.travelPassenger,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('travelPassenger', option.value)
              }}
              options={fixedOptions?.travelPassenger || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="ประเภทการเดินทาง"
            name="vehicleType"
            className="travelType"
            component={TravelType}
          />
          <div className="selector">
            <label>
              จังหวัดต้นทาง <span>*</span>
            </label>
            <Selector
              value={(options?.provinceOptions || []).find((option) => option.value === values.src)}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('src', option.value)
              }}
              options={options?.provinceOptions || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <div className="selector">
            <label>
              จังหวัดปลายทาง <span>*</span>
            </label>
            <Selector
              value={(options?.provinceOptions || []).find((option) => option.value === values.dsc)}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('dsc', option.value)
              }}
              options={options?.provinceOptions || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="วันที่ออกเดินทาง"
            name="travelDateFrom"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            minDate={addDays(new Date(), 1)}
          />
          <Field
            label="วันที่เดินทางกลับ"
            name="travelDateTo"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            minDate={addDays(values.travelDateFrom, 0)}
          />
        </section>
      )}
      {values.travelTypeCountry === 'OUT' && (
        <section className="international">
          <div className="roundType">
            <div
              className={values.travelType === 'D' ? 'item left active' : 'item left'}
              onClick={() => {
                setFieldValue('travelType', 'D')
                setSearchByTypeTa({ ...searchByTypeTa, travelType: 'D' })
              }}
            >
              รายเที่ยว
            </div>
            <div
              className={values.travelType === 'Y' ? 'item right active' : 'item right'}
              onClick={() => {
                setFieldValue('travelType', 'Y')
                setSearchByTypeTa({ ...searchByTypeTa, travelType: 'Y' })
              }}
            >
              รายปี
            </div>
          </div>
          <div className="selector">
            <label>
              ประเทศปลายทาง <span>*</span>
            </label>
            <Selector
              value={
                (optionsByTypeTa?.taCountries || []).find(
                  (option) => option.value === values.countryId,
                ) || ''
              }
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('countryId', option.value)
                setFieldValue('dsc', option.value)
              }}
              options={optionsByTypeTa?.taCountries || []}
              styles={customSelectStyles}
              placeholder="โปรดระบุ/เลือก"
              isDisabled={false}
            />
          </div>
          <div className="selector">
            <label>
              จำนวนผู้เดินทาง <span>*</span>
            </label>
            <Selector
              value={(fixedOptions?.travelPassenger || []).find(
                (option) => option.value === values.travelPassenger,
              )}
              onChange={(option: { label: string; value: string }) => {
                setFieldValue('travelPassenger', option.value)
              }}
              options={fixedOptions?.travelPassenger || []}
              styles={customSelectStyles}
              placeholder="เลือก"
              isDisabled={false}
            />
          </div>
          <Field
            label="วันที่ออกเดินทาง"
            name="travelDateFrom"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            minDate={addDays(new Date(), 1)}
          />
          <Field
            label="วันที่เดินทางกลับ"
            name="travelDateTo"
            className="datepicker"
            component={DatePicker}
            placeholder="โปรดระบุ"
            minDate={addDays(values.travelDateFrom, 0)}
            disabled={values.travelType === 'Y'}
          />
        </section>
      )}
      <button
        type="submit"
        disabled={
          (values.travelTypeCountry === 'IN' && !(values.src && values.dsc)) ||
          (values.travelTypeCountry === 'OUT' && !values.countryId)
        }
      >
        ค้นหาแบบประกัน
      </button>
    </Container>
  )
}

export default TravelForm
