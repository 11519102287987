import React from 'react'
import { currency, dateFormat } from 'utils/helper'

export const commissionColumns = (currentAgent) => {
  if (currentAgent?.agentType === 'affiliate') {
    return [
      {
        Header: 'รหัสโอกาสในการขาย',
        accessor: 'opportunityKey',
        styles: {
          header: {
            width: '200px',
          },
        },
        Cell: (cell) => {
          return <>{cell.row.original?.opportunityKey ? cell.row.original.opportunityKey : '-'}</>
        },
      },
      {
        Header: 'รหัสคำสั่งซื้อ',
        accessor: 'orderNo',
        styles: {
          header: {
            width: '150px',
          },
        },
      },
      {
        Header: 'ปี',
        accessor: 'policyYear',
        styles: {
          header: {
            width: '80px',
          },
        },
        Cell: (cell) => {
          return cell?.value || '-'
        },
      },
      {
        Header: 'งวด',
        accessor: 'installmentNo',
        styles: {
          header: {
            width: '80px',
          },
        },
      },
      {
        Header: 'เบี้ยประกัน',
        accessor: 'premium',
        styles: {
          header: {
            width: '150px',
          },
        },
        Cell: (cell) => {
          return cell?.value ? currency(cell.value, 2, '') : '-'
        },
      },
      // currentAgent?.isLeader
      //   ? {
      //       Header: 'ค่าบริหารทีม',
      //       accessor: 'incentiveAmount',
      //       styles: {
      //         header: {
      //           width: '150px',
      //         },
      //         cell: {
      //           textAlign: 'right',
      //         },
      //       },
      //       Cell: (cell) => {
      //         return currency(cell.value, 2, '')
      //       },
      //     }
      //   : {
      //       Header: '',
      //       accessor: 'incentiveAmount',
      //       Cell: () => {
      //         return null
      //       },
      //     },
      {
        Header: 'ค่าบริหารทีมขาย',
        accessor: 'incentiveAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าคอมมิชชัน',
        accessor: 'commissionAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าบริหารทีมแนะนำ',
        accessor: 'affiliateIncentiveAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าแนะนำ',
        accessor: 'affiliateCommissionAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'วันที่ได้รับเงิน',
        accessor: 'receivedDate',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.receivedDate ? dateFormat(cell.row.original.receivedDate) : '-'}
            </div>
          )
        },
      },
      {
        Header: 'รูปแบบการชำระเงิน',
        accessor: 'paymentType',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      {
        Header: 'วันที่แจ้งการแนะนำ',
        accessor: 'affiliateSubmittedAt',
        styles: {
          header: {
            width: '180px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.affiliateSubmittedAt
                ? dateFormat(cell.row.original.affiliateSubmittedAt)
                : '-'}
            </div>
          )
        },
      },
      {
        Header: 'วันที่แจ้งงานขาย',
        accessor: 'submittedAt',
        styles: {
          header: {
            width: '180px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.submittedAt ? dateFormat(cell.row.original.submittedAt) : '-'}
            </div>
          )
        },
      },
      {
        Header: 'บริษัทประกัน',
        accessor: 'companyName',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
      {
        Header: 'แบบประกัน',
        accessor: 'orderName',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
      {
        Header: 'ชื่อลูกค้า',
        accessor: 'contactName',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      currentAgent?.isLeader
        ? {
            Header: 'รหัสผู้แนะนำ',
            accessor: 'agentCode',
            styles: {
              header: {
                width: '160px',
              },
            },
          }
        : {
            Header: '',
            accessor: 'agentCode',
            Cell: () => {
              return null
            },
          },
      currentAgent?.isLeader
        ? {
            Header: 'ชื่อผู้แนะนำ',
            accessor: 'affiliateName',
            styles: {
              header: {
                width: '200px',
              },
            },
          }
        : {
            Header: '',
            accessor: 'affiliateName',
            Cell: () => {
              return null
            },
          },
      {
        Header: currentAgent?.isLeader ? 'รหัสพนักงานขาย' : 'รหัสพนักงาน',
        accessor: 'staffCode',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: currentAgent?.isLeader ? 'left' : 'center',
          },
        },
      },
      {
        Header: 'ชื่อพนักงานขาย',
        accessor: 'salesmanName',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      {
        Header: 'ทีมขาย',
        accessor: 'salesTeam',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      {
        Header: 'เลขกรมธรรม์',
        accessor: 'policyNo',
        styles: {
          header: {
            width: '200px',
          },
        },
        Cell: (cell) => {
          return cell?.value || '-'
        },
      },
      {
        Header: 'สถานะกรมธรรม์',
        accessor: 'policyState',
        styles: {
          header: {
            width: '170px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
    ]
  } else if (['tqm_staff', 'broker'].includes(currentAgent?.agentType || '')) {
    return [
      {
        Header: 'รหัสโอกาสในการขาย',
        accessor: 'opportunityKey',
        styles: {
          header: {
            width: '200px',
          },
        },
        // Cell: (cell) => {
        //   return cell.value
        // },
        Cell: (cell) => {
          return <>{cell.row.original?.opportunityKey ? cell.row.original.opportunityKey : '-'}</>
        },
      },
      {
        Header: 'รหัสคำสั่งซื้อ',
        accessor: 'orderNo',
        styles: {
          header: {
            width: '150px',
          },
        },
      },
      {
        Header: 'ปีกรมฯ',
        accessor: 'policyYear',
        styles: {
          header: {
            width: '90px',
          },
        },
        Cell: (cell) => {
          return cell?.value || '-'
        },
      },
      {
        Header: 'งวด',
        accessor: 'installmentNo',
        styles: {
          header: {
            width: '80px',
          },
        },
      },
      {
        Header: 'เบี้ยประกัน',
        accessor: 'premium',
        styles: {
          header: {
            width: '150px',
          },
        },
        Cell: (cell) => {
          return cell?.value ? currency(cell.value, 2, '') : '-'
        },
      },
      // currentAgent?.isLeader
      //   ? {
      //       Header: 'ค่าบริหารทีม',
      //       accessor: 'incentiveAmount',
      //       styles: {
      //         header: {
      //           width: '150px',
      //         },
      //         cell: {
      //           textAlign: 'right',
      //         },
      //       },
      //       Cell: (cell) => {
      //         return currency(cell.value, 2, '')
      //       },
      //     }
      //   : {
      //       Header: '',
      //       accessor: 'incentiveAmount',
      //       Cell: () => {
      //         return null
      //       },
      //     },
      {
        Header: 'ค่าบริหารทีมขาย',
        accessor: 'incentiveAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าคอมมิชชัน',
        accessor: 'commissionAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าบริหารทีมแนะนำ',
        accessor: 'affiliateIncentiveAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'ค่าแนะนำ',
        accessor: 'affiliateCommissionAmount',
        styles: {
          header: {
            width: '150px',
          },
          cell: {
            textAlign: 'right',
          },
        },
        Cell: (cell) => {
          if (parseFloat(cell.value) > 0) {
            return <b>{currency(cell.value, 2, '')}</b>
          } else {
            return currency(0, 2, '')
          }
        },
      },
      {
        Header: 'วันที่ได้รับเงิน',
        accessor: 'receivedDate',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.receivedDate ? dateFormat(cell.row.original.receivedDate) : '-'}
            </div>
          )
        },
      },
      {
        Header: 'รูปแบบการชำระเงิน',
        accessor: 'paymentType',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      {
        Header: 'วันที่แจ้งการแนะนำ',
        accessor: 'affiliateSubmittedAt',
        styles: {
          header: {
            width: '180px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.affiliateSubmittedAt
                ? dateFormat(cell.row.original.affiliateSubmittedAt)
                : '-'}
            </div>
          )
        },
      },
      {
        Header: 'วันที่แจ้งงานขาย',
        accessor: 'submittedAt',
        styles: {
          header: {
            width: '180px',
          },
          cell: {
            textAlign: 'center',
          },
        },
        Cell: (cell) => {
          return (
            <div>
              {cell.row.original.submittedAt ? dateFormat(cell.row.original.submittedAt) : '-'}
            </div>
          )
        },
      },
      {
        Header: 'บริษัทประกัน',
        accessor: 'companyName',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
      {
        Header: 'แบบประกัน',
        accessor: 'orderName',
        styles: {
          header: {
            width: '160px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
      {
        Header: 'ชื่อลูกค้า',
        accessor: 'contactName',
        styles: {
          header: {
            width: '200px',
          },
        },
      },
      {
        Header: 'รหัสผู้แนะนำ',
        accessor: 'agentCode',
        styles: {
          header: {
            width: '160px',
          },
        },
        Cell: (cell) => {
          return <>{cell.row.original.agentCode ? cell.row.original.agentCode : '-'}</>
        },
      },
      {
        Header: 'ชื่อผู้แนะนำ',
        accessor: 'affiliateName',
        styles: {
          header: {
            width: '200px',
          },
        },
        Cell: (cell) => {
          return <>{cell.row.original.affiliateName ? cell.row.original.affiliateName : '-'}</>
        },
      },
      {
        Header: 'ทีมผู้แนะนำ',
        accessor: 'affiliateTeam',
        styles: {
          header: {
            width: '200px',
          },
        },
        Cell: (cell) => {
          return <>{cell.row.original.affiliateTeam ? cell.row.original.affiliateTeam : '-'}</>
        },
      },
      currentAgent?.isLeader
        ? {
            Header: 'รหัสพนักงานขาย',
            accessor: 'staffCode',
            styles: {
              header: {
                width: '160px',
              },
            },
          }
        : {
            Header: '',
            accessor: 'staffCode',
            Cell: () => {
              return null
            },
          },
      currentAgent?.isLeader
        ? {
            Header: 'ชื่อพนักงานขาย',
            accessor: 'salesmanName',
            styles: {
              header: {
                width: '200px',
              },
            },
          }
        : {
            Header: '',
            accessor: 'salesmanName',
            Cell: () => {
              return null
            },
          },
      {
        Header: 'เลขกรมธรรม์',
        accessor: 'policyNo',
        styles: {
          header: {
            width: '200px',
          },
        },
        Cell: (cell) => {
          return cell?.value || '-'
        },
      },
      {
        Header: 'สถานะกรมธรรม์',
        accessor: 'policyState',
        styles: {
          header: {
            width: '170px',
          },
          cell: {
            textAlign: 'center',
          },
        },
      },
    ]
  }
}
