import styled from '@emotion/styled'
import { Form } from 'formik'
import Select from 'react-select'
import Icon from 'components/Icon'
import Gender from 'components/Inputs/Gender'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

export const FormValue = styled(Form)`
  /* display: flex;
  flex-direction: column; */
`

export const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const CreateNormalForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  margin-top: 16px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  margin-bottom: 24px;
  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .Row .Group {
    display: flex;
    flex-direction: column;
  }
  .Row .Group .Topic {
    color: var(--agt-primary-text-color);
    margin-bottom: 12px;
  }
  .Row .Group .Topic span {
    color: var(--agt-error-color);
  }
  .Row .Group .Topic.disabled {
    color: var(--agt-disable-color-1);
  }
  .normal-box {
    width: 356px;
    height: 48px;
  }
  .medium-box {
    width: 398px;
    height: 48px;
  }
  .extra-box {
    width: 736px;
    padding: 16px;
    border: 1px solid var(--agt-secondary-color-1);
    height: 96px !important;
    resize: none;
  }
  .normal-box.fix,
  .medium-box.fix {
    border: 1px solid var(--agt-disable-color-1);
    background: var(--agt-background-color-1);
    ::placeholder {
      color: var(--agt-disable-color-1);
    }
  }
  .normal-box.disabled,
  .medium-box.disabled {
    border: 1px solid var(--agt-disable-color-1);
    background: var(--agt-background-color-1);
    ::placeholder {
      color: var(--agt-disable-color-1);
    }
  }
  .normal-box.errors,
  .medium-box.errors {
    border: 1px solid var(--agt-error-color);
  }
  .MoreDetailLayer {
    border-bottom: 1px solid #d2d4db;
  }
  .MoreDetailLayer .Topic {
    font-weight: bold;
    color: var(--agt-primary-text-color);
    margin-bottom: 8px !important;
  }
  .MoreDetailLayer .Normal {
    width: 356px;
  }
  .MoreDetailLayer .Large {
    width: 736px;
  }
  .ImageButton {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export const NewCustomForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    column-gap: 24px;
  }
  .Row .Group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 356px;
  }
  .Row .Group b,
  .Row .Group p {
    color: var(--agt-primary-text-color);
    margin-bottom: 12px;
  }
  .Row .Group b span,
  .Row .Group p span {
    color: var(--agt-error-color);
  }
  .Row .remark {
    max-width: 756px;
  }
  .ownerName,
  .ownerAge {
  }
  .ownerName::placeholder,
  .ownerAge::placeholder {
    color: var(--agt-disable-color-1);
  }
  .errors {
    border: 1px solid var(--agt-error-color);
  }
  .DriverDetail {
    border-top: 1px solid var(--agt-secondary-color-1);
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding: 40px 0;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
  }
  .DriverDetail .Driver {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .DriverDetail .Driver .Delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: var(--agt-white-color);
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .DriverDetail .Driver .Delete svg path {
    fill: var(--agt-primary-color-1);
  }
  .DriverDetail .Driver .Delete:hover svg path {
    fill: var(--agt-dark-sky-color);
  }
  .DriverDetail .Driver > b {
    margin-bottom: 12px;
  }
  .DriverDetail .Button {
    align-self: flex-end;
  }
  .LiabilityInsurance {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  }
  .LiabilityInsurance > b {
    margin-bottom: 12px;
  }
  .Protection {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .Protection > b {
    margin-bottom: 16px;
  }
  .Protection .Accident,
  .Protection .MedicalFee {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
  .Protection .Accident .Permanent > p,
  .Protection .Accident .Temporarily > p {
    margin-bottom: 8px;
  }
  /* .Protection > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }
  .Protection > div > b {
    margin-bottom: 16px;
  } */
  .Protection .Row {
    display: flex;
    gap: 24px;
  }
  .Protection .Row .Group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 356px;
  }
  .Protection .Row .cc,
  .Protection .Row .ff {
    max-width: 200px;
  }
  .Protection .Row .cc p + div,
  .Protection .Row .ff p + div {
    width: 100%;
  }
  .Protection .Row .cc p + div > div,
  .Protection .Row .ff p + div > div {
    width: 100%;
    height: 100%;
  }
  .Protection .DriverInsurance {
    display: flex;
    flex-direction: column;
  }
  .Protection .DriverInsurance .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
    column-gap: 24px;
  }
  .Protection .DriverInsurance .Row .Group {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 356px;
  }
  .Protection .DriverInsurance button {
    width: 100%;
    align-self: flex-end;
  }
  .Protection .AddProtection {
    display: flex;
    flex-direction: column;
  }

  .Protection .NewProtection {
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .Protection .NewProtection .Row .Unit {
    max-width: 200px;
  }
  .Protection .NewProtection .Row .Unit p + div {
    width: 100%;
  }
  .Protection .NewProtection .Row .Unit p + div > div {
    width: 100%;
    height: 100%;
  }
  .Protection .NewProtection .Row .Name {
    position: relative;
  }
  .Protection .NewProtection .Row .Name .Fix {
    padding-right: 64px;
  }
  .Protection .NewProtection .Row .Name .FaTime {
    position: absolute;
    width: 48px;
    height: 38px;
    right: 5px;
    top: 43px;
    background: var(--agt-background-color-3);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Protection .NewProtection .Row .Name .FaTime:hover {
    cursor: pointer;
  }
  .Protection .NewProtection .AddBtn {
    align-self: flex-end;
    width: 356px;
    height: 48px;
  }
  .Protection .NewProtection .NonPlus {
    position: absolute;
    top: -72px;
    right: 0;
  }
  .ImageButton {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export const IconStyle = styled(Icon)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

export const PencilIcon = styled(IconStyle)`
  position: absolute;
  bottom: 20px;
  right: 75px;
`

export const TrashIcon = styled(IconStyle)`
  position: absolute;
  bottom: 20px;
  right: 35px;
`

export const CreateAddForm = styled.div`
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  .Header {
    color: var(--agt-primary-text-color);
    font-weight: bold;
    margin-bottom: 24px;
  }
  .Body {
    width: 100%;
    height: 160px;
    padding: 16px 28px;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 4px;
    margin-bottom: 24px;
    position: relative;
    .Text {
      margin-bottom: 8px;
    }
    .Text.Topic {
      font-weight: bold;
    }
  }
  .Body.Act {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: fit-content;
  }
  .Body.Act .Row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Body.Act .Row p {
    width: 40%;
  }
  .Body.Act .Row.Icon {
    justify-content: flex-end;
    gap: 16px;
  }
`

export const ButtonComponents = styled.div`
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const GroupButtons = styled.div`
  width: 100%;
  max-width: 756px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 24px;
`

export const Error = styled.div`
  font-size: 13px;
  color: var(--agt-error-color);
`

export const AddImage = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 36px;
  .ContainerBefore {
    width: 173px;
    height: 48px;
    background: var(--agt-background-color-1);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    color: var(--agt-secondary-text-color);
    padding-left: 9px;
  }
  .ContainerBefore .Right {
    align-self: center;
  }
  .ContainerBefore .Left {
    height: 100%;
    margin-right: 5px;
    margin-top: 7px;
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .ContainerAfter {
    width: 173px;
    height: 48px;
    background: #ffffff;
    border: 1px solid var(--agt-success-color);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 14px;
    padding-left: 2px;
  }
  .ContainerAfter .LeftColumn {
    width: 58px;
    height: 42px;
    background: var(--agt-success-color);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .ContainerAfter .LeftColumn .Font {
    font-size: 13px;
    color: #ffffff;
  }
  .ContainerAfter .MiddleColumn {
    width: 57px;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ContainerAfter .RightColumn {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  button {
    width: 137px;
    margin-right: 14px;
    margin-left: 14px;
  }
`

export const StyleGender = styled(Gender)`
  width: 356px;
  height: 48px;
`

export const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .Container {
    height: auto;
    width: 840px;
  }
  .Container .Header {
    margin: 24px 36px;
  }
  .Container .Body {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-left: 52px;
    padding-right: 52px;
    padding-top: 36px;
    padding-bottom: 24px;
  }
  .Container .Body .Row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .Container .Body .Row .LeftColumn {
    display: flex;
    flex-direction: column;
    width: 356px;
  }
  .Container .Body .Row .RightColumn {
    display: flex;
    flex-direction: column;
    width: 356px;
  }
  .Container .Body .Row .Topic {
    color: var(--agt-primary-text-color);
    margin-bottom: 4px;
  }
  .Container .Body .Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-bottom: 24px; */
  }
  .Warning,
  .Deleted {
    padding: 48px 98.5px;
  }
`

export const SelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    // width: '354px',
    width: '100%',
    outline: 'none',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '46px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    // width: '356px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
}

export const SelectErrorsStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    // width: '354px',
    width: '100%',
    outline: 'none',
    border: '1px solid var(--agt-error-color)',
    height: '46px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    //  width: '356px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
}

export const SelectDisabledStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '356px',
    outline: 'none',
    border: '1px solid var(--agt-disable-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '356px',
    border: '1px solid var(--agt-disable-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  placeholder: () => ({
    color: 'var(--agt-disable-color-1)',
  }),
}

export const ErrorMassageStyle = styled.div`
  display: flex;
  align-self: right;
  font-size: 13px;
  color: var(--agt-error-color);
  width: 100%;
`

export const DatePickerField = styled(DatePicker)`
  border-radius: 4px;
  height: 48px;
  width: 356px;
`
