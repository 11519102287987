import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { ReactSVG } from 'react-svg'

export type IconProps = {
  name: string
  color?: string
  className?: string
  icon?: string
}
const Layout = styled.span`
  display: inline-block;
  width: fit-content;
  path {
    ${({ color }) => color && `fill: ${color} `}
  }
`

export default (props: IconProps) => {
  const { color, name, icon, className = '', ...restProps } = props

  const svg = useMemo(() => {
    if (name) {
      return require(`assets/icons/${name}.svg`)
    } else {
      return undefined
    }
  }, [name])
  return (
    <Layout className={`app-icon ${className}`} color={color} {...restProps}>
      {svg && <ReactSVG src={svg} />}
    </Layout>
  )
}
