import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { RootState } from 'store/rootReducer'
import { clearAnnouncements, getAnnouncements } from 'store/announcementsSlice'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.css'

import authorize, { UnauthorizedAction } from 'utils/authorize'
import Banner from 'assets/images/news_banner_landing.jpg'

type LandingNewsCardType = {
  disabled?: boolean
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: hidden;
  border: var(--agt-background-color-1) solid 1px;
  border-radius: 10px;
  .notice {
    padding: 10px;
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    font-weight: bold;
  }
`
const PinMask = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 160px;
  background-color: rgba(0, 0, 0, 0);
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
`
const PinTitle = styled.div`
  color: var(--agt-white-color);
  font-weight: 700;
  padding: 17px 20px;
`
const PinImage = styled.img`
  width: 100%;
  height: 250px;
  margin-bottom: -8px;
  object-fit: cover;
`
const SwiperCustom = styled(Swiper)`
  .swiper-pagination-bullets {
    bottom: 26px;
    right: 17px;
    left: unset;
    display: flex;
    justify-content: flex-end;

    .swiper-pagination-bullet {
      border: var(--agt-white-color) solid 1px;
      background-color: transparent;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--agt-white-color);
    }
  }
`
const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 250px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(2px);
`
const ComingSoonImage = styled.img`
  width: 100%;
  height: 250px;
  margin-bottom: -8px;
  object-fit: cover;
  filter: grayscale(100%);
`
const ComingSoon = styled.div`
  margin-bottom: 16px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  line-height: 33px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 40px;
  padding: 0 22px;
  line-height: 40px;
  background-color: var(--agt-tqm-pantone-1-20);
  color: var(--agt-primary-color-1);
  font-weight: bold;
`
const AnnouncementLists = styled.ul`
  li {
    display: flex;
    justify-content: space-between;
    padding: 7px 31px 7px 27px;
    border-bottom: var(--agt-background-color-1) solid 1px;
    a {
      color: var(--agt-primary-text-color);
      text-decoration: none;
      text-align: right;
    }
  }
`
const ViewAll = styled.a`
  display: block;
  color: var(--agt-primary-color-1);
  text-align: right;
  background-color: var(--agt-background-color-1);
  padding: 7px 31px 7px 27px;
`

const LandingNewsCard: FC<LandingNewsCardType> = (props) => {
  const { disabled } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const announcements = useSelector((state: RootState) => state.announcements.announcements)
  const announcementPins = announcements.filter((announcement) => announcement.pin !== null)

  useEffect(() => {
    dispatch(getAnnouncements())
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(clearAnnouncements())
    }
  }, [dispatch])

  return (
    <Container className="agt-landing-news-card__container">
      {disabled ? (
        <>
          <ComingSoonImage alt="news" src={Banner} />
          <Mask>
            <ComingSoon>
              ข่าวสาร/ประกาศ
              <br />
              จาก TQM
            </ComingSoon>
            <div className="notice">เปิดใช้งานสำหรับตัวแทน</div>
          </Mask>
        </>
      ) : (
        <>
          {announcementPins.length > 0 ? (
            <SwiperCustom
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              loop={true}
            >
              {announcementPins.map((pin) => (
                <SwiperSlide onClick={() => history.push(`announcements/${pin.id}`)}>
                  <PinImage alt="news" src={pin.bannerImagePath} />
                  <PinMask>
                    <PinTitle>{pin.name}</PinTitle>
                  </PinMask>
                </SwiperSlide>
              ))}
            </SwiperCustom>
          ) : (
            <>
              <ComingSoonImage alt="news" src={Banner} />
              <Mask>
                <ComingSoon>
                  ข่าวสาร/ประกาศ
                  <br />
                  จาก TQM
                </ComingSoon>
              </Mask>
            </>
          )}
        </>
      )}
      <Header>ข่าวสาร/ประกาศ</Header>
      {disabled ? null : (
        <>
          <AnnouncementLists>
            {announcements
              .filter((announcement) => announcement.pin === null)
              .map((announcement, index) => {
                if (index < 5) {
                  return (
                    <li>
                      {`${announcement.name}`}
                      <a href={`/announcements/${announcement.id}`}>อ่าน</a>
                    </li>
                  )
                }
                return null
              })}
          </AnnouncementLists>
          <ViewAll href="/announcements">ดูทั้งหมด</ViewAll>
        </>
      )}
    </Container>
  )
}

export default authorize(LandingNewsCard, {
  roles: ['tqm_staff', 'affiliate', 'broker'],
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
})
