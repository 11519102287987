import styled from '@emotion/styled'
import { TabProps } from './InterestedEdit.type'

export const Container = styled.div`
  width: 100%;
  .Loading {
    text-align: center;
  }
`

export const Form = styled.form`
  width: 100%;
  max-width: 736px;
  display: flex;
  flex-direction: column;
  margin: 40px auto;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
`

export const TabsContainer = styled.div`
  width: 100%;
`

export const TabsSelector = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 64px;
  margin: 8px 0;
  background-color: var(--agt-white-color);
`

export const Tab = styled.button<TabProps>`
  width: 100%;
  height: 100%;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-text-color)'};
  background-color: ${(props) => (props.active ? 'var(--agt-background-color-3)' : 'transparent')};
  :hover {
    background-color: ${(props) =>
      props.active ? 'var(--agt-background-color-3)' : 'transparent'};
  }
`

export const CheckboxGroup = styled.div`
  padding: 16px;
  background: var(--agt-background-color-1);
  .checkbox-lists {
    width: 700px;
  }
`

export const GroupTopic = styled.div`
  margin: 5px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid var(--agt-secondary-color-1);
`

export const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-start;
  .list {
    background: var(--agt-white-color);
    display: flex;
    flex-direction: row;
    width: calc(25% - 10px);
    height: 48px;
    margin: 5px;
    border-radius: 4px;
    border: 1px solid var(--agt-secondary-color-1);
    input[type='checkbox'] {
      border: 1px solid var(--agt-secondary-text-color);
      width: 16px;
      height: 16px;
      margin: 0px;
      margin-left: 16px;
      align-self: center;
      cursor: pointer;
    }
    &:hover {
      cursor: pointer;
    }
  }
  p {
    margin-left: 16px;
    align-self: center;
  }
`

export const FieldGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-right: 16px;
  margin-bottom: 24px;
  &.extra {
    width: 100%;
  }
  .details {
    width: 690px;
    height: 96px !important;
    margin: 19px 5px 1px 5px;
    padding: 16px;
    resize: none;
  }
  .specialField {
    width: 100%;
  }
`

export const SubmitButton = styled.button`
  align-self: flex-end;
  margin-top: 24px;
`
