import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useParams, useLocation, Link } from 'routes'
import PersonalInformation from './PersonalInformation'
import FinancialInformation from './FinancialInformation'
import InsurancePlanAndSuccess from '../insurancePlanAndSuccess/index'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'
import { getPersonalInformation } from 'store/planningSlice'

type useParamsType = { id: string }
type TabType = {
  connect: boolean
}
type CheckPlaningType = {
  educationInflationRat?: string
  employeeSavingRate?: string
  expectAge?: number
  expensePerMonth?: string
  hasDebtProtections?: boolean
  hasHealthcareProtections?: boolean
  hasIncomeProtections?: boolean
  hasNonLifeProtections?: boolean
  healthcareInflationRate?: string
  id?: number
  incomeGrowthRate?: string
  incomePerMonth?: string
  inflationRate?: string
  investmentRisk?: string
  isEmployee?: boolean
  retireAge?: number
  socialInsuranceInsuredType?: string
  socialInsuranceNumberOfMonthContribution?: number
  taxRate?: string
}

const Container = styled.div`
  align-items: center;
  width: 100%;
`
const BodyContariner = styled.div`
  display: flex;
  height: 100%;
`
const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #a0a8bc;
  height: 100%;
  width: 256px;
  padding-left: 9px;
  padding-right: 7px;
  padding-top: 24px;
`
const Tab = styled.div<TabType>`
  position: relative;
  width: 240px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  .sequence {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: var(--agt-primary-color-1);
    display: flex;
    justify-content: center;
    color: var(--agt-white-color);
    margin-right: 15px;
    z-index: 1;
  }
  .label {
    font-weight: bold;
  }
  .line {
    position: ${(props) => (props.connect ? 'absolute;' : '')};
    top: ${(props) => (props.connect ? ' 24px;' : '')};
    left: ${(props) => (props.connect ? ' 11px;' : '')};
    border-left: ${(props) => (props.connect ? ' 2px solid var(--agt-primary-color-1);' : '')};
    height: ${(props) => (props.connect ? ' 48px;' : '')};
  }
`
const NonTab = styled.div`
  width: 240px;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: no-drop;
  .sequence {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    color: var(--agt-white-color);
    background: var(--agt-secondary-text-color);
    display: flex;
    justify-content: center;
    margin-right: 15px;
  }
  .label {
    font-weight: bold;
    color: var(--agt-secondary-text-color);
  }
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
function CheckPlanning(object: CheckPlaningType) {
  let step: number = 1
  for (var plan in object) {
    if (plan === 'retireAge') {
      if (object[plan] !== null) {
        step = 2
      } else {
        break
      }
    } else if (plan === 'incomePerMonth') {
      if (object[plan] !== null) {
        step = 3
      } else {
        break
      }
    }
  }
  return step
}

const Pages: FC = (props) => {
  const dispatch = useDispatch()
  let { id } = useParams<useParamsType>()
  const { state } = useLocation()
  const [step, setStep] = useState(state)
  const plan = useSelector((state: RootState) => state.plans.plan)
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    { label: 'วางแผนประกัน' },
  ]
  const path = `/contacts/${id}/planning`

  useEffect(() => {
    if (state) {
      setStep(state)
    } else {
      setStep('personal_info')
    }
  }, [state])

  useEffect(() => {
    dispatch(getPersonalInformation(id))
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Container>
      <Header>วางแผนประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {CheckPlanning(plan) === 1 && (
        <BodyContariner>
          <TabContainer>
            <Link to={{ pathname: path, state: 'personal_info' }} className="ghost">
              <Tab connect={false}>
                <div className="sequence">1</div>
                <div className="line"></div>
                <div className="label">ข้อมูลส่วนตัว</div>
              </Tab>
            </Link>
            <NonTab>
              <div className="sequence">2</div>
              <div className="label">ข้อมูลทางการเงิน</div>
            </NonTab>
            <NonTab>
              <div className="sequence">3</div>
              <div className="label">วางแผนประกัน</div>
            </NonTab>
          </TabContainer>
          {step === 'personal_info' && <PersonalInformation />}
        </BodyContariner>
      )}
      {CheckPlanning(plan) === 2 && (
        <BodyContariner>
          <TabContainer>
            <Link to={{ pathname: path, state: 'personal_info' }} className="ghost">
              <Tab connect={true}>
                <div className="sequence">1</div>
                <div className="line"></div>
                <div className="label">ข้อมูลส่วนตัว</div>
              </Tab>
            </Link>
            <Link to={{ pathname: path, state: 'financial_info' }} className="ghost">
              <Tab connect={false}>
                <div className="sequence">2</div>
                <div className="line"></div>
                <div className="label">ข้อมูลทางการเงิน</div>
              </Tab>
            </Link>
            <NonTab>
              <div className="sequence">3</div>
              <div className="label">วางแผนประกัน</div>
            </NonTab>
          </TabContainer>
          {step === 'personal_info' && <PersonalInformation />}
          {step === 'financial_info' && <FinancialInformation />}
        </BodyContariner>
      )}
      {CheckPlanning(plan) === 3 && (
        <BodyContariner>
          <TabContainer>
            <Link to={{ pathname: path, state: 'personal_info' }} className="ghost">
              <Tab connect={true}>
                <div className="sequence">1</div>
                <div className="line"></div>
                <div className="label">ข้อมูลส่วนตัว</div>
              </Tab>
            </Link>
            <Link to={{ pathname: path, state: 'financial_info' }} className="ghost">
              <Tab connect={true}>
                <div className="sequence">2</div>
                <div className="line"></div>
                <div className="label">ข้อมูลทางการเงิน</div>
              </Tab>
            </Link>
            <Link to={{ pathname: path, state: 'planning' }} className="ghost">
              <Tab connect={false}>
                <div className="sequence">3</div>
                <div className="line"></div>
                <div className="label">วางแผนประกัน</div>
              </Tab>
            </Link>
          </TabContainer>
          {step === 'personal_info' && <PersonalInformation />}
          {step === 'financial_info' && <FinancialInformation />}
          {step === 'planning' && <InsurancePlanAndSuccess />}
        </BodyContariner>
      )}
    </Container>
  )
}

export default Pages
