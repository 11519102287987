import styled from '@emotion/styled'
import moment from 'moment'
import 'moment/locale/th'
import { NotificationType } from 'types/NotificationType'

export const MainContainer = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background: var(--agt-background-color-3);
  border-radius: 50%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 7px;
`

export const NotificationIconContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const NotificationRedDot = styled.div`
  position: absolute;
  top: 7px;
  left: 18px;
  width: 12px;
  height: 12px;
  background: var(--agt-error-color);
  border: 2px solid var(--agt-background-color-3);
  border-radius: 50%;
`

export const NotificationListContainerArrowTop = styled.div`
  position: absolute;
  background: #ffffff;
  border-radius: 4px 0px 0px 0px;
  transform: rotate(45deg);
  width: 16.97px;
  height: 16.97px;
  top: 41px;
  right: 9px;
  z-index: 6;
  border: 1px solid var(--agt-secondary-color-1);
  border-bottom: 0px;
  border-right: 0px;
`

export const NotificationListContainer = styled.div`
  position: absolute;
  top: 49px;
  right: -25px;
  min-width: 346px;
  width: auto;
  height: fit-content;
  background: #ffffff;
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  padding: 24.5px 0px;
  z-index: 5;
  max-height: 619px;
  overflow-y: auto;
`

export const AlertMessageContainer = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 27px;
  margin-bottom: 9.5px;
`

export const DateMessageContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-right: 27px;
`

export const AlertMessage = styled.div`
  color: var(--agt-secondary-color-2);
  font-size: 17px;
`

export const NotificationListMainContainer = styled.div``

export const SeeAllContainer = styled.div`
  margin-top: 8.5px;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const SeeAllText = styled.div`
  text-decoration-line: underline;
  color: var(--agt-primary-color-1);
  cursor: pointer;
`
export const DontHaveNotiText = styled.div`
  text-align: center;
  color: var(--agt-secondary-color-2);
  margin-top: 24.5px;
  justify-content: center;
`
