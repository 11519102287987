import styled from '@emotion/styled'
import React from 'react'
const ImgStyle = styled.div<ImgProps>`
  width: ${(props) => (props.width ? props.width : '48px')};
  height: ${(props) => (props.height ? props.height : '48px')};
  background: ${(props) =>
    props.avatar ? 'none' : props.background ? props.background : '#e6e8ec'};
  border-radius: ${(props) => (props.type === 'circle' ? '50%' : '0px')};
  img {
    width: ${(props) => (props.width ? props.width : '48px')};
    height: ${(props) => (props.height ? props.height : '48px')};
    background: ${(props) =>
      props.avatar !== null ? '#ffffff' : props.background ? props.background : '#e6e8ec'};
    border-radius: ${(props) => (props.type === 'circle' ? '50%' : '0px')};
    /* min-height: 48px;
    min-width: 48px; */
    object-fit: cover;
  }
`

type ImgProps = {
  className?: string
  width?: string
  height?: string
  background?: string
  type?: 'circle' | 'square'
  avatar?: string
  alt?: string
}

const Avatar = (props: ImgProps) => {
  const { className = '', width, height, background, type, alt } = props
  return (
    <ImgStyle
      className={className}
      width={width}
      height={height}
      background={background}
      type={type}
    >
      {props.avatar && <img src={props.avatar} alt={alt || 'avatar'} />}
    </ImgStyle>
  )
}

export default Avatar
