import { useState, useEffect } from 'react'

interface Size {
  width: number | undefined
  height: number | undefined
  orientation: 'landscape' | 'portrait' | undefined
}

function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    orientation: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        ...windowSize,
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()

    function handleOrientationchange() {
      if (window.orientation) {
        switch (window.orientation) {
          case -90:
          case 90:
            setWindowSize({
              ...windowSize,
              orientation: 'landscape',
            })
            break
          default:
            setWindowSize({
              ...windowSize,
              orientation: 'portrait',
            })
            break
        }
      }
    }
    window.addEventListener('orientationchange', handleOrientationchange)
    handleOrientationchange()

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('orientationchange', handleOrientationchange)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return windowSize
}

export default useWindowSize
