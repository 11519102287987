import styled from '@emotion/styled'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import Select from 'react-select'
import Icon from 'components/Icon'

import { SpanProps, OptionsButtonProps } from './index.type'

export const Container = styled.div`
  width: 100%;
`

export const TasksContainer = styled.div`
  padding: 0 95px;
`

export const Selector = styled(Select)`
  border-radius: 4px;
  width: 240px;
  .css-yk16xz-control {
    height: 48px;
  }
`

export const MenuCustom = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0px;
  .status {
    display: flex;
    align-items: center;
  }
  .status .option {
  }
  .seletor {
  }
`

export const Span = styled.span<SpanProps>`
  margin-right: ${(props) => props.margin};
`

export const OptionsButton = styled.button<OptionsButtonProps>`
  width: 176px;
  background: ${(props) => (props.active ? '#CAE6FF' : '#ffffff')};
  border: ${(props) =>
    props.active
      ? '1px solid var(--agt-primary-color-1)'
      : '1px solid var(--agt-secondary-color-1)'};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
  border-radius: ${(props) =>
    props.radius === 'around'
      ? '4px'
      : props.radius === 'left'
      ? ' 4px 0px 0px 4px '
      : '0px 4px 4px 0px'};
  :hover {
    background: ${(props) => (props.active ? '#CAE6FF' : '#ffffff')};
    border: ${(props) =>
      props.active
        ? '1px solid var(--agt-primary-color-1)'
        : '1px solid var(--agt-secondary-color-1)'};
    color: ${(props) =>
      props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
    border-radius: ${(props) =>
      props.radius === 'around'
        ? '4px'
        : props.radius === 'left'
        ? ' 4px 0px 0px 4px '
        : '0px 4px 4px 0px'};
  }
`

export const FilterContainer = styled.div`
  padding: 24px 96px 24px 32px;
  margin-bottom: 24px;
  background: #eff1f5;
`

export const GroupFilter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Label = styled.div`
  width: 160px;
`

export const Filter = styled.div`
  display: flex;
  align-items: center;
`

export const ResetValue = styled.p`
  cursor: pointer;
  color: var(--agt-primary-color-1);
  text-decoration: underline;
`

export const TopCollapse = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  padding: 0 16px;

  background-color: #eff1f5;
  border-bottom: 1px solid #ffffff;

  cursor: pointer;
`

export const IconStyle = styled(Icon)`
  width: 11px;
  height: 11px;
  margin-bottom: 16px;
`

export const Header = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  padding: 32px 46px;
  background: var(--agt-background-color-4);
  color: var(--agt-primary-color-1);
  h3 {
    color: var(--agt-primary-color-1);
  }
  .hint {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    font-size: 17px;
    color: var(--agt-primary-color-1);
  }
`

export const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

export const FieldValue = styled.input`
  width: 352px;
  @media only screen and (max-width: 1024px) {
    width: 300px;
  }
`

export const DatePickerStyle = styled(DatePicker)`
  width: 352px;
  @media only screen and (max-width: 1024px) {
    width: 300px;
  }
`
