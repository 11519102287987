import React, { FC } from 'react'
import styled from '@emotion/styled'

import { currency } from 'utils/helper'
import { DashboardCompensationCardType } from 'types/DashboardType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100px;
  padding: 14px;
  border-radius: 8px;
  color: var(--agt-white-color);
  background: var(--agt-dark-sky-color);
`
const Info = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`
const FloatingLabel = styled.small`
  position: absolute;
  top: -10px;
`
const Currency = styled.h3`
  line-height: 1;
`

const DashboardCompensationCard: FC<DashboardCompensationCardType> = ({ topic, month, amount }) => {
  return (
    <Container className="agt-dashboard-compensation-card__container">
      <b>{topic}</b>
      <Info>
        <FloatingLabel>ของเดือน</FloatingLabel>
        <label>{month}</label>
        <Currency>{currency(amount, 0, '') || '-'} ฿</Currency>
      </Info>
    </Container>
  )
}

export default DashboardCompensationCard
