import styled from '@emotion/styled'
import Icon from 'components/Icon'
import ButtonLink from 'components/ButtonLink'
import { Link } from 'routes'
import { DetailType, DetailRowType } from './Tasks.type'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 288px;
  max-width: 288px;
  background: var(--agt-background-color-2);
`

export const Header = styled.div`
  width: 100%;
  height: 76px;
  background: var(--agt-tqm-pantone-1);
  display: flex;
  flex-direction: row;
  .label {
    width: 73.6%;
    color: white;
    text-align: center;
    height: 100%;
    border-right: 2px solid #003d73;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .icon {
    width: 26.4%;
    height: 100%;
    padding-top: 20px;
    padding-left: 18px;
  }
`

export const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const TodoContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`

export const NotingText = styled.div`
  width: 100%;
  margin-top: 40px;
  text-align: center;
  color: var(--agt-secondary-text-color);
`

export const IconStyle = styled(Icon)`
  width: 17px;
  height: 17px;
  margin-bottom: 10px;
`

export const CustomBtnLink = styled(ButtonLink)`
  button {
    width: fit-content;
    padding: 0px 40px;
    margin-bottom: 40px;
  }
  @media (max-width: 1024px) {
    button {
      font-size: 13px;
    }
  }
`

export const TaskContainer = styled.div`
  border-bottom: 1px solid white;
`

export const TaskHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  background-color: var(--agt-background-color-1);
  cursor: pointer;
`

export const TaskHeaderHist = styled.div`
  width: 100%;
  height: 48px;
  padding: 11px 16px;
  font-weight: bold;
  color: var(--agt-primary-color-1);
  background-color: var(--agt-white-color);
`

export const Detail = styled.ul<DetailType>`
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: white;
  > li:last-of-type {
    border-bottom: none;
  }
  height: ${(props) => (props.isMore && props.isHistory ? '750px' : props.isMore ? '500px' : '')};
  overflow-y: ${(props) => (props.isMore ? 'scroll' : '')};
`

export const DetailRow = styled.li<DetailRowType>`
  display: flex;
  flex-direction: row;
  padding: ${(props) => (props.isHistory ? '12px' : '16px')};
  padding-left: ${(props) => (props.isHistory ? '16px' : '')};
  border-bottom: ${(props) => (props.isHistory ? '' : '1px solid var(--agt-background-color-2)')};
  position: relative;
  height: 125px;
`

export const TopicContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .name {
    width: 208px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const TopicDate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .overdue {
    font-size: 13px;
  }
`

export const Clock = styled(Icon)`
  width: 17px;
  height: 17px;
  margin-right: 5px;
  margin-bottom: 5px;
  svg {
    width: 14px;
    height: 14px;
  }
`

export const StarContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 85px;
  max-width: 85px;
`

export const CheckboxHistory = styled.div`
  margin-right: 15px;
  z-index: 1;
`

export const VerticalLine = styled.div`
  position: absolute;
  top: 26px;
  left: 26px;
  border-left: 2px dashed var(--agt-secondary-color-2);
  height: 100%;
`

export const CheckboxContainer = styled.div`
  margin-right: 15px;
  .disabledCheckbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--agt-secondary-color-1);
    background-color: var(--agt-disable-color-1);
    margin-top: 3px;
    cursor: not-allowed;
  }
`

export const CheckboxWrapper = styled.div`
  padding-top: 6px;
  padding-left: 6px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  :hover {
    background: var(--agt-background-color-1);
  }
  .round {
    position: relative;
  }
  .round label {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
  }
  .round label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 4px;
    width: 9px;
    height: 4px;
    opacity: 0;
    border: 2px solid var(--agt-primary-text-color);
    border-top: none;
    border-right: none;
    transform: rotate(-45deg);
  }
  .round input[type='checkbox'] {
    visibility: hidden;
  }
  .round input[type='checkbox']:checked + label {
    background-color: white;
    border-color: var(--agt-primary-text-color);
  }
  .round input[type='checkbox']:checked + label:after {
    opacity: 1;
  }
`

export const LinkTab = styled(Link)`
  text-decoration: none;
`

export const Description = styled.div`
  width: 208px;
  min-height: 25.6px;
  font-size: 13px;
  color: var(--agt-secondary-text-color);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
`
