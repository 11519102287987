import React, { FC, useState, Fragment, useEffect } from 'react'
import { Field } from 'formik'
import Modal from 'react-modal'
import Banner from '../../../assets/images/agent_portal.png'
import { ModalCustomStyle } from 'components/Modal'
import { ConsentType } from './Consent.type'
import { CustomModal, Header, Image, Content, CheckedBox } from './Consent.styles'

const Consent: FC<ConsentType> = (props) => {
  const { consent, type, values, isChecked, setIsChecked } = props
  const [activated, setActivated] = useState<string>('')
  const [isRead, setIsRead] = useState<boolean>(false)

  useEffect(() => {
    if (consent && consent.consents && values) {
      let checkedArrays = true
      consent.consents.forEach((array, index) => {
        if (values?.consents?.[index]) {
          if (array.forcedConsent && !values.consents[index]?.value) {
            checkedArrays = false
            return null
          }
        } else if (array.forcedConsent) {
          checkedArrays = false
          return null
        }
      })
      setIsChecked(checkedArrays)
    }
  }, [setIsChecked, consent, values])

  return (
    <Fragment>
      <Modal isOpen={!!activated} style={ModalCustomStyle}>
        <CustomModal>
          <Header>
            <Image alt="agentProtal" src={Banner} />
          </Header>
          <Content>
            <div className="name">
              {consent?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
            </div>
            <p
              className="consents"
              dangerouslySetInnerHTML={{
                __html: consent?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
              }}
            />
            <ul className="">
              {(consent?.consents || []).map((consent, index: number) => {
                return (
                  <li className="">
                    <Field name={`${type}.consents.${index}.value`} type="checkbox" />
                    <label>{consent.name}</label>
                  </li>
                )
              })}
            </ul>
            <button
              type="button"
              className="submit"
              onClick={() => {
                setActivated('')
                setIsRead(true)
              }}
              disabled={!isChecked}
            >
              ยืนยันข้อมูล
            </button>
          </Content>
        </CustomModal>
      </Modal>
      <CheckedBox>
        <input type="checkbox" checked={isChecked && isRead} />
        {type === 'terms' ? (
          <label>
            ข้าพเจ้าได้อ่านและยอมรับ&nbsp;
            <u
              onClick={() => {
                if (consent) {
                  setActivated('condition')
                } else {
                  alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
                }
              }}
            >
              เงื่อนไขและข้อตกลง การเป็นตัวแทน TQM
            </u>
            &nbsp;ตามที่ได้ระบุไว้
          </label>
        ) : (
          <label>
            ข้าพเจ้าได้อ่านและยอมรับ&nbsp;
            <u
              onClick={() => {
                if (consent) {
                  setActivated('condition')
                } else {
                  alert('เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง')
                }
              }}
            >
              นโยบายการเก็บรวบรวมและเปิดเผยข้อมูล การเป็นตัวแทน TQM
            </u>
            &nbsp;ตามที่ระบุไว้
          </label>
        )}
      </CheckedBox>
    </Fragment>
  )
}

export default Consent
