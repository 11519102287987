import styled from '@emotion/styled'
import Avatar from 'components/Avatar'

export const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
  .editBtn {
    width: fit-content;
    margin: 0 auto;
  }
  .table {
    display: grid;
    grid-template-columns: 30% 40% auto;
  }
  .table.header {
    margin-bottom: 6px;
  }
  .table.detail {
    padding-bottom: 24px;
  }
  .table label {
    width: 90%;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .table.detail:last-of-type {
    padding-bottom: 0px;
  }
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const Body = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 30px 0;
`

export const CustomAvatar = styled(Avatar)`
  margin: 0 auto 24px;
`

export const InfoContainer = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  grid-gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-secondary-color-2);
  div.row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  div.row-flex {
    display: flex;
    flex-direction: row;
    grid-gap: 24px;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-bottom: 10px;
    label {
      flex: 1;
    }
    div {
      display: flex;
      align-items: center;
      flex: 1;
    }
    .button-show {
      border-bottom: none;
    }
    .license-code {
      border-bottom: 1px solid var(--agt-secondary-color-2);
    }
  }
`

export const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
`

export const Value = styled.div`
  padding-bottom: 4px;
  border-bottom: 1px solid var(--agt-secondary-color-2);
`
