import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useLocation, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'querystring'
import isEqual from 'lodash/isEqual'

import { RootState } from 'store/rootReducer'
import { getMotorOptions, getSearch } from 'store/motorInsuranceSlice'
import { FilterType, SearchStateType } from 'types/MotorInsuranceType'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/BackgroundLoading'
import SearchProduct from './SearchProduct'
import Compare from '../compare'

type ParamsType = {
  id: string
}
const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`

const InsuranceProduct: FC = () => {
  let { id } = useParams<ParamsType>()
  const location = useLocation().search
  const dispatch = useDispatch()
  const { motorInsurances, loading, options } = useSelector((state: RootState) => state.motor)
  const [filter, setFilter] = useState<FilterType>({
    companyCode: null,
    coverCurrent: null,
  })
  const [isCompare, setIsCompare] = useState<boolean>(false)
  const [compareData, setCompareData] = useState<Array<SearchStateType>>([])
  const [newLocation, setNewLocation] = useState<string>(location)
  const jsonStr = queryString.parse(newLocation.replace('?', ''))

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: 'โอกาสในการขาย' },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/motor`, label: 'ประกันรถยนต์' },
    { label: isCompare ? 'รายละเอียดและเปรียบเทียบ' : 'แสดงแบบประกัน' },
  ]

  useEffect(() => {
    dispatch(getSearch(id, jsonStr))
    dispatch(
      getMotorOptions(id, {
        year: jsonStr.year,
        makeCode: jsonStr.makeCode,
        familyCode: jsonStr.familyCode,
        cc: jsonStr.cc,
        vehicleKey: jsonStr.vehicleKey,
      }),
    )
  }, [dispatch, id, newLocation])

  const onFilterChange = useCallback(
    (value) => {
      if (!isEqual(value, filter)) {
        setFilter(value)
      }
    },
    [filter],
  )
  useEffect(() => {
    const newJsonStr = { ...jsonStr, ...filter }
    setNewLocation(`?${queryString.stringify(newJsonStr)}`)
  }, [filter])

  const memoFilter = useMemo(() => filter, [filter])

  return (
    <Container>
      <Header>ประกันรถยนต์</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      {isCompare ? (
        <Compare
          setIsCompare={setIsCompare}
          setCompareData={setCompareData}
          data={compareData}
          location={location}
        />
      ) : (
        <>
          {(loading.options || loading.search) && <Loader />}
          <SearchProduct
            data={motorInsurances}
            options={options}
            setFilter={onFilterChange}
            filter={memoFilter}
            location={location}
            setCompareData={setCompareData}
            compareData={compareData}
            setIsCompare={setIsCompare}
          />
        </>
      )}
    </Container>
  )
}
export default InsuranceProduct
