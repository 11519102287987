import Modal from 'react-modal'
import styled from '@emotion/styled'
import React, { FC } from 'react'
import Icon from './Icon'

export const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}
const CloseButtonDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  width: 100%;
  padding: 24px;

  > div {
    cursor: pointer;
  }
`
const Content = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

type ModalProps = {
  isOpen: boolean
  onCancel: Function
  onConfirm: Function
}
const ImageModal: FC<ModalProps> = (props) => {
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={props.isOpen}>
      <CloseButtonDiv>
        <div onClick={() => props.onCancel()}>
          <Icon name="close_button" />
        </div>
      </CloseButtonDiv>
      <Content>{props.children}</Content>
    </Modal>
  )
}
export default ImageModal
