import React, { FC, useEffect, useState } from 'react'
import { ValueType } from 'react-select'
import { SearchStateType } from 'types/TravelInsuranceType'
import InsuranceCard from './InsuranceCard'
import { OptionType, SearchProductType } from './SearchProduct.type'
import { Container, Selector } from './SearchProduct.styles'
import { SortByOptions } from './SearchProduct.utils'

const SearchProduct: FC<SearchProductType> = (props) => {
  const { data, compareData, location, setCompareData, setIsCompare } = props
  const [newData, setNewData] = useState<Array<SearchStateType>>([])
  const [sortByOption, setSortByOption] = useState<ValueType<OptionType>>({
    label: 'ราคาน้อย-มาก',
    value: 'priceAsc',
  })

  useEffect(() => {
    if (data.length > 0) {
      setNewData(data)
    }
  }, [data])

  const sortHandle = (sortBy: string | null) => {
    switch (sortBy) {
      case 'priceAsc':
        setNewData([...newData].sort((a, b) => parseFloat(a.netAmount) - parseFloat(b.netAmount)))
        break
      case 'priceDesc':
        setNewData([...newData].sort((a, b) => parseFloat(b.netAmount) - parseFloat(a.netAmount)))
        break
    }
  }

  return (
    <Container>
      <div className="product_header">
        <div className="sort_wrapper">
          <label>เรียงตาม</label>
          <Selector
            value={sortByOption}
            onChange={(option: OptionType) => {
              setSortByOption(option)
              const value = (option as OptionType).value
              sortHandle(value)
            }}
            options={SortByOptions}
          />
        </div>
        <div>
          <label>สูงสุด 3 รายการ</label>
          <button
            type="button"
            onClick={() => setIsCompare(true)}
            disabled={compareData.length < 1}
          >
            เปรียบเทียบ ({compareData.length})
          </button>
        </div>
      </div>
      <div className="product_body">
        <div className="top">ทั้งหมด {newData.length || 0} รายการ</div>
        {(newData || []).map((d, index) => (
          <InsuranceCard
            key={index}
            data={d}
            location={location}
            setIsCompare={setIsCompare}
            compareData={compareData}
            setCompareData={setCompareData}
          />
        ))}
      </div>
    </Container>
  )
}

export default SearchProduct
