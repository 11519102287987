import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { useHistory, useParams } from 'routes'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import queryString from 'querystring'

import MotorComparingCard from 'components/Card/MotorComparingCard'
import MotorChooseCard from 'components/Card/MotorChooseCard'

import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { SearchStateType } from 'types/MotorInsuranceType'
import HistoryType from 'types/HistoryType'
import { carUsage } from '../utils'
import { currency } from 'utils/helper'
import Icon from 'components/Icon'

type SearchType = {
  id: string
}
type ComparingType = {
  setIsCompare: Function
  setCompareData: Function
  data: Array<SearchStateType>
  location: string
}

const ComparingMotor: FC<ComparingType> = ({ setIsCompare, setCompareData, data, location }) => {
  let { id } = useParams<SearchType>()
  const dispatch = useDispatch()
  const jsonStr = queryString.parse(location.replace('?', ''))
  const history: HistoryType = useHistory()

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id])

  return (
    <Container>
      <BackWrapper onClick={() => setIsCompare(false)}>
        <CustomIcon icon={faChevronLeft} className="fa-lg" />
        ย้อนกลับ
      </BackWrapper>
      <Remark>เปรียบเทียบสูงสุด 3 รายการ</Remark>
      <ComparingContainer>
        <ProductContainer>
          <ProductWrapper>
            <div />
            {data[0] ? (
              <MotorComparingCard
                logo={data[0]?.companyLogoUrl}
                onClose={() => {
                  setCompareData([...data.filter((d) => d.matrixId !== data[0].matrixId)])
                  data.length < 2 && setIsCompare(false)
                }}
                insuranceName={`${data[0]?.companyName} ชั้น${jsonStr.insureLevel}`}
                companyName={data[0]?.companyName}
                netAmount={data[0]?.netAmount}
              />
            ) : (
              <MotorChooseCard onClick={() => setIsCompare(false)} />
            )}
            {data[1] ? (
              <MotorComparingCard
                logo={data[1]?.companyLogoUrl}
                onClose={() =>
                  setCompareData([...data.filter((d) => d.matrixId !== data[1].matrixId)])
                }
                insuranceName={`${data[1]?.companyName} ชั้น${jsonStr.insureLevel}`}
                companyName={data[1]?.companyName}
                netAmount={data[1]?.netAmount}
              />
            ) : (
              <MotorChooseCard onClick={() => setIsCompare(false)} />
            )}
            {data[2] ? (
              <MotorComparingCard
                logo={data[2]?.companyLogoUrl}
                onClose={() =>
                  setCompareData([...data.filter((d) => d.matrixId !== data[2].matrixId)])
                }
                insuranceName={`${data[2]?.companyName} ชั้น${jsonStr.insureLevel}`}
                companyName={data[2]?.companyName}
                netAmount={data[2]?.netAmount}
              />
            ) : (
              <MotorChooseCard onClick={() => setIsCompare(false)} />
            )}
          </ProductWrapper>
          <ProductWrapper style={{ marginBottom: '1rem' }}>
            <div />
            {data[0] ? (
              <BuyButton
                onClick={() =>
                  history.push({
                    pathname: `/opportunities/${id}/catalog/motor/insurance_product/droplead/${data[0]?.matrixId}`,
                    search: `${location}`,
                  })
                }
              >
                ซื้อเลย
              </BuyButton>
            ) : (
              <div />
            )}
            {data[1] ? (
              <BuyButton
                onClick={() =>
                  history.push({
                    pathname: `/opportunities/${id}/catalog/motor/insurance_product/droplead/${data[1]?.matrixId}`,
                    search: `${location}`,
                  })
                }
              >
                ซื้อเลย
              </BuyButton>
            ) : (
              <div />
            )}
            {data[2] ? (
              <BuyButton
                onClick={() =>
                  history.push({
                    pathname: `/opportunities/${id}/catalog/motor/insurance_product/droplead/${data[2]?.matrixId}`,
                    search: `${location}`,
                  })
                }
              >
                ซื้อเลย
              </BuyButton>
            ) : (
              <div />
            )}
          </ProductWrapper>
          <CollapseHeader>
            <div>รายละเอียด</div>
          </CollapseHeader>
          <DetailWrapper>
            <Label>
              ประเภท
              <HintIcon className="icon" name="I" />
              <div className="info">
                <p>ประเภทความคุ้มครอง</p>
              </div>
            </Label>
            <div>
              {data[0] &&
                `ชั้น ${jsonStr.insureLevel} ${carUsage(data[0]?.carUsage)} ${data[0]?.garage}`}
            </div>
            <div>
              {data[1] &&
                `ชั้น ${jsonStr.insureLevel} ${carUsage(data[1]?.carUsage)} ${data[1]?.garage}`}
            </div>
            <div>
              {data[2] &&
                `ชั้น ${jsonStr.insureLevel} ${carUsage(data[2]?.carUsage)} ${data[2]?.garage}`}
            </div>
            <Label>
              จำนวนอู่ <HintIcon className="icon" name="I" />
              <div className="info">
                <p>จำนวนอู่ภายใต้การคุ้มครองของกรมธรรม์นี้</p>
              </div>
            </Label>
            <div>{data[0] && (currency(data[0]?.garageTotal, 0, '') || '-')}</div>
            <div>{data[1] && (currency(data[1]?.garageTotal, 0, '') || '-')}</div>
            <div>{data[2] && (currency(data[2]?.garageTotal, 0, '') || '-')}</div>
            <Label>
              ทุนประกัน <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ในการประกันภัยรถยนต์ คำว่า ทุนประกัน จะหมายถึง
                  <br />
                  สินไหมทดแทนที่บริษัทประกัน จะจ่ายให้กับผู้เอาประกัน
                  <br />
                  กรณีรถยนต์สูญหาย หรือกรณีเสียหายโดยสิ้นเชิง
                </p>
              </div>
            </Label>
            <div>{data[0] && (currency(data[0]?.cover, 0, '') || '-')}</div>
            <div>{data[1] && (currency(data[1]?.cover, 0, '') || '-')}</div>
            <div>{data[2] && (currency(data[2]?.cover, 0, '') || '-')}</div>
            <Label>
              ตรวจสภาพ <HintIcon className="icon" name="I" />
              <div className="info">
                <p>ตรวจสภาพรถยนต์ฟรี</p>
              </div>
            </Label>
            <div>{data[0]?.carCheck === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[1]?.carCheck === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[2]?.carCheck === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
          </DetailWrapper>
          <CollapseHeader>
            <div>ความคุ้มครองรถยนต์</div>
          </CollapseHeader>
          <DetailWrapper>
            <Label>
              ภัยก่อการร้าย <HintIcon className="icon" name="I" />
              <div className="info">
                <p>คุ้มครองภัยจากการก่อการร้าย</p>
              </div>
            </Label>
            <div>{data[0]?.terrorism === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[1]?.terrorism === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[2]?.terrorism === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <Label>
              น้ำท่วม <HintIcon className="icon" name="I" />
              <div className="info">
                <p>คุ้มครองอุทกภัย</p>
              </div>
            </Label>
            <div>{data[0]?.coverFlooding === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[1]?.coverFlooding === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[2]?.coverFlooding === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <Label>
              ค่าเสียหายส่วนแรก <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ค่าความเสียหายส่วนแรก แปลง่าย ๆ ก็คือ ค่าสินไหม
                  <br />
                  ทดแทนส่วนแรกที่ผู้เอาประกันภัยตกลง (ปลงใจ - สมัคร
                  <br />
                  ใจ) รับภาระเอง โดยจะระบุไว้เป็นข้อตกลงในกรมธรรม์
                  <br />
                  นั่นเอง
                </p>
              </div>
            </Label>
            <div>{data[0]?.coverDeduct === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[1]?.coverDeduct === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
            <div>{data[2]?.coverDeduct === 'Y' ? <FontAwesomeIcon icon={faCheck} /> : null}</div>
          </DetailWrapper>
          <CollapseHeader>
            <div>คุ้มครองบุคคลภายนอก</div>
          </CollapseHeader>
          <DetailWrapper>
            <Label>
              ชีวิต <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ความเสียหายต่อชีวิต ร่างกาย หรืออนามัยเฉพาะส่วนเกิน
                  <br />
                  วงเงินสูงสุดตามพ.ร.บ.
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.cover3Rd, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.cover3Rd, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.cover3Rd, 0, '')}` || '-')}</div>
            <Label>
              ต่อชีวิต/ครั้ง <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ความรับผิดต่อบุคคลภายนอก จะชดใช้ความเสียหายต่อ
                  <br />
                  ชีวิต ร่างกาย หรืออนามัย ของบุคคลภายนอก ตาม
                  <br />
                  ความเสียหายที่แท้จริงที่ผู้เอาประกันจะต้องรับผิดชอบตาม
                  <br />
                  กฎหมายต่อบุคคลภายนอกนั้น เฉพาะส่วนเกินวงเงินสูงสุด
                  <br />
                  ตาม พ.ร.บ.
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.cover3RdTime, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.cover3RdTime, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.cover3RdTime, 0, '')}` || '-')}</div>
            <Label>
              ทรัพย์สิน <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ความเสียหายต่อทรัพย์สินของคู่กรณีซึ่งเป็นผลมาจากคุณ
                  <br />
                  หรือผู้ขับขี่ที่ได้รับอนุญาตและเป็นฝ่ายผิด
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.cover3RdAsset, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.cover3RdAsset, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.cover3RdAsset, 0, '')}` || '-')}</div>
          </DetailWrapper>
          <CollapseHeader>
            <div>คุ้มครองตัวรถ</div>
          </CollapseHeader>
          <DetailWrapper>
            <Label>
              รถยนต์ <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ความเสียหายของตัวรถยนต์ที่เอาประกันภัยจากการชน
                  <br />
                  ความเสียหายต่อตัวรถยนต์ของคุณซึ่งเป็นผลมาจากการ
                  <br />
                  ชนกับรถยนต์คันอื่น หรือผู้ขับขี่ที่ได้รับอนุญาตและเป็นฝ่ายผิด
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.coverLostFire, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.coverLostFire, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.coverLostFire, 0, '')}` || '-')}</div>
            <Label>
              สูญหาย ไฟไหม้ <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ความเสียหายต่อตัวรถยนต์ของคุณซึ่งมีสาเหตุจากไฟไหม้หรือ
                  <br />
                  หรือสูญหายจากโจรกรรม
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.coverLostFire, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.coverLostFire, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.coverLostFire, 0, '')}` || '-')}</div>
          </DetailWrapper>
          <CollapseHeader>
            <div>คุ้มครองคนในรถ</div>
          </CollapseHeader>
          <DetailWrapper>
            <Label>
              ค่าอุบัติเหตุ <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ด้วยความคุ้มครองเสริมนี้ เราจะจ่ายให้ตามจำนวนเงินที่
                  <br />
                  เอาประกันให้กับผู้ขับขี่และผู้โดยสาร ในกรณีที่เสียชีวิต
                  <br />
                  สูญเสียมือ เท้า สายตา ทุพพลภาพถาวร ทุพพลภาพ
                  <br />
                  ชั่วคราว อันเนื่องจากการเกิดอุบัติเหตุครั้งนั้น
                </p>
              </div>
            </Label>
            <div>
              {data[0] &&
                (`฿ ${currency(data[0]?.coverAcc, 0, '')} x ${currency(
                  data[0]?.coverAccNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <div>
              {data[1] &&
                (`฿ ${currency(data[1]?.coverAcc, 0, '')} x ${currency(
                  data[1]?.coverAccNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <div>
              {data[2] &&
                (`฿ ${currency(data[2]?.coverAcc, 0, '')} x ${currency(
                  data[2]?.coverAccNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <Label>
              ค่ารักษา <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  เราชดเชยค่าใช้จ่ายในการรักษาพยาบาล ค่าบริการทางการ
                  <br />
                  แพทย์ ค่าผ่าตัด และค่าบริการอื่นๆ ที่เกิดขึ้น
                  <br />
                  จากอุบัติเหตุ โดยจ่ายชดเชยตามค่าเสียหายจริง เมื่อมีการ
                  <br />
                  เรียกร้องค่าเสียหายจากอุบัติเหตุครั้งนั้น เพื่อบุคคลใดๆ ที่
                  <br />
                  ได้รับบาดเจ็บจากอุบัติเหตุครั้งนั้น
                </p>
              </div>
            </Label>
            <div>
              {data[0] &&
                (`฿ ${currency(data[0]?.coverAccPass, 0, '')} x ${currency(
                  data[0]?.coverAccPassNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <div>
              {data[1] &&
                (`฿ ${currency(data[1]?.coverAccPass, 0, '')} x ${currency(
                  data[1]?.coverAccPassNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <div>
              {data[2] &&
                (`฿ ${currency(data[2]?.coverAccPass, 0, '')} x ${currency(
                  data[2]?.coverAccPassNum,
                  0,
                  '',
                )} คน` ||
                  '-')}
            </div>
            <Label>
              ค่าประกันตัว <HintIcon className="icon" name="I" />
              <div className="info">
                <p>
                  ค่าประกันตัว เราจะประกันตัวผู้เอาประกันภัยหรือบุคคลใด
                  <br />
                  ซึ่งขับขี่รถยนต์โดยได้รับความยินยอมจากผู้เอาประกันภัย
                  <br />
                  ในกรณีเกิดอุบัติเหตุ เป็นเหตุให้ถูกควบคุมตัวในคดีอาญา
                </p>
              </div>
            </Label>
            <div>{data[0] && (`฿ ${currency(data[0]?.coverLegal, 0, '')}` || '-')}</div>
            <div>{data[1] && (`฿ ${currency(data[1]?.coverLegal, 0, '')}` || '-')}</div>
            <div>{data[2] && (`฿ ${currency(data[2]?.coverLegal, 0, '')}` || '-')}</div>
          </DetailWrapper>
        </ProductContainer>
      </ComparingContainer>
    </Container>
  )
}

export default ComparingMotor

const Container = styled.section`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  font-size: 17px;
`
const BackWrapper = styled.div`
  width: fit-content;
  cursor: pointer;
`
const CustomIcon = styled(FontAwesomeIcon)`
  color: var(--agt-primary-text-color);
  margin-right: 18px;
`
const ComparingContainer = styled.div`
  padding: 1rem 0;
`
const Remark = styled.p`
  text-align: right;
  margin-bottom: 2rem;
`
const ProductContainer = styled.div`
  width: 100%;
  overflow: auto;
`
const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px repeat(3, 284px);
  grid-template-rows: 1fr;
  column-gap: 3px;
  width: 100%;
  padding-inline: 40px;
`
const BuyButton = styled.button`
  margin: 0.5rem auto 1rem;
`
const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 40px;
  background-color: var(--agt-background-color-2);
`
const DetailWrapper = styled(ProductWrapper)`
  grid-template-columns: 160px repeat(3, 284px);
  padding: 1rem 40px;
  row-gap: 0.5rem;

  > div {
    padding-right: 0.5rem;
    display: flex;
    justify-content: space-between;
  }
`
const Label = styled.div`
  font-weight: bold;
  position: relative;
  .info {
    font-weight: normal;
    position: absolute;
    left: 136px;
    top: 32px;
    color: var(--agt-white-color);
    background: var(--agt-primary-color-1);
    border-radius: 8px;
    padding: 14px;
    font-size: 13px;
    opacity: 0;
    transition: all 0.5s;
    width: fit-content;
    white-space: nowrap;
  }
  .icon:hover {
    cursor: pointer;
  }
  .icon:hover ~ .info {
    opacity: 1;
  }
`
const HintIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
  svg path {
    fill: var(--agt-primary-color-1);
  }
`
