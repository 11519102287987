import React, { FC, useState, useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import IframeResizer from 'iframe-resizer-react'
import Api from 'api'
import { useParams } from 'routes'
import authToken from 'utils/session'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

type useParamsType = {
  id: string
  orderNo: string
  documentInfo?: string
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const CustomIframe = styled(IframeResizer)`
  width: 100%;
  height: 750px;
  border: none;
`

const getOrderDetail = async (id: string, orderNo: string, type: string, cb: Function) => {
  try {
    const response = await Api.get(`/opportunities/${id}/product/life_insurances/order`, {
      orderNo,
    })
    let draftingParams = isEmpty(response.data.data.draftingParams)
    let createURL = response.data.data
    if (draftingParams) {
      if (type === 'documentInfo') {
        cb(
          encodeURIComponent(
            `${createURL.tqmFrontendUrl}/orders/${createURL.tqmOrderId}/document_info`,
          ),
        )
      } else {
        cb(
          encodeURIComponent(
            `${createURL.tqmFrontendUrl}/orders/${createURL.tqmOrderId}/holder_info`,
          ),
        )
      }
    } else {
      let mockUp = response.data.data.draftingParams
      let riderProducts = mockUp.riderProducts
      let lastMsg: string = ''
      riderProducts.forEach((product, i: number) => {
        let riders: string = `&riders%5B${i}%5D%5Bid%5D=${product.id}&riders%5B${i}%5D%5Bpremium%5D=${product.premium}&riders%5B${i}%5D%5BsumInsured%5D=${product.sumInsured}`
        lastMsg += riders
      })
      cb(
        encodeURIComponent(
          `/products/${
            mockUp.isPackage ? `package/${mockUp.packageId}` : `custom/${mockUp.insurancePolicyId}`
          }/edit?leadId=${mockUp.leadId}&orderId=${mockUp.orderId}&id=${
            mockUp.isPackage ? mockUp.packageId : mockUp.insurancePolicyId
          }&gender=${mockUp.gender}&searchBy=${mockUp.searchBy}&searchValue=${
            mockUp.searchValue
          }&birthdate=${moment(mockUp.birthdate).format('DD/MM/YYYY')}&from=${
            mockUp.from
          }&premium=${mockUp.premium}&sumInsured=${mockUp.sumInsured}&paymentPeriod=${
            mockUp.paymentPeriod
          }&occupation=${!isEmpty(mockUp.occupation) ? mockUp.occupation : ''}&occupationCategory=${
            !isEmpty(mockUp.occupationCategory) ? mockUp.occupationCategory : ''
          }${decodeURI(lastMsg)}`,
        ),
      )
    }
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

const OrderDetail: FC = (props) => {
  const { id, orderNo, documentInfo } = useParams<useParamsType>()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [tqmLink, setTQMLink] = useState<string>('')
  const iframeRef = useRef<any>(null)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/opportunities', label: 'โอกาสในการขาย' },
    { label: 'รายละเอียดคำสั่งซื้อ' },
  ]

  useEffect(() => {
    if (currentAgent) {
      if (['tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && !!documentInfo) {
        getOrderDetail(id, orderNo, 'documentInfo', (createPath) => {
          let url = `${
            process.env.REACT_APP_TQM_LIFE
          }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${createPath}`
          setTQMLink(url)
        })
      } else if (['tqm_staff', 'affiliate', 'broker'].includes(currentAgent?.agentType || '')) {
        getOrderDetail(id, orderNo, 'holderInfo', (createPath) => {
          let url = `${
            process.env.REACT_APP_TQM_LIFE
          }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${createPath}`
          setTQMLink(url)
        })
      }
    }
  }, [id, orderNo, documentInfo, currentAgent])

  useEffect(() => {
    iframeRef.current = document.getElementById('order_iframe')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef.current])

  return (
    <Container>
      <Header>รายละเอียดคำสั่งซื้อ</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot iframe />
      <CustomIframe
        id="order_iframe"
        ref={iframeRef}
        src={tqmLink}
        log
        autoResize
        inPageLinks
        scrolling
      />
    </Container>
  )
}
export default OrderDetail
