import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'

import Landing from 'pages/insurance_portfolio/root'
import Create from 'pages/insurance_portfolio/new'
import Show from 'pages/insurance_portfolio/_id'
import Order from 'pages/insurance_portfolio/order'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/contacts/:id/insurance_portfolio" component={Landing} exact />
    <ProtectedRoute path="/contacts/:id/insurance_portfolio/create" component={Create} exact />
    <ProtectedRoute path="/contacts/:id/insurance_portfolio/:insuranceId" component={Show} exact />
    <ProtectedRoute
      path="/contacts/:id/insurance_portfolio/:insuranceId/:orderNo"
      component={Order}
      exact
    />
  </Switch>
)

export default Pages
