import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import DatePicker from 'react-datepicker'
import { useField, useFormikContext } from 'formik'
import moment from 'moment'
import Select from 'react-select'
import Icon from './Icon'

const MONTH_TH = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

const MONTH_OPTIONS = MONTH_TH.map((m, index) => ({ label: m, value: index }))

type optionType = {
  label: number
  value: number
}

export const getYearsOptions = (startYear, order = 'desc', offset = 0) => {
  let currentYear = new Date().getFullYear() + offset
  let years: Array<optionType> = []
  startYear = startYear || currentYear - 100
  while (startYear <= currentYear) {
    const year = order === 'desc' ? currentYear-- : startYear++
    years.push({ label: year + 543, value: year })
  }
  return years
}

const YEAR_OPTIONS = getYearsOptions(null, 'desc', 1)

const Container = styled.div`
  /* position: relative; */
  .react-datepicker-wrapper {
    width: 100%;
    input {
      width: 100%;
    }
  }
`
const IconCalendar = styled(Icon)`
  position: absolute;
  right: 16px;
  top: 13px;
`

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
  color: var(--agt-primary-color-1);
`
const ArrowContainer = styled.div`
  height: 18px;
`
const ArrowLeft = styled(Icon)`
  height: 18px;
  cursor: pointer;
  svg {
    path {
      stroke: var(--agt-primary-color-1);
    }
  }
`
const ArrowRight = styled(ArrowLeft)`
  transform-origin: center;
  transform: rotate(180deg);
`
const SelectorContainer = styled.div`
  display: flex;
`
const MonthSelector = styled(Select)`
  width: 100px;
  margin-right: 8px;
`
const YearSelector = styled(Select)`
  width: 100px;
`

const dateFormatterFromPattern = (date: string, pattern: string) => {
  if (!date) return ''

  switch (pattern) {
    case 'dd/MM/yyyy':
    case 'DD/MM/YYYY':
      const splitedDate = date.split('/')
      return `${splitedDate[0]}/${splitedDate[1]}/${parseInt(splitedDate[2], 10) + 543}`
    case 'MMMM yyyy':
      const splitedDate2 = date.split(' ')
      return `${splitedDate2[0]} ${parseInt(splitedDate2[1], 10) + 543}`
    default:
      return date
  }
}

export const DatePickerComponent = (props) => {
  const {
    className = '',
    value,
    selected,
    showSelector = false,
    showIcon = true,
    yearOptions = [],
    isErrors = false,
    showMonthYearPicker = false,
    popperPlacement = '',
    ...rest
  } = props
  const [UUID] = useState(() => `date-picker_${Math.floor(Math.random() * 10000)}`)
  const [currValue, setValue] = useState<Date | [Date, Date] | null>(() => {
    if (value) return new Date(value)
    if (selected) return new Date(selected)
    return null
  })

  useEffect(() => {
    if (value) {
      setValue(new Date(value))
    } else {
      setValue(null)
    }
  }, [value])

  const CustomInput = (
    props: React.HTMLProps<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const { value: curr, onClick } = props
    let formattedDate = dateFormatterFromPattern(curr + '', rest.dateFormat || 'dd/MM/yyyy')

    return (
      <>
        <input
          value={formattedDate}
          onClick={onClick}
          placeholder="โปรดระบุ"
          readOnly
          style={isErrors ? { border: '1px solid red' } : {}}
          disabled={props.disabled}
        />
        {showIcon && (
          <div onClick={onClick}>
            <IconCalendar
              name="calendarDatePicker"
              color={props.disabled ? '#ACB1BF' : currValue ? 'black' : ''}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <Container className={className}>
      <DatePicker
        id={UUID}
        popperPlacement={popperPlacement}
        renderCustomHeader={(headerProps) => {
          const { date, decreaseMonth, increaseMonth, changeMonth, changeYear } = headerProps
          const month = MONTH_TH[date.getMonth()]
          const year = date.getFullYear() + 543
          const useYearOptions = yearOptions.length > 0 ? yearOptions : YEAR_OPTIONS

          return (
            <HeaderContainer>
              <ArrowContainer onClick={decreaseMonth}>
                <ArrowLeft name="vector_left" />
              </ArrowContainer>
              {showSelector ? (
                <SelectorContainer>
                  <MonthSelector
                    value={MONTH_OPTIONS.find((m) => m.value === date.getMonth())}
                    options={MONTH_OPTIONS}
                    onChange={(e: optionType) => {
                      let month = e.value + 1
                      if (currValue) {
                        let parseDate =
                          moment(currValue.toString()).format('YYYY') +
                          '-' +
                          moment(month.toString()).format('MM') +
                          '-' +
                          moment(currValue.toString()).format('DD')
                        if (rest.maxDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff > 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') > 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else if (rest.minDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff < 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') < 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else {
                          setValue(new Date(parseDate))
                          if (props.onChange) props.onChange(new Date(parseDate))
                        }
                      } else {
                        let fakeDate = new Date()
                        let parseDate =
                          moment(fakeDate.toString()).format('YYYY') +
                          '-' +
                          moment(month.toString()).format('MM') +
                          '-' +
                          moment(fakeDate.toString()).format('DD')
                        if (rest.maxDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff > 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') > 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else if (rest.minDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff < 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') < 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else {
                          setValue(new Date(parseDate))
                          if (props.onChange) props.onChange(new Date(parseDate))
                        }
                      }
                      changeMonth(e.value)
                    }}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  />
                  <YearSelector
                    value={useYearOptions.find((y) => y.value === date.getFullYear())}
                    onChange={(e: optionType) => {
                      if (currValue) {
                        let parseDate =
                          e.value +
                          '-' +
                          moment(currValue.toString()).format('MM') +
                          '-' +
                          moment(currValue.toString()).format('DD')
                        if (rest.maxDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff > 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') > 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else if (rest.minDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff < 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') < 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else {
                          setValue(new Date(parseDate))
                          if (props.onChange) props.onChange(new Date(parseDate))
                        }
                      } else {
                        let fakeDate = new Date()
                        let parseDate =
                          e.value +
                          '-' +
                          moment(fakeDate.toString()).format('MM') +
                          '-' +
                          moment(fakeDate.toString()).format('DD')
                        if (rest.maxDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff > 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') > 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else if (rest.minDate) {
                          let date1 = moment(parseDate)
                          let date2 = moment(rest.maxDate)
                          let diff = date2.diff(date1, 'days')
                          if (diff < 0) {
                            setValue(new Date(parseDate))
                            if (props.onChange) props.onChange(new Date(parseDate))
                          } else if (diff === 0) {
                            if (date2.diff(date1, 'minutes') < 0) {
                              setValue(new Date(parseDate))
                              if (props.onChange) props.onChange(new Date(parseDate))
                            }
                          }
                        } else {
                          setValue(new Date(parseDate))
                          if (props.onChange) props.onChange(new Date(parseDate))
                        }
                      }
                      changeYear(e.value)
                    }}
                    options={useYearOptions}
                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                  />
                </SelectorContainer>
              ) : (
                <div>
                  {month} {year}
                </div>
              )}
              <ArrowContainer onClick={increaseMonth}>
                <ArrowRight name="vector_left" />
              </ArrowContainer>
            </HeaderContainer>
          )
        }}
        selected={currValue}
        locale="th"
        autoComplete="off"
        dateFormat="dd/MM/yyyy"
        placeholderText="โปรดระบุ"
        {...rest}
        onChange={(value) => {
          const parsedValue = props.parser ? props.parser(value) : value
          setValue(parsedValue)
          if (props.onChange) props.onChange(parsedValue)
        }}
        customInput={React.createElement(React.forwardRef(CustomInput))}
        showMonthYearPicker={showMonthYearPicker}
      />
    </Container>
  )
}
type DateProps = {
  name: string
  maxDate?: Date
  format?: string
  showSelector?: boolean
  minDate?: Date
  yearOptions?: Array<optionType>
  disabled?: boolean
  isErrors?: boolean
  className?: string
  showIcon?: boolean
}

export const DatePickerField = (props: DateProps) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  const format = props.format ? props.format : 'DD/MM/YYYY'

  return (
    <DatePickerComponent
      {...field}
      {...props}
      autoComplete="off"
      maxDate={props.maxDate}
      selected={(field.value && moment(field.value, format).toDate()) || null}
      dateFormat="dd/MM/yyyy"
      dropdownMode="select"
      onChange={(val) => {
        const date = moment(val?.toString()).format(format)
        setFieldValue(field.name, date)
      }}
      disabled={props.disabled}
    />
  )
}

export default DatePickerField
