export const getLifeStylesOptions = (type) => {
  switch (type) {
    case 'entertainment':
      return {
        movie: {
          label: 'ภาพยนต์/ซีรี่ย์ที่ชอบ',
          fields: [
            {
              value: 'drama',
              label: 'ดราม่า',
            },
            {
              value: 'adventure',
              label: 'ผจญภัย',
            },
            {
              value: 'crime',
              label: 'อาชญากรรม',
            },
            {
              value: 'action',
              label: 'แอคชั่น',
            },
            {
              value: 'sciFi',
              label: 'วิทยาศาสตร์',
            },
            {
              value: 'horror',
              label: 'เขย่าขวัญ',
            },
            {
              value: 'comedy',
              label: 'ตลกเบาสมอง',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
        music: {
          label: 'เพลง',
          fields: [
            {
              value: 'hiphop/rap',
              label: 'Hiphop/Rap',
            },
            {
              value: 'pop',
              label: 'Pop',
            },
            {
              value: 'rock',
              label: 'Rock',
            },
            {
              value: 'dance',
              label: 'Dance',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
        games: {
          label: 'เกมที่ชอบ',
          fields: [
            {
              value: 'mobile',
              label: 'มือถือ',
            },
            {
              value: 'pc',
              label: 'คอมพิวเตอร์',
            },
            {
              value: 'console',
              label: 'คอนโซล',
            },
            {
              value: 'boardGame',
              label: 'บอร์ดเกม',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
        podcast: {
          label: 'พอดแคสต์',
          fields: [
            {
              value: 'education',
              label: 'การศึกษา',
            },
            {
              value: 'business',
              label: 'ธุรกิจ',
            },
            {
              value: 'news/politics',
              label: 'ข่าว/การเมือง',
            },
            {
              value: 'art',
              label: 'ศิลปะ',
            },
            {
              value: 'health',
              label: 'สุขภาพ',
            },
            {
              value: 'religion',
              label: 'ศาสนา',
            },
            {
              value: 'family',
              label: 'ครอบครัว',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
      }
    case 'health':
      return {
        exercise: {
          label: 'ออกกำลังกายแบบไหน',
          fields: [
            {
              value: 'run',
              label: 'วิ่ง',
            },
            {
              value: 'cycling',
              label: 'ปั่นจักรยาน',
            },
            {
              value: 'Swim',
              label: 'ว่ายน้ำ',
            },
            {
              value: 'weightTraining',
              label: 'เวทเทรนนิ่ง',
            },
            {
              value: 'yoga',
              label: 'โยคะ',
            },
            {
              value: 'football',
              label: 'ฟุตบอล',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
        sports: {
          label: 'กีฬาที่ชอบ',
          fields: [
            {
              value: 'football',
              label: 'ฟุตบอล',
            },
            {
              value: 'volleyball',
              label: 'วอลเลย์บอล',
            },
            {
              value: 'basketball',
              label: 'บาสเกตบอล',
            },
            {
              value: 'tennis',
              label: 'เทนนิส',
            },
            {
              value: 'boxing',
              label: 'มวย',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
      }
    case 'travel':
      return {
        travelStyle: {
          label: 'ชอบท่องเที่ยวแบบไหน',
          fields: [
            {
              value: 'sea',
              label: 'ทะเล',
            },
            {
              value: 'mountain',
              label: 'ภูเขา',
            },
            {
              value: 'waterfall',
              label: 'น้ำตก',
            },
            {
              value: 'shopping/entertainment',
              label: 'ช็อปปิ้ง/บันเทิง',
            },
            {
              value: 'travelAbroad',
              label: 'ต่างประเทศ',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
      }
    case 'food':
      return {
        foodStyle: {
          label: 'ชอบอาหารแบบไหน',
          fields: [
            {
              value: 'thai',
              label: 'ไทย',
            },
            {
              value: 'Japanese',
              label: 'ญี่ปุ่น',
            },
            {
              value: 'china',
              label: 'จีน',
            },
            {
              value: 'italian',
              label: 'อิตาเลียน',
            },
            {
              value: 'fastโood',
              label: 'ฟาสต์ฟู้ด',
            },
            {
              value: 'dessert',
              label: 'ของหวาน',
            },
            {
              value: 'other',
              label: 'อื่นๆ',
            },
          ],
        },
      }
    default:
      return {}
  }
}

export const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
