import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import {
  initialStateType,
  setAnnouncementsType,
  setAnnouncementType,
} from 'types/AnnouncementsType'
import { AnnouncementsThunk } from './store'

const initialState: initialStateType = {
  initialized: false,
  announcement: {
    id: 0,
    name: '',
    authorName: '',
    authorPosition: '',
    authorImagePath: '',
    bannerImagePath: '',
    authorImageId: null,
    bannerImageId: null,
    reference: '',
    status: '',
    updateBy: '',
    updatedAt: '',
    article: '',
    numberOfRead: 0,
    pin: null,
  },
  announcements: [],
  announcementOptions: [],
  error: null,
  paging: {
    page: 1,
    perPage: 10,
    totalCount: 0,
    totalPages: 0,
  },
}

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setInitialize(state) {
      state.initialized = true
    },
    setAnnouncement(state, action: PayloadAction<setAnnouncementType>) {
      state.announcement = action.payload.announcement
      state.error = null
    },
    setAnnouncements(state, action: PayloadAction<setAnnouncementsType>) {
      state.announcements = action.payload.announcements
      state.paging = action.payload.paging
      state.error = null
    },
    setErrorMessage(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  setInitialize,
  setAnnouncement,
  setAnnouncements,
  setErrorMessage,
} = announcementsSlice.actions

export default announcementsSlice.reducer

export const getAnnouncements = (
  page: number = 1,
  callback = () => {},
): AnnouncementsThunk => async (dispatch) => {
  try {
    const response = await Api.get('/announcements', { page })
    dispatch(setAnnouncements(response.data.data))
    callback()
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const getAnnouncement = (
  id: string | number,
  callback = () => {},
): AnnouncementsThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/announcements/${id}`)
    dispatch(setAnnouncement(response.data.data))
    callback()
  } catch (err) {
    dispatch(setErrorMessage(err.toString()))
  }
}

export const clearAnnouncements = (): AnnouncementsThunk => (dispatch) => {
  dispatch(
    setAnnouncements({
      announcements: initialState.announcements,
      paging: initialState.paging,
      error: null,
    }),
  )
}

export const clearAnnouncement = (): AnnouncementsThunk => (dispatch) => {
  dispatch(setAnnouncement({ announcement: initialState.announcement, error: null }))
}
