import React, { FC, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import Icon from 'components/Icon'
import api from 'api'

const Container = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  .RightColumn {
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`
const ButtonContainer = styled.div`
  min-width: 137px;
  max-width: 137px;
  height: 48px;
  margin-right: 14px;
`

const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.setWidthUploadbutton ? '110px' : '100%')};
  height: ${(props) => (props.setWidthUploadbutton ? '48px' : '')};
`

type ButtonProps = {
  setWidthUploadbutton?: boolean
}
type PlacholderProps = {
  status?: string
  setWidthlicenseStatus?: boolean
  setWidthWarning?: boolean
}
type ValueContainer = {
  setWidthValueContainer?: boolean
}

const PlaceHolder = styled.div<PlacholderProps>`
  display: flex;
  align-items: center;
  width: ${(props) =>
    props.setWidthWarning ? '180px !important ' : props.setWidthlicenseStatus ? '180px' : '100%'};
  margin-left: ${(props) =>
    props.setWidthWarning ? '-28px' : props.setWidthlicenseStatus ? '' : ''};
  height: 48px;
  padding: 0 12px;
  overflow: hidden;
  ${(props) =>
    props.status === 'completed'
      ? 'background-color: #9BCC55; color: white; width: fit-content;'
      : props.status === 'approved'
      ? 'background-color: #9BCC55; color: white; width: fit-content;'
      : props.status === 'processing'
      ? 'background-color: #F5A71D; color: white; width: fit-content;'
      : props.status === 'rejected'
      ? 'background-color: #F45A49; color: white; width: fit-content;'
      : props.status === 'expired'
      ? 'background-color: #F45A49; color: white; width: fit-content;'
      : 'background-color: var(--agt-background-color-1); color: var(--agt-secondary-color-1); width: fit-content;'};
  border-radius: 5px;
`
const PlaceHolderIcon = styled(Icon)`
  height: 16px;
  margin-right: 10px;
`
const ValueContainer = styled.div<ValueContainer>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.setWidthValueContainer ? '180px' : 'calc(100% - 151px)')};
  margin-left: ${(props) => (props.setWidthValueContainer ? '-28px' : '')};
  height: 48px;
  padding: 3px 14px 3px 3px;

  border: 1px solid var(--agt-background-color-2);
  border-radius: 5px;

  &.DOC {
    border-color: #cadcff;
    .upload__value-type {
      background-color: #cadcff;
    }
  }
  &.PNG {
    border-color: #ffe782;
    .upload__value-type {
      background-color: #ffe782;
    }
  }
  &.JPG {
    border-color: var(--agt-success-color);
    .upload__value-type {
      background-color: var(--agt-success-color);
    }
  }
  &.PDF {
    border-color: #ffb4be;
    .upload__value-type {
      background-color: #ffb4be;
    }
  }
  &.XLX {
    border-color: #a2dfc8;
    .upload__value-type {
      background-color: #a2dfc8;
    }
  }
  &.PPT {
    border-color: #ffbe85;
    .upload__value-type {
      background-color: #ffbe85;
    }
  }
`
const ValueType = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 58px;
  max-width: 58px;
  height: 42px;
  background: var(--agt-background-color-2);
  border-radius: 4px;
  color: var(--agt-white-color);
  text-align: center;
`
const Value = styled.div`
  width: 100%;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const IconContainer = styled.div`
  min-width: 17px;
  max-height: 17px;
  height: 20px;
  cursor: pointer;
  > span {
    width: 17px;
    height: 20px;
  }
`

const getFileType = (file: File | null) => {
  if (file) {
    let type = ''
    switch (file.type) {
      case 'image/jpeg':
        type = 'JPG'
        break
      case 'image/png':
        type = 'PNG'
        break
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        type = 'DOC'
        break
      case 'application/pdf':
        type = 'PDF'
        break
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        type = 'PPT'
        break
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = 'XLX'
        break
      default:
        break
    }
    return type
  }
  return ''
}

const createAttachment = async (file: File, callback: (id: any) => void) => {
  try {
    const response = await api.post('/attachments', { image: file }, { isFormData: true })
    callback(response.data.data.attachment.id)
  } catch (err) {
    if (err && err.response) {
      console.error(err.response)
    }
    alert('เกิดข้อผิดพลาดบางอย่าง')
    throw err
  }
}

export const Uploader: FC<UploadType> = (props) => {
  const {
    className = '',
    onChange = () => {},
    placeholder = '',
    options = {},
    licenseStatus = '',
    licenseImageId = '',
    uploadDocumentPlaceholder = '',
    imageIdCard = false,
    selectType = ['PDF', 'JPG', 'JPEG', 'PNG'],
  } = props
  const [previewFile, setFile] = useState<File | null>()
  const [fileType, setFileType] = useState<string>('')

  const clearFile = useCallback(() => {
    setFile(null)
    onChange('')
  }, [setFile, onChange])

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      createAttachment(file, (value) => {
        setFile(file)
        onChange(value)
      })
    },
    [onChange],
  )

  useEffect(() => {
    if (previewFile) {
      let fileType: string = getFileType(previewFile)
      if (selectType.find((type: string) => type === fileType)) {
        setFileType(fileType)
      } else {
        let mapTypes: string = ''
        mapTypes = selectType.join(', ')
        alert(`รองรับไฟล์ ${mapTypes} เท่านั้น`)
        clearFile()
      }
    }
  }, [previewFile, selectType, clearFile])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...options,
  })

  useEffect(() => {
    if (previewFile) {
      setFileType(getFileType(previewFile))
    }
  }, [previewFile, setFileType])

  function statusDesc(status) {
    switch (status) {
      case 'completed':
        return 'ได้รับการยืนยันแล้ว'
      case 'approved':
        return 'ได้รับการยืนยันแล้ว'
      case 'processing':
        return 'อยู่ระหว่างการตรวจสอบ'
      case 'rejected':
        return 'ไม่อนุมัติ'
      case 'expired':
        return 'ไม่อนุมัติ'
      default:
        return ''
    }
  }

  return (
    <Container className={'upload upload__container' + className}>
      {licenseStatus !== '' ? (
        <>
          <PlaceHolder
            className="upload upload__placeholder small"
            status={props.licenseStatus}
            setWidthlicenseStatus={props.setWidthlicenseStatus}
          >
            <PlaceHolderIcon name="clock" color={'white'} />
            {uploadDocumentPlaceholder}
            <br />
            {statusDesc(props.licenseStatus)}
          </PlaceHolder>
        </>
      ) : (
        <>
          <ButtonContainer {...getRootProps({ className: 'upload upload__dropzone dropzone' })}>
            <Button
              type="button"
              className="upload upload__button"
              disabled={!!previewFile}
              setWidthUploadbutton={props.setWidthUploadbutton}
            >
              อัปโหลด
            </Button>
            <input {...getInputProps()} />
          </ButtonContainer>
          {previewFile && (licenseImageId || imageIdCard) ? (
            <ValueContainer
              className={`upload upload__value-container ${fileType}`}
              setWidthValueContainer={props.setWidthValueContainer}
            >
              <ValueType className="upload upload__value-type small">{fileType}</ValueType>
              <Value className="upload upload__value-text small">{previewFile.name}</Value>
              <IconContainer className="upload upload__value-icon-container" onClick={clearFile}>
                <Icon name="cross" className="upload upload__value-icon" color="#000000"></Icon>
              </IconContainer>
            </ValueContainer>
          ) : (
            <PlaceHolder
              className="upload upload__placeholder small"
              status={props.licenseStatus}
              setWidthWarning={props.setWidthWarning}
            >
              <PlaceHolderIcon name="warning" color={'var(--agt-secondary-text-color);'} />
              {placeholder}
            </PlaceHolder>
          )}
        </>
      )}
    </Container>
  )
}

type UploadType = DropzoneOptions & {
  name?: string
  className?: string
  placeholder?: string
  licenseStatus?: string
  licenseImageId?: string
  imageIdCard?: boolean
  uploadDocumentPlaceholder?: string
  onChange?: (value: string) => void
  options?: DropzoneOptions
  setWidthUploadbutton?: boolean
  setWidthlicenseStatus?: boolean
  setWidthValueContainer?: boolean
  setWidthWarning?: boolean
  selectType?: Array<string>
}

const Upload: FC<UploadType> = (props) => {
  const [, , helpers] = useField(props.name || '')
  const { setValue } = helpers
  const onChange = (value: string) => {
    setValue(value)
    if (props.onChange) {
      props.onChange(value)
    }
  }
  return <Uploader {...props} onChange={onChange} selectType={props.selectType} />
}

export default Upload
