import React, { FC, useEffect } from 'react'
import styled from '@emotion/styled'
import { Field, Form, Formik } from 'formik'
import Input from 'pages/planning/share/Input'
import { useDispatch, useSelector } from 'react-redux'
import SelectInput from 'pages/planning/share/Select'
import { useParams } from 'routes'
import { RootState } from 'store/rootReducer'
import {
  createIncomeProtections,
  getIncomeProtectionOptions,
  getIncomeProtections,
} from 'store/planningSlice'
import Currency from 'pages/planning/share/Currency'

const Container = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  .MainContent {
    padding-left: 9.52%;
  }
`

const Text = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
`

const SubmitButton = styled.button`
  width: 183px;
  height: 48px;
  align-self: center;
`

const CustomInput = styled(Input)`
  > div:first-of-type {
    width: 35%;
  }
  > div:last-of-type {
    width: 65%;
    > div {
      width: 42%;
    }
  }
`

const CustomSelect = styled(SelectInput)`
  > div:first-of-type {
    width: 35%;
  }
  > div:last-of-type {
    width: 65%;
    > div:nth-of-type(1) {
      min-width: 214px;
    }
    > div {
      width: 42%;
    }
  }
`

const CustomCurrency = styled(Currency)`
  > div:first-of-type {
    width: 35%;
  }
  > div:last-of-type {
    width: 65%;
    > div {
      width: 42%;
    }
  }
`

type newUserType = {
  incomeCoveredType: string
  incomeCoveredValue: number
  othersCoveredValue: string
}

type PropType = {
  nextStep: Function
}

const Pages: FC<PropType> = (props) => {
  const dispatch = useDispatch()
  const incomeData = useSelector((state: RootState) => state.plans.incomeData)
  const options = useSelector((state: RootState) => state.plans.options)
  const { id }: any = useParams()

  useEffect(() => {
    dispatch(getIncomeProtections(id))
    dispatch(getIncomeProtectionOptions(id))
  }, [dispatch, id])

  return (
    <Formik
      initialValues={{ incomeData }}
      enableReinitialize
      onSubmit={(values) => {
        let newUser: newUserType = {
          incomeCoveredType: values.incomeData.incomeProtections.incomeCoveredType,
          incomeCoveredValue: values.incomeData.incomeProtections.incomeCoveredValue,
          othersCoveredValue: values.incomeData.incomeProtections.othersCoveredValue,
        }
        dispatch(createIncomeProtections(id, newUser))
        props.nextStep()
      }}
    >
      {(props) => (
        <Container onSubmit={props.handleSubmit}>
          <div className="MainContent">
            <Field
              name="incomeData.calculatedData.incomePerYear"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="รายได้ต่อปี"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.calculatedData.incomeGrowthRateWithInflation"
              component={CustomInput}
              placeholder="โปรดระบุ"
              caption="อัตราการเพิ่มขึ้นของรายได้ปรับด้วยอัตราเงินเฟ้อ"
              type="number"
              unit="%"
              disabled
            />
            <Field
              name="incomeData.calculatedData.investmentRiskRate"
              component={CustomInput}
              placeholder="โปรดระบุ"
              caption="อัตราผลตอบแทนการลงทุนหลังหักภาษี"
              type="number"
              unit="%"
              disabled
            />
            <Field
              name="incomeData.calculatedData.rorWithInflation"
              component={CustomInput}
              placeholder="โปรดระบุ"
              caption="อัตราผลตอบแทนการลงทุนหลังหักภาษีปรับด้วยอัตราเงินเฟ้อ"
              type="number"
              unit="%"
              disabled
            />
            <Field
              name="incomeData.calculatedData.rorWithIncomeGrowthRate"
              component={CustomInput}
              placeholder="โปรดระบุ"
              caption="อัตราผลตอบแทนการลงทุนหลังหักภาษีปรับด้วยอัตราการเพิ่มขึ้นของรายได้"
              type="number"
              unit="%"
              disabled
            />
            <Text>รายจ่ายต่อปี</Text>
            <Field
              name="incomeData.calculatedData.totalTaxPerYear"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="ภาษีเงินได้"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.calculatedData.socialInsuraceSumInsured"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="ประกันสังคม"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.calculatedData.providentFundSumInsured"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="กองทุนสำรองเลี้ยงชีพ"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.calculatedData.expensePerYear"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="ค่าใช้จ่ายส่วนตัว"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.calculatedData.totalRemaining"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="เงินออมต่อปี จากรายได้หลังหักค่าใช้จ่าย"
              unit="บาท"
              disabled
            />
            <Field
              name="incomeData.incomeProtections.incomeCoveredType"
              component={CustomSelect}
              options={options.incomeCoveredTypeOptions}
              placeholder="โปรดระบุ"
              caption="ระยะเวลาที่ต้องการคุ้มครองแหล่งรายได้ จนถึงอายุเกษียณ"
              unit="ปี"
              onChange={(option: any) => {
                const value = (option as any).value
                props.setFieldValue('incomeData.incomeProtections.incomeCoveredType', value)

                const ageValue =
                  value === 'until_age' ? props.values.incomeData.calculatedData.yearRemaining : 0

                props.setFieldValue('incomeData.incomeProtections.incomeCoveredValue', ageValue)
              }}
            />
            <Field
              name="incomeData.incomeProtections.incomeCoveredValue"
              component={CustomInput}
              placeholder="โปรดระบุ"
              unit="ปี"
              caption=" "
              type="number"
              disabled={
                props.values.incomeData.incomeProtections.incomeCoveredType === 'until_age'
                  ? true
                  : false
              }
            />
            <Field
              name="incomeData.incomeProtections.othersCoveredValue"
              component={CustomCurrency}
              placeholder="โปรดระบุ"
              caption="ภาระการเลี้ยงดู/อุปการะอื่นๆ (ถ้ามี)"
              unit="บาท"
            />
          </div>
          <SubmitButton type="submit">บันทึกและถัดไป</SubmitButton>
        </Container>
      )}
    </Formik>
  )
}

export default Pages
