import { paramsType as initialValuesType } from 'types/OpportunityType'

const initialValues: initialValuesType = {
  name: '',
  note: '',
  contactId: null,
  tasks: [],
  events: [],
}

export default initialValues
