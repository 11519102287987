import React, { FC, useState, useMemo } from 'react'
import { useParams, useHistory } from 'routes'
import Modal from 'components/Modal'
import ImageModal from 'components/ImageModal'
import HistoryType from 'types/HistoryType'
import { InsurancePortPageType } from 'types/InsurancePortType'
import { ModalContent } from 'pages/insurance_portfolio/components/InsurancePortForm.styles'
import { useDispatch } from 'react-redux'
import { deleteInsurance } from 'store/contactSlice'
import { ModalContainer } from './InsurancePort.styles'
import { getUrlExtension } from './InsurancePort.components'
import OldForm from './OldForm'
import PolicyMotorInsurance from './PolicyMotorInsurance'
import PolicyTAInsurance from './PolicyTAInsurance'
import PolicyActInsurance from './PolicyActInsurance'

const InsurancePortPage: FC<InsurancePortPageType> = (props) => {
  const { instead = {}, onEdit = () => {} } = props
  let { id, insuranceId } = useParams<{ id: string; insuranceId: string }>()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [isImageFile, setIsImageFile] = useState<boolean>(false)

  const document = useMemo(() => {
    if (instead.policyDocumentFullPath) {
      const type = getUrlExtension(instead.policyDocumentFullPath)
      if (type === 'pdf') {
        return (
          <div style={{ width: '800px', height: 'calc(100vh - 100px)' }}>
            <embed src={instead.policyDocumentFullPath} width="800px" height="100%" />
          </div>
        )
      }
      return (
        <img src={instead.policyDocumentFullPath} alt="policyDocumentPath" className="GetImage" />
      )
    }
    return <div />
  }, [instead.policyDocumentFullPath])

  return (
    <>
      <Modal
        isOpen={isDelete}
        onConfirm={() => {
          setIsDelete(false)
          dispatch(
            deleteInsurance(id, insuranceId, () => {
              history.push(`/contacts/${id}/insurance_portfolio`)
            }),
          )
        }}
        onCancel={() => setIsDelete(false)}
      >
        <ModalContent>
          <div className="Deleted">ยืนยันการลบข้อมูลกรมธรรม์</div>
        </ModalContent>
      </Modal>
      <ImageModal isOpen={isImageFile} onConfirm={() => {}} onCancel={() => setIsImageFile(false)}>
        <ModalContainer>{document}</ModalContainer>
      </ImageModal>

      {/* Policy Act */}
      {instead.policyType === 'prb' && (
        <PolicyActInsurance
          instead={instead}
          onEdit={onEdit}
          setIsImageFile={setIsImageFile}
          setIsDelete={setIsDelete}
        />
      )}
      {/* Policy Act  */}

      {/* Policy Motor */}
      {instead.policyType === 'motor' && (
        <PolicyMotorInsurance
          instead={instead}
          onEdit={onEdit}
          setIsImageFile={setIsImageFile}
          setIsDelete={setIsDelete}
        />
      )}
      {/* Policy Motor  */}

      {/* Policy TA */}
      {instead.policyType === 'ta' && (
        <PolicyTAInsurance
          instead={instead}
          onEdit={onEdit}
          setIsImageFile={setIsImageFile}
          setIsDelete={setIsDelete}
        />
      )}
      {/* Policy TA  */}

      {/* Old Insurance */}
      {instead.policyType === 'life' && (
        <OldForm
          instead={instead}
          onEdit={onEdit}
          setIsImageFile={setIsImageFile}
          setIsDelete={setIsDelete}
        />
      )}
      {/* Old Insurance */}
    </>
  )
}

export default InsurancePortPage
