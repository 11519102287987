import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { useHistory, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import queryString from 'querystring'

import { RootState } from 'store/rootReducer'
import { getMotorOptions } from 'store/motorInsuranceSlice'
import HistoryType from 'types/HistoryType'
import { VehicleDetailType } from 'types/MotorInsuranceType'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

import VehicleForm from './VehicleForm'
import VehicleSchema from './VehicleForm.validate'

const defaultProps: VehicleDetailType = {
  driverAge: 0,
  vehicleId: null,
  insureLevel: '',
  year: '',
  makeCode: '',
  familyCode: '',
  vehicleSubModel: '',
  carSubModelDetails: {
    vehicleKey: '',
    description: '',
    vehicleTypeCode: '',
    year: 0,
    cc: '',
    realCc: '',
    badgeSecondaryDescription: '',
    newPrice: '',
    doorNum: '',
    releaseYear: '',
    carGear: '',
    weight: '',
    seat: '',
    carGroup: '',
  },
  expiryDate: null,
}
type ParamsType = {
  id: string
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`

const MotorProduct: FC = () => {
  let { id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { options } = useSelector((state: RootState) => state.motor)
  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: 'โอกาสในการขาย' },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { label: 'ประกันรถยนต์' },
  ]

  return (
    <Container>
      <Header>ประกันรถยนต์</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      <Formik
        initialValues={defaultProps}
        onSubmit={(values) => {
          dispatch(
            getMotorOptions(id, {
              year: values.year,
              makeCode: values.makeCode,
              familyCode: values.familyCode,
              cc: values.carSubModelDetails?.cc,
              vehicleKey: values.carSubModelDetails?.vehicleKey,
            }),
          )
          const coverAmountOptions =
            options?.coverAmountOptions.filter((o) => o.value !== null) || []
          const getCoverValues = coverAmountOptions.map((item) => parseInt(item.value))

          const modifiedValues = {
            vehicleId: values.vehicleId,
            insureLevel: values.insureLevel,
            year: values.year,
            makeCode: values.makeCode,
            familyCode: values.familyCode,
            cc: values.carSubModelDetails?.cc,
            vehicleSubModel: values.carSubModelDetails?.description,
            carUsage: '110',
            coverMin: Math.min(...getCoverValues),
            coverMax: Math.max(...getCoverValues),
            driverAge: options?.contact?.age,
            vehicleKey: values.carSubModelDetails?.vehicleKey,
            expiryDate: values.expiryDate,
          }
          const queryStr = queryString.stringify(modifiedValues)
          history.push({
            pathname: `/opportunities/${id}/catalog/motor/insurance_product`,
            search: `?${queryStr}`,
          })
        }}
        validationSchema={VehicleSchema}
      >
        {(props) => <VehicleForm {...props} />}
      </Formik>
    </Container>
  )
}
export default MotorProduct
