import React, { Fragment, useState } from 'react'
import { camelizeKeys } from 'humps'
import { isNull, isEmpty, isObject } from 'lodash'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import ContactModal from 'components/ContactModal/ContactModal'
import ContactForOpportunity from 'components/ContactModal/ContactOppModal'
import { OpportunityByContact } from 'pages/opportunities/modal/New'
import { getLifeStylesOptions } from 'pages/contacts/utils'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import { dateFormat, phoneNumberFormat } from 'utils/helper'
import { ContactType } from 'types/ContactType'
import ConsentModal from '../components/ConsentModal'
import SetStateType from 'types/SetStateType'
import { MenuGroupType, LeftSideType } from './ProfilePage.type'
import {
  CustomFormBodyPart,
  ContactDetailRow,
  PencilIcon,
  MoreDetailLayer,
  MoreDetailTitle,
  MoreDetailInfo,
  RegisterInfo,
  PortButton,
  PlanningButton,
  ButtonContainer,
  Header,
  HintIcon,
  CustomModal,
} from './ProfilePage.styles'

export const ContactDetailLeftSide = ({
  contact,
  consentCurrentVersion,
  getContactByIdAgain,
}: LeftSideType) => {
  const [isConsent, setIsConsent] = useState<boolean>(false)
  return contact ? (
    <CustomFormBodyPart className="contact_detail-left">
      {isConsent && (
        <ConsentModal
          isOpen={isConsent}
          onConfirm={() => {
            setIsConsent(false)
            getContactByIdAgain()
          }}
          onCancel={() => {
            setIsConsent(false)
          }}
          onLater={() => {
            setIsConsent(false)
          }}
          values={contact}
        />
      )}
      <ContactDetailRow>
        <PencilIcon name="phone_square_icon" />
        <label>{contact?.phoneNumber ? phoneNumberFormat(contact?.phoneNumber) : '-'}</label>
      </ContactDetailRow>
      <ContactDetailRow className="status">
        <label id={'contact-id__status'} style={{ marginRight: '12px' }}>
          สถานะลูกค้า
        </label>
        <label>{contact?.status ? contact.status : '-'}</label>
      </ContactDetailRow>
      {isObject(contact?.currentAgreement) && contact?.isConsented === false ? (
        <div className="consentBtn">
          <button
            id="contact-id__consent-btn"
            type="button"
            className="primary consent"
            onClick={() => {
              setIsConsent(true)
            }}
          >
            ขอความยินยอม
          </button>
          <div className="icon">
            <Icon name="I" color={'var(--agt-primary-color-1)'} />
          </div>
          <div className="info">
            <small>
              มีข้อกำหนดการเก็บรวบรวมและใช้ข้อมูล
              <br />
              {consentCurrentVersion ||
                'ความยินยอมข้อตกลงและเงื่อนไข การลงทะเบียน ฉบับมิถุนายน 2565'}
              <br />
              กรุณาขอความยินยอมจากลูกค้าภายใน 30 วัน
              <br />
              มิฉะนั้นข้อมูลลูกค้าท่านนี้จะถูกลบออกจากระบบ
            </small>
          </div>
        </div>
      ) : (
        <ContactDetailRow>
          <label className="rightDetail">หมายเหตุ</label>
          <label className="leftDetail">{contact?.remark ? contact.remark : '-'}</label>
        </ContactDetailRow>
      )}
    </CustomFormBodyPart>
  ) : (
    <p> Undefined contact </p>
  )
}

export const ContactDetailRightSide = ({ contact }: { contact: ContactType | null }) => {
  return contact ? (
    <CustomFormBodyPart className="contact_detail-right">
      <p>ที่อยู่สำหรับติดต่อ</p>
      {isNull(contact.currentAddress) ||
      (contact.currentAddress && isEmpty(contact.currentAddress.postcode)) ? (
        <p className="contact_detail-address">-</p>
      ) : (
        <p className="contact_detail-address">
          {contact?.currentAddress?.housingNo && `${contact.currentAddress.housingNo} `}
          {contact?.currentAddress?.villageNo && `หมู่ ${contact.currentAddress.villageNo} `}
          {contact?.currentAddress?.building && `${contact.currentAddress.building} `}
          {contact?.currentAddress?.lane && `ซอย ${contact.currentAddress.lane} `}
          {contact?.currentAddress?.road && `ถนน ${contact.currentAddress.road} `}
          {contact?.currentAddress?.subDistrict}
          {` `}
          {contact?.currentAddress?.district}
          {` `}
          {contact?.currentAddress?.province}
          {` `}
          {contact?.currentAddress?.postcode}
        </p>
      )}
    </CustomFormBodyPart>
  ) : (
    <p> Undefined contact </p>
  )
}

export const OfficeAddressDetail = ({ contact }: { contact: ContactType | null }) => {
  return (
    <Fragment>
      {contact && contact.isSameCurrentAddress ? (
        <p className="contact_detail-address">
          {isEmpty(contact?.currentAddress?.housingNo) ? (
            '-'
          ) : (
            <>
              {contact?.currentAddress?.housingNo && `${contact.currentAddress.housingNo} `}
              {contact?.currentAddress?.villageNo && `หมู่ ${contact.currentAddress.villageNo} `}
              {contact?.currentAddress?.building && `${contact.currentAddress.building} `}
              {contact?.currentAddress?.lane && `ซอย ${contact.currentAddress.lane} `}
              {contact?.currentAddress?.road && `ถนน ${contact.currentAddress.road} `}
              {contact?.currentAddress?.subDistrict}
              {` `}
              {contact?.currentAddress?.district}
              {` `}
              {contact?.currentAddress?.province}
              {` `}
              {contact?.currentAddress?.postcode}
            </>
          )}
        </p>
      ) : (
        <p className="office-address">
          {isEmpty(contact?.officeAddress?.housingNo) ? (
            '-'
          ) : (
            <>
              {contact?.officeAddress?.housingNo && `${contact.officeAddress.housingNo} `}
              {contact?.officeAddress?.villageNo && `หมู่ ${contact.officeAddress.villageNo} `}
              {contact?.officeAddress?.building && `${contact.officeAddress.building} `}
              {contact?.officeAddress?.lane && `ซอย ${contact.officeAddress.lane} `}
              {contact?.officeAddress?.road && `ถนน ${contact.officeAddress.road} `}
              {contact?.officeAddress?.subDistrict}
              {` `}
              {contact?.officeAddress?.district}
              {` `}
              {contact?.officeAddress?.province}
              {` `}
              {contact?.officeAddress?.postcode}
            </>
          )}
        </p>
      )}
    </Fragment>
  )
}

export const MoreDetailProfile = ({ contact }: { contact: ContactType | null }) => {
  return contact ? (
    <Fragment>
      <h4 className="profile-title">ข้อมูลเพิ่มเติม</h4>
      <MoreDetailLayer>
        <MoreDetailTitle>อีเมล</MoreDetailTitle>
        <MoreDetailInfo>
          {contact.email ? (
            <a
              href={`mailto:${contact.email}`}
              style={{ textDecoration: 'none', color: '#000000' }}
            >
              {contact.email}
            </a>
          ) : (
            `-`
          )}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>เพศ</MoreDetailTitle>
        <MoreDetailInfo>
          {contact.gender ? (contact.gender === `M` ? `ชาย` : `หญิง`) : `-`}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>วันเกิด</MoreDetailTitle>
        <MoreDetailInfo>{contact?.birthdate ? dateFormat(contact?.birthdate) : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>สถานภาพ</MoreDetailTitle>
        <MoreDetailInfo>
          {contact?.maritalStatus ? getMaritalStatusTh(contact?.maritalStatus) : `-`}
        </MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>บริษัท</MoreDetailTitle>
        <MoreDetailInfo>{contact?.company ? contact?.company : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle>ตำแหน่ง</MoreDetailTitle>
        <MoreDetailInfo>{contact?.occupation ? contact?.occupation : `-`}</MoreDetailInfo>
      </MoreDetailLayer>
      <MoreDetailLayer>
        <MoreDetailTitle style={{ alignSelf: 'flex-end' }}>ที่อยู่ที่ทำงาน</MoreDetailTitle>
        <MoreDetailInfo>
          <OfficeAddressDetail contact={contact} />
        </MoreDetailInfo>
      </MoreDetailLayer>
    </Fragment>
  ) : (
    <p> Undefined contact </p>
  )
}

export const ShowMoreDetailInteresting = (category: string, moreDetailProps: object) => {
  const camelizeKeyMoreDetailProps = camelizeKeys(moreDetailProps)
  const lifeStyleObject: any = getLifeStylesOptions(category)
  return (
    <div>
      {Object.entries(camelizeKeyMoreDetailProps).map(([key, object], i) => (
        <div key={i}>
          <MoreDetailLayer>
            <MoreDetailTitle>{lifeStyleObject[key].label}</MoreDetailTitle>
            <MoreDetailInfo>
              {moreDetailProps &&
                object.values.map((value: string, index: number) => (
                  <span key={index}>
                    {
                      lifeStyleObject[key]['fields'].find((field: any) => {
                        return field.value === value
                      }).label
                    }
                    {index !== object.values.length - 1 ? ', ' : ''}
                  </span>
                ))}
            </MoreDetailInfo>
          </MoreDetailLayer>
        </div>
      ))}
    </div>
  )
}

export const MoreDetailInteresting = ({ contact }: { contact: ContactType | null }) => {
  const entertainments = contact?.entertainment
  const healths = contact?.health
  const travels = contact?.travel
  const foods = contact?.food
  const isEmptyEntertainments: boolean | undefined =
    entertainments && Object.keys(entertainments).length !== 0 ? false : true
  const isEmptyHealths: boolean | undefined =
    healths && Object.keys(healths).length !== 0 ? false : true
  const isEmptyTravels: boolean | undefined =
    travels && Object.keys(travels).length !== 0 ? false : true
  const isEmptyFoods: boolean | undefined = foods && Object.keys(foods).length !== 0 ? false : true
  const isEmptyLifeStyle: boolean =
    isEmptyEntertainments && isEmptyHealths && isEmptyTravels && isEmptyFoods
  return contact ? (
    <Fragment>
      <h4 className="interest-title">สิ่งที่ลูกค้าสนใจ</h4>
      {isEmptyLifeStyle ? (
        <MoreDetailLayer>
          <MoreDetailTitle>ยังไม่มีสิ่งที่สนใจ</MoreDetailTitle>
        </MoreDetailLayer>
      ) : (
        <div>
          {entertainments && ShowMoreDetailInteresting('entertainment', entertainments)}
          {healths && ShowMoreDetailInteresting('health', healths)}
          {travels && ShowMoreDetailInteresting('travel', travels)}
          {foods && ShowMoreDetailInteresting('food', foods)}
        </div>
      )}
    </Fragment>
  ) : (
    <p> Undefined contact </p>
  )
}

export const getMaritalStatusTh = (maritalStatus?: string) => {
  if (maritalStatus === 'single') return 'โสด'
  else if (maritalStatus === 'married') return 'สมรส'
  else if (maritalStatus === 'divorced') return 'หย่า'
  else return '-'
}

export function checkContact(contactData: {
  id?: string
  prefixName?: string
  firstName?: string
  lastName?: string
  gender?: string
  birthdate?: string
}) {
  let fact: boolean = false
  if (
    contactData.id &&
    contactData.prefixName &&
    contactData.firstName &&
    contactData.lastName &&
    contactData.gender &&
    contactData.birthdate
  ) {
    fact = true
  }
  return fact
}

export const GuestMenu = authorize(
  () => {
    return (
      <Fragment>
        <RegisterInfo>
          <div className="label">
            สมัครตัวแทนเพื่อปลดล็อก
            <span className="hidden">
              <br />
              การใช้งานทั้งหมด
            </span>
          </div>
          <div className="icon">
            <Icon name="lock" />
          </div>
        </RegisterInfo>
        <PortButton id={'contact-id__offer-sale'} type="button" disabled>
          เสนอขายทันที
        </PortButton>
        <PortButton id={'contact-id__insurances'} type="button" disabled>
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton id={'contact-id__planning'} type="button" disabled>
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['guest'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

export const AffiliateMenu = authorize(
  ({ contactId, contactData, getContactByIdAgain, currentAgent }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [isModalContact, setModalContact] = useState<boolean>(false)
    const [isModalContactOpps, setModalContactOpps] = useState<boolean>(false)
    return (
      <Fragment>
        <OpportunityByContact
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          contactId={contactId}
          contactData={contactData}
        />
        <ContactForOpportunity
          isOpen={isModalContactOpps}
          onConfirm={() => {}}
          onCancel={setModalContactOpps}
          setModalOpen={setModalOpen}
          contactData={contactData}
          getContactByIdAgain={getContactByIdAgain}
        />
        <ContactModal
          isOpen={isModalContact}
          onConfirm={() => {}}
          onCancel={setModalContact}
          contactData={contactData}
        />
        <PortButton
          id={'contact-id__offer-sale'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              setModalOpen(!isModalOpen)
            } else {
              setModalContactOpps(!isModalContactOpps)
            }
          }}
          disabled={
            currentAgent?.statusTh === 'ระงับ' ||
            (isObject(contactData?.currentAgreement) && contactData?.isConsented === false)
          }
        >
          เสนอขายทันที
        </PortButton>
        <PortButton
          id={'contact-id__insurances'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/insurance_portfolio`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={isObject(contactData?.currentAgreement) && contactData?.isConsented === false}
        >
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton
          id={'contact-id__planning'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/planning`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={
            currentAgent?.statusTh === 'ระงับ' ||
            (isObject(contactData?.currentAgreement) && contactData?.isConsented === false)
          }
        >
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['affiliate'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

export const StaffMenu = authorize(
  ({ contactId, contactData, getContactByIdAgain, currentAgent }) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    const [isModalContact, setModalContact] = useState<boolean>(false)
    const [isModalContactOpps, setModalContactOpps] = useState<boolean>(false)
    return (
      <Fragment>
        <OpportunityByContact
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
          contactId={contactId}
          contactData={contactData}
        />
        <ContactForOpportunity
          isOpen={isModalContactOpps}
          onConfirm={() => {}}
          onCancel={setModalContactOpps}
          setModalOpen={setModalOpen}
          contactData={contactData}
          getContactByIdAgain={getContactByIdAgain}
        />
        <ContactModal
          isOpen={isModalContact}
          onConfirm={() => {}}
          onCancel={setModalContact}
          contactData={contactData}
        />
        <PortButton
          id={'contact-id__offer-sale'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              setModalOpen(!isModalOpen)
            } else {
              setModalContactOpps(!isModalContactOpps)
            }
          }}
          disabled={
            currentAgent?.statusTh === 'ระงับ' ||
            (isObject(contactData?.currentAgreement) && contactData?.isConsented === false)
          }
        >
          เสนอขายทันที
        </PortButton>
        <PortButton
          id={'contact-id__insurances'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/insurance_portfolio`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={isObject(contactData?.currentAgreement) && contactData?.isConsented === false}
        >
          สรุปกรมธรรม์
        </PortButton>
        <PlanningButton
          id={'contact-id__planning'}
          type="button"
          status={contactData.status}
          onClick={() => {
            if (checkContact(contactData)) {
              document.location.href = `/contacts/${contactId}/planning`
            } else {
              setModalContact(!isModalContact)
            }
          }}
          disabled={
            currentAgent?.statusTh === 'ระงับ' ||
            (isObject(contactData?.currentAgreement) && contactData?.isConsented === false)
          }
        >
          วางแผนประกัน
        </PlanningButton>
      </Fragment>
    )
  },
  { roles: ['tqm_staff', 'broker'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)

export const AuthorizeMenu = (props: MenuGroupType) => {
  const { contactId, contact = {}, getContactByIdAgain, currentAgent } = props
  return (
    <ButtonContainer>
      <GuestMenu />
      <AffiliateMenu
        contactId={contactId}
        contactData={contact}
        getContactByIdAgain={getContactByIdAgain}
        currentAgent={currentAgent}
      />
      <StaffMenu
        contactId={contactId}
        contactData={contact}
        getContactByIdAgain={getContactByIdAgain}
        currentAgent={currentAgent}
      />
    </ButtonContainer>
  )
}

export const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>รายชื่อลูกค้า</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

export const DeleteModalComponent = ({
  isDelete,
  setIsDelete,
  contact,
  confirmDelete,
}: {
  isDelete: boolean
  setIsDelete: SetStateType<boolean>
  contact: ContactType | null
  confirmDelete: (contactId: number | string, setIsDelete: SetStateType<boolean>) => void
}) => {
  return (
    <Modal
      isOpen={isDelete}
      onConfirm={() => confirmDelete(contact?.id || 0, setIsDelete)}
      onCancel={() => setIsDelete(false)}
      hiddenButton={!contact?.canDeleted}
    >
      <CustomModal>
        <Avatar avatar={contact?.avatar || ''} width="100px" height="100px" type="circle" />
        {contact?.canDeleted ? (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลอื่น (นัดหมาย, งานถัดไป, โอกาสในการขาย)</p>
            <p>
              ที่เกี่ยวข้องกับ&nbsp;
              <span className="name">
                {contact?.name && contact?.name?.split(' ')[0]}&nbsp;
                {contact?.name && contact?.name.split(' ')[1]}
              </span>
              &nbsp;จะถูกลบไปด้วย
            </p>
            <p>คุณแน่ใจหรือไม่ว่าต้องการลบ?</p>
          </div>
        ) : (
          <div style={{ textAlign: 'center', marginTop: 30 }}>
            <p>ข้อมูลติดต่อนี้มีข้อมูลคำสั่งซื้อแล้ว</p>
            <p className="name">
              {contact?.name && contact?.name?.split(' ')[0]}&nbsp;
              {contact?.name && contact?.name.split(' ')[1]}
            </p>
            <p>ไม่สามารถลบได้</p>
          </div>
        )}
      </CustomModal>
    </Modal>
  )
}

export const MoreDetailCompoent = ({
  className,
  label,
  currentAgreement,
  isMoreDetail,
  setIsMoreDetail,
  consentCurrentVersion,
  contact,
  getContactByIdAgain,
}: {
  className: string
  label: string
  currentAgreement?: {
    agreementType: string
    beginDate: string
    consentBy: string
  } | null
  isMoreDetail: boolean
  setIsMoreDetail: SetStateType<boolean>
  consentCurrentVersion: string
  contact: ContactType | null
  getContactByIdAgain: () => void
}) => {
  const [isConsent, setIsConsent] = useState<boolean>(false)

  return (
    <div className={`${className} Consented`}>
      {isConsent && (
        <ConsentModal
          isOpen={isConsent}
          onConfirm={() => {
            setIsConsent(false)
            getContactByIdAgain()
          }}
          onCancel={() => {
            setIsConsent(false)
          }}
          onLater={() => {
            setIsConsent(false)
          }}
          values={contact}
        />
      )}
      <div className="Interested">
        {isObject(currentAgreement) && (
          <div className="Concede">
            <button
              className="primary"
              type="button"
              onClick={() => {
                setIsConsent(true)
              }}
            >
              ขอความยินยอม
            </button>
            <div className="icon">
              <Icon name="I" color={'var(--agt-primary-color-1)'} />
            </div>
            <div className="info">
              <small>
                มีข้อกำหนดการเก็บรวบรวมและใช้ข้อมูล
                <br />
                {consentCurrentVersion ||
                  'ความยินยอมข้อตกลงและเงื่อนไข การลงทะเบียน ฉบับมิถุนายน 2565'}
                <br />
                กรุณาขอความยินยอมจากลูกค้าภายใน 30 วัน
                <br />
                มิฉะนั้นข้อมูลลูกค้าท่านนี้จะถูกลบออกจากระบบ
              </small>
            </div>
          </div>
        )}
        {/* <button className="secondary" type="button" disabled>
          สินทรัพย์ส่วนตัว
        </button>
        <button className="secondary" type="button" disabled>
          สิ่งที่ลูกค้าสนใจ
        </button> */}
      </div>
      <div />
      <p onClick={() => setIsMoreDetail(!isMoreDetail)}>{label}</p>
    </div>
  )
}
