import React, { FC, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import DetailItem from './DetailItem'
import moment from 'moment'
import 'moment/locale/th'

import { useDispatch, useSelector } from 'react-redux'
import { updateCheck, updateRead } from 'store/notificationSlice'
import { RootState } from 'store/rootReducer'
import { useMemo } from 'react'
import { NotificationType } from 'types/NotificationType'

type NotificationProps = {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Header = styled.div`
  display: flex;
  align-items: center;
  height: 106px;
  padding: 0 46px;
  width: 100%;
  background: var(--agt-background-color-4);
  color: var(--agt-primary-color-1);
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 8px;
  padding-left: 46px;
  padding-right: 56px;
`
const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 46px;
  padding-right: 56px;
`
const Title = styled.div`
  text-align: right;
  color: var(--agt-secondary-color-2);
`
const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Line = styled.div`
  border-bottom: 1px solid var(--agt-background-color-2);
`
const DontHaveNotiText = styled.div`
  text-align: center;
  color: var(--agt-secondary-color-2);
`

const getTask = (notifications) => {
  let currentDayNotificatons: NotificationType[] = []
  let latedNotificatons: NotificationType[] = []
  let isRead = false
  let alertAt = '1920-01-01'
  let idTask = 0
  let actionId = 0
  notifications.forEach((item) => {
    if (item.actionType === 'task') {
      idTask = item.id ? item.id : 0
      if (moment(item?.info?.eventStartDate).isSame(new Date(), 'day')) {
        currentDayNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      } else if (moment(item?.info?.eventStartDate).isBefore(new Date(), 'day')) {
        latedNotificatons.push(item)
        if (moment(item?.alertAt).isAfter(alertAt)) {
          alertAt = item?.alertAt
          actionId = item?.actionId
        }
      }
      if (item.isRead) {
        isRead = item.isRead
      }
    }
  })
  const task = {
    id: idTask,
    actionType: 'task',
    actionId: actionId,
    info: null,
    isRead: isRead,
    isChecked: null,
    contactName: 'contactName',
    alertAt: alertAt,
    currentDayTask: currentDayNotificatons.length,
    latedTask: latedNotificatons.length,
  }
  return task
}

const Notification: FC<NotificationProps> = (props) => {
  const dispatch = useDispatch()
  const notifications = useSelector((state: RootState) => state.notifications.notifications)

  useEffect(() => {
    let notificatonIds: number[] = []
    notifications.forEach((item) => {
      if (!item.isChecked && item.id) {
        notificatonIds.push(item.id)
      }
    })
    if (notificatonIds.length > 0) dispatch(updateCheck(notificatonIds))
  }, [notifications, dispatch])

  const setOpenToConverse = useCallback(
    async (notification) => {
      let notificatonIds: number[] = []
      notifications.forEach((item) => {
        if (
          !item.isRead &&
          item.id &&
          (notification.id === item.id ||
            (notification.actionType === 'task' && item.actionType === 'task'))
        ) {
          notificatonIds.push(item.id)
        }
      })
      if (notificatonIds.length > 0) dispatch(updateRead(notificatonIds))
    },
    [notifications, dispatch],
  )

  const notificationLated = useMemo(() => {
    let newNotificatons: NotificationType[] = []
    const task = getTask(notifications)
    notifications.forEach((item, key) => {
      if (moment(item?.alertAt).isBefore(new Date(), 'day')) {
        if (item.actionType !== 'task') {
          newNotificatons.push(item)
        } else {
          if (item.id === task.id) {
            newNotificatons.push(task)
          }
        }
      }
    })
    newNotificatons = newNotificatons.sort(
      (a, b) => new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime(),
    )
    return newNotificatons
  }, [notifications])

  const notificationToday = useMemo(() => {
    let newNotificatons: NotificationType[] = []
    const task = getTask(notifications)
    notifications.forEach((item, key) => {
      if (moment(item?.alertAt).isSame(new Date(), 'day')) {
        if (item.actionType !== 'task') {
          newNotificatons.push(item)
        } else if (item.id === task.id) {
          newNotificatons.push(task)
        }
      }
    })
    newNotificatons = newNotificatons.sort(
      (a, b) => new Date(b.alertAt).getTime() - new Date(a.alertAt).getTime(),
    )
    return newNotificatons
  }, [notifications])

  return (
    <Container>
      <Header>
        <h3>การแจ้งเตือน</h3>
      </Header>
      {notificationLated.length > 0 || notificationToday.length > 0 ? (
        <>
          {notificationToday.length > 0 && (
            <>
              <TitleContainer>
                <Title>ใหม่</Title>
              </TitleContainer>
              <DetailContainer>
                <Line />
                {notificationToday.map((item, index) => (
                  <DetailItem {...item} key={index} setOpenToConverse={setOpenToConverse} />
                ))}
              </DetailContainer>
            </>
          )}
          {notificationLated.length > 0 && (
            <>
              <SubTitleContainer>
                <Title>ก่อนหน้านี้</Title>
              </SubTitleContainer>
              <DetailContainer>
                <Line />
                {notificationLated.map((item, index) => (
                  <DetailItem {...item} key={index} setOpenToConverse={setOpenToConverse} />
                ))}
              </DetailContainer>
            </>
          )}
        </>
      ) : (
        <DontHaveNotiText>ยังไม่มีการแจ้งเตือน</DontHaveNotiText>
      )}
    </Container>
  )
}

export default Notification
