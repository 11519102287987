import React from 'react'
import { Selector, Error, OtherComponent, OptionsError, OptionsStyles } from './EditAsset.styles'
import { ErrorMessage, Field, useField } from 'formik'
import Icon from 'components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import {
  RedLicencePlateComponentType,
  OptionsComponentType,
  VehicleChassisComponentType,
  ColumnType,
} from './EditAsset.type'

export const OptionsComponent = (props) => {
  const {
    name,
    options,
    className = '',
    topic = '',
    star = true,
    disabled = false,
    onChange = () => {},
    placeholder = 'เลือก',
  }: OptionsComponentType = props
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor =
    options?.find((select: { value: string; label: string }) => select.value === value) || ''
  return (
    <div className={`Column ${className}`}>
      <p>
        {topic} {star && <span>*</span>}
      </p>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: { value: string; label: string }) => {
          setValue(option.value)
          if (onChange) onChange(option)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const RedLicencePlateComponent = (props: RedLicencePlateComponentType) => {
  const { name, label, className = '', cb = () => {}, disabled = false } = props
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <OtherComponent className={className} style={disabled ? { cursor: 'not-allowed' } : {}}>
      <input id="red_label__1" type="checkbox" checked={value} />
      <span
        className="checkmark"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </span>
      <label
        htmlFor="red_label__1"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        {label}
      </label>
    </OtherComponent>
  )
}

export const VehicleChassisComponent = ({
  topic,
  name,
  type = 'string',
  placeholder = 'โปรดระบุ',
  disabled = false,
  star = true,
}: VehicleChassisComponentType) => {
  return (
    <div className="VehicleClassis">
      <p>
        {topic} {star && <span>*</span>}
      </p>
      <Field name={name} type={type} placeholder={placeholder} disabled={disabled} />
      <ErrorMessage component={Error} name={name} />
      <div className="Icon" onClick={() => {}}>
        <Icon name="I" color={'var(--agt-primary-color-1)'} />
      </div>
    </div>
  )
}

export const Column = ({
  className = '',
  topic,
  name,
  type = 'string',
  placeholder = 'โปรดระบุ',
  disabled = false,
  star = true,
}: ColumnType) => {
  return (
    <div className={`Column ${className}`}>
      <p>
        {topic} {star && <span>*</span>}
      </p>
      <Field name={name} type={type} placeholder={placeholder} disabled={disabled} />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}
