import React, { useState, useEffect, FC, Fragment, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'routes'
import { useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import Icon from 'components/Icon'
import { LandingPremiumCardType } from 'types/DashboardType'
import { getdashboard } from 'store/profileSlice'
import {
  getThisMonthTh,
  getThisQuarterShortTh,
  getThisYearTh,
} from 'components/Inputs/DatePicker/DatePicker.utils'
import { currency } from 'utils/helper'
import {
  ChartDiv,
  HeaderMonthDiv,
  HeaderQuarterDiv,
  TitterDashBoard,
  RightDashBoardDiv,
  DashBoardDiv,
  TopDashBoard,
  LeftDashBoardDiv,
  BottomDashBoard,
  Container,
  ColumnData,
  IconStyle,
  HeaderYearDiv,
} from './LandingPremiumCard.styles'
import {
  DashboardNoValue,
  DoughnutNoChart,
  DoughnutMonthChart,
  DoughnutQuarterChart,
  DoughnutYearChart,
  TogglePremium,
  SuggestPremium,
} from './LandingPremiumCard.components'

const LandingPremiumCard: FC<LandingPremiumCardType> = (props) => {
  const { disabled } = props
  const dispatch = useDispatch()
  const chartData = useSelector((state: RootState) => state.profile.dashboard)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isToggle, setIsToggle] = useState<boolean>(true)
  const [policyType, setPolicyType] = useState<string>('')

  useEffect(() => {
    if (['tqm_staff', 'broker'].includes(currentAgent?.agentType || '')) {
      setPolicyType('premium')
    } else if (['affiliate'].includes(currentAgent?.agentType || '')) {
      setPolicyType('suggest_premium')
    } else {
      setPolicyType('')
    }
  }, [currentAgent?.agentType])

  useEffect(() => {
    if (policyType) dispatch(getdashboard(policyType))
  }, [dispatch, policyType])

  const thisMonth = useMemo(() => {
    return getThisMonthTh()
  }, [])

  const thisQuarter = useMemo(() => {
    return getThisQuarterShortTh()
  }, [])

  const thisYear = useMemo(() => {
    return getThisYearTh()
  }, [])

  const formatValue = useCallback((input) => {
    if (input) {
      if (input.toString().split('.')[1]) return `${currency(input, 2, '')} ฿`
      return `${currency(input, 0, '')} ฿`
    }
    return '-'
  }, [])

  const isNoDataArray = useMemo(() => {
    return Object.values(chartData).map((chart) => chart.every((c) => c.value === 0))
  }, [chartData])

  const switchToggle = () => {
    setIsToggle(!isToggle)
    if (policyType === 'premium') {
      setPolicyType('suggest_premium')
    } else {
      setPolicyType('premium')
    }
  }

  return (
    <Container className="agt-landing-premium-card__container">
      <DashBoardDiv>
        {disabled ? (
          <DashboardNoValue />
        ) : (
          <Fragment>
            <TopDashBoard>
              <LeftDashBoardDiv>
                <TitterDashBoard>
                  <p className="Title">ภาพรวมเบี้ยประกัน</p>
                </TitterDashBoard>
                <TogglePremium isToggle={isToggle} switchToggle={switchToggle} />
                <SuggestPremium />
              </LeftDashBoardDiv>
              <RightDashBoardDiv>
                <ChartDiv>
                  <HeaderMonthDiv>
                    <p className="MonthTitle">รายเดือน{thisMonth}</p>
                  </HeaderMonthDiv>
                  {isNoDataArray[0] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutMonthChart rawData={chartData.month} />
                  )}
                </ChartDiv>
                <ChartDiv>
                  <HeaderQuarterDiv>
                    <p className="QuarterTitle">รายไตรมาส {thisQuarter}</p>
                  </HeaderQuarterDiv>
                  {isNoDataArray[1] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutQuarterChart rawData={chartData.quarter} />
                  )}
                </ChartDiv>
                <ChartDiv>
                  <HeaderYearDiv>
                    <p className="YearTitle">รายปี {thisYear}</p>
                  </HeaderYearDiv>
                  {isNoDataArray[2] ? (
                    <DoughnutNoChart />
                  ) : (
                    <DoughnutYearChart rawData={chartData.year} />
                  )}
                </ChartDiv>
              </RightDashBoardDiv>
            </TopDashBoard>
            <BottomDashBoard>
              <ColumnData color={'#00529A'}>
                <IconStyle name="Life" />
                <div className="Header">ชีวิต</div>
                <div className="ValueLife">{formatValue(chartData?.month[0]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[0]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[0]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#45DAAE'}>
                <IconStyle name="health" />
                <div className="Header">สุขภาพ</div>
                <div className="ValueHealth">{formatValue(chartData?.month[1]?.value)}</div>
                <div className="Values">
                  {chartData?.quarter[1]?.value
                    ? `${currency(chartData?.quarter[1]?.value, 0, '')} ฿`
                    : '-'}
                </div>
                <div className="Values">{formatValue(chartData?.year[1]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#3ABAF8'}>
                <IconStyle name="car" />
                <div className="Header">รถยนต์</div>
                <div className="ValueCar">{formatValue(chartData?.month[2]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[2]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[2]?.value)}</div>
              </ColumnData>
              <ColumnData color={'#7E5FFF'} style={{ border: 'unset' }}>
                <IconStyle name="Fire" />
                <div className="Header">อัคคีภัย</div>
                <div className="ValueFire">{formatValue(chartData?.month[3]?.value)}</div>
                <div className="Values">{formatValue(chartData?.quarter[3]?.value)}</div>
                <div className="Values">{formatValue(chartData?.year[3]?.value)}</div>
              </ColumnData>
              <div className="LinkNextPage">
                <Link to="/dashboard" className="LinkText">
                  ดูเพิ่มเติม
                </Link>

                <Link to="/dashboard" className="LinkText">
                  <Icon name="Arrow_dashboard" />
                </Link>
              </div>
            </BottomDashBoard>
          </Fragment>
        )}
      </DashBoardDiv>
    </Container>
  )
}

export default authorize(LandingPremiumCard, {
  roles: ['tqm_staff', 'affiliate', 'broker'],
  params: { unauthorizedAction: UnauthorizedAction.DISABLED },
})
