import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { clearAnnouncement, getAnnouncement } from 'store/announcementsSlice'
import { useParams } from 'react-router'
import { RootState } from 'store/rootReducer'
import Avatar from 'components/Avatar'
import newsImage from 'assets/images/news_banner_landing.jpg'
import Loader from 'components/Loader'

type useParamsType = { id: string }

const Container = styled.div`
  width: 100%;
`
const Header = styled.h3`
  padding: 32px 45px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: none;
  padding-bottom: 40px;
`
const ArticleContainer = styled.div``
const ArticleImage = styled.img`
  width: 700px;
  display: block;
  margin: 0 auto;
`
const ArticleText = styled.div`
  padding: 40px 0;
  border-bottom: 2px solid var(--agt-primary-color-1);
  p {
    text-indent: 50px;
    margin-bottom: 10px;
  }
`
const AuthorContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .author {
    display: flex;
    flex-direction: row;
    margin: 20px 0;
  }
  .ref {
  }
`
const AuthorImage = styled.div`
  margin-right: 20px;
`
const AuthorDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  p {
    font-weight: bolder;
  }
`

const AnnouncementId: FC = () => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { announcement: data } = useSelector((state: RootState) => state.announcements)

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/announcements', label: 'ข่าวสาร/ประกาศ' },
    {
      label: data?.name || 'แบบประกันใหม่จากเอบีซี ประกันชีวิต',
    },
  ]

  useEffect(() => {
    setIsLoading(true)
    dispatch(getAnnouncement(id, () => setIsLoading(false)))
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch(clearAnnouncement())
    }
  }, [dispatch])
  if (isLoading) {
    return <Loader />
  }
  return (
    <Container>
      <Header>{data?.name || 'แบบประกันใหม่จากเอบีซี ประกันชีวิต'}</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot />
      <ArticleContainer>
        <ArticleImage src={data?.bannerImagePath || newsImage} />
        <ArticleText>
          <p
            className="content"
            dangerouslySetInnerHTML={{
              __html: data?.article || 'ยังไม่มีการระบุเนื้อหาใดๆ',
            }}
          />
        </ArticleText>
      </ArticleContainer>
      <AuthorContainer>
        <div className="author">
          <AuthorImage>
            <Avatar
              type="circle"
              avatar={
                data?.authorImagePath ||
                'https://image.sistacafe.com/images/uploads/summary/image/54688/301386_full1.jpg'
              }
              width="80px"
              height="80px"
            />
          </AuthorImage>
          <AuthorDetail>
            <p>{data?.authorName || 'แฮร์รี่ พอตเตอร์'}</p>
            <p>{data?.authorPosition || 'Admin'}</p>
          </AuthorDetail>
        </div>
        <div className="ref"></div>
      </AuthorContainer>
    </Container>
  )
}

export default AnnouncementId
