import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import Modal from 'react-modal'

import OpportunityForm from '../components/EditForm'
import { diff } from 'deep-object-diff'

import Api from 'api'

Modal.setAppElement('#root')
const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minWidth: '20rem',
    minHeight: '10rem',
    width: '80%',
    maxWidth: '44rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '999',
  },
}

const Container = styled.div`
  align-items: center;
  width: 100%;
`
const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`
const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
`
type OpportunityValues = {
  id: number
  name: string
  note: string
  contactId: number | null
  pipelineStateId: String | null
}

const updateOpportunity = async (
  newOpportunity: OpportunityValues,
  setModalOpen,
  setOpportunities,
  opportunity,
  getOpportunity,
  forContact,
) => {
  try {
    if (forContact) {
      await Api.put(`/opportunities/${newOpportunity.id}`, newOpportunity)
      getOpportunity()
      setModalOpen(false)
    } else if (Object.keys(diff(opportunity, newOpportunity)).length !== 0) {
      const response = await Api.put(`/opportunities/${newOpportunity.id}`, newOpportunity)
      setOpportunities(response.data.data.pipelineStates)
      setModalOpen(false)
      setModalOpen(true)
    }
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}
const getOpportunityId = async (
  id: number,
  setOpportunity: Function,
  setpPipelineStateNameTh: Function,
) => {
  try {
    const response = await Api.get(`/opportunities/${id}`, {})
    setOpportunity(response.data.data.opportunity)
    setpPipelineStateNameTh(response.data.data.pipelineStateNameTh)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}
const EditOpportunityForm = ({
  opportunity,
  setModalOpen,
  setOpportunities,
  pipelineStateNameTh,
  getOpportunity,
  forContact,
  destroyOpp,
}) => {
  const defaultProps: OpportunityValues = {
    ...opportunity,
  }
  return (
    <CustomForm>
      <Formik
        initialValues={defaultProps}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateOpportunity(
            values,
            setModalOpen,
            setOpportunities,
            opportunity,
            getOpportunity,
            forContact,
          )
        }}
      >
        {(props) => (
          <OpportunityForm
            handleSubmit={props.handleSubmit}
            opportunity={opportunity}
            setModalOpen={setModalOpen}
            pipelineStateNameTh={pipelineStateNameTh}
            dirty={props.dirty}
            isValid={props.isValid}
            getOpportunity={getOpportunity}
            destroyOpp={destroyOpp}
          />
        )}
      </Formik>
    </CustomForm>
  )
}
type EditPropsType = {
  className?: string
  isModalOpen: boolean
  pipelineStateId?: number | undefined
  setModalOpen: Function
  setOpportunities?: Function
  taskId: number
  getOpportunity?: Function
  forContact?: boolean
  destroyOpp?: Function
}
const OpportunityEdit: FC<EditPropsType> = (props) => {
  const { forContact = false, getOpportunity, destroyOpp } = props
  const [opportunity, setOpportunity] = useState<OpportunityValues>()
  const [pipelineStateNameTh, setpPipelineStateNameTh] = useState<any>()
  useEffect(() => {
    getOpportunityId(props.taskId, setOpportunity, setpPipelineStateNameTh)
  }, [props.taskId])
  return (
    <Container className={props.className}>
      <Modal style={ModalCustomStyle} id="modal" isOpen={props.isModalOpen}>
        <HeaderModal></HeaderModal>
        <EditOpportunityForm
          opportunity={opportunity}
          setModalOpen={props.setModalOpen}
          setOpportunities={props.setOpportunities}
          pipelineStateNameTh={pipelineStateNameTh}
          getOpportunity={getOpportunity}
          forContact={forContact}
          destroyOpp={destroyOpp}
        />
      </Modal>
    </Container>
  )
}
export default OpportunityEdit
