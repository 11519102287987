import Modal from 'react-modal'
import React, { FC } from 'react'

type ModalProps = {
  isOpen: boolean
}
const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'unset',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
}

const ModalContainer: FC<ModalProps> = (props) => {
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={props.isOpen}>
      {props.children}
    </Modal>
  )
}
export default ModalContainer
