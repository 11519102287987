import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'routes'
import get from 'lodash/get'
import { Formik } from 'formik'
import queryString from 'querystring'
import { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/BackgroundLoading'
import HistoryType from 'types/HistoryType'
import { SearchStateType } from 'types/TravelInsuranceType'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import {
  getTravelOptions,
  getSearch,
  getSearchByTypeTa,
  createOrder,
} from 'store/travelInsuranceSlice'
import InsuranceDetail from './InsuranceDetail'
import DropleadForm from './form'
import { Container, Header, BreadCrumbStyle, DropleadContainer } from './index.styles'
import { ParamsType, DropleadTypes, AssuredTypes } from './index.type'
import DropleadSchema from './index.validate'

const TravelDropleadProduct: FC = () => {
  let { id, matrix_id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const location = useLocation().search
  const jsonStr = queryString.parse(location.replace('?', ''))
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { travelInsurances, loading, options, optionsByTypeTa } = useSelector(
    (state: RootState) => state.travel,
  )
  const [insurancesById, setInsurancesById] = useState<SearchStateType>()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/travel`, label: 'ประกันเดินทาง' },
    {
      path: `/opportunities/${id}/catalog/travel/insurance_product${location}`,
      label: 'แสดงแบบประกัน',
    },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  useEffect(() => {
    if (id) {
      dispatch(getNewOpportunityById(id))
      dispatch(getTravelOptions(id))
    }
  }, [dispatch, id])

  useEffect(
    () => {
      dispatch(
        getSearchByTypeTa(id, {
          travelType: jsonStr.travelType.toString(),
          travelTypeCountry: jsonStr.travelTypeCountry.toString(),
        }),
      )
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, id],
  )

  useEffect(() => {
    if (id && jsonStr) {
      dispatch(getSearch(id, jsonStr))
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id])

  useEffect(() => {
    setInsurancesById(travelInsurances.find((d) => d.productId === matrix_id))
  }, [matrix_id, travelInsurances])

  const onSaving = (values: DropleadTypes) => {
    let params = { ...values }
    // console.log('params....', params)
    dispatch(
      createOrder(id, params, () => {
        history.push('/opportunities')
      }),
    )
  }

  const insuranceData = useMemo(
    () => ({
      productAbb: insurancesById?.productAbb || '',
      companyLogoUrl: insurancesById?.companyLogoUrl || '',
      companyName: insurancesById?.companyName || '',
      netAmount: insurancesById?.netAmount || '',
      minAge: insurancesById?.minAge || '',
      maxAge: insurancesById?.maxAge || '',
      coverDetail: insurancesById?.coverDetail || null,
      // ----------------
      insureLevel: jsonStr?.insureLevel?.toString() || '',
      travelTypeCountry: jsonStr?.travelTypeCountry?.toString() || '',
    }),
    [insurancesById, jsonStr],
  )

  const defaultTaDetails = useMemo(
    () => {
      let array: Array<AssuredTypes> = []
      if (jsonStr && jsonStr.travelPassenger && options?.contact?.id) {
        let numberPassenger: number = jsonStr?.travelPassenger
          ? parseInt(jsonStr.travelPassenger.toString())
          : 0
        if (numberPassenger > 0) {
          for (let i = 0; i <= numberPassenger - 1; i++) {
            if (i === 0) {
              array.push({
                isSameOwner: true,
                prefixName:
                  jsonStr?.travelTypeCountry.toString() === 'IN' && options?.contact?.prefixName
                    ? options?.contact?.prefixName
                    : jsonStr?.travelTypeCountry.toString() === 'OUT' &&
                      options?.contact?.prefixNameEn
                    ? options?.contact?.prefixNameEn
                    : '',
                firstName:
                  jsonStr?.travelTypeCountry.toString() === 'IN' && options?.contact?.firstName
                    ? options?.contact?.firstName
                    : jsonStr?.travelTypeCountry.toString() === 'OUT' &&
                      options?.contact?.firstNameEn
                    ? options?.contact?.firstNameEn
                    : '',
                lastName:
                  jsonStr?.travelTypeCountry.toString() === 'IN' && options?.contact?.lastName
                    ? options?.contact?.lastName
                    : jsonStr?.travelTypeCountry.toString() === 'OUT' &&
                      options?.contact?.lastNameEn
                    ? options?.contact?.lastNameEn
                    : '',
                birthdate: options.contact?.birthdate || '',
                email: options.contact?.email || '',
                phoneNumber: options.contact?.phoneNumber || '',
                idCard: options.contact?.idCard || '',
                statutoryHeir: true,
                nationality:
                  jsonStr?.travelTypeCountry.toString() === 'IN'
                    ? 'thai'
                    : jsonStr?.travelTypeCountry.toString() === 'OUT'
                    ? ''
                    : '',
                beneficiary: '',
                relations: '',
              })
            } else {
              array.push({
                isSameOwner: false,
                prefixName: '',
                firstName: '',
                lastName: '',
                birthdate: '',
                email: '',
                phoneNumber: '',
                idCard: '',
                statutoryHeir: true,
                nationality:
                  jsonStr?.travelTypeCountry.toString() === 'IN'
                    ? 'thai'
                    : jsonStr?.travelTypeCountry.toString() === 'OUT'
                    ? ''
                    : '',
                beneficiary: '',
                relations: '',
              })
            }
          }
        }
        return {
          countryId: jsonStr.countryId.toString(),
          travelCountryFrom: jsonStr.src.toString(),
          travelCountryTo: jsonStr.dsc.toString(),
          travelDateFrom: jsonStr.travelDateFrom.toString(),
          travelDateTo: jsonStr.travelDateTo.toString(),
          travelPassenger: jsonStr.travelPassenger.toString(),
          travelType: jsonStr.travelType.toString(),
          travelTypeCountry: jsonStr.travelTypeCountry.toString(),
          travelBy: jsonStr.vehicleType.toString(),
          orderPolicyBeneficiary: array,
        }
      }
      return {
        countryId: '',
        travelCountryFrom: '',
        travelCountryTo: '',
        travelDateFrom: '',
        travelDateTo: '',
        travelPassenger: '',
        travelType: '',
        travelTypeCountry: '',
        travelBy: '',
        orderPolicyBeneficiary: array,
      }
    }, // eslint-disable-next-line
    [jsonStr?.travelPassenger, options?.contact?.id],
  )

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      {(loading.options || loading.search || loading.submit) && <Loader />}
      <DropleadContainer>
        <InsuranceDetail data={insuranceData} />
        <Formik
          enableReinitialize
          initialValues={{
            type: 'update',
            order: {
              name: insurancesById?.productAbb ? insurancesById.productAbb : '',
              taDetails: {
                ...defaultTaDetails,
                policyForm: 'online',
                outboundFlightNumber: '',
                returnFlightNumber: '',
                productDetails: {
                  ...insurancesById,
                },
                policyAddress: {
                  aaaaaa: 'ระบุเอง',
                  housingNo: '',
                  villageNo: '',
                  building: '',
                  lane: '',
                  road: '',
                  subDistrict: '',
                  district: '',
                  province: '',
                  postcode: '',
                },
              },
            },
          }}
          validationSchema={DropleadSchema}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              type: 'droplead',
            }
            // console.log('modifiedValues', modifiedValues)
            dispatch(
              createOrder(id, modifiedValues, () => {
                history.push('/opportunities')
              }),
            )
          }}
        >
          {(props) => {
            return (
              <DropleadForm
                id={id}
                handleSubmit={props.handleSubmit}
                values={props.values}
                options={options}
                optionsByTypeTa={optionsByTypeTa}
                onSaving={onSaving}
              />
            )
          }}
        </Formik>
      </DropleadContainer>
    </Container>
  )
}

export default TravelDropleadProduct
