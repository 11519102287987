import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useHistory } from 'routes'
import Api from 'api'

import EventForm from 'components/Form/EventForm'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

import HistoryType from 'types/HistoryType'
import SetStateType from 'types/SetStateType'
import Icon from 'components/Icon'
import WebTour from './WebTour'
type EventType = {
  id?: number
  title: string
  startDate: string
  endDate: string
  colorCode: string
  allDay: boolean
  hasNotEndDate: boolean
  repeatSchedule: string
  location: string
  description: string
  typeAlert: string
  eventRelates: object[]
}
const defaultProps: EventType = {
  title: '',
  startDate: '',
  endDate: '',
  colorCode: '#1BC47D',
  repeatSchedule: 'none',
  allDay: false,
  hasNotEndDate: false,
  location: '',
  description: '',
  typeAlert: 'not_alert',
  eventRelates: [],
}
const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 10px;
  }
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>สร้างนัดหมายใหม่</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const createEvent = async (newEvent: EventType, history: HistoryType) => {
  try {
    const response = await Api.post('/events/', newEvent)
    history.push('/calendar')
    return response
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

const Event: FC = (props) => {
  let history: HistoryType = useHistory()
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/calendar', label: 'Calendar' },
    { label: 'สร้างนัดหมายใหม่' },
  ]
  const [isShowHint, setIsShowHint] = useState<boolean>(false)

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['calendar']['create'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({ ...storage, calendar: { ...storage.calendar, create: true } }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: true },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <CustomForm>
        <Formik
          initialValues={defaultProps}
          onSubmit={(values) => {
            createEvent(values, history)
          }}
        >
          {(props) => (
            <EventForm handleSubmit={props.handleSubmit} isSubmitting={props.isSubmitting} />
          )}
        </Formik>
      </CustomForm>
    </Container>
  )
}
export default Event
