import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Contacts from 'pages/contacts/root'
import ContactsNew from 'pages/contacts/new'
import ContactsDetail from 'pages/contacts/_id'
import ContactsEdit from 'pages/contacts/edit'
import InsurancePortfolio from 'pages/insurance_portfolio'
import Planning from 'pages/planning'
import InterestedDetail from './_id/InterestedDetail'
import InterestedEdit from './_id/InterestedEdit'
import AssetDetail from './_id/AssetDetail'
import EditAsset from './_id/EditAsset'
import AddAsset from './_id/AddAsset'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/contacts" component={Contacts} exact />
    <ProtectedRoute path="/contacts/new" component={ContactsNew} exact />
    <ProtectedRoute path="/contacts/:id" component={ContactsDetail} exact />
    <ProtectedRoute path="/contacts/:id/edit" component={ContactsEdit} exact />
    <ProtectedRoute path="/contacts/:id/interested/edit" component={InterestedEdit} />
    <ProtectedRoute path="/contacts/:id/interested" component={InterestedDetail} />
    <ProtectedRoute path="/contacts/:id/asset/new" component={AddAsset} />
    <ProtectedRoute path="/contacts/:id/asset/:assetId" component={EditAsset} />
    <ProtectedRoute path="/contacts/:id/asset" component={AssetDetail} />
    <ProtectedRoute path="/contacts/:id/insurance_portfolio" component={InsurancePortfolio} />
    <ProtectedRoute path="/contacts/:id/planning" component={Planning} />
  </Switch>
)

export default Pages
