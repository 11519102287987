import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Calendar from 'pages/calendar/root'
import EventNew from 'pages/calendar/new'
import EventDetail from 'pages/calendar/_id'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/calendar/new" component={EventNew} />
    <ProtectedRoute path="/calendar/:id" component={EventDetail} />
    <ProtectedRoute path="/calendar" component={Calendar} />
  </Switch>
)

export default Pages
