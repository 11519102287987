import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useParams } from 'routes'
import Icon from 'components/Icon'
import { Field, Form, Formik } from 'formik'
import SelectInput from 'pages/planning/share/Select'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  createHealthCareProtections,
  getHealthCareProtectionOptions,
  getHealthCareProtections,
} from 'store/planningSlice'
import SuccessModal from 'components/SuccessModal'
import Currency from 'pages/planning/share/Currency'
import { getContactById } from 'store/contactSlice'

type useParamsType = { id: string }

const Container = styled.div`
  align-items: center;
  width: 100%;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const HeaderContainer = styled.div`
  height: 84px;
  background: #eff1f5;
  padding-left: 122.96px;
  padding-right: 121.96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #393d43;
`
const RightIcon = styled(Icon)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 11px;
  svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const LeftIcon = styled(RightIcon)`
  transform-origin: center;
  transform: rotate(180deg);
`
const ButtonArrow = styled.div`
  cursor: pointer;
`
const BodyContainer = styled(Form)`
  padding-left: 25%;
  padding-right: 16.5%;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
`
const SubmitButton = styled.button`
  width: 122px;
  height: 48px;
  align-self: center;
`
const EmptryContainer = styled.div`
  width: 34px;
`
const TextTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  text-align: start;
  color: #393d43;
  margin-bottom: 24px;
`

const Pages: FC = (props) => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const healthData = useSelector((state: RootState) => state.plans.healthData)
  const options = useSelector((state: RootState) => state.plans.options)
  const [step, setStep] = useState<number>(0)
  const [isPopupSuccess, setIsPopupSuccess] = useState<boolean>(false)
  const stepList = useMemo(() => ['ความต้องการในการรักษาพยาบาล'], [])
  const nextStep = useCallback(() => {
    step + 1 < stepList.length && setStep(step + 1)
  }, [step, stepList.length])
  const backStep = useCallback(() => {
    step - 1 >= 0 && setStep(step - 1)
  }, [step])
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    {
      path: { pathname: `/contacts/${id}/planning`, state: 'planning' },
      label: 'วางแผนประกัน',
    },
    { label: 'วางแผนคุ้มครองค่าใช้จ่ายสำหรับการรักษาพยาบาล' },
  ]
  useEffect(() => {
    dispatch(getHealthCareProtections(parseInt(id)))
    dispatch(getHealthCareProtectionOptions(parseInt(id)))
    dispatch(getContactById(id))
  }, [dispatch, id])
  return (
    <Container>
      <Header>วางแผนประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <HeaderContainer>
        {step !== 0 && (
          <ButtonArrow onClick={backStep}>
            <LeftIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === 0 && <EmptryContainer />}
        <TextHeader>วางแผนคุ้มครองค่าใช้จ่ายสำหรับการรักษาพยาบาล</TextHeader>
        {step !== stepList.length - 1 && (
          <ButtonArrow onClick={nextStep}>
            <RightIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === stepList.length - 1 && <EmptryContainer />}
      </HeaderContainer>
      <Formik
        initialValues={{ healthData }}
        enableReinitialize
        onSubmit={(values) => {
          dispatch(
            createHealthCareProtections(parseInt(id), values.healthData.healthcareProtections),
          )
          setIsPopupSuccess(true)
        }}
      >
        {(props) => (
          <BodyContainer onSubmit={props.handleSubmit}>
            <SuccessModal
              isOpen={isPopupSuccess}
              onCancel={() => {
                setIsPopupSuccess(false)
              }}
              onConfirm={() => {
                setIsPopupSuccess(false)
              }}
            ></SuccessModal>
            <TextTitle>{stepList[step]}</TextTitle>
            <Field
              name="healthData.healthcareProtections.hospitalName"
              component={SelectInput}
              caption="โรงพยาบาลที่รักษาเป็นประจำ"
              placeholder="โปรดระบุ"
              options={options.hospitalNameOptions || []}
            />
            <Field
              name="healthData.healthcareProtections.roomType"
              component={SelectInput}
              caption="ประเภทห้องพักรักษาตัว"
              placeholder="โปรดระบุ"
              options={options.roomTypeOptions || []}
            />
            <Field
              name="healthData.healthcareProtections.admissionNumber"
              component={Currency}
              caption="จำนวนครั้งที่เข้ารับการรักษาแบบผู้ป่วยนอกเฉลี่ยต่อปี"
              unit="ครั้ง"
              placeholder="โปรดระบุ"
              onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
            />
            <SubmitButton type="submit">บันทึก</SubmitButton>
          </BodyContainer>
        )}
      </Formik>
    </Container>
  )
}

export default Pages
