import React, { FC, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'routes'

import Avatar from 'components/Avatar'
import Notification from 'components/Notification'
import Banner from 'assets/images/agent_portal.png'
import { DropdownList, DropdownLink } from 'components/Dropdown'

import authToken from 'utils/session'
import HistoryType from 'types/HistoryType'
import { setClearAgent } from 'store/appSlice'
import { useDispatch } from 'react-redux'
import { HeaderProps } from './Header.type'
import {
  Container,
  LinkTabs,
  Image,
  UserContainer,
  ContainerRight,
  CustomDropdown,
  ArrowTop,
  Line,
  UserWrapper,
  UserName,
  CustomIcon,
} from './Header.styles'

const Header: FC<HeaderProps> = (props) => {
  const { currentAgent } = props
  let status: boolean = authToken.isAuthTokenPresent()
  let history: HistoryType = useHistory()
  const dispacth = useDispatch()
  const location = useLocation()
  const pathName = location.pathname.split('/')[1]

  const logout = useCallback(async () => {
    authToken.removeAuthToken()
    authToken.removeAuthTqmLifeToken()
    localStorage.removeItem('isFirstSignIn')
    dispacth(setClearAgent())
    history.push('/landing')
  }, [history, setClearAgent])

  return (
    <Container>
      <LinkTabs to="/">
        <Image alt="agentProtal" src={Banner} />
      </LinkTabs>
      <UserContainer id="header__image_user">
        {status ? (
          <ContainerRight>
            <Notification />
            <CustomDropdown
              content={
                <>
                  <ArrowTop />
                  <Line />
                  {['affiliate', 'tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && (
                    <div className="main">
                      <div className="column">
                        <div className="row">
                          <div className="position">
                            <p>ตำแหน่ง</p>&nbsp;
                            <div className="agentTypeTh">
                              {(currentAgent?.agentTypeTh || []).map((test) => (
                                <p>: {test}</p>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <p> สถานะ : {currentAgent?.statusTh}</p>
                          <b className="codeNumber">
                            {currentAgent?.agentType === 'affiliate'
                              ? currentAgent?.agentKey
                              : currentAgent?.agentCode || '-'}
                          </b>
                        </div>
                      </div>
                    </div>
                  )}
                  {['affiliate', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/profile">
                      <DropdownList>ข้อมูลส่วนตัว</DropdownList>
                    </DropdownLink>
                  )}
                  {['guest'].includes(currentAgent?.agentType || '') &&
                  ![
                    'ปฏิเสธชั่วคราว (1 ปี)',
                    'ปฏิเสธถาวร (Blacklist)',
                    'เก็บประวัติการสมัคร',
                    null,
                    '',
                  ].includes(currentAgent?.agentStatus || '') ? (
                    <DropdownLink to="/profile/documents">
                      <DropdownList>เอกสารตัวแทน</DropdownList>
                    </DropdownLink>
                  ) : ['affiliate', 'broker'].includes(currentAgent?.agentType || '') ? (
                    <DropdownLink to="/profile/documents">
                      <DropdownList>เอกสารตัวแทน</DropdownList>
                    </DropdownLink>
                  ) : (
                    ['guest'].includes(currentAgent?.agentType || '') && (
                      <DropdownLink to="/profile/recruitment">
                        <DropdownList>สมัครตัวแทน</DropdownList>
                      </DropdownLink>
                    )
                  )}
                  {['affiliate', 'tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/commission">
                      <DropdownList>รายการค่าตอบแทน</DropdownList>
                    </DropdownLink>
                  )}
                  {['affiliate', 'tqm_staff', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/profile/diagram">
                      <DropdownList>แผนผังสายงาน</DropdownList>
                    </DropdownLink>
                  )}
                  {['guest', 'affiliate', 'broker'].includes(currentAgent?.agentType || '') && (
                    <DropdownLink to="/setting">
                      <DropdownList>ตั้งค่าบัญชี</DropdownList>
                    </DropdownLink>
                  )}
                  <DropdownList onClick={logout}>
                    <b>ออกจากระบบ</b>
                  </DropdownList>
                </>
              }
            >
              <UserWrapper>
                <Avatar avatar={currentAgent?.avatar} width="36px" height="36px" type="circle" />
                <UserName>{`${currentAgent?.firstName || ''} ${
                  currentAgent?.lastName || ''
                }`}</UserName>
              </UserWrapper>
            </CustomDropdown>
          </ContainerRight>
        ) : (
          pathName === 'landing' && (
            <div className="anonymous">
              <Link to="/login" className="ghost login">
                เข้าสู่ระบบ
              </Link>
              <Link to="/register" className="ghost register">
                <span>ลงทะเบียน</span>
                <CustomIcon name="Arrow_dashboard" />
              </Link>
            </div>
          )
        )}
      </UserContainer>
    </Container>
  )
}

const defaultProps: HeaderProps = {
  currentAgent: null,
}
Header.defaultProps = defaultProps

export default Header
