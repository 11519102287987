import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Tasks from 'pages/tasks/root'
import TasksNew from 'pages/tasks/new'
import TaskDetail from 'pages/tasks/_id'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/tasks/new/:contactId" component={TasksNew} />
    <ProtectedRoute path="/tasks/new" component={TasksNew} />
    <ProtectedRoute path="/tasks/:id" component={TaskDetail} />
    <ProtectedRoute path="/tasks" component={Tasks} />
  </Switch>
)

export default Pages
