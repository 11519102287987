import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .labelArrow.bottom {
    top: unset;
    left: 16px;
    bottom: -8px;
    border-radius: 0px 4px 0px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`

const CustomButton = styled.button`
  &.processingBtn {
    background: var(--agt-light-sky-color);
    border-right: 1px solid var(--agt-secondary-color-1) !important;
    border: 1px solid var(--agt-primary-color-1);
    color: var(--agt-primary-color-1);
    border-radius: 4px 0px 0px 4px;
  }
  &.completedBtn {
    background: #ffffff;
    border-left: 1px solid var(--agt-secondary-color-1) !important;
    border: 1px solid var(--agt-secondary-color-1);
    color: var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
  }
`
const CustomTable = styled.div`
  max-width: 1120px;
  width: calc(100vw * 0.93);
  display: flex;
  justify-content: space-between;
  .card {
    width: 23.5%;
  }
  .card .header {
    background-color: var(--agt-primary-color-1);
    width: 100%;
    height: 32px;
    color: var(--agt-white-color);
    padding: 4px 8px;
  }
  .card .create {
    width: 100%;
    height: 52px;
    padding: 8px 12px 8px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--agt-disable-color-1);
  }
  .card .create .createBtn {
    width: 100px;
    height: 36px;
    border-radius: 5px;
  }
  .card .body {
    width: 100%;
    height: 476px;
    background-color: var(--agt-background-color-1);
    padding: 6px 12px;
  }
  .card .body .detail {
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 4px;
    padding: 8px 4px 4px;
    background-color: var(--agt-white-color);
  }
  .card .body .detail .topic {
    display: flex;
    justify-content: space-between;
    padding: 0px 4px;
    margin-bottom: 6px;
  }
  .card .body .detail .name {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .card .body .detail .name .contactName {
    color: var(--agt-primary-color-1);
  }
  .card .body .detail .updateAt {
    display: block;
    text-align: right;
    padding: 6px 0px;
    color: var(--agt-secondary-text-color);
  }
  .card .body .detail .see {
    height: 32px;
    display: flex;
    background-color: var(--agt-light-sky-color);
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    column-gap: 8px;
    color: var(--agt-primary-color-1);
  }
  .card .body .detail .price {
    display: flex;
    padding: 12px 4px 0px 4px;
    column-gap: 9px;
    align-items: center;
  }
  .card .body .detail .price b {
    color: var(--agt-primary-color-1);
  }
  .card .body .detail .status {
    display: flex;
    justify-content: space-between;
    color: var(--agt-secondary-text-color);
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .card .body .detail .affiliate {
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .card .body .detail .affiliate .affiliateName {
    text-align: center;
    margin: 10px 0 6px 0;
    font-size: 13px;
    border-radius: 4px;
    background-color: var(--agt-background-color-1);
  }
`
const NoteIcon = styled(Icon)`
  height: 24px;
  div {
    height: 24px;
  }
  svg {
    height: 24px;
  }
`
const EmptyCard = ({ title }) => {
  return (
    <div className="card">
      <div className="header">{title}</div>
      <div className="create">
        <button type="button" className="secondary createBtn">
          + สร้าง
        </button>
        <b>฿ 0</b>
      </div>
      <div className="body"></div>
    </div>
  )
}

const DetailCard = ({ title, price, content }) => {
  return (
    <div className="card">
      <div className="header">{title}</div>
      <div className="create">
        <button type="button" className="secondary createBtn">
          + สร้าง
        </button>
        <b>{price}</b>
      </div>
      <div className="body">{content}</div>
    </div>
  )
}

const WebTour = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 17
  const layout = [
    {
      top: '64px',
      right: 'unset',
      width: '278px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '289px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '282px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '218px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '241px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '243px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '256px',
      arrow: 'top',
    },
    {
      top: '-186px',
      right: 'unset',
      width: '283px',
      arrow: 'bottom',
    },
    {
      top: '32px',
      right: '796px',
      width: '200px',
      arrow: 'left',
    },
    {
      top: '32px',
      right: '104px',
      width: '232px',
      arrow: 'right',
    },
    {
      top: '84px',
      right: '617px',
      width: '217px',
      arrow: 'left',
    },
    {
      top: '195px',
      right: '646px',
      width: '213px',
      arrow: 'left',
    },
    {
      top: '90px',
      right: '553px',
      width: '304px',
      arrow: 'left',
    },
    {
      top: '90px',
      right: '252px',
      width: '206px',
      arrow: 'right',
    },
    {
      top: '168px',
      right: '251px',
      width: '206px',
      arrow: 'right',
    },
    {
      top: '228px',
      right: '263px',
      width: '261px',
      arrow: 'right',
    },
    {
      top: '262px',
      right: '263px',
      width: '210px',
      arrow: 'right',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [switchPagePosition, setSwitchPagePosition] = useState({
    top: '0',
    left: '0',
  })
  const [statusButtonPosition, setStatusButtonPosition] = useState({
    top: '0',
    left: '0',
  })
  const [sortPagePosition, setSortPagePosition] = useState({
    top: '0',
    left: '0',
  })
  const [ascButtonPotition, setAscButtonPotition] = useState({
    top: '0',
    left: '0',
  })
  const [filterPotition, setFilterPotition] = useState({
    top: '0',
    left: '0',
  })
  const [tablePosiotion, setTablePotition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('opportunity__switch-page', setSwitchPagePosition)
      getPositionById('opportunity__status-button', setStatusButtonPosition)
      getPositionById('opportunity__sort-page', setSortPagePosition)
      getPositionById('opportunity__asc-button', setAscButtonPotition)
      getPositionById('opportunity__filter-button', setFilterPotition)
      getPositionById('opportunity__table', setTablePotition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงชื่อพนักงานขายหรือผู้แนะนำ
            <br />
            ของคำสั่งซื้อนี้เมื่อมีข้อมูล
          </small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>คลิกเพื่อสลับมุมมองตามสถานะโอกาสในการขาย</small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            คลิกมุมมองกำลังดำเนินการเพื่อดูโอกาสในการขาย
            <br />
            ที่กำลังดำเนินการและยังไม่เสร็จสิ้น
          </small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            คลิกมุมมองสถานะการขายเพื่อดูโอกาสในการขาย
            <br />
            ที่ดำเนินการเสร็จสิ้น
          </small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            คลิกเพื่อเรียงข้อมูลโอกาสในการขาย
            <br />
            ตามวันที่อัปเดต
          </small>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>คลิกใหม่ที่สุดเพื่อเรียงจากวันที่ล่าสุดก่อน</small>
        </div>
      )
    } else if (step === 6) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>คลิกเก่าที่สุดเพื่อเรียงจากวันที่เก่าสุดก่อน</small>
        </div>
      )
    } else if (step === 7) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            คลิกเพื่อเปิดตัวกรองข้อมูลโอกาสในการขาย
            <br />
            ตามมุมมองที่ดูอยู่
          </small>
        </div>
      )
    } else if (step === 8) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงกระดานโอกาสในการขายตามสถานะต่างๆ
            <br />
            ได้แก่ สถานะติดต่อได้แล้ว นัดหมายเก็บข้อมูลแล้ว
            <br />
            เสนอแล้วและรอการตัดสินใจ และแจ้งงานแล้ว
          </small>
        </div>
      )
    } else if (step === 9) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>คลิกเพื่อสร้างโอกาสในการขาย</small>
        </div>
      )
    } else if (step === 10) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงยอดเบี้ยรวมทั้งหมดตามคำสั่งซื้อ
            <br />
            ในแต่ละสถานะ
          </small>
        </div>
      )
    } else if (step === 11) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            ตัวอย่างการ์ดโอกาสในการขาย
            <br />
            โดยลากการ์ดเพื่อย้ายไปสถานะอื่นได้
          </small>
        </div>
      )
    } else if (step === 12) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            คลิกที่ดูรายละเอียดเพื่อแสดงข้อมูล
            <br />
            เพิ่มเติมของโอกาสในการขาย
          </small>
        </div>
      )
    } else if (step === 13) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            สามารถคลิกเพื่อเลือกสถานะเป็นขายไม่สำเร็จ
            <br />
            หรือเลือกลบโอกาสในการขาย สำหรับโอกาสในการขาย
            <br />
            ที่ไม่มีคำสั่งซื้อเท่านั้น
          </small>
        </div>
      )
    } else if (step === 14) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            ตัวอย่างการ์ดโอกาสในการขาย
            <br />
            ที่มีคำสั่งซื้อ
          </small>
        </div>
      )
    } else if (step === 15) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>
            แสดงยอดเบี้ยรวมทุกคำสั่งซื้อ
            <br />
            ของโอกาสในการขายนี้
          </small>
        </div>
      )
    } else if (step === 16) {
      return (
        <div className="top">
          <b>โอกาสในการขาย</b>
          <small>แสดงชื่อแบบประกัน สถานะ และรหัสคำสั่งซื้อ</small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <>
          <CustomButton className="processingBtn" type="button">
            กำลังดำเนินการ
          </CustomButton>
          <CustomButton className="completedBtn" type="button">
            สถานะการขาย
          </CustomButton>
        </>
      )
    } else if (step === 2) {
      return (
        <CustomButton className="processingBtn" type="button">
          กำลังดำเนินการ
        </CustomButton>
      )
    } else if (step === 3) {
      return (
        <CustomButton className="completedBtn" type="button">
          สถานะการขาย
        </CustomButton>
      )
    } else if (step === 4) {
      return (
        <>
          <CustomButton className="processingBtn" type="button">
            ใหม่ที่สุด
          </CustomButton>
          <CustomButton className="completedBtn" type="button">
            เก่าที่สุด
          </CustomButton>
        </>
      )
    } else if (step === 5) {
      return (
        <CustomButton className="processingBtn" type="button">
          ใหม่ที่สุด
        </CustomButton>
      )
    } else if (step === 6) {
      return (
        <CustomButton className="completedBtn" type="button">
          เก่าที่สุด
        </CustomButton>
      )
    } else if (step === 7) {
      return (
        <CustomButton className="secondary" type="button">
          ปรับตัวเลือก
        </CustomButton>
      )
    } else if (
      step === 8 ||
      step === 9 ||
      step === 10 ||
      step === 11 ||
      step === 12 ||
      step === 13 ||
      step === 14 ||
      step === 15 ||
      step === 16 ||
      step === 17
    ) {
      return (
        <CustomTable>
          <DetailCard
            title="ติดต่อได้แล้ว"
            price="฿ 0"
            content={
              <div className="detail">
                <div className="topic">
                  <div>ลูกค้าใหม่จากการออกบูธ</div>
                  <Icon name="dot_option" />
                </div>
                <div className="name">
                  <Avatar
                    avatar="https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png"
                    width="32px"
                    height="32px"
                    type="circle"
                  />
                  <div className="contactName">โรแลนด์ แบงส์ (โรดี้)</div>
                </div>
                <small className="updateAt">วันที่อัปเดต 12/03/2564</small>
                <div className="see">
                  <NoteIcon name="note_opps" color={'var(--agt-primary-color-1)'} />
                  <small>ดูรายละเอียด</small>
                </div>
              </div>
            }
          />
          <EmptyCard title="นัดหมายเก็บข้อมูลแล้ว" />
          <EmptyCard title="เสนอแล้วรอการตัดสินใจ" />
          <DetailCard
            title="แจ้งงานแล้ว"
            price="฿ 152,000"
            content={
              <div className="detail">
                <div className="topic">ลีดจากบริษัท</div>
                <div className="name">
                  <Avatar
                    avatar="https://api-dev.agent-tools.tqld.xyz/images/avatar_agent.png"
                    width="32px"
                    height="32px"
                    type="circle"
                  />
                  <div className="contactName">โรแลนด์ แบงส์ (โรดี้)</div>
                </div>
                <div className="price">
                  <Icon name="bag" />
                  <b>฿ 152,000</b>
                </div>
                <small>Saving Super Four 10/4</small>
                <div className="status">
                  <small>รอแนบเอกสาร</small>
                  <small># 665432</small>
                </div>
                <div className="affiliate">
                  <div className="affiliateName">แสดงชื่อผู้รับผิดชอบคำสั่งซื้อ</div>
                </div>
                <small className="updateAt">วันที่อัปเดต 12/03/2564</small>
                <div className="see">
                  <NoteIcon name="note_opps" color={'var(--agt-primary-color-1)'} />
                  <small>ดูรายละเอียด</small>
                </div>
              </div>
            }
          />
        </CustomTable>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1 || step === 2) {
      return switchPagePosition
    } else if (step === 3) {
      return statusButtonPosition
    } else if (step === 4 || step === 5) {
      return sortPagePosition
    } else if (step === 6) {
      return ascButtonPotition
    } else if (step === 7) {
      return filterPotition
    } else if (
      step === 8 ||
      step === 9 ||
      step === 10 ||
      step === 11 ||
      step === 12 ||
      step === 13 ||
      step === 14 ||
      step === 15 ||
      step === 16 ||
      step === 17
    ) {
      return tablePosiotion
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTour
