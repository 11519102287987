import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getTqmOfficeAddressesGuest } from 'store/profileSlice'
import { BranchProps, SortSelector } from './Branches.type'
import {
  Container,
  BranchContainer,
  Branch,
  OfficeBranch,
  BranchContent,
  BranchName,
  BranchDetail,
  Input,
  IconSearch,
  IconMapPin,
  IconStyle,
} from './Branches.styles'
import { searchOptions } from './Branches.utils'
import { SelectorComponent } from './Branches.components'

const Branches: FC = () => {
  const dispatch = useDispatch()
  const [branch, setBranch] = useState<Array<BranchProps>>([])
  const [sortBy, setSorting] = useState<SortSelector>(searchOptions[0])
  const [search, setDataSearch] = useState<string>('')
  let branchFilter = branch.filter((key) =>
    sortBy.label === 'ค้นหาทั้งหมด'
      ? new RegExp(search).test(key.addressSecond) || new RegExp(search).test(key.branchName)
      : (sortBy.label === 'สาขา' && new RegExp(search).test(key.branchName)) ||
        (sortBy.label === 'จังหวัด' && new RegExp(search).test(key.addressSecond)),
  )

  useEffect(() => {
    dispatch(
      getTqmOfficeAddressesGuest((branch) => {
        setBranch(branch)
      }),
    )
  }, [dispatch])

  return (
    <Container>
      <BranchContainer>
        <Branch>
          <div className="Header">สาขา TQM</div>
          <SelectorComponent sortBy={sortBy} setSorting={setSorting} />
          <Input
            type="text"
            placeholder={sortBy.value}
            onChange={(event) => setDataSearch(event.target.value)}
          />
          <div className="Searching">
            <IconSearch name="search" />
          </div>
        </Branch>
        <OfficeBranch>
          {branchFilter.map((v, key) => (
            <BranchContent key={key}>
              <BranchName>
                <IconMapPin name="map_pin" color={'#00529a'} />
                {v.branchName}
              </BranchName>
              <BranchDetail>
                <div className="address">
                  {v.addressFirst}
                  {v.addressSecond && <p>{v.addressSecond}</p>}
                </div>

                <div>
                  <IconStyle name="clock_white" />
                  {v.openDay ? v.openDay : 'จ-ศ (ยกเว้นวันหยุดนักขัตฤกษ์) 9.00-15.00 น.'}
                </div>
                <div>
                  <IconStyle name="phone_icon" />
                  {v.phoneNumber}
                </div>
              </BranchDetail>
            </BranchContent>
          ))}
        </OfficeBranch>
      </BranchContainer>
    </Container>
  )
}

export default Branches
