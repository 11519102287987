import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Loader from './Loader'

const BackgroundLoading = () => {
  const [height, setHeight] = useState<string>('100vh')
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
  }, [])
  return (
    <Container height={height}>
      <div>
        <Loader />
      </div>
    </Container>
  )
}

export default BackgroundLoading

const Container = styled.div<{ height: string }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: ${(props) => (props.height ? props.height : '100vh')};
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s;
  > div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
