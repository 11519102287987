import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'routes'
import { ErrorMessage, Field, Form, Formik, getIn } from 'formik'
import GenderComponent from '../share/Gender'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import moment from 'moment'
import { getAge } from 'utils/helper'
import { getContactById } from 'store/contactSlice'
import { getPersonalInformation, createPersonalInformation } from 'store/planningSlice'
import NumberFormat from 'react-number-format'
import { go2Top } from 'utils/helper'

type useParamsType = { id: string }

const Container = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  padding-right: 9.52%;
  padding-left: 9.52%;
`
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
`
const SubmitButton = styled.button`
  width: 122px;
  height: 48px;
  align-self: center;
`
const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
`
const Caption = styled.div`
  display: flex;
  color: #393d43;
  width: 55%;
`
const InputAndErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const Unit = styled.div`
  display: flex;
  color: #393d43;
  padding-left: 24px;
  width: 15%;
`
const StylesError = styled.div`
  display: flex;
  align-self: right;
  font-size: 13px;
  color: var(--agt-error-color);
  width: 100%;
`
function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      border: '1px solid #f45a49',
    }
  }
}
const StylesNumberFormat = styled(NumberFormat)`
  padding-left: 16px;
  background: #ffffff;
  border: '1px solid #a0a8bc !important';
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 214px;
  position: relative;
  flex-direction: column;
  color: #393d43;
  background: ${(props) => (props.disabled ? '#EFF1F5' : '')};
  ::placeholder {
    color: #acb1bf;
  }
`

const CustomInputComponent = ({ field, form: { touched, errors }, ...props }) => {
  const { min = 0, max = 99 } = props
  return (
    <MainLayout>
      <Caption>{props.caption}</Caption>
      <InputAndErrorContainer>
        <StylesNumberFormat
          isNumericString={true}
          thousandSeparator={true}
          placeholder="โปรดระบุ"
          {...field}
          {...props}
          style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
          decimalScale={2}
          isAllowed={(values) => {
            const { floatValue } = values
            if (!floatValue) {
              return true
            } else {
              return floatValue >= min && floatValue <= max
            }
          }}
        />
        <ErrorMessage component={StylesError} name={field.name} />
      </InputAndErrorContainer>
      <Unit>{props.unit}</Unit>
    </MainLayout>
  )
}
const Pages: FC = (mainProps) => {
  const dispatch = useDispatch()
  const planData = useSelector((state: RootState) => state.plans.plan)
  const contactData: any = useSelector(
    (state: RootState) =>
      state.contacts.contact || {
        id: '',
        nickname: '',
        phoneNumber: '',
        prefixName: '',
        firstName: '',
        lastName: '',
        gender: '',
        birthdate: '',
      },
  )
  const { id } = useParams<useParamsType>()
  const history = useHistory()
  const path = `/contacts/${id}/planning`
  const toFinancialInfo = { pathname: path, state: 'financial_info' }
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    go2Top()
    dispatch(getPersonalInformation(id))
    dispatch(getContactById(id))
    setLoading(true)
  }, [id, dispatch])

  const initialValues = useMemo(() => {
    return {
      incomePerMonth: '',
      expensePerMonth: '',
      incomeGrowthRate: '3',
      taxRate: '0',
      isEmployee: true,
      socialInsuranceInsuredType: 'ม.33',
      socialInsuranceNumberOfMonthContribution: '0',
      employeeSavingRate: '0',
      inflationRate: '2',
      healthcareInflationRate: '3',
      educationInflationRate: '5',
      investmentRisk: '0.05',
      ...planData,
      gender: contactData?.gender,
      age: getAge(moment(contactData?.birthdate)),
    }
  }, [planData, contactData])
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(object) => {
        if (initialValues.gender && initialValues.age) {
          dispatch(createPersonalInformation(id, object))
          history.push(toFinancialInfo)
        } else {
          // pass
        }
      }}
    >
      {(props) => (
        <Container onSubmit={props.handleSubmit}>
          {!loading ? (
            <>
              <div>Loading...</div>
            </>
          ) : (
            <>
              <Text>ข้อมูลส่วนตัว</Text>
              <Field name="gender" component={GenderComponent} caption="เพศ" disabled />
              <Field
                name="age"
                component={CustomInputComponent}
                caption="อายุ"
                onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                validate={validator('กรุณาระบุอายุ')}
                disabled
              />
              <Field
                name="retireAge"
                component={CustomInputComponent}
                caption="คาดว่าจะเกษียณเมื่ออายุ"
                unit="ปี"
                validate={validator('กรุณาระบุอายุคาดว่าจะเกษียณ')}
                onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              />
              <Field
                name="expectAge"
                component={CustomInputComponent}
                caption="อายุคาดหมาย"
                unit="ปี"
                validate={validator('กรุณาระบุอายุคาดหมาย')}
                onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
              />
              <SubmitButton type="submit">บันทึก</SubmitButton>
            </>
          )}
        </Container>
      )}
    </Formik>
  )
}

export default Pages
