import AddressType from 'types/AddressType'

export const initialAddress: AddressType = {
  housingNo: '',
  villageNo: '',
  building: '',
  lane: '',
  road: '',
  subDistrict: '',
  district: '',
  province: '',
  postcode: '',
}
