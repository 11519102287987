import React, { FC, Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { ModalCustomStyle } from 'components/Modal'
import Modal from 'react-modal'
import { getReHistoriesConsentsByAgent, sendNewConsent } from 'store/consentsSlice'
import { Formik } from 'formik'
import { NewConsentModalType } from './NewConsentModal.type'
import { FormModal } from './NewConsentModal.components'

const NewConsentModal: FC<NewConsentModalType> = ({ currentAgent }) => {
  const dispatch = useDispatch()
  const agreement = useSelector((state: RootState) => state.consents.agreement)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [indexAgreement, setIndexAgreement] = useState<number>(0)
  const [isChecked, setIsChecked] = useState<boolean>(false)

  useEffect(() => {
    if (
      currentAgent &&
      ['guest'].includes(currentAgent.agentType) &&
      // currentAgent.isExpired === false &&
      currentAgent.newAgreements.length > 0
    )
      setIsOpen(true)
    else if (
      currentAgent &&
      ['affiliate', 'broker'].includes(currentAgent.agentType) &&
      currentAgent.newAgreements.length > 0
    )
      setIsOpen(true)
  }, [currentAgent, setIsOpen])

  useEffect(() => {
    if (isOpen && currentAgent && currentAgent.newAgreements.length > indexAgreement) {
      let agreementId: number = currentAgent.newAgreements[indexAgreement].agreementId
      dispatch(
        getReHistoriesConsentsByAgent(agreementId, () => {
          setIsOpen(false)
        }),
      )
    } else if (isOpen && currentAgent && currentAgent.newAgreements.length === indexAgreement) {
      window.location.reload()
    }
  }, [dispatch, currentAgent, indexAgreement, isOpen])

  if (currentAgent && currentAgent.newAgreements.length > 0) {
    return (
      <Fragment>
        <Modal isOpen={isOpen} style={ModalCustomStyle}>
          <Formik
            enableReinitialize
            initialValues={{
              agreementId: agreement?.id || '',
              consents: agreement?.consents || [],
            }}
            onSubmit={(values) => {
              dispatch(
                sendNewConsent(values, () => {
                  setIndexAgreement(indexAgreement + 1)
                  setIsChecked(false)
                  if (indexAgreement === currentAgent.newAgreements.length - 1) {
                    setIsOpen(false)
                  }
                }),
              )
            }}
          >
            {(props) => {
              return (
                <FormModal
                  agreement={agreement}
                  setIsChecked={setIsChecked}
                  isChecked={isChecked}
                  {...props}
                />
              )
            }}
          </Formik>
        </Modal>
      </Fragment>
    )
  }
  return null
}

export default NewConsentModal
