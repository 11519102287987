import React, { FC, useState, useEffect } from 'react'
import { ValueType } from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import { getMoreTasks, getTasks } from 'store/taskSlice'
import { RootState } from 'store/rootReducer'
import WebTour from './WebTour'
import { OptionType, FilterValues, TaskValues, IncompleteTasksType } from './index.type'
import { Container } from './index.styles'
import {
  FilterComponent,
  HeaderComponent,
  MenuComponent,
  NewTaskComponent,
  options,
} from './index.components'

const Tasks: FC = () => {
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [viewpoint, setViewpoint] = useState<string>('incomplete')
  const [isFilter, setFilter] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Array<TaskValues>>([])
  const [selectedOption, setSelectedOption] = useState<ValueType<OptionType>>(options[0])
  const [dueDate, setDueDate] = useState<string>()
  const [isindex, setindex] = useState<number>(-1)
  const [incomingWeek, setIncomingWeek] = useState<number>(0)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<FilterValues>({
    dataSearch: '',
    priorityLevel: null,
    dueDate: null,
  })
  const [tasksIncomplete, setTasksIncomplete] = useState<IncompleteTasksType>({
    tasksLated: [],
    tasksCurrentDay: [],
    tasksCurrentWeek: [],
    tasksNextWeek: [],
    tasksIncoming: [],
  })

  useEffect(() => {
    setTasks([])
    setTasksIncomplete({
      tasksLated: [],
      tasksCurrentDay: [],
      tasksCurrentWeek: [],
      tasksNextWeek: [],
      tasksIncoming: [],
    })
    dispatch(getTasks(setTasks, setTasksIncomplete, viewpoint, searchValue))
  }, [dispatch, viewpoint, searchValue])

  useEffect(() => {
    if (tasksIncomplete.tasksCurrentDay.length > 0) {
      setindex(2)
    } else if (tasksIncomplete.tasksLated.length > 0) {
      setindex(1)
    } else if (tasksIncomplete.tasksCurrentWeek.length > 0) {
      setindex(3)
    } else if (tasksIncomplete.tasksNextWeek.length > 0) {
      setindex(4)
    } else if (tasksIncomplete.tasksIncoming.length > 0) {
      setindex(5)
    }
  }, [tasksIncomplete])

  useEffect(() => {
    if (incomingWeek) dispatch(getMoreTasks(setTasksIncomplete, searchValue, incomingWeek))
    // eslint-disable-next-line
  }, [dispatch, incomingWeek])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['tasks']['tasks'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({ ...storage, tasks: { ...storage.tasks, tasks: true } }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: true, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <MenuComponent
        viewpoint={viewpoint}
        setViewpoint={setViewpoint}
        setIndex={setindex}
        setFilter={setFilter}
        isFilter={isFilter}
        currentAgent={currentAgent}
      />
      <FilterComponent
        isFilter={isFilter}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        dueDate={dueDate}
        setDueDate={setDueDate}
      />
      <NewTaskComponent
        viewpoint={viewpoint}
        tasks={tasks}
        tasksIncomplete={tasksIncomplete}
        isindex={isindex}
        setIncomingWeek={setIncomingWeek}
        incomingWeek={incomingWeek}
      />
    </Container>
  )
}

export default Tasks
