import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/Loader'
import { Formik } from 'formik'
import { get } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'routes'
import { createOrder, getActOptions, getOrder, searchAct } from 'store/actSlice'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'
import HistoryType from 'types/HistoryType'
import ActAside from './ActAside'
import DetailForm from './DetailForm'
import { BackgroundLoading, Container, Content, Form, Header } from './EditAct.styles'
import { validateSchema } from './NewAct.validation'

const EditAct: FC = () => {
  let { id, orderNo } = useParams<{ id: string; orderNo: string }>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { options, order, prbInsurance, loading } = useSelector((state: RootState) => state.act)
  const [height, setHeight] = useState<string>('100vh')

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    // { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    // { path: `/opportunities/${id}/catalog/act`, label: 'ประกัน พ.ร.บ.' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  const onSaving = (values) => {
    let params = { ...values, type: 'update' }
    delete values.vehicleDetails.carUsage
    dispatch(
      createOrder(id, params, () => {
        history.push('/opportunities')
      }),
    )
  }

  useEffect(() => {
    if (id && order) {
      dispatch(getNewOpportunityById(id))
      dispatch(
        getActOptions(id, {
          year: order?.vehicle?.year,
          makeCode: order?.vehicle?.makeCode,
          familyCode: order?.vehicle?.familyCode,
        }),
      )
      dispatch(
        searchAct(id, {
          carUsage: order?.order?.prbInsurance?.productDetails?.prbCode || '',
          companyCode: order?.order.prbInsurance?.productDetails?.companyCode || '',
        }),
      )
    }
  }, [dispatch, id, order])

  useEffect(() => {
    if (id && orderNo) dispatch(getOrder(id, orderNo))
  }, [dispatch, id, orderNo])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {(loading.options || loading.submit) && (
        <BackgroundLoading height={height}>
          <div>
            <Loader />
          </div>
        </BackgroundLoading>
      )}
      <Header>ประกัน พ.ร.บ.</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <ActAside
          prbCarType={order?.order?.prbInsurance?.productDetails?.prbCarType || ''}
          prbCode={order?.order?.prbInsurance?.productDetails?.prbCode || ''}
          companyCode={order?.order?.companyName || ''}
          options={options}
          prbInsurance={prbInsurance}
        />
        <Form>
          <b>กรอกข้อมูลผู้เอาประกันภัย</b>
          <Formik
            initialValues={{
              type: 'droplead',
              prefixName: order?.contact?.prefixName,
              firstName: order?.contact?.firstName,
              lastName: order?.contact?.lastName,
              birthdate: order?.contact?.birthdate,
              idCard: order?.contact?.idCard,
              phoneNumber: order?.contact?.phoneNumber,
              email: order?.contact?.email,
              vehicleDetails: {
                ...order?.vehicle,
                carUsage: order?.vehicle?.carUsage || '',
                vehicleChassis: order?.vehicle?.vehicleChassis?.value || '',
                vehicleLicense: order?.vehicle?.vehicleLicense?.value || '',
                vehicleProvince: order?.vehicle?.vehicleProvince?.value || '',
              },
              order: {
                ...order?.order,
                orderId: order?.order?.id,
                prbDetails: {
                  ...order?.order?.prbInsurance,
                  policyAddress: {
                    ...order?.order?.prbInsurance?.policyAddress,
                    aaaaaa: 'ระบุเอง',
                  },
                  policyDeliveryAddress: {
                    ...order?.order?.prbInsurance?.policyDeliveryAddress,
                    aaaaaa: 'ระบุเอง',
                  },
                  receiptAddress: {
                    ...order?.order?.prbInsurance?.receiptAddress,
                    aaaaaa: 'ระบุเอง',
                  },
                },
              },
            }}
            enableReinitialize
            validationSchema={validateSchema}
            onSubmit={(values) => {
              delete values.vehicleDetails.carUsage
              dispatch(
                createOrder(id, values, () => {
                  history.push('/opportunities')
                }),
              )
            }}
          >
            {(props) => <DetailForm {...props} options={options} onSaving={onSaving} isEdit />}
          </Formik>
        </Form>
      </Content>
    </Container>
  )
}
export default EditAct
