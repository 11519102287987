import React, { FC, useMemo, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById, updateContact } from 'store/contactSlice'
import {
  CheckboxGroup,
  Container,
  Form,
  Header,
  SubmitButton,
  TabsContainer,
  TabsSelector,
  Tab,
  GroupTopic,
  Checkbox,
  FieldGroup,
} from './InterestedEdit.styles'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { Formik, Field } from 'formik'
import { ContactType } from 'types/ContactType'
import { getLifeStylesOptions } from '../utils'
import HistoryType from 'types/HistoryType'
import { DistributeList, removeAvatar } from './InterestedEdit.components'

const TAB_MENU = [
  { label: 'บันเทิง', value: 'entertainment' },
  { label: 'สุขภาพ', value: 'health' },
  { label: 'ท่องเที่ยว', value: 'travel' },
  { label: 'อาหาร', value: 'food' },
]

const InterestedEdit: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  let history: HistoryType = useHistory()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      path: `/contacts/${contact?.id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: `/contacts/${contact?.id}/interested`,
      label: 'สิ่งที่ลูกค้าสนใจ',
    },
    {
      label: 'แก้ไขสิ่งที่ลูกค้าสนใจ',
    },
  ]
  const [currentTab, setCurrentTab] = useState('entertainment')

  useEffect(() => {
    if (id) dispatch(getContactById(id))
  }, [dispatch, id])

  const initialValue: ContactType = useMemo(
    () => ({
      age: contact?.age || '',
      status: contact?.status ? contact?.status : 'รอเสนองาน',
      remark: contact?.remark || '',
      note: contact?.note || '',
      firstName: contact?.firstName || '',
      avatar: contact?.avatar || '',
      lastName: contact?.lastName || '',
      gender: contact?.gender || '',
      nickname: contact?.nickname ? contact.nickname : `${contact?.firstName} ${contact?.lastName}`,
      phoneNumber: contact?.phoneNumber || '',
      email: contact?.email || '',
      name: contact?.name || '',
      birthdate: contact?.birthdate || '',
      maritalStatus: contact?.maritalStatus || '',
      prefixName: contact?.prefixName || '',
      idCard: contact?.idCard || '',
      occupation: contact?.occupation || '',
      position: contact?.position || '',
      company: contact?.company || '',
      facebook: contact?.facebook || '',
      lineId: contact?.lineId || '',
      canDeleted: contact?.canDeleted || true,
      homeAddress: {
        housingNo: contact?.homeAddress?.housingNo || '',
        villageNo: contact?.homeAddress?.villageNo || '',
        building: contact?.homeAddress?.building || '',
        lane: contact?.homeAddress?.lane || '',
        road: contact?.homeAddress?.road || '',
        subDistrict: contact?.homeAddress?.subDistrict || '',
        district: contact?.homeAddress?.district || '',
        province: contact?.homeAddress?.province || '',
        postcode: contact?.homeAddress?.postcode || '',
      },
      currentAddress: {
        housingNo: contact?.currentAddress?.housingNo || '',
        villageNo: contact?.currentAddress?.villageNo || '',
        building: contact?.currentAddress?.building || '',
        lane: contact?.currentAddress?.lane || '',
        road: contact?.currentAddress?.road || '',
        subDistrict: contact?.currentAddress?.subDistrict || '',
        district: contact?.currentAddress?.district || '',
        province: contact?.currentAddress?.province || '',
        postcode: contact?.currentAddress?.postcode || '',
      },
      officeAddress: {
        housingNo: contact?.officeAddress?.housingNo || '',
        villageNo: contact?.officeAddress?.villageNo || '',
        building: contact?.officeAddress?.building || '',
        lane: contact?.officeAddress?.lane || '',
        road: contact?.officeAddress?.road || '',
        subDistrict: contact?.officeAddress?.subDistrict || '',
        district: contact?.officeAddress?.district || '',
        province: contact?.officeAddress?.province || '',
        postcode: contact?.officeAddress?.postcode || '',
      },
      idCardAddress: {
        housingNo: contact?.idCardAddress?.housingNo || '',
        villageNo: contact?.idCardAddress?.villageNo || '',
        building: contact?.idCardAddress?.building || '',
        lane: contact?.idCardAddress?.lane || '',
        road: contact?.idCardAddress?.road || '',
        subDistrict: contact?.idCardAddress?.subDistrict || '',
        district: contact?.idCardAddress?.district || '',
        province: contact?.idCardAddress?.province || '',
        postcode: contact?.idCardAddress?.postcode || '',
      },
      entertainment: contact?.entertainment || {},
      health: contact?.health || {},
      travel: contact?.travel || {},
      food: contact?.food || {},
      isSameHomeAddress: contact?.isSameHomeAddress || false,
      isSameCurrentAddress: contact?.isSameCurrentAddress || false,
      isSameIdCardAddress: contact?.isSameIdCardAddress || false,
      currentAgreement: contact?.currentAgreement || null,
    }),
    [contact],
  )

  return (
    <Container>
      <Header>
        <h3>แก้ไขสิ่งที่ลูกค้าสนใจ</h3>
      </Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Formik
        enableReinitialize
        initialValues={initialValue}
        onSubmit={(values) => {
          const modifiedValues = {
            ...values,
            birthdate: values.birthdate ? values.birthdate.toString() : null,
          }
          removeAvatar(modifiedValues)
          dispatch(
            updateContact(id, modifiedValues, () => {
              history.push(`/contacts/${id}/interested`)
            }),
          )
        }}
      >
        {({ handleSubmit, isSubmitting }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <TabsContainer>
                <TabsSelector>
                  {TAB_MENU.map((tab, index) => (
                    <Tab
                      key={index}
                      type="button"
                      onClick={() => setCurrentTab(tab.value)}
                      active={currentTab === tab.value}
                    >
                      {tab.label}
                    </Tab>
                  ))}
                </TabsSelector>
              </TabsContainer>
              <CheckboxGroup>
                {Object.entries(getLifeStylesOptions(currentTab) || { values: {} }).map(
                  ([key, values], i) => (
                    <div key={i}>
                      <GroupTopic>{values?.label}</GroupTopic>
                      <Checkbox className="checkbox-lists">
                        {values?.fields.map(
                          ({ value, label }: { value: string; label: string }, index: number) => {
                            return (
                              <label key={index} className="list">
                                <DistributeList
                                  currentTab={currentTab}
                                  value={value}
                                  label={label}
                                  index={index}
                                  lifeStyleKey={key}
                                />
                                <p>{label}</p>
                              </label>
                            )
                          },
                        )}
                      </Checkbox>
                      <div>
                        <FieldGroup key={`${currentTab}-remark-${i}`}>
                          <Field
                            className="details"
                            component="textarea"
                            key={`${currentTab}-remark-${i}`}
                            name={`${currentTab}.${key}.remark`}
                            type="textarea"
                            placeholder="รายละเอียดเพิ่มเติม"
                          />
                        </FieldGroup>
                      </div>
                    </div>
                  ),
                )}
              </CheckboxGroup>
              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
              </SubmitButton>
            </Form>
          )
        }}
      </Formik>
    </Container>
  )
}

export default InterestedEdit
