import styled from '@emotion/styled'
import React, { useState } from 'react'
import { useHistory } from 'routes'
import moment from 'moment'
import Modal from 'components/Modal'
import HistoryType from 'types/HistoryType'
import { useDispatch } from 'react-redux'
import { confirmDelete } from 'store/eventsSlice'

type ColorType = {
  colorCode: string
}

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
`
const ButtonComponents = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`
const GroupComponents = styled.div`
  width: 100%;
  border-bottom: 1px solid #a0a8bc;
`
const TitleComponents = styled.div`
  margin-top: 40px;
`
const Participants = styled.div`
  display: flex;
`
const ParticipantsList = styled.div`
  margin: 16px 0 16px 10px;
`
const DetailComponents = styled.div`
  margin: 16px 0;
`
const ContactDetails = styled.div`
  display: flex;
  justify-content: space-between;
`
const ColorDetail = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 48px;
  width: 72px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #a0a8bc;
`
const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 48px 0;
  width: 352px;
  /* height: 164px; */
  background: #ffffff;
  border-radius: 4px;
  .topic {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
  }
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    margin: 0px;
    color: #393d43;
  }
`
const ColorCode = styled.div<ColorType>`
  width: 50px;
  height: 32px;
  background: ${(props) => (props.colorCode ? props.colorCode : 'none')};
  border-radius: 4px;
`
const DateContainer = styled.div`
  display: grid;
  grid-template-columns: 13% 20% 8% auto;
  margin-bottom: 11px;
`
const DateText = styled.div``

const DetailForm = ({ event, setIsEdit, currentAgent }) => {
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const [deleteIsClicked, setStateDeleteClicked] = useState<boolean>(false)
  return (
    <DetailContainer>
      <Modal
        isOpen={deleteIsClicked}
        onConfirm={() => {
          dispatch(
            confirmDelete(event?.id, () => {
              history.push('/calendar')
            }),
          )
        }}
        onCancel={() => setStateDeleteClicked(false)}
      >
        <DeleteModal>
          <p className="topic">ยืนยันการลบนัดหมาย</p>
          <p className="title">{event?.title}</p>
        </DeleteModal>
      </Modal>
      <GroupComponents>
        <TitleComponents>
          <b>{event.title}</b>
        </TitleComponents>
        <ContactDetails>
          <Participants>
            <DetailComponents>บุคคลที่เข้าร่วม : </DetailComponents>
            <ParticipantsList>
              {event.eventRelates.map((value, index: number) => (
                <div key={index}>{value.label}</div>
              ))}
            </ParticipantsList>
          </Participants>
          <DetailComponents>
            <ColorDetail>
              <ColorCode colorCode={event.colorCode} />
            </ColorDetail>
          </DetailComponents>
        </ContactDetails>
      </GroupComponents>
      <GroupComponents>
        <DetailComponents>
          <DateContainer>
            <DateText>เริ่ม วันที่</DateText>
            <DateText>{moment(event.startDate?.toString()).format('DD/MM/YYYY')}</DateText>
            <DateText>{event.allDay ? ' ทั้งวัน' : ' เวลา '}</DateText>
            <DateText>
              {!event.allDay &&
                moment(event.startDate?.toString()).locale('en').format('H:mm') + ' น.'}
            </DateText>
          </DateContainer>
          <DateContainer>
            <DateText>จบ วันที่</DateText>
            <DateText>{moment(event.endDate?.toString()).format('DD/MM/YYYY')}</DateText>
            <DateText>{event.allDay ? ' ทั้งวัน' : ' เวลา '}</DateText>
            <DateText>
              {!event.allDay &&
                moment(event.endDate?.toString()).locale('en').format('H:mm') + ' น.'}
            </DateText>
          </DateContainer>
          <DateContainer>
            <DateText>สถานที่</DateText>
            <DateText>{event.location}</DateText>
          </DateContainer>
        </DetailComponents>
      </GroupComponents>
      <GroupComponents>
        <DetailComponents>รายละเอียดเพิ่มเติม </DetailComponents>
        <DetailComponents style={{ whiteSpace: 'pre-line' }}> {event.description}</DetailComponents>
      </GroupComponents>
      <ButtonComponents>
        <button
          type="button"
          className="secondary"
          onClick={() => setStateDeleteClicked(true)}
          style={{ marginRight: '24px' }}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button
          type="button"
          className="secondary"
          onClick={() => setIsEdit(true)}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          แก้ไข
        </button>
      </ButtonComponents>
    </DetailContainer>
  )
}

export default DetailForm
