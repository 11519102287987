import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'
import Profile from 'pages/profile/root'
import EditProfile from 'pages/profile/edit'
import Landing from 'pages/profile/recruitment'
import Recruitment from 'pages/profile/recruitment/form'
// import Download from 'pages/profile/recruitment/download'
import NewDownload from 'pages/profile/recruitment/newDownload'
import Diagram from 'pages/profile/root/diagram'
import Documents from 'pages/documents/root'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path="/profile/recruitment/form" component={Recruitment} />
    <ProtectedRoute path="/profile/recruitment/download" component={NewDownload} />
    <ProtectedRoute path="/profile/recruitment" component={Landing} />
    <ProtectedRoute path="/profile/edit" component={EditProfile} />
    <ProtectedRoute path="/profile/diagram" component={Diagram} />
    <ProtectedRoute path="/profile/documents" component={Documents} />
    <ProtectedRoute path="/profile" component={Profile} />
  </Switch>
)

export default Pages
