import React, { FC, useMemo } from 'react'
import { useParams } from 'routes'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { InsurancePortCardType } from './InsurancePortCard.type'
import {
  Card,
  Row,
  IconStyles,
  InfoRightBottom,
  StateRejected,
  StateComplete,
  StateExpired,
  LinkName,
  InfoRight,
  InfoLeftBottom,
  ContainerBottom,
  LinkOrderNo,
  Detial,
  InfoLeft,
  AvartarContainer,
  ContainerTop,
} from './InsurancePortCard.styles'
import { formatChangeDate } from './InsurancePortCard.utils'

const InsurancePortCard: FC<InsurancePortCardType> = ({ port }) => {
  const { id } = useParams<{ id: string }>()

  const State = useMemo(() => {
    let state = port?.state || ''
    if (state === 'completed') {
      return (
        <Row className="status">
          <StateComplete>
            <label>กรมธรรม์มีผลบังคับใช้</label>
          </StateComplete>
        </Row>
      )
    } else if (state === 'rejected') {
      return (
        <Row className="status">
          <StateRejected>
            <label>กรมธรรม์ถูกยกเลิก</label>
          </StateRejected>
        </Row>
      )
    } else if (state === 'expired') {
      return (
        <Row className="status">
          <StateExpired>
            <label>กรมธรรม์หมดอายุ</label>
          </StateExpired>
        </Row>
      )
    } else return null
  }, [port.state])

  const PolicyType = useMemo(() => {
    let policyType = port?.policyType || ''
    if (policyType === 'life') {
      return (
        <Row className="policyType life">
          <Icon name="Life" /> ชีวิต
        </Row>
      )
    } else if (policyType === 'health') {
      return (
        <Row className="policyType health">
          <Icon name="health" /> สุขภาพ
        </Row>
      )
    } else if (policyType === 'motor') {
      return (
        <Row className="policyType car">
          <Icon name="car" /> รถยนต์
        </Row>
      )
    } else if (policyType === 'prb') {
      return (
        <Row className="policyType car">
          <Icon name="car" /> พ.ร.บ.
        </Row>
      )
    } else if (policyType === 'ta') {
      return (
        <Row className="policyType ta">
          <Icon name="airplane" /> เดินทาง
        </Row>
      )
    } else if (policyType === 'home') {
      return (
        <Row className="policyType home">
          <Icon name="home" /> บ้าน
        </Row>
      )
    } else if (policyType === 'accident') {
      return (
        <Row className="policyType accident">
          <Icon name="accident" /> อุบัติเหตุ
        </Row>
      )
    } else return null
  }, [port.policyType])

  const LinkOrder = useMemo(() => {
    let policyType = port?.policyType || ''
    let orderStatus = port?.orderStatus || ''
    let orderNo = port?.orderNo || ''
    let orderId = port?.orderId || ''
    let opportunityId = port?.opportunityId || ''
    if (policyType === 'life') {
      return (
        <LinkOrderNo to={`/contacts/${id}/insurance_portfolio/${port.id}/${orderNo}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'prb' && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(orderStatus)) {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/catalog/act/form/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'prb') {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/catalog/act/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'motor' && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(orderStatus)) {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/motor/droplead/edit/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'motor') {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/motor/droplead/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'ta' && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(orderStatus)) {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/travel/droplead/edit/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else if (policyType === 'ta') {
      return (
        <LinkOrderNo to={`/opportunities/${opportunityId}/travel/droplead/${orderId}`}>
          รหัสคำสั่งซื้อ #{orderNo}
        </LinkOrderNo>
      )
    } else {
      return null
    }
  }, [
    id,
    port.id,
    port.policyType,
    port.orderId,
    port.orderNo,
    port.orderStatus,
    port.opportunityId,
  ])

  return (
    <Card>
      <ContainerTop>
        <AvartarContainer>
          <Avatar avatar={port?.logoUrl || '-'} width="48px" height="48px" type="circle" />
        </AvartarContainer>
        <Detial>
          <InfoLeft>
            <Row className="name">
              <LinkName to={`/contacts/${id}/insurance_portfolio/${port.id}`}>
                <b>{port?.name || '-'}</b>
              </LinkName>
            </Row>
            <Row>{port?.ownerName || '-'}</Row>
          </InfoLeft>
          <InfoRight>
            {State}
            {PolicyType}
            {['tqm', 'tqm_life'].includes(port?.createdSource || '') && port.orderNo ? (
              <Row className="orderNo">{LinkOrder}</Row>
            ) : (
              <Row className="orderNo" style={{ opacity: 0 }}>
                รหัสคำสั่งซื้อ #123456
              </Row>
            )}
          </InfoRight>
        </Detial>
      </ContainerTop>
      <ContainerBottom>
        <InfoLeftBottom>
          <Row>
            <IconStyles name="policyNo" />
            {port?.policyNo || '-'}
          </Row>
          <Row>
            <IconStyles name="endPolicyAt" />
            {port?.policyType === 'ta' ? (
              <>
                {port?.endPolicyAt && port?.startPolicyAt
                  ? `${formatChangeDate(port.startPolicyAt)} - ${formatChangeDate(
                      port.endPolicyAt,
                    )}`
                  : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
              </>
            ) : (
              <>
                {port?.endPolicyAt
                  ? formatChangeDate(port.endPolicyAt)
                  : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
              </>
            )}
          </Row>
        </InfoLeftBottom>
        <InfoRightBottom>
          {['prb', ''].includes(port?.policyType || '') ? null : (
            <Row>
              <IconStyles name="sumInsured" />
              ทุน {port?.sumInsured ? financial(port.sumInsured) : 0}
            </Row>
          )}
          {['life'].includes(port?.policyType || '') ? (
            <Row>
              <IconStyles name="insuranceRiders" />
              อนุสัญญา {port?.insuranceRiders ? port.insuranceRiders.length : 0}
            </Row>
          ) : null}
        </InfoRightBottom>
      </ContainerBottom>
    </Card>
  )
}
export default InsurancePortCard
