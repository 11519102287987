import React, { FC, useState } from 'react'
import styled from '@emotion/styled'
import { Draggable } from 'react-beautiful-dnd'
import sumBy from 'lodash/sumBy'
import { isEmpty } from 'lodash'
import { Link } from 'routes'
import Icon from 'components/Icon'
import Modal from 'components/Modal'
import Avatar from 'components/Avatar'
import Dropdown, { DropdownList } from 'components/Dropdown'
import { currency, dateFormat } from 'utils/helper'
import { useDispatch } from 'react-redux'
import { updateStatus } from 'store/opportunitiesSlice'
import { TaskProps } from 'types/OpportunityType'
import initialConfirm, { ConfirmType } from './Task.utils'

type ContainerProps = {
  status?: string
}
type DateContentProps = {
  hasOrder?: boolean
}
type TaskNameType = {
  agtCode?: boolean
}
type OrderStatusProps = {
  status?: string
}
type DropdownSubmenuProps = {
  name: string
  contactName: string
  pipelineStateName: string
  setConfirmOpen: Function
  state: string
  canDelete?: boolean
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin-bottom: 8px;
  border: 1px solid;
  border-color: ${(props) =>
    props.status === 'rejected'
      ? 'var(--agt-error-color)'
      : props.status === 'completed'
      ? 'var(--agt-success-color)'
      : 'var(--agt-secondary-text-color)'};
  border-radius: 4px;
  transition: background-color 0.2s ease;
  background-color: var(--agt-white-color);
  .disabledLink {
    pointer-events: none;
  }
`
const TaskContent = styled.div`
  display: flex;
  justify-content: space-between;
`
const TaskName = styled.div<TaskNameType>`
  width: 180px;
  margin-bottom: 6px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${(props) => (props.agtCode ? '13px' : 'unset')};
  color: ${(props) =>
    props.agtCode ? 'var(--agt-secondary-color-1)' : 'var(--agt-primary-text-color)'};
  @media (max-width: 1120px) {
    width: 160px;
  }
`
const IconBag = styled(Icon)`
  margin-right: 8px;
`
const IconNote = styled(Icon)`
  height: 24px;
  div {
    height: 24px;
  }
  svg {
    height: 24px;
  }
`
const ContactContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`
const StyledAvatar = styled(Avatar)`
  min-width: 32px;
  margin-right: 8px;
`
const DateContent = styled.div<DateContentProps>`
  text-align: right;
  color: var(--agt-secondary-text-color);
  border-top: ${(props) => (props.hasOrder ? '' : '1px solid var(--agt-secondary-text-color)')};
  padding-top: ${(props) => (props.hasOrder ? '' : '8px')};
  margin-bottom: 6px;
`
const SeeMoreBtn = styled.button`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--agt-primary-color-1);
  background: var(--agt-light-sky-color);
  small {
    margin-left: 8px;
  }
  &:hover {
    color: var(--agt-primary-color-1);
    background: var(--agt-light-sky-color);
  }
`
const StateText = styled.span`
  color: var(--agt-error-text);
  font-weight: bold;
  .won-text {
    color: var(--agt-primary-color-1);
  }
  .fail-text {
    color: var(--agt-error-color);
  }
`
const OportunitiesName = styled.p`
  font-weight: bold;
  max-width: 255px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`
const NameInModal = styled.span`
  font-weight: bold;
  color: var(--agt-primary-color-1);
`
const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 278px;
  padding: 32px 37px 40px 37px;
  p {
    margin: 0px;
  }
  .normal-text {
    margin-bottom: 8px;
    margin-top: 24px;
  }
  .warning-text {
    margin-top: 10px;
    color: var(--agt-error-color);
  }
  span {
    p {
      margin: 0px;
    }
  }
`
const OrderContent = styled.div`
  border-top: 1px solid var(--agt-secondary-text-color);
  padding-top: 8px;
  .totalPremium {
    display: flex;
    flex-direction: row;
  }
  .totalPremium .premium {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
  .orders {
  }
  .orders .card {
    margin-bottom: 6px;
  }
  .orders .card .salesBy {
    padding-top: 8px;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--agt-secondary-color-1);
    margin-bottom: 4px;
  }
  .orders .card .salesBy .name {
    min-height: 28px;
    text-align: center;
    font-size: 13px;
    background: var(--agt-background-color-1);
    border-radius: 4px;
  }
`
const OrderStatus = styled.div<OrderStatusProps>`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid;
  white-space: nowrap;
  border-bottom-color: ${(props) =>
    props.status === 'ยกเลิก' ||
    props.status === 'พนักงานยกเลิก' ||
    props.status === 'ผู้แนะนำยกเลิก'
      ? 'var(--agt-error-color)'
      : props.status === 'กรมธรรม์อนุมัติ' || props.status === 'บริษัทประกันอนุมัติ'
      ? 'var(--agt-success-color)'
      : 'var(--agt-secondary-text-color)'};
  span {
    color: ${(props) =>
      props.status === 'ยกเลิก' ||
      props.status === 'พนักงานยกเลิก' ||
      props.status === 'ผู้แนะนำยกเลิก'
        ? 'var(--agt-error-color)'
        : props.status === 'กรมธรรม์อนุมัติ' || props.status === 'บริษัทประกันอนุมัติ'
        ? 'var(--agt-success-color)'
        : 'var(--agt-secondary-text-color)'};
  }
`
const StyledDropdown = styled(Dropdown)`
  .agt-dropdown__content-wrapper {
    width: 160px;
    right: 0;
  }
`
const DropdownIconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  :hover {
    background-color: var(--agt-background-color-1);
  }
`
const DropdownSubmenu = ({
  name,
  contactName,
  pipelineStateName,
  setConfirmOpen,
  state,
  canDelete = false,
}: DropdownSubmenuProps) => {
  return (
    <>
      {pipelineStateName === 'Implementation' && state === 'completed' && (
        <DropdownList
          onClick={() =>
            setConfirmOpen({
              isOpen: true,
              name: name,
              contactName: contactName,
              toState: 'won',
            })
          }
        >
          ขายสำเร็จ
        </DropdownList>
      )}
      {state !== 'completed' && (
        <DropdownList
          onClick={() =>
            setConfirmOpen({
              isOpen: true,
              name: name,
              contactName: contactName,
              toState: 'lose',
            })
          }
        >
          ขายไม่สำเร็จ
        </DropdownList>
      )}
      {canDelete && (
        <DropdownList
          onClick={() =>
            setConfirmOpen({
              isOpen: true,
              name: name,
              contactName: contactName,
              toState: 'delete',
            })
          }
        >
          ลบ
        </DropdownList>
      )}
    </>
  )
}
const getText = (toState: string, name: string, contactName: string, contactAvatar: string) => {
  const contactNameInfo = contactName && contactName.split(' ')
  if (toState === 'delete') {
    return (
      <ModalBody>
        <Avatar type="circle" width="100px" height="100px" avatar={contactAvatar} />
        <p className="normal-text">ลบโอกาสในการขาย</p>
        <OportunitiesName>{name}</OportunitiesName>
        <span>
          ซึ่งเกี่ยวข้องกับ&nbsp;
          {!isEmpty(contactNameInfo) ? (
            <NameInModal>
              <span>{contactNameInfo[0]}</span>
              {!isEmpty(contactNameInfo[1]) ? <span>&nbsp;{contactNameInfo[1]}</span> : null}
              &nbsp;
            </NameInModal>
          ) : null}
        </span>
        <StateText className="warning-text">(ไม่สามารถกู้คืนได้)</StateText>
      </ModalBody>
    )
  } else {
    return (
      <ModalBody>
        <Avatar type="circle" width="100px" height="100px" avatar={contactAvatar} />
        <p className="normal-text">เปลี่ยนสถานะของโอกาสในการขาย</p>
        <OportunitiesName>{name}</OportunitiesName>
        <span>
          <span>ซึ่งเกี่ยวข้องกับ&nbsp;</span>
          {!isEmpty(contactNameInfo) ? (
            <NameInModal>
              <span>{contactNameInfo[0]}</span>
              {!isEmpty(contactNameInfo[1]) ? <span>&nbsp;{contactNameInfo[1]}</span> : null}
              &nbsp;
            </NameInModal>
          ) : null}
          <StateText>
            {toState === 'won' ? (
              <span className="won-text">เป็น ขายสำเร็จ</span>
            ) : (
              <span className="fail-text">เป็น ขายไม่สำเร็จ</span>
            )}
          </StateText>
        </span>
      </ModalBody>
    )
  }
}

const Task: FC<TaskProps> = (props) => {
  const { task, setOpportunities } = props
  const dispatch = useDispatch()
  const [confirmUpdateStatus, setConfirmOpen] = useState<ConfirmType>(initialConfirm)
  const orders = props.task.orders
  const orderPremium = sumBy(orders, function (order) {
    return parseFloat(order.premium)
  })
  return (
    <Draggable draggableId={props.task.id.toString()} index={props.index}>
      {(provided, snapshot) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          status={props.isChangeColor ? props.task.state : 'processing'}
        >
          {confirmUpdateStatus.isOpen && (
            <Modal
              isOpen={confirmUpdateStatus.isOpen}
              onConfirm={() => {
                dispatch(updateStatus(task.id, confirmUpdateStatus.toState, setOpportunities))
              }}
              onCancel={() => setConfirmOpen(initialConfirm)}
            >
              {getText(
                confirmUpdateStatus.toState,
                confirmUpdateStatus.name,
                confirmUpdateStatus.contactName,
                props.task.contactAvatar,
              )}
            </Modal>
          )}
          <TaskContent>
            <Link to={`/opportunities/${task.id}`} className="ghost">
              <TaskName>{task.name}</TaskName>
            </Link>
            {((props.task.state !== 'processing' && props.isChangeColor) ||
              orders.length === 0) && (
              <StyledDropdown
                content={
                  <DropdownSubmenu
                    name={props.task.name}
                    contactName={props.task.contactName}
                    pipelineStateName={props.pipelineStateName}
                    setConfirmOpen={setConfirmOpen}
                    state={props.task.state}
                    canDelete={orders.length === 0}
                  />
                }
              >
                <DropdownIconContainer>
                  <Icon name="dot_option" />
                </DropdownIconContainer>
              </StyledDropdown>
            )}
          </TaskContent>
          <ContactContent>
            <StyledAvatar type="circle" width="32px" height="32px" avatar={task.contactAvatar} />
            &nbsp;
            <Link
              to={`/contacts/${task.contactId}`}
              className={task.canEdit ? ' ghost' : 'disabledLink ghost'}
            >
              {task.contactName}
            </Link>
          </ContactContent>
          <OrderContent hidden={orders.length === 0}>
            <div className="totalPremium">
              <IconBag name="bag" />
              <div className="premium">
                ฿&nbsp;
                {currency(orderPremium, 2, '')}
              </div>
            </div>
            <div className="orders">
              {(orders || []).map((order, key) => (
                <div className="card" key={key}>
                  <div className="agt-small">{order.name}</div>
                  <OrderStatus
                    className="agt-small"
                    status={props.isChangeColor ? order.status : 'approve'}
                  >
                    <span>{order.status}</span> <span># {order.orderNo}</span>
                  </OrderStatus>
                  {order?.salesman && (
                    <div className="salesBy">
                      <div className="name">พนักงานขาย : {order.salesman}</div>
                    </div>
                  )}
                  {order?.affiliate && (
                    <div className="salesBy">
                      <div className="name">ผู้แนะนำ : {order.affiliate}</div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </OrderContent>
          <DateContent className="agt-small" hasOrder={orders && orders.length > 0}>
            วันที่อัปเดต&nbsp;{dateFormat(props.task.updatedAt)}
          </DateContent>
          <Link to={`/opportunities/${task.id}`} className="ghost">
            <SeeMoreBtn type="button">
              <IconNote name="note_opps" color={'var(--agt-primary-color-1)'} />
              <small>ดูรายละเอียด</small>
            </SeeMoreBtn>
          </Link>
        </Container>
      )}
    </Draggable>
  )
}

export default Task
