import React from 'react'
import { camelizeKeys } from 'humps'
import { getLifeStylesOptions } from '../utils'

export const ShowMoreDetailInteresting = (category: string, moreDetailProps: object) => {
  const camelizeKeyMoreDetailProps = camelizeKeys(moreDetailProps)
  const lifeStyleObject = getLifeStylesOptions(category)
  return (
    <>
      {Object.entries(camelizeKeyMoreDetailProps).map(([key, object], i) => (
        <div className="Row" key={i}>
          <b>{lifeStyleObject[key].label}</b>
          <p>
            {moreDetailProps &&
              object.values.map((value: string, index: number) => (
                <span key={index}>
                  {
                    lifeStyleObject[key]['fields'].find((field: any) => {
                      return field.value === value
                    }).label
                  }
                  {index !== object.values.length - 1 ? ', ' : ''}
                </span>
              ))}
          </p>
        </div>
      ))}
    </>
  )
}
