import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import ResetPassword from 'pages/reset_password/root'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/reset_password" component={ResetPassword} />
  </Switch>
)

export default Pages
