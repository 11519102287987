import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik, useField } from 'formik'
import Select from 'react-select'
import authorize from 'utils/authorize'

import Icon from 'components/Icon'
import ViewSelector from './ViewSelector'
import MemberSelector from './MemberSelector'
import DatePicker from 'components/Inputs/DatePicker'
import DashboardGraph from 'components/Graph/DashboardGraph'
import DashboardOrderCard from 'components/Card/DashboardOrderCard'
import DashboardTopProductCard from 'components/Card/DashboardTopProductCard'
import DashboardCompensationCard from 'components/Card/DashboardCompensationCard'
import DashboardOpportunitiesCard from 'components/Card/DashboardOpportunitiesCard'
import DashboardOrdersStatusCard from 'components/Card/DashboardOrdersStatusCard'

import {
  getDashboardOptions,
  getDashboardCommission,
  getDashboardSummary,
  getDashboardDailySummary,
  getDashboardGraph,
} from 'store/dashboardSlice'

import {
  ViewType,
  OptionType,
  InsuranceType,
  SearchValuesType,
  SelectorFieldType,
  CompensationContainerType,
} from 'types/DashboardType'
import { RootState } from 'store/rootReducer'
import { DatePickerSubmitValueType } from 'types/DatePickerType'

import { getInitialDateRange } from './dashboard.utils'
import { THIS_MONTH, MONTH_SHORT_TH } from 'components/Inputs/DatePicker/DatePicker.utils'

type InsuranceColor = {
  color: string
  actived: boolean
}

const Container = styled(Form)`
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
`
const Header = styled.h2`
  margin-bottom: 26px;
  padding-left: 49px;
`
const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-row-gap: 20px;
`
const Orders = styled.div`
  grid-area: 1 / 1 / 2 / 2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;

  width: 100%;

  .datepicker {
    grid-area: 1 / 1 / 2 / 2;
  }
  .selecter {
    grid-area: 1 / 2 / 2 / 3;
  }
  .agt-dashboard-order-card__container {
    grid-area: 2 / 1 / 3 / 3;
  }
  .agt-dashboard-top-product-card__container {
    grid-area: 3 / 1 / 4 / 3;
    height: 100%;
  }
`
const Compensation = styled.div<CompensationContainerType>`
  padding: 0.5rem;
  grid-area: 1 / 2 / 2 / 3;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  border-radius: 0.5rem;
  background-color: var(--agt-light-sky-color);

  > div:first-of-type {
    grid-area: 1 / 1 / 2 / 2;
  }
  > div:nth-of-type(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  > div:nth-of-type(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  > div:nth-of-type(4) {
    grid-area: 2 / 2 / 3 / 3;
  }

  ${(props) =>
    props.isLeader
      ? css`
          > div:first-of-type {
            grid-area: 1 / 1 / 2 / 2;
          }
          > div:nth-of-type(2) {
            grid-area: 1 / 2 / 2 / 3;
          }
          > div:nth-of-type(3) {
            grid-area: 2 / 1 / 3 / 2;
          }
          > div:nth-of-type(4) {
            grid-area: 2 / 2 / 3 / 3;
          }
        `
      : css`
          > div:first-of-type {
            grid-area: 1 / 1 / 2 / 3;
          }
          > div:nth-of-type(2) {
            grid-area: 2 / 1 / 3 / 2;
          }
          > div:nth-of-type(3) {
            grid-area: 2 / 2 / 3 / 3;
          }
        `};
`
const Graph = styled.div`
  grid-area: 2 / 1 / 3 / 3;

  display: flex;
  flex-direction: column;
  padding: 20px 44px 60px;
  border: 1px solid var(--agt-background-color-1);
  border-radius: 8px;
`
const GraphHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  .topic {
    position: relative;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .topic h4 {
    color: var(--agt-primary-color-1);
  }
  .topic .info {
    position: absolute;
    left: 136px;
    top: 42px;
    color: var(--agt-white-color);
    background: var(--agt-primary-color-1);
    border-radius: 8px;
    padding: 14px;
    width: 285px;
    font-size: 13px;
    opacity: 0;
    transition: all 0.5s;
  }
  .topic .icon:hover {
    cursor: pointer;
  }
  .topic .icon:hover ~ .info {
    opacity: 1;
  }
`
const IconContainer = styled.div`
  display: flex;
`
const Insurance = styled.div<InsuranceColor>`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;

  color: ${(props) => (props.actived ? props.color : 'var(--agt-secondary-text-color)')};

  ${(props) =>
    props.actived
      ? css`
          color: ${props.color};
        `
      : css`
          color: var(--agt-secondary-text-color);

          svg {
            filter: grayscale(1);
            opacity: 0.8;
          }
        `};
`
const IconStyle = styled(Icon)`
  width: 24px;
  height: 24px;
  margin-right: 0.5rem;
`
const HintIcon = styled(Icon)`
  width: 24px;
  height: 24px;
  svg path {
    fill: var(--agt-primary-color-1);
  }
`
const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
}
const Selector = styled(Select)`
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const FilterContainer = styled.div`
  display: flex;

  > div {
    min-width: 160px;
    margin-left: 1rem;
  }
  > div:nth-child(2) {
    min-width: 248px;
  }
  > div:nth-child(3) {
    min-width: 168px;
  }
`

const SelectorField = ({ name, options = [], onChange = () => {} }: SelectorFieldType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers

  const defaultSeletor = options.find((select) => select.value === value)

  return (
    <Selector
      className={name}
      value={defaultSeletor}
      options={options}
      styles={customSelectStyles}
      onChange={(e: OptionType) => {
        setValue(e.value)
        onChange(e.value)
      }}
      isSearchable={false}
    />
  )
}

const INITIAL_VALUES: SearchValuesType = {
  date: {
    periodType: 'daily',
    summittedAt: getInitialDateRange(),
  },
  insuranceType: 'all',
  premium: 'premium',
  summary: 'salable',
  view: 'team',
  member: 'all',
}

function displayInfoPremium(premium: string) {
  let title = ''
  switch (premium) {
    case 'premium':
      title =
        'รวมยอดเบี้ยที่แจ้งงานปิดการขายเองและยอดเบี้ยที่ปิดการขายให้ผู้อื่น (ยอดเบี้ยที่มีผู้แนะนำให้)'
      break
    case 'suggest_premium':
      title = 'ยอดเบี้ยที่แจ้งแนะนำให้ผู้อื่นเป็นผู้ปิดการขาย'
      break
    case 'affiliate_premium':
      title = 'ยอดเบี้ยที่ผู้อื่นแนะนำให้ปิดการขายให้'
      break
    case 'policy':
      title =
        'รวมยอดกรมธรรม์ที่แจ้งงานปิดการการขายเองและยอดกรมธรรม์ที่ปิดการขายให้ผู้อื่น (ยอดกรมธรรม์ที่มีผู้แนะนำให้)'
      break
    case 'suggest_policy':
      title = 'ยอดกรมธรรม์ที่แจ้งแนะนำให้ผู้อื่นเป็นผู้ปิดการขาย'
      break
    case 'affiliate_policy':
      title = 'ยอดกรมธรรม์ที่ผู้อื่นแนะนำให้ปิดการขายให้'
      break
    default:
      title =
        'รวมยอดเบี้ยที่แจ้งงานปิดการขายเองและยอดเบี้ยที่ปิดการขายให้ผู้อื่น (ยอดเบี้ยที่มีผู้แนะนำให้)'
      break
  }
  return title
}

const NewDashboard: FC = () => {
  const dispatch = useDispatch()
  const {
    commission,
    orderCount,
    policies,
    opportunityStats,
    orderStats,
    policyTypeOptions,
    memberOptions,
    graph,
  } = useSelector((state: RootState) => state.dashboard)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  const [searchValue, searchSetValues] = useState<SearchValuesType>(INITIAL_VALUES)

  useEffect(() => {
    dispatch(getDashboardOptions())
    dispatch(getDashboardCommission())
    dispatch(getDashboardDailySummary())
  }, [dispatch])

  const _handleOnChange = useCallback(
    (values: SearchValuesType) => {
      searchSetValues({ ...searchValue, ...values })
    },
    [searchValue, searchSetValues],
  )

  useEffect(() => {
    dispatch(getDashboardSummary(searchValue))
    dispatch(getDashboardGraph(searchValue))
  }, [dispatch, searchValue])

  const PREMIUM_OPTIONS = useMemo(() => {
    if (['tqm_staff', 'broker'].includes(currentAgent?.agentType || '')) {
      return [
        { label: 'ยอดเบี้ย', value: 'premium' },
        { label: 'ยอดเบี้ยที่แนะนำ', value: 'suggest_premium' },
        { label: 'ยอดเบี้ยที่มีผู้แนะนำให้', value: 'affiliate_premium' },
        { label: 'ยอดกรมธรรม์', value: 'policy' },
        { label: 'ยอดกรมธรรม์ที่แนะนำ', value: 'suggest_policy' },
        { label: 'ยอดกรมธรรม์ที่มีผู้แนะนำให้', value: 'affiliate_policy' },
      ]
    }
    // if (currentAgent?.isLeader) {
    //   return [
    //     { label: 'ยอดเบี้ยแนะนำ', value: 'premium' },
    //     { label: 'ยอดกรมธรรม์', value: 'policy' },
    //   ]
    // }
    return [
      { label: 'ยอดเบี้ย', value: 'premium' },
      { label: 'ยอดเบี้ยที่มีผู้แนะนำให้', value: 'affiliate_premium' },
      { label: 'ยอดกรมธรรม์', value: 'policy' },
      { label: 'ยอดกรมธรรม์ที่มีผู้แนะนำให้', value: 'affiliate_policy' },
    ]
  }, [currentAgent])

  const CommissionCard = useMemo(() => {
    return (
      <React.Fragment>
        {currentAgent?.isLeader && (
          <DashboardCompensationCard
            topic="ค่าบริหารทีม"
            month={MONTH_SHORT_TH[THIS_MONTH]}
            amount={commission.incentiveAmount}
          />
        )}
        {['tqm_staff', 'broker'].includes(currentAgent?.agentType || '') ? (
          <DashboardCompensationCard
            topic="ค่าคอมมิชชั่น"
            month={MONTH_SHORT_TH[THIS_MONTH]}
            amount={commission.commissionAmount}
          />
        ) : (
          <DashboardCompensationCard
            topic="ค่าแนะนำ"
            month={MONTH_SHORT_TH[THIS_MONTH]}
            amount={commission.commissionAmount}
          />
        )}
      </React.Fragment>
    )
  }, [currentAgent, commission])

  return (
    <Formik initialValues={INITIAL_VALUES} enableReinitialize onSubmit={() => {}}>
      <Container>
        <Header>ภาพรวมเบี้ยประกัน</Header>
        <Content>
          <Orders>
            <div className="datepicker">
              <label>เลือกดูจากมุมมอง</label>
              <DatePicker
                onSubmit={(value: DatePickerSubmitValueType) => _handleOnChange({ date: value })}
              />
            </div>
            <div className="selecter">
              <label>เลือกดูจากประเภทประกัน</label>
              <SelectorField
                name="insuranceType"
                options={[{ label: 'ประกันทุกประเภท', value: 'all' }, ...policyTypeOptions]}
                onChange={(value: InsuranceType) => _handleOnChange({ insuranceType: value })}
              />
            </div>
            <DashboardOrderCard orderCount={orderCount} />
            <DashboardTopProductCard policies={policies} />
          </Orders>
          <Compensation isLeader={currentAgent?.isLeader}>
            {CommissionCard}
            <DashboardOpportunitiesCard opportunityStats={opportunityStats} />
            <DashboardOrdersStatusCard orderStats={orderStats} />
          </Compensation>
          <Graph>
            <GraphHeader>
              <div className="topic">
                <h4>
                  {PREMIUM_OPTIONS.find((e) => e.value === searchValue.premium)?.label ||
                    'ยอดที่แนะนำ'}
                </h4>
                <HintIcon className="icon" name="I" />
                <div className="info">
                  <b>
                    {PREMIUM_OPTIONS.find((e) => e.value === searchValue.premium)?.label ||
                      'ยอดเบี้ย'}
                  </b>
                  <p>{displayInfoPremium(searchValue?.premium || '')}</p>
                </div>
              </div>
              <FilterContainer>
                <ViewSelector
                  name="view"
                  onChange={(value: ViewType) => _handleOnChange({ view: value })}
                />
                <SelectorField
                  name="premium"
                  options={PREMIUM_OPTIONS}
                  onChange={(value: string) => _handleOnChange({ premium: value })}
                />
                <SelectorField
                  name="summary"
                  options={[
                    { label: 'ยอดที่ขายได้', value: 'salable' },
                    { label: 'ยอดที่ขายไม่ได้', value: 'rejectable' },
                    { label: 'ยอดรวมทั้งหมด', value: 'total' },
                  ]}
                  onChange={(value: string) => _handleOnChange({ summary: value })}
                />
              </FilterContainer>
            </GraphHeader>
            <GraphHeader>
              <IconContainer>
                <Insurance
                  color="#00529A"
                  actived={
                    searchValue.insuranceType === 'life' || searchValue.insuranceType === 'all'
                  }
                >
                  <IconStyle name="Life" />
                  ชีวิต
                </Insurance>
                <Insurance
                  color="#45DAAE"
                  actived={
                    searchValue.insuranceType === 'health' || searchValue.insuranceType === 'all'
                  }
                >
                  <IconStyle name="health" />
                  สุขภาพ
                </Insurance>
                <Insurance
                  color="#3ABAF8"
                  actived={
                    searchValue.insuranceType === 'motor' || searchValue.insuranceType === 'all'
                  }
                >
                  <IconStyle name="car" />
                  รถยนต์
                </Insurance>
                <Insurance
                  color="#7E5FFF"
                  actived={
                    searchValue.insuranceType === 'fire' || searchValue.insuranceType === 'all'
                  }
                >
                  <IconStyle name="Fire" />
                  อัคคีภัย
                </Insurance>
              </IconContainer>
              {searchValue.view === 'team' && (
                <MemberSelector
                  selectedMember={searchValue.member}
                  options={memberOptions}
                  onChange={(value: string) => _handleOnChange({ member: value })}
                />
              )}
            </GraphHeader>
            <DashboardGraph graph={graph} searchValue={searchValue} />
          </Graph>
        </Content>
      </Container>
    </Formik>
  )
}

export default authorize(NewDashboard, {
  roles: ['tqm_staff', 'affiliate', 'broker'],
})
