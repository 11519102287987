import React, { FC, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { DragDropContext } from 'react-beautiful-dnd'
import useQuery from 'hooks/useQuery'
import Column from '../components/Column'
import ClosedCard from 'pages/opportunities/components/ClosedCard'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  ContactType,
  FilterValuesType,
  OpportunitiesCompleteType,
  OpportunitiesProcessingType,
} from 'types/OpportunityType'
import initialValues from './index.utils'
import { getOpportunitiesByFilter, updatePipelineState } from 'store/opportunitiesSlice'
import SetStateType from 'types/SetStateType'
import Icon from 'components/Icon'
import WebTour from './WebTour'
import WebTourCompleted from './WebTourCompleted'

type SpanProps = {
  margin?: string
}
type OptionsButtonProps = {
  radius?: string
  active?: boolean
}

const Container = styled.div`
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  .notFound {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-top: 80px;
    color: var(--agt-secondary-color-1);
    text-align: center;
  }
`
const Header = styled.div`
  display: flex;
  column-gap: 24px;
  align-items: center;
  padding: 32px 46px;
  background: var(--agt-background-color-4);
  color: var(--agt-primary-color-1);
  h3 {
    color: var(--agt-primary-color-1);
  }
  .hint {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    font-size: 17px;
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`
const DragDropContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0px;
  flex-wrap: wrap;
  row-gap: 16px;
  .statusSide {
    display: flex;
    align-items: center;
  }
  .sortingSide {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sortingSide .sorting {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .sortingSide .filterBtn {
    align-self: flex-end;
    margin-left: 24px;
  }
  .filterSide {
    width: 100%;
    background-color: var(--agt-background-color-1);
    padding: 20px 32px 20px 48px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    align-items: center;
    row-gap: 16px;
  }
  .filterSide .customInput {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .filterSide .customInput.contact {
    width: 445px;
  }
  .filterSide .customInput.orders,
  .filterSide .customInput.article {
    width: 425px;
  }
  .filterSide .customInput label {
    margin-right: 8px;
  }
  .filterSide .customInput input {
    width: 300px;
  }
  .filterSide .resetBtn {
    margin-left: 40px;
    color: var(--agt-primary-color-1);
    cursor: pointer;
  }
  .filterSide .resetBtn:hover {
    color: var(--agt-dark-sky-color);
  }
  @media (max-width: 1024px) {
    .sortingSide {
      width: 100%;
    }
    .filterSide .customInput {
      width: 100%;
      justify-content: flex-start;
    }
    .filterSide .resetBtn {
      width: 100%;
      margin-left: 0px;
    }
  }
`
const Span = styled.span<SpanProps>`
  margin-right: ${(props) => props.margin};
`
const OptionButtonGroup = styled.div`
  display: flex;
`
const OptionsButton = styled.button<OptionsButtonProps>`
  background: ${(props) => (props.active ? 'var(--agt-light-sky-color)' : '#ffffff')};
  ${(props) =>
    props.active && props.radius === 'left'
      ? 'border-right: 1px solid var(--agt-secondary-color-1) !important'
      : 'border-left: 1px solid var(--agt-secondary-color-1) !important'};
  border: ${(props) =>
    props.active
      ? '1px solid var(--agt-primary-color-1)'
      : '1px solid var(--agt-secondary-color-1)'};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
  border-radius: ${(props) => (props.radius === 'left' ? ' 4px 0px 0px 4px ' : '0px 4px 4px 0px')};
  :hover {
    background: ${(props) => (props.active ? 'var(--agt-light-sky-color)' : '#ffffff')};
    border: ${(props) =>
      props.active
        ? '1px solid var(--agt-primary-color-1)'
        : '1px solid var(--agt-secondary-color-1)'};
    color: ${(props) =>
      props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
    border-radius: ${(props) =>
      props.radius === 'left' ? ' 4px 0px 0px 4px ' : '0px 4px 4px 0px'};
  }
`

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>โอกาสในการขาย</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const FilterComponent = ({
  isFilterBar = false,
  displayValues = { opportunityKey: '', orderNo: '', contactName: '', name: '' },
  setDisplayValues = (e) => {},
  isFilterValues = { opportunityKey: '', orderNo: '', contactName: '', name: '' },
  setIsFilterValues = (e) => {},
}) => {
  const [timeoutId, setTimeoutId] = useState<any>(null)
  const useTimeout: number = 1500

  const handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    setDisplayValues({ ...displayValues, [name]: value })
    if (timeoutId) clearTimeout(timeoutId)
    setTimeoutId(
      setTimeout(() => {
        onChange({ [name]: value })
      }, useTimeout),
    )
  }

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [timeoutId])

  const onChange = useCallback(
    (value) => {
      setIsFilterValues({ ...isFilterValues, ...value })
    },
    [isFilterValues, setIsFilterValues],
  )

  if (isFilterBar) {
    return (
      <div className="filterSide">
        <div className="customInput">
          <label>รหัสโอกาสในการขาย</label>
          <input
            name="opportunityKey"
            value={displayValues.opportunityKey}
            placeholder="โปรดระบุ"
            onChange={(e) => {
              handleChange(e)
            }}
          />
        </div>
        <div className="customInput orders">
          <label>รหัสคำสั่งซื้อ</label>
          <input
            name="orderNo"
            value={displayValues.orderNo}
            placeholder="โปรดระบุ"
            onChange={(e) => {
              handleChange(e)
            }}
          />
        </div>
        <div className="customInput contact">
          <label>รายชื่อลูกค้า</label>
          <input
            name="contactName"
            value={displayValues.contactName}
            placeholder="โปรดระบุ"
            onChange={(e) => {
              handleChange(e)
            }}
          />
        </div>
        <div className="customInput article">
          <label>หัวข้อ</label>
          <input
            name="name"
            value={displayValues.name}
            placeholder="โปรดระบุ"
            onChange={(e) => {
              handleChange(e)
            }}
          />
        </div>
        <u
          className="resetBtn"
          onClick={() => {
            setIsFilterValues(initialValues)
            setDisplayValues(initialValues)
          }}
        >
          รีเซ็ตค่าตัวเลือก
        </u>
      </div>
    )
  }
  return null
}

const Opportunities: FC = (props) => {
  const searchViewpoint = useQuery().get('viewpoint')
  const dispatch = useDispatch()
  const data: string = searchViewpoint === 'completed' ? searchViewpoint : 'processing'
  const [viewpoint, setViewpoint] = useState<string>(data)
  const [opportunities, setOpportunities] = useState<OpportunitiesProcessingType[]>([])
  const [opportunitiesComplete, setOpportunitiesComplete] = useState<OpportunitiesCompleteType[]>(
    [],
  )
  const [sortByProcessing, setSortByProcessing] = useState<string>('desc')
  const [sortByCompleted, setSortByCompleted] = useState<string>('desc')
  const [contacts, setContacts] = useState<ContactType[]>([])
  const [dragCard, setDrag] = useState<boolean>(false)
  const [isFilterBar, setIsFilterBar] = useState<boolean>(false)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [isFilterValues, setIsFilterValues] = useState<FilterValuesType>(initialValues)
  const [displayValues, setDisplayValues] = useState<FilterValuesType>(initialValues)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  let sortBy: string = viewpoint === 'processing' ? sortByProcessing : sortByCompleted

  useEffect(() => {
    dispatch(
      getOpportunitiesByFilter(
        viewpoint,
        isFilterValues,
        setOpportunities,
        setOpportunitiesComplete,
        setContacts,
      ),
    )
  }, [dispatch, viewpoint, isFilterValues])

  useEffect(() => {
    setIsFilterValues({ ...isFilterValues, order: sortBy })
    setDisplayValues({ ...displayValues, order: sortBy })
    // eslint-disable-next-line
  }, [sortBy])

  useEffect(() => {
    setOpportunities(opportunities)
  }, [dragCard, opportunities, setOpportunities])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (viewpoint === 'processing') {
        if (storage['opportunities']['opportunities'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, opportunities: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      } else if (viewpoint === 'completed') {
        if (storage['opportunities']['completed'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, completed: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: viewpoint === 'processing' ? true : false,
            create: false,
            opportunityById: false,
            completed: viewpoint === 'completed' ? true : false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [viewpoint, setIsShowHint])

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result
    if (!destination) {
      return
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }
    var start = opportunities.find(
      (element) => element.pipelineStateId === parseInt(source.droppableId),
    )

    const finish = opportunities.find(
      (element) => element.pipelineStateId === parseInt(destination.droppableId),
    )
    if (start === undefined) {
      return
    }
    if (finish === undefined) {
      return
    }
    const draggable = start.opportunities.find((element) => element.id === parseInt(draggableId))
    if (draggable === undefined) {
      return
    }

    if (start === finish) {
      const newTaskIds = start.opportunities
      newTaskIds.splice(source.index, 1)
      newTaskIds.splice(destination.index, 0, draggable)

      const newColumn = {
        ...start,
        opportunities: newTaskIds,
      }
      const newColumnIndex = opportunities.findIndex(
        (element) => element.pipelineStateId === newColumn.pipelineStateId,
      )

      const newState = opportunities
      newState.splice(newColumnIndex, 1)
      newState.splice(newColumnIndex, 0, newColumn)

      setOpportunities(newState)
      return
    }

    // Moving from one list to another
    const startTaskIds = start.opportunities
    startTaskIds.splice(source.index, 1)

    const finishTaskIds = finish.opportunities
    finishTaskIds.splice(destination.index, 0, draggable)

    const newStartIndex = opportunities.findIndex(
      (element) => element.pipelineStateId === start?.pipelineStateId,
    )
    const newFinishIndex = opportunities.findIndex(
      (element) => element.pipelineStateId === finish.pipelineStateId,
    )
    const newStartColumn = {
      ...start,
      opportunities: startTaskIds,
    }
    const newFinishColumn = {
      ...finish,
      opportunities: finishTaskIds,
    }
    const newState = opportunities
    newState.splice(newStartIndex, 1)
    newState.splice(newStartIndex, 0, newStartColumn)
    newState.splice(newFinishIndex, 1)
    newState.splice(newFinishIndex, 0, newFinishColumn)

    dispatch(
      updatePipelineState(draggableId, destination.droppableId, () => {
        setOpportunities(newState)
      }),
    )
    setDrag(!dragCard)
  }

  return (
    <Container>
      {viewpoint === 'processing' ? (
        <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      ) : (
        <WebTourCompleted visible={isShowHint} setVisible={setIsShowHint} />
      )}
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <Menu>
        <div className="statusSide">
          <Span margin="32px">สถานะ</Span>
          <OptionButtonGroup id="opportunity__switch-page">
            <OptionsButton
              radius="left"
              active={viewpoint === 'processing'}
              onClick={() => setViewpoint('processing')}
            >
              กำลังดำเนินการ
            </OptionsButton>
            <OptionsButton
              id="opportunity__status-button"
              radius="right"
              active={viewpoint === 'completed'}
              onClick={() => setViewpoint('completed')}
            >
              สถานะการขาย
            </OptionsButton>
          </OptionButtonGroup>
        </div>
        <div className="sortingSide">
          <div className="sorting">
            <Span margin="32px">เรียงตามอัปเดต</Span>
            <OptionButtonGroup id="opportunity__sort-page">
              <OptionsButton
                radius="left"
                active={sortBy === 'desc'}
                onClick={() => {
                  if (viewpoint === 'processing') {
                    setSortByProcessing('desc')
                  } else {
                    setSortByCompleted('desc')
                  }
                }}
              >
                ใหม่ที่สุด
              </OptionsButton>
              <OptionsButton
                id="opportunity__asc-button"
                radius="right"
                active={sortBy === 'asc'}
                onClick={() => {
                  if (viewpoint === 'processing') {
                    setSortByProcessing('asc')
                  } else {
                    setSortByCompleted('asc')
                  }
                }}
              >
                เก่าที่สุด
              </OptionsButton>
            </OptionButtonGroup>
          </div>
          <button
            id="opportunity__filter-button"
            type="button"
            className="filterBtn secondary"
            onClick={() => {
              setIsFilterBar(!isFilterBar)
            }}
          >
            ปรับตัวเลือก
          </button>
        </div>
        <FilterComponent
          isFilterBar={isFilterBar}
          displayValues={displayValues}
          setDisplayValues={setDisplayValues}
          isFilterValues={isFilterValues}
          setIsFilterValues={setIsFilterValues}
        />
      </Menu>
      {viewpoint === 'processing' && (
        <DragDropContext onDragEnd={onDragEnd}>
          <DragDropContainer id="opportunity__table">
            {opportunities &&
              opportunities.map((column) => {
                const tasks = column.opportunities
                return (
                  <Column
                    tasks={tasks}
                    column={column}
                    contacts={contacts}
                    key={column.pipelineStateId}
                    setOpportunities={setOpportunities}
                    currentAgent={currentAgent}
                  />
                )
              })}
          </DragDropContainer>
        </DragDropContext>
      )}
      {viewpoint === 'completed' && (
        <div id="opportunity__close-card">
          {opportunitiesComplete.length > 0 ? (
            opportunitiesComplete.map((value, i) => <ClosedCard key={i} {...value} />)
          ) : (
            <div className="notFound">ยังไม่มีรายการแสดง</div>
          )}
        </div>
      )}
    </Container>
  )
}

export default Opportunities
