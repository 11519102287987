import React, { FC, useState, useEffect, useMemo } from 'react'
import { Link, useParams } from 'routes'
import Summary from './Summary'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import InsurancePortCard from 'pages/insurance_portfolio/components/InsurancePortCard'
import { InsurancePortType } from 'types/InsurancePortType'
import { go2Top } from 'utils/helper'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById, getInsurance } from 'store/contactSlice'
import { isArray, isEmpty } from 'lodash'
import { useParamsType } from './index.type'
import {
  Container,
  Header,
  Loading,
  SubHeaderAndButton,
  OptionButtonGroup,
  Button,
  ButtonContainer,
  Noting,
  SubHeader,
  MainContent,
  OptionsButton,
} from './index.styles'

const InsurancePorfolio: FC = () => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    { label: 'สรุปกรมธรรม์' },
  ]
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isConclusion, setConclusion] = useState<boolean>(false)
  const [InsurancePorts, setInsurancePorts] = useState<Array<InsurancePortType>>([]) || null
  const [viewpoint, setViewpoint] = useState<string>('completed')

  useEffect(() => {
    dispatch(getInsurance(id, setInsurancePorts, setLoading))
    dispatch(getContactById(id))
  }, [id, setInsurancePorts, dispatch])

  useEffect(() => {
    go2Top()
  }, [isConclusion])

  const completedPorts = useMemo(() => {
    if (InsurancePorts.length > 0) {
      let used: Array<InsurancePortType> = []
      for (let i = 0; i < InsurancePorts.length; i++) {
        if (InsurancePorts[i].state === 'completed') {
          used.push(InsurancePorts[i])
        }
      }
      return used
    } else {
      return null
    }
  }, [InsurancePorts])

  return (
    <Container>
      <Header>สรุปกรมธรรม์</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {isLoading ? (
        <Loading>loading ....</Loading>
      ) : (
        <>
          {InsurancePorts.length > 0 ? (
            isConclusion ? (
              <Summary />
            ) : (
              <>
                <SubHeaderAndButton>
                  <div>ข้อมูลกรมธรรม์</div>
                  <OptionButtonGroup>
                    <OptionsButton
                      radius="left"
                      active={viewpoint === 'completed'}
                      onClick={() => setViewpoint('completed')}
                    >
                      กรมธรรม์ที่มีผลบังคับ
                    </OptionsButton>
                    <OptionsButton
                      radius="right"
                      active={viewpoint === 'totally'}
                      onClick={() => setViewpoint('totally')}
                    >
                      กรมธรรม์ทั้งหมด
                    </OptionsButton>
                  </OptionButtonGroup>
                </SubHeaderAndButton>
                <MainContent>
                  {viewpoint === 'completed' ? (
                    <>
                      {isArray(completedPorts) && !isEmpty(completedPorts) ? (
                        (completedPorts || []).map((port, index) => (
                          <InsurancePortCard port={port} key={index} />
                        ))
                      ) : (
                        <div className="Nothing">ไม่มีข้อมูลกรมธรรม์ที่มีผลบังคับ</div>
                      )}
                    </>
                  ) : (
                    <>
                      {(InsurancePorts || []).map((port, index) => (
                        <InsurancePortCard port={port} key={index} />
                      ))}
                    </>
                  )}
                </MainContent>
              </>
            )
          ) : (
            <>
              <SubHeader>ข้อมูลกรมธรรม์</SubHeader>
              <Noting>ยังไม่มีข้อมูลกรมธรรม์ที่ถืออยู่</Noting>
            </>
          )}
          <ButtonContainer>
            {InsurancePorts.length > 0 && (
              <Button
                className="secondary"
                style={{ marginRight: '24px' }}
                onClick={() => {
                  setConclusion(!isConclusion)
                }}
              >
                {!isConclusion ? 'สรุปความคุ้มครอง' : 'กลับไปหน้าข้อมูลกรมธรรม์'}
              </Button>
            )}
            <Link to={`/contacts/${id}/insurance_portfolio/create`}>
              <Button disabled={currentAgent?.statusTh === 'ระงับ'}>
                เพิ่มข้อมูลประกันที่ถืออยู่
              </Button>
            </Link>
          </ButtonContainer>
        </>
      )}
    </Container>
  )
}

export default InsurancePorfolio
