import React, { FC, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'

import { RootState } from 'store/rootReducer'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Loader from 'components/BackgroundLoading'
import { createOrder, getMotorOptions, getOrder } from 'store/motorInsuranceSlice'
import DropleadForm from '../form'
import DropleadSchema from '../form/form.validate'
import InsuranceDetail from '../InsuranceDetail'
import Show from '../show'
import HistoryType from 'types/HistoryType'

const EditDroplead: FC = () => {
  let { id, order_id } = useParams<ParamsType>()
  const dispatch = useDispatch()
  const { order, loading, options } = useSelector((state: RootState) => state.motor)
  let history: HistoryType = useHistory()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: 'โอกาสในการขาย' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]
  useEffect(() => {
    dispatch(getOrder(id, order_id))
  }, [id, order_id])

  useEffect(() => {
    if (order && order?.vehicle !== null) {
      dispatch(
        getMotorOptions(id, {
          year: order.vehicle.year,
          makeCode: order.vehicle.makeCode,
          familyCode: order.vehicle.familyCode,
        }),
      )
    }
  }, [order])

  const vehicleData = useMemo(
    () => ({
      name: order?.vehicle.name || '',
      vehicleLicense: { canEdit: order?.vehicle.vehicleLicense.canEdit || null },
      vehicleProvince: { canEdit: order?.vehicle.vehicleProvince.canEdit || null },
      vehicleChassis: { canEdit: order?.vehicle.vehicleChassis.canEdit || null },
    }),
    [order],
  )

  const insuranceDetailData = useMemo(
    () => ({
      companyLogoUrl: order?.order.companyLogoUrl || '',
      companyName: order?.order.motorInsurance.productDetails.companyName || '',
      netAmount: order?.order.motorInsurance.productDetails.netAmount || '',
      carUsage: order?.vehicle.carUsage || '',
      garage: order?.order.motorInsurance.productDetails.garage || '',
      garageTotal: order?.order.motorInsurance.productDetails.garageTotal || '',
      cover: order?.order.motorInsurance.productDetails.cover || '',
      carCheck: order?.order.motorInsurance.productDetails.carCheck || '',
      terrorism: order?.order.motorInsurance.productDetails.terrorism || '',
      coverFlooding: order?.order.motorInsurance.productDetails.coverFlooding || '',
      coverDeduct: order?.order.motorInsurance.productDetails.coverDeduct || '',
      cover3Rd: order?.order.motorInsurance.productDetails.cover3Rd || '',
      cover3RdTime: order?.order.motorInsurance.productDetails.cover3RdTime || '',
      cover3RdAsset: order?.order.motorInsurance.productDetails.cover3RdAsset || '',
      coverLostFire: order?.order.motorInsurance.productDetails.coverLostFire || '',
      coverAcc: order?.order.motorInsurance.productDetails.coverAcc || '',
      coverAccNum: order?.order.motorInsurance.productDetails.coverAccNum || '',
      coverAccPass: order?.order.motorInsurance.productDetails.coverAccPass || '',
      coverAccPassNum: order?.order.motorInsurance.productDetails.coverAccPassNum || '',
      coverLegal: order?.order.motorInsurance.productDetails.coverLegal || '',
      insureLevel: order?.order.motorInsurance.insureLevel || '',
    }),
    [order],
  )

  return (
    <Container>
      <Header>ประกันรถยนต์</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      {(loading.order || loading.options || loading.submit) && <Loader />}
      <DropleadContainer>
        <InsuranceDetail data={insuranceDetailData} />

        {order && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(order.order.status || '') && (
          <Formik
            enableReinitialize
            initialValues={{
              ...order?.contact,
              order: {
                orderId: order_id,
                motorDetails: {
                  ...order?.order.motorInsurance,
                },
              },
              vehicleDetails: {
                ...order?.vehicle,
              },
            }}
            validationSchema={DropleadSchema}
            onSubmit={(values) => {
              const modifiedValues = {
                ...values,
                type: 'droplead',
                vehicleDetails: {
                  ...values.vehicleDetails,
                  id: order.vehicle.id,
                  expiryDate: order.vehicle.expiryDate,
                  cc: order.vehicle.cc,
                  // carUsage: order.vehicle.carUsage,
                  vehicleLicense: values.vehicleDetails.vehicleLicense.value,
                  vehicleProvince: values.vehicleDetails.vehicleProvince.value,
                  vehicleChassis: values.vehicleDetails.vehicleChassis.value,
                },
                order: {
                  ...values.order,
                  name: `${values.order.motorDetails.productDetails.companyName} ชั้น ${order.order.motorInsurance.insureLevel}`,
                  motorDetails: {
                    ...values.order.motorDetails,
                    productDetails: { ...values.order.motorDetails.productDetails },
                  },
                },
              }
              delete modifiedValues.vehicleDetails.carUsage
              // console.log('modifiedValues', modifiedValues)
              dispatch(
                createOrder(id, { ...modifiedValues }, () => history.push(`/opportunities/${id}/`)),
              )
            }}
          >
            {(props) => (
              <DropleadForm
                handleSubmit={props.handleSubmit}
                values={props.values}
                options={options}
                data={vehicleData}
                id={id}
                errors={props.errors}
              />
            )}
          </Formik>
        )}

        {order && ['แจ้งงาน', 'แจ้งแนะนำ', 'ส่งงานเข้าระบบ'].includes(order.order.status || '') && (
          <Show data={order} options={options} />
        )}
      </DropleadContainer>
    </Container>
  )
}

export default EditDroplead

type ParamsType = {
  id: string
  order_id: string
}
const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  font-size: 17px;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`
const DropleadContainer = styled.div`
  display: flex;
  flex-direction: row;
`
