import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Icon from 'components/Icon'
import Select, { StylesConfig } from 'react-select'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const customSelectStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
  }),
  placeholder: () => ({
    color: 'var(--agt-secondary-color-1)',
  }),
}
const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const Sidebar = styled.div`
  border-left: 1px solid var(--agt-secondary-color-1);
  border-right: 1px solid var(--agt-secondary-color-1);
  width: 254px;
  .header {
    padding: 0 18px;
    padding-right: 6px;
    height: 48px;
    background: var(--agt-background-color-3);
    border-bottom: 1px solid var(--agt-white-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header .edit {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }
  .header .topic {
    color: var(--agt-primary-color-1);
  }
  .header .add {
    width: 36px;
    height: 36px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .header .penBtn {
    width: 36px;
    height: 36px;
    padding: 0;
  }
  .card {
    padding: 8px 11px;
    padding-bottom: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--agt-background-color-2);
    height: 130px;
    background-color: var(--agt-white-color);
  }
  .card .datetime {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card .location {
    color: var(--agt-secondary-color-2);
  }
  .card .contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card .date {
    padding: 0 4px;
    color: var(--agt-secondary-color-2);
  }
  .card .contact .name {
    color: var(--agt-primary-color-1);
  }
`
const PenIcon = styled(Icon)`
  width: 13px;
  height: 13px;
  svg {
    width: 13px;
    height: 13px;
  }
`
const CalendarIcon = styled(Icon)`
  width: 13px;
  height: 13px;
  svg {
    width: 13px;
    height: 13px;
  }
`
const CustomClock = styled(Icon)`
  svg {
    width: 12px !important;
    height: 12px !important;
    path {
      stroke: var(--agt-secondary-color-2);
    }
  }
`
const CustomForm = styled.div`
  padding: 4px;
  background: var(--agt-white-color);
  border-radius: 8px;
  .row {
    display: flex;
    column-gap: 24px;
  }
  .column {
    display: flex;
    flex-direction: column;
    /* row-gap: 8px; */
  }
  .column .topic {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .column .topic small {
    color: var(--agt-secondary-text-color);
  }
  .column u {
    color: var(--agt-primary-color-1);
    margin-top: 4px;
  }
`

const WebTourEdit = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 5
  const layout = [
    {
      top: '30px',
      right: '752px',
      width: '191px',
      arrow: 'right',
    },
    {
      top: '0px',
      right: '176px',
      width: '229px',
      arrow: 'right',
    },
    {
      top: '0px',
      right: '270px',
      width: '222px',
      arrow: 'right',
    },
    {
      top: '0px',
      right: '270px',
      width: '212px',
      arrow: 'right',
    },
    {
      top: '56px',
      right: '0px',
      width: '231px',
      arrow: 'topRight',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [todayBtnPosition, setTodayBtnPosition] = useState({
    top: '0',
    left: '0',
  })
  const [datepickerPosition, setDatepickerPosition] = useState({
    top: '0',
    left: '0',
  })
  const [selectMonthPosition, setSelectMonthPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('opportunity__topic', setTodayBtnPosition)
      getPositionById('opportunity__find-insurance', setDatepickerPosition)
      getPositionById('opportunity__other-detail', setSelectMonthPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>สร้างโอกาสในการขาย</b>
          <small>
            คลิกเพื่อแก้ไขรายการนัดหมายหรืองาน
            <br />
            ที่ต้องการเชื่อมกับโอกาสในการขายนี้
          </small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>สร้างโอกาสในการขาย</b>
          <small>ระบุหัวข้อและลูกค้าที่เกี่ยวข้อง</small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>สร้างโอกาสในการขาย</b>
          <small>คลิกค้นหาแบบประกันเพื่อเพิ่มคำสั่งซื้อ</small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>สร้างโอกาสในการขาย</b>
          <small>
            คลิก + เพื่อเชื่อมกับนัดหมายหรืองาน
            <br />
            ที่มีอยู่ของลูกค้าที่ระบุ
          </small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>สร้างโอกาสในการขาย</b>
          <small>ตัวอย่างนัดหมายและงานที่เชื่อมอยู่</small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <CustomForm>
          <div className="row">
            <div className="column" style={{ width: 352 }}>
              <div className="topic">
                <div>หัวข้อ</div>
                <small>AGTOPP010964001</small>
              </div>
              <input className="" placeholder="โปรดระบุ" />
            </div>
            <div className="column" style={{ width: 352 }}>
              <div className="">ลูกค้าที่เกี่ยวข้อง</div>
              <Select
                styles={customSelectStyles}
                isClearable
                placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
                isDisabled={true}
              />
            </div>
          </div>
        </CustomForm>
      )
    } else if (step === 2) {
      return <button type="button">ค้นหาแบบประกัน</button>
    } else if (step === 3) {
      return (
        <Sidebar>
          <div className="header">
            <b className="topic">นัดหมาย</b>
            <button className="add secondary">+</button>
          </div>
          <div className="header">
            <b className="topic">งานที่ต้องทำ</b>
            <button className="add secondary">+</button>
          </div>
        </Sidebar>
      )
    } else if (step === 4 || step === 5) {
      return (
        <Sidebar>
          <div className="header">
            <div className="edit">
              <FontAwesomeIcon icon={faChevronDown} color="var(--agt-primary-color-1)" />
              <b className="topic">นัดหมาย (2)</b>
            </div>
            <button className="penBtn secondary">
              <PenIcon name="pencil_icon" color="var(--agt-primary-color-1)" />
            </button>
          </div>
          <div className="card">
            <div className="datetime">
              <div>
                <CalendarIcon name="calendarDatePicker" color="var(--agt-secondary-color-2)" />
                <small className="date">15/06/2563</small>
              </div>
              <div>
                <CustomClock name="clock_white" color="var(--agt-secondary-color-2)" />
                <small className="date">14:00 น.-15:00 น.</small>
              </div>
            </div>
            <div>
              <b>นัดคุยเก็บข้อมูล</b>
              <div className="color" />
            </div>
            <small>
              นัดคุยเพื่อเก็บข้อมูลเพิ่มเติมนัดคุยเพื่อเก็บข้อ
              มูลเพิ่มเติมนัดคุยเพื่อเก็บข้อมูลเพิ่มเติม...
            </small>
            <div>
              <Icon name="location" />
              <small className="location">เซนทรัลลาดพร้าว</small>
            </div>
          </div>
          <div className="header">
            <div className="edit">
              <FontAwesomeIcon icon={faChevronDown} color="var(--agt-primary-color-1)" />
              <b className="topic">งานที่ต้องทำ (2)</b>
            </div>
            <button className="penBtn secondary">
              <PenIcon name="pencil_icon" color="var(--agt-primary-color-1)" />
            </button>
          </div>
          <div className="card">
            <div>
              <CalendarIcon name="calendarDatePicker" color="var(--agt-secondary-color-2)" />
              <small className="date">15/06/2563</small>
            </div>
            <b>โทรตามเอกสารโทรตามเอ...</b>
            <small>เตรียมเอกสารเตรียมเอกสารเตรียมเอกสารเตรียม...เตรียมเอกสารเตรียมเ...</small>
            <div className="contact">
              <small className="name">โรแลนด์ แบงส์ (โรดี้)</small>
              <div>
                <Icon name="star" color={'var(--agt-primary-text-color)'} />
                <Icon name="star" color={'var(--agt-primary-text-color)'} />
                <Icon name="star" color={'var(--agt-primary-text-color)'} />
              </div>
            </div>
          </div>
        </Sidebar>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1) {
      return todayBtnPosition
    } else if (step === 2) {
      return datepickerPosition
    } else if (step === 3 || step === 4 || step === 5) {
      return selectMonthPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTourEdit
