import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const ModalCustomStyle = {
  width: '510px',
  // maxWidth: '510px',
  minHeight: '10rem',
  border: '1px solid #ccc',
  background: '#ffffff',
  borderRadius: '4px',
  outline: 'none',
  padding: '0px',
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
  .modal {
    position: fixed;
    top: calc(50% - 255px);
    left: calc(50% - 255px);
  }
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.right {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const CustomButton = styled.button`
  &.consentBtn {
  }
`
const ConsentModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px 24px 32px;
  b {
    margin-bottom: 8px;
  }
  .contactBy {
    margin-bottom: 24px;
  }
  .contactBy span {
    color: var(--agt-primary-color-1);
    font-weight: bold;
  }
  .share {
    margin-bottom: 8px;
  }
  .copyURL {
    width: 100%;
    max-width: 445px;
    height: 58px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  .copyURL .url {
    width: 100%;
    max-width: 345px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ccdceb;
    border-radius: 8px 0 0 8px;
  }
  .copyURL .url .text {
    width: 100%;
    max-width: 266px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 16px;
    text-decoration: none;
  }
  .copyURL .paste {
    width: 100%;
    max-width: 100px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
    cursor: pointer;
  }
  .email {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    > label {
      margin-bottom: 8px;
    }
    > input {
      width: 100%;
    }
    margin-bottom: 8px;
  }
  small {
    color: var(--agt-secondary-color-1);
  }
`
const ChoiceBtn = styled.div`
  display: flex;
  width: 100%;
  .submitBtn {
    width: 100%;
    height: 56px;
    background-color: var(--agt-primary-color-1);
    border-radius: 0;
    &:disabled {
      color: var(--agt-disable-color-2);
      background-color: var(--agt-disable-color-1);
    }
  }
  .laterBtn {
    width: 100%;
    height: 56px;
    background-color: white;
    color: var(--agt-primary-text-color);
    border-top: 1px solid var(--agt-secondary-color-1);
    border-radius: 0;
    &:hover {
      background-color: white;
      color: var(--agt-primary-text-color);
    }
  }
`
const CloseBtn = styled.div`
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  width: 100%;
  padding: 24px 32px 16px 0;
  > div {
    cursor: pointer;
  }
`

const WebTourNonConsent = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 3
  const layout = [
    {
      top: '0px',
      right: '-245px',
      width: '232px',
      arrow: 'right',
      modal: false,
    },
    {
      top: '216px',
      right: '-300px',
      width: '283px',
      arrow: 'right',
      modal: true,
    },
    {
      top: '453px',
      right: '-272px',
      width: '257px',
      arrow: 'right',
      modal: true,
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [consentBtnPosition, setConsentBtnPosition] = useState({
    top: '0',
    left: '0',
  })
  // const [consentModal, setConsentModalPosition] = useState({
  //   top: '0',
  //   left: '0',
  // })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('contact-id__consent-btn', setConsentBtnPosition)
      // getPositionById('contact-id__modal', setConsentModalPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    // document.addEventListener('scroll', getPosition)
    return () => {
      clearInterval()
      // document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>สร้างรายชื่อลูกค้า</b>
          <small>
            สามารถคัดลอกลิงก์หรือระบุที่อยู่อีเมลเพื่อ
            <br />
            ขอความยินยอมจากลูกค้า หรือเลือกไว้ทีหลัง
          </small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>ลูกค้าที่ยังไม่ให้ความยินยอม</b>
          <small>
            คลิกเพื่อส่งขอความยินยอมจากลูกค้า
            <br />
            ในภายหลัง
          </small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>สร้างรายชื่อลูกค้า</b>
          <small>
            สามารถนำข้อมูลลูกค้าไปใช้งานได้
            <b>
              หลังจาก
              <br />
              ลูกค้าให้ความยินยอมแล้วเท่านั้น
            </b>
            หากลูกค้าไม่ให้
            <br />
            ความยินยอม<b>ภายใน 30 วัน</b> ข้อมูลจะถูกลบออก
          </small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <CustomButton type="button" className="consentBtn">
          ขอความยินยอม
        </CustomButton>
      )
    } else if (step === 2 || step === 3) {
      return (
        <div style={ModalCustomStyle}>
          <CloseBtn>
            <div>
              <Icon name="close_button" />
            </div>
          </CloseBtn>
          <ConsentModal>
            <b>การขอความยินยอม</b>
            <label>เราได้ทำหนังสือการยินยอมให้เปิดเผยข้อมูลตาม พ.ร.บ.คอมฯ</label>
            <label className="contactBy">
              ฉบับล่าสุดของ&nbsp;<span>{'โรแลนด์ แบงส์'}</span>
              &nbsp;เรียบร้อยแล้ว
            </label>
            <label className="share">คัดลอกลิงก์เพื่อแชร์ให้กับลูกค้าของคุณ</label>
            <div className="copyURL">
              <div className="url">
                <Icon name="chainLink" />
                <a href="/" className="text" target="_blank" rel="noopener noreferrer">
                  https://httpshttpshttpshttpshttps
                </a>
              </div>
              <div className="paste">คัดลอก</div>
            </div>
            <div className="email">
              <label>หรือระบุที่อยู่อีเมลลูกค้า เพื่อส่งขอความยินยอม</label>
              <input placeholder="กรุณาระบุอีเมลลูกค้า" disabled />
            </div>
            <small>แนะนำให้ระบุอีเมลลูกค้า</small>
            <small>เพื่อประโยชน์ในการขอความยินยอมฉบับใหม่ในอนาคต</small>
          </ConsentModal>
          <ChoiceBtn>
            <button className="submitBtn">ส่งขอความยินยอม</button>
            <button className="laterBtn">ไว้ทีหลัง</button>
          </ChoiceBtn>
        </div>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1) {
      return consentBtnPosition
    } else if (step === 2) {
      return consentBtnPosition
    } else if (step === 3) {
      return consentBtnPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          <div className={layout[step - 1].modal ? 'modal' : ''}>
            {ContentSection}
            <DialogBox
              width={layout[step - 1].width}
              top={layout[step - 1].top}
              right={layout[step - 1].right}
            >
              {LabelSection}
              {ButtonSection}
              <div className={`labelArrow ${layout[step - 1].arrow}`} />
            </DialogBox>
          </div>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTourNonConsent
