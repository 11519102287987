import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import Avatar from 'components/Avatar'
import { getPositionById } from 'utils/helper'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}
type TaskStackType = {
  top?: string
  left?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.bottomRight {
    top: unset;
    right: unset;
    border-radius: 0px 4px 0px;
    bottom: -8px;
  }
  .labelArrow.hidden {
    display: none;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const WorkSelect = styled.div`
  display: flex;
  width: 352px;
  height: 48px;
  &.flexEnd {
    justify-content: flex-end;
  }
  .incompletedBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: #cae6ff;
    border: 1px solid var(--agt-primary-color-1);
    color: var(--agt-primary-color-1);
    border-radius: 4px 0px 0px 4px;
  }
  .completedBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    background-color: var(--agt-white-color);
    border: 1px solid var(--agt-secondary-color-1);
    color: var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
  }
`
const CustomButton = styled.button`
  &.selectBtn {
    width: fit-content;
    height: fit-content;
    min-width: 160px;
    max-height: 48px;
    background-color: var(--agt-white-color);
    border-radius: 5px;
    padding: 11px 15px;
    color: var(--agt-primary-color-1);
    border: 1px solid var(--agt-primary-color-1);
    text-align: center;
  }
  &.createBtn {
    width: fit-content;
    height: fit-content;
    min-width: 160px;
    max-height: 48px;
    background-color: var(--agt-primary-color-1);
    border-radius: 5px;
    padding: 11px 15px;
    color: var(--agt-white-color);
    border: 1px solid var(--agt-primary-color-1);
    text-align: center;
  }
`
const TaskStack = styled.div<TaskStackType>`
  /* width: ${(props) => (props.left ? `calc(100vw - 95px - ${props.left})` : 'calc(100vw)')}; */
  width: calc(100vw - 95*0.9px);
  max-width: 1120px;
  padding: 0 95px;
  .textOverLine {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: var(--agt-primary-text-color);
    background-color: var(--agt-background-color-2);
    padding: 4px 16px;
    border-bottom: 1px solid var(--agt-primary-text-color);
  }
  .title.late {
    color: var(--agt-error-color);
  }
  .display {
    padding: 12px 30px;
    display: flex;
    background-color: var(--agt-white-color);
  }
  .display .personal {
    flex: 0.4;
    display: flex;
    padding-right: 16px;
    border-right: 1px solid var(--agt-secondary-color-1);
  }
  .display .personal .circle {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    border-radius: 50%;
    border: 1px solid var(--agt-secondary-color-2);
  }
  .display .personal .text {
    padding-left: 24px;
    display: flex;
    flex-direction: column;
  }
  .display .personal .text .date {
    display: flex;
    justify-content: space-between;
    margin-top: 6px;
  }
  .display .personal .text .date .calendar {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  .display .personal .text .date .calendar small {
    color: var(--agt-secondary-color-2);
  }
  .display .personal .text .date .star {
    display: flex;
  }
  .display .work {
    /* width: 100%;
    max-width: 540px; */
    flex: 0.6;
    padding: 0 34px 0 40px;
    display: flex;
    justify-content: space-between;
  }
  .display .work .groupDetail {
    display: flex;
    align-items: center;
  }
  .display .work .groupDetail .detail {
    display: flex;
    flex-direction: column;
    padding: 0 14px;
    row-gap: 2px;
  }
  .display .work .groupDetail .detail p {
    color: var(--agt-primary-color-1);
  }
  .display .work .groupDetail .detail small {
    color: var(--agt-primary-text-color);
  }
  .display .work .groupIcon {
    display: flex;
    align-items: center;
    column-gap: 18px;
  }
  .display .work .groupIcon .icon {
    width: 40px;
    height: 40px;
    border: 1px solid var(--agt-primary-color-1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const WebTour = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 8
  const layout = [
    {
      top: '64px',
      right: 'unset',
      width: '259px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '308px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: '-174px',
      width: '323px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '323px',
      arrow: 'top',
    },
    {
      top: '64px',
      right: 'unset',
      width: '191px',
      arrow: 'top',
    },
    {
      top: '-200px',
      right: 'calc(100% - 314px)',
      width: '218px',
      arrow: 'bottomRight',
    },
    {
      top: '224px',
      right: 'calc(100% - 324px)',
      width: '214px',
      arrow: 'top',
    },
    {
      top: '276px',
      right: '-25px',
      width: '283px',
      arrow: 'top',
    },
  ]
  const [step, setStep] = useState(1)

  const [height, setHeight] = useState<string>('100vh')
  const [statusPosition, setStatusPosition] = useState({
    top: '0',
    left: '0',
  })
  const [filterPosition, setFilterPosition] = useState({
    top: '0',
    left: '0',
  })
  const [createBtnPosition, setCreateBtnPosition] = useState({ top: '0', left: '0' })
  const [tableNavbarPosition, setTableNavbarPosition] = useState({ top: '0', left: '0' })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('task__option', setStatusPosition)
      getPositionById('task__filter', setFilterPosition)
      getPositionById('task__create-work', setCreateBtnPosition)
      getPositionById('task__table', setTableNavbarPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    // document.addEventListener('scroll', getPosition)
    return () => {
      // document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>คลิกเพื่อแก้ไขหรือลบข้อมูลรายการงานที่ต้องทำนี้</small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>คลิกเพื่อสลับมุมมองตามสถานะงานที่ต้องทำ</small>
        </div>
      )
    } else if (step === 2) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>คลิกมุมมองงานที่ยังไม่เสร็จเพื่อดูรายการงานที่ต้องทำ</small>
        </div>
      )
    } else if (step === 3) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>
            คลิกมุมมองงานที่ทำเสร็จแล้วเพื่อดูรายการงาน
            <br />
            ที่ปรับเป็นทำเสร็จแล้ว
          </small>
        </div>
      )
    } else if (step === 4) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>คลิกเพื่อเปิดตัวกรองข้อมูลรายการงานตามมุมมองที่ดูอยู่</small>
        </div>
      )
    } else if (step === 5) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>คลิกเพื่อสร้างงานที่ต้องทำ&nbsp;&nbsp;&nbsp;&nbsp;</small>
        </div>
      )
    } else if (step === 6) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>
            แสดงรายการงานตามช่วงเวลาต่างๆ
            <br />
            โดยสามารถเปิดปิดรายการงาน
            <br />
            ตามแต่ละช่วงเวลาได้
          </small>
        </div>
      )
    } else if (step === 7) {
      return (
        <div className="top">
          <b>งานที่ต้องทำ</b>
          <small>
            คลิกเพื่อทำเครื่องหมายหน้ารายการ
            <br />
            งานที่ต้องการปรับสถานะ
          </small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1) {
      return (
        <WorkSelect>
          <div className="incompletedBtn">งานที่ยังไม่เสร็จ</div>
          <div className="completedBtn">งานที่ทำเสร็จแล้ว</div>
        </WorkSelect>
      )
    } else if (step === 2) {
      return (
        <WorkSelect>
          <div className="incompletedBtn">งานที่ยังไม่เสร็จ</div>
        </WorkSelect>
      )
    } else if (step === 3) {
      return (
        <WorkSelect className="flexEnd">
          <div className="completedBtn">งานที่ทำเสร็จแล้ว</div>
        </WorkSelect>
      )
    } else if (step === 4) {
      return (
        <CustomButton type="button" className="selectBtn">
          ปรับตัวเลือก
        </CustomButton>
      )
    } else if (step === 5) {
      return (
        <CustomButton type="button" className="createBtn">
          + สร้างงานที่ต้องทำ
        </CustomButton>
      )
    }
    return (
      <TaskStack top={tableNavbarPosition.top} left={tableNavbarPosition.left}>
        <div className="title late">
          เกินกำหนด
          <Icon name="vector_bottom" />
        </div>
        <div className="title">
          วันนี้ <Icon name="vector_top" />
        </div>
        <div className="title">
          สัปดาห์นี้ <Icon name="vector_bottom" />
        </div>
        <div className="title">
          สัปดาห์หน้า <Icon name="vector_bottom" />
        </div>
        <div className="title">
          กำลังมาถึง (1) <Icon name="vector_bottom" />
        </div>
        <div className="display">
          <div className="personal">
            <div className="circle" />
            <div className="text">
              <div className="textOverLine">
                โทรตามเอกสาร โทรตามเอกสารโทรตาม เอกสารโทรตามเอกสารโทรตามเอกสาร...
              </div>
              <small className="textOverLine">เตรียมเอกสารเตรียมเอกสารเตรียมเอกสารเตรียม...</small>
              <div className="date">
                <div className="calendar">
                  <Icon name="calendarDatePicker" color="var(--agt-secondary-color-2)" />
                  <small>15/06/2563</small>
                </div>
                <div className="star">
                  <Icon name="star" color="var(--agt-primary-text-color)" />
                  <Icon name="star" color="var(--agt-primary-text-color)" />
                  <Icon name="star" color="var(--agt-primary-text-color)" />
                </div>
              </div>
            </div>
          </div>
          <div className="work">
            <div className="groupDetail">
              <Avatar avatar="" type="circle" />
              <div className="detail">
                <p>โรแลนด์ แบงส์</p>
                <small>นักร้อง</small>
                <small>087-111-1111</small>
              </div>
            </div>
            <div className="groupIcon">
              <div className="icon">
                <Icon name="pencil_icon" color="var(--agt-primary-color-1)" />
              </div>
              <div className="icon">
                <Icon name="bin" color="var(--agt-primary-color-1)" />
              </div>
            </div>
          </div>
        </div>
      </TaskStack>
    )
  }, [step, tableNavbarPosition])

  const StyledPosition = () => {
    if (step === 1) {
      return statusPosition
    } else if (step === 2) {
      return statusPosition
    } else if (step === 3) {
      return statusPosition
    } else if (step === 4) {
      return filterPosition
    } else if (step === 5) {
      return createBtnPosition
    } else if (step === 6 || step === 7 || step === 8) {
      return tableNavbarPosition
    }
    return { top: 0, left: 0 }
  }
  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTour
