import styled from '@emotion/styled'
import React, { useMemo } from 'react'
import { Caption, InputAndUnitContainer, Unit } from './Input'
import Select from 'react-select'

const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 24px;
`

const CustomSelect = styled(Select)`
  width: 214px;
  height: fit-content;
  > div {
    border: ${(props) =>
      props.errors ? '1px solid #f45a49 !important' : '1px solid #a0a8bc !important'};
    min-height: 48px;
    > div:last-of-type {
      > span {
        display: none;
      }
    }
  }
`

const SelectInput = (props) => {
  let value = useMemo(() => {
    let value = ''
    if (props.options) {
      value = props.options.find((x) => x.value === props.field.value)
    }
    return value
  }, [props.field, props.options])

  return (
    <MainLayout className={props.className}>
      {props.caption && <Caption>{props.caption}</Caption>}
      <InputAndUnitContainer>
        <CustomSelect
          {...props.field}
          {...props}
          value={value || ''}
          onChange={
            props.onChange
              ? props.onChange
              : (value) => {
                  if (value) {
                    props.form.setFieldValue(props.field.name, value.value)
                  } else {
                    props.form.setFieldValue(props.field.name, value)
                  }
                }
          }
          className
        />
        <Unit>{props.unit}</Unit>
      </InputAndUnitContainer>
    </MainLayout>
  )
}

export default SelectInput
