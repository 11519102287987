import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Api from 'api'
import SuccessModal from 'components/SuccessModal'
import { emailCensorer } from 'utils/helper'
import SetStateType from 'types/SetStateType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--agt-primary-color-1);
  width: 100%;
  height: 374px;
`
const Header = styled.h4`
  margin-bottom: 45px;

  color: var(--agt-white-color);
  text-align: center;
`
const Content = styled.div`
  text-align: center;
  color: var(--agt-white-color);
  margin-bottom: 15px;
`
const ResendButton = styled.button`
  width: 352px;
`
type SuccessProps = {
  email: string
  resend: boolean
}

const reSendConfirm = async (email: string, setIsPopup: SetStateType<boolean>) => {
  try {
    await Api.post('/auth/resend_confirmation/', { email: email })
    setIsPopup(true)
  } catch (err) {
    console.error(err || err.response)
  }
}

const Success: FC<SuccessProps> = ({ email, resend }) => {
  const [isPopup, setIsPopup] = useState(false)

  useEffect(() => {
    if (resend && email) {
      reSendConfirm(email, setIsPopup)
    }
  }, [resend, email])

  return (
    <Container>
      <SuccessModal
        isOpen={isPopup}
        onCancel={() => {
          setIsPopup(false)
        }}
        onConfirm={() => {
          setIsPopup(false)
        }}
        state={'register'}
      ></SuccessModal>
      <Header>
        ระบบได้ส่งอีเมลไปที่ {emailCensorer(email, 3)}
        <br />
        กรุณาตรวจสอบอีเมลของคุณเพื่อดำเนินการยืนยันตัวตน
        <br />
        ภายใน 30 นาที
      </Header>
      <Content>
        หากไม่พบอีเมล โปรดตรวจสอบในอีเมลขยะ หรือ คลิกปุ่มด้านล่างให้ระบบส่งอีเมลอีกครั้ง
      </Content>
      <ResendButton
        className="secondary"
        type="button"
        onClick={() => {
          reSendConfirm(email, setIsPopup)
        }}
      >
        คลิกที่นี่เพื่อขออีเมลยืนยันอีกครั้ง
      </ResendButton>
    </Container>
  )
}

export default Success
