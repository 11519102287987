import styled from '@emotion/styled'
import { BackgroundLoadingType } from './NewAct.type'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const Form = styled.div`
  width: 68%;
  padding: 40px 0 0 18px;
`

export const BackgroundLoading = styled.div<BackgroundLoadingType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100vw;
  height: ${(props) => (props.height ? props.height : '100vh')};
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.3s;
  > div {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`
