import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Api from 'api'
import SetStateType from 'types/SetStateType'
import { useParams } from 'routes'

const Container = styled.div`
  padding-bottom: 35px;
`

const Header = styled.h4`
  padding-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const NewMainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding: 8px 0 10px;

  border-bottom: 1px solid var(--agt-secondary-color-1);

  .NewBody.Header {
    color: var(--agt-primary-color-1);
  }

  .NewRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .NewLeftColumn {
  }
  .NewRightColumn {
  }

  .Topic {
    font-weight: bold;
    margin-top: 28px;
    margin-bottom: 26px;
    color: var(--agt-primary-text-color);
  }
`

type optionsType = {
  value: string
  label: string
}

type insuranceSummaryType = {
  accidentInsuranceSummary: Array<optionsType>
  lifeInsuranceSummary: Array<optionsType>
  healthInsuranceSummary: Array<optionsType>
}

const getInsuranceSummary = async (
  setInsuranceSummary: SetStateType<insuranceSummaryType>,
  pathApi: string,
) => {
  try {
    const response = await Api.get(pathApi)
    setInsuranceSummary(response.data.data.insurancePortsSummaryData)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export function financial(x: string) {
  let amount: string
  amount = Number.parseFloat(x).toFixed(2)
  return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function totallyHealthcare(x: any) {
  let summaryHealtyCare = 0
  for (var i = 0; i < x.length; i++) {
    summaryHealtyCare += x[i].value
  }
  return summaryHealtyCare
}

const Summary: FC = () => {
  let { id }: any = useParams()
  const pathApi = `/contacts/${id}/insurance_ports/summary`
  const [insuranceSummary, setInsuranceSummary] =
    useState<insuranceSummaryType>({
      accidentInsuranceSummary: [{ label: '', value: '' }],
      lifeInsuranceSummary: [{ label: '', value: '' }],
      healthInsuranceSummary: [{ label: '', value: '' }],
    }) || null

  useEffect(() => {
    getInsuranceSummary(setInsuranceSummary, pathApi)
  }, [setInsuranceSummary, pathApi])

  return (
    <Container>
      <Header>สรุปความคุ้มครอง</Header>

      <NewMainContent>
        <div className="NewBody">
          <div className="Topic">ประกันชีวิต</div>
          {insuranceSummary.lifeInsuranceSummary.map((value, index) => (
            <div className="NewRow" key={index}>
              <div className="NewLeftColumn">{value.label}</div>
              <div className="NewRightColumn">{financial(value.value)} บาท</div>
            </div>
          ))}
        </div>
      </NewMainContent>

      <NewMainContent>
        <div className="NewBody">
          <div className="Topic">ประกันสุขภาพ</div>
          {/* {insuranceSummary.healthInsuranceSummary.map((value, index) => (
            <>
              {index === insuranceSummary.healthInsuranceSummary.length - 1 ? (
                <div className="NewRow" key={index}>
                  <div className="NewLeftColumn">
                    <b>รวม</b>
                  </div>
                  <div className="NewRightColumn">บาท</div>
                </div>
              ) : (
                <div className="NewRow" key={index}>
                  <div className="NewLeftColumn">{value.label}</div>
                  <div className="NewRightColumn">{financial(value.value)} บาท</div>
                </div>
              )}
            </>
          ))} */}
          {insuranceSummary.healthInsuranceSummary.map((value, index) => (
            <div className="NewRow" key={index}>
              <div className="NewLeftColumn">{value.label}</div>
              <div className="NewRightColumn">{financial(value.value)} บาท</div>
            </div>
          ))}
          <div className="NewRow">
            <div className="NewLeftColumn">
              <b>รวม</b>
            </div>
            <div className="NewRightColumn">
              {financial(totallyHealthcare(insuranceSummary.healthInsuranceSummary).toString())} บาท
            </div>
          </div>
        </div>
      </NewMainContent>

      <NewMainContent>
        <div className="NewBody">
          <div className="Topic">ประกันอุบัติเหตุ</div>
          {insuranceSummary.accidentInsuranceSummary.map((value, index) => (
            <div className="NewRow" key={index}>
              <div className="NewLeftColumn">{value.label}</div>
              <div className="NewRightColumn">{financial(value.value)} บาท</div>
            </div>
          ))}
        </div>
      </NewMainContent>
    </Container>
  )
}

export default Summary
