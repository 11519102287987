import React, { FC, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { FormikErrors } from 'formik'

import Icon from 'components/Icon'

const Container = styled.div`
  position: absolute;
  top: 34px;
  left: calc(100% + 50px);

  width: 100%;
  padding: 18px 30px;

  border-radius: 4px;
  border: 1px solid var(--agt-background-color-1);
  @media (max-width: 1024px) {
    font-size: 13px;
    padding: 18px;
    max-width: 270px;
  }
`
const Title = styled.div`
  margin-bottom: 8px;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`
const CheckIcon = styled(Icon)`
  height: 20px;
  margin-right: 10px;

  path {
    fill: var(--agt-secondary-text-color);
  }

  &.success {
    path {
      fill: var(--agt-success-color);
    }
  }
`

type PasswordValues = {
  password: string
  passwordConfirmation: string
}
export const validate = (values: PasswordValues) => {
  let errors: FormikErrors<PasswordValues> = {}

  if (!values.password) {
    errors.password = 'กรุณาระบุรหัสผ่าน'
  } else if (values.password.length < 8) {
    errors.password = 'ต้องไม่น้อยกว่า 8 ตัวอักษร'
  } else {
    const passwordValidated = validator(values.password)
    const isPassed =
      passwordValidated.hasUppercase && passwordValidated.hasLowercase && passwordValidated.hasDigit
    if (!isPassed) errors.password = 'รูปแบบไม่ถูกต้อง'
  }
  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'กรุณาระบุรหัสผ่าน'
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'รหัสผ่านไม่ตรงกัน'
  }

  return errors
}

export const validator = (text: string) => {
  const lower = /[a-z]/
  const upper = /[A-Z]/
  const digit = /[0-9]/
  // return pattern.test(text)
  return {
    hasLowercase: lower.test(text),
    hasUppercase: upper.test(text),
    hasDigit: digit.test(text),
  }
}
const ValidateBox: FC<ValidatorTypes> = (props) => {
  const { values } = props
  const [passed, setPassed] = useState({
    hasLowercase: false,
    hasUppercase: false,
    hasDigit: false,
  })

  useEffect(() => {
    const passwordValidated = validator(values.password)
    setPassed({
      hasLowercase: passwordValidated.hasLowercase,
      hasUppercase: passwordValidated.hasUppercase,
      hasDigit: passwordValidated.hasDigit,
    })
  }, [values])

  return (
    <Container>
      <Title>รหัสผ่านขั้นต่ำ 8 ตัวอักษร ต้องประกอบด้วย</Title>
      <Row>
        <CheckIcon name="check-circle" className={passed.hasUppercase ? 'success' : ''} />
        A-Z ตัวพิมพ์ใหญ่อย่างน้อย 1 ตัวอักษร
      </Row>
      <Row>
        <CheckIcon name="check-circle" className={passed.hasLowercase ? 'success' : ''} />
        a-z ตัวพิมพ์เล็กอย่างน้อย 1 ตัวอักษร
      </Row>
      <Row>
        <CheckIcon name="check-circle" className={passed.hasDigit ? 'success' : ''} />
        0-9 อย่างน้อย 1 ตัวอักษร
      </Row>
    </Container>
  )
}
type ValidatorTypes = {
  values: {
    password: string
    passwordConfirmation: string
  }
}

export default ValidateBox
