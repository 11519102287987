import React from 'react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import Select from 'react-select'

type OptionType = {
  label: string
  value: string
}

const LicenseTypeStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '214px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '216px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const InsuranceCompanyNameStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: () => ({
    color: 'var(--agt-primary-color-text)',
  }),
}

const InsuranceCompanyNameDisabled = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-background-color-1);',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: () => ({
    color: 'var(--agt-primary-color-text)',
  }),
}

const InsuranceCompanyNameError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const PrefixNameStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const PrefixNameStylesDisabled = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-background-color-1);',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle) => ({
    ...defaultStyle,
    color: 'var(--agt-primary-color-text)',
  }),
}

const PrefixNameStylesError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

export const LicenseTypeComponent = (props) => {
  const { options, className = '', isDisabled = false } = props
  const [, meta, helpers] = useField(props.field.name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: '12px',
        marginLeft: '12px',
      }}
    >
      <label style={{ marginBottom: '4px', visibility: 'collapse' }}>""</label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: OptionType) => {
          setValue(option.value)
        }}
        className={className}
        styles={LicenseTypeStyles}
        options={options}
        placeholder="โปรดระบุ"
        isDisabled={isDisabled}
      />
    </div>
  )
}

export const InsuranceCompanyNameComponent = (props) => {
  const { options, isDisabled = false } = props
  const [, meta, helpers] = useField(props.field.name)
  const { value } = meta
  const { setValue, setTouched } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor ? defaultSeletor : ''}
      onChange={(option: OptionType) => {
        setValue(option.value)
      }}
      onBlur={() => setTouched(true)}
      options={options}
      styles={
        meta.touched && meta.error
          ? InsuranceCompanyNameError
          : isDisabled
          ? InsuranceCompanyNameDisabled
          : InsuranceCompanyNameStyles
      }
      placeholder="โปรดระบุ"
      isDisabled={isDisabled}
    />
  )
}

export const PrefixNameComponent = (props) => {
  const { options, className = '', label, isDisabled = false } = props
  const [, meta, helpers] = useField(props.field.name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
      }}
    >
      <label style={{ marginBottom: '4px' }}>
        {label} <label style={{ color: 'var(--agt-error-color)' }}>*</label>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: OptionType) => {
          setValue(option.value)
        }}
        className={className}
        styles={
          meta.touched && meta.error
            ? PrefixNameStylesError
            : isDisabled
            ? PrefixNameStylesDisabled
            : PrefixNameStyles
        }
        options={options}
        placeholder="เลือก"
        isDisabled={isDisabled}
      />
      {meta.touched && meta.error && (
        <small style={{ marginTop: 8, color: 'var(--agt-error-color)' }}>{meta.error}</small>
      )}
    </div>
  )
}
