import React, { FC } from 'react'
import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { Link } from 'routes'

// const path = '/profile/recruitment/download'

const Container = styled.footer`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: var(--agt-white-color);
  height: 200px;
  width: 100%;
  margin-top: 32px;
  background-color: var(--agt-primary-text-color);
  position: relative;
  @media only screen and (max-width: 1024px) {
    padding: 0 37px;
  }
`
const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50%;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  color: var(--agt-white-color);
  border-top: 1px solid var(--agt-white-color);
  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
const GroupIcons = styled.div`
  display: flex;
  align-items: flex-end;
`
const CustomIcon = styled(Icon)`
  height: 28px;
  margin-left: 20px;
  svg {
    height: 28px;
  }
`

const Footer: FC = () => (
  <Container>
    <Body>
      <small className="version">Ver. 2.0.0</small>
      <div className="copyright">
        <small>
          Copyright © 2021 TQD สงวนสิทธิ์ทุกประการ ข้อกำหนดการใช้งาน | นโยบายความเป็นส่วนตัว
        </small>
        <GroupIcons>
          <Link
            // to={{ pathname: path, state: 'true' }}
            to="/branches"
            className="ghost"
            style={{ color: 'var(--agt-primary-color-text)' }}
          >
            <small>สาขา TQM</small>
          </Link>
          <CustomIcon className="layout" name="facebook" />
          <CustomIcon className="layout" name="line" />
        </GroupIcons>
      </div>
    </Body>
  </Container>
)

export default Footer
