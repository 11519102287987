import styled from '@emotion/styled'
import BreadCrumb from 'components/BreadCrumb'

export const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
  font-size: 17px;
`
export const Header = styled.h3`
  margin-bottom: 10px;
`
export const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`
export const DropleadContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
