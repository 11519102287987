export const today = new Date()

export const fixedOptions = {
  coutries: [
    { value: '999', label: 'ประเทศไทย' },
    { value: '2', label: 'ประเทศญี่ปุ่น' },
  ],
  travelPassenger: [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
  ],
  province: [
    { value: '001', label: 'กรุงเทพ' },
    { value: '002', label: 'ขอนแก่น' },
    { value: '003', label: 'นครศรีธรรมราช' },
  ],
}
