import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  padding: 16px 0 0;
  .loading {
    text-align: center;
    margin: 24px;
  }
`

export const Header = styled.h3`
  margin-bottom: 10px;
`
