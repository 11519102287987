import format from 'date-fns/format'
import addYears from 'date-fns/addYears'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import startOfQuarter from 'date-fns/startOfQuarter'
import endOfQuarter from 'date-fns/endOfQuarter'
import startOfYear from 'date-fns/startOfYear'
import endOfYear from 'date-fns/endOfYear'

import { PeriodType, MonthlyType, QuarterlyType, DatePickerValueType } from 'types/DatePickerType'

export const TODAY = new Date()
export const THIS_MONTH = TODAY.getMonth()
export const THIS_QUARTER = Math.floor((THIS_MONTH + 3) / 3)
export const THIS_YEAR = TODAY.getFullYear()

export const DAYS_IN_WEEK_TH = ['จันทร์', 'อังคาร', 'พุธ', 'พฤหัส', 'ศุกร์', 'เสาร์', 'อาทิตย์']

export const MONTH_TH = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

export const MONTH_SHORT_TH = [
  'ม.ค.',
  'ก.พ.',
  'มี.ค.',
  'เม.ย.',
  'พ.ค.',
  'มิ.ย.',
  'ก.ค.',
  'ส.ค.',
  'ก.ย.',
  'ต.ค.',
  'พ.ย.',
  'ธ.ค.',
]

export const PERIOD_OPTIONS: Array<{ label: string; value: PeriodType }> = [
  { label: 'วัน', value: 'daily' },
  { label: 'เดือน', value: 'monthly' },
  { label: 'ไตรมาส', value: 'quarterly' },
  { label: 'ปี', value: 'yearly' },
]

export const QUARTER_TH = [
  'มกราคม - มีนาคม',
  'เมษายน - มิถุนายน',
  'กรกฎาคม - กันยายน',
  'ตุลาคม - ธันวาคม',
]

export const QUARTER_SHORT_TH = ['ม.ค.-มี.ค.', 'เม.ย.-มิ.ย.', 'ก.ค.-ก.ย.', 'ต.ค.-ธ.ค.']

export const getYearOptions = (year: number) => {
  if (!year) return
  const toBD = year + 543
  const lastDigit = (toBD + '')[3]
  switch (lastDigit) {
    case '1':
    case '6':
      return [year, year + 1, year + 2, year + 3, year + 4]
    case '2':
    case '7':
      return [year - 1, year, year + 1, year + 2, year + 3]
    case '3':
    case '8':
      return [year - 2, year - 1, year, year + 1, year + 2]
    case '4':
    case '9':
      return [year - 3, year - 2, year - 1, year, year + 1]
    case '5':
    case '0':
      return [year - 4, year - 3, year - 2, year - 1, year]
    default:
      break
  }
}

function date2String(param: Date) {
  return format(addYears(param, 543), 'dd/MM/yyyy')
}

function monthly2Sring(param: MonthlyType) {
  return `${param.month + 1}/${param.year + 543}`
}

function quarterly2String(param: QuarterlyType) {
  const { quarter, year } = param
  let quarterText = ''
  switch (quarter) {
    case 1:
      quarterText = '01-03'
      break
    case 2:
      quarterText = '04-06'
      break
    case 3:
      quarterText = '07-09'
      break
    case 4:
      quarterText = '10-12'
      break
    default:
      break
  }
  return `${quarterText}/${year + 543}`
}

export function getDatePickerValue2String(input: DatePickerValueType) {
  const { periodType, date, monthly, quarterly, yearly } = input
  let periodText = PERIOD_OPTIONS.find((p) => p.value === periodType)?.label
  let periodValue = ''
  switch (periodType) {
    case 'daily':
      periodValue = date2String(date)
      break
    case 'monthly':
      periodValue = monthly2Sring(monthly)
      break
    case 'quarterly':
      periodValue = quarterly2String(quarterly)
      break
    case 'yearly':
      periodValue = (yearly + 543).toString()
      break
    default:
      break
  }
  return { periodText, periodValue }
}

export function getDateRangeFormDate(input: Date) {
  if (!input) return [null, null]
  return [startOfWeek(input, { weekStartsOn: 1 }), endOfWeek(input, { weekStartsOn: 1 })]
}

export function getDateRangeFromMonthAndYear({ month, year }: MonthlyType) {
  const selectedMonth = new Date(year, month, 1)
  return [startOfMonth(selectedMonth), endOfMonth(selectedMonth)]
}

export function getDateRangeFromQuarterAndYear({ quarter, year }: QuarterlyType) {
  const selectedQuater = new Date(year, quarter * 3 - 1, 1)
  return [startOfQuarter(selectedQuater), endOfQuarter(selectedQuater)]
}

export function getDateRangeFromYear(year: number) {
  return [startOfYear(new Date(year, 0, 1)), endOfYear(new Date(year, 11, 31))]
}

export function modifiedDatePickerValue(param: DatePickerValueType) {
  const { periodType, date, monthly, quarterly, yearly } = param

  switch (periodType) {
    case 'daily':
      const [startDate, endDate] = getDateRangeFormDate(date)
      return { min: startDate, max: endDate }
    case 'monthly':
      const [startMonth, endMonth] = getDateRangeFromMonthAndYear(monthly)
      return { min: startMonth, max: endMonth }
    case 'quarterly':
      const [startQuarter, endQuarter] = getDateRangeFromQuarterAndYear(quarterly)
      return { min: startQuarter, max: endQuarter }
    case 'yearly':
      const [startYear, endYear] = getDateRangeFromYear(yearly)
      return { min: startYear, max: endYear }
    default:
      return { min: null, max: null }
  }
}

export function getThisMonthTh() {
  return MONTH_TH[THIS_MONTH]
}

export function getThisMonthShortTh() {
  return MONTH_SHORT_TH[THIS_MONTH]
}

export function getThisQuarterTh() {
  return QUARTER_TH[THIS_QUARTER]
}

export function getThisQuarterShortTh() {
  return QUARTER_SHORT_TH[THIS_QUARTER - 1]
}

export function getThisYearTh() {
  return THIS_YEAR + 543
}
