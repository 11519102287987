import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Login from 'pages/login/root'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/login" component={Login} />
  </Switch>
)

export default Pages
