import React, { FC, useEffect } from 'react'
import { Formik } from 'formik'
import { RootState } from 'store/rootReducer'
import { useDispatch, useSelector } from 'react-redux'
import { DocumentsType, OptionsType } from 'types/RegisterType'
import { getDocuments, editDocuments } from 'store/profileSlice'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import DocumentsForm from './DocumentForm'
import validate from './validation'
import { Container, Header } from './index.styles'

const Documents: FC = () => {
  const dispatch = useDispatch()
  const documents: DocumentsType = useSelector((state: RootState) => state.profile.documents)
  const options: OptionsType = useSelector((state: RootState) => state.profile.options)
  const isLoading: boolean = useSelector((state: RootState) => state.profile.initialized)
  const breadCrumbArr: Array<LinkValues> = [{ path: '/documents', label: 'เอกสารตัวแทน' }]

  useEffect(() => {
    dispatch(getDocuments())
  }, [dispatch])

  return (
    <Container>
      <Header>เอกสารตัวแทน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {isLoading ? (
        <p className="loading">Loading...</p>
      ) : (
        <Formik
          enableReinitialize
          initialValues={{ ...documents, recruits: documents?.recruits || [] }}
          onSubmit={(values: DocumentsType) => {
            let modifiedValues: DocumentsType = { ...values }
            delete modifiedValues.isApproved
            delete modifiedValues.recruitsStatus
            dispatch(editDocuments(modifiedValues))
          }}
          validate={validate}
        >
          {(propsFormik) => {
            return <DocumentsForm {...propsFormik} options={options} />
          }}
        </Formik>
      )}
    </Container>
  )
}
export default Documents
