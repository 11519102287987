import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'

import Icon from 'components/Icon'

type ContainerProps = {
  iframe: boolean
}
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: ${(props) => (props.iframe ? 'none' : ' 1px solid var(--agt-secondary-color-1)')};

  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    svg {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    margin: 0 11px;
  }
`
const RightIcon = styled(Icon)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 11px;

  svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const LinkContainer = styled.div`
  display: flex;
  align-items: center;
`
export type LinkValues = {
  path?: string | null | {}
  label: string
}

export type BreadCrumbLinkProps = {
  className?: string
  links: Array<LinkValues>
  hasBackToRoot?: boolean
  rootLink?: string
  iframe?: boolean
}

const BreadCrumb: FC<BreadCrumbLinkProps> = ({ className = '', links, iframe = false }) => (
  <Container className={className} iframe={iframe}>
    <LinkContainer>
      {links.map(({ path, label }, index) => {
        return (
          <React.Fragment key={index}>
            {index !== 0 && <RightIcon name="vector_right_black" />}
            {path ? (
              <Link to={path} className="ghost" style={{ color: 'var(--agt-primary-text-color)' }}>
                {index + 1 === links.length ? <b>{label}</b> : label}
              </Link>
            ) : (
              <div>{index + 1 === links.length ? <b>{label}</b> : label}</div>
            )}
          </React.Fragment>
        )
      })}
    </LinkContainer>
  </Container>
)

export default BreadCrumb
