export const chart = {
  a: [
    { key: 'Life', value: '10000' },
    { key: 'health', value: '10000' },
    { key: 'Car', value: '10000' },
    { key: 'Fire', value: '10000' },
  ],

  b: [
    { key: 'Life', value: '20000' },
    { key: 'health', value: '40000' },
    { key: 'Car', value: '30000' },
    { key: 'Fire', value: '50000' },
  ],

  c: [
    { key: 'Life', value: '10000' },
    { key: 'health', value: '50000' },
    { key: 'Car', value: '20000' },
    { key: 'Fire', value: '52200' },
  ],
}

export const Nochart = {
  a: [
    { key: 'Life', value: '20000' },
    { key: 'health', value: '10000' },
  ],
}

export const dataA = {
  datasets: [
    {
      data: chart.a.map((key) => key.value),

      backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
      hoverBorderColor: '#7E5FFF',
      hoverBorderWidth: 1,
      borderWidth: 0,
    },
  ],
}
