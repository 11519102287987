import styled from '@emotion/styled'
import { Form } from 'formik'
import Select from 'react-select'

export const Container = styled(Form)`
  margin-top: 52px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  .firstName,
  .lastName {
    width: 100%;
    max-width: 272px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .birthdate,
  .idCard,
  .email,
  .phoneNumber,
  .plateNumber,
  .startDate,
  .endDate,
  .kkkkk,
  .blank {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .firstName,
  .lastName,
  .kkkkk {
    > label span {
      color: var(--agt-white-color);
    }
  }
  .birthdate,
  .idCard,
  .email,
  .phoneNumber,
  .plateNumber,
  .startDate,
  .endDate {
    > label span {
      color: var(--agt-error-color);
    }
  }
  .prefixName {
    width: 100%;
    max-width: 160px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .prefixName > div:last-of-type {
    width: 100%;
  }
  .insuranceCompany,
  .ffff {
    width: 100%;
    max-width: 736px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .insuranceCompany > div:last-of-type,
  .ffff > div:last-of-type {
    width: 100%;
  }
  .aaaa,
  .bbbb,
  .cccc,
  .dddd,
  .eeee,
  .provice {
    width: 100%;
    max-width: 360px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .aaaa > div:last-of-type,
  .bbbb > div:last-of-type,
  .cccc > div:last-of-type,
  .dddd > div:last-of-type,
  .eeee > div:last-of-type,
  .provice > div:last-of-type {
    width: 100%;
  }
  .eeee,
  .prefixName,
  .insuranceCompany {
    > label span {
      color: var(--agt-white-color);
    }
  }
  .aaaa,
  .bbbb,
  .cccc,
  .dddd,
  .ffff,
  .provice,
  .dDate {
    > label span {
      color: var(--agt-error-color);
    }
  }
  .dDate {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .dDate > div:last-of-type {
    width: 100%;
    max-width: 360px;
  }
  .topic {
    width: 100%;
    margin-top: 24px;
  }
  .submitBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 16px;
  }
`

export const OtherComponent = styled.div`
  &.checkup,
  &.policy {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    .choice {
      display: flex;
      column-gap: 52px;
    }
    .choice label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }

    .choice label:hover {
      cursor: pointer;
    }
    .choice label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    .choice label input {
      display: none;
    }
    .choice label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    .choice label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    .choice label input:checked ~ .checkmark:after {
      display: block;
    }
    .choice label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
  &.notice {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 8px;
    > input {
      display: none;
    }
    > .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    > .checkmark .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      font-size: 10px;
      color: var(--agt-white-color);
    }
    > input:checked ~ .checkmark {
      background-color: var(--agt-primary-color-1);
    }
    > input:checked ~ .checkmark .icon {
      display: block;
    }
  }
  &.idCar {
    position: relative;
    width: 100%;
    max-width: 328px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    @media (max-width: 1024px) {
      /* max-width: 272px; */
      max-width: 230px;
    }
  }
  &.idCar {
    > label span {
      color: var(--agt-error-color);
    }
  }
  &.idCar .icon {
    position: absolute;
    top: 40px;
    right: -36px;
    :hover {
      cursor: pointer;
    }
  }
  &.redLabel {
    width: 100%;
    max-width: 360px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 8px;
    @media (max-width: 1024px) {
      /* max-width: 316px; */
      max-width: 270px;
    }
    > input {
      display: none;
    }
    > .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    > .checkmark .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
      font-size: 10px;
      color: var(--agt-white-color);
    }
    > input:checked ~ .checkmark {
      background-color: var(--agt-primary-color-1);
    }
    > input:checked ~ .checkmark .icon {
      display: block;
    }
  }
`

export const AddressComponent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  .aaaaaa {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .aaaaaa > div:last-child {
    width: 100%;
    max-width: 360px;
  }
  .housingNo,
  .villageNo,
  .road,
  .land {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 360px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .housingNo > input,
  .villageNo > input,
  .road > input,
  .land > input {
    width: 100%;
  }
  .building {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 736px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .building > input {
    width: 100%;
  }
  .housingNo > label span {
    color: var(--agt-error-color);
  }
  .aaaaaa > label span,
  .villageNo > label span,
  .building > label span,
  .land > label span,
  .road > label span {
    color: var(--agt-white-color);
  }
  .topic {
    width: 100%;
    margin-top: unset;
  }
`

export const Error = styled.small`
  color: var(--agt-error-color);
`

export const OptionsStyles = {
  // container: (styles, { isDisabled }) => ({
  //   cursor: isDisabled ? 'not-allowed' : 'default',
  // }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: (styles, { isDisabled }) => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    color: isDisabled ? 'var(--agt-primary-text-color)' : 'var(--agt-primary-text-color)',
  }),
}

export const OptionsError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

export const Selector = styled(Select)`
  width: 356px;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
