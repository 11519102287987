import React, { FC } from 'react'
import { useHistory, useParams } from 'react-router'
import styled from '@emotion/styled'

import Avatar from 'components/Avatar'
import { currency } from 'utils/helper'
import HistoryType from 'types/HistoryType'
import { SearchStateType } from 'types/TravelInsuranceType'
import Prb from 'assets/images/Prb.png'

type CardType = {
  data: SearchStateType
  location: string
  setIsCompare: Function
  compareData: Array<SearchStateType>
  setCompareData: Function
}
type ParamsType = {
  id: string
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: var(--agt-background-color-1);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 8px;
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 14px;
  .price_text {
    color: var(--agt-primary-color-1);
    font-weight: 700;
    font-size: 24px;
    align-self: center;
  }
`
const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  gap: 4px;
  .insurance_name {
    color: var(--agt-primary-color-1);
    font-weight: 700;
    margin: 0;
  }
  .installment_text {
    text-align: center;
    font-size: 13px;
    color: var(--agt-white-color);
    background-color: var(--agt-primary-color-1);
    border-radius: 20px;
    padding: 1px 16px;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const InsuranceDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    color: var(--agt-primary-color-1);
    margin: 0 0 14px;
  }
  div {
    width: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 8px;
  }
  div label {
    margin: 0;
  }
  div:last-of-type {
    margin-bottom: 0px;
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: end;
`
const CheckboxWrapper = styled.div`
  label {
    margin: 0 0 0 8px;
    color: var(--agt-primary-color-1);
  }
`

const InsuranceCard: FC<CardType> = ({
  data,
  location,
  setIsCompare,
  compareData,
  setCompareData,
}) => {
  let { id } = useParams<ParamsType>()
  const history: HistoryType = useHistory()

  return (
    <CardWrapper>
      <HeaderWrapper>
        <AvatarWrapper>
          <Avatar avatar={data.companyLogoUrl || Prb} width="58px" height="58px" type="circle" />
          <TextWrapper>
            <label className="insurance_name">{data.productAbb}</label>
            <label className="insurance_name">{data.companyName}</label>
          </TextWrapper>
        </AvatarWrapper>
        <div className="price_text">{currency(data.netAmount, 2, '฿ ')}</div>
      </HeaderWrapper>
      <BodyWrapper>
        <InsuranceDetails>
          <label className="title">
            คุ้มครองบุคคลที่มีอายุ ระหว่าง {data?.minAge ? parseInt(data?.minAge) : 0} ถึง{' '}
            {data?.maxAge ? parseInt(data?.maxAge) : 0} ปี
          </label>
          <div>
            <label>ค่ารักษาพยาบาลระหว่างการเดินทาง</label>
            <label>{currency(data.coverDetail?.travelA02 || 0, 0, ': ')}</label>
          </div>
          <div>
            <label>ค่าเคลื่อนย้ายฉุกเฉิน</label>
            <label>{currency(data.coverDetail?.travelA04 || 0, 0, ': ')}</label>
          </div>
          <div>
            <label>ยกเลิกการเดินทาง / เลื่อนเที่ยวบิน</label>
            <label>{currency(data.coverDetail?.travelA06 || 0, 0, ': ')}</label>
          </div>
          <div>
            <label>เที่ยวบินล่าช้า</label>
            <label>{currency(data.coverDetail?.travelA08 || 0, 0, ': ')}</label>
          </div>
          <div>
            <label>ชดเชยค่ากระเป๋าเดินทางชำรุด / สูญหาย</label>
            <label>{currency(data.coverDetail?.travelA09 || 0, 0, ': ')}</label>
          </div>
        </InsuranceDetails>
        <ButtonWrapper>
          <CheckboxWrapper>
            <input
              id={`compare-${data.productId}`}
              type="checkbox"
              checked={!!compareData.find((d) => d.productId === data.productId)}
              onChange={(e) => {
                if (e.target.checked) {
                  setCompareData([...compareData, { ...data }])
                } else {
                  const removeData = compareData?.filter((d) => d.productId !== data.productId)
                  setCompareData(removeData)
                }
              }}
              disabled={
                compareData.length === 3 && !compareData.find((d) => d.productId === data.productId)
              }
            />
            <label htmlFor={`compare-${data.productId}`}>เปรียบเทียบ</label>
          </CheckboxWrapper>
          <button
            type="button"
            className="secondary"
            onClick={() => {
              setIsCompare(true)
              setCompareData([data])
            }}
          >
            รายละเอียด
          </button>
          <button
            type="button"
            onClick={() => {
              history.push({
                pathname: `/opportunities/${id}/catalog/travel/insurance_product/droplead/${data.productId}`,
                search: `${location}`,
              })
            }}
            // disabled
          >
            ซื้อเลย
          </button>
        </ButtonWrapper>
      </BodyWrapper>
    </CardWrapper>
  )
}

export default InsuranceCard
