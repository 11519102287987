import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { useParams } from 'routes'
import { Field, FieldArray, Form, Formik } from 'formik'
import SelectInput from 'pages/planning/share/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Currency from 'pages/planning/share/Currency'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  createDebtProtectionFreeAssetDatas,
  getDebtProtectionFreeAssetDatas,
  getDebtProtectionOptions,
} from 'store/planningSlice'
import Model from 'components/Modal'
import { useMemo } from 'react'
import SuccessModal from 'components/SuccessModal'

const Container = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  padding-right: 9.52%;
  padding-left: 9.52%;
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #393d43;
  margin-bottom: 24px;
  margin-left: 25px;
`
const Line = styled.div`
  height: 1px;
  background: #a0a8bc;
`

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: center;
`

const SubmitButton = styled.button`
  width: 183px;
  height: 48px;
  align-self: center;
`

const AddButton = styled.button`
  width: 219px;
  color: #00529a;
  background: #ffffff;
  border: 1px solid #00529a;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 24px;
  padding: 0 10px !important;
  :hover {
    color: white;
    background: #00529a;
  }
`
const TextNoDetailList = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #acb1bf;
  margin-bottom: 24px;
`

const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 42% 42% auto auto;
  grid-column-gap: 2%;
  align-items: baseline;
`
const Unit = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #393d43;
  width: fit-content;
`

const CustomSelect = styled(SelectInput)`
  > div {
    width: 100%;
    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`

const CustomInput = styled(Currency)`
  > div {
    width: 100%;
    > input {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`

const ButtonDelete = styled.div`
  cursor: pointer;
`

const ModelContainer = styled.div`
  width: 352px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TextModel = styled.div`
  text-align: center;
  margin-bottom: 8px;
`
const TextModelBlueColor = styled.div`
  text-align: center;
  color: var(--agt-primary-color-1);
`

const FieldArrayDetail = (props) => {
  const { name, remove, index, assetType, options } = props
  const [isOpen, setIsOpen] = useState(false)
  const labelTypeDelete = useMemo(
    () => (options.freeAssetOptions.find((item) => item.value === assetType) || {}).label,
    [assetType, options],
  )
  return (
    <InputContainer key={index}>
      <Field
        name={`${name}.${index}.assetType`}
        component={CustomSelect}
        placeholder="โปรดระบุ"
        options={options.freeAssetOptions}
      />
      <Field name={`${name}.${index}.assetValue`} component={CustomInput} placeholder="โปรดระบุ" />
      <Unit>บาท</Unit>
      <ButtonDelete
        onClick={() => {
          setIsOpen(true)
        }}
      >
        <FontAwesomeIcon icon={faTrashAlt} />
      </ButtonDelete>
      <Model
        isOpen={isOpen}
        onCancel={() => {
          setIsOpen(false)
        }}
        onConfirm={() => {
          remove(index)
          setIsOpen(false)
        }}
      >
        <ModelContainer>
          <TextModel>ยืนยันการลบข้อมูลภาระหนี้สิน</TextModel>
          <TextModelBlueColor>{labelTypeDelete}</TextModelBlueColor>
        </ModelContainer>
      </Model>
    </InputContainer>
  )
}

const Pages: FC = (props) => {
  const dispatch = useDispatch()
  const freeAssetData = useSelector((state: RootState) => state.plans.freeAssetData)
  const options = useSelector((state: RootState) => state.plans.options)
  const { id }: any = useParams()
  const [isPopupSuccess, setIsPopupSuccess] = useState(false)

  useEffect(() => {
    dispatch(getDebtProtectionFreeAssetDatas(id))
    dispatch(getDebtProtectionOptions(id))
  }, [dispatch, id])

  return (
    <Formik
      initialValues={{ freeAssetData }}
      enableReinitialize
      onSubmit={(values) => {
        dispatch(createDebtProtectionFreeAssetDatas(id, values))
        setIsPopupSuccess(true)
      }}
    >
      {(props) => (
        <Container onSubmit={props.handleSubmit}>
          {!get(props.values, 'freeAssetData', false) ||
          get(props.values, 'freeAssetData', []).length === 0 ? (
            <TextNoDetailList>ใส่รายละเอียดภาระหนี้สินเพื่อใช้ในการคำนวณ</TextNoDetailList>
          ) : (
            <InputContainer>
              <Text>ประเภท</Text>
              <Text>มูลค่าหนี้สินคงค้าง</Text>
            </InputContainer>
          )}
          <SuccessModal
            isOpen={isPopupSuccess}
            onCancel={() => {
              setIsPopupSuccess(false)
            }}
            onConfirm={() => {
              setIsPopupSuccess(false)
            }}
          ></SuccessModal>
          <FieldArray name="freeAssetData">
            {(arrayHelpers) => (
              <>
                {(props.values.freeAssetData || []).map((item, index) => {
                  return (
                    <FieldArrayDetail {...arrayHelpers} {...item} index={index} options={options} />
                  )
                })}
                <Line />
                <ButtonContainer>
                  <AddButton
                    type="button"
                    onClick={() => {
                      arrayHelpers.push({ assetType: '', assetValue: '' })
                    }}
                  >
                    + เพิ่มรายละเอียดหนี้สิน
                  </AddButton>
                  <SubmitButton type="submit">บันทึกและถัดไป</SubmitButton>
                </ButtonContainer>
              </>
            )}
          </FieldArray>
        </Container>
      )}
    </Formik>
  )
}

export default Pages
