import React, { FC, useState } from 'react'
import Avatar from 'components/Avatar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import Icon from 'components/Icon'
import { currency } from 'utils/helper'
import Prb from '../../../../assets/images/Prb.png'
import { ActAsideType } from './ActAside.type'
import { Act, ChevronDirect } from './ActAside.styles'

const ActAside: FC<ActAsideType> = (props) => {
  const { prbInsurance } = props
  const [firstDetail, setFirstDetail] = useState<boolean>(true)
  const [secondDetail, setSecondDetail] = useState<boolean>(true)

  return (
    <Act>
      <section className="avatar">
        <Avatar avatar={Prb} width="120px" height="120px" type="circle" />
        {/* <b>
          พ.ร.บ.{prbCarType || ''} {prbCode ? `(${prbCode})` : ''}
        </b> */}
        {/* <b>
          ฿{' '}
          {prbCarType === 'เก๋ง'
            ? '645.21'
            : prbCarType === 'กระบะ'
            ? '967.28'
            : prbCarType === 'รถตู้'
            ? '1,182.35'
            : '0.00'}
        </b> */}
        {/* {companyCode && options && options.prbInsuranceCompanyOptions && (
          <b>
            {(options?.prbInsuranceCompanyOptions || []).find(
              (option) => option.value === companyCode,
            )?.label || ''}
          </b>
        )} */}
        <b>
          พ.ร.บ.{prbInsurance?.prbCarType} ({prbInsurance?.prbCode})
        </b>
        <b>฿ {prbInsurance?.netAmount}</b>
        <b>{prbInsurance?.companyName}</b>
      </section>
      <section className="detail">
        <div className="">
          <div>รายละเอียด</div>
          <ChevronDirect spin={firstDetail} onClick={() => setFirstDetail(!firstDetail)}>
            <Icon name="vector_right_black" />
          </ChevronDirect>
        </div>
        {firstDetail && (
          <ul>
            <li>
              <b>จำนวนอู่</b>
              <div>-{/* {currency(1700, 0, '')} */}</div>
            </li>
            <li>
              <b>ทุนประกัน</b>
              <div>-{/* {currency(1700, 0, '')} */}</div>
            </li>
            <li>
              <b>ตรวจสภาพ</b>
              <FontAwesomeIcon icon={faTimes} />
            </li>
          </ul>
        )}
      </section>
      <section className="detail">
        <div className="">
          <div>ความคุ้มครอง (พ.ร.บ.)</div>
          <ChevronDirect spin={secondDetail} onClick={() => setSecondDetail(!secondDetail)}>
            <Icon name="vector_right_black" />
          </ChevronDirect>
        </div>
        {secondDetail && (
          <ul>
            <li>
              <b>ค่ารักษาพยาบาล</b>
              <div>{currency(30000, 0)}</div>
            </li>
            <li>
              <b>
                สูญเสียอวัยวะหรือ
                <br />
                ทุพพลภาพ
              </b>
              <div>{currency(35000, 0)}</div>
            </li>
          </ul>
        )}
      </section>
    </Act>
  )
}

export default ActAside
