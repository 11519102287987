import React, { FC } from 'react'
import styled from '@emotion/styled'

import { DashboardOrderCardType } from 'types/DashboardType'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 82px;
  padding: 1rem;
  border-radius: 8px;
  color: white;
  background-color: var(--agt-dark-sky-color);
`
const Count = styled.div`
  display: flex;
  align-items: baseline;
  align-self: flex-end;
`
const Order = styled.span`
  font-size: 38px;
  font-weight: bold;
`

const DashboardOrderCard: FC<DashboardOrderCardType> = ({ orderCount = 0 }) => {
  return (
    <Container className="agt-dashboard-order-card__container">
      <div>
        <b>จำนวนคำสั่งซื้อที่สร้าง</b>
      </div>
      <Count>
        <Order>{orderCount || '-'}</Order>
        &nbsp; ออเดอร์
      </Count>
    </Container>
  )
}

export default DashboardOrderCard
