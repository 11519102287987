import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { DashboardOpportunitiesCardType } from 'types/DashboardType'

const Container = styled.div`
  background: var(--agt-primary-color-1);
  border-radius: 5px;
  width: 100%;
  max-width: 262px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  color: var(--agt-white-color);

  .seemore {
    margin-top: 34px;
    align-self: flex-end;
    display: flex;
    align-items: center;
    color: var(--agt-white-color);
    small {
      padding-right: 13px;
    }
    &:hover {
      color: var(--agt-dark-sky-color);
    }
  }
`
const Topic = styled.b`
  font-size: 13px;
  margin-bottom: 20px;
`
const Status = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--agt-white-color);
  padding-bottom: 14px;
  margin-bottom: 14px;
  &:nth-of-type(4) {
    margin-bottom: 0px;
  }
`

const DashboardOpportunitiesCard: FC<DashboardOpportunitiesCardType> = ({
  opportunityStats = [],
}) => {
  const renderOpportunityStats = useMemo(() => {
    return opportunityStats.map((stat, index) => (
      <Status key={index}>
        <label>{stat.pipelineStateName}</label>
        <label>{stat.opportunityCount || ''}</label>
      </Status>
    ))
  }, [opportunityStats])

  return (
    <Container className="agt-dashboard-opportunities-card__container">
      <Topic>สถานะโอกาสในการขาย</Topic>
      {renderOpportunityStats}
      <Link to="/opportunities" className="ghost seemore">
        <small>ดูเพิ่มเติม</small>
        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 18 }} />
      </Link>
    </Container>
  )
}

export default DashboardOpportunitiesCard
