import React, { FC } from 'react'
import styled from '@emotion/styled'

import { DashboardOrdersStatusCardType } from 'types/DashboardType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--agt-background-color-2);
  border-radius: 5px;
  width: 100%;
  max-width: 262px;
  padding: 14px;
  background-color: white;
`
const Topic = styled.b`
  color: var(--agt-primary-color-1);
  font-size: 13px;
  margin-bottom: 10px;
`
const Status = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--agt-background-color-2);
  padding-bottom: 2px;
  margin-bottom: 2px;
`

const DashboardOrdersStatusCard: FC<DashboardOrdersStatusCardType> = ({ orderStats }) => {
  return (
    <Container className="agt-dashboard-orders-status-card__container">
      <Topic>สถานะคำสั่งซื้อ</Topic>
      {orderStats.map((order, index) => {
        return (
          <Status key={index}>
            <small>{order.status}</small>
            <small>{order.orderCount || ''}</small>
          </Status>
        )
      })}
    </Container>
  )
}

export default DashboardOrdersStatusCard
