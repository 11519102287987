import { EventType } from 'types/EventType'

export const initialEvent: EventType = {
  title: '',
  startDate: null,
  endDate: null,
  colorCode: '',
  allDay: false,
  hasNotEndDate: false,
  repeatSchedule: '',
  location: '',
  description: '',
  typeAlert: '',
  eventRelates: [],
}
