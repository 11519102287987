import styled from '@emotion/styled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { decamelizeKeys } from 'humps'
import React, { FC, useState } from 'react'
import Api from 'api'
import SetStateType from 'types/SetStateType'

const FormValue = styled(Form)`
  display: grid;
`
const FieldValue = styled(Field)`
  margin-top: 10px;
`
const Submit = styled.button`
  margin-top: 10px;
`
const Error = styled.div`
  color: red;
`

const validateEmail = (value: string) => {
  if (!value) {
    return 'กรุณาระบุอีเมล'
  }
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    return 'Invalid email address'
  }
}

type ForgetPasswordValues = {
  email: string
}

const handleSubmit = async (values: ForgetPasswordValues, setIsSuccess: SetStateType<boolean>) => {
  try {
    const response = await Api.post(
      '/auth/send_reset_password_instructions/',
      decamelizeKeys(values),
    )
    setIsSuccess(true)
    return response
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
      return err.response
    }
    throw err
  }
}

type ForgetPasswordFormProps = {
  initialValues: ForgetPasswordValues
  setIsSuccess: SetStateType<boolean>
}

const ForgetPasswordForm = ({ initialValues, setIsSuccess }: ForgetPasswordFormProps) => (
  <div>
    <h1>Forget Password</h1>
    <Formik initialValues={initialValues} onSubmit={(values) => handleSubmit(values, setIsSuccess)}>
      <FormValue>
        <FieldValue
          name="email"
          type="email"
          placeholder="example@email.com"
          validate={validateEmail}
        />
        <ErrorMessage component={Error} name="email" />
        <Submit type="submit">Submit</Submit>
      </FormValue>
    </Formik>
  </div>
)

const ForgetPassword: FC = (props) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const initialValues: ForgetPasswordValues = {
    email: '',
  }
  return isSuccess ? (
    <div>กรุณาตรวจสอบอีเมลของคุณเพื่อตั้งรหัสผ่านใหม่</div>
  ) : (
    <ForgetPasswordForm setIsSuccess={setIsSuccess} initialValues={initialValues} />
  )
}

export default ForgetPassword
