import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { get } from 'lodash'
import { getContactById } from 'store/contactSlice'
import { Formik } from 'formik'
import DetailForm from './DetailForm'
import { createOrder, getActOptions, searchAct } from 'store/actSlice'
import Loader from 'components/Loader'
import ActAside from './ActAside'
import HistoryType from 'types/HistoryType'
import { validateSchema } from './NewAct.validation'
import { Container, BackgroundLoading, Header, Content, Form } from './NewAct.styles'
import { initialValues } from './NewAct.utils'

const NewAct: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { state } = useLocation()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { contact } = useSelector((state: RootState) => state.contacts)
  const { options, prbInsurance, loading } = useSelector((state: RootState) => state.act)
  const [height, setHeight] = useState<string>('100vh')

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { path: `/opportunities/${id}/catalog/act`, label: 'ประกัน พ.ร.บ.' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  const onSaving = (values) => {
    let params = { ...values, type: 'update' }
    delete values.vehicleDetails.carUsage
    dispatch(
      createOrder(id, params, () => {
        history.push('/opportunities')
      }),
    )
  }

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
    dispatch(getActOptions(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id && state) {
      dispatch(
        searchAct(id, {
          carUsage: get(state, 'prbCode', ''),
          companyCode: get(state, 'companyCode', ''),
        }),
      )
    }
  }, [dispatch, id, state])

  useEffect(() => {
    let contactId: string | number = get(opportunity, 'contactId', '')
    if (contactId === '') {
    } else {
      dispatch(getContactById(contactId))
    }
  }, [dispatch, opportunity])

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      {(loading.options || loading.submit) && (
        <BackgroundLoading height={height}>
          <div>
            <Loader />
          </div>
        </BackgroundLoading>
      )}
      <Header>ประกัน พ.ร.บ.</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <ActAside
          prbCarType={get(state, 'prbCarType', '')}
          prbCode={get(state, 'prbCode', '')}
          companyCode={get(state, 'companyCode', '')}
          options={options}
          prbInsurance={prbInsurance}
        />
        <Form>
          <b>กรอกข้อมูลผู้เอาประกันภัย</b>
          <Formik
            initialValues={{
              ...initialValues,
              prefixName: contact?.prefixName || '',
              firstName: contact?.firstName || '',
              lastName: contact?.lastName || '',
              birthdate: contact?.birthdate || new Date(),
              idCard: contact?.idCard || '',
              email: contact?.email || '',
              phoneNumber: contact?.phoneNumber,
              companyCode: get(state, 'companyCode', ''),
              order: {
                ...initialValues.order,
                prbDetails: {
                  ...initialValues.order.prbDetails,
                  prbCarType: get(state, 'prbCarType', ''),
                  prbCode: get(state, 'prbCode', ''),
                  productDetails: { ...prbInsurance, companyCode: get(state, 'companyCode', '') },
                },
              },
            }}
            enableReinitialize
            validationSchema={validateSchema}
            onSubmit={(values) => {
              delete values.vehicleDetails.carUsage
              dispatch(
                createOrder(id, values, () => {
                  history.push('/opportunities')
                }),
              )
            }}
          >
            {(props) => <DetailForm {...props} options={options} onSaving={onSaving} />}
          </Formik>
        </Form>
      </Content>
    </Container>
  )
}
export default NewAct
