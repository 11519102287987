import {
  POLICY_TYPE,
  INSURANCE_TYPE,
  INSURANCE_STATE,
  INSURANCE_PURPOSE,
  INSURANCE_PATTERN,
  TRAVEL_TYPE,
} from 'utils/constants'

export const getFullNamePurpose = (shortNamePurpose: string) => {
  const text = INSURANCE_PURPOSE[shortNamePurpose]
  return text || 'ไม่ขอระบุวัตถุประสงค์ของกรมธรรม์'
}

export const getFullNameState = (shortNameState: string) => {
  const text = INSURANCE_STATE[shortNameState]
  return text || 'กรมธรรม์ถูกยกเลิก'
}

export const getFullNameInsurancePattern = (shortNameInsurancePattern: string) => {
  const text = INSURANCE_PATTERN[shortNameInsurancePattern]
  return text || 'ไม่ต้องระบุประเภทประกัน'
}

export const getFullNamePolicyType = (shortNamePolicyType: string) => {
  const text = POLICY_TYPE[shortNamePolicyType]
  return text || 'ไม่ต้องระบุประเภทประกัน'
}

export const getFullNameInsuranceType = (shortNameInsuranceType: string) => {
  const text = INSURANCE_TYPE[shortNameInsuranceType]
  return text || 'ไม่ต้องระบุประเภทกรมธรรม์'
}

export const getFullNameTravelType = (shortName: string) => {
  const text = TRAVEL_TYPE[shortName]
  return text || 'ไม่ต้องระบุประเภทประกัน'
}

export function getUrlExtension(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim()
}
