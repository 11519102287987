import React from 'react'
import { useField } from 'formik'
import { DatePickerComponent } from 'components/DatePicker'

import { OtherComponent } from './TravelForm.styles'

export const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          if (cb) cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  )
}

export const TravelType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="policy__1" onClick={() => setValue('plane')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'plane'} />
          <span className="checkmark" />
          เครื่องบิน
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('etc')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'etc'} />
          <span className="checkmark" />
          ยานพาหนะอื่นๆ
        </label>
      </div>
    </OtherComponent>
  )
}
