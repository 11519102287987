import React, { useState } from 'react'
import { ErrorContainer, FieldValue, IconContainer, InputContainer } from './Login.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

export function validatePassword(value: string) {
  let error: string | undefined
  if (!value) {
    error = 'กรุณากรอกข้อมูล'
  } else if (value.length < 8) {
    error = 'รหัสผ่านต้องไม่น้อยกว่า 8 ตัวอักษร'
  }
  return error
}

export function validateUsername(value: string) {
  let error: string | undefined
  if (!value) {
    error = 'กรุณากรอกข้อมูล'
  }
  return error
}

export const Error = (msg: string) => (
  <ErrorContainer className="error agt-small">{msg}</ErrorContainer>
)

export const PasswordField = (props) => {
  const { field, form, onChange, isNavbarError = '' } = props
  const { touched, errors } = form
  const { value } = field
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const togglePassword = () => setShowPassword(!showPassword)
  return (
    <InputContainer>
      <FieldValue
        {...field}
        type={showPassword ? 'text' : 'password'}
        placeholder="โปรดระบุ"
        isError={(touched.password && errors.password) || isNavbarError !== ''}
        onChange={(e) => {
          field.onChange(e)
          if (onChange) {
            onChange(e)
          }
        }}
      />
      {value && (
        <IconContainer onClick={() => togglePassword()}>
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            color="#ACB1BF"
            style={{ fontSize: '18px' }}
          />
        </IconContainer>
      )}
    </InputContainer>
  )
}
