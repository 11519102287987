import styled from '@emotion/styled'

import { InputCustomType } from './ShowDetail.type'

export const Container = styled.div`
  width: 68%;
  padding: 40px 0 0 18px;
  .topic {
    margin-bottom: 40px;
    font-weight: bold;
  }
  .padding {
    padding-left: 12px;
  }
  .padding .travel {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .padding .travel > b {
    width: 100%;
  }
  .padding .travel label {
    width: 50%;
  }
  .padding .address {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;
  }
  .padding .flight {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 24px;
  }
  .padding .flight > b {
    width: 100%;
  }
  .padding .assured {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-bottom: 64px;
    justify-content: space-between;
  }
  .padding .assured > b {
    width: 100%;
  }
  .padding .assured .half {
    width: 100%;
  }
  .padding .assured .half > div {
    width: 50%;
  }
`

export const InputCustom = styled.div<InputCustomType>`
  width: 100%;
  max-width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
  p {
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding: 4px 0;
  }
  @media (max-width: 1024px) {
    /* max-width: ${(props) => (props.width === 272 ? `260px` : 'unset')}; */
  }
`
