import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useParams } from 'routes'
import Icon from 'components/Icon'
import { Field, FieldArray, Form, Formik } from 'formik'
import SelectInput from 'pages/planning/share/Select'
import get from 'lodash/get'
import Currency from 'pages/planning/share/Currency'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Model from 'components/Modal'
import Input from 'pages/planning/share/Input'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  createNonLifeProtections,
  getNonLifeProtections,
  getNonLifeProtectionsOptions,
} from 'store/planningSlice'
import SuccessModal from 'components/SuccessModal'
import { getContactById } from 'store/contactSlice'

type useParamsType = {
  id: string
}
const Container = styled.div`
  align-items: center;
  width: 100%;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const HeaderContainer = styled.div`
  height: 84px;
  background: #eff1f5;
  padding-left: 122.96px;
  padding-right: 121.96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextHeader = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #393d43;
`
const RightIcon = styled(Icon)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 11px;
  svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const LeftIcon = styled(RightIcon)`
  transform-origin: center;
  transform: rotate(180deg);
`
const ButtonArrow = styled.div`
  cursor: pointer;
`
const BodyContainer = styled(Form)`
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 34px;
  display: flex;
  flex-direction: column;
`
const SubmitButton = styled.button`
  width: 122px;
  height: 48px;
  align-self: center;
`
const EmptryContainer = styled.div`
  width: 34px;
`
const TextTitle = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #393d43;
  margin-bottom: 24px;
`
const TextNoDetailList = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #acb1bf;
  margin-bottom: 24px;
`
const Line = styled.div`
  height: 1px;
  background: #a0a8bc;
`
const ButtonContainer = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: center;
`
const AddButton = styled.button`
  width: 219px;
  color: #00529a;
  background: #ffffff;
  border: 1px solid #00529a;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 24px;
  padding: 0 10px !important;
  :hover {
    color: white;
    background: #00529a;
  }
`
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 23% 20% 18% auto 18% auto auto;
  grid-column-gap: 2%;
  align-items: baseline;
`
const Unit = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #393d43;
  width: fit-content;
`
const CustomSelect = styled(SelectInput)`
  > div {
    width: 100%;
    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`
const CustomInput = styled(Input)`
  > div {
    width: 100%;
    > input {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`
const CustomCurrency = styled(Currency)`
  > div {
    width: 100%;
    > input {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`
const ButtonDelete = styled.div`
  cursor: pointer;
`
const ModelContainer = styled.div`
  width: 352px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TextModel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #393d43;
  margin-bottom: 8px;
`
const TextModelBlueColor = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  color: #00529a;
`
const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #393d43;
  margin-bottom: 24px;
`
const FieldArrayDetail = (props) => {
  const { name, remove, index, options } = props
  const [isOpen, setIsOpen] = useState(false)
  const onConfirm = useCallback(() => {
    remove(index)
    setIsOpen(false)
  }, [remove, index])
  const onCancel = useCallback(() => {
    setIsOpen(false)
  }, [])
  const setModelOpen = useCallback(() => {
    setIsOpen(true)
  }, [])
  const labelTypeDelete = useMemo(
    () =>
      ((options.assetTypeOptions || []).find((item) => item.value === props.assetType) || {}).label,
    [options, props.assetType],
  )
  return (
    <InputContainer key={index}>
      <Field
        name={`${name}.${index}.assetType`}
        component={CustomSelect}
        placeholder="โปรดระบุ"
        options={options.assetTypeOptions}
      />
      <Field name={`${name}.${index}.assetDesc`} component={CustomInput} placeholder="โปรดระบุ" />
      <Field
        name={`${name}.${index}.assetValue`}
        component={CustomCurrency}
        placeholder="โปรดระบุ"
        onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
      />
      <Unit>บาท</Unit>
      <Field
        name={`${name}.${index}.assetSumInsured`}
        component={CustomCurrency}
        placeholder="โปรดระบุ"
        onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
      />
      <Unit>บาท</Unit>
      <ButtonDelete onClick={setModelOpen}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </ButtonDelete>
      <Model isOpen={isOpen} onCancel={onCancel} onConfirm={onConfirm}>
        <ModelContainer>
          <TextModel>ยืนยันการลบข้อมูลภาระหนี้สิน</TextModel>
          <TextModelBlueColor>{labelTypeDelete}</TextModelBlueColor>
        </ModelContainer>
      </Model>
    </InputContainer>
  )
}

const Pages: FC = (props) => {
  let { id } = useParams<useParamsType>()
  const [step, setStep] = useState<number>(0)
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const nonLifeData = useSelector((state: RootState) => state.plans.nonLifeData)
  const options = useSelector((state: RootState) => state.plans.options)
  const [isPopupSuccess, setIsPopupSuccess] = useState<boolean>(false)
  useEffect(() => {
    dispatch(getContactById(id))
    dispatch(getNonLifeProtectionsOptions(parseInt(id)))
    dispatch(getNonLifeProtections(parseInt(id)))
  }, [dispatch, id])
  const stepList = useMemo(() => ['รายละเอียดสินทรัพย์ทั้งที่ยังมีภาระหนี้ และปลอดภาระแล้ว'], [])
  const nextStep = useCallback(() => {
    step + 1 < stepList.length && setStep(step + 1)
  }, [step, stepList.length])
  const backStep = useCallback(() => {
    step - 1 >= 0 && setStep(step - 1)
  }, [step])
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    {
      path: { pathname: `/contacts/${id}/planning`, state: 'planning' },
      label: 'วางแผนประกัน',
    },
    { label: 'วางแผนคุ้มครองความเสี่ยงภัยอื่นๆ' },
  ]
  return (
    <Container>
      <Header>วางแผนประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <HeaderContainer>
        {step !== 0 && (
          <ButtonArrow onClick={backStep}>
            <LeftIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === 0 && <EmptryContainer />}
        <TextHeader>วางแผนคุ้มครองความเสี่ยงภัยอื่นๆ</TextHeader>
        {step !== stepList.length - 1 && (
          <ButtonArrow onClick={nextStep}>
            <RightIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === stepList.length - 1 && <EmptryContainer />}
      </HeaderContainer>

      <Formik
        initialValues={{ nonLifeData }}
        enableReinitialize
        onSubmit={(values) => {
          dispatch(createNonLifeProtections(parseInt(id), values))
          setIsPopupSuccess(true)
        }}
      >
        {(props) => (
          <BodyContainer>
            <TextTitle>{stepList[step]}</TextTitle>
            {!get(props.values, 'nonLifeData', false) ||
            get(props.values, 'nonLifeData', []).length === 0 ? (
              <TextNoDetailList>ใส่รายละเอียดภาระหนี้สินเพื่อใช้ในการคำนวณ</TextNoDetailList>
            ) : (
              <InputContainer>
                <Text>ประเภท</Text>
                <Text>รายละเอียด</Text>
                <Text>มูลค่าสินทรัพย์</Text>
                <Text />
                <Text>จำนวนเงินเอาประกัน</Text>
                <Text />
              </InputContainer>
            )}
            <SuccessModal
              isOpen={isPopupSuccess}
              onCancel={() => {
                setIsPopupSuccess(false)
              }}
              onConfirm={() => {
                setIsPopupSuccess(false)
              }}
            ></SuccessModal>
            <FieldArray
              name="nonLifeData"
              render={(arrayHelpers) => {
                return (
                  <>
                    {(props.values.nonLifeData || []).map((item, index) => (
                      <FieldArrayDetail
                        {...arrayHelpers}
                        {...item}
                        index={index}
                        options={options}
                      />
                    ))}
                    <Line />
                    <ButtonContainer>
                      <AddButton
                        onClick={() => {
                          arrayHelpers.push({
                            assetDesc: '',
                            assetSumInsured: '',
                            assetType: '',
                            assetValue: '',
                          })
                        }}
                        type="button"
                      >
                        + เพิ่มรายละเอียดสินทรัพย์
                      </AddButton>
                      <SubmitButton type="submit">บันทึก</SubmitButton>
                    </ButtonContainer>
                  </>
                )
              }}
            />
          </BodyContainer>
        )}
      </Formik>
    </Container>
  )
}

export default Pages
