import { addDays, addYears } from 'date-fns'
import { Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'routes'
import { getActOptions } from 'store/actSlice'
import {
  ChassisNumberComponent,
  CustomAddressComponent,
  DatePicker,
  InputComponent,
  OptionsComponent,
  PolicyType,
  RedLabelCarComponent,
} from './DetailForm.components'
import { Container } from './DetailForm.styles'

const today = new Date()
const tomorrow = addDays(today, 1)

const DetailForm = (props) => {
  const { values, options, onSaving, isEdit = false, initialValues, isSubmitting = false } = props
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { setFieldValue } = useFormikContext()
  const [isEditForm, setIsEditForm] = useState(false)
  const [isSuggest, setIsSuggest] = useState(false)

  const onSuggest = () => {
    setIsSuggest(!isSuggest)
  }

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.year && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.year && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.makeCode', '')
        setFieldValue('vehicleDetails.vehicleBrand', '')
        setFieldValue('vehicleDetails.vehicleModel', '')
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, { year: values.vehicleDetails.year, makeCode: '', familyCode: '' }),
        )
      }
    },
    // eslint-disable-next-line
    [values?.vehicleDetails?.year, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.makeCode && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.makeCode && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.vehicleModel', '')
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, {
            year: values.vehicleDetails.year,
            makeCode: values.vehicleDetails.makeCode,
            familyCode: '',
          }),
        )
      }
    },
    // eslint-disable-next-line
    [values?.vehicleDetails?.makeCode, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      if (
        (values?.vehicleDetails?.vehicleModel && values?.vehicleDetails?.id === null && !isEdit) ||
        (values?.vehicleDetails?.vehicleModel && values?.vehicleDetails?.id === null && isEditForm)
      ) {
        setFieldValue('vehicleDetails.vehicleSubModel', '')
        dispatch(
          getActOptions(id, {
            year: values.vehicleDetails.year,
            makeCode: values.vehicleDetails.makeCode,
            familyCode: values.vehicleDetails.vehicleModel,
          }),
        )
      }
    }, // eslint-disable-next-line
    [values?.vehicleDetails?.vehicleModel, values?.vehicleDetails?.id],
  )

  useEffect(
    () => {
      let policyAddress = values?.order?.prbDetails?.policyAddress?.aaaaaa
      if (policyAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(policyAddress)
        ) {
          let params = options.addressOptions.find((address) => address.label === policyAddress)
          setFieldValue('order.prbDetails.policyAddress.housingNo', params?.value?.housingNo || '')
          setFieldValue('order.prbDetails.policyAddress.villageNo', params?.value?.villageNo || '')
          setFieldValue('order.prbDetails.policyAddress.building', params?.value?.building || '')
          setFieldValue('order.prbDetails.policyAddress.lane', params?.value?.lane || '')
          setFieldValue('order.prbDetails.policyAddress.road', params?.value?.road || '')
          setFieldValue(
            'order.prbDetails.policyAddress.subDistrict',
            params?.value?.subDistrict || '',
          )
          setFieldValue('order.prbDetails.policyAddress.district', params?.value?.district || '')
          setFieldValue('order.prbDetails.policyAddress.province', params?.value?.province || '')
          setFieldValue('order.prbDetails.policyAddress.postcode', params?.value?.postcode || '')
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyAddress?.aaaaaa, options],
  )

  useEffect(
    () => {
      if (values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa === 'เหมือนที่อยู่บนกรมธรรม์') {
        let params = {
          ...values?.order?.prbDetails?.policyAddress,
          aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
        }
        setFieldValue('order.prbDetails.policyDeliveryAddress', params)
      }
      if (values?.order?.prbDetails?.receiptAddress?.aaaaaa === 'เหมือนที่อยู่บนกรมธรรม์') {
        let params = {
          ...values?.order?.prbDetails?.policyAddress,
          aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
        }
        setFieldValue('order.prbDetails.receiptAddress', params)
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyAddress],
  )

  useEffect(
    () => {
      let policyDeliveryAddress = values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa
      if (policyDeliveryAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(policyDeliveryAddress)
        ) {
          let params = options.addressOptions.find(
            (address) => address.label === policyDeliveryAddress,
          )
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.housingNo',
            params?.value?.housingNo,
          )
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.villageNo',
            params?.value?.villageNo,
          )
          setFieldValue('order.prbDetails.policyDeliveryAddress.building', params?.value?.building)
          setFieldValue('order.prbDetails.policyDeliveryAddress.lane', params?.value?.lane)
          setFieldValue('order.prbDetails.policyDeliveryAddress.road', params?.value?.road)
          setFieldValue(
            'order.prbDetails.policyDeliveryAddress.subDistrict',
            params?.value?.subDistrict,
          )
          setFieldValue('order.prbDetails.policyDeliveryAddress.district', params?.value?.district)
          setFieldValue('order.prbDetails.policyDeliveryAddress.province', params?.value?.province)
          setFieldValue('order.prbDetails.policyDeliveryAddress.postcode', params?.value?.postcode)
        } else if (policyDeliveryAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
          let params = {
            ...values?.order?.prbDetails?.policyAddress,
            aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
          }
          setFieldValue('order.prbDetails.policyDeliveryAddress', params)
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.policyDeliveryAddress?.aaaaaa, options?.addressOptions],
  )

  useEffect(
    () => {
      let receiptAddress = values?.order?.prbDetails?.receiptAddress?.aaaaaa
      if (receiptAddress && options?.addressOptions) {
        if (
          [
            'ที่อยู่ปัจจุบัน',
            'ที่อยู่ตามทะเบียนบ้าน',
            'ที่อยู่ที่ทำงาน',
            'ที่อยู่ตามบัตรประชาชน',
          ].includes(receiptAddress)
        ) {
          let params = options.addressOptions.find((address) => address.label === receiptAddress)
          setFieldValue('order.prbDetails.receiptAddress.housingNo', params?.value?.housingNo)
          setFieldValue('order.prbDetails.receiptAddress.villageNo', params?.value?.villageNo)
          setFieldValue('order.prbDetails.receiptAddress.building', params?.value?.building)
          setFieldValue('order.prbDetails.receiptAddress.lane', params?.value?.lane)
          setFieldValue('order.prbDetails.receiptAddress.road', params?.value?.road)
          setFieldValue('order.prbDetails.receiptAddress.subDistrict', params?.value?.subDistrict)
          setFieldValue('order.prbDetails.receiptAddress.district', params?.value?.district)
          setFieldValue('order.prbDetails.receiptAddress.province', params?.value?.province)
          setFieldValue('order.prbDetails.receiptAddress.postcode', params?.value?.postcode)
        } else if (receiptAddress === 'เหมือนที่อยู่บนกรมธรรม์') {
          let params = {
            ...values?.order?.prbDetails?.policyAddress,
            aaaaaa: 'เหมือนที่อยู่บนกรมธรรม์',
          }
          setFieldValue('order.prbDetails.receiptAddress', params)
        } else {
        }
      }
    }, // eslint-disable-next-line
    [values?.order?.prbDetails?.receiptAddress?.aaaaaa, options?.addressOptions],
  )

  useEffect(
    () => {
      let ownVehicle = values?.vehicleDetails?.id || null

      if (ownVehicle && options?.vehicleOptions && options?.vehicleOptions.length > 0) {
        let params = options.vehicleOptions.find((vehicle) => vehicle.value === ownVehicle)
        dispatch(
          getActOptions(id, {
            year: params.vehicle.year,
            makeCode: params.vehicle.makeCode,
            familyCode: params.vehicle.vehicleModel,
          }),
        )
        setFieldValue('vehicleDetails', params?.vehicle)
        setFieldValue('vehicleDetails.vehicleChassis', params?.vehicle?.vehicleChassis?.value)
        setFieldValue('vehicleDetails.vehicleLicense', params?.vehicle?.vehicleLicense?.value)
        setFieldValue('vehicleDetails.vehicleProvince', params?.vehicle?.vehicleProvince?.value)
      } else {
      }
    }, // eslint-disable-next-line
    [values?.vehicleDetails?.id],
  )

  return (
    <Container>
      <Field
        label="คำนำหน้าชื่อ"
        name="prefixName"
        className="prefixName"
        component={OptionsComponent}
        options={[
          { label: 'นาย', value: 'นาย' },
          { label: 'นาง', value: 'นาง' },
          { label: 'นางสาว', value: 'นางสาว' },
        ]}
        disabled
      />
      <Field
        label="ชื่อจริง"
        name="firstName"
        className="firstName"
        component={InputComponent}
        disabled
      />
      <Field
        label="นามสกุล"
        name="lastName"
        className="lastName"
        component={InputComponent}
        disabled
      />
      <Field
        label="วันเกิด"
        name="birthdate"
        className="birthdate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        disabled
      />
      <Field
        label="เลขบัตรประชาชน"
        name="idCard"
        className="idCard"
        component={InputComponent}
        maxlength={13}
        disabled={initialValues?.idCard}
      />
      <Field label="อีเมล" name="email" className="email" component={InputComponent} />
      <Field
        label="เบอร์โทร"
        name="phoneNumber"
        className="phoneNumber"
        component={InputComponent}
      />
      <Field
        label="ประกันเดิม"
        name="order.prbDetails.oldCompanyName"
        className="insuranceCompany"
        component={OptionsComponent}
        options={options?.insuranceCompanyOptions || []}
        placeholder="โปรดระบุ"
      />
      <CustomAddressComponent
        label="ที่อยู่บนกรมธรรม์"
        name="order.prbDetails.policyAddress"
        options={(options?.addressOptions || []).filter(
          (options) => options?.label !== 'เหมือนที่อยู่บนกรมธรรม์',
        )}
        address={values.order.prbDetails.policyAddress}
      />
      <CustomAddressComponent
        label="ที่อยู่จัดส่งกรมธรรม์"
        name="order.prbDetails.policyDeliveryAddress"
        options={options?.addressOptions || []}
        address={values.order.prbDetails.policyDeliveryAddress}
      />
      <CustomAddressComponent
        label="ที่อยู่ใบเสร็จรับเงิน"
        name="order.prbDetails.receiptAddress"
        options={options?.addressOptions || []}
        address={values.order.prbDetails.receiptAddress}
      />
      <div className="topic">
        <b>รายละเอียดกรมธรรม์</b>
      </div>
      {/* <Field
        label="สถานที่ตรวจสภาพรถยนต์"
        name="order.prbDetails.inspectionLocation"
        className="checkup"
        component={CarCheckUp}
      />
      <Field
        label="วันที่ตัวสภาพรถยนต์"
        name="order.prbDetails.vehicleInspectionDate"
        className="dDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
      /> */}
      <Field
        label="รูปแบบกรมธรรม์"
        name="order.prbDetails.policyForm"
        className="policy"
        component={PolicyType}
      />
      <Field
        label="เริ่มคุ้มครอง"
        name="order.prbDetails.startPolicyAt"
        className="startDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        minDate={tomorrow}
        cb={(e) => {
          setFieldValue('order.prbDetails.endPolicyAt', addYears(new Date(e), 1))
        }}
      />
      <Field
        label="สิ้นสุดการคุ้มครอง"
        name="order.prbDetails.endPolicyAt"
        className="endDate"
        component={DatePicker}
        placeholder="โปรดระบุ"
        disabled
      />
      {/* <Field
        label="แจ้งเตือนต่อประกัน"
        name="order.prbDetails.insuranceRenewalNotification"
        className="notice"
        component={NoticeComponent}
      />
      */}
      <div className="topic">
        <b>รายละเอียดรถยนต์</b>
      </div>
      <Field
        label="เลือกจากข้อมูลรถของลูกค้า"
        name="vehicleDetails.id"
        className="eeee"
        component={OptionsComponent}
        options={options?.vehicleOptions || []}
        onChange={(e) => {
          if (e.value) {
          } else {
            if (isEdit) {
              setFieldValue('vehicleDetails', {
                ...initialValues?.vehicleDetails,
              })
              dispatch(
                getActOptions(id, {
                  year: initialValues.vehicleDetails.year,
                  makeCode: initialValues.vehicleDetails.makeCode,
                  familyCode: initialValues.vehicleDetails.vehicleModel,
                }),
              )
            } else {
              setFieldValue('vehicleDetails', {
                id: null,
                name: '',
                year: '',
                vehicleBrand: '',
                vehicleModel: '',
                vehicleSubModel: '',
                redLicencePlate: false,
                vehicleProvince: '',
                vehicleLicense: '',
                vehicleChassis: '',
                expiryDate: '',
                cc: '',
                carUsage: '',
                makeCode: '',
                familyCode: '',
                vehicleKey: '',
              })
            }
          }
        }}
      />
      <Field
        label="ตั้งชื่อ"
        name="vehicleDetails.name"
        className="ffff"
        component={InputComponent}
        disabled={values.vehicleDetails.id !== null}
      />
      <Field
        label="ปีรถยนต์"
        name="vehicleDetails.year"
        className="aaaa"
        component={OptionsComponent}
        options={options?.carYearOptions || []}
        disabled={values.vehicleDetails.id}
        onChange={() => {
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
        }}
      />
      <Field
        label="ยี่ห้อรถยนต์"
        name="vehicleDetails.makeCode"
        className="bbbb"
        component={OptionsComponent}
        options={options?.makeCodeOptions || []}
        disabled={!values?.vehicleDetails?.year || values.vehicleDetails.id}
        onChange={(e) => {
          console.log('ERRROR!!', e)
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
          setFieldValue('vehicleDetails.makeCode', e?.value || '')
        }}
      />
      <Field
        label="รุ่น"
        name="vehicleDetails.familyCode"
        className="cccc"
        component={OptionsComponent}
        options={options?.familyCodeOptions || []}
        disabled={!values?.vehicleDetails?.makeCode || values.vehicleDetails.id}
        onChange={(e) => {
          if (isEdit && !isEditForm) {
            setIsEditForm(true)
          }
          setFieldValue('vehicleDetails.familyCode', e?.value || '')
        }}
      />
      <Field
        label="รุ่นย่อย"
        name="vehicleDetails.vehicleSubModel"
        className="dddd"
        component={OptionsComponent}
        options={options?.carSubModelOptions || []}
        disabled={!values?.vehicleDetails?.vehicleModel || values.vehicleDetails.id}
        onChange={(e) => {
          setFieldValue('vehicleDetails.cc', e?.details?.cc || '')
          setFieldValue('vehicleDetails.vehicleKey', e?.details?.vehicleKey || '')
        }}
      />
      <Field
        label="เลขทะเบียนรถยนต์ (ตย. 1กก8888)"
        name="vehicleDetails.vehicleLicense"
        className="plateNumber"
        component={InputComponent}
        disabled={
          (values?.vehicleDetails?.id &&
            options?.vehicleOptions &&
            options.vehicleOptions.find((vehicle) => vehicle.value === values?.vehicleDetails?.id)
              ?.vehicle?.vehicleLicense?.canEdit === false) ||
          values.vehicleDetails.redLicencePlate
        }
      />
      <Field
        label="จังหวัดที่จดทะเบียน"
        name="vehicleDetails.vehicleProvince"
        className="provice"
        component={OptionsComponent}
        options={options?.provinceOptions || []}
        disabled={
          (values?.vehicleDetails?.id &&
            options?.vehicleOptions &&
            options.vehicleOptions.find((vehicle) => vehicle.value === values?.vehicleDetails?.id)
              ?.vehicle?.vehicleProvince?.canEdit === false) ||
          false
        }
      />
      <Field
        label="เลขตัวถัง"
        name="vehicleDetails.vehicleChassis"
        className="idCar"
        component={ChassisNumberComponent}
        disabled={
          (values?.vehicleDetails?.id &&
            options?.vehicleOptions &&
            options.vehicleOptions.find((vehicle) => vehicle.value === values?.vehicleDetails?.id)
              ?.vehicle?.vehicleChassis?.canEdit === false) ||
          false
        }
        onSuggest={onSuggest}
        isSuggest={isSuggest}
      />
      {/* <div className="blank" />
      <Field
        label="มูลค่ารถยนต์ (บาท)"
        name="vehicleDetails.bbbb"
        className="kkkkk"
        component={InputComponent}
      /> */}
      <Field
        label="เป็นรถป้ายแดง"
        name="vehicleDetails.redLicencePlate"
        className="redLabel"
        component={RedLabelCarComponent}
        cb={(redLicencePlate: boolean) => {
          if (redLicencePlate) setFieldValue('vehicleDetails.vehicleLicense', 'ป้ายแดง')
        }}
        disabled={
          (values?.vehicleDetails?.id &&
            options?.vehicleOptions &&
            options.vehicleOptions.find((vehicle) => vehicle.value === values?.vehicleDetails?.id)
              ?.vehicle?.vehicleLicense?.canEdit === false) ||
          false
        }
      />
      <div className="submitBtn">
        <button
          type="button"
          onClick={() => {
            onSaving(values)
          }}
        >
          บันทึก
        </button>
        <button type="submit" className="secondary" disabled={isSubmitting}>
          แจ้งแนะนำ
        </button>
      </div>
    </Container>
  )
}

export default DetailForm
