import React, { FC, useState, useEffect, useCallback } from 'react'
import api from 'api'
import Icon from 'components/Icon'
import { TasksType, TaskType, DataType } from './Tasks.type'
import {
  Container,
  Header,
  Loading,
  TodoContainer,
  NotingText,
  CustomBtnLink,
} from './Tasks.styles'
import { TaskRenderer } from './Tasks.components'
import { isObject } from 'lodash'

const Tasks: FC<TasksType> = (props) => {
  const { contactId, contactData, currentAgent } = props
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isHistory, setHistory] = useState<boolean>(false)
  const [data, setData] = useState<DataType>({
    tasksCurrentDay: [],
    tasksCurrentWeek: [],
    tasksIncoming: [],
    tasksLated: [],
    tasksNextWeek: [],
  })
  const {
    tasksCurrentDay = [],
    tasksCurrentWeek = [],
    tasksIncoming = [],
    tasksLated = [],
    tasksNextWeek = [],
  } = data

  const [tasksHistory, setTasksData] = useState<Array<TaskType>>([])

  const getTasks = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/tasks`, { contactId })
      const { tasks = {} } = response.data.data
      setData(tasks)
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [contactId])

  const getHistoryTasks = useCallback(async (contactId: number) => {
    try {
      setLoading(true)
      const response = await api.get(`/tasks/log`, { contactId: contactId })
      const { tasks = {} } = response.data.data
      setTasksData(tasks)
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (isHistory && contactId) {
      getHistoryTasks(contactId)
    } else if (contactId) {
      getTasks()
    }
  }, [contactId, isHistory, setHistory, getTasks, getHistoryTasks])

  const tasksCurrentDayExpand = tasksCurrentDay.length > 0
  const tasksLatedExpand = tasksLated.length > 0 && !tasksCurrentDayExpand
  const tasksCurrentWeekExpand =
    tasksCurrentWeek.length > 0 && !tasksCurrentDayExpand && !tasksLatedExpand
  const tasksNextWeekExpand =
    tasksNextWeek.length > 0 &&
    !tasksCurrentWeekExpand &&
    !tasksLatedExpand &&
    !tasksCurrentDayExpand
  const tasksIncomingExpand =
    tasksIncoming.length > 0 &&
    !tasksNextWeekExpand &&
    !tasksCurrentWeekExpand &&
    !tasksLatedExpand &&
    !tasksCurrentDayExpand

  return (
    <Container id="contact-id__task-sidebar">
      <Header>
        <h4
          className="label"
          style={isHistory ? { cursor: 'pointer' } : {}}
          onClick={() => {
            if (isHistory) {
              setHistory(!isHistory)
            }
          }}
        >
          งานที่ต้องทำ
        </h4>
        <div
          className="icon"
          style={isHistory ? { background: '#003d73' } : { cursor: 'pointer' }}
          onClick={() => {
            if (!isHistory) {
              setHistory(!isHistory)
            }
          }}
        >
          <Icon name="history" />
        </div>
      </Header>
      {isLoading ? (
        <Loading>loading ....</Loading>
      ) : (
        <>
          {isHistory ? (
            <TodoContainer>
              {tasksHistory.length === 0 ? (
                <NotingText>ยังไม่มีประวัติงานที่ต้องทำ</NotingText>
              ) : (
                <TaskRenderer
                  header="ประวัติงานที่ทำเสร็จแล้ว"
                  type="history"
                  data={tasksHistory}
                  forceExpand={true}
                />
              )}
            </TodoContainer>
          ) : (
            <TodoContainer>
              {tasksLated.length === 0 &&
                tasksCurrentDay.length === 0 &&
                tasksCurrentWeek.length === 0 &&
                tasksNextWeek.length === 0 &&
                tasksIncoming.length === 0 && <NotingText>ยังไม่มีงานที่ต้องทำ</NotingText>}
              {tasksLated.length > 0 && (
                <TaskRenderer
                  header="เกินกำหนด"
                  type="overdue"
                  data={tasksLated}
                  forceExpand={tasksLatedExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentDay.length > 0 && (
                <TaskRenderer
                  header="วันนี้"
                  type="today"
                  data={tasksCurrentDay}
                  forceExpand={tasksCurrentDayExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentWeek.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์นี้"
                  type="incoming"
                  data={tasksCurrentWeek}
                  forceExpand={tasksCurrentWeekExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksNextWeek.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์หน้า"
                  type="incoming"
                  data={tasksNextWeek}
                  forceExpand={tasksNextWeekExpand}
                  currentAgent={currentAgent}
                />
              )}
              {tasksIncoming.length > 0 && (
                <TaskRenderer
                  header="สัปดาห์กำลังมาถึง"
                  type="incoming"
                  data={tasksIncoming}
                  forceExpand={tasksIncomingExpand}
                  currentAgent={currentAgent}
                />
              )}
            </TodoContainer>
          )}
        </>
      )}
      <CustomBtnLink
        link={`/tasks/new/${contactId}`}
        label="สร้างงานที่ต้องทำ"
        disabled={
          currentAgent?.statusTh === 'ระงับ' ||
          (isObject(contactData?.currentAgreement || null) && contactData?.isConsented === false)
        }
      />
    </Container>
  )
}

export default Tasks
