import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ArrowType } from './index.type'
import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
`

export const HeaderTopic = styled.h2`
  margin-bottom: 40px;

  color: white;
  text-align: center;
`

export const ImageContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1120px;
  height: 250px;
  margin: 0 auto;
  overflow: hidden;
`

export const Image = styled.img`
  position: absolute;
  z-index: -2;

  width: 100%;
  height: 100%;

  object-fit: cover;
`

export const RegisterButton = styled.button`
  display: block;
  width: 352px;
  margin: 0 auto;

  font-weight: bold;
`

export const StickyHeader = styled(ImageContainer)`
  position: fixed;
  z-index: 99;
  top: 0;

  height: 0;

  background-color: rgba(204, 220, 235, 0.7);
  backdrop-filter: blur(1px);
  transition: height 0.5s;

  &.is-sticky {
    height: 58px;
  }
`

export const Privilege = styled.div`
  width: 100%;
  padding: 40px 0;
`

export const Topic = styled.h4`
  margin-bottom: 40px;

  text-align: center;
  color: var(--agt-primary-color-1);
`

export const PrivilegeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const PrivilegeCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 352px;
  padding: 15px;
  padding-bottom: 24px;
  height: 290px;
  border: 1px solid var(--agt-background-color-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const PrivilegeTopic = styled.div`
  margin-bottom: 19px;
`

export const PrivilegeIcon = styled(Icon)`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 62px;
  height: 62px;
  > div {
    > div {
      > svg {
        width: 62px;
        height: 62px;
      }
    }
  }
`

export const Step = styled.img`
  width: 100%;
  height: 357px;
  object-fit: cover;
`

export const Alliance = styled.div`
  width: 240px;
  max-width: 240px;
  min-width: 240px;
  display: block;
  > img {
    width: 100%;
    height: 136px;
    object-fit: contain;
  }
`

export const Why = styled.div`
  width: 100%;
  padding: 40px 96px 60px;

  text-align: center;
`

export const WhyTopic = styled(Topic)`
  margin-bottom: 23px;
`

export const WhyHr = styled.div`
  width: 160px;
  height: 4px;
  margin: 26px auto 0;

  background-color: var(--agt-primary-color-1);
`

export const QnA = styled.div`
  width: 100%;
  padding: 24px 96px 40px;

  background-color: var(--agt-background-color-3);
`

export const QnATopic = styled(Topic)`
  margin-bottom: 24px;
`

export const QnAContainer = styled.div`
  margin-bottom: 12px;

  background-color: white;
`

export const QnAQuestion = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  padding: 20px 80px 20px 60px;
  font-weight: bold;
  cursor: pointer;
`

export const Arrow = styled(FontAwesomeIcon)<ArrowType>`
  position: absolute;
  right: 40px;

  font-size: 20px;
  transform-origin: center;
  transform: ${(props) => (props.activated ? 'rotate(180deg)' : 'rotate(0)')};
  transition: transform 0.5s;
`

export const QnAAnswer = styled.div`
  padding: 20px 80px 20px 60px;
  border-top: 1px solid var(--agt-background-color-1);

  white-space: pre-line;
`
export const GroupAliliance = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  position: relative;
  .navigate {
    width: 48px;
    height: 48px;
    padding: 0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navigate.pre {
    position: absolute;
    top: 108px;
    left: 0;
  }
  .navigate.nxt {
    position: absolute;
    top: 108px;
    right: 0;
  }
`

export const Gallery = styled.div`
  width: 90%;
  overflow: hidden;
  > div {
    display: flex;
    gap: 2rem;
    overflow-x: auto;
    scroll-behavior: smooth;
  }
  > div::-webkit-scrollbar {
    display: none;
  }
`
