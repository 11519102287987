import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import ForgetPassword from 'pages/forget-password/root'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/forget-password" component={ForgetPassword} />
  </Switch>
)

export default Pages
