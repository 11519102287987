import styled from '@emotion/styled'
import { ContainerType, DialogBoxType } from './FirstTimeTour.type'

export const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const Position = styled.div`
  position: absolute;
`

export const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0 0;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
    transform: rotate(135deg);
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top label {
    margin-bottom: 4px;
  }
  .top label b {
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`

export const Header = styled.div`
  display: flex;
  > div:first-of-type {
    width: 36px;
  }
  .avatar {
    background-color: var(--agt-background-color-1);
    border-radius: 4px;
    padding: 8px;
    color: var(--agt-primary-text-color);
    display: flex;
    align-items: center;
    gap: 14px;
  }
`

export const Menu = styled.ul`
  background: var(--agt-white-color);
  border: 1px solid var(--agt-secondary-color-1);
  border-radius: 4px;
  margin-top: 16px;
  width: fit-content;
  float: right;
  list-style: none;
  > a {
    text-decoration: unset;
    color: var(--agt-primary-text-color);
  }
  li {
    padding: 12px 24px 0;
    width: 224px;
    height: 48px;
  }
  li:hover {
    background-color: var(--agt-background-color-1);
    cursor: pointer;
  }
  > li:last-of-type {
    font-weight: bold;
  }
`
