import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContactsThunk } from 'store/store'
import Api from 'api'
import {
  ContactType,
  ContactStateType,
  ContactFilterValues,
  ContactOptionsType,
  ContactAgreementType,
  ContactDetailConsentType,
  PortOptionsType,
} from 'types/ContactType'
import { InsurancePortType } from 'types/InsurancePortType'
import { initialState } from './contactSlice.utils'
import SetStateType from 'types/SetStateType'

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    initializeContacts(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    setCurrentContactsSuccess(state, action: PayloadAction<ContactStateType>) {
      state.contact = action.payload.contact
      state.consentCurrentVersion = action.payload.consentCurrentVersion
      state.lastConsentHistory = action.payload.lastConsentHistory
      state.error = null
    },
    setCurrentContactsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setClearContact(state) {
      state.contact = initialState.contact
    },
    getContactsSuccess(state, action: PayloadAction<ContactStateType>) {
      state.contacts = action.payload.contacts
      state.paging = action.payload.paging
      state.haveConsented = action.payload.haveConsented
      state.error = null
    },
    getContactsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setOptionsSuccess(state, action: PayloadAction<ContactOptionsType>) {
      state.options = action.payload
      state.error = null
    },
    setOptionsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setConsentSuccess(state, action: PayloadAction<ContactDetailConsentType>) {
      state.agreement = action.payload.agreement
      state.contact = action.payload.contact
      state.error = null
    },
    setConsentFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setConsentURLSuccess(state, action: PayloadAction<string>) {
      state.consentUrl = action.payload
      state.error = null
    },
    setConsentURLFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    setConsentByContact(state, action: PayloadAction<ContactAgreementType | null>) {
      state.agreement = action.payload
      state.error = null
    },
    setPortOptionsSuccess(state, action: PayloadAction<PortOptionsType>) {
      state.portOptions = action.payload
      state.error = null
    },
    setPortOptionsFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  initializeContacts,
  getContactsSuccess,
  getContactsFailed,
  setCurrentContactsSuccess,
  setCurrentContactsFailed,
  setClearContact,
  setOptionsSuccess,
  setOptionsFailed,
  setConsentSuccess,
  setConsentFailed,
  setConsentURLSuccess,
  setConsentURLFailed,
  setConsentByContact,
  setPortOptionsSuccess,
  setPortOptionsFailed,
} = contactsSlice.actions

export default contactsSlice.reducer

export const getContacts = (searchvalue: ContactFilterValues): ContactsThunk => async (
  dispatch,
) => {
  try {
    dispatch(initializeContacts(true))
    const response = await Api.get('/contacts/', searchvalue)
    dispatch(getContactsSuccess(response.data.data))
  } catch (err) {
    dispatch(getContactsFailed(err.toString()))
  }
  dispatch(initializeContacts(false))
}

export const getWaitConsent = (): ContactsThunk => async (dispatch) => {
  try {
    dispatch(initializeContacts(true))
    const response = await Api.get('/contacts/wait_consent')
    dispatch(getContactsSuccess(response.data.data))
  } catch (err) {
    dispatch(getContactsFailed(err.toString()))
  }
  dispatch(initializeContacts(false))
}

export const patchStatus = (
  id: string | number,
  value: { status: string },
  searchValue: ContactFilterValues,
): ContactsThunk => async (dispatch) => {
  try {
    await Api.put(`/contacts/${id}/update_status`, value)
    dispatch(getContacts(searchValue))
  } catch (error) {
    console.error(error.response || error)
  }
}

export const getContactById = (id: number | string): ContactsThunk => async (dispatch) => {
  try {
    dispatch(initializeContacts(true))
    const response = await Api.get(`/contacts/${id}`, {})
    dispatch(setCurrentContactsSuccess(response.data.data))
  } catch (err) {
    console.error(err)
    if (err && err.response) {
      dispatch(
        setCurrentContactsFailed(
          err.response.data?.message || 'เกิดข้อผิดพลาด กรุณาทำรายการใหม่ในภายหลัง',
        ),
      )
      alert(
        JSON.stringify(err.response.data?.message || 'เกิดข้อผิดพลาด กรุณาทำรายการใหม่ในภายหลัง'),
      )
    }
    switch (err.response.status) {
      case 404:
        window.open('/contacts', '_self')
        break
      default:
        break
    }
  }
  dispatch(initializeContacts(false))
}

export const getInsurance = (
  id: string | number,
  setInsurancePorts: SetStateType<Array<InsurancePortType>>,
  setLoading: SetStateType<boolean>,
): ContactsThunk => async () => {
  try {
    setLoading(true)
    const response = await Api.get(`/contacts/${id}/insurance_ports/`)
    setInsurancePorts(response.data.data.insurancePorts)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    setLoading(false)
  }
}

export const getInsuranceById = (
  getInsurancePorts: SetStateType<InsurancePortType>,
  setLoading: SetStateType<boolean>,
  id: string,
  insuranceId: string,
  cb: Function,
): ContactsThunk => async () => {
  try {
    setLoading(true)
    const response = await Api.get(`/contacts/${id}/insurance_ports/${insuranceId}`)
    getInsurancePorts(response.data.data.insurancePort)
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
      switch (err.response.status) {
        case 404:
          cb()
          break
        default:
          break
      }
    }
  } finally {
    setLoading(false)
  }
}

export const createInsurance = (
  id: string,
  newUser: InsurancePortType,
  cb: Function,
): ContactsThunk => async () => {
  try {
    // initial values
    delete newUser.ownerName
    delete newUser.ownerGender
    delete newUser.ownerAge
    if (newUser.premium) {
      let data_array = newUser.premium.split(',')
      let new_data = data_array.join('')
      newUser.premium = new_data
    }
    if (newUser.sumInsured) {
      let data_array = newUser.sumInsured.split(',')
      let new_data = data_array.join('')
      newUser.sumInsured = new_data
    }
    // initial values

    //  specialFields
    if (newUser.policyType === 'life' && newUser.insuranceType === 'investment') {
      if (newUser.specialFields?.premiumAllowance) {
        let data = newUser.specialFields.premiumAllowance
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.premiumAllowance = parseFloat(new_data)
      }
      if (newUser.specialFields?.residualValue) {
        let data = newUser.specialFields.residualValue
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.residualValue = parseFloat(new_data)
      }
      if (newUser.specialFields?.rateOfReturn) {
        let data = newUser.specialFields.rateOfReturn
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.rateOfReturn = parseFloat(new_data)
      }
    } else if (newUser.policyType === 'motor') {
      delete newUser.paymentType
      delete newUser.payPeriodType
      delete newUser.payPeriodValue
      delete newUser.specialFields?.premiumAllowance
      delete newUser.specialFields?.residualValue
      delete newUser.specialFields?.rateOfReturn
    } else {
      delete newUser.specialFields
    }
    // specialFields

    // purpose
    if (newUser.insurancePattern === 'personal') {
      // pass
    } else {
      newUser.purpose = 'for_other_protection'
    }
    // purpose

    // insuranceRiders
    if (
      newUser.policyType === 'life' &&
      newUser.insuranceRiders &&
      newUser.insuranceRiders.length > 0
    ) {
      let arr: Array<{
        riderType: string
        peroidYearNumber: number
        compensation: number
        premium: number
      }> = []
      newUser.insuranceRiders.forEach((value) => {
        if (value.riderType !== '') {
          arr.push(value)
        }
      })
      newUser.insuranceRiders = arr
    } else {
      delete newUser.insuranceRiders
    }
    // insuranceRiders

    // insuranceType
    if (newUser.policyType === 'accident') {
      newUser.insuranceType = 'PA/ME'
    } else if (newUser.policyType === 'home') {
      newUser.insuranceType = ''
    }
    // insuranceType

    // yieldToMaturityRate
    if (newUser.insurancePattern === 'personal') {
      if (newUser.policyType === 'life' && newUser.insuranceType === 'term') {
        newUser.yieldToMaturityRate = ''
      }
    } else {
      newUser.yieldToMaturityRate = ''
    }
    // yieldToMaturityRate

    let modifiedParams = {}
    modifiedParams = {
      ...newUser,
    }

    await Api.post(`/contacts/${id}/insurance_ports`, modifiedParams)
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const editInsurance = (
  id: string,
  insuranceId: string,
  newUser: InsurancePortType,
  cb: Function,
): ContactsThunk => async () => {
  try {
    // initial values
    delete newUser.ownerName
    delete newUser.ownerGender
    delete newUser.ownerAge
    if (newUser.premium) {
      let data_array = newUser.premium.split(',')
      let new_data = data_array.join('')
      newUser.premium = new_data
    }
    if (newUser.sumInsured) {
      let data_array = newUser.sumInsured.split(',')
      let new_data = data_array.join('')
      newUser.sumInsured = new_data
    }
    // initial values

    //  specialFields
    if (newUser.policyType === 'life' && newUser.insuranceType === 'investment') {
      if (newUser.specialFields?.premiumAllowance) {
        let data = newUser.specialFields.premiumAllowance
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.premiumAllowance = parseFloat(new_data)
      }
      if (newUser.specialFields?.residualValue) {
        let data = newUser.specialFields.residualValue
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.residualValue = parseFloat(new_data)
      }
      if (newUser.specialFields?.rateOfReturn) {
        let data = newUser.specialFields.rateOfReturn
        let data_array = data.toString().split(',')
        let new_data = data_array.join('')
        newUser.specialFields.rateOfReturn = parseFloat(new_data)
      }
    } else if (newUser.policyType === 'motor') {
      delete newUser.paymentType
      delete newUser.payPeriodType
      delete newUser.payPeriodValue
      delete newUser.specialFields?.premiumAllowance
      delete newUser.specialFields?.residualValue
      delete newUser.specialFields?.rateOfReturn
    } else {
      delete newUser.specialFields
    }
    // specialFields

    // purpose
    if (newUser.insurancePattern === 'personal') {
      // pass
    } else {
      newUser.purpose = 'for_other_protection'
    }
    // purpose

    // insuranceRiders
    if (
      newUser.policyType === 'life' &&
      newUser.insuranceRiders &&
      newUser.insuranceRiders.length > 0
    ) {
      let arr: Array<{
        riderType: string
        peroidYearNumber: number
        compensation: number
        premium: number
      }> = []
      newUser.insuranceRiders.forEach((value) => {
        if (value.riderType !== '') {
          arr.push(value)
        }
      })
      newUser.insuranceRiders = arr
    } else {
      delete newUser.insuranceRiders
    }
    // insuranceRiders

    // insuranceType
    if (newUser.policyType === 'accident') {
      newUser.insuranceType = 'PA/ME'
    } else if (newUser.policyType === 'home') {
      newUser.insuranceType = ''
    }
    // insuranceType

    // yieldToMaturityRate
    if (newUser.insurancePattern === 'personal') {
      if (newUser.policyType === 'life' && newUser.insuranceType === 'term') {
        newUser.yieldToMaturityRate = ''
      }
    } else {
      newUser.yieldToMaturityRate = ''
    }
    // yieldToMaturityRate

    let modifiedParams = {}
    modifiedParams = {
      ...newUser,
    }

    await Api.put(`/contacts/${id}/insurance_ports/${insuranceId}`, modifiedParams)
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const deleteInsurance = (
  id: string,
  insuranceId: string,
  cb: Function,
): ContactsThunk => async () => {
  try {
    await Api.delete(`/contacts/${id}/insurance_ports/${insuranceId}`, {})
    cb()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const createContact = (
  values: ContactType,
  isFormData: boolean,
  cb: Function,
): ContactsThunk => async (dispatch) => {
  try {
    dispatch(initializeContacts(true))
    const response = await Api.post('/contacts/', values, { isFormData: isFormData })
    dispatch(setCurrentContactsSuccess(response.data.data))
    cb()
  } catch (err) {
    console.error(err)
    if (err && err.response) {
      dispatch(
        setCurrentContactsFailed(
          err.response.data?.message || 'เกิดข้อผิดพลาด กรุณาทำรายการใหม่ในภายหลัง',
        ),
      )
      alert(
        JSON.stringify(err.response.data?.message || 'เกิดข้อผิดพลาด กรุณาทำรายการใหม่ในภายหลัง'),
      )
    }
    switch (err.response.status) {
      case 404:
        window.open('/contacts', '_self')
        break
      default:
        break
    }
  }
  dispatch(initializeContacts(false))
}

export const updateContact = (
  id: number | string,
  contact: ContactType,
  cb: Function,
): ContactsThunk => async (dispatch) => {
  try {
    const response = await Api.put(`/contacts/${id}`, contact, { isFormData: true })
    dispatch(setCurrentContactsSuccess(response.data.data))
    dispatch(getContactById(id))
    cb()
  } catch (err) {
    dispatch(setCurrentContactsFailed(err.toString()))
  }
}

export const updateAssets = (
  id: number | string,
  assetId: number,
  cb: Function,
): ContactsThunk => async (dispatch) => {
  try {
    await Api.put(`/contacts/${id}`, { assetId })
    cb()
  } catch (err) {
    console.error(err)
    if (err && err.response) {
      alert(JSON.stringify(err.response.data.message))
    }
  }
}

export const updateShortContact = (id: number | string, values = {}): ContactsThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.put(`/contacts/${id}`, values)
    dispatch(setCurrentContactsSuccess(response.data.data))
  } catch (err) {
    dispatch(setCurrentContactsFailed(err.toString()))
  }
}

export const deleteContactById = (id: number | string, cb: Function): ContactsThunk => async (
  dispatch,
) => {
  try {
    dispatch(initializeContacts(true))
    await Api.delete(`/contacts/${id}`, {})
    cb()
  } catch (err) {
    dispatch(setCurrentContactsFailed(err.toString()))
  }
  dispatch(initializeContacts(false))
}

export const getOptions = (): ContactsThunk => async (dispatch) => {
  try {
    const response = await Api.get('/contacts/options')
    dispatch(setOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(setOptionsFailed(err.toString()))
  }
}

export const sendConsent = (
  id: number | string,
  email: string,
  cb: Function = () => {},
): ContactsThunk => async (dispatch) => {
  try {
    await Api.put(`/contacts/${id}/send_email`, { email: email })
    cb()
  } catch (err) {
    console.error(err)
    // alert(JSON.stringify(err.response.data.message))
    alert(err.response.data.message)
    dispatch(setConsentFailed(err.toString()))
  }
}

export const getPortOptions = (
  id: number | string,
  cb: Function = () => {},
): ContactsThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/contacts/${id}/insurance_ports/options`, { contactId: id })
    dispatch(setPortOptionsSuccess(response.data.data))
    cb()
  } catch (err) {
    console.error(err)
    alert(err.response.data.message)
    dispatch(setPortOptionsFailed(err.toString()))
  }
}
