import styled from '@emotion/styled'
import { StatusType } from './OrdersCard.type'

export const Status = styled.div<StatusType>`
  font-size: 12px;
  color: ${(props) =>
    props.status === 'บริษัทประกันอนุมัติ' || props.status === 'สั่งซื้อสำเร็จ'
      ? 'var(--agt-success-color)'
      : props.status === 'พนักงานยกเลิก' || props.status === 'ยกเลิก'
      ? 'var(--agt-error-color)'
      : 'var(--agt-primary-text-color)'};
`
