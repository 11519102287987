import React, { useState, useEffect } from 'react'
import { ErrorMessage, useField, useFormikContext, getIn, FieldArray, Field } from 'formik'
import { faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format'
import { useDropzone } from 'react-dropzone'
import subDays from 'date-fns/subDays'
import isEqual from 'lodash/isEqual'
import isNaN from 'lodash/isNaN'
import get from 'lodash/get'
import has from 'lodash/has'
import moment from 'moment'
import Api from 'api'
import SetStateType from 'types/SetStateType'
import {
  ImageComponentType,
  optionsType,
  insuranceType,
  policyType,
  purposeOptionsType,
  imageResponse,
  OptionPropsType,
  RidersType,
  ActModalType,
  LifeModalType,
} from './InsurancePortForm.type'
import {
  AddImage,
  Selector,
  SelectDisabledStyles,
  SelectErrorsStyles,
  SelectStyles,
  ErrorMassageStyle,
  ModalContent,
  DatePickerField,
} from './InsurancePortForm.styles'
import InsuranceModal from 'components/InsuranceModal'
import { getYearsOptions } from 'components/DatePicker'

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)

export const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (parseFloat(value) === 0) {
    error = message || 'กรุณาระบุ'
  }
  return error
}

export const getOptions = async (
  setGenderOptions: SetStateType<Array<optionsType>>,
  setInsurancePatternOptions: SetStateType<Array<optionsType>>,
  setInsuranceTypeOptions: SetStateType<insuranceType>,
  setPayPeriodTypeOptions: SetStateType<Array<optionsType>>,
  setPaymentTypeOptions: SetStateType<Array<optionsType>>,
  setPolicyTypeOptions: SetStateType<policyType>,
  setInsuranceCompanyNameOptions: SetStateType<Array<optionsType>>,
  setRiderTypeOptions: SetStateType<Array<optionsType>>,
  setPurposeOptions: SetStateType<purposeOptionsType>,
  setStateOptions: SetStateType<Array<optionsType>>,
  setLoading: SetStateType<boolean>,
  path: string,
) => {
  try {
    setLoading(true)
    const response = await Api.get(path)
    setGenderOptions(response.data.data.genderOptions)
    setInsurancePatternOptions(response.data.data.insurancePatternOptions)
    setInsuranceTypeOptions(response.data.data.insuranceTypeOptions)
    setPayPeriodTypeOptions(response.data.data.payPeriodTypeOptions)
    setPaymentTypeOptions(response.data.data.paymentTypeOptions)
    setPolicyTypeOptions(response.data.data.policyTypeOptions)
    setRiderTypeOptions(response.data.data.riderTypeOptions)
    setInsuranceCompanyNameOptions(response.data.data.insuranceCompanyNameOptions)
    setPurposeOptions(response.data.data.purposeOptions)
    setStateOptions(response.data.data.stateOptions)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  } finally {
    setLoading(false)
  }
}

export const postImageAttachment = async (image: File) => {
  try {
    let isFormData = true
    let postImageApi: string = '/attachments/'
    const response = await Api.post(postImageApi, { image }, { isFormData: isFormData })
    return response.data.data.attachment
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

export const ImageComponent = (props: ImageComponentType) => {
  const { isDisabled, isEdit, forOnce } = props
  const [field] = useField('policyDocumentId')
  const [fieldFullPath] = useField('policyDocumentFullPath')
  const [previewFile, setFile] = useState<imageResponse>({ id: 0, url: '' })
  const { setFieldValue } = useFormikContext()
  const [typeOfUrl, setTypeOfUrl] = useState<string>('')
  const onDrop = async (acceptedFiles) => {
    let lengthOfUrl: number
    const attachment = await postImageAttachment(acceptedFiles[0])
    setFile(attachment)
    setFieldValue('policyDocumentId', attachment.id)
    lengthOfUrl = attachment.url.split('.').length
    setTypeOfUrl(attachment.url.split('.')[lengthOfUrl - 1].toUpperCase())
  }
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const clearFile = () => {
    setFile({ id: 0, url: '' })
    setFieldValue('policyDocumentId', '')
    if (isEdit && forOnce) {
      forOnce(false)
    }
  }
  useEffect(() => {
    if (isEdit && fieldFullPath.value) {
      let lengthImage: number = 0
      lengthImage = fieldFullPath.value.split('.').length
      setTypeOfUrl(fieldFullPath.value.split('.')[lengthImage - 1].toUpperCase())
    }
  }, [isEdit, fieldFullPath.value])
  return (
    <>
      {isEdit && fieldFullPath.value ? (
        <AddImage>
          <div {...getRootProps({ className: 'dropzone' })}>
            <button type="button" disabled={true}>
              อัปโหลด
            </button>
            <input {...getInputProps()} />
          </div>
          <div className="ContainerAfter">
            <div className="LeftColumn">
              <div className="Font">{typeOfUrl}</div>
            </div>
            <div className="MiddleColumn">
              <small title={fieldFullPath.value}>{fieldFullPath.value}</small>
            </div>
            <div className="RightColumn" onClick={clearFile}>
              <FontAwesomeIcon icon={faTimes} color="#000000" style={{ fontSize: '20px' }} />
            </div>
          </div>
        </AddImage>
      ) : (
        <AddImage>
          {field.value === '' || !field.value ? (
            <div {...getRootProps({ className: 'dropzone' })}>
              <button type="button" disabled={isDisabled}>
                อัปโหลด
              </button>
              <input {...getInputProps()} />
            </div>
          ) : (
            <div {...getRootProps({ className: 'dropzone' })}>
              <button disabled>อัปโหลด</button>
              <input {...getInputProps()} />
            </div>
          )}
          {field.value === '' || !field.value ? (
            <div className="ContainerBefore">
              <div className="Left">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="var(--agt-secondary-text-color)"
                  style={{ fontSize: '14px' }}
                />
              </div>
              <small className="Right">อัปโหลดภาพกรมธรรม์ รองรับไฟล์ .jpg .png .pdf</small>
            </div>
          ) : (
            <div className="ContainerAfter">
              <div className="LeftColumn">
                <div className="Font">{typeOfUrl}</div>
              </div>
              <div className="MiddleColumn">
                <small>{previewFile.url}</small>
              </div>
              <div className="RightColumn" onClick={clearFile}>
                <FontAwesomeIcon icon={faTimes} color="#000000" style={{ fontSize: '20px' }} />
              </div>
            </div>
          )}
        </AddImage>
      )}
    </>
  )
}

export const OptionsComponent = ({
  name,
  options,
  isDisable,
  onChange = () => {},
}: OptionPropsType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor ? defaultSeletor : ''}
      onChange={(option: optionsType) => {
        setValue(option.value)
        if (onChange) {
          onChange(option.value)
        }
      }}
      options={options}
      styles={
        isDisable
          ? SelectDisabledStyles
          : meta.error && meta.touched
          ? SelectErrorsStyles
          : SelectStyles
      }
      isDisabled={isDisable}
      placeholder="โปรดระบุ"
    />
  )
}

export const CustomSelectComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  forChangePattern = (value: any) => {},
  ...props
}) => {
  const { options, isDisabled = false } = props
  const isSelected = options.find((select) => select.value === field.value)
  return (
    <>
      <Selector
        name={field.name}
        {...field}
        {...props}
        options={options}
        value={isSelected ? isSelected : ''}
        onChange={(option: { value: string; label: string }) => {
          setFieldValue(field.name, option.value)
          if (forChangePattern) {
            forChangePattern(option.value)
          }
        }}
        styles={errors[field.name] && touched[field.name] ? SelectErrorsStyles : SelectStyles}
        placeholder="โปรดระบุ"
        isDisabled={isDisabled}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export const CustomVehicleSelectComponent = (props) => {
  const { field, placeholder = 'โปรดระบุ', options, isDisabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { setValue } = helpers
  return (
    <>
      <Selector
        options={options}
        value={options?.find((e) => e.value === field.value?.id) || ''}
        onChange={(option: { value: string; label: string; vehicle: {} }) => {
          setValue(option.vehicle)
        }}
        styles={meta.error && meta.touched ? SelectErrorsStyles : SelectStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export function getStateFromEndPolicyAt(date: any) {
  let state = ''
  let today = new Date()
  let yesterday = moment(new Date(subDays(today, 1)), 'MM/DD/YYYY')
  let myDate = moment(new Date(date), 'MM/DD/YYYY')
  let diffDays = myDate.diff(yesterday, 'days')
  let diffHours = myDate.diff(yesterday, 'hours')
  if (diffDays > 0) {
    // pass
  } else {
    if (diffHours < 0) {
      state = 'expired'
    }
  }
  return state
}

export function getMinDate(date: any) {
  let validateDate = subDays(date, -1)
  return new Date(validateDate)
}

export function getMaxDate(date: any) {
  let validateDate = subDays(date, 1)
  return new Date(validateDate)
}

export function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      border: '1px solid var(--agt-error-color)',
    }
  }
}

export const CustomCurrencyComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 9999999 } = props
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
        }}
        decimalScale={2}
        style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export const CurrencyModal = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 10000000 } = props
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator={true}
      placeholder="โปรดระบุ"
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
      }}
      decimalScale={2}
      isAllowed={(values) => {
        const { value, floatValue } = values
        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
          return true
        }
        if (value.charAt(0) === '0') {
          if (value.charAt(1) && value.charAt(1) !== '.') {
            return false
          }
        }
        if (floatValue >= min && floatValue <= max) {
          return true
        }
      }}
    />
  )
}

export const YearsModal = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 99 } = props
  return (
    <NumberFormat
      isNumericString={true}
      thousandSeparator={true}
      placeholder="โปรดระบุ"
      {...field}
      {...props}
      onChange={(e) => {
        setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
      }}
      decimalScale={0}
      isAllowed={(values) => {
        const { value, floatValue } = values
        if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
          return true
        }
        if (value.charAt(0) === '0') {
          if (value.charAt(1) && value.charAt(1) !== '.') {
            return false
          }
        }
        if (floatValue >= min && floatValue <= max) {
          return true
        }
      }}
    />
  )
}

export const CustomPercentComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 100 } = props
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        decimalScale={2}
        style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export const CustomSpecialFieldComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 9999999 } = props
  let name: string = field.name.split('.')
  let getTouched = getIn(touched.specialFields, name[1])
  let getErrors = getIn(errors.specialFields, name[1])

  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        onChange={(e) => {
          setFieldValue(field.name, e.target.value.replace(/(,|-)/g, ''))
        }}
        decimalScale={2}
        style={getTouched && getErrors && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export const CustomSpecialFieldPercentComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { min = 0, max = 100 } = props
  let name: string = field.name.split('.')
  let getTouched = getIn(touched.specialFields, name[1])
  let getErrors = getIn(errors.specialFields, name[1])
  return (
    <>
      <NumberFormat
        isNumericString={true}
        thousandSeparator={true}
        placeholder="โปรดระบุ"
        {...field}
        {...props}
        decimalScale={2}
        style={getTouched && getErrors && getStyles(errors, field.name)}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
      />
      <ErrorMessage component={ErrorMassageStyle} name={field.name} />
    </>
  )
}

export function checkStateModal(object: any) {
  let flag = true
  for (var property in object) {
    let value = `${object[property]}`
    if (property === 'riderType') {
      if (value === '') {
        flag = false
        break
      }
    } else {
      if (value === '') {
        flag = false
        break
      } else {
        let data_array = value.toString().split(',')
        let new_data = data_array.join('')
        let amount = 0
        amount = parseFloat(new_data)
        if (amount === 0) {
          flag = false
          break
        }
      }
    }
  }
  return flag
}

export function getNaNCurrency(obj, key) {
  let amount = 0
  amount = parseFloat(get(obj, key))
  if (key === 'compensation') {
    if (isNaN(amount)) {
      return true
    } else {
      return false
    }
  } else {
    if (isNaN(amount) || amount <= 0) {
      return true
    } else {
      return false
    }
  }
}

export function myValidate(obj: Array<RidersType>, isEdit: boolean = false, index: number = 0) {
  if (isEdit) {
    if (
      isEqual(get(obj[index], 'riderType'), '') ||
      getNaNCurrency(obj[index], 'compensation') ||
      getNaNCurrency(obj[index], 'premium') ||
      getNaNCurrency(obj[index], 'peroidYearNumber')
    ) {
      return true
    } else {
      return false
    }
  } else {
    let len = obj.length - 1
    if (
      isEqual(get(obj[len], 'riderType'), '') ||
      getNaNCurrency(obj[len], 'compensation') ||
      getNaNCurrency(obj[len], 'premium') ||
      getNaNCurrency(obj[len], 'peroidYearNumber')
    ) {
      return true
    } else {
      return false
    }
  }
}

export function getMoreInsurance(array) {
  let fact = false
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (get(array[i], 'riderType') !== '' && !has(array[i], '_destroy')) {
        fact = true
        break
      }
    }
  } else {
    fact = false
  }
  return fact
}

export function checkedRiders(array: Array<RidersType>) {
  let fact = false
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (get(array[i], 'riderType') !== '' && !has(array[i], '_destroy')) {
        fact = true
        break
      }
    }
  } else {
    fact = false
  }
  return fact
}

export const NewCustomStrInputComponent = (props) => {
  const { field, placeholder = 'โปรดระบุ', disabled = false, className = '' } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers

  return (
    <>
      <input
        {...field}
        type="string"
        value={value}
        className={className}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value)
        }}
        disabled={disabled}
        onKeyDown={(e) => {
          if (/[+-.,]$/.test(e.key)) {
            e.preventDefault()
          }
        }}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
      />
      <ErrorMessage component={ErrorMassageStyle} name={name} />
    </>
  )
}

export const NewCustomPersonInputComponent = (props) => {
  const { field, placeholder = 'โปรดระบุ', disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers

  return (
    <>
      <input
        {...field}
        type="number"
        value={value}
        placeholder={placeholder}
        onChange={(e) => {
          if (parseInt(e.target.value) > 0 && parseInt(e.target.value) < 10) {
            setValue(e.target.value)
          } else if (e.target.value === '') {
            setValue(e.target.value)
          }
        }}
        disabled={disabled}
        onKeyDown={(e) => {
          if (/[+-.,]$/.test(e.key)) {
            e.preventDefault()
          }
        }}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
      />
      <ErrorMessage component={ErrorMassageStyle} name={name} />
    </>
  )
}

export const NewCustomFieldComponent = (props) => {
  const { field, placeholder = 'โปรดระบุ', min = 0, max = 9999999, disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { setValue } = helpers

  return (
    <>
      <NumberFormat
        {...field}
        isNumericString={true}
        thousandSeparator={true}
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value.replace(/(,|-)/g, ''))
        }}
        decimalScale={2}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        isAllowed={(values) => {
          const { value, floatValue } = values
          if (typeof floatValue === 'undefined' || typeof value === 'undefined') {
            return true
          }
          if (value.charAt(0) === '0') {
            if (value.charAt(1) && value.charAt(1) !== '.') {
              return false
            }
          }
          if (floatValue >= min && floatValue <= max) {
            return true
          }
        }}
        disabled={disabled}
      />
      <ErrorMessage component={ErrorMassageStyle} name={name} />
    </>
  )
}

export const NewCustomSelectComponent = (props) => {
  const { field, placeholder = 'โปรดระบุ', options, isDisabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { setValue } = helpers

  return (
    <>
      <Selector
        // {...field}
        options={options}
        value={options?.find((select) => select.value === field.value) || ''}
        onChange={(option: { value: string; label: string }) => {
          setValue(option.value)
        }}
        styles={meta.error && meta.touched ? SelectErrorsStyles : SelectStyles}
        placeholder={placeholder}
        isDisabled={isDisabled}
      />
      <ErrorMessage component={ErrorMassageStyle} name={name} />
    </>
  )
}

export function actValidate(obj: {
  annualPremium: string
  policyNo: string
  startPolicyAt: string | Date
  endPolicyAt: string | Date
}) {
  if (isEqual(get(obj, 'policyNo'), '') || getNaNCurrency(obj, 'annualPremium')) {
    return true
  }
  return false
}

export const LifeModal = ({
  isAddInsurance,
  values,
  setIsAddInsurance,
  setWaitingModal,
  prevArray,
  portOptions,
  setPrevArray,
  isChange,
  setIsChange,
  indexField,
}: LifeModalType) => {
  return (
    <>
      <InsuranceModal
        isOpen={isAddInsurance}
        onConfirm={() => {}}
        onCancel={() => {
          if (values.insuranceRiders) {
            setIsAddInsurance(false)
            setWaitingModal(false)
            values.insuranceRiders = prevArray
          }
        }}
      >
        <ModalContent>
          <div className="Container">
            <h3 className="Header">เพิ่มอนุสัญญา</h3>
            <FieldArray name="insuranceRiders">
              {({ push }) => (
                <>
                  {values.insuranceRiders &&
                    values.insuranceRiders.length > 0 &&
                    values.insuranceRiders.map((friend, index) => {
                      let len = values.insuranceRiders ? values.insuranceRiders.length - 1 : 0
                      if (index !== len) {
                        return null
                      }
                      return (
                        <div className="Body">
                          <div className="Row">
                            <div className="LeftColumn">
                              <div className="Topic">ประเภทอนุสัญญา</div>
                              <Field
                                name={`insuranceRiders.${index}.riderType`}
                                component={CustomSelectComponent}
                                options={portOptions.riderTypeOptions}
                                // validate={validator('กรุณาระบุประเภทอนุสัญญา')}
                              />
                            </div>
                            <div className="RightColumn">
                              <div className="Topic">เงินชดเชยต่อวัน</div>
                              <Field
                                name={`insuranceRiders.${index}.compensation`}
                                component={CurrencyModal}
                              />
                            </div>
                          </div>
                          <div className="Row">
                            <div className="LeftColumn">
                              <div className="Topic">เบี้ยประกันต่อปี</div>
                              <Field
                                name={`insuranceRiders.${index}.premium`}
                                component={CurrencyModal}
                              />
                            </div>
                            <div className="RightColumn">
                              <div className="Topic">จำนวนปีที่ต้องชำระเบี้ยประกันตามกรมธรรม์</div>
                              <Field
                                name={`insuranceRiders.${index}.peroidYearNumber`}
                                component={YearsModal}
                              />
                            </div>
                          </div>
                          <div className="Button">
                            <button
                              className="primary"
                              onClick={() => {
                                if (values.insuranceRiders) {
                                  let setArr = values.insuranceRiders
                                  setArr.push({
                                    riderType: '',
                                    compensation: 0,
                                    premium: 0,
                                    peroidYearNumber: 0,
                                  })
                                  setPrevArray(setArr)
                                  push({
                                    riderType: '',
                                    compensation: 0,
                                    premium: 0,
                                    peroidYearNumber: 0,
                                  })
                                  setIsAddInsurance(false)
                                  setWaitingModal(false)
                                }
                              }}
                              disabled={
                                values.insuranceRiders && myValidate(values.insuranceRiders)
                              }
                            >
                              บันทึก
                            </button>
                          </div>
                        </div>
                      )
                    })}
                </>
              )}
            </FieldArray>
          </div>
        </ModalContent>
      </InsuranceModal>
      <InsuranceModal
        isOpen={isChange}
        onConfirm={() => {}}
        onCancel={() => {
          if (values.insuranceRiders) {
            setIsChange(false)
            setWaitingModal(false)
            values.insuranceRiders = prevArray
          }
        }}
      >
        <ModalContent>
          <div className="Container">
            <h3 className="Header">แก้ไขอนุสัญญา</h3>
            <div className="Body">
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">ประเภทอนุสัญญา</div>
                  <Field
                    name={`insuranceRiders.${indexField}.riderType`}
                    component={CustomSelectComponent}
                    options={portOptions.riderTypeOptions}
                    // validate={validator('กรุณาระบุประเภทอนุสัญญา')}
                  />
                </div>
                <div className="RightColumn">
                  <div className="Topic">เงินชดเชยต่อวัน</div>
                  <Field
                    name={`insuranceRiders.${indexField}.compensation`}
                    component={CurrencyModal}
                  />
                </div>
              </div>
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">เบี้ยประกันต่อปี</div>
                  <Field name={`insuranceRiders.${indexField}.premium`} component={CurrencyModal} />
                </div>
                <div className="RightColumn">
                  <div className="Topic">จำนวนปีที่ต้องชำระเบี้ยประกันตามกรมธรรม์</div>
                  <Field
                    name={`insuranceRiders.${indexField}.peroidYearNumber`}
                    component={YearsModal}
                  />
                </div>
              </div>
              <div className="Button">
                <button
                  className="primary"
                  onClick={() => {
                    if (values.insuranceRiders) {
                      let setArr = values.insuranceRiders
                      setPrevArray(setArr)
                      setIsChange(false)
                      setWaitingModal(false)
                    }
                  }}
                  disabled={
                    values.insuranceRiders && myValidate(values.insuranceRiders, true, indexField)
                  }
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </ModalContent>
      </InsuranceModal>
    </>
  )
}

export const ActModal = ({
  isAct,
  values,
  setIsAct,
  setWaitingModal,
  setFieldValue,
  isChange,
  setIsChange,
  setIndexField,
  indexField,
  stack,
}: ActModalType) => {
  return (
    <>
      <InsuranceModal
        isOpen={isAct}
        onConfirm={() => {}}
        onCancel={() => {
          setIsAct(false)
          setWaitingModal(false)
          setFieldValue('specialFields.prbInsurance', {
            annualPremium: '',
            policyNo: '',
            startPolicyAt: new Date(),
            endPolicyAt: new Date(),
          })
        }}
      >
        <ModalContent>
          <div className="Container">
            <h3 className="Header">เพิ่ม พ.ร.บ.</h3>
            <div className="Body">
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">เบี้ยประกันต่อปี (บาท)</div>
                  <Field
                    name={`specialFields.prbInsurance.annualPremium`}
                    component={NewCustomFieldComponent}
                  />
                </div>
                <div className="RightColumn">
                  <div className="Topic">เลขกรมธรรม์</div>
                  <Field
                    name={`specialFields.prbInsurance.policyNo`}
                    component={NewCustomStrInputComponent}
                  />
                </div>
              </div>
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">เริ่มการคุ้มครอง</div>
                  <DatePickerField
                    name={`specialFields.prbInsurance.startPolicyAt`}
                    value={
                      values?.specialFields?.prbInsurance?.startPolicyAt
                        ? values?.specialFields?.prbInsurance?.startPolicyAt
                        : ''
                    }
                    showSelector={true}
                    yearOptions={getYearsOptions(null, 'desc', 50)}
                    onChange={(value) => {
                      setFieldValue(`specialFields.prbInsurance.startPolicyAt`, value)
                    }}
                  />
                </div>
                <div className="RightColumn">
                  <div className="Topic">สิ้นสุดคุ้มครอง</div>
                  <DatePickerField
                    name={`specialFields.prbInsurance.endPolicyAt`}
                    value={
                      values?.specialFields?.prbInsurance?.endPolicyAt
                        ? values?.specialFields?.prbInsurance?.endPolicyAt
                        : ''
                    }
                    showSelector={true}
                    yearOptions={getYearsOptions(null, 'desc', 50)}
                    onChange={(value) => {
                      setFieldValue(`specialFields.prbInsurance.endPolicyAt`, value)
                    }}
                  />
                </div>
              </div>
              <div className="Button">
                <button
                  className="primary"
                  onClick={() => {
                    setIsAct(false)
                    setWaitingModal(false)
                  }}
                  disabled={
                    values?.specialFields?.prbInsurance
                      ? actValidate(values?.specialFields?.prbInsurance)
                      : true
                  }
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </ModalContent>
      </InsuranceModal>
      <InsuranceModal
        isOpen={isChange}
        onConfirm={() => {}}
        onCancel={() => {
          if (values.specialFields && values.specialFields.prbInsurance) {
            setIsChange(false)
            setWaitingModal(false)
            setFieldValue('specialFields.prbInsurance', stack)
          }
        }}
      >
        <ModalContent>
          <div className="Container">
            <h3 className="Header">แก้ไข พ.ร.บ.</h3>
            <div className="Body">
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">เบี้ยประกันต่อปี (บาท)</div>
                  <Field
                    name={`specialFields.prbInsurance.annualPremium`}
                    component={NewCustomFieldComponent}
                  />
                </div>
                <div className="RightColumn">
                  <div className="Topic">เลขกรมธรรม์</div>
                  <Field
                    name={`specialFields.prbInsurance.policyNo`}
                    component={NewCustomStrInputComponent}
                  />
                </div>
              </div>
              <div className="Row">
                <div className="LeftColumn">
                  <div className="Topic">เริ่มการคุ้มครอง</div>
                  <DatePickerField
                    name={`specialFields.prbInsurance.startPolicyAt`}
                    value={
                      values?.specialFields?.prbInsurance?.startPolicyAt
                        ? values?.specialFields?.prbInsurance?.startPolicyAt
                        : ''
                    }
                    showSelector={true}
                    yearOptions={getYearsOptions(null, 'desc', 50)}
                    onChange={(value) => {
                      setFieldValue(`specialFields.prbInsurance.startPolicyAt`, value)
                    }}
                  />
                </div>
                <div className="RightColumn">
                  <div className="Topic">สิ้นสุดคุ้มครอง</div>
                  <DatePickerField
                    name={`specialFields.prbInsurance.endPolicyAt`}
                    value={
                      values?.specialFields?.prbInsurance?.endPolicyAt
                        ? values?.specialFields?.prbInsurance?.endPolicyAt
                        : ''
                    }
                    showSelector={true}
                    yearOptions={getYearsOptions(null, 'desc', 50)}
                    onChange={(value) => {
                      setFieldValue(`specialFields.prbInsurance.endPolicyAt`, value)
                    }}
                  />
                </div>
              </div>
              <div className="Button">
                <button
                  className="primary"
                  onClick={() => {
                    setIsChange(false)
                    setWaitingModal(false)
                  }}
                  disabled={
                    values?.specialFields?.prbInsurance
                      ? actValidate(values?.specialFields?.prbInsurance)
                      : true
                  }
                >
                  บันทึก
                </button>
              </div>
            </div>
          </div>
        </ModalContent>
      </InsuranceModal>
    </>
  )
}
