import React, {
  FC,
  // FormEvent,
  useState,
  useEffect,
  useCallback,
  Fragment,
} from 'react'
import { ErrorMessage, Form, Field, useField, useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons'
import { useDropzone } from 'react-dropzone'
import styled from '@emotion/styled'
import Select from 'react-select'
import subDays from 'date-fns/subDays'

import Avatar from 'components/Avatar'
import Gender from 'components/Inputs/Gender'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import CustomAddress from 'components/Address'
import { getLifeStylesOptions, validator } from 'pages/contacts/utils'
import { isEmail, isPhoneNumber } from 'utils/helper'
import { ContactType } from 'types/ContactType'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getOptions } from 'store/contactSlice'
import { RootState } from 'store/rootReducer'
// import ConsentModal from './ConsentModal'

const today = new Date()
const yesterday = subDays(today, 1)

type ContactValues = {
  values: ContactType
  // handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  handleSubmit: () => void
  submitForm: () => void
  isSubmitting?: boolean
}
type ListProps = {
  currentTab: string
  value: string
  index: number
  label: string
  lifeStyleKey: string
}
type optionsType = {
  value: string
  label: string
}
type OptionPropsType = {
  name: string
  options: Array<optionsType>
}
type TabProps = {
  active?: boolean
}
const Container = styled(Form)`
  display: flex;
  padding: 32px 0 8px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  max-width: 752px;
  margin: 0 auto;
  .Row {
    display: flex;
    flex-direction: row;
  }
`
const FieldGroup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 360px;
  margin-right: 16px;
  margin-bottom: 24px;
  &.extra {
    width: 100%;
  }
  .details {
    width: 690px;
    height: 96px !important;
    margin: 19px 5px 1px 5px;
    padding: 16px;
    resize: none;
  }
  .specialField {
    width: 100%;
  }
`
const StyledGender = styled(Gender)`
  width: 100%;
  height: 48px;
`
const AddressCheckboxGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  .isSameHomeAddress,
  .isSameCurrentAddress,
  .isSameIdCardAddress {
    width: 16px;
    height: 16px;
    margin: 0px 14px 0px 0px;
  }
  .topic {
    margin: 0px;
  }
`
const DatePickerField = styled(DatePicker)`
  border-radius: 4px;
  height: 48px;
  width: 100%;
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
const SubmitButton = styled.button`
  margin: 20px 16px 0 auto;
`
const Topic = styled.label`
  margin-bottom: 4px;
  svg {
    margin-right: 7px;
  }
`
const Tab = styled.button<TabProps>`
  width: 100%;
  height: 100%;
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-text-color)'};
  background-color: ${(props) => (props.active ? 'var(--agt-background-color-3)' : 'transparent')};
  :hover {
    background-color: ${(props) =>
      props.active ? 'var(--agt-background-color-3)' : 'transparent'};
  }
`
const ExpandDetail = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 736px;
  margin-right: 16px;
  padding: 12px 0;
  text-align: right;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  p {
    cursor: pointer;
    margin: 0px;
    color: var(--agt-primary-color-1);
    text-decoration: underline;
  }
`
const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 16px 32px 0;
  .dropzone {
    cursor: pointer;
  }
`
const DummyAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--agt-background-color-2);
`
const IconContainer = styled.div`
  position: absolute;
  left: calc(50% + 25px);
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--agt-primary-color-1);
  cursor: pointer;
`
const Selector = styled(Select)`
  background: var(--agt-white-color);
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '360px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '360px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}
const prefixSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '160px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '160px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}

const validateEmail = (value: string) => {
  let error
  if (value && !isEmail(value)) {
    error = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  return error
}
const validatePhoneNumber = (value: string) => {
  let error
  if (!value) {
    error = 'กรุณาระบุเบอร์โทรศัพท์'
  } else {
    let arr = value.split(' ')
    let filterArr = arr.filter((n) => n !== '')
    filterArr.forEach((phoneNumber) => {
      if (!isPhoneNumber(phoneNumber)) {
        return (error = 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง')
      }
    })
  }
  return error
}
const ImageComponent = () => {
  const [field] = useField('avatar')
  const [previewFile, setFile] = useState(field.value || '')
  const { setFieldValue } = useFormikContext()
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFile(URL.createObjectURL(acceptedFiles[0]))
      setFieldValue('avatar', acceptedFiles[0])
    },
    [setFieldValue],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })
  useEffect(
    () => () => {
      previewFile && URL.revokeObjectURL(previewFile)
    },
    [previewFile],
  )
  return (
    <ImageContainer>
      <div {...getRootProps({ className: 'dropzone' })}>
        {previewFile ? (
          <Avatar type="circle" avatar={previewFile || ''} width="120px" height="120px" />
        ) : (
          <DummyAvatar>
            <FontAwesomeIcon icon={faUser} color="white" style={{ fontSize: '60px' }} />
          </DummyAvatar>
        )}

        <input {...getInputProps()} />
      </div>
      <IconContainer>
        <FontAwesomeIcon icon={faCamera} color="white" style={{ fontSize: '18px' }} />
      </IconContainer>
    </ImageContainer>
  )
}
const OptionsComponent = ({ name, options }: OptionPropsType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor}
      onChange={(option: optionsType) => {
        const value = (option as optionsType).value
        setValue(value)
      }}
      options={
        value !== 'รอยินยอม' ? options.filter((option) => option.value !== 'รอยินยอม') : options
      }
      styles={customSelectStyles}
      placeholder="โปรดระบุ"
      isDisabled={value === 'รอยินยอม'}
    />
  )
}
export const PrefixComponent = ({ name, options }: OptionPropsType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor}
      onChange={(option: optionsType) => {
        const value = (option as optionsType).value
        setValue(value)
      }}
      options={options}
      styles={prefixSelectStyles}
      placeholder="โปรดระบุ"
    />
  )
}

const GroupAddress = (props) => {
  const { name } = props
  return (
    <Fragment>
      <FieldGroup>
        <Topic>เลขที่</Topic>
        <Field name={`${name}.housingNo`} type="string" placeholder="โปรดระบุ" />
      </FieldGroup>
      <FieldGroup>
        <Topic>หมู่</Topic>
        <Field name={`${name}.villageNo`} type="string" placeholder="โปรดระบุ" />
      </FieldGroup>
      <FieldGroup className="extra">
        <Topic>หมู่บ้าน/อาคาร</Topic>
        <Field name={`${name}.building`} type="string" placeholder="โปรดระบุ" />
      </FieldGroup>
      <FieldGroup>
        <Topic>ซอย/ตรอก</Topic>
        <Field name={`${name}.lane`} type="string" placeholder="โปรดระบุ" />
      </FieldGroup>
      <FieldGroup>
        <Topic>ถนน</Topic>
        <Field name={`${name}.road`} type="string" placeholder="โปรดระบุ" />
      </FieldGroup>
    </Fragment>
  )
}

const ContactForm: FC<ContactValues> = (props) => {
  const { isSubmitting, values, handleSubmit } = props
  const { setFieldValue } = useFormikContext()
  const dispatch = useDispatch()
  const options = useSelector((state: RootState) => state.contacts.options)
  const [isAddDetail, setIsAddDetail] = useState<boolean>(false)
  let defaultIsSameHomeAddress: boolean = false
  let defaultIsSameCurrentAddress: boolean = false
  let defaultIsSameIdCardAddress: boolean = false
  if (props.values !== undefined && props.values !== null) {
    defaultIsSameHomeAddress = props.values['isSameHomeAddress'] || false
    defaultIsSameCurrentAddress = props.values['isSameCurrentAddress'] || false
    defaultIsSameIdCardAddress = props.values['isSameIdCardAddress'] || false
  }
  const [isSameHomeAddress, setIsSameHomeAddress] = useState<boolean>(defaultIsSameHomeAddress)
  const [isSameCurrentAddress, setIsSameCurrentAddress] = useState<boolean>(
    defaultIsSameCurrentAddress,
  )
  const [isSameIdCardAddress, setIsSameIdCardAddress] = useState<boolean>(
    defaultIsSameIdCardAddress,
  )
  const onChangeField = (source: string, changeTo: string) => {
    setFieldValue(source, props.values[`${changeTo}`])
  }

  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])

  useEffect(() => {
    if (values.status !== 'ติดต่อไม่ได้') {
      setFieldValue('remark', '')
    }
  }, [values.status, setFieldValue])

  useEffect(() => {
    if (isSameHomeAddress) onChangeField('homeAddress', 'currentAddress')
    if (isSameCurrentAddress) onChangeField('officeAddress', 'currentAddress')
    if (isSameIdCardAddress) onChangeField('idCardAddress', 'homeAddress')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSameHomeAddress,
    isSameCurrentAddress,
    isSameIdCardAddress,
    values.currentAddress,
    values.homeAddress,
  ])

  useEffect(() => {
    if (
      !values.isSameHomeAddress &&
      values.currentAddress &&
      values.homeAddress &&
      isEqual(values.currentAddress, values.homeAddress)
    ) {
      setIsSameHomeAddress(true)
      setFieldValue('isSameHomeAddress', true)
    }
    if (
      !values.isSameCurrentAddress &&
      values.currentAddress &&
      values.officeAddress &&
      isEqual(values.currentAddress, values.officeAddress)
    ) {
      setIsSameCurrentAddress(true)
      setFieldValue('isSameCurrentAddress', true)
    }
    if (
      !values.isSameIdCardAddress &&
      values.homeAddress &&
      values.idCardAddress &&
      isEqual(values.homeAddress, values.idCardAddress)
    ) {
      setIsSameIdCardAddress(true)
      setFieldValue('isSameIdCardAddress', true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.currentAddress, values.homeAddress, values.officeAddress, values.idCardAddress])

  return (
    <Container onSubmit={handleSubmit}>
      <ImageComponent />
      <div className="Row">
        <FieldGroup>
          <Topic id="contact-new__name">ชื่อ *</Topic>
          <Field
            name="nickname"
            type="string"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุชื่อ')}
          />
          <ErrorMessage component={Error} name="nickname" />
        </FieldGroup>
        <FieldGroup>
          <Topic>เบอร์โทรศัพท์ *</Topic>
          <Field name="phoneNumber" validate={validatePhoneNumber}>
            {({ field, form, meta }) => (
              <div>
                <input
                  className="specialField"
                  type="text"
                  {...field}
                  placeholder="โปรดระบุ"
                  maxLength={10}
                  onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                />
                <ErrorMessage component={Error} name="phoneNumber" />
              </div>
            )}
          </Field>
        </FieldGroup>
      </div>
      <FieldGroup>
        <Topic>สถานะลูกค้า *</Topic>
        <OptionsComponent name="status" options={options.statusOptions} />
        <Field
          hidden
          name="status"
          placeholder="โปรดระบุ"
          validate={validator('กรุณาระบุสถานะลูกค้า')}
        />
        <ErrorMessage component={Error} name="status" />
      </FieldGroup>
      {values.status === 'ติดต่อไม่ได้' && (
        <FieldGroup>
          <Topic>หมายเหตุ</Topic>
          <Field name="remark" type="text" placeholder="โปรดระบุ" />
        </FieldGroup>
      )}
      <FieldGroup className="extra">
        <Topic>โน้ต</Topic>
        <Field
          name="note"
          className="textarea"
          component="textarea"
          type="text"
          placeholder="รายละเอียดเพิ่มเติม"
        />
      </FieldGroup>
      {isAddDetail && (
        <>
          <FieldGroup style={{ width: '160px' }}>
            <Topic>คำนำหน้าชื่อ</Topic>
            <PrefixComponent name="prefixName" options={options.prefixNameOptions} />
          </FieldGroup>
          <FieldGroup style={{ width: isAddDetail ? '272px' : '360px' }}>
            <Topic>ชื่อจริง</Topic>
            <Field name="firstName" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup style={{ width: isAddDetail ? '272px' : '360px' }}>
            <Topic>นามสกุล</Topic>
            <Field name="lastName" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup style={{ marginRight: '376px' }}>
            <Topic>เพศ</Topic>
            <StyledGender name="gender" />
          </FieldGroup>
          <div className="Row">
            <FieldGroup>
              <Topic>วันเกิด</Topic>
              <DatePickerField
                value={values.birthdate || ''}
                maxDate={yesterday}
                showSelector
                onChange={(value) => setFieldValue('birthdate', value)}
              />
              <Field hidden={true} name="birthdate" type="string" />
            </FieldGroup>
            <FieldGroup>
              <Topic>อีเมล</Topic>
              <Field name="email" type="email" placeholder="โปรดระบุ" validate={validateEmail} />
              <ErrorMessage component={Error} name="email" />
            </FieldGroup>
          </div>
          <div style={{ display: 'flex' }}>
            <FieldGroup>
              <Topic>สถานภาพ</Topic>
              <OptionsComponent name="maritalStatus" options={options.maritalStatusOptions} />
            </FieldGroup>
            <FieldGroup>
              <Topic>เลขบัตรประจำตัวประชาชน</Topic>
              <Field name="idCard">
                {({ field, form, meta }) => (
                  <div>
                    <input
                      className="specialField"
                      type="text"
                      {...field}
                      placeholder="โปรดระบุ"
                      onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                    />
                    <ErrorMessage component={Error} name="idCard" />
                  </div>
                )}
              </Field>
            </FieldGroup>
          </div>
          <FieldGroup>
            <Topic>อาชีพ</Topic>
            <Field name="occupation" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup>
            <Topic>ตำแหน่ง</Topic>
            <Field name="position" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup style={{ marginRight: '376px' }}>
            <Topic>บริษัท</Topic>
            <Field name="company" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup>
            <Topic>Facebook</Topic>
            <Field name="facebook" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup>
            <Topic>Line ID</Topic>
            <Field name="lineId" type="string" placeholder="โปรดระบุ" />
          </FieldGroup>
          <FieldGroup
            className="extra"
            style={{ borderTop: '1px solid var(--agt-secondary-color-1)', paddingTop: '24px' }}
          >
            <Topic style={{ marginBottom: '0' }}>ที่อยู่ปัจจุบัน (สำหรับส่งเอกสาร)</Topic>
          </FieldGroup>
          <GroupAddress name={'currentAddress'} />
          <CustomAddress addressType={'currentAddress'} />
          <FieldGroup className="extra">
            <Topic style={{ marginBottom: '24px' }}>ที่อยู่ตามทะเบียนบ้าน</Topic>
            <AddressCheckboxGroup style={{ marginBottom: '0' }}>
              <input
                className="isSameHomeAddress"
                name="isSameHomeAddress"
                type="checkbox"
                checked={isSameHomeAddress}
                onChange={() => {
                  const changed = !isSameHomeAddress
                  setFieldValue('isSameHomeAddress', changed)
                  setIsSameHomeAddress(changed)
                  onChangeField('homeAddress', 'currentAddress')
                }}
              />
              <Topic className="topic">เหมือนที่อยู่ปัจจุบัน</Topic>
            </AddressCheckboxGroup>
          </FieldGroup>
          {!isSameHomeAddress && <GroupAddress name={'homeAddress'} />}
          {!isSameHomeAddress && <CustomAddress addressType={'homeAddress'} />}
          <FieldGroup className="extra">
            <Topic style={{ marginBottom: '24px' }}>ที่อยู่ที่ทำงาน</Topic>
            <AddressCheckboxGroup style={{ marginBottom: '0' }}>
              <input
                className="isSameCurrentAddress"
                name="isSameCurrentAddress"
                type="checkbox"
                checked={isSameCurrentAddress}
                onChange={() => {
                  const changed = !isSameCurrentAddress
                  setFieldValue('isSameCurrentAddress', changed)
                  setIsSameCurrentAddress(changed)
                  onChangeField('officeAddress', 'currentAddress')
                }}
              />
              <Topic className="topic">เหมือนที่อยู่ปัจจุบัน</Topic>
            </AddressCheckboxGroup>
          </FieldGroup>
          {!isSameCurrentAddress && <GroupAddress name={'officeAddress'} />}
          {!isSameCurrentAddress && <CustomAddress addressType={'officeAddress'} />}
          <FieldGroup className="extra">
            <Topic style={{ marginBottom: '24px' }}>ที่อยู่ตามบัตรประชาชน</Topic>
            <AddressCheckboxGroup style={{ marginBottom: '0' }}>
              <input
                className="isSameIdCardAddress"
                name="isSameIdCardAddress"
                type="checkbox"
                checked={isSameIdCardAddress}
                onChange={() => {
                  const changed = !isSameIdCardAddress
                  setFieldValue('isSameIdCardAddress', changed)
                  setIsSameIdCardAddress(changed)
                  onChangeField('idCardAddress', 'homeAddress')
                }}
              />
              <Topic className="topic">เหมือนที่อยู่ตามทะเบียนบ้าน</Topic>
            </AddressCheckboxGroup>
          </FieldGroup>
          {!isSameIdCardAddress && <GroupAddress name={'idCardAddress'} />}
          {!isSameIdCardAddress && <CustomAddress addressType={'idCardAddress'} />}
          <br />
          <TabsComponent />
        </>
      )}
      {values.status !== 'รอยินยอม' && (
        <ExpandDetail>
          <p onClick={() => setIsAddDetail(!isAddDetail)}>
            {isAddDetail ? 'ซ่อนรายละเอียด' : 'กรอกแบบละเอียด'}
          </p>
        </ExpandDetail>
      )}
      <SubmitButton type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
      </SubmitButton>
    </Container>
  )
}

export default ContactForm

const TabsContainer = styled.div`
  width: 100%;
  padding-right: 16px;
`
const TabsTopic = styled.h4`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
`
const TabsSelector = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 66px;
  padding: 8px 0;
  background-color: white;
`
const CheckboxGroup = styled.div`
  padding: 15px;
  background: #eff1f5;
  .checkbox-lists {
    padding-left: 0px;
    width: 700px;
  }
`
const GroupTopic = styled.div`
  margin: 5px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid var(--agt-secondary-color-1);
`
const Checkbox = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: flex-start;
  .list {
    background: white;
    display: flex;
    flex-direction: row;
    width: calc(25% - 10px);
    height: 48px;
    margin: 5px;
    border-radius: 4px;
    border: 1px solid var(--agt-secondary-color-1);
    input[type='checkbox'] {
      border: 1px solid var(--agt-secondary-text-color);
      width: 16px;
      height: 16px;
      margin: 0px;
      margin-left: 16px;
      align-self: center;
      cursor: pointer;
    }
    &:hover {
      cursor: pointer;
    }
  }
  p {
    margin-left: 16px;
    align-self: center;
  }
`
const DistributeList = (props: ListProps) => {
  return (
    <Field
      name={`${props.currentTab}.${props.lifeStyleKey}.values`}
      type="checkbox"
      value={props.value}
    />
  )
}

const TAB_MENU = [
  { label: 'บันเทิง', value: 'entertainment' },
  { label: 'สุขภาพ', value: 'health' },
  { label: 'ท่องเที่ยว', value: 'travel' },
  { label: 'อาหาร', value: 'food' },
]
const TabsComponent = () => {
  const [currentTab, setCurrentTab] = useState('entertainment')
  return (
    <TabsContainer>
      <TabsTopic>สิ่งที่ลูกค้าสนใจ</TabsTopic>
      <TabsSelector>
        {TAB_MENU.map((tab, index) => (
          <Tab
            key={index}
            type="button"
            onClick={() => setCurrentTab(tab.value)}
            active={currentTab === tab.value}
          >
            {tab.label}
          </Tab>
        ))}
      </TabsSelector>
      <CheckboxGroup>
        {Object.entries(getLifeStylesOptions(currentTab) || { values: {} }).map(
          ([key, values], i) => (
            <div key={i}>
              <GroupTopic>{values?.label}</GroupTopic>
              <Checkbox className="checkbox-lists">
                {values?.fields.map(
                  ({ value, label }: { value: string; label: string }, index: number) => {
                    return (
                      <label key={index} className="list">
                        <DistributeList
                          currentTab={currentTab}
                          value={value}
                          label={label}
                          index={index}
                          lifeStyleKey={key}
                        />
                        <p>{label}</p>
                      </label>
                    )
                  },
                )}
              </Checkbox>
              <div>
                <FieldGroup key={`${currentTab}-remark-${i}`}>
                  <Field
                    className="details"
                    component="textarea"
                    key={`${currentTab}-remark-${i}`}
                    name={`${currentTab}.${key}.remark`}
                    type="textarea"
                    placeholder="รายละเอียดเพิ่มเติม"
                  />
                </FieldGroup>
              </div>
            </div>
          ),
        )}
      </CheckboxGroup>
    </TabsContainer>
  )
}
