import React, { useMemo } from 'react'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTable } from 'react-table'
import TableStyles from './CommissionTable.module.css'
import cx from 'classnames'
import Icon from 'components/Icon'
import styled from '@emotion/styled'
import snakeCase from 'lodash/snakeCase'
import { camelCase } from 'lodash'

const AscVector = styled(Icon)`
  transform: rotate(90deg);
  transform-origin: center;
  &.app-icon div > div {
    display: flex;
  }
  svg {
    width: 18px;
    height: 18px;
  }
  svg path {
    stroke: var(--agt-white-color);
  }
`

const DescVector = styled(AscVector)`
  transform: rotate(270deg) translateX(-4px);
`

function Table(props) {
  const {
    data,
    columns,
    className = '',
    paging = { page: 1, totalPages: 1 },
    searchValues = {},
    pagination = true,
    onChange = () => {},
    orderBy = [],
  } = props
  const table = useTable({ columns, data })
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } = table
  const { page, totalPages } = paging

  // TODO : DANGER ZONE this is for datepicker bug, if's broke!, FIX THIS FOR ME PLS!
  const dataList = useMemo(() => {
    return rows.map((row) => {
      prepareRow(row)
      return (
        <tr {...row.getRowProps()}>
          {row.cells.map((cell: any) => {
            return (
              <td
                {...cell.getCellProps([
                  {
                    style: cell.column.styles?.cell,
                  },
                ])}
              >
                {cell.render('Cell')}
              </td>
            )
          })}
        </tr>
      )
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const generateSortingIndicator = (columnId, searchValue) => {
    // if (columnId !== searchValue.orderBy) return null
    // return searchValue.order === 'desc' ? '^' : 'v'
    if (columnId !== camelCase(searchValue.orderBy)) return <AscVector name="vector_left" />
    else if (searchValue.order === 'asc') return <AscVector name="vector_left" />
    else if (searchValue.order === 'desc') return <DescVector name="vector_left" />
  }

  return (
    <>
      <section className={cx(TableStyles.container, className)}>
        <table className={TableStyles.table} {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => (
                  <th
                    {...column.getHeaderProps([
                      {
                        style: column.styles?.header,
                      },
                    ])}
                    onClick={() => {
                      if (orderBy.includes(column.id)) {
                        onChange({
                          page: 1,
                          orderBy: snakeCase(column.id),
                          // orderBy: column.id,
                          // order: searchValues.order === 'desc' ? 'asc' : 'desc',
                          order:
                            column.id === camelCase(searchValues.orderBy) &&
                            searchValues.order === 'desc'
                              ? 'asc'
                              : 'desc',
                        })
                      }
                    }}
                  >
                    {column.render('Header')}
                    {orderBy.includes(column.id) && (
                      <span style={{ float: 'right' }}>
                        {generateSortingIndicator(column.id, searchValues)}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>{dataList}</tbody>
        </table>
      </section>
      {pagination && (
        <section className={TableStyles.pagination}>
          <span className={TableStyles.input_wrapper}>
            หน้า
            <input
              type="number"
              value={page}
              onChange={(e) => {
                onChange({ page: e.target.value })
              }}
            />
            จาก {totalPages}
          </span>
          <button
            className={TableStyles.btn_pagination}
            onClick={() => {
              onChange({ page: page - 1 })
            }}
            disabled={page <= 1}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            className={TableStyles.btn_pagination}
            onClick={() => {
              onChange({ page: page + 1 })
            }}
            disabled={page >= totalPages}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </section>
      )}
    </>
  )
}

export default Table
