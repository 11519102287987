import React, { useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import SetStateType from 'types/SetStateType'
import { getPositionById } from 'utils/helper'
import Avatar from 'components/Avatar'

type WebTourType = {
  visible: boolean
  setVisible: SetStateType<boolean>
}
type ContainerType = {
  visible: boolean
  height: string
}
type DialogBoxType = {
  width?: string
  top?: string
  right?: string
}

const Container = styled.div<ContainerType>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => (props.visible ? 10000 : -1)};
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: rgba(0, 0, 0, 0.7);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s;
`
const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Position = styled.div`
  position: absolute;
`
const DialogBox = styled.div<DialogBoxType>`
  position: absolute;
  top: ${(props) => props.top || '0'};
  right: ${(props) => props.right || '0'};
  background-color: var(--agt-light-sky-color);
  color: var(--agt-primary-color-1);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 18px;
  width: ${(props) => props.width || 'fit-content'};
  .labelArrow {
    position: absolute;
    top: 16px;
    right: -8px;
    width: 16px;
    height: 16px;
    transform: rotate(135deg);
    background-color: var(--agt-light-sky-color);
    border-radius: 4px 0px 0px;
  }
  .labelArrow.top {
    top: -8px;
    left: 16px;
    border-radius: 0px 0px 0px 4px;
  }
  .labelArrow.topRight {
    top: -8px;
    right: 16px;
    border-radius: 0px 4px 0px;
  }
  .labelArrow.left {
    top: 16px;
    left: -8px;
    border-radius: 0px 0px 4px 0px;
  }
  .top {
    display: flex;
    flex-direction: column;
  }
  .top b {
    margin-bottom: 4px;
  }
  .bottom {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .bottom .choice {
    display: flex;
    justify-content: space-between;
    column-gap: 24px;
  }
  .bottom .choice .next,
  .bottom .choice .close {
    cursor: pointer;
  }
  .bottom .choice .next:hover,
  .bottom .choice .close:hover {
    color: var(--agt-dark-sky-color);
  }
  .bottom .choice.end {
    display: flex;
    justify-content: flex-end;
  }
`
const ViewOrder = styled.div`
  margin-top: 86px;
  max-width: 732px;
  width: calc(100vw * 0.65);
  border-top: 1px solid var(--agt-secondary-color-1);
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  background: var(--agt-background-color-1);
  .title {
    margin-bottom: 12px;
  }
  .content {
    display: flex;
    margin-bottom: 16px;
  }
  .content .detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .content .detail .left {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
  }
  .content .detail .left .companyName,
  .content .detail .left .date {
    color: var(--agt-secondary-color-1);
  }
  .content .detail .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .content .detail .right .createAt {
    color: var(--agt-secondary-color-1);
  }
  .content .detail .right .createAt .link {
    padding-left: 14px;
    color: var(--agt-primary-color-1);
  }
  .summary {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .summary .total {
    color: var(--agt-primary-color-1);
  }
`

const WebTourViewOrder = (props: WebTourType) => {
  const { visible, setVisible } = props
  const totalPage = 2
  const layout = [
    {
      top: '86px',
      right: '-204px',
      width: '191px',
      arrow: 'left',
    },
    {
      top: '164px',
      right: '-247px',
      width: '233px',
      arrow: 'left',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [notePosition, setNotePosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)
    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('opportunity-id__note', setNotePosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
  }, [setStep, setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <b>จัดการคำสั่งซื้อ</b>
          <small>
            คลิกเพื่อดูรายละเอียดและดำเนินการต่อ
            <br />
            สำหรับคำสั่งซื้อที่เลือก
          </small>
        </div>
      )
    } else if (step === 1) {
      return (
        <div className="top">
          <b>จัดการคำสั่งซื้อ</b>
          <small>ตัวอย่างรายการคำสั่งซื้อ</small>
        </div>
      )
    } else return null
  }, [step])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          <small>
            {step}/{totalPage}
          </small>
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === 1 || step === 2) {
      return (
        <ViewOrder>
          <b className="title">รายการคำสั่งซื้อ</b>
          <div className="content">
            <Avatar
              avatar="https://api-dev.agent-tools.tqld.xyz/logo/avatar_insurance.png"
              width="48px"
              height="48px"
              type="circle"
            />
            <div className="detail">
              <div className="left">
                <div>สะสมทรัพย์4 (15/8) มีเงินจ่ายคืนพิเศษ</div>
                <div className="companyName">เมืองไทยประกันชีวิต</div>
                <small>รหัสการขาย 123456</small>
                <small className="date">วันที่แจ้งการแนะนำ 04/08/2563</small>
              </div>
              <div className="right">
                <small>กำลังพิจารณา</small>
                <b>฿ 48,000</b>
                <small className="createAt">
                  ข้อมูลเมื่อ 07/11/2563
                  <u className="link">รายละเอียดคำสั่งซื้อ</u>
                </small>
              </div>
            </div>
          </div>
          <div className="summary">
            <div>ค่าเบี้ยประกันรวม</div>
            <b className="total">฿ 48,000</b>
          </div>
        </ViewOrder>
      )
    }
    return null
  }, [step])

  const StyledPosition = () => {
    if (step === 1 || step === 2) {
      return notePosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default WebTourViewOrder
