import React, { useState, useEffect, useMemo } from 'react'
import { Link } from 'routes'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import BannerImage from 'assets/images/register_header_banner.jpg'
import {
  HeaderTopic,
  ImageContainer,
  QnAAnswer,
  QnAContainer,
  QnAQuestion,
  RegisterButton,
  StickyHeader,
  Image,
  Arrow,
} from './index.styles'

export const Header = () => {
  const [isSticky, setIsSticky] = useState<boolean>(false)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  const link: string = useMemo(() => {
    if (
      ['ปฏิเสธชั่วคราว (1 ปี)', 'ปฏิเสธถาวร (Blacklist)', 'เก็บประวัติการสมัคร'].includes(
        currentAgent?.agentStatus || '',
      )
    ) {
      return 'form'
    } else if (currentAgent?.recruitsStatus && currentAgent?.recruitsStatus?.length > 0) {
      return 'download'
    } else {
      return 'form'
    }
    // eslint-disable-next-line
  }, [currentAgent, currentAgent?.agentStatus, currentAgent?.recruitsStatus])

  useEffect(() => {
    function tracking() {
      setIsSticky(window.scrollY > 330)
    }
    window.addEventListener('scroll', tracking)
    return () => window.removeEventListener('scroll', tracking)
  }, [])

  return (
    <>
      <ImageContainer>
        <Image src={BannerImage} />
        <HeaderTopic>สมัครเป็นตัวแทนกับ TQM</HeaderTopic>
        <Link to={currentAgent ? `/profile/recruitment/${link}` : '/login'} className="ghost">
          <RegisterButton>สมัครเป็นตัวแทน</RegisterButton>
        </Link>
      </ImageContainer>
      <StickyHeader className={isSticky ? 'is-sticky' : ''}>
        <Link to={currentAgent ? `/profile/recruitment/${link}` : '/login'} className="ghost">
          <RegisterButton>สมัครเป็นตัวแทน</RegisterButton>
        </Link>
      </StickyHeader>
    </>
  )
}

export const QnARenderer = (props) => {
  const [activated, setActive] = useState<boolean>(false)
  const toggle = () => setActive(!activated)
  return (
    <QnAContainer>
      <QnAQuestion onClick={() => toggle()}>
        {props.question}
        <Arrow activated={activated} icon={faChevronUp} color="var(--agt-primary-color-1)" />
      </QnAQuestion>
      {activated && <QnAAnswer>{props.answer}</QnAAnswer>}
    </QnAContainer>
  )
}
