import React, { FC, FormEvent, useState, useEffect, useMemo, Fragment } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'routes'
import { ErrorMessage, Form, Field, useField, useFormikContext } from 'formik'
import Select, { StylesConfig } from 'react-select'
import Dropdown, { DropdownList } from 'components/Dropdown'
import StarRating from 'components/StarRating'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'

import { validator } from 'pages/tasks/utils'
import { isEmpty, isUndefined } from 'lodash'
import { useDispatch } from 'react-redux'
import { getTasksOptions } from 'store/taskSlice'
import { TaskType, OpportunityType } from 'types/TaskType'
import Icon from 'components/Icon'
import Avatar from 'components/Avatar'
import { currency, dateFormat } from 'utils/helper'
import Modal from './CustomModal'

type TaskTypeById = {
  id: number | null
  name: string
  dueDate: string | null
  priorityLevel: number | null
  contactId: number | null
  status: string | null
  description: string
  taskDocuments: File[]
  contactName?: string
  contactPhoneNumber?: string
  contactEmail?: string
  opportunity?: OpportunityType | null
}
type TaskPageType = {
  handleSubmit: ((event: FormEvent<HTMLFormElement>) => void) | undefined
  taskDocuments?: File[]
  dueDate?: string
  isSubmitting?: boolean
  values?: TaskType | TaskTypeById
}
type OptionsType = {
  value: number
  label: string
}
type ContactComponentType = {
  name: string
  contacts: Array<OptionsType>
  contactId: string | null
}
type OptionsComponentType = {
  name: string
  options: Array<OptionsType>
}
type useParamsType = {
  contactId?: string
}
type StatusOrdersType = { status: string }

const mockUpData = [
  {
    id: 1,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'รัฐชพงษ์ ชมสารวิวัฒน์',
    updatedAt: new Date().toISOString(),
    orders: null,
  },
  {
    id: 2,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'รัฐชพงษ์ ชมสารวิวัฒน์',
    updatedAt: new Date().toISOString(),
    orders: null,
  },
  {
    id: 3,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'เช เกบารา',
    updatedAt: new Date().toISOString(),
    orders: [
      {
        name: 'สมาร์ท โพรเทคชั่น 99/20',
        orderNo: '183081d0',
        premium: '3094.0',
        status: 'รอแจ้งงาน',
      },
    ],
  },
  {
    id: 4,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'เหมาเจอตุง',
    updatedAt: new Date().toISOString(),
    orders: null,
  },
  {
    id: 5,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'รัฐชพงษ์ ชมสารวิวัฒน์',
    updatedAt: new Date().toISOString(),
    orders: [
      {
        name: 'สมาร์ท โพรเทคชั่น 99/20',
        orderNo: '183081d0',
        premium: '3094.0',
        status: 'รอแจ้งงาน',
      },
      {
        name: 'สมาร์ท โพรเทคชั่น 99/20',
        orderNo: '183081d0',
        premium: '3094.0',
        status: 'รอแจ้งงาน',
      },
    ],
  },
  {
    id: 6,
    name: 'โอกาสในการขาย',
    contactId: '58',
    contactName: 'รัฐชพงษ์ ชมสารวิวัฒน์',
    updatedAt: new Date().toISOString(),
    orders: [
      {
        name: 'สมาร์ท โพรเทคชั่น 99/20',
        orderNo: '183081d0',
        premium: '3094.0',
        status: 'บริษัทประกันอนุมัติ',
      },
      {
        name: 'สมาร์ท โพรเทคชั่น 99/20',
        orderNo: '183081d0',
        premium: '3094.0',
        status: 'พนักงานยกเลิก',
      },
    ],
  },
]
// const mockUpData = []
// const newOpportunity = {
//   id: 1,
//   name: 'โอกาสในการขาย',
//   contactId: '58',
//   contactName: 'รัฐชพงษ์ ชมสารวิวัฒน์',
//   updatedAt: new Date().toISOString(),
//   orders: [
//     {
//       name: 'สมาร์ท โพรเทคชั่น 99/20',
//       orderNo: '183081d0',
//       premium: '3094.0',
//       status: 'รอแจ้งงาน',
//     },
//     {
//       name: 'สมาร์ท โพรเทคชั่น 99/20',
//       orderNo: '183081d0',
//       premium: '3094.0',
//       status: 'รอแจ้งงาน',
//     },
//   ],
//   // orders: null,
// }

const StatusOrders = styled.small<StatusOrdersType>`
  color: ${(props) =>
    props.status === 'บริษัทประกันอนุมัติ' || props.status === 'สั่งซื้อสำเร็จ'
      ? 'var(--agt-success-color)'
      : props.status === 'พนักงานยกเลิก' || props.status === 'ยกเลิก'
      ? 'var(--agt-error-color)'
      : 'var(--agt-secondary-color-1)'};
`
const FormValue = styled(Form)`
  display: grid;
  justify-content: center;
  .document {
    color: var(--agt-primary-color-1);
    border: 1px solid var(--agt-primary-color-1);
    background: #ffffff;
  }
`
const FieldContainer = styled.div`
  width: 736px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
  margin-bottom: 24px;
  padding-bottom: 24px;
  .opportunityCard {
    width: 352px;
    b {
      color: var(--agt-primary-color-1);
    }
    button {
      font-size: 17px;
    }
    .title {
      background: var(--agt-background-color-3);
      height: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 14px;
      padding-right: 6px;
    }
    .title .addCard {
      width: 80px;
      height: 36px;
      padding-left: 18px;
      padding-right: 18px;
    }
    .body {
      height: 386px;
      border: 1px solid var(--agt-secondary-color-1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body .card {
      width: 244px;
      max-height: 278px;
      border: 1px solid var(--agt-secondary-color-1);
      border-radius: 4px;
      padding: 4px;
      padding-top: 8px;
    }
    .body .card .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      padding-left: 2px;
      margin-bottom: 6px;
    }
    .body .card .content {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .avatar {
      display: flex;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      label {
        color: var(--agt-primary-color-1);
        margin-left: 8px;
      }
    }
    .body .card .content .orders {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .orders .premium {
      display: flex;
      align-items: flex-start;
      padding: 12px;
      padding-bottom: 10px;
      b {
        margin-left: 8px;
        color: var(--agt-primary-color-1);
      }
    }
    .body .card .content .orders .order {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      padding: 0 4px;
    }
    .body .card .content .orders .order .status {
      display: flex;
      justify-content: space-between;
      color: var(--agt-secondary-color-1);
    }
    .body .card .content .updatedAt {
      margin: 6px;
      align-self: end;
      color: var(--agt-secondary-color-1);
    }
    .body .card .footer {
      width: 100%;
      height: 32px;
      color: var(--agt-primary-color-1);
      background: var(--agt-light-sky-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      small {
        margin-left: 8px;
      }
    }
  }
`
const SubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
const FieldValue = styled(Field)`
  width: 352px;
`
const DatePickerStyle = styled(DatePicker)`
  width: 352px;
`
const Error = styled.div`
  color: var(--agt-error-color);
  font-size: 13px;
`
const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  .file-name {
    justify-content: space-between;
    width: 340px;
  }
  .details {
    height: 142px;
    width: 736px;
    border-radius: 4px;
    padding: 16px;
  }
`
const PencilIcon = styled(Icon)`
  cursor: pointer;
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
`
const NoteIcon = styled(Icon)`
  cursor: pointer;
  width: 18px;
  height: 24px;
  svg {
    width: 18px;
    height: 24px;
  }
`
const BagIcon = styled(Icon)`
  width: 23px;
  height: 23px;
  svg {
    width: 23px;
    height: 23px;
  }
`
const TriggerDropdown = styled(Dropdown)`
  position: absolute;
  top: 12px;
  right: -30px;
  width: 60px;
  .agt-dropdown__content-wrapper {
    width: 160px;
    top: -17px;
    left: 41px;
    @media (max-width: 1180px) {
      right: 0;
      top: unset;
      left: unset;
    }
  }
  .agt-dropdown__button {
    background: unset;
    &:hover {
      background: unset;
    }
  }
`
const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 900px;
  padding-bottom: 14px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding-left: 24px;
    padding-right: 12px;
    background: var(--agt-background-color-3);
    b {
      color: var(--agt-primary-color-1);
    }
  }
  .content {
    padding: 12px;
    padding-bottom: 24px;
  }
  .content .frame {
    height: 570px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .content .frame .card {
    width: 270px;
    height: fit-content;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 8px;
    margin-right: 30px;
    margin-bottom: 4px;
    padding: 12px;
  }
  .content .frame .card:nth-of-type(3n) {
    margin-right: 0px;
  }
  .content .frame .card .select {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    small {
      color: var(--agt-primary-color-1);
    }
    .group {
      display: flex;
      align-items: center;
    }
    .group .word {
      margin-left: 8px;
      cursor: pointer;
    }
    .group .round {
      width: 16px;
      height: 16px;
      position: relative;
    }
    .group .round label {
      cursor: pointer;
      position: absolute;
      width: 16px;
      height: 16px;
      border: 1px solid var(--agt-secondary-color-2);
      border-radius: 4px;
    }
    .group .round label:after {
      content: '';
      border: 2px solid var(--agt-white-color);
      border-top: none;
      border-right: none;
      opacity: 0;
      position: absolute;
      top: 4px;
      left: 2px;
      transform: rotate(-45deg);
      width: 8px;
      height: 3px;
    }
    .group .round input[type='checkbox'] {
      display: none;
      visibility: hidden;
    }
    .group .round input[type='checkbox']:checked + label {
      background-color: var(--agt-primary-color-1);
      border: 1px solid var(--agt-primary-color-1);
    }
    .group .round input[type='checkbox']:checked + label:after {
      opacity: 1;
    }
  }
  .content .frame .card .object {
    width: 244px;
    max-height: 278px;
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 4px;
    padding: 4px;
    padding-top: 8px;
  }
  .content .frame .card .object .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    padding-left: 2px;
    margin-bottom: 6px;
  }
  .content .frame .card .object .content {
    display: flex;
    flex-direction: column;
    padding: unset;
  }
  .content .frame .card .object .content .avatar {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid var(--agt-secondary-color-1);
    label {
      color: var(--agt-primary-color-1);
      margin-left: 8px;
    }
  }
  .content .frame .card .object .content .orders {
    display: flex;
    flex-direction: column;
  }
  .content .frame .card .object .content .orders .premium {
    display: flex;
    align-items: flex-start;
    padding: 12px;
    padding-bottom: 10px;
    b {
      margin-left: 8px;
      color: var(--agt-primary-color-1);
    }
  }
  .content .frame .card .object .content .orders .order {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding: 0 4px;
  }
  .content .frame .card .object .content .orders .order .status {
    display: flex;
    justify-content: space-between;
    color: var(--agt-secondary-color-1);
  }
  .content .frame .card .object .content .updatedAt {
    margin: 6px;
    align-self: end;
    color: var(--agt-secondary-color-1);
  }
  .content .frame .card .object .footer {
    width: 100%;
    height: 32px;
    color: var(--agt-primary-color-1);
    background: var(--agt-light-sky-color);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    small {
      margin-left: 8px;
    }
  }
  .submit {
    align-self: center;
  }
  @media (max-width: 1024px) {
    width: 720px;
    .content .frame {
      justify-content: center;
    }
    .content .frame .card:nth-of-type(3n) {
      margin-right: 32px;
    }
  }
`

const Topic = styled.div``
const Selector = styled(Select)`
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const customSelectStyles: StylesConfig = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    position: 'absolute',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '352px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
  }),
}

const ContactComponent = ({ name, contacts, contactId }: ContactComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers

  const defaultSeletor = contacts.find(
    (select) => select.value === (contactId ? parseInt(contactId) : value),
  )
  return (
    <div>
      <Selector
        value={defaultSeletor}
        styles={customSelectStyles}
        onChange={(option: OptionsType) => {
          setValue(option == null ? null : option.value)
        }}
        options={contacts}
        isClearable
        placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
        isDisabled={contactId}
      />
    </div>
  )
}

const OptionsComponent = ({ name, options }: OptionsComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor}
      onChange={(option: OptionsType) => {
        const value = (option as OptionsType).value
        setValue(value)
      }}
      options={options}
      styles={customSelectStyles}
      placeholder="โปรดระบุ"
    />
  )
}

const SubMenu = ({ changeStateModal = () => {}, onDelete = () => {} }) => {
  return (
    <Fragment>
      <DropdownList onClick={() => changeStateModal()}>
        <div style={{ display: 'flex' }}>
          <PencilIcon name="pencil_icon" />
          <label style={{ marginLeft: 16 }}>แก้ไข</label>
        </div>
      </DropdownList>
      <DropdownList
        onClick={() => {
          onDelete()
        }}
      >
        <div style={{ display: 'flex' }}>
          <Icon name="bin" />
          <label style={{ marginLeft: 16 }}>ลบ</label>
        </div>
      </DropdownList>
    </Fragment>
  )
}

const OpportunityCard = (props) => {
  const {
    values,
    setFieldValue,
    setChangeStyle,
    setSelectCard,
    changeStyle = false,
    premium = 0,
    changeStateModal = () => {},
  } = props
  return (
    <div
      className="opportunityCard"
      style={{ position: 'absolute', left: 385, top: 55, display: 'none' }}
    >
      <div
        className="title"
        style={
          changeStyle
            ? {
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                paddingRight: 16,
                position: 'relative',
              }
            : {}
        }
      >
        <b>โอกาสในการขาย</b>
        {changeStyle ? (
          <TriggerDropdown
            content={
              <SubMenu
                changeStateModal={changeStateModal}
                onDelete={() => {
                  setFieldValue('opportunity', null)
                  setSelectCard({})
                  setChangeStyle(!changeStyle)
                }}
              />
            }
          >
            <PencilIcon name="pencil_icon" color="var(--agt-primary-color-1)" />
          </TriggerDropdown>
        ) : (
          <button
            className="secondary addCard"
            type="button"
            onClick={() => {
              changeStateModal()
            }}
            disabled={mockUpData.length === 0}
          >
            + เพิ่ม
          </button>
        )}
      </div>
      {changeStyle && values?.opportunity && (
        <div className="body">
          <div className="card">
            <div className="header">
              <div>{values.opportunity.name || ''}</div>
              <Icon name="dot_option" />
            </div>
            <div className="content">
              <div className="avatar">
                <Avatar type="circle" width="32px" height="32px" avatar="" />
                <label>{values.opportunity.contactName || ''}</label>
              </div>
              {values.opportunity.orders && values.opportunity.orders?.length > 0 && (
                <div className="orders">
                  <div className="premium">
                    <BagIcon name="bag" />
                    <b>
                      ฿&nbsp;
                      {currency(premium, 0, '')}
                    </b>
                  </div>
                  {values.opportunity.orders.map((order, index) => {
                    if (index < 2)
                      return (
                        <div className="order">
                          <small>{order.name}</small>
                          <div className="status">
                            <StatusOrders status={order.status}>{order.status}</StatusOrders>
                            <StatusOrders status={order.status}># {order.orderNo}</StatusOrders>
                          </div>
                        </div>
                      )
                    else return null
                  })}
                </div>
              )}
              <small className="updatedAt">
                วันที่อัปเดต {dateFormat(values.opportunity.updatedAt || new Date())}
              </small>
            </div>
            <div className="footer">
              <NoteIcon name="note_opps" color={'var(--agt-primary-color-1)'} />
              <small>ดูรายละเอียด</small>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const ListOpportunities = (props) => {
  const { setIsModal, setSelectCard, selectCard, setFieldValue, setChangeStyle } = props
  return (
    <ContentModal>
      <div className="title">
        <b>โอกาสในการขาย</b>
        <div
          onClick={() => {
            setIsModal(false)
            if (selectCard?.id) {
            } else setSelectCard({})
          }}
          style={{ cursor: 'pointer' }}
        >
          <Icon name="close_button" />
        </div>
      </div>
      <div className="content">
        <div className="frame">
          {mockUpData.map((data, index) => {
            let price = 0
            if (data.orders) {
              data.orders.forEach((d, index) => {
                price += parseFloat(d.premium)
              })
            }
            return (
              <div
                className="card"
                style={
                  selectCard?.id === data.id
                    ? { borderColor: 'var(--agt-primary-color-1)', borderWidth: 2 }
                    : {}
                }
              >
                <div className="select">
                  <div className="group">
                    <div className="round">
                      <input
                        id={`checkbox-${index}`}
                        type="checkbox"
                        onClick={() => {
                          setSelectCard(data)
                        }}
                        checked={selectCard?.id && selectCard?.id === data.id}
                      />
                      <label htmlFor={`checkbox-${index}`} />
                    </div>
                    <label htmlFor={`checkbox-${index}`} className="word">
                      เลือก
                    </label>
                  </div>
                  <small>เสนอวิธีการและรอการตัดสินใจ</small>
                </div>
                <div className="object">
                  <div className="header">
                    <div>{data.name}</div>
                    <Icon name="dot_option" />
                  </div>
                  <div className="content">
                    <div className="avatar">
                      <Avatar type="circle" width="32px" height="32px" avatar="" />
                      <label>{data.contactName}</label>
                    </div>
                    {data.orders && data.orders?.length > 0 && (
                      <div className="orders">
                        <div className="premium">
                          <BagIcon name="bag" />
                          <b>
                            ฿&nbsp;
                            {currency(price, 0, '')}
                          </b>
                        </div>
                        {data.orders.map((order, index) => {
                          if (index < 2)
                            return (
                              <div className="order">
                                <small>{order.name}</small>
                                <div className="status">
                                  <StatusOrders status={order.status}>{order.status}</StatusOrders>
                                  <StatusOrders status={order.status}>
                                    # {order.orderNo}
                                  </StatusOrders>
                                </div>
                              </div>
                            )
                          else return null
                        })}
                      </div>
                    )}
                    <small className="updatedAt">วันที่อัปเดต {dateFormat(data.updatedAt)}</small>
                  </div>
                  <div className="footer">
                    <NoteIcon name="note_opps" color={'var(--agt-primary-color-1)'} />
                    <small>ดูรายละเอียด</small>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <button
        className="primary submit"
        type="button"
        disabled={isEmpty(selectCard)}
        onClick={() => {
          setFieldValue('opportunity', selectCard)
          setChangeStyle(true)
          setIsModal(false)
        }}
      >
        เลือก
      </button>
    </ContentModal>
  )
}

const Today = new Date()

const TaskForm: FC<TaskPageType> = (props) => {
  let { contactId } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const { values = { opportunity: null } } = props
  const [contacts, setContacts] = useState<Array<OptionsType>>([])
  const [selectCard, setSelectCard] = useState<{ id?: string | number }>({})
  const [changeStyle, setChangeStyle] = useState<boolean>(false)
  const [isModal, setIsModal] = useState<boolean>(false)
  const { setFieldValue } = useFormikContext()
  const [metaPriorityLevel] = useField('priorityLevel')
  const [metaDueDate] = useField('dueDate')
  const priorityLevel = metaPriorityLevel.value
  useEffect(() => {
    if (!isUndefined(contactId)) {
      setFieldValue('contactId', contactId)
    }
  }, [contactId, setFieldValue])
  useEffect(() => {
    dispatch(getTasksOptions(setContacts))
  }, [dispatch, setContacts])
  const onDateChange = (fieldname: string) => (value: Date) => {
    setFieldValue(fieldname, value)
  }
  const premium = useMemo(() => {
    if (values.opportunity?.orders) {
      let price = 0
      values.opportunity.orders.forEach((order, index) => {
        price += parseFloat(order.premium)
      })
      return price
    } else return 0
  }, [values])
  return (
    <FormValue onSubmit={props.handleSubmit}>
      <Modal isOpen={isModal}>
        <ListOpportunities
          setIsModal={setIsModal}
          setSelectCard={setSelectCard}
          selectCard={selectCard}
          setFieldValue={setFieldValue}
          setChangeStyle={setChangeStyle}
        />
      </Modal>
      <FieldContainer>
        <FieldGroup style={{ position: 'relative', paddingTop: 32 }}>
          <Topic>ชื่องาน *</Topic>
          <FieldValue
            id="task-id__name"
            name="name"
            type="string"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุชื่องาน')}
          />
          <ErrorMessage component={Error} name="name" />
          <OpportunityCard
            values={values}
            changeStyle={changeStyle}
            setChangeStyle={setChangeStyle}
            setFieldValue={setFieldValue}
            setSelectCard={setSelectCard}
            premium={premium}
            changeStateModal={() => setIsModal(!isModal)}
            closeModal={() => setIsModal(false)}
          />
        </FieldGroup>
        <FieldGroup>
          <Topic>วันครบกำหนด *</Topic>
          <DatePickerStyle
            value={metaDueDate.value ? metaDueDate.value : ''}
            onChange={onDateChange('dueDate')}
            minDate={Today}
          />
          <FieldValue
            hidden={true}
            name="dueDate"
            type="string"
            validate={validator('กรุณาระบุวันครบกำหนด')}
          />
          <ErrorMessage component={Error} name="dueDate" />
        </FieldGroup>
        {changeStyle && (
          <FieldGroup>
            <Topic>แจ้งเตือนล่วงหน้า</Topic>
            <OptionsComponent
              name="reminderTime"
              options={[
                { label: 'เช้า', value: 0 },
                { label: 'สาย', value: 1 },
                { label: 'บ่าย', value: 2 },
                { label: 'เย็น', value: 3 },
              ]}
            />
          </FieldGroup>
        )}
        <FieldGroup>
          <div style={{ display: 'flex' }}>
            <Topic style={{ paddingRight: '32px', paddingTop: '7px' }}>ระดับความสำคัญ</Topic>
            <StarRating
              star={3}
              setValue={setFieldValue}
              valueName="priorityLevel"
              value={priorityLevel}
            />
          </div>
        </FieldGroup>
        <FieldGroup>
          <Topic id="task-id__contact">ลูกค้าที่เกี่ยวข้อง</Topic>
          <ContactComponent
            name="contactId"
            contacts={contacts}
            contactId={contactId ? contactId : null}
          />
        </FieldGroup>
        <FieldGroup>
          <Topic>รายละเอียด</Topic>
          <FieldValue
            className="details"
            name="description"
            type="string"
            component="textarea"
            placeholder="โปรดระบุ"
          />
        </FieldGroup>
      </FieldContainer>
      <SubmitContainer>
        <button type="submit" disabled={props.isSubmitting}>
          {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
        </button>
      </SubmitContainer>
    </FormValue>
  )
}

export default TaskForm
