import styled from '@emotion/styled'
import Icon from 'components/Icon'
import Select from 'react-select'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const BranchContainer = styled.div`
  display: flex;
  margin-top: 26px;
  flex-direction: column;
  /* justify-content: center; */
  width: 100%;
  padding: 0 96px;
  height: 100%;
  margin-bottom: 40px;
`

export const Branch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 60px;
  .Header {
    font-size: 24px;
    font-weight: bold;
    color: var(--agt-primary-color-1);
    align-self: center;
    margin-right: 24px;
  }
  .Searching {
    width: 54px;
    height: 48px;
    background: var(--agt-background-color-2);
    border: 1px solid var(--agt-secondary-color-1);
    border-radius: 0px 4px 4px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const OfficeBranch = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  color: var(--agt-primary-color-1);
  height: 500px;
  overflow: auto;
  @media (max-height: 1024px) {
    height: 700px;
  }
`

export const BranchContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 324px;
  height: fit-content;
  color: var(--agt-primary-color-1);
  margin-bottom: 30px;
`

export const BranchName = styled.div`
  font-weight: bold;
  font-size: 17px;
`

export const BranchDetail = styled.div`
  margin-left: 20px;
  font-size: 13px;
  color: var(--agt-primary-text-color);
  .address {
    height: 55px;
  }
`

export const Input = styled.input`
  width: 333px;
  border-radius: 0px 0px 0px 0px !important;
`

export const IconSearch = styled(Icon)`
  width: 25px;
  height: 25px;
  color: var(--agt-primary-color-1);
`

export const IconMapPin = styled(Icon)`
  width: 14px;
  height: 24px;
  margin-right: 7px;
  color: var(--agt-primary-color-1);
`

export const IconStyle = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-right: 7px;
  color: var(--agt-primary-color-1);
`

export const Selector = styled(Select)``

export const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px 0px 0px 4px',
    width: '153px',
    background: 'var(--agt-background-color-2)',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    paddingLeft: '6px',
    fontSize: '17px',
    fontWeight: '400',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '172px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
    fontSize: '17px',
    fontWeight: '400',
    color: ' var(--agt-primary-text-color)',
  }),
}
