import React, { Fragment, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Select from 'react-select'
import { Formik, ErrorMessage, Form, Field, useField, useFormikContext } from 'formik'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import Api from 'api'
import Gender from 'components/Inputs/Gender'
import subDays from 'date-fns/subDays'
import { has, get } from 'lodash'
import { setTimeout } from 'timers'

type optionsType = {
  value: string
  label: string
}

type OptionPropsType = {
  name: string
  options: Array<optionsType>
}

const Selector = styled(Select)`
  background: var(--agt-white-color);
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const Container = styled.div`
  border-top: 1px solid var(--agt-secondary-color-1);
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  .RowLayout {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .RowLayout .ColumnLayout {
    width: 272px;
  }
  .SubmitLayout {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  .Gender {
    width: 360px;
  }
`

const prefixSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}

export const PrefixComponent = ({ name, options }: OptionPropsType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor}
      onChange={(option: optionsType) => {
        const value = (option as optionsType).value
        setValue(value)
      }}
      options={options}
      styles={prefixSelectStyles}
      placeholder="โปรดระบุ"
    />
  )
}

const Error = styled.small`
  color: var(--agt-error-color);
`

const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (parseFloat(value) === 0) {
    error = message || 'กรุณาระบุ'
  }
  return error
}

const ContactForm = (props) => {
  const { setFieldValue } = useFormikContext()
  const { values } = props
  const { birthdate } = values
  const [prefixNameOptions, setPrefixNameOptions] = useState<Array<optionsType>>([])
  const today = new Date()
  const yesterday = subDays(today, 1)

  const getOption = async () => {
    try {
      const response = await Api.get('/contacts/options')
      setPrefixNameOptions(response.data.data.prefixNameOptions)
    } catch (err) {
      if (err && err.response) {
        return err.response
      }
      throw err
    }
  }

  useEffect(() => {
    getOption()
  }, [])

  return (
    <Form onSubmit={props.handleSubmit}>
      <div className="RowLayout">
        <div className="ColumnLayout" style={{ width: '160px' }}>
          <div className="">คำนำหน้าชื่อ</div>
          <PrefixComponent name="prefixName" options={prefixNameOptions} />
          <Field hidden={true} name="prefixName" validate={validator('กรุณาระบุคำนำหน้าชื่อ')} />
          <ErrorMessage component={Error} name="prefixName" />
        </div>
        <div className="ColumnLayout">
          <div className="">ชื่อจริง</div>
          <Field
            name="firstName"
            type="string"
            placeholder="โปรดระบุ"
            style={{ width: '100%' }}
            validate={validator('กรุณาระบุชื่อจริง')}
          />
          <ErrorMessage component={Error} name="firstName" />
        </div>
        <div className="ColumnLayout">
          <div className="">นามสกุล</div>
          <Field
            name="lastName"
            type="string"
            placeholder="โปรดระบุ"
            style={{ width: '100%' }}
            validate={validator('กรุณาระบุนามสกุล')}
          />
          <ErrorMessage component={Error} name="lastName" />
        </div>
      </div>
      <div className="RowLayout">
        <div className="ColumnLayout">
          <div className="">เพศ</div>
          <Gender name="gender" className="Gender" />
          <Field hidden={true} name="gender" validate={validator('กรุณาระบุเพศ')} />
          <ErrorMessage component={Error} name="gender" />
        </div>
        <div className="ColumnLayout" style={{ width: '360px' }}>
          <div className="">วันเกิด</div>
          <DatePicker
            value={birthdate || ''}
            maxDate={yesterday}
            showSelector
            onChange={(value) => setFieldValue('birthdate', value)}
          />
          <Field hidden={true} name="birthdate" validate={validator('กรุณาระบุวันเกิด')} />
          <ErrorMessage component={Error} name="birthdate" />
        </div>
      </div>
      <div className="SubmitLayout">
        <button className="secondary" disabled={!props.dirty || props.isSubmitting}>
          บันทึก
        </button>
      </div>
    </Form>
  )
}

const ContactCard = (props) => {
  const {
    contact: initialValues = {},
    isForceOpen = false,
    onClickContact = false,
    afterFillContact = () => {},
  } = props

  const updateContact = async (id: number, values: {}) => {
    try {
      await Api.put(`/contacts/${id}`, values, { isFormData: true })
    } catch (err) {
      if (err && err.response) {
        return err.response
      }
      throw err
    }
  }

  return (
    <Fragment>
      {isForceOpen && onClickContact && (
        <Container>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={(values) => {
              let id: number = has(values, 'id') ? get(values, 'id') : 0
              let modifiedValues = {
                ...values,
                birthdate: has(values, 'birthdate') ? get(values, 'birthdate').toString() : null,
              }
              updateContact(id, modifiedValues)
              setTimeout(() => {
                afterFillContact(modifiedValues)
              }, 3000)
            }}
          >
            {(props) => <ContactForm {...props} />}
          </Formik>
        </Container>
      )}
    </Fragment>
  )
}
export default ContactCard
