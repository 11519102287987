import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { get } from 'lodash'
import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'routes'
import { getActOptions, getOrder, searchAct } from 'store/actSlice'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'
import ActAside from './ActAside'
import Detail from './Detail'
import { Container, Content, Form, Header } from './DetailAct.styles'

const DetailAct: FC = () => {
  let { id, orderNo } = useParams<{ id: string; orderNo: string }>()
  const dispatch = useDispatch()
  const { order, options, prbInsurance } = useSelector((state: RootState) => state.act)
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    // { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    // { path: `/opportunities/${id}/catalog/act`, label: 'ประกัน พ.ร.บ.' },
    { label: 'ข้อมูลผู้เอาประกันภัย' },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id && order) {
      dispatch(
        getActOptions(id, {
          year: order?.vehicle?.year,
          makeCode: order?.vehicle?.makeCode,
          familyCode: order?.vehicle?.familyCode,
        }),
      )
      dispatch(
        searchAct(id, {
          carUsage: order?.order?.prbInsurance?.productDetails?.prbCode || '',
          companyCode: order?.order?.prbInsurance?.productDetails?.companyCode || '',
        }),
      )
    }
  }, [dispatch, id, order])

  useEffect(() => {
    if (id && orderNo) dispatch(getOrder(id, orderNo))
  }, [dispatch, id, orderNo])

  return (
    <Container>
      <Header>ข้อมูลผู้เอาประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Content>
        <ActAside
          prbCarType={order?.order?.prbInsurance?.productDetails?.prbCarType || ''}
          prbCode={order?.order?.prbInsurance?.productDetails?.prbCode || ''}
          companyCode={order?.order?.companyName || ''}
          options={options}
          prbInsurance={prbInsurance}
        />
        <Form>
          <b>กรอกข้อมูลผู้เอาประกันภัย</b>
          <Detail data={order} options={options} />
        </Form>
      </Content>
    </Container>
  )
}

export default DetailAct
