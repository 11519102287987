import React, { FC, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { useParams, useHistory } from 'routes'
import { Field, Form, Formik, getIn } from 'formik'
import Select from '../share/Select'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getPersonalInformation, getOptions, createPersonalInformation } from 'store/planningSlice'
import { getContactById } from 'store/contactSlice'
import { getAge } from 'utils/helper'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { go2Top } from 'utils/helper'
import Icon from 'components/Icon'
import Api from 'api'
import HistoryType from 'types/HistoryType'

type useParamsType = { id: string }

const Container = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  padding-left: 9.52%;
  .icon {
    position: absolute;
    top: 10px;
    right: 20%;
    cursor: pointer;
    @media (max-width: 1024px) {
      right: 17%;
    }
  }
  .suggest {
    padding: 13px 10px;
    border-radius: 5px;
    background: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    display: flex;
    justify-content: center;
    align-items: center;
    .label {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      div {
        line-height: 16px;
      }
    }
    .app-icon {
      div {
        display: flex;
        align-items: center;
        margin-right: 4px;
        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    @media (max-width: 1024px) {
      padding: 13px 5px;
    }
  }
`
const Text = styled.div`
  font-weight: bold;
  margin-bottom: 24px;
`
const SubmitButton = styled.button`
  width: 122px;
  height: 48px;
  align-self: center;
`
const GroupField = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`
const CustomSelect = styled(Select)`
  > div:nth-of-type(1) {
    width: 35%;
    @media only screen and (max-width: 1024px) {
      width: 45%;
    }
  }
  > div:nth-of-type(2) {
    width: 65%;
    @media only screen and (max-width: 1024px) {
      justify-content: flex-start;
      > div:nth-of-type(1) {
        width: 49%;
      }
    }
    > div:nth-of-type(2) {
      width: 42.5%;
    }
  }
  margin-bottom: 0px;
`
const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
const Caption = styled.div`
  display: flex;
  /* align-items: center; */
  color: #393d43;
  width: 41%;
`
const InputAndErrorContainer = styled.div`
  width: 29%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`
const Unit = styled.div`
  display: flex;
  /* align-items: center; */
  color: #393d43;
  padding-left: 24px;
  width: 30%;
`
const StylesError = styled.div`
  position: absolute;
  top: 48px;
  font-size: 13px;
  color: var(--agt-error-color);
`
const StylesNumberFormat = styled(NumberFormat)`
  padding-left: 16px;
  background: #ffffff;
  border: '1px solid #a0a8bc !important';
  box-sizing: border-box;
  border-radius: 4px;
  height: 48px;
  width: 214px;
  position: relative;
  flex-direction: column;
  color: #393d43;
  background: ${(props) => (props.disabled ? '#EFF1F5' : '')};
  ::placeholder {
    color: #acb1bf;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  }
  return error
}
const validatorCurrency = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (value < 0) {
    error = message || 'กรุณาระบุ'
  } else {
    if (Number((value + '').replace(/[^0-9.-]+/g, '')) > 10000000) {
      error = message || 'กรุณาระบุ'
    }
  }
  return error
}
const validatorPercent = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (value < 0 || value > 100) {
    error = message || 'กรุณาระบุ'
  } else {
    if (Number((value + '').replace(/[^0-9.-]+/g, '')) > 100) {
      error = message || 'กรุณาระบุ'
    }
  }
  return error
}
function getStyles(errors, fieldName) {
  if (getIn(errors, fieldName)) {
    return {
      border: '1px solid #f45a49',
    }
  }
}

const CustomCurrencyComponent = ({ field, form: { touched, errors }, ...props }) => {
  const { min = 0, max = 10000000 } = props
  return (
    <MainLayout style={touched[field.name] && errors[field.name] ? { marginBottom: 24 } : {}}>
      <Caption>{props.caption}</Caption>
      <InputAndErrorContainer>
        <StylesNumberFormat
          isNumericString={true}
          thousandSeparator={true}
          placeholder="โปรดระบุ"
          {...field}
          {...props}
          style={touched[field.name] && errors[field.name] && getStyles(errors, field.name)}
          decimalScale={2}
          isAllowed={(values) => {
            const { floatValue } = values
            if (!floatValue) {
              return true
            } else {
              return floatValue >= min && floatValue <= max
            }
          }}
        />
        {touched[field.name] && errors[field.name] && (
          <StylesError>{errors[field.name]}</StylesError>
        )}
      </InputAndErrorContainer>
      <Unit>{props.unit}</Unit>
    </MainLayout>
  )
}

const FinalcialInfo = (props) => {
  const { options = [], values, id } = props
  const [isSuggest, setIsSuggest] = useState(false)
  const [isEmployee] = useState([
    { value: true, label: 'เป็น' },
    { value: false, label: 'ไม่เป็น' },
  ])

  useEffect(() => {
    if (
      props.dirty &&
      values.socialInsuranceInsuredType &&
      values.incomePerMonth &&
      values.expensePerMonth
    ) {
      getCalculateTax({
        socialInsuranceInsuredType: values.socialInsuranceInsuredType,
        incomePerMonth: values.incomePerMonth,
        expensePerMonth: values.expensePerMonth,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.socialInsuranceInsuredType, values.incomePerMonth, values.expensePerMonth])

  const getCalculateTax = async (value) => {
    try {
      const response = await Api.get(`/contacts/${id}/plans/calculate_tax_rate`, {
        ...value,
        incomePerMonth: parseFloat(value.incomePerMonth.split(',').join('')),
        expensePerMonth: parseFloat(value.expensePerMonth.split(',').join('')),
      })
      props.setFieldValue('taxRate', response.data.data.taxRate)
    } catch (err) {
      if (err && err.response) {
        alert(err.response.data.message)
        return err.response
      }
      throw err
    }
  }

  return (
    <Container onSubmit={props.handleSubmit}>
      <Text>ข้อมูลทางการเงิน</Text>
      <GroupField>
        <Field
          name="incomePerMonth"
          caption="รายได้เฉลี่ยต่อเดือน"
          unit="บาท"
          validate={validatorCurrency('กรุณาระบุรายได้เฉลี่ยต่อเดือน')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <GroupField>
        <Field
          name="expensePerMonth"
          caption="รายจ่ายส่วนตัวเฉลี่ยต่อเดือน"
          unit="บาท (ไม่รวมส่วนที่เก็บออม หรือ ใช้ร่วมกับ/มอบให้กับครอบครัว)"
          validate={validatorCurrency('กรุณาระบุรายจ่ายส่วนตัวเฉลี่ยต่อเดือน')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <GroupField style={{ flexDirection: 'row', position: 'relative' }}>
        <Field
          name="incomeGrowthRate"
          caption="อัตราการเพิ่มขึ้นของรายได้ต่อปี"
          unit="%"
          validate={validatorPercent('กรุณาระบุอัตราการเพิ่มขึ้นของรายได้ต่อปี')}
          component={CustomCurrencyComponent}
        />
        <div className="icon" onClick={() => setIsSuggest(!isSuggest)}>
          <Icon name="I" color={'var(--agt-primary-color-1)'} />
        </div>
        <div
          className="suggest"
          style={
            isSuggest
              ? {
                  position: 'absolute',
                  top: '48px',
                  right: '0px',
                  width: 'fit-content',
                  display: 'block',
                }
              : { display: 'none', width: '0px' }
          }
        >
          <div className="label">
            <div>3% มาจากค่าเฉลี่ยอัตราการเติบโต</div>
            <div>ของรายได้ประชาชาติ (GDP Growth</div>
            <div>rate) ของประเทศไทย</div>
          </div>
        </div>
      </GroupField>
      <GroupField>
        <Field
          name="isEmployee"
          caption="เป็นพนักงานประจำหรือไม่"
          placeholder="เลือก"
          options={isEmployee}
          component={CustomSelect}
          onChange={(val) => {
            props.setFieldValue('isEmployee', val.value)
            if (val.value) {
              props.setFieldValue('socialInsuranceInsuredType', 'ม.33')
              props.setFieldValue(
                'socialInsuranceNumberOfMonthContribution',
                (parseFloat(values.socialInsuranceNumberOfMonthContribution) / 12).toString(),
              )
            } else {
              props.setFieldValue('socialInsuranceInsuredType', 'ม.39')
              props.setFieldValue(
                'socialInsuranceNumberOfMonthContribution',
                (parseFloat(values.socialInsuranceNumberOfMonthContribution) * 12).toString(),
              )
            }
          }}
        />
      </GroupField>
      <GroupField>
        <Field
          name="taxRate"
          caption="อัตราภาษีสุทธิโดยประมาณ"
          unit="%"
          component={CustomCurrencyComponent}
          disabled
        />
      </GroupField>
      <Text>ข้อมูลสิทธิประโยชน์จากกองทุนประกันสังคม</Text>
      <GroupField>
        <Field
          name="socialInsuranceInsuredType"
          placeholder="เลือก"
          options={
            props.values.isEmployee
              ? options.socialInsuranceInsuredTypeOptions
              : options.socialInsuranceInsuredTypeOptions.filter(({ value }) => value !== 'ม.33')
          }
          caption="ประเภทผู้ประกันตน"
          validate={validator('กรุณาระบุประเภทผู้ประกันตน')}
          component={CustomSelect}
          isDisabled={props.values.isEmployee}
        />
      </GroupField>
      <GroupField>
        <Field
          name="socialInsuranceNumberOfMonthContribution"
          caption={
            props.values.isEmployee ? 'จำนวนปีตั้งแต่เริ่มทำงาน' : 'ผู้ประกันตนจ่ายเงินสมทบมาแล้ว'
          }
          unit={props.values.isEmployee ? 'ปี (จนถึงปัจจุบัน)' : 'เดือน (จนถึงปัจจุบัน)'}
          validate={
            props.values.isEmployee
              ? validatorCurrency('กรุณาระบุจำนวนปี')
              : validatorCurrency('กรุณาระบุจำนวนเดือน')
          }
          component={CustomCurrencyComponent}
          onKeyDown={(e) => /[+-.]$/.test(e.key) && e.preventDefault()}
        />
      </GroupField>
      <Text>ข้อมูลสิทธิประโยชน์จากกองทุนสำรองเลี้ยงชีพ</Text>
      <GroupField>
        <Field
          name="employeeSavingRate"
          placeholder="โปรดระบุ"
          caption="อัตราเงินสะสมของลูกจ้าง"
          unit="%"
          validate={validatorPercent('กรุณาระบุอัตราเงินสะสมของลูกจ้าง')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <Text>ข้อมูลอื่นๆ</Text>
      <GroupField>
        <Field
          name="inflationRate"
          placeholder="2"
          caption="อัตราเงินเฟ้อทั่วไป"
          unit="%"
          validate={validatorPercent('กรุณาระบุอัตราเงินเฟ้อทั่วไป')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <GroupField>
        <Field
          name="healthcareInflationRate"
          placeholder="3"
          caption="อัตราเงินเฟ้อด้านการรักษาพยาบาล"
          unit="%"
          validate={validatorPercent('กรุณาระบุอัตราเงินเฟ้อด้านการรักษาพยาบาล')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <GroupField>
        <Field
          name="educationInflationRate"
          placeholder="5"
          caption="อัตราเงินเฟ้อด้านการศึกษา"
          unit="%"
          validate={validatorPercent('กรุณาระบุอัตราเงินเฟ้อด้านการศึกษา')}
          component={CustomCurrencyComponent}
        />
      </GroupField>
      <GroupField>
        <Field
          name="investmentRisk"
          placeholder="เลือก"
          options={options.investmentRiskOptions}
          caption="ระดับความเสี่ยงในการลงทุนที่รับได้"
          validate={validator('กรุณาระบุระดับความเสี่ยงในการลงทุนที่รับได้')}
          component={CustomSelect}
        />
      </GroupField>
      <SubmitButton type="submit" disabled={!props.dirty || !props.isValid}>
        บันทึก
      </SubmitButton>
    </Container>
  )
}

const Pages: FC = (props) => {
  const dispatch = useDispatch()
  const planData = useSelector((state: RootState) => state.plans.plan)
  const options = useSelector((state: RootState) => state.plans.options)

  const contactData = useSelector((state: RootState) => state.contacts.contact)
  const { id } = useParams<useParamsType>()
  const history: HistoryType = useHistory()
  const path: string = `/contacts/${id}/planning`
  const link = { pathname: path, state: 'planning' }

  const initialValues = useMemo(() => {
    return {
      incomePerMonth: '',
      expensePerMonth: '',
      incomeGrowthRate: '3',
      taxRate: '0',
      isEmployee: true,
      socialInsuranceInsuredType: 'ม.33',
      socialInsuranceNumberOfMonthContribution: '0',
      employeeSavingRate: '0',
      inflationRate: '2',
      healthcareInflationRate: '3',
      educationInflationRate: '5',
      investmentRisk: '0.05',
      ...planData,
      gender: contactData?.gender,
      age: getAge(moment(contactData?.birthdate)),
    }
  }, [planData, contactData])

  useEffect(() => {
    go2Top()
    dispatch(getOptions(id))
    dispatch(getPersonalInformation(id))
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Formik
      initialValues={{
        ...initialValues,
        socialInsuranceNumberOfMonthContribution: initialValues.isEmployee
          ? (parseInt(initialValues.socialInsuranceNumberOfMonthContribution) / 12).toString()
          : initialValues.socialInsuranceNumberOfMonthContribution,
      }}
      enableReinitialize
      onSubmit={(object) => {
        for (var value in object) {
          let data: string = `${object[value]}`
          let data_array = data.split(',')
          let new_data = data_array.join('')
          if (value === 'baseIncomePerMonth') {
          } else if (value === 'expensePerMonth') {
            object.expensePerMonth = new_data
          } else if (value === 'incomePerMonth') {
            object.incomePerMonth = new_data
          } else if (value === 'socialInsuranceNumberOfMonthContribution') {
            object.socialInsuranceNumberOfMonthContribution = new_data
          } else if (value === 'isEmployee') {
            if (new_data === 'true') {
              let dataArray = object.socialInsuranceNumberOfMonthContribution.split(',')
              let newData = dataArray.join('')
              object.socialInsuranceNumberOfMonthContribution = (
                parseFloat(newData) * 12
              ).toString()
            }
          }
        }
        dispatch(createPersonalInformation(id, object))
        history.push(link)
      }}
    >
      {(props) => {
        return <FinalcialInfo id={id} {...props} options={options} />
      }}
    </Formik>
  )
}

export default Pages
