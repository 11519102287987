import React, { FC, useMemo, useEffect } from 'react'
import { Link, useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'
import { Container, Header } from './InterestedDetail.styles'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { ShowMoreDetailInteresting } from './InterestedDetail.components'

const InterestedDetail: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      path: `/contacts/${contact?.id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      label: 'สิ่งที่ลูกค้าสนใจ',
    },
  ]

  useEffect(() => {
    if (id) dispatch(getContactById(id))
  }, [dispatch, id])

  const entertainments = useMemo(() => {
    if (contact?.entertainment && Object.keys(contact?.entertainment).length > 0) {
      return contact?.entertainment
    } else {
      return null
    }
  }, [contact?.entertainment])

  const healths = useMemo(() => {
    if (contact?.health && Object.keys(contact?.health).length > 0) {
      return contact?.health
    } else {
      return null
    }
  }, [contact?.health])

  const travels = useMemo(() => {
    if (contact?.travel && Object.keys(contact?.travel).length > 0) {
      return contact?.travel
    } else {
      return null
    }
  }, [contact?.travel])

  const foods = useMemo(() => {
    if (contact?.food && Object.keys(contact?.food).length > 0) {
      return contact?.food
    } else {
      return null
    }
  }, [contact?.food])

  return (
    <Container>
      <Header>
        <h3>สิ่งที่ลูกค้าสนใจ</h3>
      </Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <div className="Content">
        {entertainments && ShowMoreDetailInteresting('entertainment', entertainments)}
        {healths && ShowMoreDetailInteresting('health', healths)}
        {travels && ShowMoreDetailInteresting('travel', travels)}
        {foods && ShowMoreDetailInteresting('food', foods)}
        <Link to={`/contacts/${contact?.id}/interested/edit`}>
          <button type="button" className="secondary">
            แก้ไข
          </button>
        </Link>
      </div>
    </Container>
  )
}

export default InterestedDetail
