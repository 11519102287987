import styled from '@emotion/styled'
import Table from 'components/Table'
import ButtonLink from 'components/ButtonLink'

export type ContainerType = {
  active?: boolean
}

export type FilterType = {
  contactId?: string | number
  dataSearch: string
  orderBy: string | Array<string>
  order: string | Array<string>
  page: number
  perPage: number
}

export const StyledTable = styled(Table)`
  width: 100%;
  margin-bottom: 25px;
  > table {
    > thead {
      > tr {
        padding: 0;

        th {
          padding: 10px 0;

          background-color: white;
          color: var(--agt-secondary-text-color);

          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
        }
      }
    }
    > tbody {
      > tr {
        padding: 0;
        td {
          padding: 10px 0;
          color: var(--agt-primary-text-color);
          border-bottom: 1px solid var(--agt-background-color-2) !important;
          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
        }
      }
    }
  }
  > div:last-of-type {
    display: none;
  }
`

export const OppotunityTable = styled(Table)`
  width: 100%;
  margin-bottom: 25px;
  > table {
    > thead {
      > tr {
        padding: 0;
        th {
          padding: 10px 0;
          background-color: white;
          color: var(--agt-secondary-text-color);
          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
        }
      }
    }
    > tbody {
      > tr {
        padding: 0;
        td {
          padding: 10px 0;
          color: var(--agt-primary-text-color);
          border-bottom: 1px solid var(--agt-background-color-2) !important;
          &:first-of-type {
            padding-left: 20px;
          }
          &:last-of-type {
            padding-right: 20px;
          }
          &:nth-of-type(4) {
            width: 250px;
          }
        }
      }
    }
  }
  > div:last-of-type {
    display: none;
  }
`

export const StyledButtonLink = styled(ButtonLink)`
  button {
    width: fit-content;
    padding: 0px 40px;
    margin-bottom: 0;
  }
`

export const Container = styled.div<ContainerType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${(props) => (props.active ? '100%' : '0px')};
  height: ${(props) => (props.active ? '100%' : '0px')};
  overflow: ${(props) => (props.active ? '' : 'hidden')};
`

export const Loading = styled.div`
  display: block;
  margin-bottom: 20px;

  text-align: center;
`

export const Noting = styled.div`
  display: block;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 20px;

  text-align: center;
  border-bottom: 1px solid var(--agt-background-color-2);
`
