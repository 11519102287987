import React, { FC, useCallback, useMemo, useState, useEffect } from 'react'
import styled from '@emotion/styled'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useParams } from 'routes'
import Icon from 'components/Icon'
import DebtDetails from './DebtDetails'
import DebtProtectionsFreeAssets from './DebtProtectionsFreeAssets'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'

type useParamsType = { id: string }

const Container = styled.div`
  width: 100%;
`
const Header = styled.h3`
  margin: 16px 0 10px;
`
const HeaderContainer = styled.div`
  height: 84px;
  background: #eff1f5;
  padding-left: 122.96px;
  padding-right: 121.96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextHeader = styled.h3``
const RightIcon = styled(Icon)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 11px;
  svg {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`
const LeftIcon = styled(RightIcon)`
  transform-origin: center;
  transform: rotate(180deg);
`
const ButtonArrow = styled.div`
  cursor: pointer;
`
const BodyContainer = styled.div`
  padding-left: 17%;
  padding-right: 17%;
  padding-top: 34px;
`
const EmptryContainer = styled.div`
  width: 34px;
`
const TextTitle = styled.div`
  font-weight: bold;
  text-align: center;
`

const Pages: FC = (props) => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const [step, setStep] = useState<number>(0)
  const stepList = useMemo(
    () => [
      'รายละเอียดภาระหนี้สิน',
      'รายละเอียดสินทรัพย์ที่ใช้เพื่อวางแผนการชำระหนี้สิน (ที่ไม่ได้จัดสรรเพื่อวัตถุประสงค์อื่น)',
    ],
    [],
  )
  const nextStep = useCallback(() => {
    step + 1 < stepList.length && setStep(step + 1)
  }, [step, stepList])
  const backStep = useCallback(() => {
    step - 1 >= 0 && setStep(step - 1)
  }, [step])

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: contact?.name || 'รายละเอียดข้อมูลติดต่อ' },
    {
      path: { pathname: `/contacts/${id}/planning`, state: 'planning' },
      label: 'วางแผนประกัน',
    },
    { label: 'วางแผนคุ้มครองภาระที่อาจจะตกถึงครอบครัว' },
  ]

  useEffect(() => {
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Container>
      <Header>วางแผนประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <HeaderContainer>
        {step !== 0 && (
          <ButtonArrow onClick={backStep}>
            <LeftIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === 0 && <EmptryContainer />}
        <TextHeader>วางแผนคุ้มครองภาระหนี้สินที่อาจจะตกถึงครอบครัว</TextHeader>
        {step !== stepList.length - 1 && (
          <ButtonArrow onClick={nextStep}>
            <RightIcon name="vector_right" />
          </ButtonArrow>
        )}
        {step === stepList.length - 1 && <EmptryContainer />}
      </HeaderContainer>
      <BodyContainer>
        <TextTitle>{stepList[step]}</TextTitle>
        {step === 0 && <DebtDetails nextStep={nextStep} />}
        {step === 1 && <DebtProtectionsFreeAssets />}
      </BodyContainer>
    </Container>
  )
}

export default Pages
