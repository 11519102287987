import React, { FC, useState, useEffect } from 'react'
import { ErrorMessage, Field, useFormikContext, FieldArray, getIn } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import has from 'lodash/has'
import Modal from 'components/Modal'
import { getYearsOptions } from 'components/DatePicker'
import { RootState } from 'store/rootReducer'
import { getContactById, getPortOptions } from 'store/contactSlice'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import { getFullNameInsuranceType } from 'pages/insurance_portfolio/_id/InsurancePort.components'
import {
  CustomSelectComponent,
  ImageComponent,
  getMaxDate,
  getMinDate,
  getStateFromEndPolicyAt,
  CustomCurrencyComponent,
  CustomPercentComponent,
  CustomSpecialFieldComponent,
  CustomSpecialFieldPercentComponent,
  checkedRiders,
  NewCustomPersonInputComponent,
  NewCustomFieldComponent,
  NewCustomSelectComponent,
  NewCustomStrInputComponent,
  ActModal,
  LifeModal,
  CustomVehicleSelectComponent,
} from './InsurancePortForm.components'
import {
  CreateAddForm,
  FormValue,
  Loading,
  ModalContent,
  StyleGender,
  PencilIcon,
  TrashIcon,
  Error,
  DatePickerField,
  GroupButtons,
  NewCustomForm,
  IconStyle,
} from './InsurancePortForm.styles'
import { InsurancePortFormType, RidersType, useParamsType } from './InsurancePortForm.type'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

const initialValue: Array<RidersType> = [
  {
    riderType: '',
    compensation: 0,
    premium: 0,
    peroidYearNumber: 0,
  },
]

const NewPortForm: FC<InsurancePortFormType> = (props) => {
  const { values = {}, errors = {}, touched = {} } = props
  const { id } = useParams<useParamsType>()
  const formik = useFormikContext()
  const { setFieldValue } = formik
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const [isAddInsurance, setIsAddInsurance] = useState<boolean>(false)
  const [isAct, setIsAct] = useState<boolean>(false)
  const [isRemove, setIsRemove] = useState<number>(-1)
  const [stateImage, setStateImage] = useState<boolean>(true)
  const [waitingModal, setWaitingModal] = useState<boolean>(false)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [prevArray, setPrevArray] = useState<Array<RidersType>>(initialValue)
  const [indexField, setIndexField] = useState<number>(-1)
  const [stack, setStack] = useState<{
    annualPremium: string
    policyNo: string
    startPolicyAt: string | Date
    endPolicyAt: string | Date
  }>({
    annualPremium: '',
    policyNo: '',
    startPolicyAt: new Date(),
    endPolicyAt: new Date(),
  })
  const { portOptions } = useSelector((state: RootState) => state.contacts)

  useEffect(() => {
    dispatch(getContactById(id))
    dispatch(getPortOptions(id, () => {}))
  }, [dispatch, id])

  useEffect(() => {
    // console.log(
    //   'driverNum---------->',
    //   values?.specialFields?.productDetails?.driverNum,
    //   typeof values?.specialFields?.productDetails?.driverNum,
    // )
    // console.log(
    //   'passengerNum---------->',
    //   values?.specialFields?.productDetails?.passengerNum,
    //   typeof values?.specialFields?.productDetails?.passengerNum,
    // )
    let driverNum: number =
      values?.specialFields?.productDetails?.driverNum &&
      typeof values?.specialFields?.productDetails?.driverNum === 'number'
        ? values?.specialFields?.productDetails?.driverNum
        : values?.specialFields?.productDetails?.driverNum
        ? parseInt(values?.specialFields?.productDetails?.driverNum.toString())
        : 0
    let passengerNum: number =
      values?.specialFields?.productDetails?.passengerNum &&
      typeof values?.specialFields?.productDetails?.passengerNum === 'number'
        ? values?.specialFields?.productDetails?.passengerNum
        : values?.specialFields?.productDetails?.passengerNum
        ? parseInt(values?.specialFields?.productDetails?.passengerNum.toString())
        : 0
    setFieldValue('specialFields.productDetails.coverAccNum', driverNum + passengerNum)
  }, [
    values?.specialFields?.productDetails?.driverNum,
    values?.specialFields?.productDetails?.passengerNum,
  ])

  // useEffect(() => {
  //   console.log('productDetails---------->', values?.specialFields?.productDetails)
  // }, [values?.specialFields?.productDetails])

  // useEffect(() => {
  //   console.log('vehicle---------->', values?.specialFields?.vehicle)
  // }, [values?.specialFields?.vehicle])

  // useEffect(() => {
  //   console.log('coverAccNum---------->', values?.specialFields?.productDetails?.coverAccNum)
  // }, [values?.specialFields?.productDetails?.coverAccNum])

  // useEffect(() => {
  //   console.log('values---------->', values)
  // }, [values])

  // useEffect(() => {
  //   console.log('errors---------->', errors)
  // }, [errors])

  return (
    <>
      {loading ? (
        <Loading>Loading...</Loading>
      ) : (
        <FormValue onSubmit={props.handleSubmit}>
          {['life'].includes(values?.policyType || '') && (
            <LifeModal
              isAddInsurance={isAddInsurance}
              values={values}
              setIsAddInsurance={setIsAddInsurance}
              setWaitingModal={setWaitingModal}
              prevArray={prevArray}
              portOptions={portOptions}
              setPrevArray={setPrevArray}
              isChange={isChange}
              setIsChange={setIsChange}
              indexField={indexField}
            />
          )}
          {['motor'].includes(values?.policyType || '') && (
            <ActModal
              isAct={isAct}
              values={values}
              setIsAct={setIsAct}
              setWaitingModal={setWaitingModal}
              setFieldValue={setFieldValue}
              isChange={isChange}
              setIsChange={setIsChange}
              setIndexField={setIndexField}
              indexField={indexField}
              stack={stack}
            />
          )}
          <NewCustomForm>
            <div className="Row">
              <div className="Group">
                <b>เจ้าของกรมธรรม์</b>
                <Field
                  className="ownerName"
                  name="ownerName"
                  type="string"
                  placeholder="โปรดระบุ"
                  disabled
                />
              </div>
              <div className="Group">
                <ImageComponent
                  isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                  isEdit={stateImage}
                  forOnce={setStateImage}
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <p>เพศ</p>
                <StyleGender name="ownerGender" disabled={true} />
              </div>
              <div className="Group">
                <p>อายุ</p>
                <Field
                  className="ownerAge"
                  name="ownerAge"
                  placeholder="โปรดระบุ"
                  value={values.ownerAge}
                  disabled
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <p>
                  รูปแบบการประกันภัย <span>*</span>
                </p>
                <Field
                  className="insurancePattern"
                  name="insurancePattern"
                  component={CustomSelectComponent}
                  options={portOptions.insurancePatternOptions}
                  forChangePattern={(value) => {
                    if (value !== values.insurancePattern) {
                      setFieldValue('policyType', '')
                      setFieldValue('insuranceType', '')
                      setFieldValue('purpose', '')
                      setFieldValue('yieldToMaturityRate', '0')
                    }
                  }}
                  isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                />
              </div>
              <div className="Group">
                <p>
                  ประเภทประกัน <span>*</span>
                </p>
                <Field
                  className="policyType"
                  name="policyType"
                  component={CustomSelectComponent}
                  options={(
                    portOptions?.policyTypeOptions[`${values.insurancePattern}`] || []
                  ).filter((option) => !['ta'].includes(option.value))}
                  isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                />
              </div>
            </div>
            <div className="Row">
              {['motor', 'life', 'health', 'accident', 'home'].includes(
                values?.policyType || '',
              ) && (
                <div className="Group">
                  <p>
                    ประเภทกรมธรรม์ <span>*</span>
                  </p>
                  <Field
                    className="insuranceType"
                    name="insuranceType"
                    component={CustomSelectComponent}
                    options={portOptions?.insuranceTypeOptions[`${values.policyType}`] || []}
                    isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                  />
                </div>
              )}
              <div className="Group">
                <p>
                  บริษัทประกัน <span>*</span>
                </p>
                <Field
                  className="insuranceCompanyName"
                  name="insuranceCompanyName"
                  component={CustomSelectComponent}
                  options={portOptions.insuranceCompanyNameOptions}
                  isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                />
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <p>
                  แบบประกัน <span>*</span>
                </p>
                <Field
                  className={errors.name && touched.name ? 'name errors' : 'name'}
                  name="name"
                  type="string"
                  placeholder="กรอกชื่อแบบประกัน"
                  disabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                />
                <ErrorMessage component={Error} name="name" />
              </div>
              <div className="Group">
                <p>
                  หมายเลขกรมธรรม์ <span>*</span>
                </p>
                <Field
                  className={errors.policyNo && touched.policyNo ? 'policyNo errors' : 'policyNo'}
                  name="policyNo"
                  type="string"
                  placeholder="โปรดระบุ"
                  disabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                />
                <ErrorMessage component={Error} name="policyNo" />
              </div>
            </div>
            {/* {['ta'].includes(values?.policyType || '') && (
              <div className="TaDetail">
                <div className="Row">
                  <div className="Group">
                    <p>จังหวัดต้นทาง</p>
                    <Field
                      className=""
                      name="qqq"
                      component={CustomSelectComponent}
                      options={[
                        { label: 'x', value: 'x' },
                        { label: 'y', value: 'y' },
                        { label: 'z', value: 'z' },
                      ]}
                      isDisabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <p>จังหวัดปลายทาง</p>
                    <Field
                      className=""
                      name="qqqq"
                      component={CustomSelectComponent}
                      options={[
                        { label: 'x', value: 'x' },
                        { label: 'y', value: 'y' },
                        { label: 'z', value: 'z' },
                      ]}
                      isDisabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <p>จำนวนผู้เดินทาง</p>
                    <Field
                      className=""
                      name="ssssss"
                      component={CustomSelectComponent}
                      options={[
                        { label: 'x', value: 'x' },
                        { label: 'y', value: 'y' },
                        { label: 'z', value: 'z' },
                      ]}
                      isDisabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <p>วันที่ออกเดินทาง</p>
                    <DatePickerField
                      name="eeeeee"
                      // value={values.startPolicyAt || ''}
                      showSelector={true}
                      // maxDate={getMaxDate(values.endPolicyAt)}
                      disabled={values.state === 'rejected' || values.createdSource === 'tqm_life'}
                      // isErrors={errors.startPolicyAt && touched.startPolicyAt}
                      onChange={(value) => setFieldValue('eeeeee', value)}
                    />
                    <ErrorMessage component={Error} name="eeeeee" />
                  </div>
                  <div className="Group">
                    <p>วันที่กลับถึงไทย</p>
                    <DatePickerField
                      name="qqqqqqqq"
                      // value={values.endPolicyAt || ''}
                      showSelector={true}
                      // minDate={getMinDate(values.startPolicyAt)}
                      yearOptions={getYearsOptions(null, 'desc', 50)}
                      disabled={values.state === 'rejected' || values.createdSource === 'tqm_life'}
                      // isErrors={errors.endPolicyAt && touched.endPolicyAt}
                      onChange={(value) => {
                        setFieldValue('qqqqqqqq', value)
                      }}
                    />
                    <ErrorMessage component={Error} name="qqqqqqqq" />
                  </div>
                </div>
              </div>
            )} */}
            {['life', 'motor', 'health', 'accident', 'home'].includes(values?.policyType || '') && (
              <div className="Row">
                <div className="Group">
                  <p>
                    วันที่เริ่มทำสัญญา <span>*</span>
                  </p>
                  <DatePickerField
                    name="startPolicyAt"
                    value={values.startPolicyAt || ''}
                    showSelector={true}
                    maxDate={getMaxDate(values.endPolicyAt)}
                    disabled={
                      values.state === 'rejected' ||
                      ['tqm', 'tqm_life'].includes(values?.createdSource || '')
                    }
                    isErrors={errors.startPolicyAt && touched.startPolicyAt}
                    onChange={(value) => setFieldValue('startPolicyAt', value)}
                  />
                  <ErrorMessage component={Error} name="startPolicyAt" />
                </div>
                <div className="Group">
                  <p>
                    วันที่ครบกำหนดสัญญา (คุ้มครองถึง) <span>*</span>
                  </p>
                  <DatePickerField
                    name="endPolicyAt"
                    value={values.endPolicyAt || ''}
                    showSelector={true}
                    minDate={getMinDate(values.startPolicyAt)}
                    yearOptions={getYearsOptions(null, 'desc', 50)}
                    disabled={
                      values.state === 'rejected' ||
                      ['tqm', 'tqm_life'].includes(values?.createdSource || '')
                    }
                    isErrors={errors.endPolicyAt && touched.endPolicyAt}
                    onChange={(value) => {
                      setFieldValue('endPolicyAt', value)
                      setFieldValue('state', getStateFromEndPolicyAt(value))
                    }}
                  />
                  <ErrorMessage component={Error} name="endPolicyAt" />
                </div>
              </div>
            )}
            <div className="Row">
              <div className="Group">
                <p>
                  สถานะกรมธรรม์ <span>*</span>
                </p>
                {values.state === 'expired' ? (
                  <input className="" placeholder="โปรดระบุ" value="กรมธรรม์หมดอายุ" disabled />
                ) : (
                  <Field
                    className="state"
                    name="state"
                    component={CustomSelectComponent}
                    options={portOptions.stateOptions}
                    isDisabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                  />
                )}
              </div>
              {['motor'].includes(values?.policyType || '') && (
                <div className="Group">
                  <p>เลือกรถยนต์ของลูกค้า</p>
                  <Field
                    name="specialFields.vehicle"
                    component={CustomVehicleSelectComponent}
                    options={portOptions?.vehicleOptions}
                    isDisabled={values.createdSource === 'tqm'}
                  />
                </div>
              )}
            </div>
            <div className="Row">
              <div className="Group remark">
                <p>รายละเอียดเพิ่มเติม</p>
                <Field
                  className="remark"
                  component="textarea"
                  name="remark"
                  type="textarea"
                  placeholder="รายละเอียดเพิ่มเติม"
                />
              </div>
            </div>
            {['motor'].includes(values?.policyType || '') && (
              <>
                <FieldArray
                  name="specialFields.drivers"
                  render={(arrayHelpers) => {
                    function addDriver() {
                      arrayHelpers.push({
                        firstName: '',
                        lastName: '',
                        occupation: '',
                        birthdate: new Date(),
                      })
                    }
                    return (
                      <div className="DriverDetail">
                        {(values.specialFields?.drivers || []).map((_, index: number) => {
                          return (
                            <div className="Driver">
                              <b>ข้อมูลผู้ขับขี่ {`${index + 1}`}</b>
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="Delete secondary"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <IconStyle name="bin" />
                                </button>
                              )}
                              <div className="Row">
                                <div className="Group">
                                  <p>
                                    ชื่อจริง <span>*</span>
                                  </p>
                                  <Field
                                    name={`specialFields.drivers[${index}].firstName`}
                                    component={NewCustomStrInputComponent}
                                  />
                                </div>
                                <div className="Group">
                                  <p>
                                    นามสกุล <span>*</span>
                                  </p>
                                  <Field
                                    name={`specialFields.drivers[${index}].lastName`}
                                    component={NewCustomStrInputComponent}
                                  />
                                </div>
                              </div>
                              <div className="Row">
                                <div className="Group">
                                  <p>
                                    อาชีพ <span>*</span>
                                  </p>
                                  <Field
                                    name={`specialFields.drivers[${index}].occupation`}
                                    component={NewCustomStrInputComponent}
                                  />
                                </div>
                                <div className="Group">
                                  <p>
                                    วันเกิด <span>*</span>
                                  </p>
                                  <DatePickerField
                                    name={`specialFields.drivers[${index}].birthdate`}
                                    value={
                                      values?.specialFields?.drivers
                                        ? values?.specialFields?.drivers[index]?.birthdate
                                        : ''
                                    }
                                    showSelector={true}
                                    yearOptions={getYearsOptions(null, 'desc', 50)}
                                    onChange={(value) => {
                                      setFieldValue(
                                        `specialFields.drivers[${index}].birthdate`,
                                        value,
                                      )
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )
                        })}
                        {(values.specialFields?.drivers || []).length < 2 && (
                          <div className="Button">
                            <button type="button" className="secondary" onClick={() => addDriver()}>
                              + เพิ่มผู้ขับขี่
                            </button>
                          </div>
                        )}
                      </div>
                    )
                  }}
                />
              </>
            )}
            {['life', 'motor', 'health', 'accident', 'home'].includes(values?.policyType || '') && (
              <div className="Row">
                <div className="Group">
                  <p>
                    ทุนประกัน (บาท) <span>*</span>
                  </p>
                  <Field
                    className="sumInsured"
                    name="sumInsured"
                    component={CustomCurrencyComponent}
                    disabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                  />
                </div>
                <div className="Group">
                  <p>
                    {values.insurancePattern === 'personal'
                      ? 'เบี้ยประกันต่อปี (บาท)'
                      : 'เบี้ยประกันภัยสุทธิต่อปี (บาท)'}{' '}
                    <span>*</span>
                  </p>
                  <Field
                    className="premium"
                    name="netPremium"
                    component={CustomCurrencyComponent}
                    disabled={['tqm', 'tqm_life'].includes(values?.createdSource || '')}
                  />
                </div>
              </div>
            )}
            {['life', 'health', 'accident', 'home'].includes(values?.policyType || '') && (
              <>
                <div className="Row">
                  <div className="Group">
                    <p>
                      รูปแบบการจ่าย <span>*</span>
                    </p>
                    <Field
                      className="paymentType"
                      name="paymentType"
                      component={CustomSelectComponent}
                      options={portOptions.paymentTypeOptions}
                      isDisabled={['tqm_life'].includes(values?.createdSource || '')}
                    />
                  </div>
                  {['term', ''].includes(values?.insuranceType || '') ? (
                    <div className="Group">
                      <p>เงินคืนเมื่อครบสัญญา (%)</p>
                      <Field
                        className="yieldToMaturityRate"
                        name="yieldToMaturityRate"
                        component={CustomPercentComponent}
                        disabled
                      />
                    </div>
                  ) : (
                    <div className="Group">
                      <p>เงินคืนเมื่อครบสัญญา (%)</p>
                      <Field
                        className="yieldToMaturityRate"
                        name="yieldToMaturityRate"
                        component={CustomPercentComponent}
                        disabled={['tqm_life'].includes(values?.createdSource || '')}
                      />
                    </div>
                  )}
                </div>
                <div className="Row">
                  <div className="Group">
                    <p>
                      วิธีนับระยะเวลาจ่ายเบี้ย <span>*</span>
                    </p>
                    <Field
                      className="payPeriodType"
                      name="payPeriodType"
                      component={CustomSelectComponent}
                      options={portOptions.payPeriodTypeOptions}
                      isDisabled={['tqm_life'].includes(values?.createdSource || '')}
                    />
                  </div>
                  {values.payPeriodType && (
                    <div className="Group">
                      <p>
                        {values.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}{' '}
                        <span>*</span>
                      </p>
                      <Field
                        className="payPeriodValue"
                        name="payPeriodValue"
                        component={CustomPercentComponent}
                        onKeyDown={(e) => /[+-.,]$/.test(e.key) && e.preventDefault()}
                        disabled={['tqm_life'].includes(values?.createdSource || '')}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {['life'].includes(values?.policyType || '') &&
            ['investment'].includes(values?.insuranceType || '') ? (
              <>
                <div className="Row">
                  <div className="Group">
                    <p>
                      วัตถุประสงค์ของกรมธรรม์ <span>*</span>
                    </p>
                    <br />
                    <Field
                      className="purpose"
                      name="purpose"
                      component={CustomSelectComponent}
                      options={portOptions?.purposeOptions[`${values.insurancePattern}`] || []}
                    />
                  </div>
                  <div className="Group">
                    <p>
                      เบี้ยประกันของ unit link เฉพาะส่วนที่ใช้ลดหย่อน
                      <br />
                      ภาษีได้
                    </p>
                    <Field
                      className="premiumAllowance"
                      name="specialFields.premiumAllowance"
                      component={CustomSpecialFieldComponent}
                      disabled={['tqm_life'].includes(values?.createdSource || '')}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <p>คาดการณ์มูลค่าคงเหลือ ณ ปีสุดท้าย</p>
                    <Field
                      className="residualValue"
                      name="specialFields.residualValue"
                      component={CustomSpecialFieldComponent}
                      disabled={['tqm_life'].includes(values?.createdSource || '')}
                    />
                  </div>
                  <div className="Group">
                    <p>อัตราผลตอบแทนคาดหวัง</p>
                    <Field
                      className="rateOfReturn"
                      name="specialFields.rateOfReturn"
                      component={CustomSpecialFieldPercentComponent}
                      disabled={['tqm_life'].includes(values?.createdSource || '')}
                    />
                  </div>
                </div>
              </>
            ) : (
              ['life'].includes(values?.policyType || '') && (
                <div className="Row">
                  <div className="Group">
                    <p>
                      วัตถุประสงค์ของกรมธรรม์ <span>*</span>
                    </p>
                    <Field
                      className="purpose"
                      name="purpose"
                      component={CustomSelectComponent}
                      options={portOptions?.purposeOptions[`${values.insurancePattern}`] || []}
                    />
                  </div>
                </div>
              )
            )}
            {['motor'].includes(values?.policyType || '') && (
              <>
                <div className="LiabilityInsurance">
                  <b>ความรับผิดต่อบุคคลภายนอก</b>
                  <div className="Row">
                    <div className="Group">
                      <p>
                        ความเสียหายต่อชีวิตชีวิต ร่างกาย หรืออนามัยเฉพาะ ส่วนเกินวงเงินสูงสุดตาม
                        พ.ร.บ. (บาท/คน)
                      </p>
                      <Field
                        name="specialFields.productDetails.cover3Rd"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                    <div className="Group">
                      <p>
                        ความเสียหายต่อชีวิตชีวิต ร่างกาย หรืออนามัยเฉพาะ ส่วนเกินวงเงินสูงสุดตาม
                        พ.ร.บ. (บาท/ครั้ง)
                      </p>
                      <Field
                        name="specialFields.productDetails.cover3RdTime"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Group">
                      <p>ความเสียหายต่อทรัพย์สิน (บาท/ครั้ง)</p>
                      <Field
                        name="specialFields.productDetails.cover3RdAsset"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                    <div className="Group">
                      <p>ความเสียหายส่วนแรก (บาท/ครั้ง)</p>
                      <Field
                        name="specialFields.productDetails.coverEarly"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                  </div>
                </div>
                <div className="LiabilityInsurance">
                  <b>รถยนต์เสียหาย สูญหาย ไฟไหม้</b>
                  <div className="Row">
                    <div className="Group">
                      <p>
                        ความเสียหายต่อรถยนต์ (บาท/ครั้ง) <span>*</span>
                      </p>
                      <Field
                        name="specialFields.productDetails.coverCar"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                    <div className="Group">
                      <p>
                        ความเสียหายส่วนแรก (บาท/ครั้ง) <span>*</span>
                      </p>
                      <Field
                        name="specialFields.productDetails.coverDeduct"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                  </div>
                  <div className="Row">
                    <div className="Group">
                      <p>
                        รถยนต์สูญหาย ไฟไหม้ (บาท) <span>*</span>
                      </p>
                      <Field
                        name="specialFields.productDetails.coverLostFire"
                        component={NewCustomFieldComponent}
                        disabled={values.createdSource === 'tqm'}
                      />
                    </div>
                  </div>
                </div>
                <div className="Protection">
                  <b>ความคุ้มครองตามเอกสารแนบท้าย</b>
                  <div className="Accident">
                    <b>อุบัติเหตุส่วนบุคคล</b>
                    <div className="Permanent">
                      <p>การเสียชีวิต สูญเสียอวัยวะ ทุพพลภาพถาวร</p>
                      <div className="Row">
                        <div className="Group">
                          <p>ผู้ขับขี่ (คน)</p>
                          <Field
                            name="specialFields.productDetails.driverNum"
                            component={NewCustomPersonInputComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group">
                          <p>มูลค่า</p>
                          <Field
                            name="specialFields.productDetails.coverDriverDeceased"
                            component={NewCustomFieldComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group cc">
                          <p>หน่วย</p>
                          <Field
                            name="specialFields.productDetails.coverDriverDeceasedUnit"
                            component={NewCustomSelectComponent}
                            options={portOptions?.accidentExpenseOptions}
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="Row">
                        <div className="Group">
                          <p>ผู้โดยสาร (คน)</p>
                          <Field
                            name="specialFields.productDetails.passengerNum"
                            component={NewCustomPersonInputComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group">
                          <p>มูลค่า</p>
                          <Field
                            name="specialFields.productDetails.coverPassengerDeceased"
                            component={NewCustomFieldComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group ff">
                          <p>หน่วย</p>
                          <Field
                            name="specialFields.productDetails.coverPassengerDeceasedUnit"
                            component={NewCustomSelectComponent}
                            options={portOptions?.accidentExpenseOptions}
                            isDisabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="Temporarily">
                      <p>ทุพพลภาพชั่วคราว</p>
                      <div className="Row">
                        <div className="Group">
                          <p>ผู้ขับขี่ (คน)</p>
                          <Field
                            name="specialFields.productDetails.driverNum"
                            component={NewCustomPersonInputComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group">
                          <p>มูลค่า</p>
                          <Field
                            name="specialFields.productDetails.coverDriverTemporary"
                            component={NewCustomFieldComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group cc">
                          <p>หน่วย</p>
                          <Field
                            name="specialFields.productDetails.coverDriverTemporaryUnit"
                            component={NewCustomSelectComponent}
                            options={portOptions?.accidentExpenseOptions}
                            isDisabled={values.createdSource === 'tqm'}
                          />
                        </div>
                      </div>
                      <div className="Row">
                        <div className="Group">
                          <p>ผู้โดยสาร (คน)</p>
                          <Field
                            name="specialFields.productDetails.passengerNum"
                            component={NewCustomPersonInputComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group">
                          <p>มูลค่า</p>
                          <Field
                            name="specialFields.productDetails.coverPassengerTemporary"
                            component={NewCustomFieldComponent}
                            disabled={values.createdSource === 'tqm'}
                          />
                        </div>
                        <div className="Group ff">
                          <p>หน่วย</p>
                          <Field
                            name="specialFields.productDetails.coverPassengerTemporaryUnit"
                            component={NewCustomSelectComponent}
                            options={portOptions?.accidentExpenseOptions}
                            isDisabled={values.createdSource === 'tqm'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="MedicalFee">
                    <b>ค่ารักษาพยาบาล</b>
                    <div className="Row">
                      <div className="Group">
                        <p>จำนวนคนในรถ (คน)</p>
                        <Field
                          name="specialFields.productDetails.coverAccPassNum"
                          component={NewCustomPersonInputComponent}
                          disabled={values.createdSource === 'tqm'}
                        />
                      </div>
                      <div className="Group">
                        <p>มูลค่า</p>
                        <Field
                          name="specialFields.productDetails.coverAccPass"
                          component={NewCustomFieldComponent}
                          disabled={values.createdSource === 'tqm'}
                        />
                      </div>
                      <div className="Group ff">
                        <p>หน่วย</p>
                        <Field
                          name="specialFields.productDetails.coverAccPassUnit"
                          component={NewCustomSelectComponent}
                          options={portOptions?.medicalExpenseOptions}
                          isDisabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="AddProtection">
                    <div className="Row">
                      <div className="Group">
                        <b>ประกันตัวผู้ขับขี่ (บาท/ครั้ง)</b>
                        <Field
                          name="specialFields.productDetails.coverLegal"
                          component={NewCustomFieldComponent}
                          disabled={values.createdSource === 'tqm'}
                        />
                      </div>
                    </div>
                  </div>
                  <FieldArray
                    name="specialFields.productDetails.coverMoreDetails"
                    render={(arrayHelpers) => {
                      function addProtection() {
                        arrayHelpers.push({
                          name: '',
                          value: 0,
                          unit: '',
                        })
                      }
                      return (
                        <div className="NewProtection">
                          {(values.specialFields?.productDetails?.coverMoreDetails || []).map(
                            (_, index: number) => {
                              return (
                                <div className="Row">
                                  <div className="Group Name">
                                    <p>ความคุ้มครอง</p>
                                    <Field
                                      className="Fix"
                                      name={`specialFields.productDetails.coverMoreDetails[${index}].name`}
                                      component={NewCustomStrInputComponent}
                                    />
                                    <div
                                      className="FaTime"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimes}
                                        color="var(--agt-primary-text-color)"
                                      />
                                    </div>
                                  </div>
                                  <div className="Group">
                                    <p>มูลค่า</p>
                                    <Field
                                      name={`specialFields.productDetails.coverMoreDetails[${index}].value`}
                                      component={NewCustomFieldComponent}
                                    />
                                  </div>
                                  <div className="Group Unit">
                                    <p>หน่วย</p>
                                    <Field
                                      name={`specialFields.productDetails.coverMoreDetails[${index}].unit`}
                                      component={NewCustomSelectComponent}
                                      options={portOptions?.medicalExpenseOptions}
                                    />
                                  </div>
                                </div>
                              )
                            },
                          )}
                          <button
                            className={`secondary AddBtn ${
                              values?.specialFields?.productDetails?.coverMoreDetails &&
                              values.specialFields.productDetails.coverMoreDetails.length === 0
                                ? 'NonPlus'
                                : ''
                            }`}
                            type="button"
                            onClick={addProtection}
                          >
                            + เพิ่มความคุ้มครองอื่นๆ
                          </button>
                        </div>
                      )
                    }}
                  />
                </div>
              </>
            )}
            {/* {['ta'].includes(values?.policyType || '') && (
              <div className="TaDetail">
                <div className="Row">
                  <div className="Group">
                    <p>เบี้ยประกันภัย (บาท)</p>
                    <br />
                    <Field
                      className=""
                      name="specialFields.zzz"
                      component={CustomSpecialFieldComponent}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <p>
                      ความคุ้มครองกรณีเสียชีวิต สูญเสียอวัยวะ
                      <br />
                      ทุพพลภาพ
                    </p>
                    <Field
                      className=""
                      name="specialFields.xxx"
                      component={CustomSpecialFieldComponent}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
                <div className="Row">
                  <div className="Group">
                    <p>ค่ารักษาพยาบาลกรณีอุบัติเหตุและเจ็บป่วย</p>
                    <br />
                    <Field
                      className=""
                      name="specialFields.ccc"
                      component={CustomSpecialFieldComponent}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                  <div className="Group">
                    <p>
                      ค่าใช้จ่ายการเคลื่อนย้ายผู้ป่วยฉุกเฉินหรือ
                      <br />
                      ส่งศพกลับประเทศ
                    </p>
                    <Field
                      className=""
                      name="specialFields.vvv"
                      component={CustomSpecialFieldComponent}
                      disabled={values.createdSource === 'tqm_life'}
                    />
                  </div>
                </div>
              </div>
            )} */}
          </NewCustomForm>
          {waitingModal ? (
            <CreateAddForm>
              <p>Loading...</p>
            </CreateAddForm>
          ) : ['life'].includes(values?.policyType || '') &&
            values.insuranceRiders &&
            checkedRiders(values.insuranceRiders) ? (
            <CreateAddForm>
              <div className="Header">อนุสัญญา</div>
              <FieldArray name="insuranceRiders">
                {({ push, remove, replace }) => {
                  return (
                    <>
                      {values.insuranceRiders &&
                        values.insuranceRiders.length > 0 &&
                        values.insuranceRiders.map((value, index) => {
                          if (get(value, 'riderType') === '') {
                            return null
                          }
                          if (has(value, '_destroy') && value._destroy) {
                            return null
                          }
                          return (
                            <>
                              <div className="Body" key={index}>
                                <p className="Text Topic">
                                  {getFullNameInsuranceType(value.riderType)}
                                </p>
                                <p className="Text">
                                  เบี้ยประกันต่อปี : {financial(value.premium + '')}
                                </p>
                                <p className="Text">
                                  จำนวนปีที่ต้องชำระเบี้ยประกันภัยตามกรมธรรม์ :{' '}
                                  {value.peroidYearNumber}
                                </p>
                                <p className="Text">
                                  เงินชดเชยต่อวัน : {financial(value.compensation + '')}
                                </p>
                                <div
                                  onClick={() => {
                                    if (values.insuranceRiders) {
                                      setPrevArray(values.insuranceRiders)
                                      setIsChange(true)
                                      setWaitingModal(true)
                                      setIndexField(index)
                                    }
                                  }}
                                  style={
                                    ['tqm_life'].includes(values?.createdSource || '')
                                      ? { display: 'none' }
                                      : {}
                                  }
                                >
                                  <PencilIcon name="edit" />
                                </div>
                                <div
                                  onClick={() => {
                                    setIsRemove(index)
                                  }}
                                  style={
                                    ['tqm_life'].includes(values?.createdSource || '')
                                      ? { display: 'none' }
                                      : {}
                                  }
                                >
                                  <TrashIcon name="bin" />
                                </div>
                              </div>
                              <Modal
                                isOpen={isRemove === index}
                                onConfirm={() => {
                                  if (has(value, 'id')) {
                                    replace(index, {
                                      id: value.id,
                                      riderType: value.riderType,
                                      premium: value.premium,
                                      peroidYearNumber: value.peroidYearNumber,
                                      compensation: value.compensation,
                                      _destroy: true,
                                    })
                                    let arr = values.insuranceRiders
                                      ? values.insuranceRiders
                                      : initialValue
                                    arr.splice(index, 1)
                                    setPrevArray(arr)
                                  } else {
                                    remove(index)
                                    let arr = values.insuranceRiders
                                      ? values.insuranceRiders
                                      : initialValue
                                    arr.splice(index, 1)
                                    setPrevArray(arr)
                                  }
                                  setIsRemove(-1)
                                }}
                                onCancel={() => setIsRemove(-1)}
                              >
                                <ModalContent>
                                  <div className="Warning">ยืนยันการลบอนุสัญญา</div>
                                </ModalContent>
                              </Modal>
                            </>
                          )
                        })}
                    </>
                  )
                }}
              </FieldArray>
            </CreateAddForm>
          ) : ['motor'].includes(values?.policyType || '') &&
            values.specialFields?.prbInsurance &&
            values.specialFields?.prbInsurance?.policyNo ? (
            <CreateAddForm>
              <div className="Header">พ.ร.บ.</div>
              <div className="Body Act">
                <div className="Row">
                  <p>เบี้ยประกันต่อปี : {values.specialFields?.prbInsurance?.annualPremium}</p>
                  <p>เลขกรมธรรม์ : {values.specialFields?.prbInsurance?.policyNo}</p>
                </div>
                <div className="Row">
                  <p>
                    เริ่มการคุ้มครอง :{' '}
                    {new Date(
                      values.specialFields?.prbInsurance?.startPolicyAt || '',
                    ).toLocaleDateString('th-TH')}
                  </p>
                  <p>
                    สิ้นสุดคุ้มครอง :{' '}
                    {new Date(
                      values.specialFields?.prbInsurance?.endPolicyAt || '',
                    ).toLocaleDateString('th-TH')}
                  </p>
                </div>
                {values.createdSource === 'tqm' ? null : (
                  <div className="Row Icon">
                    <div
                      onClick={() => {
                        setIsChange(true)
                        setWaitingModal(true)
                        setStack({
                          policyNo: values.specialFields?.prbInsurance?.policyNo || '',
                          annualPremium: values.specialFields?.prbInsurance?.annualPremium || '',
                          startPolicyAt: values.specialFields?.prbInsurance?.startPolicyAt
                            ? new Date(values.specialFields?.prbInsurance?.startPolicyAt)
                            : new Date(),
                          endPolicyAt: values.specialFields?.prbInsurance?.endPolicyAt
                            ? new Date(values.specialFields?.prbInsurance?.endPolicyAt)
                            : new Date(),
                        })
                      }}
                    >
                      <IconStyle name="edit" />
                    </div>
                    <div
                      onClick={() => {
                        let prbInsurance = {
                          annualPremium: '',
                          policyNo: '',
                          startPolicyAt: new Date(),
                          endPolicyAt: new Date(),
                        }
                        setFieldValue('specialFields.prbInsurance', prbInsurance)
                      }}
                    >
                      <IconStyle name="bin" />
                    </div>
                  </div>
                )}
              </div>
            </CreateAddForm>
          ) : null}
          <GroupButtons>
            {['life'].includes(values?.policyType || '') && (
              <button
                className="secondary"
                type="button"
                onClick={() => {
                  if (values.insuranceRiders) {
                    let len = values.insuranceRiders.length
                    let obj = values.insuranceRiders[len - 1]
                    if (isEqual(get(obj, 'riderType'), '')) {
                    } else {
                      values.insuranceRiders.push({
                        riderType: '',
                        compensation: 0,
                        premium: 0,
                        peroidYearNumber: 0,
                      })
                      setPrevArray(values.insuranceRiders)
                    }
                    setIsAddInsurance(true)
                    setWaitingModal(true)
                  }
                }}
                disabled={['tqm_life'].includes(values?.createdSource || '')}
              >
                + เพิ่มอนุสัญญา
              </button>
            )}
            {['motor'].includes(values?.policyType || '') &&
              values.specialFields?.prbInsurance &&
              values.specialFields?.prbInsurance?.policyNo === '' && (
                <button
                  className="secondary"
                  type="button"
                  onClick={() => {
                    let prbInsurance = {
                      annualPremium: '',
                      policyNo: '',
                      startPolicyAt: new Date(),
                      endPolicyAt: new Date(),
                    }
                    setFieldValue('specialFields.prbInsurance', prbInsurance)
                    setIsAct(true)
                    setWaitingModal(true)
                  }}
                  disabled
                >
                  + เพิ่ม พ.ร.บ.
                </button>
              )}
            <button className="primary" type="submit" disabled={props.isSubmitting}>
              {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
            </button>
          </GroupButtons>
        </FormValue>
      )}
    </>
  )
}

export default NewPortForm
