import React, { FC, useEffect, useState } from 'react'
import { Link } from 'routes'
import Task from '../components/Task'
import { TaskType } from '../components/Task.type'
import SetStateType from 'types/SetStateType'
import { OptionType, DisplayTasksType, FilterValues } from './index.type'
import {
  IconStyle,
  TasksContainer,
  ResetValue,
  DatePickerStyle,
  Label,
  Filter,
  GroupFilter,
  TopCollapse,
  Header,
  HintIcon,
  MenuCustom,
  Span,
  OptionsButton,
  FilterContainer,
  FieldValue,
  Selector,
} from './index.styles'

export const defaultSearchValue: FilterValues = {
  dataSearch: '',
  priorityLevel: null,
  dueDate: '',
}

export const options: Array<OptionType> = [
  { value: null, label: 'ไม่ระบุ' },
  { value: '1', label: 'น้อย' },
  { value: '2', label: 'ปานกลาง' },
  { value: '3', label: 'มาก' },
]

export const DisplayTasks: FC<DisplayTasksType> = (props) => {
  const { topic = '', tasks = [], status, forceExpand, setObj = () => {}, index } = props
  const toggle = () => {
    setObj()
  }

  return (
    <>
      <TopCollapse onClick={toggle}>
        <div
          style={{
            color: status === 'expire' ? 'var(--agt-error-color)' : 'var(--agt-secondary-color-2)',
          }}
        >
          {topic} {status !== 'complete' && `(${tasks.length})`}
        </div>
        {forceExpand === index ? (
          <IconStyle name="vector_top" />
        ) : (
          <IconStyle name="vector_bottom" />
        )}
      </TopCollapse>
      {forceExpand === index &&
        tasks.map((task: TaskType, index: number) => (
          <Task key={index} {...task} status={status} />
        ))}
    </>
  )
}

export const NewDisplayTasks = (props) => {
  const { topic = '', tasks = [], status = '', forceExpand } = props
  const [toggle, isToggle] = useState<boolean>(forceExpand)

  useEffect(() => {
    isToggle(forceExpand)
  }, [forceExpand])

  return (
    <>
      <TopCollapse onClick={() => isToggle(!toggle)}>
        <div
          style={{
            color: status === 'expire' ? 'var(--agt-error-color)' : 'var(--agt-secondary-color-2)',
          }}
        >
          {topic} {status !== 'complete' && `(${tasks.length})`}
        </div>
        {toggle ? <IconStyle name="vector_top" /> : <IconStyle name="vector_bottom" />}
      </TopCollapse>
      {toggle &&
        tasks.map((task: TaskType, index: number) => (
          <Task key={index} {...task} status={status} />
        ))}
    </>
  )
}

export const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>งานที่ต้องทำ</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

export const MenuComponent = ({
  viewpoint,
  setViewpoint,
  setIndex,
  setFilter,
  isFilter,
  currentAgent,
}) => {
  return (
    <MenuCustom>
      <div className="status">
        <Span margin="52px">สถานะ</Span>
        <div className="option" id="task__option">
          <OptionsButton
            radius="left"
            active={viewpoint === 'incomplete'}
            onClick={() => {
              setViewpoint('incomplete')
              setIndex(-1)
            }}
          >
            งานที่ยังไม่เสร็จ
          </OptionsButton>
          <OptionsButton
            radius="right"
            active={viewpoint === 'complete'}
            onClick={() => {
              setViewpoint('complete')
              setIndex(6)
            }}
          >
            งานที่เสร็จแล้ว
          </OptionsButton>
        </div>
      </div>
      <div className="seletor">
        <button
          id="task__filter"
          type="button"
          className="secondary"
          onClick={() => {
            setFilter(!isFilter)
          }}
        >
          ปรับตัวเลือก
        </button>
        <Link style={{ marginLeft: '16px' }} to="/tasks/new">
          <button
            id="task__create-work"
            type="button"
            disabled={currentAgent?.statusTh === 'ระงับ'}
          >
            สร้างงานที่ต้องทำ
          </button>
        </Link>
      </div>
    </MenuCustom>
  )
}

export const FilterComponent = ({
  isFilter,
  searchValue,
  setSearchValue,
  selectedOption,
  setSelectedOption,
  dueDate,
  setDueDate,
}) => {
  return (
    <FilterContainer hidden={!isFilter}>
      <GroupFilter style={{ marginBottom: '16px' }}>
        <Filter style={{ marginRight: '24px' }}>
          <Label>ค้นหา</Label>
          <FieldValue
            type="text"
            value={searchValue.dataSearch}
            placeholder="ชื่องาน,ผู้ติดต่อ,รายละเอียดงาน"
            onChange={(event) => setSearchValue({ ...searchValue, dataSearch: event.target.value })}
          />
        </Filter>
        <Filter>
          <Label>ระดับความสำคัญ</Label>
          <Selector
            value={selectedOption}
            onChange={(option: OptionType) => {
              setSelectedOption(option)
              const value = (option as OptionType).value
              setSearchValue({ ...searchValue, priorityLevel: value })
            }}
            options={options}
          />
        </Filter>
      </GroupFilter>
      <GroupFilter>
        <Filter>
          <Label>วันครบกำหนด</Label>
          <DatePickerStyle
            value={dueDate}
            onChange={(val) => {
              setDueDate(val)
              setSearchValue({
                ...searchValue,
                dueDate: val,
              })
            }}
          />
        </Filter>
        <ResetValue
          onClick={() => {
            setSearchValue(defaultSearchValue)
            setDueDate('')
            setSelectedOption(options[0])
          }}
        >
          รีเซ็ตค่าตัวเลือก
        </ResetValue>
      </GroupFilter>
    </FilterContainer>
  )
}

export const TaskComponent = ({
  viewpoint,
  tasks,
  tasksIncomplete,
  setindex,
  isindex,
  setIncomingWeek,
  incomingWeek,
}) => {
  if (viewpoint === 'incomplete') {
    return (
      <TasksContainer id="task__table">
        <DisplayTasks
          index={1}
          topic="เกินกำหนด"
          tasks={tasksIncomplete.tasksLated}
          status="expire"
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 1) setindex(1)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={2}
          topic="วันนี้"
          tasks={tasksIncomplete.tasksCurrentDay}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 2) setindex(2)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={3}
          topic="สัปดาห์นี้"
          tasks={tasksIncomplete.tasksCurrentWeek}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 3) setindex(3)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={4}
          topic="สัปดาห์หน้า"
          tasks={tasksIncomplete.tasksNextWeek}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 4) setindex(4)
            else setindex(-1)
          }}
        />
        <DisplayTasks
          index={5}
          topic="กำลังมาถึง"
          onTopicClick={() => {
            setIncomingWeek(incomingWeek + 1)
          }}
          tasks={tasksIncomplete.tasksIncoming}
          forceExpand={isindex}
          setObj={() => {
            if (isindex !== 5) setindex(5)
            else setindex(-1)
          }}
        />
      </TasksContainer>
    )
  }
  return (
    <TasksContainer>
      <DisplayTasks
        index={6}
        topic="งานที่ทำเสร็จแล้ว"
        tasks={tasks}
        status="complete"
        forceExpand={isindex}
        setObj={() => {
          if (isindex !== 6) setindex(6)
          else setindex(-1)
        }}
      />
    </TasksContainer>
  )
}

export const NewTaskComponent = ({
  viewpoint,
  tasks,
  tasksIncomplete,

  isindex,
  setIncomingWeek,
  incomingWeek,
}) => {
  return (
    <>
      <div style={viewpoint === 'incomplete' ? {} : { display: 'none' }}>
        <TasksContainer id="task__table">
          <NewDisplayTasks
            topic="เกินกำหนด"
            tasks={tasksIncomplete.tasksLated}
            status="expire"
            forceExpand={isindex === 1}
          />
          <NewDisplayTasks
            topic="วันนี้"
            tasks={tasksIncomplete.tasksCurrentDay}
            forceExpand={isindex === 2}
          />
          <NewDisplayTasks
            topic="สัปดาห์นี้"
            tasks={tasksIncomplete.tasksCurrentWeek}
            forceExpand={isindex === 3}
          />
          <NewDisplayTasks
            topic="สัปดาห์หน้า"
            tasks={tasksIncomplete.tasksNextWeek}
            forceExpand={isindex === 4}
          />
          <NewDisplayTasks
            topic="กำลังมาถึง"
            onTopicClick={() => {
              setIncomingWeek(incomingWeek + 1)
            }}
            tasks={tasksIncomplete.tasksIncoming}
            forceExpand={isindex === 5}
          />
        </TasksContainer>
      </div>
      <div style={viewpoint === 'incomplete' ? { display: 'none' } : {}}>
        <TasksContainer id="task__table">
          <NewDisplayTasks
            topic="งานที่ทำเสร็จแล้ว"
            tasks={tasks}
            status="complete"
            forceExpand={true}
          />
        </TasksContainer>
      </div>
    </>
  )
}
