import styled from '@emotion/styled'
import Select from 'react-select'
import { OptionType } from './SearchProduct.type'

export const Container = styled.div`
  width: 100%;
  max-width: 832px;
  height: 100%;
  margin: 24px auto;
  display: flex;
  flex-direction: column;
  .product_header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .product_header .sort_wrapper {
    display: flex;
    align-items: center;
  }
  .product_body .top {
    background-color: var(--agt-primary-color-1);
    color: var(--agt-white-color);
    text-align: right;
    padding: 4px 16px;
    margin-bottom: 8px;
  }
  label {
    margin-right: 16px;
  }
`

export const Selector = styled(Select)`
  border-radius: 4px;
  width: 220px;
  align-self: center;
  .css-yk16xz-control,
  .css-1pahdxg-control,
  .css-1fhf3k1-control {
    height: 48px;
  }
  .css-26l3qy-menu {
    z-index: 99;
  }
`

export const SortByOptions: Array<OptionType> = [
  { label: 'ราคาน้อย-มาก', value: 'priceAsc' },
  {
    label: 'ราคามาก-น้อย',
    value: 'priceDesc',
  },
]
