import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import Avatar from 'components/Avatar'
import { currency } from 'utils/helper'
import { dateFormat } from 'utils/helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

type ValueDisplayStatusProps = {
  status?: string
}
type PolicyStatusProps = {
  status: String
}
type ClosedCardType = {
  className?: string
  contactAvatar: string
  contactId: number
  contactName: string
  contactPhoneNumber: string
  contactEmail: string
  orders: Array<any>
  id: number
  name: string
  premium: number
  wonPremium?: number
  losePremium?: number
  status: string
  updatedAt: string
  date: string
  canEdit?: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  width: 100%;
  max-width: 930px;
  padding: 8px 0px;
  margin: 0 auto;
  border-bottom: 1px solid var(--agt-background-color-2);
  /* &:first-of-type {
    border-top: 1px solid var(--agt-secondary-color-1);
  } */
  .disabledLink {
    pointer-events: none;
  }
`
const ValuesDisplay = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`
const ValuesDisplayStatusAndPolicy = styled(ValuesDisplay)`
  width: 42%;
  border-right: 1px solid var(--agt-background-color-2);
`
const ValuesDisplayContactAndWrapper = styled(ValuesDisplay)`
  width: 35%;
  border-right: 1px solid var(--agt-background-color-2);
`
const ValuesDisplayPremium = styled.div`
  width: 23%;
  padding-left: 14px;
`
const ValuesDisplayDescription = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0px !important;
  .task-name {
    display: flex;
    align-items: center;
  }
  .date {
    font-size: 14px;
    color: var(--agt-secondary-text-color);
  }
  .order-no {
    font-size: 14px;
  }
  .opportunity-name {
    word-wrap: break-word;
    margin-bottom: 5px;
  }
`
const ValuesDisplayContact = styled.div`
  display: flex;
  width: 26.55%;
  justify-content: center;
  align-items: start;
`
const ValuesDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .desc {
    color: var(--agt-secondary-text-color);
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
`
const ValuesDisplaySummary = styled.div`
  display: flex;
  flex-direction: column;
  .header {
    color: var(--agt-primary-color-1);
    margin-bottom: 4px;
  }
  .premium {
    color: var(--agt-secondary-text-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
  }
`
const StyledAvatar = styled(Avatar)``
const ValueDisplayStatus = styled.p<ValueDisplayStatusProps>`
  color: var(--agt-white-color);
  background: ${(props) =>
    props.status === 'lose' ? 'var(--agt-error-color)' : 'var(--agt-primary-color-1)'};
  width: 88px;
  height: 16px;
  text-align: center;
  vertical-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  margin-right: 24px;
`
const PolicyDisplay = styled.div`
  font-size: 14px;
`
const PolicyDisplayDetail = styled.div`
  display: flex;
  flex-direction: row;
  .order-no {
  }
`
const PolicyStatus = styled.div<PolicyStatusProps>`
  margin-left: 8px;
  color: ${(props) =>
    props.status === 'กรมธรรม์อนุมัติ' || props.status === 'บริษัทประกันอนุมัติ'
      ? 'var(--agt-success-color)'
      : props.status === 'ยกเลิก' ||
        props.status === 'พนักงานยกเลิก' ||
        props.status === 'ผู้แนะนำยกเลิก'
      ? 'var(--agt-error-color)'
      : 'var(--agt-secondary-text-color)'};
`

const ClosedCard: FC<ClosedCardType> = (props) => {
  const { className = '' } = props
  return (
    <Container className={className}>
      <ValuesDisplayStatusAndPolicy>
        <ValuesDisplayDescription>
          <ValueDisplayStatus className="agt-small" status={props.status}>
            {props.status === 'won' ? 'ขายสำเร็จ' : 'ขายไม่สำเร็จ'}
          </ValueDisplayStatus>
          <div className="date">
            <FontAwesomeIcon icon={faClock} /> {dateFormat(props.updatedAt)}
          </div>
        </ValuesDisplayDescription>
        <ValuesDisplayDescription>
          <div className="opportunity-name">{props.name}</div>
          <PolicyDisplay>
            {props.orders.map((order, index) => (
              <PolicyDisplayDetail>
                <Link
                  to={`/opportunities/${props.id}/order-detail/${order.orderNo}/document_info`}
                  className=""
                >
                  <div className="order-no">รหัสการขาย #{order.orderNo}</div>
                </Link>
                <PolicyStatus status={order.status}>{order.status}</PolicyStatus>
              </PolicyDisplayDetail>
            ))}
          </PolicyDisplay>
        </ValuesDisplayDescription>
      </ValuesDisplayStatusAndPolicy>
      <ValuesDisplayContactAndWrapper>
        <ValuesDisplayContact>
          <StyledAvatar type="circle" width="48px" height="48px" avatar={props.contactAvatar} />
        </ValuesDisplayContact>
        <ValuesDisplayWrapper>
          <div style={{ marginBottom: '4px' }}>
            <Link
              className={props?.canEdit ? 'ghost' : 'disabledLink ghost'}
              to={`/contacts/${props.contactId}`}
            >
              {props.contactName}
            </Link>
          </div>
          <div className="desc">{props.contactPhoneNumber}</div>
          <div className="desc">{props.contactEmail}</div>
        </ValuesDisplayWrapper>
      </ValuesDisplayContactAndWrapper>
      <ValuesDisplayPremium>
        <ValuesDisplaySummary>
          <div className="header">สรุปการขาย</div>
          <div className="premium">
            <div>สำเร็จ</div>
            <div>฿&nbsp;{currency(props.wonPremium, 0, '')}</div>
          </div>
          <div className="premium">
            <div>ไม่สำเร็จ</div>
            <div>฿&nbsp;{currency(props.losePremium, 0, '')}</div>
          </div>
        </ValuesDisplaySummary>
      </ValuesDisplayPremium>
    </Container>
  )
}

export default ClosedCard
