import React, { useState, useEffect, useCallback } from 'react'
import api from 'api'
import { useDropzone } from 'react-dropzone'
import { CustomIconSM, CustomIconType, StatusUpload } from './UploadFile.style'

const createAttachment = async (file: File, callback: (id: string) => void) => {
  try {
    const response = await api.post('/attachments', { image: file }, { isFormData: true })
    callback(response.data.data.attachment.id)
  } catch (err) {
    if (err && err.response) {
      console.error(err.response)
    }
    alert('เกิดข้อผิดพลาดบางอย่าง')
    throw err
  }
}

const getFileType = (file) => {
  if (file) {
    let type: string = ''
    switch (file.type) {
      case 'image/jpeg':
        type = 'JPG'
        break
      case 'image/png':
        type = 'PNG'
        break
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        type = 'DOC'
        break
      case 'application/pdf':
        type = 'PDF'
        break
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.slideshow':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        type = 'PPT'
        break
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = 'XLX'
        break
      case 'text/plain':
        type = 'TXT'
        break
      default:
        break
    }
    return type
  }
  return ''
}

const UploadFile = (props) => {
  const {
    status = null,
    onChange = () => {},
    options = {},
    small = false,
    selectType = ['PDF', 'JPG', 'JPEG', 'PNG'],
  } = props
  const [previewFile, setFile] = useState<File | null>()
  const [fileType, setFileType] = useState<string>('')
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      createAttachment(file, (value) => {
        setFile(file)
        onChange(value)
      })
    },
    [onChange],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...options,
  })
  const clearFile = useCallback(() => {
    setFile(null)
    onChange(null)
  }, [setFile, onChange])
  useEffect(() => {
    if (previewFile) {
      let fileType: string = getFileType(previewFile)
      if (selectType.find((type: string) => type === fileType)) {
        setFileType(fileType)
      } else {
        let mapTypes: string = ''
        mapTypes = selectType.join(', ')
        alert(`รองรับไฟล์ ${mapTypes} เท่านั้น`)
        clearFile()
      }
    }
  }, [previewFile, selectType, clearFile])
  return (
    <div className="uploadbtn">
      <button
        className="secondary"
        type="button"
        disabled={!!previewFile || status === 'processing' || status === 'approved'}
        {...getRootProps({})}
      >
        {small ? 'อัปโหลด' : 'อัปโหลดเอกสาร'}
        <input {...getInputProps()} />
      </button>
      <StatusUpload small={small}>
        {previewFile ? (
          <div className={`file ${fileType}`}>
            <div className="type upload__value-type">{fileType}</div>
            <div className="text">{previewFile.name}</div>
            <div onClick={clearFile} style={{ cursor: 'pointer' }}>
              <CustomIconType name="cross" color="#000000" />
            </div>
          </div>
        ) : status === 'processing' ? (
          <div className="button waiting">
            <CustomIconSM name="glass" color={'var(--agt-white-color)'} />
            <small className="">
              สำเนาเอกสารสำคัญ
              <br />
              อยู่ระหว่างการตรวจสอบ
            </small>
          </div>
        ) : status === 'approved' ? (
          <div className="button verified">
            <CustomIconSM name="correct-outline" color={'var(--agt-white-color)'} />
            <small className="">
              เอกสารการสมัคร
              <br />
              ได้รับการยืนยันแล้ว
            </small>
          </div>
        ) : status === 'rejected' ? (
          <div className="button banned">
            <CustomIconSM name="banned" color={'var(--agt-white-color)'} />
            <small className="">
              เอกสารการสมัคร
              <br />
              ไม่ผ่านการตรวจสอบ
            </small>
          </div>
        ) : status === 'expired' ? (
          <div className="button banned">
            <CustomIconSM name="banned" color={'var(--agt-white-color)'} />
            <small className="">เอกสารใบอนุญาตหมดอายุ</small>
          </div>
        ) : (
          <div className="button blank">
            <CustomIconSM name="warning" color={'var(--agt-secondary-color-1)'} />
            {small ? (
              <small className="">
                รอแนบเอกสาร
                <br />
                สมัครตัวแทน
              </small>
            ) : (
              <small className="">รอแนบเอกสารสมัครตัวแทน</small>
            )}
          </div>
        )}
      </StatusUpload>
    </div>
  )
}

export default UploadFile
