import React, { FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getConsentByContact,
  getConsentDetail,
  getHistoriesConsentsByAgent,
} from 'store/consentsSlice'
import Banner from '../assets/images/agent_portal.png'
import ReactModal from 'react-modal'
import { ModalCustomStyle } from 'components/Modal'
import { RootState } from 'store/rootReducer'
import { ShowHistoriesConsentType, consentsType } from './ShowHistoriesConsent.type'
import { Container, CustomModal } from './ShowHistoriesConsent.styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import get from 'lodash/get'

const ShowHistoriesConsent: FC<ShowHistoriesConsentType> = ({
  contactId,
  className,
  consents,
  type,
}) => {
  const [isOpenConsent, setIsOpenConsent] = useState<boolean>(false)
  const dispatch = useDispatch()
  const consentUrl = useSelector((state: RootState) => state.consents?.consentUrl || null)
  const agreementContact = useSelector((state: RootState) => state.consents?.agreement || null)
  const agreementAgent = useSelector((state: RootState) => state.consents?.agreement)

  useEffect(() => {
    if (contactId && consentUrl) {
      let searchConsentUrl = consentUrl.split('?')[1].split('&')
      let keyValues = { agreement_id: '', contact_id: '', jwt_contact_token: '' }
      searchConsentUrl.forEach((keySearch) => {
        let val = keySearch.split('=')
        keyValues[val[0]] = val[1]
      })
      dispatch(getConsentDetail(keyValues, 'contact'))
    }
  }, [dispatch, consentUrl, contactId])

  return (
    <Container className={className}>
      {type === 'contact' && agreementContact && (
        <ReactModal isOpen={isOpenConsent} style={ModalCustomStyle}>
          <CustomModal>
            <header>
              <img alt="agentProtal" src={Banner} />
            </header>
            <div className="content">
              <div className="name">
                {agreementContact?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
              </div>
              <p
                className="consents"
                dangerouslySetInnerHTML={{
                  __html: agreementContact?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
                }}
              />
              {agreementAgent?.consents && agreementAgent.consents.length > 0 && (
                <ul>
                  {agreementAgent.consents.map((e) => (
                    <li>
                      <label className="checked">
                        <input type="checkbox" checked={get(e, 'forcedConsent', false)} disabled />
                        <div className="square">
                          <FontAwesomeIcon icon={faCheck} className="faCheck" />
                        </div>
                        {get(e, 'name', '')}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
              <button type="button" onClick={() => setIsOpenConsent(false)}>
                ตกลง
              </button>
            </div>
          </CustomModal>
        </ReactModal>
      )}
      {type === 'setting' && agreementAgent && (
        <ReactModal isOpen={isOpenConsent} style={ModalCustomStyle}>
          <CustomModal>
            <header>
              <img alt="agentProtal" src={Banner} />
            </header>
            <div className="content">
              <div className="name">
                {agreementAgent?.name || 'นโยบายการเก็บรวบรวมและเปิดเผยข้อมูลส่วนบุคคล'}
              </div>
              <p
                className="consents"
                dangerouslySetInnerHTML={{
                  __html: agreementAgent?.detail || 'ยังไม่มีการระบุเนื้อหาใดๆ',
                }}
              />
              {agreementAgent?.consents && agreementAgent.consents.length > 0 && (
                <ul>
                  {agreementAgent.consents.map((e) => (
                    <li>
                      <label className="checked">
                        <input type="checkbox" checked={get(e, 'forcedConsent', false)} disabled />
                        <div className="square">
                          <FontAwesomeIcon icon={faCheck} className="faCheck" />
                        </div>
                        {get(e, 'name', '')}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
              <button type="button" onClick={() => setIsOpenConsent(false)}>
                ตกลง
              </button>
            </div>
          </CustomModal>
        </ReactModal>
      )}
      <section>
        {consents.map((consent: consentsType, index: number) => (
          <div className="consents">
            <p
              key={index}
              onClick={() => {
                if (type === 'contact') {
                  dispatch(
                    getConsentByContact(consent.id, () => {
                      setIsOpenConsent(true)
                    }),
                  )
                } else if (type === 'setting') {
                  dispatch(
                    getHistoriesConsentsByAgent(consent.id, () => {
                      setIsOpenConsent(true)
                    }),
                  )
                }
              }}
            >
              {consent.name}
            </p>
            {consent?.version && <small>{consent.version}</small>}
          </div>
        ))}
      </section>
    </Container>
  )
}

export default ShowHistoriesConsent
