import React, { FC, useState } from 'react'
import api from 'api'
import { TaskRendererType, TaskType } from './Tasks.type'
import {
  TaskContainer,
  TaskHeaderHist,
  TaskHeader,
  IconStyle,
  Detail,
  DetailRow,
  CheckboxHistory,
  VerticalLine,
  CheckboxContainer,
  CheckboxWrapper,
  TopicContainer,
  LinkTab,
  Description,
  TopicDate,
  Clock,
  StarContainer,
} from './Tasks.styles'

export const toggleStatus = async (id: number, callback: () => void) => {
  if (id === undefined) {
    alert('Undefind task id')
  }
  try {
    await api.patch(`/tasks/${id}/toggle_status`, {})
    callback()
  } catch (err) {
    if (err && err.response) {
      alert(err.response)
    }
    throw err
  }
}

export const TaskRenderer: FC<TaskRendererType> = (props) => {
  const { header, type, data, forceExpand = false, currentAgent } = props
  const [isExpanded, setExpand] = useState<boolean>(forceExpand)
  const [tasks, setTasks] = useState<TaskType[]>(data)
  const toggleExpand = () => setExpand(!isExpanded)
  const showMax = type === 'history' ? 6 : 4
  return (
    <TaskContainer>
      {type === 'history' ? (
        <TaskHeaderHist>
          <span>{header}</span>
        </TaskHeaderHist>
      ) : (
        <TaskHeader onClick={toggleExpand}>
          <span
            style={
              header === 'เกินกำหนด' && tasks.length > 0 ? { color: 'var(--agt-error-color)' } : {}
            }
          >
            {header} ({tasks.length})
          </span>
          <IconStyle name={isExpanded ? 'vector_top' : 'vector_bottom'} />
        </TaskHeader>
      )}
      {isExpanded && (
        <Detail isMore={tasks.length > showMax} isHistory={type === 'history'}>
          {tasks.map((d: TaskType, index: number) => {
            return (
              <DetailRow key={index} isHistory={type === 'history'}>
                {type === 'history' ? (
                  <>
                    <CheckboxHistory>
                      <IconStyle name="check" />
                    </CheckboxHistory>
                    {index < tasks.length - 1 && <VerticalLine />}
                  </>
                ) : (
                  <CheckboxContainer>
                    {currentAgent?.statusTh === 'ระงับ' ? (
                      <div className="disabledCheckbox" />
                    ) : (
                      <CheckboxWrapper>
                        <div className="round">
                          <input
                            type="checkbox"
                            id={`checkoux_task_${d.id}`}
                            onChange={() =>
                              toggleStatus(d.id, () => {
                                setTimeout(function () {
                                  setTasks([])
                                  setTasks(
                                    tasks.filter(function (obj) {
                                      return obj.id !== d.id
                                    }),
                                  )
                                }, 500)
                              })
                            }
                          />
                          <label htmlFor={`checkoux_task_${d.id}`}></label>
                        </div>
                      </CheckboxWrapper>
                    )}
                  </CheckboxContainer>
                )}
                <TopicContainer>
                  <LinkTab to={`/tasks/${d.id}`} style={{ color: 'var(--agt-primary-text-color)' }}>
                    <div className="name">{d.name}</div>
                  </LinkTab>
                  {d.description ? <Description>{d.description}</Description> : <br />}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TopicDate className={type === 'overdue' ? 'error' : 'success'}>
                      <Clock
                        name="clock"
                        color={
                          type === 'overdue'
                            ? 'var(--agt-error-color)'
                            : 'var(--agt-secondary-color-2)'
                        }
                      />
                      <label
                        className="overdue"
                        style={
                          type === 'overdue'
                            ? { color: 'var(--agt-error-color)' }
                            : { color: 'var(--agt-secondary-color-2)' }
                        }
                      >
                        {new Date(d.dueDate).toLocaleDateString('th-TH')}
                      </label>
                    </TopicDate>
                    <StarContainer>
                      {Array.from(Array(d.priorityLevel).keys()).map((s, i) => (
                        <IconStyle name="star" key={i} color="black" />
                      ))}
                    </StarContainer>
                  </div>
                </TopicContainer>
              </DetailRow>
            )
          })}
        </Detail>
      )}
    </TaskContainer>
  )
}
