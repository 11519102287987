import EmptyDoughnut from 'assets/images/no_data_doughnut.png'
import Icon from 'components/Icon'
import React, { Fragment, useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { Link } from 'routes'
import authorize, { UnauthorizedAction } from 'utils/authorize'
import {
  BottomDashBoard,
  ChartDiv,
  ChartMainMonth,
  ChartMainQuarter,
  ChartMainYear,
  ColumnData,
  DoughnutNoChartImage,
  HeaderMonthDiv,
  HeaderQuarterDiv,
  HeaderYearDiv,
  IconStyle,
  LeftDashBoardDiv,
  MiniTitterDashBoard,
  RightDashBoardDiv,
  TitterDashBoard,
  ToggleSwitch,
  TopDashBoard,
} from './LandingPremiumCard.styles'
import { ToggleComponentType } from './LandingPremiumCard.type'
import { Nochart, dataA } from './LandingPremiumCard.utils'

export const DoughnutMonthChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainMonth>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainMonth>
  )
}

export const DoughnutQuarterChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainQuarter>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainQuarter>
  )
}

export const DoughnutYearChart = ({ rawData }) => {
  const [formatData, setFormatData] = useState(dataA)

  useEffect(() => {
    if (rawData) {
      let checkData = rawData.every((x) => x.value === 0)

      if (checkData) {
        let data = {
          datasets: [
            {
              data: Nochart.a.map((key) => key.value),

              backgroundColor: ['#F9F6F6', '#ECECEC'],
              hoverBorderColor: '#FFFFFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      } else {
        let data = {
          datasets: [
            {
              data: rawData.map((key) => key.value),

              backgroundColor: ['#00529A', '#45DAAE', '#4881FF', '#7E5FFF'],
              hoverBorderColor: '#7E5FFF',
              hoverBorderWidth: 1,
              borderWidth: 0,
            },
          ],
        }
        setFormatData(data)
      }
    }
  }, [rawData])

  return (
    <ChartMainYear>
      <Doughnut
        data={formatData}
        options={{
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              display: false,
            },
          },
        }}
      />
    </ChartMainYear>
  )
}

export const DoughnutNoChart = () => {
  return <DoughnutNoChartImage src={EmptyDoughnut} alt="for no data" />
}

export const DashboardNoValue = () => {
  return (
    <Fragment>
      <TopDashBoard>
        <LeftDashBoardDiv>
          <TitterDashBoard>
            <p className="Title">ภาพรวมเบี้ยประกัน</p>
          </TitterDashBoard>
          <MiniTitterDashBoard>
            <p className="MiniTitle">ยอดที่ขายได้</p>
          </MiniTitterDashBoard>
        </LeftDashBoardDiv>
        <RightDashBoardDiv>
          <ChartDiv>
            <HeaderMonthDiv>
              <p className="MonthTitle">รายเดือน</p>
            </HeaderMonthDiv>
            <DoughnutNoChart />
          </ChartDiv>
          <ChartDiv>
            <HeaderQuarterDiv>
              <p className="QuarterTitle">รายไตรมาส</p>
            </HeaderQuarterDiv>
            <DoughnutNoChart />
          </ChartDiv>
          <ChartDiv>
            <HeaderYearDiv>
              <p className="YearTitle">รายปี</p>
            </HeaderYearDiv>
            <DoughnutNoChart />
          </ChartDiv>
        </RightDashBoardDiv>
      </TopDashBoard>
      <BottomDashBoard>
        <ColumnData color={'#00529A'}>
          <IconStyle name="Life" />
          <div className="Header">ชีวิต</div>
          <div className="ValueLife">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#45DAAE'}>
          <IconStyle name="health" />
          <div className="Header">สุขภาพ</div>
          <div className="ValueHealth">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#3ABAF8'}>
          <IconStyle name="car" />
          <div className="Header">รถยนต์</div>
          <div className="ValueCar">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <ColumnData color={'#7E5FFF'} style={{ border: 'unset' }}>
          <IconStyle name="Fire" />
          <div className="Header">อัคคีภัย</div>
          <div className="ValueFire">-</div>
          <div className="Values">-</div>
          <div className="Values">-</div>
        </ColumnData>
        <div className="LinkNextPage">
          <Link to="/dashboard" className="LinkText">
            ดูเพิ่มเติม
          </Link>

          <Link to="/dashboard" className="LinkText">
            <Icon name="Arrow_dashboard" />
          </Link>
        </div>
      </BottomDashBoard>
    </Fragment>
  )
}

export const ToggleComponent = ({ isToggle, switchToggle }: ToggleComponentType) => {
  return (
    <ToggleSwitch>
      <input type="checkbox" checked={isToggle} />
      <span className="slider round" onClick={switchToggle}></span>
    </ToggleSwitch>
  )
}

export const TogglePremium = authorize(
  (props: ToggleComponentType) => {
    const { isToggle, switchToggle } = props
    return (
      <MiniTitterDashBoard>
        <ToggleComponent isToggle={isToggle} switchToggle={switchToggle} />
        <label className={`MiniTitle ${isToggle ? '' : 'MiniTitleSub'}`} onClick={switchToggle}>
          {isToggle ? (
            <>ยอดที่ขายได้</>
          ) : (
            <>
              ยอดที่แนะนำ
              <br />
              ได้สำเร็จ
            </>
          )}
        </label>
      </MiniTitterDashBoard>
    )
  },
  {
    roles: ['tqm_staff', 'broker'],
    params: { unauthorizedAction: UnauthorizedAction.HIDDEN },
  },
)

export const SuggestPremium = authorize(
  () => {
    return (
      <MiniTitterDashBoard>
        <label className={`MiniTitle `}>
          <>
            ยอดที่แนะนำ
            <br />
            ได้สำเร็จ
          </>
        </label>
      </MiniTitterDashBoard>
    )
  },
  { roles: ['affiliate'], params: { unauthorizedAction: UnauthorizedAction.HIDDEN } },
)
