import Modal from 'react-modal'
import styled from '@emotion/styled'
import React, { FC, useEffect, useMemo } from 'react'
import { useParams, Link } from 'routes'
import Icon from 'components/Icon'

export const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}

const ChoiceButton = styled.div`
  display: flex;
  width: 100%;
`

const SubmitButton = styled.button`
  width: 352px;
  height: 56px;
  background-color: var(--agt-primary-color-1);
  border-radius: 0;
`

const Title = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--agt-primary-color-1);
`

const Content = styled.div`
  margin-top: 12px;
  margin-bottom: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

type ModalProps = {
  isOpen: boolean
  onCancel: Function
  onConfirm: Function
  state?: string
}

const IconStyles = styled(Icon)`
  width: 72px;
  height: 72px;
`

var timeOutModal: any = []

function clearTimeoutModal() {
  for (var i = 0; i < timeOutModal.length; i++) {
    clearTimeout(timeOutModal[i])
  }
}

const ModalContainer: FC<ModalProps> = (props) => {
  const { id }: any = useParams()
  const path = `/contacts/${id}/planning`
  const { state = '', isOpen, onConfirm } = props

  useEffect(() => {
    if (isOpen && state !== 'register') {
      timeOutModal.push(
        setTimeout(() => {
          onConfirm()
          window.location.href = path
        }, 3000),
      )
    }
  }, [isOpen, state, path, onConfirm])

  const title = useMemo(() => {
    switch (state) {
      case 'personal_info':
        return 'บันทึกข้อมูลส่วนตัวแล้ว'
      case 'financial_info':
        return 'บันทึกข้อมูลทางการเงินแล้ว'
      case 'register':
        return 'ส่งอีเมลเรียบร้อยแล้ว'
      default:
        return 'บันทึกข้อมูลแล้ว'
    }
  }, [state])

  const link = useMemo(() => {
    switch (state) {
      case 'personal_info':
        return { pathname: path, state: 'personal_info' }
      case 'financial_info':
        return { pathname: path, state: 'financial_info' }
      case 'register':
        return
      default:
        return { pathname: path, state: 'planning' }
    }
  }, [state, path])

  const buttonText = useMemo(() => {
    switch (state) {
      case 'personal_info':
      case 'financial_info':
      case 'register':
        return 'ตกลง'
      default:
        return 'กลับไปหน้าวางแผนประกัน'
    }
  }, [state])

  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <Title>{title}</Title>
      <Content>
        <IconStyles name="Correct" />
      </Content>
      <ChoiceButton>
        {link && state !== 'register' ? (
          <Link to={link} className="ghost">
            <SubmitButton
              onClick={() => {
                clearTimeoutModal()
                onConfirm()
              }}
            >
              {buttonText}
            </SubmitButton>
          </Link>
        ) : (
          <SubmitButton
            onClick={() => {
              clearTimeoutModal()
              onConfirm()
            }}
          >
            {buttonText}
          </SubmitButton>
        )}
      </ChoiceButton>
    </Modal>
  )
}
export default ModalContainer
