import styled from '@emotion/styled'
import Select from 'react-select'
import Icon from 'components/Icon'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-radius: 0px;
  .isLoading {
    text-align: center;
  }
`

export const SelectCustom = styled.div`
  margin: 24px 0px;
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  .total {
    color: var(--agt-primary-color-1);
    margin-right: 16px;
  }
  .buttomLink {
    margin-right: 16px;
  }
  .buttomLink button {
    padding: 0;
  }
  .consentBtn {
    width: 240px;
    padding: 0 12px;
    background: var(--agt-white-color);
    border: 1px solid var(--agt-primary-color-1);
    display: flex;
    align-items: center;
  }
  .consentBtn label {
    margin-left: 6px;
    color: var(--agt-primary-color-1);
    cursor: pointer;
  }
  .consentBtn.onClick {
    background: var(--agt-light-sky-color);
  }
  .consentBtn.onClick label {
    color: var(--agt-primary-color-1);
  }
  .consentBtn:hover {
    svg path {
      fill: var(--agt-dark-sky-color);
    }
  }
`

export const SearchCustom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 106px;
  padding: 0 46px;
  background: var(--agt-background-color-4);
`

export const Header = styled.div`
  display: flex;
  column-gap: 24px;
  h3 {
    color: var(--agt-primary-color-1);
  }
  .hint {
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    font-size: 17px;
    color: var(--agt-primary-color-1);
  }
`

export const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

export const ConsentCustom = styled(Icon)`
  width: 32px;
  height: 32px;
  &:hover {
    svg path {
      fill: var(--agt-dark-sky-color);
    }
  }
`

export const InputCustom = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`

export const Input = styled.input`
  width: 448px;
  border-radius: 5px 0px 0px 5px !important;
`

export const SearchButton = styled.button`
  height: 48px;
  width: 97px;
  padding: 0px;
  color: var(--agt-primary-text-color);
  background: var(--agt-background-color-2);
  border: 1px solid #a0a8bc;
  border-radius: 0px 5px 5px 0px;
  &:hover {
    cursor: pointer;
    background: #d8d9e0;
    border: 1px solid #7d828e;
  }
`

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
  padding-top: 10px;
  height: 88px;
  position: relative;
  .warning {
    width: 16px;
    height: 16px;
    background: var(--agt-white-color);
    position: absolute;
    top: 8px;
    left: -4px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .warning .circle {
    width: 12px;
    height: 12px;
    background: var(--agt-error-color);
    border-radius: 50%;
  }
  .warning .icon {
    position: absolute;
    font-size: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--agt-white-color);
  }
`

export const NameAndOccupation = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 14px;
  .name,
  .occupation {
    width: 165px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 2px;
  }
`

export const ResponsiveDiv = styled.div`
  .status {
    width: 175px;
  }
  .opportunity {
    padding-top: 12px;
    width: 180px;
    padding-right: 16px;
  }
  .event,
  .task {
    padding-top: 12px;
    width: 180px;
    padding-right: 16px;
  }
  @media (max-width: 1180px) {
    .status {
      width: 155px;
    }
    .opportunity,
    .event,
    .task {
      width: 155px;
      padding-right: 8px;
    }
  }
`

export const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    background: 'var(--agt-white-color)',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}

export const customSelectDisabledStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-background-color-1)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: () => ({ color: 'var(--agt-primary-text-color)' }),
}
export const Selector = styled(Select)`
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
