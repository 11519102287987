import React, { FC } from 'react'
import { Route, Switch } from 'routes'
import Branches from './Branches'

const Pages: FC = (props) => (
  <Switch>
    <Route path="/" component={Branches} />
  </Switch>
)

export default Pages
