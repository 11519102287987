import styled from '@emotion/styled'
import React from 'react'
import { Caption, InputAndUnitContainer, Unit } from './Input'

const MainLayout = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  width: 100%;
`

type genderContainer = {
  disabled: boolean
}
const Gender = styled.div`
  display: flex;
  height: 48px;
  width: 214px;

  .male {
    border-radius: 4px 0px 0px 4px;
  }
  .female {
    border-radius: 0px 4px 4px 0px;
  }
`
const GenderButton = styled.div<genderContainer>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 50%;
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: #ffffff;
  border: 1px solid var(--agt-secondary-color-1);
  text-align: center;

  &.selected {
    background-color: ${(props) =>
      props.disabled ? 'var(--agt-background-color-2)' : 'var(--agt-light-sky-color)'};
    border: ${(props) => (props.disabled ? '' : '1px solid var(--agt-tqm-pantone-1)')};
    color: ${(props) =>
      props.disabled ? 'var(--agt-primary-text-color)' : 'var(--agt-tqm-pantone-1)'};
  }
`
const GenderComponent = (props) => {
  const isSelected = (v: 'M' | 'F') => (v === props.field.value ? 'selected' : '')

  return (
    <MainLayout className={props.className}>
      {props.caption && <Caption>{props.caption}</Caption>}
      <InputAndUnitContainer>
        <Gender>
          <GenderButton
            onClick={() => props.form.setFieldValue(props.field.name, 'M')}
            className={`male ${isSelected('M')}`}
            disabled={props.disabled}
          >
            <p>ชาย</p>
          </GenderButton>
          <GenderButton
            onClick={() => props.form.setFieldValue(props.field.name, 'F')}
            className={`female ${isSelected('F')}`}
            disabled={props.disabled}
          >
            <p>หญิง</p>
          </GenderButton>
        </Gender>
        <Unit />
      </InputAndUnitContainer>
    </MainLayout>
  )
}

export default GenderComponent
