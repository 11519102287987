import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { get } from 'lodash'
import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import Loader from 'components/BackgroundLoading'
import InsuranceDetail from '../InsuranceDetail'
import ShowDetail from './ShowDetail'
import { ParamsType } from './Detail.type'
import { getOrder, getSearchByTypeTa, getTravelOptions } from 'store/travelInsuranceSlice'
import { Container, Header, Content } from './Detail.styles'

const EditTravel: FC = () => {
  let { id, order_id: orderId } = useParams<ParamsType>()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { optionsByTypeTa, loading, options, order } = useSelector(
    (state: RootState) => state.travel,
  )
  const dispatch = useDispatch()

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { label: 'รายละเอียดคำสั่งซื้อ' },
  ]

  const insuranceData = useMemo(() => {
    if (order) {
      return { ...order.order.taInsurance.productDetails }
    } else {
      return {
        companyLogoUrl: '',
        companyName: '',
        netAmount: '',
        minAge: '',
        maxAge: '',
        coverDetail: null,
        insureLevel: '',
        travelTypeCountry: '',
      }
    }
  }, [order])

  useEffect(() => {
    if (id && orderId) {
      dispatch(getNewOpportunityById(id))
      dispatch(getOrder(id, orderId))
      dispatch(getTravelOptions(id))
    }
  }, [dispatch, id, orderId])

  useEffect(() => {
    let travelType = order?.order?.taInsurance?.travelType || null
    let travelTypeCountry = order?.order?.taInsurance?.travelTypeCountry || null
    if (id && travelType && travelTypeCountry) {
      dispatch(
        getSearchByTypeTa(id, {
          travelType: travelType,
          travelTypeCountry: travelTypeCountry,
        }),
      )
    }
  }, [
    dispatch,
    id,
    order?.order?.taInsurance?.travelType,
    order?.order?.taInsurance?.travelTypeCountry,
  ])

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {(loading.options || loading.search) && <Loader />}
      <Content>
        <InsuranceDetail data={insuranceData} />
        <ShowDetail order={order} options={options} optionsByTypeTa={optionsByTypeTa} />
      </Content>
    </Container>
  )
}

export default EditTravel
