import { combineReducers } from '@reduxjs/toolkit'

import appReducer from 'store/appSlice'
import contactsReducer from 'store/contactSlice'
import tasksReducer from 'store/taskSlice'
import notificationReducer from 'store/notificationSlice'
import planningReducer from 'store/planningSlice'
import profileReducer from 'store/profileSlice'
import dashboardReducer from 'store/dashboardSlice'
import announcementsReducer from 'store/announcementsSlice'
import consentsReducer from 'store/consentsSlice'
import opportunitiesReducer from 'store/opportunitiesSlice'
import motorReducer from 'store/motorInsuranceSlice'
import eventsReducer from 'store/eventsSlice'
import commissionsReducer from 'store/commissionsSlice'
import actReducer from 'store/actSlice'
import travelReducer from 'store/travelInsuranceSlice'

const rootReducer = combineReducers({
  app: appReducer,
  contacts: contactsReducer,
  tasks: tasksReducer,
  events: eventsReducer,
  notifications: notificationReducer,
  plans: planningReducer,
  profile: profileReducer,
  dashboard: dashboardReducer,
  announcements: announcementsReducer,
  consents: consentsReducer,
  opportunities: opportunitiesReducer,
  motor: motorReducer,
  commissions: commissionsReducer,
  act: actReducer,
  travel: travelReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
