import React, { FC, useMemo, useEffect } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import Icon from 'components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { css } from '@emotion/core'
import { useHistory } from 'routes'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'store/rootReducer'
import get from 'lodash/get'
import { useParams } from 'routes'
import { getContactById } from 'store/contactSlice'
import { getPersonalInformation } from 'store/planningSlice'
import { go2Top } from 'utils/helper'
import HistoryType from 'types/HistoryType'

type useParamsType = { id: string }
type PlanningBoxStyleProps = {
  disabled?: boolean
}

const Container = styled.div`
  justify-content: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  padding-top: 37px;
`

const PlanningBox = styled.div<PlanningBoxStyleProps>`
  width: 256px;
  height: 120px;
  border-radius: 8px;
  margin-left: 32px;
  margin-bottom: 24px;
  padding: 13px;
  background: ${(props) => (props.disabled ? '#D8D9E0' : '#00529a')};
  color: ${(props) => (props.disabled ? '#ACB1BF' : '#FFFFFF')};
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      > span {
        filter: grayscale(0.9);
        opacity: 0.5;
      }
    `}
`

const PlanningText = styled.div`
  position: absolute;
  bottom: 11px;
  left: 13px;
  width: 194px;
  white-space: pre-line;
`

const ComimgSoonText = styled.div`
  position: absolute;
  top: 11px;
  left: 16px;
  width: 194px;
  white-space: pre-line;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #acb1bf;
`

const SummaryBox = styled.div<PlanningBoxStyleProps>`
  width: 544px;
  height: 120px;
  border-radius: 8px;
  margin-left: 32px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => (props.disabled ? '#D8D9E0' : 'var(--agt-background-color-3)')};
  color: ${(props) => (props.disabled ? '#ACB1BF' : 'var(--agt-primary-color-1)')};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const CustomIcon = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 13px;
  width: 62px;
  height: 62px;
  > div {
    > div {
      > svg {
        width: 62px;
        height: 62px;
      }
    }
  }
`

const CustomFontAwesome = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 19px;
  right: 13px;
  width: 16px;
  height: 16px;
`

function checkPlanning(plan: {}) {
  let step: boolean = false
  if (get(plan, 'investmentRisk')) {
    step = true
  }
  return step
}

type PlanningProp = {
  disabled: boolean
  text: string
  url: string
  name: string
  haveData: boolean
}
const Planning: FC<PlanningProp> = (props) => {
  let history = useHistory()

  const url = history.location.pathname + props.url
  return (
    <Link to={url} className={`ghost ${props.disabled ? 'disabled' : ''}`}>
      <PlanningBox disabled={props.disabled}>
        {props.disabled && <ComimgSoonText>เร็วๆนี้...</ComimgSoonText>}
        <CustomIcon name={props.name} />
        {!props.disabled && props.haveData && (
          <CustomFontAwesome icon={faPencilAlt} color="var(--agt-tqm-pantone-2)" />
        )}
        <PlanningText>{props.text}</PlanningText>
      </PlanningBox>
    </Link>
  )
}

const Pages: FC = (props) => {
  const dispatch = useDispatch()
  const { id } = useParams<useParamsType>()
  let history: HistoryType = useHistory()
  const plan = useSelector((state: RootState) => state.plans.plan)
  const haveSomePlan = useMemo(() => {
    return (
      get(plan, 'hasDebtProtections', false) ||
      get(plan, 'hasIncomeProtections', false) ||
      get(plan, 'hasHealthcareProtections', false) ||
      get(plan, 'hasNonLifeProtections', false)
    )
  }, [plan])

  useEffect(() => {
    go2Top()
    dispatch(getPersonalInformation(id))
    dispatch(getContactById(id))
  }, [id, dispatch])

  return (
    <Container>
      <Planning
        disabled={checkPlanning(plan) ? false : true}
        text={'วางแผนคุ้มครอง\nภาระที่อาจจะตกถึงครอบครัว'}
        url="/debt_protection"
        name="debt_menu"
        haveData={get(plan, 'hasDebtProtections', false)}
      />
      <Planning
        disabled={checkPlanning(plan) ? false : true}
        text={'วางแผนคุ้มครองแหล่งรายได้'}
        url="/income_protection"
        name="income_menu"
        haveData={get(plan, 'hasIncomeProtections', false)}
      />
      <Planning
        disabled={checkPlanning(plan) ? false : true}
        text={'วางแผนคุ้มครอง\nค่าใช้จ่ายการรักษาพยาบาล'}
        url="/healthcare_coverage"
        name="healthcare_menu"
        haveData={get(plan, 'hasHealthcareProtections', false)}
      />
      <Planning
        disabled
        text={'วางแผนกระแสเงินสด\nสำหรับการเกษียณ'}
        url="/retirement_income"
        name="retirement_income_menu"
        haveData={false}
      />
      <Planning
        disabled
        text={'วางแผนคุ้มครอง\nค่าใช้จ่ายการศึกษาบุตร'}
        url="/child_education_protection"
        name="child_education_menu"
        haveData={false}
      />
      <Planning
        disabled
        text={'วางแผนเก็บออม\nพร้อมความคุ้มครอง'}
        url="/savings"
        name="savings_menu"
        haveData={false}
      />
      <Planning
        disabled={checkPlanning(plan) ? false : true}
        text={'วางแผนคุ้มครอง\nความเสี่ยงภัยอื่น ๆ'}
        url="/non_life"
        name="non_life_menu"
        haveData={get(plan, 'hasNonLifeProtections', false)}
      />
      <Link
        to={`${history.location.pathname}/summary`}
        className={`ghost ${!haveSomePlan ? 'disabled' : ''}`}
      >
        <SummaryBox disabled={!haveSomePlan}>สรุปการวางแผนการจัดการความเสี่ยง</SummaryBox>
      </Link>
    </Container>
  )
}

export default Pages
