import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams, Link } from 'routes'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { get } from 'lodash'

import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Icon from 'components/Icon'
import HistoryType from 'types/HistoryType'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'

type SearchType = {
  id: string
}
type PlanningBoxStyleProps = {
  disabled?: boolean
}
type PlanningProp = {
  disabled: boolean
  text: string
  url: string
  name: string
  className?: string
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const BreadCrumbStyle = styled(BreadCrumb)`
  border-bottom: 1px solid var(--agt-secondary-color-1);
  padding-bottom: 16px;
`
const PlanningHeader = styled.p`
  font-size: 17px;
  text-align: center;
  padding: 40px 0;
`
const PlanningContainer = styled.div`
  display: grid;
  grid-template-areas: 'item item item';
  justify-items: center;
  justify-content: center;

  .item-center {
    grid-area: item;
  }
`
const PlanningBox = styled.div<PlanningBoxStyleProps>`
  width: 256px;
  height: 120px;
  border-radius: 8px;
  margin-left: 32px;
  margin-bottom: 24px;
  padding: 13px;
  background: ${(props) => (props.disabled ? '#D8D9E0' : 'var(--agt-background-color-3)')};
  color: ${(props) => (props.disabled ? '#ACB1BF' : 'var(--agt-primary-color-1)')};
  position: relative;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: no-drop;
      > span {
        filter: grayscale(0.9);
        opacity: 0.5;
      }
    `}
`
const PlanningText = styled.div`
  position: absolute;
  bottom: 11px;
  left: 13px;
  width: 194px;
  white-space: pre-line;
`
const CustomIcon = styled(Icon)`
  position: absolute;
  top: 13px;
  right: 13px;
  width: 62px;
  height: 62px;
  > div {
    > div {
      > svg {
        width: 62px;
        height: 62px;
      }
    }
  }
`

const Planning: FC<PlanningProp> = (props) => {
  const { url, className, disabled, name, text } = props
  return (
    <Link to={url} className={`ghost ${className || ''} ${disabled ? 'disabled' : ''}`}>
      <PlanningBox disabled={disabled}>
        <CustomIcon name={name} />
        <PlanningText>{text}</PlanningText>
      </PlanningBox>
    </Link>
  )
}

const CatalogProduct: FC = () => {
  let { id } = useParams<SearchType>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const url = history.location.pathname

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { label: 'เลือกหมวดประกัน' },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id, history])

  return (
    <Container>
      <Header>เลือกประเภทประกัน</Header>
      <BreadCrumbStyle links={breadCrumbArr} hasBackToRoot iframe />
      <PlanningHeader>เลือกหมวดประกัน</PlanningHeader>
      <PlanningContainer>
        <Planning
          disabled={false}
          text={'ประกันชีวิต'}
          url={`${url.replace('/catalog', '')}/search-product/${get(
            opportunity,
            'contactId',
            null,
          )}`}
          name="insuretype-life"
          className="item-center"
        />
        <Planning
          disabled={false}
          text={'ประกันรถยนต์'}
          url={`${url}/motor`}
          name="insuretype-car"
        />
        <Planning disabled={false} text={'ประกันพ.ร.บ.'} url={`${url}/act`} name="insuretype-prb" />
        <Planning
          disabled={false}
          text={'ประกันเดินทาง'}
          url={`${url}/travel`}
          name="insuretype-travel"
        />
        <Planning disabled={true} text={'ประกันบ้าน'} url={``} name="insuretype-house" />
        <Planning disabled={true} text={'ประกันสุขภาพ'} url={``} name="insuretype-health" />
        <Planning disabled={true} text={'ประกันอุบัติเหตุ'} url={``} name="insuretype-accident" />
      </PlanningContainer>
    </Container>
  )
}
export default CatalogProduct
