import styled from '@emotion/styled'
import { IndicatorProps } from './index.type'
import { TAB_OPTIONS } from './index.utils'

export const Container = styled.div`
  padding: 24px 0 0;
  .btn {
    display: flex;
    justify-content: center;
  }
  .btn .createBtn {
    width: 160px;
    padding: 0 8px;
  }
  @media (max-width: 1024px) {
    .btn .createBtn {
      font-size: 13px;
    }
  }
`

export const Header = styled.h4`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

export const NavWorkDetail = styled.ul`
  position: relative;
  height: 56px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  list-style-type: none;
  align-items: center;
  margin-bottom: 26px;
  li {
    background: var(--agt-background-color-1);
    color: var(--agt-primary-color-1);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  li:hover {
    cursor: pointer;
  }
`

export const Indicator = styled.div<IndicatorProps>`
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% / ${TAB_OPTIONS.length});
  height: 4px;
  background-color: var(--agt-primary-color-1);
  transform: ${(props) => (props.active ? `translateX(calc(100% * ${props.active}))` : ``)};
  transition: 0.5s transform;
`
