import GuestType from 'types/GuestType'
import { isEmail } from 'utils/helper'

const validate = (values: GuestType) => {
  const errors: GuestType = {}
  if (!values.firstName) {
    errors.firstName = 'กรุณากรอกข้อมูล'
  }
  if (!values.lastName) {
    errors.lastName = 'กรุณากรอกข้อมูล'
  }
  if (!values.email) {
    errors.email = 'กรุณากรอกข้อมูล'
  } else if (!isEmail(values.email)) {
    errors.email = 'รูปแบบอีเมลไม่ถูกต้อง'
  }
  return errors
}

export default validate
