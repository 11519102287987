import styled from '@emotion/styled'
import { OptionsButtonProps } from './index.type'

export const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const SubHeader = styled.h4`
  margin: 16px 0;
  padding-left: 16px;
`

export const SubHeaderAndButton = styled.h4`
  margin: 16px 0;
  padding-left: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 41px;
`

export const Button = styled.button`
  width: 242px;
`

export const Noting = styled.div`
  height: 110px;
  padding: 22px 0 62px;
  text-align: center;
  color: var(--agt-secondary-text-color);
  border-bottom: 1px solid var(--agt-secondary-text-color);
`

export const MainContent = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--agt-primary-color-1);
  .Nothing {
    width: 100%;
    text-align: center;
    color: var(--agt-secondary-text-color);
    padding: 22px 0 38px;
  }
  @media screen and (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 24px 24px;
  }
`

export const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const OptionButtonGroup = styled.div``

export const OptionsButton = styled.button<OptionsButtonProps>`
  width: 190px;
  padding: 11px, 15.5px;
  background: ${(props) =>
    props.active ? 'var(--agt-light-sky-color)' : 'var(--agt-white-color)'};
  border: ${(props) =>
    props.active
      ? '1px solid var(--agt-primary-color-1)'
      : '1px solid var(--agt-secondary-color-1)'};
  color: ${(props) =>
    props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
  border-radius: ${(props) =>
    props.radius === 'around'
      ? '4px'
      : props.radius === 'left'
      ? ' 4px 0px 0px 4px '
      : '0px 4px 4px 0px'};
  :hover {
    background: ${(props) =>
      props.active ? 'var(--agt-light-sky-color)' : 'var(--agt-white-color)'};
    border: ${(props) =>
      props.active
        ? '1px solid var(--agt-primary-color-1)'
        : '1px solid var(--agt-secondary-color-1)'};
    color: ${(props) =>
      props.active ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'};
    border-radius: ${(props) =>
      props.radius === 'around'
        ? '4px'
        : props.radius === 'left'
        ? ' 4px 0px 0px 4px '
        : '0px 4px 4px 0px'};
  }
`
