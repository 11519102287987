import React, { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { useField, useFormikContext } from 'formik'
import { useDropzone } from 'react-dropzone'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons'
import api from 'api'
import Avatar from 'components/Avatar'

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 24px;
  .dropzone {
    cursor: pointer;
  }
`

const DummyAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: var(--agt-background-color-2);
`

const IconContainer = styled.div`
  position: absolute;
  left: calc(50% + 25px);
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--agt-primary-color-1);
  cursor: pointer;
`

const createAttachment = async (file: File, callback: (id: any) => void) => {
  try {
    const response = await api.post('/attachments', { image: file }, { isFormData: true })
    callback(response.data.data.attachment.id)
  } catch (err) {
    if (err && err.response) {
      console.error(err.response)
    }
    alert('เกิดข้อผิดพลาดบางอย่าง')
    throw err
  }
}

const ImageComponent = () => {
  const [field] = useField('avatarImageId')
  const [fieldAvatar] = useField('avatar')
  const [previewFile, setFile] = useState(field.value || '')
  const { setFieldValue } = useFormikContext()
  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0]
      createAttachment(file, (value) => {
        setFile(URL.createObjectURL(file))
        setFieldValue('avatarImageId', value)
      })
    },
    [setFieldValue],
  )
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  })
  useEffect(
    () => () => {
      previewFile && URL.revokeObjectURL(previewFile)
    },
    [previewFile],
  )
  return (
    <ImageContainer>
      <div {...getRootProps({ className: 'dropzone' })}>
        {previewFile ? (
          <Avatar type="circle" avatar={previewFile || ''} width="120px" height="120px" />
        ) : (
          <>
            {fieldAvatar.value ? (
              <Avatar type="circle" avatar={fieldAvatar.value || ''} width="120px" height="120px" />
            ) : (
              <DummyAvatar>
                <FontAwesomeIcon icon={faUser} color="white" style={{ fontSize: '60px' }} />
              </DummyAvatar>
            )}
          </>
        )}
        <input {...getInputProps()} />
      </div>
      <IconContainer>
        <FontAwesomeIcon icon={faCamera} color="white" style={{ fontSize: '18px' }} />
      </IconContainer>
    </ImageContainer>
  )
}

export default ImageComponent
