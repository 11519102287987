import * as Yup from 'yup'

export let schema = Yup.object().shape({
  consents: Yup.array().of(
    Yup.object().shape({
      forced_consent: Yup.boolean().required('กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ'),
      value: Yup.boolean().when(['forced_consent'], {
        is: (forced_consent) => forced_consent === true,
        then: Yup.boolean()
          .required('กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ')
          .oneOf([true], 'กรุณายอมรับเงื่อนไขข้อตกลงนี้เพื่อดำเนินการต่อ'),
      }),
    }),
  ),
})
