import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { isEmail, emailCensorer } from 'utils/helper'
import { sendResetPassword } from 'store/appSlice'

type ForgotPasswordValues = {
  email?: string
}
type ForgotPageType = {
  onBackClick: () => void
}
type FieldValueProps = {
  isError?: boolean
}

const Container = styled.div`
  padding: 50px 0;
`
const Title = styled.h4`
  margin-bottom: 20px;
  text-align: center;
`
const FormValue = styled(Form)`
  width: 100%;
  max-width: 352px;
`
const FormContainer = styled.div`
  margin: 0 0 24px;
`
const Label = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin: 0 0 8px;
`
const FieldValue = styled(Field)<FieldValueProps>`
  width: 352px;
  border: ${(props) =>
    props.isError
      ? '1px solid var(--agt-error-color) !important'
      : '1px solid var(--agt-secondary-color-1) !important'};
`
const ErrorContainer = styled.div`
  margin-top: 8px;
`
const SubmitButton = styled.button`
  width: 100%;
  margin-bottom: 24px;
`
const RegisterButton = styled.button`
  width: 100%;
`
const SubTitle = styled(Title)`
  margin-bottom: 40px;
`
const SubLabel = styled(Label)`
  justify-content: center;
`
const Email = styled(SubLabel)`
  margin-bottom: 40px;
  color: var(--agt-primary-color-1);
`

const Error = (msg: string) => <ErrorContainer className="error agt-small">{msg}</ErrorContainer>

function validateEmail(value: string) {
  let error: string | undefined
  if (!value) {
    error = 'กรุณากรอกข้อมูล'
  } else if (!isEmail(value)) {
    error = 'รูปแบบไม่ถูกต้อง'
  }
  return error
}

const ForgotPage: FC<ForgotPageType> = (props) => {
  const { onBackClick } = props
  const [email, setEmail] = useState('')
  const [state, setState] = useState<'forming' | 'success'>('forming')
  const dispatch = useDispatch()
  if (state === 'success') {
    return (
      <Container>
        <SubTitle>ลืมรหัสผ่าน</SubTitle>
        <SubLabel>กรุณาตรวจสอบอีเมลและดำเนินการตามคำแนะนำ</SubLabel>
        <Email>{emailCensorer(email)}</Email>
        <RegisterButton className="secondary" type="button" onClick={() => onBackClick()}>
          กลับหน้าหลัก
        </RegisterButton>
      </Container>
    )
  }
  return (
    <Container>
      <SubTitle>ลืมรหัสผ่าน</SubTitle>
      <Formik
        initialValues={{
          email: '',
        }}
        onSubmit={(values: ForgotPasswordValues) => {
          dispatch(
            sendResetPassword(values, () => {
              setState('success')
              setEmail(values.email || '')
            }),
          )
        }}
      >
        {(props) => {
          const { isValid } = props
          return (
            <FormValue>
              <FormContainer>
                <SubLabel>กรอกอีเมลของคุณ</SubLabel>
                <FieldValue
                  name="email"
                  type="email"
                  placeholder="โปรดระบุ"
                  validate={validateEmail}
                />
                <ErrorMessage render={Error} name="email" />
              </FormContainer>
              <SubmitButton type="submit" disabled={!isValid}>
                ส่ง
              </SubmitButton>
              <RegisterButton className="secondary" type="button" onClick={() => onBackClick()}>
                กลับหน้าหลัก
              </RegisterButton>
            </FormValue>
          )
        }}
      </Formik>
    </Container>
  )
}

export default ForgotPage
