import React, { FC } from 'react'
import styled from '@emotion/styled'
import { OptionStateType, OrderStateType } from 'types/MotorInsuranceType'
import {
  convertBirthdate,
  convertDate,
  convertInspectionLocation,
  convertPolicyForm,
  convertValueToLabel,
} from './utils'
import { phoneNumberFormat } from 'utils/helper'

const Show: FC<DetailType> = ({ data, options }) => {
  return (
    <Container>
      <label className="header">กรอกข้อมูลผู้เอาประกันภัย</label>
      <DetailWrapper>
        <div className="row_group">
          <div className="column prefixName">
            <label className="topic">คำนำหน้าชื่อ</label>
            <label className="detail">{data?.contact.prefixName || '-'}</label>
          </div>
          <div className="column name">
            <label className="topic">ชื่อ</label>
            <label className="detail">{data?.contact.name || '-'}</label>
          </div>
          <div className="column name">
            <label className="topic">นามสกุล</label>
            <label className="detail">{data?.contact.lastName || '-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">วันเกิด</label>
            <label className="detail">{convertBirthdate(data?.contact.birthdate) || '-'}</label>
          </div>
          <div className="column">
            <label className="topic">เลขบัตรประจำตัวประชาชน</label>
            <label className="detail">{data?.contact.idCard || '-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">อีเมล</label>
            <label className="detail">{data?.contact.email || '-'}</label>
          </div>
          <div className="column">
            <label className="topic">เบอร์โทรศัพท์</label>
            <label className="detail">{phoneNumberFormat(data?.contact.phoneNumber) || '-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column extra">
            <label className="topic">ประกันเดิม</label>
            <label className="detail">
              {convertValueToLabel(
                options?.insuranceCompanyOptions,
                data?.order.motorInsurance.oldCompanyName,
              ) || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column extra">
            <label className="topic">ที่อยู่บนกรมธรรม์</label>
            <label className="detail">
              {Object.values(data?.order.motorInsurance.policyAddress || {}).join(' ') || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column extra">
            <label className="topic">ที่อยู่จัดส่งกรมธรรม์</label>
            <label className="detail">
              {Object.values(data?.order.motorInsurance.policyDeliveryAddress || {}).join(' ') ||
                '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column extra">
            <label className="topic">ที่อยู่ใบเสร็จรับเงิน</label>
            <label className="detail">
              {Object.values(data?.order.motorInsurance.receiptAddress || {}).join(' ') || '-'}
            </label>
          </div>
        </div>

        <label className="group_header">รายละเอียดกรมธรรม์</label>

        <div className="row_group">
          <div className="column">
            <label className="topic">สถานที่ตรวจสภาพรถยนต์</label>
            <label className="detail">
              {convertInspectionLocation(data?.order.motorInsurance.inspectionLocation) || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">วันที่ตรวจสภาพรถยนต์</label>
            <label className="detail">
              {convertDate(data?.order.motorInsurance.vehicleInspectionDate) || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">รูปแบบกรมธรรม์</label>
            <label className="detail">
              {convertPolicyForm(data?.order.motorInsurance.policyForm) || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">เริ่มการคุ้มครอง</label>
            <label className="detail">
              {convertDate(data?.order.motorInsurance.startPolicyAt) || '-'}
            </label>
          </div>
          <div className="column">
            <label className="topic">สิ้นสุดการคุ้มครอง</label>
            <label className="detail">
              {convertDate(data?.order.motorInsurance.endPolicyAt) || '-'}
            </label>
          </div>
        </div>

        {/* <div className="row_group">
          <div className="column">
            <label className="topic">ต้องการ พ.ร.บ.</label>
            <label className="detail">{'-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">เริ่มการคุ้มครอง</label>
            <label className="detail">{'-'}</label>
          </div>
          <div className="column">
            <label className="topic">สิ้นสุดการคุ้มครอง</label>
            <label className="detail">{'-'}</label>
          </div>
        </div> */}

        <label className="group_header">รายละเอียดรถยนต์</label>

        <div className="row_group">
          <div className="column extra">
            <label className="topic">เลือกจากข้อมูลรถของลูกค้า</label>
            <label className="detail">{data?.vehicle.name || '-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">ปีรถยนต์</label>
            <label className="detail">{data?.vehicle.year || '-'}</label>
          </div>
          <div className="column">
            <label className="topic">ยี่ห้อรถยนต์</label>
            <label className="detail">
              {convertValueToLabel(options?.makeCodeOptions, data?.vehicle.makeCode) || '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">รุ่น</label>
            <label className="detail">
              {convertValueToLabel(options?.familyCodeOptions, data?.vehicle.familyCode) || '-'}
            </label>
          </div>
          <div className="column">
            <label className="topic">รุ่นย่อย</label>
            <label className="detail">{data?.vehicle.vehicleSubModel || '-'}</label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">เลขทะเบียนรถยนต์</label>
            <label className="detail">{data?.vehicle.vehicleLicense.value || '-'}</label>
          </div>
          <div className="column">
            <label className="topic">จังหวัดที่จดทะเบียน</label>
            <label className="detail">
              {convertValueToLabel(options?.provinceOptions, data?.vehicle.vehicleProvince.value) ||
                '-'}
            </label>
          </div>
        </div>

        <div className="row_group">
          <div className="column">
            <label className="topic">เลขตัวถังรถ</label>
            <label className="detail">{data?.vehicle.vehicleChassis.value || '-'}</label>
          </div>
          {data?.vehicle.redLicencePlate && (
            <div className="column red_license">
              <label className="topic">เป็นรถป้ายแดง</label>
            </div>
          )}
        </div>
      </DetailWrapper>
    </Container>
  )
}

export default Show

type DetailType = {
  data: OrderStateType | null
  options: OptionStateType | null
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 17px;
  width: 100%;

  .header {
    font-weight: 700;
    padding: 40px 18px;
  }
`
const DetailWrapper = styled.div`
  padding-left: 31px;

  .row_group {
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    margin-bottom: 24px;
  }
  .column {
    display: flex;
    flex-direction: column;
    width: 360px;
    gap: 6px;
    @media (max-width: 1195px) {
      width: 100%;
    }
  }
  .group_header {
    display: block;
    font-weight: 700;
    margin-bottom: 24px;
  }
  .topic {
    font-weight: 700;
  }
  .detail {
    padding-bottom: 6px;
    border-style: solid;
    border-width: 0 0 1px 0;
    border-color: var(--agt-secondary-color-1);
  }
  .extra {
    width: 100%;
  }
  .prefixName {
    width: 160px;
    @media (max-width: 1195px) {
      width: 100%;
    }
  }
  .name {
    width: 270px;
    @media (max-width: 1195px) {
      width: 100%;
    }
  }
  .red_license {
    align-self: end;
  }
`
