export type FilterValues = {
  dataSearch: string
  orderBy: string
  order: string
  page: number
  perPage: number
}

export const statusOptions = [
  { value: 'รอเสนองาน', label: 'รอเสนองาน' },
  { value: 'ไม่สนใจ', label: 'ไม่สนใจ' },
  { value: 'ติดต่อไม่ได้', label: 'ติดต่อไม่ได้' },
  { value: 'ติดตามงาน', label: 'ติดตามงาน' },
  { value: 'รอเสนองานใหม่', label: 'รอเสนองานใหม่' },
  { value: 'รอยินยอม', label: 'รอยินยอม' },
]

export const initialSearchValues = {
  dataSearch: '',
  orderBy: 'updated_at',
  order: 'desc',
  page: 1,
  perPage: 10,
}
