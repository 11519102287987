import React, { FC, useState } from 'react'
import { Link, useHistory } from 'routes'
import { ErrorMessage, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { RootState } from 'store/rootReducer'
import { loginUser, loginUserBySocial, setIsNavbarErrorShow } from 'store/appSlice'
import { get } from 'lodash'
import {
  AnotherSignIn,
  Container,
  ErrorBar,
  FieldValue,
  ForgetPassword,
  FormContainer,
  FormValue,
  IconSignIn,
  IconStyles,
  Loading,
  SelectTitle,
  SubmitButton,
} from './Login.styles'
import { LoginPageType, LoginValues } from './Login.types'
import { PasswordField } from './Login.components'
import LoginSchema from './Login.validate'

const Login: FC<LoginPageType> = (props) => {
  const { onForgotPasswordClick } = props
  let history = useHistory()
  const dispatch = useDispatch()
  const isNavbarError = useSelector((state: RootState) => state.app.isNavbarErrorShow)
  const [isResp, setIsResp] = useState<{ email: string }>({ email: '' })
  const [isLoading, setLoading] = useState<boolean>(false)

  const clearError = () => {
    if (isNavbarError) dispatch(setIsNavbarErrorShow(''))
  }

  return (
    <>
      {isNavbarError && (
        <ErrorBar>
          <div className="isTimes" onClick={clearError}>
            <IconStyles name="cross" />
          </div>
          {['Unauthorized.', 'Member not found.'].includes(isNavbarError as string) ? (
            <span>อีเมลหรือรหัสผ่านไม่ถูกต้อง</span>
          ) : ['อีเมลยังไม่ได้รับการยืนยัน'].includes(isNavbarError as string) ? (
            <span>
              {'อีเมลนี้ยังไม่ได้รับการยืนยันตัวตน'}{' '}
              <Link
                className="resend"
                to={{ pathname: '/register', state: { resendEmail: isResp.email, callApi: 'Y' } }}
              >
                {'โปรดยืนยันตัวตนหรือคลิกที่นี่เพื่อขออีเมลอีกครั้ง'}
              </Link>
            </span>
          ) : (
            <span>{isNavbarError}</span>
          )}
        </ErrorBar>
      )}
      <Container>
        <SelectTitle>
          <div className="primary">เข้าสู่ระบบ</div>
          <div className="center" />
          <Link to="/register" className="ghost">
            <div className="secondary">ลงทะเบียน</div>
          </Link>
        </SelectTitle>
        <Formik
          enableReinitialize
          initialValues={{
            agentCode: '',
            password: '',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values: LoginValues) => {
            dispatch(loginUser(values, history, setLoading))
          }}
        >
          {(props) => {
            const { isValid, values, errors, touched, setFieldValue } = props
            const disabled = !isValid || !values.agentCode || !values.password
            return (
              <FormValue>
                <FormContainer>
                  <div className="title">บัญชีผู้ใช้งาน (อีเมล/รหัสตัวแทน)</div>
                  <FieldValue
                    name="agentCode"
                    type="text"
                    placeholder="โปรดระบุ"
                    isError={isNavbarError || errors.agentCode}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFieldValue('agentCode', e.target.value)
                      setIsResp({ email: e.target.value })
                      clearError()
                    }}
                  />
                  {errors.agentCode && touched.agentCode && (
                    <small className="error">
                      <ErrorMessage name="agentCode" />
                    </small>
                  )}
                </FormContainer>
                <FormContainer>
                  <div className="title">
                    รหัสผ่าน
                    <ForgetPassword className="small" onClick={() => onForgotPasswordClick()}>
                      ลืมรหัสผ่าน?
                    </ForgetPassword>
                  </div>
                  <FieldValue
                    name="password"
                    type="password"
                    placeholder="โปรดระบุ"
                    isNavbarError={isNavbarError || errors.password}
                    component={PasswordField}
                    onChange={() => clearError()}
                  />
                  {errors.password && touched.password && (
                    <small className="error">
                      <ErrorMessage name="password" />
                    </small>
                  )}
                </FormContainer>
                {isLoading ? (
                  <Loading>loading ....</Loading>
                ) : (
                  <SubmitButton type="submit" disabled={disabled}>
                    เข้าสู่ระบบ
                  </SubmitButton>
                )}
                <AnotherSignIn>
                  <div className="suggest">
                    <hr className="line" />
                    หรือ
                    <hr className="line" />
                  </div>
                  <GoogleLogin
                    clientId={`${process.env.REACT_APP_APPID_GOOGLE}`}
                    render={(renderProps) => (
                      <button
                        className="google secondary"
                        type="button"
                        onClick={renderProps.onClick}
                      >
                        <div className="icon">
                          <IconSignIn name="google" />
                        </div>
                        เข้าสู่ระบบด้วย Google
                      </button>
                    )}
                    onSuccess={(res) => {
                      let values: { email: string; tokenId: string } = {
                        email: get(res, 'profileObj.email', ''),
                        tokenId: get(res, 'tokenId', ''),
                      }
                      setIsResp(values)
                      dispatch(loginUserBySocial('google', values, history))
                    }}
                    onFailure={(err) => console.log('onFailure : ', err)}
                  />
                  <FacebookLogin
                    appId={`${process.env.REACT_APP_APPID_FACEBOOK}`}
                    autoLoad={false}
                    callback={(res) => {
                      if (res.userID) {
                        let values: { email: string; accessToken: string } = {
                          email: get(res, 'email', ''),
                          accessToken: get(res, 'accessToken', ''),
                        }
                        setIsResp(values)
                        dispatch(loginUserBySocial('facebook', values, history))
                      }
                    }}
                    fields="email"
                    render={(renderProps) => (
                      <button
                        className="facebook secondary"
                        type="button"
                        onClick={renderProps.onClick}
                      >
                        <div className="icon">
                          <IconSignIn name="facebook-signin" />
                        </div>
                        เข้าสู่ระบบด้วย Facebook
                      </button>
                    )}
                  />
                </AnotherSignIn>
              </FormValue>
            )
          }}
        </Formik>
      </Container>
    </>
  )
}

export default Login
