import React, { FC, useEffect, useState } from 'react'
import { useHistory, useParams } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { get } from 'lodash'
import Avatar from 'components/Avatar'
import { getContactById } from 'store/contactSlice'
import Icon from 'components/Icon'
import { getActOptions, searchAct } from 'store/actSlice'
import HistoryType from 'types/HistoryType'
import {
  Container,
  Header,
  Detail,
  Selector,
  Table,
  SmallText,
  customSelectStyles,
} from './index.styles'

const ActProduct: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const history: HistoryType = useHistory()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { contact } = useSelector((state: RootState) => state.contacts)
  const { options } = useSelector((state: RootState) => state.act)
  const [insuranceCompany, setInsuranceCompany] = useState<string | null>('')
  const [typeAct, setTypeAct] = useState<string>('เก๋ง')
  const [, setIsBlocked] = useState<boolean>(true)

  const breadCrumbArr: Array<LinkValues> = [
    { path: `/opportunities/${id}`, label: get(opportunity, 'name', 'โอกาสในการขาย') },
    { path: `/opportunities/${id}/catalog`, label: 'เลือกหมวดประกัน' },
    { label: 'ประกัน พ.ร.บ.' },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
    dispatch(getActOptions(id))
  }, [dispatch, id])

  useEffect(() => {
    let contactId: string | number = get(opportunity, 'contactId', '')
    if (contactId === '') {
    } else {
      dispatch(getContactById(contactId))
    }
  }, [dispatch, opportunity])

  const onClick = () => {
    let parsePrbCar = options?.prbOptions.find((option) => option.prbCarType === typeAct)
    if (parsePrbCar?.prbCarType && parsePrbCar?.prbCode && insuranceCompany) {
      let parseParams = {
        companyCode: insuranceCompany,
        prbCarType: parsePrbCar?.prbCarType || '',
        prbCode: parsePrbCar?.prbCode || '',
      }
      dispatch(
        searchAct(
          id,
          {
            carUsage: parsePrbCar.prbCode,
            companyCode: insuranceCompany,
          },
          setIsBlocked,
          () => {
            history.push({
              pathname: `/opportunities/${id}/catalog/act/form`,
              state: parseParams,
            })
          },
        ),
      )
    }
  }

  return (
    <Container>
      <Header>ประกัน พ.ร.บ.</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <div className="">
        <Detail>
          <div className="title">ประกัน พ.ร.บ.</div>
          <div className="customer">
            <div>ข้อมูลลูกค้า</div>
            <div>
              <Avatar avatar={contact?.avatar || ''} width="36px" height="36px" type="circle" />
              {contact?.name || ''}
            </div>
          </div>
          <div className="select">
            <label
              className={typeAct === 'เก๋ง' ? 'active' : ''}
              htmlFor="radio__1"
              onClick={() => {
                setTypeAct('เก๋ง')
              }}
            >
              <Icon
                name="sedan"
                color={
                  typeAct === 'เก๋ง' ? 'var(--agt-primary-color-1)' : 'var(--agt-secondary-color-1)'
                }
              />
              <input id="radio__1" type="radio" name="radio" checked={typeAct === 'เก๋ง'} />
              เก๋ง 645.21 บาท
              <span className="checkmark"></span>
            </label>
            <label
              className={typeAct === 'กระบะ' ? 'active' : ''}
              htmlFor="radio__2"
              onClick={() => {
                setTypeAct('กระบะ')
              }}
            >
              <Icon
                name="pickup"
                color={
                  typeAct === 'กระบะ'
                    ? 'var(--agt-primary-color-1)'
                    : 'var(--agt-secondary-color-1)'
                }
              />
              <input id="radio__2" type="radio" name="radio" checked={typeAct === 'กระบะ'} />
              กระบะ 967.28 บาท
              <span className="checkmark"></span>
            </label>
            <label
              className={typeAct === 'รถตู้' ? 'active' : ''}
              htmlFor="radio__3"
              onClick={() => {
                setTypeAct('รถตู้')
              }}
            >
              <Icon
                name="van"
                color={
                  typeAct === 'รถตู้'
                    ? 'var(--agt-primary-color-1)'
                    : 'var(--agt-secondary-color-1)'
                }
              />
              <input id="radio__3" type="radio" name="radio" checked={typeAct === 'รถตู้'} />
              รถตู้ 1,182.35 บาท
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="insurance">
            <div>เลือกบริษัทประกันภัย</div>
            <Selector
              value={(options?.prbInsuranceCompanyOptions || []).find(
                (option) => option.value === insuranceCompany,
              )}
              className="insuranceOptions"
              onChange={(option: { label: string; value: string }) => {
                setInsuranceCompany(option.value)
              }}
              options={options?.prbInsuranceCompanyOptions || []}
              styles={customSelectStyles}
              placeholder="โปรดระบุ/เลือก"
              isDisabled={false}
            />
          </div>
          {/* <Link
            to={{
              pathname: `/opportunities/${id}/catalog/act/form`,
              state: { insuranceCompany, typeAct },
            }}
          >
            <button type="button">ค้นหาแบบประกัน</button>
          </Link> */}
          <button type="button" onClick={onClick} disabled={insuranceCompany === ''}>
            ค้นหาแบบประกัน
          </button>
        </Detail>
        <Table>
          <thead>
            <tr>
              <td>
                ความคุ้มครองประกันภัย พ.ร.บ. เกิดเหตุ
                <br />
                ตั้งแต่ 1 เมษายน 2563
              </td>
              <td>
                วงเงินคุ้มครองรถที่เกิดเหตุตั้ง
                <br />
                แต่ 1 เม.ย. 63 (บาท/คน)
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>เสียชีวิต</td>
              <td rowSpan={3} className="group">
                500,000
              </td>
            </tr>
            <tr>
              <td>ทุพลภาพถาวรสิ้นเชิง</td>
            </tr>
            <tr>
              <td>สูญเสีย แขน ขา ตาบอด 2 อย่าง / ข้างขึ้นไป</td>
            </tr>
            <tr>
              <td>
                ทุพลภาพถาวรสิ้นเชิง ไม่สามารถประกอบหน้าที่การงานใดๆ ในอาชีพ ประจำได้โดย
                สิ้นเชิงตลอดไป
              </td>
              <td rowSpan={1} className="group">
                300,000
              </td>
            </tr>
            <tr>
              <td>หูหนวก</td>
              <td rowSpan={7} className="group">
                250,000
              </td>
            </tr>
            <tr>
              <td>เป็นใบ้ / สูญเสียความสามารถในการพูด</td>
            </tr>
            <tr>
              <td>ลิ้นขาด</td>
            </tr>
            <tr>
              <td>เสียอวัยวะสืบพันธ์ / ความสามารถ</td>
            </tr>
            <tr>
              <td>จิตพิการติดตัว</td>
            </tr>
            <tr>
              <td>สูญเสียอวัยวะอื่นใดที่ส่งผลให้การใช้ชีวิตผิดปกติ</td>
            </tr>
            <tr>
              <td>สูญเสียมือ แขน ขา เท้า ตาบอด 1 ข้าง</td>
            </tr>
            <tr>
              <td>นิ้วขาด (มือ / เท้า 1 ข้อขึ้นไป)</td>
              <td rowSpan={1} className="group">
                200,000
              </td>
            </tr>
            <tr>
              <td>
                บาดเจ็บ / สูญเสียอนามัยค่าเสียหายอย่างอื่น ที่สามารถเรียกร้องได้ ตามมูลความผิด
              </td>
              <td rowSpan={1} className="group">
                80,000
              </td>
            </tr>
            <tr>
              <td>
                เงิดชดเชยรายวัน กรณีเข้ารับการรักษาพยาบาลในฐานะผู้ป่วยใน สำหรับผู้ถูกกระทำละเมิด
                วันละ 200 บาท ได้ไม่เกิน 20 วัน
              </td>
              <td rowSpan={1} className="group">
                4,000
              </td>
            </tr>
          </tbody>
        </Table>
        <SmallText>
          <small>
            ผู้ขับขี่ที่เป็นฝ่ายผิด หรือ ไม่มีผู้ใดต้องรับผิดตามกฎหมาย
            จะได้รับเฉพาะค่าเสียหายเบื้องต้น เป็นค่ารักษาพยาบาลไม่เกิน 30,000 บาท
            <br />
            และ กรณีสูญเสียอวัยวะ หรือ ทุพพลภาพถาวร หรือ เสียชีวิต 35,000 บาท รวมแล้วไม่เกิน 65,000
            บาท เท่านั้น
          </small>
        </SmallText>
      </div>
    </Container>
  )
}

export default ActProduct
