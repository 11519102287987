import { Form } from 'formik'
import styled from '@emotion/styled'
import Select from 'react-select'

export const FormValue = styled(Form)`
  display: flex;
  flex-direction: column;
  padding: 40px 0 0 18px;
  width: 68%;
  .header {
    margin-bottom: 24px;
  }
  .detail {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid var(--agt-secondary-color-1);
  }
  .detail b {
    width: 100%;
  }
  .detail label {
    width: 50%;
  }
  .detail .domestic {
    width: 100%;
  }
  .assured {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    border-bottom: 1px solid var(--agt-secondary-color-1);
    padding-bottom: 24px;
    margin-bottom: 24px;
    justify-content: space-between;
  }
  .assured b {
    width: 100%;
    color: var(--agt-primary-color-1);
  }
  .assured .input,
  .assured .selector,
  .assured .datepicker {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .assured .input label span,
  .assured .selector label span,
  .assured .datepicker label span {
    color: var(--agt-error-color);
  }
  .assured .input input {
    width: 100%;
  }
  .assured .firstName,
  .assured .lastName {
    max-width: 272px;
  }
  .assured .prefix {
    max-width: 160px;
  }
  .assured .prefix label ~ div {
    width: 100%;
  }
  .assured .idcard,
  .assured .birthdate,
  .assured .national {
    max-width: 230px;
  }
  .assured .national label ~ div {
    width: 100%;
  }
  .assured .email,
  .assured .phoneNumber,
  .assured .whos,
  .assured .relationship {
    width: 100%;
    max-width: 352px;
  }
  .assured .beneficiary {
    width: 100%;
  }
  .policyType {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .flight {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    margin-bottom: 24px;
    justify-content: space-between;
  }
  .flight b {
    width: 100%;
  }
  .flight .row {
    width: 100%;
    max-width: 352px;
  }
  .flight .row label span {
    color: var(--agt-white-color);
  }
  .flight .row label ~ div {
    width: 100%;
  }
  .flight .row input {
    width: 100%;
  }
  .tqm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
  }
  .tqm label {
    text-align: justify;
  }
  .tqm u {
    color: var(--agt-primary-color-1);
  }
  .groupBtn {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
`

export const AddressComponent = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 24px 16px;
  margin-bottom: 24px;
  .aaaaaa {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .aaaaaa > div:last-child {
    width: 100%;
    max-width: 352px;
  }
  .housingNo,
  .villageNo,
  .road,
  .land {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 352px;
    @media (max-width: 1024px) {
      max-width: 272px;
    }
  }
  .housingNo > input,
  .villageNo > input,
  .road > input,
  .land > input {
    width: 100%;
  }
  .building {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    max-width: 736px;
    @media (max-width: 1024px) {
      width: 89%;
    }
  }
  .building > input {
    width: 100%;
  }
  .housingNo > label span {
    color: var(--agt-error-color);
  }
  .aaaaaa > label span,
  .villageNo > label span,
  .building > label span,
  .land > label span,
  .road > label span {
    color: var(--agt-white-color);
  }
  .topic {
    width: 100%;
    margin-top: unset;
  }
  .group {
    justify-content: space-between;
  }
  .group > div {
    max-width: 352px;
  }
  .group > div label ~ div {
    max-width: 352px;
  }
`

export const OtherComponent = styled.div`
  &.policyType,
  &.beneficiary,
  &.xxxx {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    > label span {
      color: var(--agt-error-color);
    }
    > div:last-child {
      display: flex;
      column-gap: 52px;
    }
    > div:last-child label {
      display: flex;
      column-gap: 16px;
      align-items: center;
    }
    > div:last-child label:hover {
      cursor: pointer;
    }
    > div:last-child label .checkmark {
      position: relative;
      width: 16px;
      height: 16px;
      border: 2px solid var(--agt-secondary-color-2);
      border-radius: 50%;
    }
    > div:last-child label input {
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark {
      border: 2px solid var(--agt-primary-color-1);
    }
    > div:last-child label .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }
    > div:last-child label input:checked ~ .checkmark:after {
      display: block;
    }
    > div:last-child label .checkmark:after {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: var(--agt-primary-color-1);
    }
  }
  &.policyType > label {
    font-weight: bold;
  }
`

export const Error = styled.small`
  color: var(--agt-error-color);
`

export const OptionsStyles = {
  // container: (styles, { isDisabled }) => ({
  //   cursor: isDisabled ? 'not-allowed' : 'default',
  // }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: (styles, { isDisabled }) => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: isDisabled ? 'var(--agt-background-color-1)' : 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
  singleValue: (defaultStyle, { isDisabled }) => ({
    ...defaultStyle,
    color: isDisabled ? 'var(--agt-primary-text-color)' : 'var(--agt-primary-text-color)',
  }),
}

export const OptionsError = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-error-color)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '240px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

export const Selector = styled(Select)`
  width: 100%;
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
