import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
// import { useParams } from 'routes'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import { RootState } from 'store/rootReducer'
import { useSelector } from 'react-redux'
import { NewButton, NewForm, ShieldIcon, Table, SmallText } from './InsurancePort.styles'
import {
  getFullNameInsurancePattern,
  // getFullNameInsuranceType,
  getFullNamePolicyType,
  getFullNameState,
} from './InsurancePort.components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { PolicyActInsuranceType } from './PolicyActInsurance.type'

const PolicyActInsurance: FC<PolicyActInsuranceType> = (props) => {
  const { instead = {}, onEdit = () => {}, setIsImageFile, setIsDelete } = props
  // let { id } = useParams<{ id: string }>()
  const { portOptions } = useSelector((state: RootState) => state.contacts)
  // const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isCarDetail, setIsCarDetail] = useState<boolean>(false)

  return (
    <>
      <NewForm className="ta">
        <section className="detail">
          {instead.createdSource === 'tqm' ? (
            <div className="Row">
              <div className="Group">
                <b>เจ้าของกรมธรรม์</b>
                <p>{instead.ownerName}</p>
              </div>
              <div className="orderNoTqm">
                <div className="Group orderNo">
                  <b>รหัสคำสั่งซื้อ</b>
                  <p>{instead?.orderNo || '-'}</p>
                </div>
                <Link to={`/opportunities/${instead.opportunityId}/catalog/act/${instead.orderId}`}>
                  <button type="button" style={{ width: 176 }}>
                    รายละเอียดคำสั่งซื้อ
                  </button>
                </Link>
              </div>
            </div>
          ) : (
            <div className="Row">
              <div className="Group">
                <b>เจ้าของกรมธรรม์</b>
                <p>{instead.ownerName}</p>
              </div>
              <div className="Group">
                <b>รหัสคำสั่งซื้อ</b>
                <p>{instead?.orderNo || '-'}</p>
              </div>
            </div>
          )}
          <div className="Row">
            <div className="Group">
              <b>เพศ</b>
              <p>{instead.ownerGender === 'M' ? 'ชาย' : 'หญิง'}</p>
            </div>
            <div className="Group">
              <b>อายุ</b>
              <p>{instead.ownerAge}</p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>รูปแบบการประกันภัย</b>
              <p>{getFullNameInsurancePattern(instead.insurancePattern || '')}</p>
            </div>
            <div className="Group">
              <b>ประเภทประกัน</b>
              <p>{getFullNamePolicyType(instead.policyType || '')}</p>
            </div>
          </div>
          {instead?.specialFields?.vehicle && (
            <div className="CarDetail">
              <div className="Icon">
                <b>รายละเอียดรถยนต์</b>
                <div onClick={() => setIsCarDetail(!isCarDetail)}>
                  {isCarDetail ? (
                    <FontAwesomeIcon icon={faChevronUp} color={'var(--agt-secondary-text-color)'} />
                  ) : (
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      color={'var(--agt-secondary-text-color)'}
                    />
                  )}
                </div>
              </div>
              <div className="CarForm">
                <div className="Row">
                  <div className="Group carName">
                    <b>รถยนต์</b>
                    <p>{instead?.specialFields?.vehicle?.name || '-'}</p>
                  </div>
                </div>
                {isCarDetail && (
                  <>
                    <div className="Row">
                      <div className="Group">
                        <b>ปีรถยนต์</b>
                        <p>{instead?.specialFields?.vehicle?.year || '-'}</p>
                      </div>
                      <div className="Group">
                        <b>ยี่ห้อรถยนต์</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleBrand || '-'}</p>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Group">
                        <b>รุ่น</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleModel || '-'}</p>
                      </div>
                      <div className="Group">
                        <b>รุ่นย่อย</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleSubModel || '-'}</p>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Group">
                        <b>เลขทะเบียนรถยนต์</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleLicense || '-'}</p>
                      </div>
                      <div className="Group">
                        <b>จังหวัดที่จดทะเบียน</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleProvince || '-'}</p>
                      </div>
                    </div>
                    <div className="Row">
                      <div className="Group">
                        <b>เลขตัวถังรถ</b>
                        <p>{instead?.specialFields?.vehicle?.vehicleChassis || '-'}</p>
                      </div>
                      {instead?.specialFields?.vehicle?.redLicencePlate && (
                        <div className="Group redPlate">
                          <b></b>
                          <p>เป็นรถป้ายแดง</p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="Row">
            <div className="Group">
              <b>ประเภทกรมธรรม์</b>
              {/* <p>{getFullNameInsuranceType(instead.insuranceType || '')}</p> */}
              <p>พ.ร.บ.</p>
            </div>
            <div className="Group">
              <b>บริษัทประกัน</b>
              <p>
                {portOptions.insuranceCompanyNameOptions.find(
                  (e) => e.value === instead.insuranceCompanyName,
                )?.label || '-'}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ชื่อแบบประกัน</b>
              <p>{instead?.name || '-'}</p>
              {/* <p>
                พ.ร.บ. {instead?.specialFields?.prbCarType}{' '}
                {instead?.specialFields?.prbCode ? `(${instead?.specialFields?.prbCode})` : ''}
              </p> */}
            </div>
            <div className="Group">
              <b>หมายเลขกรมธรรม์</b>
              <p>{instead?.policyNo || '-'}</p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>สถานะกรมธรรม์</b>
              <p>{instead.state ? getFullNameState(instead.state) : 'ไม่ต้องระบุสถานะกรมธรรม์'}</p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>วันที่เริ่มทำสัญญา</b>
              <p>{new Date(instead.startPolicyAt || '').toLocaleDateString('th-TH')}</p>
            </div>
            <div className="Group">
              <b>วันที่ครบกำหนดสัญญา (คุ้มครองถึง)</b>
              <p>
                {instead.endPolicyAt
                  ? new Date(instead.endPolicyAt || '').toLocaleDateString('th-TH')
                  : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
              </p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>เบี้ยประกันภัย (บาท)</b>
              <p>{instead.netPremium && financial(instead.netPremium)}</p>
            </div>
          </div>
          <div className="Row">
            <div className="Group">
              <b>ค่ารักษาพยาบาล (บาท/คน)</b>
              <p>{financial('30000')}</p>
            </div>
            <div className="Group">
              <b>สูญเสียอวัยวะหรือทุพพลภาพ (บาท/คน)</b>
              <p>{financial('35000')}</p>
            </div>
          </div>
        </section>
        <section className="table">
          <p>ตารางความคุ้มครอง</p>
          <Table>
            <thead>
              <tr>
                <td>
                  ความคุ้มครองประกันภัย พ.ร.บ. เกิดเหตุ
                  <br />
                  ตั้งแต่ 1 เมษายน 2563
                </td>
                <td>
                  วงเงินคุ้มครองรถที่เกิดเหตุตั้ง
                  <br />
                  แต่ 1 เม.ย. 63 (บาท/คน)
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>เสียชีวิต</td>
                <td rowSpan={3} className="group">
                  500,000
                </td>
              </tr>
              <tr>
                <td>ทุพลภาพถาวรสิ้นเชิง</td>
              </tr>
              <tr>
                <td>สูญเสีย แขน ขา ตาบอด 2 อย่าง / ข้างขึ้นไป</td>
              </tr>
              <tr>
                <td>
                  ทุพลภาพถาวรสิ้นเชิง ไม่สามารถประกอบหน้าที่การงานใดๆ ในอาชีพ ประจำได้โดย
                  สิ้นเชิงตลอดไป
                </td>
                <td rowSpan={1} className="group">
                  300,000
                </td>
              </tr>
              <tr>
                <td>หูหนวก</td>
                <td rowSpan={7} className="group">
                  250,000
                </td>
              </tr>
              <tr>
                <td>เป็นใบ้ / สูญเสียความสามารถในการพูด</td>
              </tr>
              <tr>
                <td>ลิ้นขาด</td>
              </tr>
              <tr>
                <td>เสียอวัยวะสืบพันธ์ / ความสามารถ</td>
              </tr>
              <tr>
                <td>จิตพิการติดตัว</td>
              </tr>
              <tr>
                <td>สูญเสียอวัยวะอื่นใดที่ส่งผลให้การใช้ชีวิตผิดปกติ</td>
              </tr>
              <tr>
                <td>สูญเสียมือ แขน ขา เท้า ตาบอด 1 ข้าง</td>
              </tr>
              <tr>
                <td>นิ้วขาด (มือ / เท้า 1 ข้อขึ้นไป)</td>
                <td rowSpan={1} className="group">
                  200,000
                </td>
              </tr>
              <tr>
                <td>
                  บาดเจ็บ / สูญเสียอนามัยค่าเสียหายอย่างอื่น ที่สามารถเรียกร้องได้ ตามมูลความผิด
                </td>
                <td rowSpan={1} className="group">
                  80,000
                </td>
              </tr>
              <tr>
                <td>
                  เงิดชดเชยรายวัน กรณีเข้ารับการรักษาพยาบาลในฐานะผู้ป่วยใน สำหรับผู้ถูกกระทำละเมิด
                  วันละ 200 บาท ได้ไม่เกิน 20 วัน
                </td>
                <td rowSpan={1} className="group">
                  4,000
                </td>
              </tr>
            </tbody>
          </Table>
          <SmallText>
            <small>
              ผู้ขับขี่ที่เป็นฝ่ายผิด หรือ ไม่มีผู้ใดต้องรับผิดตามกฎหมาย
              จะได้รับเฉพาะค่าเสียหายเบื้องต้น เป็นค่ารักษาพยาบาลไม่เกิน 30,000 บาท
              <br />
              และ กรณีสูญเสียอวัยวะ หรือ ทุพพลภาพถาวร หรือ เสียชีวิต 35,000 บาท รวมแล้วไม่เกิน
              65,000 บาท เท่านั้น
            </small>
          </SmallText>
        </section>
        <section className="button">
          <button
            className="ImageButton"
            type="button"
            onClick={() => setIsImageFile(true)}
            disabled={!instead.policyDocumentFullPath}
          >
            <ShieldIcon
              className="EditIcon"
              name="Vector"
              color={
                instead.policyDocumentFullPath
                  ? 'var(--agt-white-color)'
                  : 'var(--agt-disable-color-2)'
              }
            />
            <div style={{ marginLeft: '14px' }}>ดูหน้ากรมธรรม์</div>
          </button>
        </section>
      </NewForm>
      {/* <NewButton>
        <button
          className="secondary"
          type="button"
          style={{ marginRight: '24px' }}
          onClick={() => setIsDelete(true)}
          disabled={instead?.createdSource === 'tqm' || currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button className="secondary" type="button" onClick={() => onEdit()} disabled>
          แก้ไข
        </button>
      </NewButton> */}
    </>
  )
}

export default PolicyActInsurance
