import React, { FC, useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import HistoryType from 'types/HistoryType'
import { InsurancePortType } from 'types/InsurancePortType'
import { useDispatch, useSelector } from 'react-redux'
import { getInsuranceById } from 'store/contactSlice'
import { RootState } from 'store/rootReducer'
import authToken from 'utils/session'
import { Container, Header, Loading, CustomIframe } from './index.styles'

const OrderDetail: FC = () => {
  let { id, insuranceId } = useParams<{ id: string; insuranceId: string; orderNo: string }>()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [insurancePort, setInsurancePort] = useState<InsurancePortType>({})
  const [tqmLink, setTQMLink] = useState<string>('')
  const iframeRef = useRef<any>(null)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { path: `/contacts/${id}`, label: insurancePort?.ownerName || 'รายละเอียดข้อมูลติดต่อ' },
    { path: `/contacts/${id}/insurance_portfolio`, label: 'สรุปกรมธรรม์' },
    { label: 'รายละเอียดคำสั่งซื้อ' },
  ]

  useEffect(() => {
    dispatch(
      getInsuranceById(setInsurancePort, setIsLoading, id, insuranceId, () => {
        history.push(`/contacts/${id}/insurance_portfolio/`)
      }),
    )
  }, [dispatch, id, insuranceId, history])

  useEffect(() => {
    if (currentAgent && insurancePort?.tqmOrderId) {
      let url = `${
        process.env.REACT_APP_TQM_LIFE
      }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${
        process.env.REACT_APP_TQM_LIFE
      }orders/${insurancePort?.tqmOrderId}/holder_info`
      setTQMLink(url)
    }
  }, [currentAgent, insurancePort])

  useEffect(() => {
    iframeRef.current = document.getElementById('order_iframe')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef.current])

  return (
    <Container>
      <Header>รายละเอียดคำสั่งซื้อ</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : (
        <CustomIframe
          id="order_iframe"
          ref={iframeRef}
          src={tqmLink}
          log
          autoResize
          inPageLinks
          scrolling
        />
      )}
    </Container>
  )
}
export default OrderDetail
