import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { ColorType } from './LandingPremiumCard.type'

export const Container = styled.div`
  width: 100%;

  .dashboard {
    width: 100%;
    height: 128px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--agt-primary-color-1);
    border-radius: 8px;
    cursor: pointer;
    .lable {
      color: var(--agt-white-color);
    }
  }
  .disabledDashboard {
    width: 100%;
    height: 128px;
    padding: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--agt-disable-color-1);
    border-radius: 8px;
    cursor: no-drop;
    .lable {
      color: var(--agt-secondary-text-color);
    }
  }
`

export const DashBoardDiv = styled.div`
  width: 100%;
  height: 381px;
  background: #ffffff;
  border: 1px solid #e6e8ec;
  border-radius: 8px;

  .LinkNextPage {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 7px;
    margin-left: auto;
    justify-content: flex-end;
    padding: 0 1rem;
  }
  .LinkText {
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    padding-right: 13.62px;
    /* identical to box height */

    text-align: center;
  }
`

export const TopDashBoard = styled.div`
  height: 179px;
  width: 100%;
  display: flex;
  flex-direction: row;
`

export const LeftDashBoardDiv = styled.div`
  height: 100%;
  width: 25%;
  display: flex;
  flex-direction: column;
`

export const RightDashBoardDiv = styled.div`
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: row;
`

export const TitterDashBoard = styled.div`
  width: 122px;
  height: 66px;
  margin-top: 8px;
  margin-left: 16px;
  margin-bottom: 8px;
  .Title {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 33px;
    text-align: left;
    color: #00529a;
  }
`

export const MiniTitterDashBoard = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  margin-left: 16px;
  .MiniTitle {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: var(--agt-tqm-pantone-1);
    white-space: nowrap;
    transition: all 0.5s;
  }
  .MiniTitleSub {
    color: var(--agt-tqm-pantone-2);
    transition: all 0.5s;
  }
`

export const HeaderMonthDiv = styled.div`
  width: 56px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .MonthTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`

export const HeaderQuarterDiv = styled.div`
  width: 60px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .QuarterTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`

export const HeaderYearDiv = styled.div`
  width: 28px;
  height: 36px;
  margin: 0 auto 0.5rem;
  text-align: center;
  .YearTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    color: #7d828e;
  }
`

export const ChartDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  padding: 1rem;
`

export const ChartMainMonth = styled.div`
  width: 100%;
  height: 110px;
  justify-content: center;
`

export const ChartMainQuarter = styled.div`
  width: 100%;
  height: 110px;

  justify-content: center;
`

export const ChartMainYear = styled.div`
  width: 100%;
  height: 110px;

  justify-content: center;
`

export const BottomDashBoard = styled.div`
  width: 100%;
  height: 179px;
`

export const ColumnData = styled.div<ColorType>`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;

  border-bottom: 1px solid #e6e8ec;
  .Header {
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 26px;
    /* identical to box height */
    margin-left: 13px;
    margin-top: 7px;
    margin-bottom: 7px;
    width: 20%;
    color: ${(props) => props.color && props.color};
  }
  .ValueLife {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueHealth {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueCar {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .ValueFire {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
  .Values {
    width: 33.3%;
    margin-top: 7px;
    margin-bottom: 7px;
    text-align: center;
  }
`

export const ToggleSwitch = styled.label`
  width: 28px;
  height: 16px;
  position: relative;
  margin-top: 4px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--agt-tqm-pantone-2);
    transition: 0.4s;
    width: 100%;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 50%;
    background-color: var(--agt-white-color);
    transition: 0.4s;
    transform: translateY(50%);
  }
  input:checked + .slider {
    background-color: var(--agt-primary-color-1);
  }
  input:checked + .slider:before {
    transform: translateX(12px) translateY(50%);
  }
  .slider.round {
    border-radius: 40px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`

export const IconStyle = styled(Icon)`
  width: 16px;
  height: 13px;
  margin-top: 8px;
  margin-left: 20px;
  margin-right: 8px;
  margin-bottom: 8px;
`

export const DoughnutNoChartImage = styled.img`
  width: 100%;
`
