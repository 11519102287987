import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'routes'

export const UnauthorizedAction = {
  REDIRECT: 'redirect',
  HIDDEN: 'hidden',
  DISABLED: 'disabled',
}

const Authorize = (Component, options = {}) => {
  const { subject = '', actions = '', roles = [], params = {} } = options

  const defaultOptions = {
    unauthorizedAction: UnauthorizedAction.REDIRECT,
    unauthorizedRedirectPath: '/404',
  }

  const modifiedOptions = { ...defaultOptions, ...params }

  const App = (props) => {
    let history = useHistory()
    const [initialized, setInitialized] = useState(false)
    const [passed, setPassed] = useState(false)
    const currentAgent = useSelector((state) => state.app.currentAgent || {})
    const abilities = useSelector((state) => state.app.abilities || [])

    const checkAuthorized = useCallback(
      (requestAction, requestSubject) => {
        for (const ability of abilities) {
          const foundSubject = ability.subjects.find((subject) => subject === requestSubject)
          if (foundSubject) {
            const foundAction = ability.actions.find(
              (action) => action === requestAction || action === 'crud',
            )
            if (foundAction && ability.can) return true
          }
        }

        return false
      },
      [abilities],
    )

    useEffect(() => {
      if (currentAgent.agentType) {
        if (roles.length > 0) {
          let foundRole = roles.find((role) => role === currentAgent.agentType)
          if (foundRole) setPassed(true)
        } else {
          if (typeof actions === 'string') {
            setPassed(checkAuthorized(actions, subject))
          } else if (Array.isArray(actions)) {
            let allPassed = actions
              .map((action) => checkAuthorized(action, subject))
              .every((a) => a)
            setPassed(allPassed)
          }
        }
        setInitialized(true)
      }
    }, [currentAgent.agentType, abilities, checkAuthorized])

    if (initialized && !passed) {
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.REDIRECT) {
        history.push(modifiedOptions.unauthorizedRedirectPath)
      }
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.HIDDEN) {
        return null
      }
      if (modifiedOptions.unauthorizedAction === UnauthorizedAction.DISABLED) {
        return <Component {...props} disabled />
      }
    }

    if (initialized && passed) return <Component {...props} />

    return null
  }

  return App
}

export const HoC = (fn) => (ComponentOrOption, ...options) => {
  if (typeof ComponentOrOption === 'object') {
    return (Component) => fn(Component, ComponentOrOption)
  }
  return fn(ComponentOrOption, ...options)
}

export default HoC(Authorize)
