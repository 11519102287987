import React, { FC } from 'react'
import { ProtectedRoute, Switch } from 'routes'

import Planning from 'pages/planning/root'
import Debt from 'pages/planning/insurancePlanAndSuccess/debt/index'
import Income from 'pages/planning/insurancePlanAndSuccess/income/index'
import Health from 'pages/planning/insurancePlanAndSuccess/healthcare/index'
import NonLife from 'pages/planning/insurancePlanAndSuccess/nonLife/index'
import PlanningSummary from 'pages/planning/summary'

const BASE_PATH = '/contacts/:id/planning'

const Pages: FC = (props) => (
  <Switch>
    <ProtectedRoute path={BASE_PATH} component={Planning} exact />
    <ProtectedRoute path={`${BASE_PATH}/debt_protection`} component={Debt} exact />
    <ProtectedRoute path={`${BASE_PATH}/income_protection`} component={Income} exact />
    <ProtectedRoute path={`${BASE_PATH}/healthcare_coverage`} component={Health} exact />
    <ProtectedRoute path={`${BASE_PATH}/retirement_income`} component={Planning} exact />
    <ProtectedRoute path={`${BASE_PATH}/child_education_protection`} component={Planning} exact />
    <ProtectedRoute path={`${BASE_PATH}/savings`} component={Planning} exact />
    <ProtectedRoute path={`${BASE_PATH}/non_life`} component={NonLife} exact />
    <ProtectedRoute path={`${BASE_PATH}/summary`} component={PlanningSummary} exact />
  </Switch>
)

export default Pages
