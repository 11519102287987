import styled from '@emotion/styled'
import StarRating from 'components/StarRating'
import Icon from 'components/Icon'

export const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`

export const Header = styled.h3`
  margin-bottom: 10px;
`

export const ContentContainer = styled.div`
  width: 100%;
  max-width: 736px;
  margin: 0 auto;
  padding-top: 40px;
`

export const GroupComponents = styled.div`
  border-bottom: 1px solid #a0a8bc;
`

export const DetailComponents = styled.div`
  margin: 16px 0;
`

export const BagIcon = styled(Icon)`
  width: 23px;
  height: 23px;
  svg {
    width: 23px;
    height: 23px;
  }
`

export const NoteIcon = styled(Icon)`
  cursor: pointer;
  width: 18px;
  height: 24px;
  svg {
    width: 18px;
    height: 24px;
  }
`

export const Detail = styled.div`
  display: flex;
  margin: 16px 0;
  .dueDate {
    margin-left: 18px;
    margin-right: 24px;
  }
  .time {
    margin-right: 8px;
  }
  .remainTime {
    margin-left: 40px;
  }
  .OpportunityCard {
    width: 352px;
    b {
      color: var(--agt-primary-color-1);
    }
    .title {
      background: var(--agt-background-color-3);
      height: 48px;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 14px;
      padding-right: 6px;
    }
    .body {
      height: 386px;
      border: 1px solid var(--agt-secondary-color-1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body {
      height: 386px;
      border: 1px solid var(--agt-secondary-color-1);
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .body .card {
      width: 244px;
      max-height: 278px;
      border: 1px solid var(--agt-secondary-color-1);
      border-radius: 4px;
      padding: 4px;
      padding-top: 8px;
    }
    .body .card .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 8px;
      padding-left: 2px;
      margin-bottom: 6px;
    }
    .body .card .content {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .avatar {
      display: flex;
      align-items: center;
      padding-bottom: 6px;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      label {
        color: var(--agt-primary-color-1);
        margin-left: 8px;
      }
    }
    .body .card .content .orders {
      display: flex;
      flex-direction: column;
    }
    .body .card .content .orders .premium {
      display: flex;
      align-items: flex-start;
      padding: 12px;
      padding-bottom: 10px;
      b {
        margin-left: 8px;
        color: var(--agt-primary-color-1);
      }
    }
    .body .card .content .orders .order {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--agt-secondary-color-1);
      padding: 0 4px;
    }
    .body .card .content .orders .order .status {
      display: flex;
      justify-content: space-between;
      color: var(--agt-secondary-color-1);
    }
    .body .card .content .updatedAt {
      margin: 6px;
      align-self: end;
      color: var(--agt-secondary-color-1);
    }
    .body .card .footer {
      width: 100%;
      height: 32px;
      color: var(--agt-primary-color-1);
      background: var(--agt-light-sky-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      small {
        margin-left: 8px;
      }
    }
  }
`

export const ButtonComponents = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
`

export const DeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 0 48px 0;
  width: 352px;
  /* height: 164px; */
  background: #ffffff;
  border-radius: 4px;
`

export const StarDisplay = styled(StarRating)`
  margin-left: 32px;
`
