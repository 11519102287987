import React, { useState, useEffect, useMemo, FC } from 'react'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { Formik } from 'formik'
import { useHistory, useLocation, useParams } from 'routes'
import { isUndefined, get } from 'lodash'
import CardForm from './CardForm'
import HistoryType from 'types/HistoryType'
import { useDispatch } from 'react-redux'
import {
  createOpportunity,
  getNewOpportunityById,
  updateOpportunity,
} from 'store/opportunitiesSlice'
import initialValues from './OpportunityCard.utils'
import WebTourViewOrder from './WebTourViewOrder'
import WebTourNew from './WebTourNew'
import WebTourEdit from './WebTourEdit'
import { Container } from './OpportunityCard.styles'
import { HeaderComponent, DisplayCard } from './OpportunityCard.components'

const OpportunityCard: FC = () => {
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const { state = {} } = useLocation()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const [orders, setOrders] = useState<
    | Array<{
        affiliate: string
        companyLogoUrl: string
        companyName: string
        draftingParams: string | null
        id: string | number
        lastUpdatedAt: string
        name: string
        orderNo: string
        orderType: string
        premium: string
        salesman: string
        status: string
        submittedAt: string
      }>
    | []
  >([])
  const [events, setEvents] = useState<
    | Array<{
        id: number
        title: string
        startDate: string
        endDate: string
        colorCode: string
        allDay: boolean
        hasNotEndDate: boolean
        repeatSchedule: string
        location: string
        description: string
        typeAlert: string
        eventRelates: object[]
      }>
    | []
  >([])
  const [tasks, setTasks] = useState([])
  const [opportunity, setOpportunity] = useState<{
    canEdit: boolean
    contactAge: number
    contactAvatar: string
    contactGender: string
    contactId: string | number
    contactName: string
    contactStatus: string
    createdAt: string
    createdByType: string
    events: []
    id: string | number
    name: string
    note: string
    opportunityKey: string
    orders: []
    state: string
    status: string | null
    tasks: []
    updatedAt: string
  } | null>(null)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/opportunities', label: 'โอกาสในการขาย' },
    {
      label: get(opportunity, 'name') || 'สร้างโอกาสในการขาย',
    },
  ]

  const modifiedValue = useMemo(() => {
    if (opportunity) {
      return {
        name: get(opportunity, 'name'),
        note: get(opportunity, 'note'),
        contactId: get(opportunity, 'contactId'),
        tasks: get(opportunity, 'tasks'),
        events: get(opportunity, 'events'),
        opportunityKey: get(opportunity, 'opportunityKey'),
      }
    } else {
      return initialValues
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunity])

  useEffect(() => {
    if (!isUndefined(id)) {
      dispatch(
        getNewOpportunityById(
          id,
          (e) => {
            setOpportunity(e.data.data.opportunity)
          },
          () => {
            history.push('/opportunities/')
          },
        ),
      )
    }
  }, [dispatch, id, history])

  useEffect(() => {
    if (opportunity) {
      setOpportunity(opportunity)
      setOrders(get(opportunity, 'orders', []))
      setEvents(get(opportunity, 'events', []))
      setTasks(get(opportunity, 'tasks', []))
    }
  }, [opportunity])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (id) {
        if (storage['opportunities']['opportunityById'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, opportunityById: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      } else {
        if (storage['opportunities']['create'] === false) {
          window.localStorage.setItem(
            'webtour',
            JSON.stringify({
              ...storage,
              opportunities: { ...storage.opportunities, create: true },
            }),
          )
          setTimeout(() => {
            setIsShowHint(true)
          }, 1000)
        }
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: id ? false : true,
            opportunityById: id ? true : false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [id, setIsShowHint])

  return (
    <Container>
      {id && isEdit ? (
        <WebTourEdit visible={isShowHint} setVisible={setIsShowHint} />
      ) : id ? (
        <WebTourViewOrder visible={isShowHint} setVisible={setIsShowHint} />
      ) : (
        <WebTourNew visible={isShowHint} setVisible={setIsShowHint} />
      )}
      <HeaderComponent setIsShowHint={setIsShowHint} id={id} isEdit={isEdit} />
      <BreadCrumb links={breadCrumbArr} />
      {id && isEdit ? (
        <Formik
          initialValues={modifiedValue}
          enableReinitialize
          onSubmit={(values) => {
            let formatValues = {
              ...values,
              events: get(values, 'events', []),
              tasks: get(values, 'tasks', []),
              name: get(values, 'name', ''),
              note: get(values, 'note', ''),
              contactId: get(values, 'contactId', 0),
            }
            let newEvents: Array<number> =
              events.length > 0 && formatValues.events.length > 0
                ? formatValues.events.map((e: { id: number }) => e.id)
                : []
            let newTasks: Array<number> =
              tasks.length > 0 && formatValues.tasks.length > 0
                ? formatValues.tasks.map((e: { id: number }) => e.id)
                : []
            dispatch(
              updateOpportunity(id, { ...formatValues, tasks: newTasks, events: newEvents }, () => {
                window.location.reload()
              }),
            )
          }}
        >
          {(props) => (
            <CardForm {...props} {...opportunity} isDisabledContact={orders.length > 0} />
          )}
        </Formik>
      ) : id ? (
        <DisplayCard id={id} setIsEdit={setIsEdit} />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values) => {
            let newEvents: Array<number> = values.events.map((e: { id: number }) => e.id)
            let newTasks: Array<number> = values.tasks.map((e: { id: number }) => e.id)
            dispatch(
              createOpportunity(
                {
                  ...values,
                  tasks: newTasks,
                  events: newEvents,
                  pipelineStateId: get(state, 'pipelineStateId', 1),
                },
                (response) => {
                  let id: string = response.data.data.opportunity.id
                  history.push(`/opportunities/${id}`)
                },
              ),
            )
          }}
        >
          {(props) => <CardForm {...props} />}
        </Formik>
      )}
    </Container>
  )
}

export default OpportunityCard
