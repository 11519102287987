import React, { FC, useEffect } from 'react'
import { Link } from 'routes'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import StepImage from 'assets/images/recruitment_step.jpg'
import AIA from 'assets/images/alliance/aia.png'
import ALIFE from 'assets/images/alliance/alife.png'
import ARKANE from 'assets/images/alliance/arkane.png'
import AYUDHYA from 'assets/images/alliance/ayudhya.png'
import BKK from 'assets/images/alliance/bkk.png'
import CHUBB from 'assets/images/alliance/chubb.png'
import FWD from 'assets/images/alliance/fwd.png'
import GENERALI from 'assets/images/alliance/generali.png'
import MANULIFE from 'assets/images/alliance/manulife.png'
import MKLIFE from 'assets/images/alliance/mbklife.png'
import PLILLIP from 'assets/images/alliance/phillip.png'
import PRUDENTIAL from 'assets/images/alliance/prudential.png'
import SAMSUNG from 'assets/images/alliance/samsung.png'
import THAIINSURANCE from 'assets/images/alliance/thaiinsurance.png'
import THAILAND from 'assets/images/alliance/thailand.png'
import THAISEA from 'assets/images/alliance/thaisea.png'
import TIPLIFE from 'assets/images/alliance/tiplife.png'
import TOKIO from 'assets/images/alliance/tokio.png'
import {
  Alliance,
  Container,
  Privilege,
  PrivilegeCard,
  PrivilegeContainer,
  PrivilegeIcon,
  PrivilegeTopic,
  QnA,
  QnATopic,
  Step,
  Topic,
  Why,
  WhyHr,
  WhyTopic,
  GroupAliliance,
  Gallery,
} from './index.styles'
import { Header, QnARenderer } from './index.components'
import { QUESTION } from './index.utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

const Landing: FC = () => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  useEffect(() => {
    const nodeList = document.querySelectorAll('.example')
    const nxtBtn = document.querySelectorAll('.nxt')
    const preBtn = document.querySelectorAll('.pre')

    nodeList.forEach((item, i) => {
      let containerDimensions = item.getBoundingClientRect()
      let containerWidth = containerDimensions.width
      if (nxtBtn[i])
        nxtBtn[i].addEventListener('click', () => {
          nodeList[i].scrollLeft += containerWidth
        })
      if (preBtn[i])
        preBtn[i].addEventListener('click', () => {
          nodeList[i].scrollLeft -= containerWidth
        })
    })
    // have access to it
  }, [])

  return (
    <Container>
      <Header />
      <Privilege>
        <Topic>สิทธิประโยชน์ในการเป็นตัวแทนกับ TQM</Topic>
        <PrivilegeContainer>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_product" />
              <PrivilegeTopic>
                <b>
                  ผลิตภัณฑ์มากมายจากเครือข่าย
                  <br />
                  พันธมิตรบริษัทประกัน
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                ขยายความสามารถในการให้บริการด้านผลิตภัณฑ์ของคุณ ทั้งประกันชีวิต ประกันสุขภาพ
                ประกันรถยนต์ ประกันอุบัติเหตุ ฯลฯ
                จากผลิตภัณฑ์ของบริษัทประกันชีวิตและประกันวินาศภัยกว่า 40 ค่าย
                เพื่อให้บริการกับลูกค้าของคุณได้ครบวงจร โดยไม่ต้องยกเลิกใบอนุญาตเดิม
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <a href="https://www.tqmlife.co.th/blank">
                <button className="primary" type="button" style={{ width: 261 }}>
                  ดูผลิตภัณฑ์
                </button>
              </a>
            </div>
          </PrivilegeCard>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_tools" />
              <PrivilegeTopic>
                <b>
                  เครื่องมือช่วยทำงานให้เต็ม
                  <br />
                  ประสิทธิภาพ
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                เพียบพร้อมด้วยหลากเครื่องมือบริหารความสัมพันธ์กับลูกค้า
                ที่จะทำให้คุณไม่พลาดทุกโอกาสในการปิดการขาย ติดตามผลงาน และการบริการได้ครบทุกองศา
                รวมถึงเครื่องมือวางแผนการเงิน และการประกันภัยให้ลูกค้า และยังมีทีมงาน TQM
                ใกล้บ้านคุณ ที่จะคอยช่วยเหลือ การปิดการขายทำให้การทำงานของคุณ ให้ง่ายขึ้นไปอีกขั้น
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Link to={currentAgent ? '/home' : '/login'}>
                <button className="primary" type="button" style={{ width: 261 }}>
                  ทดลองใช้งาน 30 วัน
                </button>
              </Link>
            </div>
          </PrivilegeCard>
          <PrivilegeCard>
            <div>
              <PrivilegeIcon name="agt_register_income" />
              <PrivilegeTopic>
                <b>
                  ช่องทางในการสร้างรายได้
                  <br />
                  มากขึ้น
                </b>
              </PrivilegeTopic>
              <div className="agt-small">
                รับส่วนแบ่งรายได้จากการแนะนำผลิตภัณฑ์ของ TQM ให้กับลูกค้า พร้อมโอกาสในการสร้างทีม
                ขยายเครือข่ายทีมงานของคุณ
                ไปพร้อมกับการฝึกอบรมและพัฒนาตัวแทนโดยทีมผู้เชี่ยวชาญหลากหลายแขนงจาก TQM
              </div>
            </div>
          </PrivilegeCard>
        </PrivilegeContainer>
      </Privilege>
      <GroupAliliance>
        <Topic>พันธมิตรของเรา</Topic>
        <button type="button" className="nxt navigate">
          <FontAwesomeIcon icon={faChevronRight} />
        </button>
        <button type="button" className="pre navigate">
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>
        <Gallery>
          <div className="example">
            <Alliance>
              <img src={AIA} alt={AIA} />
            </Alliance>
            <Alliance>
              <img src={ALIFE} alt={ALIFE} />
            </Alliance>
            <Alliance>
              <img src={ARKANE} alt={ARKANE} />
            </Alliance>
            <Alliance>
              <img src={AYUDHYA} alt={AYUDHYA} />
            </Alliance>
            <Alliance>
              <img src={BKK} alt={BKK} />
            </Alliance>
            <Alliance>
              <img src={CHUBB} alt={CHUBB} />
            </Alliance>
            <Alliance>
              <img src={FWD} alt={FWD} />
            </Alliance>
            <Alliance>
              <img src={GENERALI} alt={GENERALI} />
            </Alliance>
            <Alliance>
              <img src={MANULIFE} alt={MANULIFE} />
            </Alliance>
            <Alliance>
              <img src={MKLIFE} alt={MKLIFE} />
            </Alliance>
            <Alliance>
              <img src={PLILLIP} alt={PLILLIP} />
            </Alliance>
            <Alliance>
              <img src={PRUDENTIAL} alt={PRUDENTIAL} />
            </Alliance>
            <Alliance>
              <img src={SAMSUNG} alt={SAMSUNG} />
            </Alliance>
            <Alliance>
              <img src={THAIINSURANCE} alt={THAIINSURANCE} />
            </Alliance>
            <Alliance>
              <img src={THAILAND} alt={THAILAND} />
            </Alliance>
            <Alliance>
              <img src={THAISEA} alt={THAISEA} />
            </Alliance>
            <Alliance>
              <img src={TIPLIFE} alt={TIPLIFE} />
            </Alliance>
            <Alliance>
              <img src={TOKIO} alt={TOKIO} />
            </Alliance>
          </div>
        </Gallery>
      </GroupAliliance>
      <Step src={StepImage} />
      <Why>
        <WhyTopic>ทำไมต้องเป็น TQM ?</WhyTopic>
        <div>
          TQM โบรกเกอร์ประกันภัยชั้นนำของไทย ด้วยประสบการณ์ที่มีมายาวนานกว่า 60 ปี
          ในการให้บริการครบวงจร
          <br />
          ด้วยความชำนาญทั้งในเรื่องประกันวินาศภัย ประกันชีวิต และการเงิน
          มีผลิตภัณฑ์ประกันภัยให้เลือกจากพันธมิตรประกันภัยกว่า 40 แห่ง
          <br />
          รวมถึงสาขาทั่วประเทศกว่า 100
          และพร้อมทีมงานที่พร้อมช่วยเหลือการทำงานของตัวแทนได้อย่างเต็มประสิทธิภาพ
          <br />
          และเทคโนโลยีที่จะช่วยยกระดับความสามารถในการให้บริการในฐานะที่ปรึกษาการเงินและการประกันภัยของคุณได้อย่างเต็มรูปแบบ
        </div>
        <WhyHr />
      </Why>
      <QnA>
        <QnATopic>คำถามที่พบบ่อย</QnATopic>
        {QUESTION.map((q, index) => (
          <QnARenderer key={index} {...q} />
        ))}
      </QnA>
    </Container>
  )
}

export default Landing
