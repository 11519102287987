import { ContactType } from 'types/ContactType'
import { TravelStateType, OrderType } from 'types/TravelInsuranceType'
import { initialAddress } from './utils'

export const initialContact: ContactType = {
  id: 0,
  age: 0,
  avatar: '',
  email: '',
  name: '',
  gender: '',
  nickname: '',
  firstName: '',
  lastName: '',
  birthdate: '',
  maritalStatus: '',
  prefixName: '',
  idCard: '',
  phoneNumber: '',
  occupation: '',
  entertainment: {},
  health: {},
  travel: {},
  food: {},
  position: '',
  company: '',
  facebook: '',
  lineId: '',
  canDeleted: true,
  homeAddress: initialAddress,
  currentAddress: initialAddress,
  officeAddress: initialAddress,
  idCardAddress: initialAddress,
  isSameHomeAddress: false,
  isSameCurrentAddress: false,
  isSameIdCardAddress: false,
  updatedAt: '',
  createdAt: '',
}

export const initialOrder: OrderType = {
  id: null,
  name: '',
  companyName: null,
  companyLogoUrl: null,
  orderNo: null,
  orderType: null,
  status: null,
  premium: null,
  draftingParams: null,
  salesman: null,
  affiliate: null,
  taInsurance: {
    productDetails: {
      companyLogoUrl: '',
      companyName: '',
      netAmount: '',
      minAge: '',
      maxAge: '',
      coverDetail: null,
      insureLevel: '',
      travelTypeCountry: '',
    },
    countryId: '',
    travelCountryTo: '',
    travelCountryFrom: '',
    travelDateFrom: '',
    travelDateTo: '',
    travelPassenger: '',
    travelType: '',
    travelTypeCountry: '',
    travelBy: '',
    policyForm: '',
    outboundFlightNumber: '',
    returnFlightNumber: '',
    orderPolicyBeneficiary: [],
    policyAddress: null,
  },
  lastUpdatedAt: null,
  submittedAt: null,
}

export const initialState: TravelStateType = {
  initialized: false,
  error: null,
  options: {
    contact: initialContact,
    insuranceCompanyOptions: [],
    addressOptions: [],
    travelTypeCountryOptions: [],
    travelTypeOptions: [],
    provinceOptions: [],
    nationalityOptions: [],
    familyRelationshipOptions: [],
    prefixNameEnOptions: [],
    prefixNameThOptions: [],
  },
  optionsByTypeTa: {
    taCountries: [],
  },
  travelInsurances: [],
  order: {
    contact: initialContact,
    order: initialOrder,
  },
  loading: {
    options: false,
    search: false,
    submit: false,
  },
}
