import React, { FC, useState, useEffect, Fragment } from 'react'
import styled from '@emotion/styled'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { useHistory } from 'routes'
import moment from 'moment'
import ButtonLink from 'components/ButtonLink'
import Select from 'react-select'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import Icon from 'components/Icon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import TabSideBar from '../../opportunities/root/TabSideBar'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { dateFormatThai, dayOfWeek } from 'utils/helper'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import HistoryType from 'types/HistoryType'
import { getEvents, getEventsByDate, getTasks } from 'store/eventsSlice'
import Hint from 'components/Hint'
import WebTour from './WebTour'
import SetStateType from 'types/SetStateType'

type EventType = {
  id: number
  title: string
  allDay: boolean
  start: Date
  end: Date
}
type TaskType = {
  id: number
  title: string
  type: string
  start: Date
  end: Date
}
type SelectorComponentType = {
  value: string
  options: Array<any>
  setView: Function
}

const Container = styled.div`
  justify-content: space-around;
  align-items: center;
  width: 100%;
  .components {
    display: flex;
  }
  .components .bigcalendar {
    width: 75%;
  }
  .components .tabs {
    width: 25%;
    .bordertabs {
      width: 100%;
      height: 100%;
      border-left: 1px solid var(--agt-background-color-2);
      border-right: 1px solid var(--agt-background-color-2);
      border-bottom: 1px solid var(--agt-background-color-2);
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .today {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: var(--agt-primary-color-1);
      color: var(--agt-white-color);
      padding: 8px 0;
      text-align: center;
    }
  }
`
const Header = styled.div`
  display: flex;
  align-items: center;
  height: 106px;
  padding: 0 46px;
  background: var(--agt-background-color-4);
  color: var(--agt-primary-color-1);
`
const CalendarComponent = styled(Calendar)`
  min-height: 700px;

  .rbc-time-view {
    flex: none;
    border: none;

    .rbc-time-header {
      .rbc-time-header-content {
        border-left: 1px solid white;

        .rbc-row.rbc-time-header-cell {
          min-height: 50px;

          .rbc-header {
            text-align: left;
            font-weight: normal;
            border-left: 1px solid white;
          }
        }

        .rbc-allday-cell {
          .rbc-day-bg:first-of-type {
            border-left: 1px solid #ddd;
          }
          .rbc-day-bg:last-of-type {
            border-right: 1px solid #ddd;
          }
        }
      }
    }

    .rbc-time-header.rbc-overflowing {
      /* border-right: none; */
    }

    .rbc-time-content {
      border: 1px solid #ddd;
      border-left: 1px solid white;
      border-bottom: none;
      .rbc-events-container {
        display: flex;
        margin-right: unset;
        font-size: 12px;
      }
      .rbc-event-content {
        line-height: normal;
      }

      .rbc-event-label {
        display: none;
      }
    }

    .rbc-today {
      background-color: rgba(60, 174, 163, 0.15);
    }
  }

  .rbc-month-view {
    flex: none;
    height: 930px;
    border: none;
    border-bottom: 1px solid #ddd;

    .rbc-header {
      text-align: left;
      border-left: none;
      padding: 0 8px;
    }

    .rbc-month-row {
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;

      .rbc-row-bg {
        .rbc-off-range-bg {
          background-color: transparent;
        }
      }
      .rbc-row-content {
        .rbc-row {
          .rbc-date-cell.rbc-off-range {
            color: var(--agt-secondary-text-color);
          }
          .rbc-now {
            a {
              height: 30px;
              width: 30px;
              background-color: #ccdceb;
              border-radius: 50%;
              padding-top: 3px;
              text-align: center;
              display: inline-block;
            }
          }
        }
        .rbc-row:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
`
const Menu = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 19px 0px;
  .select-side {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .sorting-side {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
  .create-side {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: 0;
    left: 89.2%;
    @media screen and (device-width: 1024px) and (device-height: 768px) {
      left: 81.2%;
    }
  }
`
const LeftIcon = styled(Icon)`
  height: 20px;

  div {
    height: 20px;
  }

  path {
    stroke: var(--agt-primary-color-1);
  }
`
const RightIcon = styled(LeftIcon)`
  margin-top: -3px;

  transform-origin: center;
  transform: rotate(180deg);
`
const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '110px',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '275px',
    overflowY: 'auto',
    boxSizing: 'border-box',
    borderRadius: '4px',
    width: '172px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: '#ffffff',
    position: 'absolute',
    zIndex: ' 999 !important',
  }),
}
const Selector = styled(Select)``
const PrevPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--agt-primary-color-1);
  padding: 0 20px;
  cursor: pointer;
`
export const CurrentDay = styled.button`
  width: 110px;
  margin-right: 24px;
`
const DatePickerStyle = styled(DatePicker)`
  width: 160px;
`
const ShowMonth = styled.span`
  white-space: nowrap;
  margin-right: 24px;
  z-index: 999;
`
const TitleOverFlow = styled.div`
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    overflow: visible;
    white-space: normal;
  }
`
const CheckBoxContainer = styled.label`
  background: var(--agt-background-color-4);
  cursor: pointer;
  font-size: 17px;
  align-items: center;
  width: 190px;
  color: black;
  padding: 11px 20px;
  border-radius: 4px;
  margin-right: 20px;
  :hover {
    background: var(--agt-background-color-3);
  }
  input {
    margin-right: 10px;
  }
  input,
  label {
    cursor: pointer;
  }
`
const IconContainer = styled.div`
  background: #a0a8bc;
  display: inline;
  padding: 0 5px;
  margin-right: 5px;
`

const setDateTime = (events) => {
  const newEvents: any = []
  events.map((event) =>
    newEvents.push({ ...event, start: new Date(event.start), end: new Date(event.end) }),
  )
  return newEvents
}

const countDateDuplicates = (arr) => {
  const newValues: any = []
  const counts = {}
  let id = 0
  arr.forEach((x) => {
    counts[x] = (counts[x] || 0) + 1
  })
  for (const property in counts) {
    newValues.push({
      id: id,
      start: property,
      end: property,
      title: `${counts[property]} งานที่ต้องทำ`,
      type: 'task',
      color: '#a0a8bc',
    })
    id++
  }
  return newValues
}

function eventStyleGetter(event) {
  var style = {
    backgroundColor: event.color + '66',
    color: 'black',
    borderRadius: '0px',
    borderLeft: event.type === 'task' ? '0px' : `10px solid ${event.color}`,
    padding: event.type === 'task' ? '2px 0px' : '2px 5px',
    position: 'sticky',
  }
  return {
    style: style,
  }
}

function dayStyleGetter(event) {
  var style = {
    backgroundColor: 'white',
  }
  return {
    style: style,
  }
}

const SelectorComponent = ({ value, options, setView }: SelectorComponentType) => {
  const defaultSeletor = options.find((select) => select.value === value)
  return (
    <div id="calendar__select-month">
      <Selector
        value={defaultSeletor}
        styles={customSelectStyles}
        onChange={(option: any) => {
          const value = (option as any).value
          setView(value)
        }}
        options={options}
      />
    </div>
  )
}

const ToolBarView = (props) => {
  const { currentAgent } = props
  return (
    <Menu>
      <div className="select-side">
        <CurrentDay id="calendar__today-btn" onClick={() => props.onNavigate('TODAY')}>
          วันนี้
        </CurrentDay>
        <ShowMonth id="calendar__datepicker">
          <DatePickerStyle
            value={props.date}
            locale="th"
            selected={props.date}
            onChange={(date: Date) => props.onNavigate(null, date)}
            dateFormat="MMMM yyyy"
            showIcon={false}
            showMonthYearPicker
          />
        </ShowMonth>
        <SelectorComponent value={props.view} setView={props.setView} options={options} />
      </div>
      <div id="calendar__change-page" className="select-side">
        <PrevPage onClick={() => props.onNavigate('PREV')}>
          <LeftIcon name="vector_left" />
        </PrevPage>
        <PrevPage onClick={() => props.onNavigate('NEXT')}>
          <RightIcon name="vector_left" />
        </PrevPage>
      </div>
      <div id="calendar__show-task" className="create-side">
        <CheckBoxContainer>
          <input
            type="checkbox"
            id="checkButton"
            name="checkButton"
            checked={props.isShowTasks}
            onChange={() => props.setIsShowTasks(!props.isShowTasks)}
          />
          แสดงงานที่ต้องทำ
        </CheckBoxContainer>
        <ButtonLink
          id="calendar__create-new"
          link="/calendar/new"
          label="สร้างนัดหมาย"
          disabled={currentAgent?.statusTh === 'ระงับ'}
        />
      </div>
    </Menu>
  )
}

function Event({ event }) {
  return (
    <TitleOverFlow>
      {event.type === 'task' ? (
        <IconContainer>
          <FontAwesomeIcon icon={faClipboard} color="#ffffff" size="sm" />
        </IconContainer>
      ) : null}
      {event.title}
    </TitleOverFlow>
  )
}

const CustomDateHeader = (props) => {
  const { label, date, setDate } = props
  const dateStyle: any = {
    day: {
      top: '10px',
      right: moment(date).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') ? '2px' : '8px',
      position: 'relative',
      color: 'var(--agt-primary-text-color)',
    },
  }
  return (
    <div
      style={dateStyle.day}
      onClick={() => {
        setDate(moment(date).format('MM/DD/YYYY'))
      }}
    >
      {label}
    </div>
  )
}

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>นัดหมาย</h3>
      <Hint onClick={() => setIsShowHint(true)} />
    </Header>
  )
}

const options = [
  { label: 'เดือน', value: 'month' },
  { label: 'สัปดาห์', value: 'week' },
]

const Calendars: FC = (props) => {
  let history: HistoryType = useHistory()
  const localizer = momentLocalizer(moment)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [currDate, setCurrDate] = useState<string>('')
  const [events, setEvents] = useState<Array<EventType>>([])
  const [tasks, setTasks] = useState<Array<TaskType>>([])
  const [tasksTab, setTasksTab] = useState([])
  const [eventsTab, setEventsTab] = useState([])
  const [view, setView] = useState<'month' | 'week'>('month')
  const [isShowTasks, setIsShowTasks] = useState<boolean>(true)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)
  const dispatch = useDispatch()
  const allData = isShowTasks ? [...tasks, ...events] : [...events]

  useEffect(() => {
    dispatch(
      getEvents((response) => {
        setEvents(setDateTime(response.data.data.events))
      }),
    )
    dispatch(
      getTasks((response) => {
        let tasks = response.data.data.tasks
        let newTasks: any = []
        Object.values(tasks).forEach((task: any) => task.map((m) => newTasks.push(m.dueDate)))
        setTasks(setDateTime(countDateDuplicates(newTasks)))
      }),
    )
    let today = moment(new Date()).format('MM/DD/YYYY')
    setCurrDate(today)
    dispatch(
      getEventsByDate(today, (response) => {
        setEventsTab(response.data.data.events)
        setTasksTab(setDateTime(response.data.data.tasks))
      }),
    )
  }, [dispatch])

  useEffect(() => {
    if (currDate)
      dispatch(
        getEventsByDate(currDate, (response) => {
          setEventsTab(response.data.data.events)
          setTasksTab(setDateTime(response.data.data.tasks))
        }),
      )
  }, [dispatch, currDate])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['calendar']['calendar'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({ ...storage, calendar: { ...storage.calendar, calendar: true } }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: true, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Fragment>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <Container>
        <HeaderComponent setIsShowHint={setIsShowHint} />
        <div className="components">
          <div id="calendar__big-calendar" className="bigcalendar">
            <CalendarComponent
              popup
              messages={{
                showMore: (total) => <div>{`+ อีก ${total}`}</div>,
              }}
              localizer={localizer}
              view={view}
              views={[view]}
              events={allData}
              components={{
                toolbar: (props) => (
                  <ToolBarView
                    {...props}
                    view={view}
                    setView={setView}
                    setIsShowTasks={setIsShowTasks}
                    isShowTasks={isShowTasks}
                    currentAgent={currentAgent}
                  />
                ),
                week: {
                  header: ({ date, localizer }) => (
                    <div>
                      <b>{date.getDate()}</b>
                      <br />
                      วัน{localizer.format(date, 'dddd')}
                    </div>
                  ),
                },
                month: {
                  header: ({ date, localizer }) => `วัน${localizer.format(date, 'dddd')}`,
                  dateHeader: (props) => <CustomDateHeader {...props} setDate={setCurrDate} />,
                },
                event: Event,
              }}
              onSelectEvent={(event) =>
                event.type === 'task'
                  ? history.push(`/tasks`)
                  : history.push(`/calendar/${event.id}`)
              }
              startAccessor="start"
              endAccessor="end"
              eventPropGetter={eventStyleGetter}
              dayPropGetter={dayStyleGetter}
            />
          </div>
          <div className="tabs" style={{ paddingLeft: 24, paddingTop: 108 }}>
            <div id="calendar__sidebar" className="bordertabs">
              <div className="today">
                {moment(currDate).format('DD/MM/YYYY') ===
                moment(new Date()).format('DD/MM/YYYY') ? (
                  <b>
                    วัน{dayOfWeek(currDate)}ที่ {dateFormatThai(currDate)}
                  </b>
                ) : (
                  <>
                    วัน{dayOfWeek(currDate)}ที่ {dateFormatThai(currDate)}
                  </>
                )}
              </div>
              <TabSideBar
                header="นัดหมาย"
                events={eventsTab}
                addition={false}
                addEventRelates={true}
                forceExpand={true}
                selectedDate={currDate}
                currentAgent={currentAgent}
              />
              <TabSideBar
                header="งานที่ต้องทำ"
                tasks={tasksTab.filter(
                  ({ dueDate }) => moment(dueDate).format('MM/DD/YYYY') === currDate,
                )}
                addition={false}
                addEventRelates={true}
                forceExpand={true}
                currentAgent={currentAgent}
              />
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

export default Calendars
