import React, { useEffect, useState, useMemo } from 'react'
import styled from '@emotion/styled'
import { ErrorMessage, Form, Field, useField } from 'formik'
import { Link, useHistory } from 'routes'
import moment from 'moment'
import Select from 'react-select'
import Api from 'api'
import { dateFormat, getGender } from 'utils/helper'
import isUndefined from 'lodash/isUndefined'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isEqual from 'lodash/isEqual'

import Modal from 'components/Modal'
import Avatar from 'components/Avatar'

import TabSideBar from './TabSideBar'
import OrdersCard from './OrdersCard'
import ContactCard from './ContactCard'

import SetStateType from 'types/SetStateType'

type OpportunityType = {
  value: number
  label: string
}

type OpportunityComponentType = {
  name: string
  contacts: Array<OpportunityType>
  isDisabledContact: boolean
  onChange: Function
}

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 280px;
  padding: 32px 37px 40px 37px;
  p:first-of-type {
    margin-top: 24px;
  }
  p:nth-of-type(2) {
    font-weight: bold;
  }
  p {
    margin-bottom: 8px;
  }
  span {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
  b:last-of-type {
    color: var(--agt-error-color);
  }
`

const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid #A0A8BC',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '100px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid #A0A8BC',
    background: '#ffffff',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}

const Selector = styled(Select)`
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

const OpportunityComponent = ({
  name,
  contacts,
  isDisabledContact = false,
  onChange,
}: OpportunityComponentType) => {
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = contacts.find((select) => select.value === value)
  return (
    <Selector
      value={defaultSeletor}
      onChange={(option: OpportunityType) => {
        const value = (option as OpportunityType).value
        setValue(value)
        onChange()
      }}
      isDisabled={isDisabledContact}
      options={contacts}
      styles={customSelectStyles}
      placeholder="เลือกหรือพิมพ์เพื่อค้นหา"
    />
  )
}

const Container = styled.div`
  height: 100%;
  Body {
    width: 100%;
    height: 100%;
    display: flex;
    padding-left: 94px;
  }
  .Body .Title {
    width: ${(764 / 1120) * 100}%;
    max-width: 764px;
    padding-top: 24px;
    padding-right: 32px;
  }
  .Submit {
    border-top: 1px solid var(--agt-secondary-color-1);
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .Body .Sub {
    border-left: 1px solid var(--agt-secondary-color-1);
    border-right: 1px solid var(--agt-secondary-color-1);
    width: ${(254 / 1120) * 100}%;
    max-width: 254px;
    height: 100%;
  }
  .Body .RowLayout {
    display: flex;
    justify-content: space-between;
  }
  .Body .RowLayout .Column {
    display: flex;
    flex-direction: column;
    width: 352px;
    margin-bottom: 24px;
    @media only screen and (max-width: 1024px) {
      width: 300px;
    }
  }
  .Body .RowLayout .SearchInsurance {
    width: 352px;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    > div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    @media only screen and (max-width: 1024px) {
      width: 300px;
    }
  }
`
const GetText = ({
  toState,
  name,
  contactName,
  contactAvatar,
}: {
  toState: string
  name: string
  contactName: string
  contactAvatar: string
}) => {
  const contactNameInfo = contactName.split(' ')
  return (
    <ModalBody>
      <Avatar type="circle" width="100px" height="100px" avatar={contactAvatar} />
      <p className="">เปลี่ยนสถานะของโอกาสในการขาย</p>
      <p>{name}</p>
      <p>
        ซึ่งเกี่ยวข้องกับ&nbsp;
        <span>
          {contactNameInfo[0]}
          {!isEmpty(contactNameInfo[1]) ? <>&nbsp;{contactNameInfo[1]}</> : null}
          &nbsp;
        </span>
        <b>{toState === 'won' ? 'เป็น ขายสำเร็จ' : 'เป็น ขายไม่สำเร็จ'}</b>
      </p>
    </ModalBody>
  )
}

const Error = styled.small`
  color: var(--agt-error-color);
`

const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  } else if (parseFloat(value) === 0) {
    error = message || 'กรุณาระบุ'
  }
  return error
}

const CardForm = (props) => {
  const { values = {}, isDisabledContact = false, id = null, setFieldValue, ...rest } = props
  const history = useHistory()
  const [onClickContact, setOnClickContact] = useState(false)
  const [contacts, setContacts] = useState<Array<OpportunityType>>([])
  const defaultConfirm = {
    id: 0,
    isOpen: false,
    name: '',
    contactName: '',
    toState: '',
    contactAvatar: '',
  }
  const [confirmUpdateStatus, setConfirmOpen] = useState(defaultConfirm)
  const [completedContact, setCompletedContact] = useState({
    id: '',
    nickname: '',
    phoneNumber: '',
    prefixName: '',
    firstName: '',
    lastName: '',
    gender: '',
    birthdate: '',
  })
  const [contactName, setContactName] = useState('')

  function getAgeByContactId(birthdate: string) {
    let age: number = 0
    let dateToday = moment().format('YYYY-MM-DD')
    let initial = moment(birthdate.split('T')[0], 'YYYY-MM-DD')
    let final = moment(dateToday, 'YYYY-MM-DD')
    age = final.diff(initial, 'years')
    return age
  }

  const getContacts = async (setContacts: SetStateType<Array<OpportunityType>>) => {
    try {
      const response = await Api.get('/tasks/configs')
      setContacts(response.data.data.contacts)
    } catch (err) {
      if (err && err.response) {
        return err.response
      }
      throw err
    }
  }

  const getContactById = async (id: number) => {
    try {
      const response = await Api.get(`/contacts/${id}`)
      let mockupContact = response.data.data.contact
      setCompletedContact({
        ...completedContact,
        id: get(mockupContact, 'id', ''),
        nickname: get(mockupContact, 'nickname', ''),
        firstName: get(mockupContact, 'firstName', ''),
        lastName: get(mockupContact, 'lastName', ''),
        phoneNumber: get(mockupContact, 'phoneNumber', ''),
        prefixName: get(mockupContact, 'prefixName', ''),
        gender: get(mockupContact, 'gender', ''),
        birthdate: get(mockupContact, 'birthdate', ''),
      })
      setContactName(get(mockupContact, 'name', ''))
    } catch (err) {
      if (err && err.response) {
        return err.response
      }
      throw err
    }
  }

  const updateStatus = async (id: number, status: string) => {
    try {
      await Api.put(`/opportunities/${id}/update_status`, { status: status })
      history.push(`/opportunities/`)
    } catch (err) {
      if (err && err.response) {
        if (err && err.response) {
          return err.response
        }
        throw err
      }
    }
  }

  const afterFillContact = (modifiedValues: {}) => {
    setOnClickContact(false)
    getContacts(setContacts)
    getContactById(values.contactId)
  }

  const isHiddenButton = useMemo(() => {
    for (const [key] of Object.entries(completedContact)) {
      let valueByLodash = get(completedContact, `${key}`)
      if (key === 'id' && isEqual(valueByLodash, '')) {
        return false
      } else if (key === 'phoneNumber' || key === 'nickname') {
      } else if (!isEmpty(valueByLodash) || !isNull(valueByLodash)) {
      } else {
        return true
      }
    }
    return false
  }, [completedContact])

  useEffect(() => {
    getContacts(setContacts)
  }, [])

  useEffect(() => {
    if (values.contactId) {
      getContactById(values.contactId)
    }
    setConfirmOpen({
      ...confirmUpdateStatus,
      id: id,
      name: rest.name,
      contactName: rest.contactName,
      contactAvatar: rest.contactAvatar,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.contactId])

  return (
    <Container>
      {confirmUpdateStatus.isOpen && (
        <Modal
          isOpen={confirmUpdateStatus.isOpen}
          onConfirm={() => updateStatus(confirmUpdateStatus.id, confirmUpdateStatus.toState)}
          onCancel={() => setConfirmOpen({ ...confirmUpdateStatus, isOpen: false })}
        >
          <GetText {...confirmUpdateStatus} />
        </Modal>
      )}
      <div className="Body">
        <Form className="Title" onSubmit={props.handleSubmit}>
          <div className="Detail">
            <div className="RowLayout">
              <div className="Column">
                <div className="RowLayout" style={{ alignItems: 'center' }}>
                  <div id="opportunity__topic">หัวข้อ</div>
                  <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                    {values?.opportunityKey || ''}
                  </small>
                </div>
                <Field
                  name="name"
                  type="string"
                  placeholder="โปรดระบุ"
                  validate={validator('กรุณาระบุหัวข้อ')}
                />
                <ErrorMessage component={Error} name="name" />
              </div>
              <div className="Column" style={{ marginBottom: '0' }}>
                <div id="opportunity__detail">ลูกค้าที่เกี่ยวข้อง</div>
                <OpportunityComponent
                  name="contactId"
                  contacts={contacts}
                  isDisabledContact={isDisabledContact}
                  onChange={() => setOnClickContact(false)}
                />
                <Field
                  hidden={true}
                  name="contactId"
                  validate={validator('กรุณาระบุลูกค้าที่เกี่ยวข้อง')}
                />
                <ErrorMessage component={Error} name="contactId" />
                {values.contactId && (
                  <>
                    {isHiddenButton ? (
                      <div
                        style={{
                          textAlign: 'left',
                          marginTop: '4px',
                        }}
                      >
                        <label
                          style={{
                            textDecorationLine: 'underline',
                            color: 'var(--agt-primary-color-1)',
                            cursor: 'pointer',
                          }}
                          onClick={() => setOnClickContact(!onClickContact)}
                        >
                          คลิกเพื่อกรอกรายละเอียด
                        </label>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          marginTop: '4px',
                        }}
                      >
                        <div>
                          อายุ&nbsp;
                          {getAgeByContactId(
                            completedContact.birthdate
                              ? completedContact.birthdate
                              : moment(new Date()).format('YYYY-MM-DD'),
                          )}
                          &nbsp;ปี&nbsp;เพศ&nbsp;
                          {getGender(completedContact.gender)}
                        </div>
                        <Link to={`/contacts/${values.contactId}`}>รายละเอียดลูกค้า</Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="RowLayout">
              <div className="Column">
                <div>โน้ตเพิ่มเติม</div>
                <Field
                  name="note"
                  type="string"
                  component="textarea"
                  placeholder="รายละเอียดเพิ่มเติม"
                />
              </div>
              {id && (
                <div className="SearchInsurance">
                  <Link to={`/opportunities/${id}/catalog`}>
                    <button id="opportunity__find-insurance" disabled={isHiddenButton}>
                      {rest.orders && rest.orders.length > 0 ? 'เพิ่มคำสั่งซื้อ' : 'ค้นหาแบบประกัน'}
                    </button>
                  </Link>
                  <div>
                    <small>อัปเดทล่าสุด {dateFormat(rest.updatedAt)}</small>
                    <small style={{ color: 'var(--agt-secondary-color-1)' }}>
                      สร้างเมื่อ {dateFormat(rest.createdAt)}
                    </small>
                  </div>
                </div>
              )}
            </div>
            <ContactCard
              contact={completedContact}
              isForceOpen={isHiddenButton}
              onClickContact={onClickContact}
              afterFillContact={(e) => {
                afterFillContact(e)
              }}
            />
            {!isUndefined(id) && <OrdersCard orders={rest.orders} id={id} />}
          </div>
          {!onClickContact && (
            <div
              className="Submit"
              style={
                id && rest.orders.length === 0 && isEqual(values.contactId, rest.contactId)
                  ? {}
                  : { justifyContent: 'flex-end' }
              }
            >
              {id && rest.orders.length === 0 && isEqual(values.contactId, rest.contactId) && (
                <button
                  type="button"
                  className="secondary error"
                  style={{ borderColor: 'var(--agt-error-color)' }}
                  onClick={() =>
                    setConfirmOpen({
                      ...confirmUpdateStatus,
                      isOpen: true,
                      toState: 'lose',
                    })
                  }
                >
                  ขายไม่สำเร็จ
                </button>
              )}
              <button
                type="submit"
                className="primary"
                disabled={props.isSubmitting || !props.dirty}
              >
                {props.isSubmitting ? 'กำลังดำเนินการ' : 'บันทึก'}
              </button>
            </div>
          )}
        </Form>
        <div id="opportunity__other-detail" className="Sub">
          <TabSideBar
            header="นัดหมาย"
            events={values.events}
            contactId={values.contactId}
            contactName={contactName}
            onSelected={(e) => {
              setFieldValue('events', e)
            }}
          />
          <TabSideBar
            header="งานที่ต้องทำ"
            tasks={values.tasks}
            contactId={values.contactId}
            contactName={contactName}
            onSelected={(e) => {
              setFieldValue('tasks', e)
            }}
          />
        </div>
      </div>
    </Container>
  )
}
export default CardForm
