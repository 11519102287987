import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import get from 'lodash/get'
import { useParams } from 'routes'
import { Field, FieldArray, Form, Formik } from 'formik'
import SelectInput from 'pages/planning/share/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import Currency from 'pages/planning/share/Currency'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  createDebtProtections,
  getDebtProtectionOptions,
  getDebtProtections,
} from 'store/planningSlice'
import Model from 'components/Modal'
import { isEmpty } from 'lodash'

type PropType = {
  nextStep: Function
}
const Container = styled(Form)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 42px;
  padding-right: 9.52%;
  padding-left: 9.52%;
`
const Text = styled.div`
  margin-bottom: 24px;
  margin-left: 25px;
`
const Line = styled.div`
  height: 1px;
  background: var(--agt-secondary-color-1);
`
const ButtonContainer = styled.div`
  display: flex;
  padding-top: 24px;
  justify-content: center;
`
const SubmitButton = styled.button`
  width: 183px;
  height: 48px;
  align-self: center;
`
const AddButton = styled.button`
  width: 219px;
  color: var(--agt-primary-color-1);
  background: white;
  border: 1px solid var(--agt-primary-color-1);
  border-radius: 5px;
  margin-right: 24px;
  :hover {
    color: white;
    background: var(--agt-primary-color-1);
  }
`
const TextNoDetailList = styled.div`
  text-align: center;
  color: #acb1bf;
  margin-bottom: 24px;
`
const InputContainer = styled.div`
  display: grid;
  grid-template-columns: 42% 42% auto auto;
  grid-column-gap: 2%;
  align-items: baseline;
`
const Unit = styled.div`
  width: fit-content;
`
const CustomSelect = styled(SelectInput)`
  > div {
    width: 100%;
    > div:first-of-type {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`
const CustomInput = styled(Currency)`
  > div {
    width: 100%;
    > input {
      width: 100%;
    }
    > div:last-of-type {
      display: none;
    }
  }
`
const ButtonDelete = styled.div`
  cursor: pointer;
`
const ModelContainer = styled.div`
  width: 352px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const TextModel = styled.div`
  text-align: center;
  margin-bottom: 8px;
`
const TextModelBlueColor = styled.div`
  text-align: center;
  color: var(--agt-primary-color-1);
`
function fullNameDebtType(options, object = {}) {
  let fullNameDebt: string = ''
  if (!isEmpty(options) && get(object, 'debtType') !== '') {
    let type: string = get(object, 'debtType')
    fullNameDebt = options.find((item) => item.value === type).label
  }
  return fullNameDebt
}

const Pages: FC<PropType> = (props) => {
  const dispatch = useDispatch()
  const debtData = useSelector((state: RootState) => state.plans.debtData)
  const options = useSelector((state: RootState) => state.plans.options)
  const { id }: any = useParams()
  const [isOpen, setIsOpen] = useState(-1)

  useEffect(() => {
    dispatch(getDebtProtections(id))
    dispatch(getDebtProtectionOptions(id))
  }, [dispatch, id])

  return (
    <Formik
      initialValues={{ debtData }}
      enableReinitialize
      onSubmit={(values) => {
        dispatch(createDebtProtections(id, values))
        props.nextStep()
      }}
    >
      {(props) => (
        <Container onSubmit={props.handleSubmit}>
          {!get(props.values, 'debtData', false) ||
          get(props.values, 'debtData', []).length === 0 ? (
            <TextNoDetailList>ใส่รายละเอียดภาระหนี้สินเพื่อใช้ในการคำนวณ</TextNoDetailList>
          ) : (
            <InputContainer>
              <Text>ประเภท</Text>
              <Text>มูลค่าหนี้สินคงค้าง</Text>
            </InputContainer>
          )}
          <FieldArray name="debtData">
            {({ push, remove }) => (
              <>
                {props.values.debtData.map((item, index) => {
                  return (
                    <InputContainer key={index}>
                      <Field
                        name={`debtData.${index}.debtType`}
                        component={CustomSelect}
                        placeholder="โปรดระบุ"
                        options={options.debtTypeOptions}
                      />
                      <Field
                        name={`debtData.${index}.debtValue`}
                        component={CustomInput}
                        placeholder="โปรดระบุ"
                      />
                      <Unit>บาท</Unit>
                      <ButtonDelete
                        onClick={() => {
                          if (!isEmpty(item) && get(item, 'debtType') !== '') {
                            setIsOpen(index)
                          } else {
                            remove(index)
                          }
                        }}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </ButtonDelete>
                      <Model
                        isOpen={isOpen === index}
                        onCancel={() => setIsOpen(-1)}
                        onConfirm={() => {
                          remove(index)
                          setIsOpen(-1)
                        }}
                      >
                        <ModelContainer>
                          <TextModel>ยืนยันการลบข้อมูลภาระหนี้สิน</TextModel>
                          <TextModelBlueColor>
                            {fullNameDebtType(options.debtTypeOptions, item)}
                          </TextModelBlueColor>
                        </ModelContainer>
                      </Model>
                    </InputContainer>
                  )
                })}
                <Line />
                <ButtonContainer>
                  <AddButton
                    type="button"
                    onClick={() => {
                      push({ debtType: '', debtValue: '' })
                    }}
                  >
                    + เพิ่มรายละเอียดหนี้สิน
                  </AddButton>
                  <SubmitButton type="submit">บันทึกและถัดไป</SubmitButton>
                </ButtonContainer>
              </>
            )}
          </FieldArray>
        </Container>
      )}
    </Formik>
  )
}

export default Pages
