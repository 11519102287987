import React, { FC } from 'react'
import styled from '@emotion/styled'

import Avatar from 'components/Avatar'
import Icon from 'components/Icon'
import { currency } from 'utils/helper'

type ComparingType = {
  logo: string
  onClose: Function
  insuranceName: string
  companyName: string
  netAmount: number | string
  promotion: string
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 284px;
  /* height: 300px; */
  padding: 1rem;
  border: 2px solid var(--agt-primary-color-1);
  border-radius: 0.5rem;
  overflow: hidden;
  color: var(--agt-primary-color-1);
  font-weight: bold;
`
const CloseButton = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    width: 24px;
    height: 24px;
  }
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: #393d43;
      }
    }
  }
`
const Logo = styled(Avatar)`
  min-height: 120px;
  margin-bottom: 0.5rem;
`
const Name = styled.p`
  width: 180px;
  text-align: center;
  font-weight: bold;
`
const Price = styled.div`
  font-size: 24px;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
`
const NewPromotion = styled.small`
  width: 160px;
  text-align: center;
  font-weight: normal;
`

const CompanyName = styled.div``

const ComparingCard: FC<ComparingType> = (props) => {
  const { logo, onClose = () => {}, insuranceName, companyName, netAmount, promotion } = props
  return (
    <Container>
      <CloseButton onClick={() => onClose()}>
        <Icon name="close_button" />
      </CloseButton>
      <Logo avatar={logo} width="120px" height="120px" type="circle" />
      <Name>{insuranceName}</Name>
      <Price>{currency(netAmount, 2, '฿ ')}</Price>
      {promotion && <NewPromotion>{promotion}</NewPromotion>}
      <CompanyName>{companyName}</CompanyName>
    </Container>
  )
}

export default ComparingCard
