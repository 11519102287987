import React, { FC, useState, useCallback, Fragment, useMemo } from 'react'
import styled from '@emotion/styled'
import { useField } from 'formik'
import { useSelector } from 'react-redux'

import Icon from 'components/Icon'
import Modal from 'components/Modal'

import { MemberFieldType, MemberSelectorType } from 'types/DashboardType'
import { RootState } from 'store/rootReducer'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const MemberButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    height: 24px;
    margin-right: 0.5rem;
  }
`
const StyledModal = styled(Modal)`
  min-height: unset !important;
`
const MemberContainer = styled.div`
  width: 400px;
  max-height: 80vh;
  overflow-x: auto;
`
const MemberHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 0 13px 0 24px;
  background-color: var(--agt-background-color-3);

  > div:last-of-type {
    height: 24px;
    cursor: pointer;
  }
`
const MemberTopic = styled.div`
  color: var(--agt-primary-color-1);
`
const MemberCheckboxes = styled.div`
  width: 100%;
  padding: 1.5rem;
`
const MemberRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`
const MemberCheckbox = styled.input`
  height: 16px !important;
  margin-right: 1.5rem;
  cursor: pointer;
`
const MemberAvatar = styled.img`
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  margin-right: 10px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
  background-color: var(--agt-background-color-2);
`
const MemberName = styled.label`
  cursor: pointer;

  &.all {
    font-weight: bold;
    color: var(--agt-primary-color-1);
  }
`
const MemberSubmitButton = styled.button`
  display: block;
  margin: 0 auto;
`

const MemberField = ({ name, onSubmit = () => {}, options = [] }: MemberFieldType) => {
  const [, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  const _handleOnChange = useCallback(
    (e: string) => {
      setValue(e)
    },
    [setValue],
  )

  return (
    <MemberCheckboxes>
      <MemberRow onClick={() => _handleOnChange('all')}>
        <MemberCheckbox name="member.all" type="radio" value={'all'} checked={value === 'all'} />
        <MemberName htmlFor="member.all" className="all">
          ทั้งหมด
        </MemberName>
      </MemberRow>
      {options.map((o, index: number) => {
        return (
          <MemberRow key={index} onClick={() => _handleOnChange(o.value)}>
            <MemberCheckbox
              name={`member.${o.value}`}
              type="radio"
              value={o.value}
              checked={value === o.value}
            />
            <Member avatar={o.avatar} label={o.label} value={o.value} />
          </MemberRow>
        )
      })}

      <MemberSubmitButton
        onClick={() => {
          onSubmit(value)
        }}
      >
        เลือก
      </MemberSubmitButton>
    </MemberCheckboxes>
  )
}

const MemberSelector: FC<MemberSelectorType> = ({
  selectedMember,
  options = [],
  onChange = () => {},
}) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [isOpen, setOpen] = useState<boolean>(false)

  const onButtonClick = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const onClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const selectedValue = useMemo(() => {
    if (selectedMember === 'all') return
    const foundMember = options.find((o) => o.value === selectedMember)
    if (foundMember) {
      return (
        <Member avatar={foundMember.avatar} label={foundMember.label} value={foundMember.value} />
      )
    }
    return null
  }, [selectedMember, options])

  if (currentAgent?.isLeader) {
    return (
      <>
        <Container>
          {selectedValue}
          <MemberButton className="secondary" type="button" onClick={() => onButtonClick()}>
            <Icon name="people" />
            เลือกสมาชิก
          </MemberButton>
        </Container>
        <StyledModal
          isOpen={isOpen}
          onCancel={() => onClose()}
          onConfirm={() => {}}
          hiddenButton
          hiddenClose
        >
          <MemberContainer>
            <MemberHeader>
              <MemberTopic>
                <b>เลือกสมาชิก</b>
              </MemberTopic>
              <div onClick={() => onClose()}>
                <Icon name="close_button" />
              </div>
            </MemberHeader>
            <MemberField
              name="member"
              options={options}
              onSubmit={(value: string) => {
                onChange(value)
                onClose()
              }}
            />
          </MemberContainer>
        </StyledModal>
      </>
    )
  }

  return <Fragment />
}

export default MemberSelector

const SelectedMemberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;
`

const Member = ({ value, avatar, label }) => {
  return (
    <SelectedMemberContainer>
      <MemberAvatar src={avatar} alt={label} />
      <MemberName htmlFor={`member.${value}`}>{label}</MemberName>
    </SelectedMemberContainer>
  )
}
