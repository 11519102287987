import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useParams } from 'routes'
import { financial } from 'pages/insurance_portfolio/root/Summary'
import {
  CreateAddForm,
  CreateNormalForm,
} from 'pages/insurance_portfolio/components/InsurancePortForm.styles'
import { RootState } from 'store/rootReducer'
import { useSelector } from 'react-redux'
import { NewButton, ShieldIcon } from './InsurancePort.styles'
import {
  getFullNameInsurancePattern,
  getFullNameInsuranceType,
  getFullNamePolicyType,
  getFullNamePurpose,
  getFullNameState,
} from './InsurancePort.components'
import { OldFormType } from './OldForm.type'

const OldForm: FC<OldFormType> = (props) => {
  const { instead = {}, onEdit = () => {}, setIsImageFile, setIsDelete } = props
  let { id }: { id: string } = useParams()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const { portOptions } = useSelector((state: RootState) => state.contacts)

  return (
    <>
      <CreateNormalForm>
        {instead.createdSource === 'tqm_life' ? (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เจ้าของกรมธรรม์</div>
                <div className="Normal">{instead.ownerName}</div>
              </div>
            </div>
            <div className="Row" style={{ width: 356, margin: 0, alignItems: 'flex-end' }}>
              <div className="Group" style={{ width: 158, borderBottom: '1px solid #d2d4db' }}>
                <b className="Topic" style={{ marginBottom: 8 }}>
                  รหัสคำสั่งซื้อ
                </b>
                <div className="Normal">{instead.orderNo}</div>
              </div>
              <Link to={`/contacts/${id}/insurance_portfolio/${instead.id}/${instead.orderNo}`}>
                <button type="button" style={{ width: 176 }}>
                  รายละเอียดคำสั่งซื้อ
                </button>
              </Link>
            </div>
          </div>
        ) : (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เจ้าของกรมธรรม์</div>
                <div className="Large">{instead.ownerName}</div>
              </div>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">เพศ</div>
              <div className="Normal">{instead.ownerGender === 'M' ? 'ชาย' : 'หญิง'}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">อายุ</div>
              <div className="Normal">{instead.ownerAge}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">รูปแบบการประกันภัย</div>
              <div className="Normal">
                {getFullNameInsurancePattern(instead.insurancePattern || '')}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ประเภทประกัน</div>
              <div className="Normal">{getFullNamePolicyType(instead.policyType || '')}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ประเภทกรมธรรม์</div>
              <div className="Normal">{getFullNameInsuranceType(instead.insuranceType || '')}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">บริษัทประกัน</div>
              <div className="Normal">
                {portOptions.insuranceCompanyNameOptions.find(
                  (e) => e.value === instead.insuranceCompanyName,
                )?.label || '-'}
              </div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">แบบประกัน</div>
              <div className="Normal">{instead.name}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">หมายเลขกรมธรรม์</div>
              <div className="Normal">{instead.policyNo}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วันที่เริ่มทำสัญญา</div>
              <div className="Normal">
                {new Date(instead.startPolicyAt || '').toLocaleDateString('th-TH')}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วันที่ครบกำหนดสัญญา</div>
              <div className="Normal">
                {instead.endPolicyAt
                  ? new Date(instead.endPolicyAt || '').toLocaleDateString('th-TH')
                  : 'ไม่ได้ระบุวันครบกำหนดสัญญา'}
              </div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">สถานะกรมธรรม์</div>
              <div className="Normal">
                {instead.state ? getFullNameState(instead.state) : 'ไม่ต้องระบุสถานะกรมธรรม์'}
              </div>
            </div>
          </div>
        </div>
        {instead.remark && instead.remark.length > 0 && (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">รายละเอียดเพิ่มเติม</div>
                <div className="Large">{instead.remark}</div>
              </div>
            </div>
          </div>
        )}
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">ทุนประกัน (บาท)</div>
              <div className="Normal">{instead.sumInsured && financial(instead.sumInsured)}</div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">เบี้ยประกัน (ต่อปี)</div>
              <div className="Normal">{instead.netPremium && financial(instead.netPremium)}</div>
            </div>
          </div>
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">รูปแบบการจ่าย</div>
              <div className="Normal">
                {instead.paymentType === '1' && 'รายเดือน'}
                {instead.paymentType === '3' && 'ราย 3 เดือน'}
                {instead.paymentType === '6' && 'ราย 6 เดือน'}
                {instead.paymentType === '12' && 'ทุก 1 ปี'}
              </div>
            </div>
          </div>
          {instead.yieldToMaturityRate && (
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">เงินคืนเมื่อครบสัญญา (%)</div>
                <div className="Normal">{instead.yieldToMaturityRate}</div>
              </div>
            </div>
          )}
        </div>
        <div className="Row">
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">วิธีนับเวลาจ่ายเบี้ย</div>
              <div className="Normal">
                {instead.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}
              </div>
            </div>
          </div>
          <div className="Group">
            <div className="MoreDetailLayer">
              <div className="Topic">
                {instead.payPeriodType === 'year' ? 'จำนวนปีที่จ่ายเบี้ย' : 'จ่ายถึงอายุ'}
              </div>
              <div className="Normal">{instead.payPeriodValue}</div>
            </div>
          </div>
        </div>
        {instead.insuranceType === 'investment' ? (
          <>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">วัตถุประสงค์ของกรมธรรม์</div>
                  <br />
                  <div className="Normal">{getFullNamePurpose(instead.purpose || '')}</div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">
                    เบี้ยประกันของ unit link เฉพาะส่วนที่ใช้ลดหย่อน
                    <br />
                    ภาษีได้
                  </div>
                  <div className="Normal">
                    {instead.specialFields?.premiumAllowance &&
                      financial(instead.specialFields?.premiumAllowance.toString())}
                  </div>
                </div>
              </div>
            </div>
            <div className="Row">
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">คาดการณ์มูลค่าคงเหลือ ณ ปีสุดท้าย</div>
                  <div className="Normal">
                    {instead.specialFields?.residualValue &&
                      financial(instead.specialFields?.residualValue.toString())}
                  </div>
                </div>
              </div>
              <div className="Group">
                <div className="MoreDetailLayer">
                  <div className="Topic">อัตราผลตอบแทนคาดหวัง</div>
                  <div className="Normal">{instead.specialFields?.rateOfReturn}</div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="Row">
            <div className="Group">
              <div className="MoreDetailLayer">
                <div className="Topic">วัตถุประสงค์ของกรมธรรม์</div>
                <div className="Normal">{getFullNamePurpose(instead.purpose || '')}</div>
              </div>
            </div>
          </div>
        )}
        <button
          className="ImageButton"
          type="button"
          onClick={() => setIsImageFile(true)}
          disabled={!instead.policyDocumentFullPath}
        >
          <ShieldIcon
            color={instead.policyDocumentFullPath ? '#ffffff' : '#b5b6bf'}
            name="Vector"
            className="EditIcon"
          />
          <div style={{ marginLeft: '14px' }}>ดูหน้ากรมธรรม์</div>
        </button>
      </CreateNormalForm>
      {instead.insuranceRiders && instead.insuranceRiders.length > 0 && (
        <CreateAddForm>
          <div className="Header" style={{ fontWeight: 'bold' }}>
            อนุสัญญา
          </div>
          {instead.insuranceRiders.map((value, index) => {
            return (
              <div className="Body" key={index}>
                <p className="Text primary">{getFullNameInsuranceType(value.riderType)}</p>
                <p className="Text">เบี้ยประกันต่อปี : {financial(value.premium + '')}</p>
                <p className="Text">
                  จำนวนปีที่ต้องชำระเบี้ยประกันภัยตามกรมธรรม์ : {value.peroidYearNumber}
                </p>
                <p className="Text">เงินชดเชยต่อวัน : {financial(value.compensation + '')}</p>
              </div>
            )
          })}
        </CreateAddForm>
      )}
      <NewButton>
        <button
          className="secondary"
          type="button"
          style={{ marginRight: '24px' }}
          onClick={() => setIsDelete(true)}
          disabled={instead?.createdSource === 'tqm_life' || currentAgent?.statusTh === 'ระงับ'}
        >
          ลบ
        </button>
        <button
          className="secondary"
          type="button"
          onClick={() => onEdit()}
          disabled={currentAgent?.statusTh === 'ระงับ'}
        >
          แก้ไข
        </button>
      </NewButton>
    </>
  )
}

export default OldForm
