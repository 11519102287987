import React, { FC, useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import Icon from 'components/Icon'
import ContactForm from '../components/ContactForm'
import { RootState } from 'store/rootReducer'
import { ContactType } from 'types/ContactType'
import { getContactById, updateContact } from 'store/contactSlice'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import HistoryType from 'types/HistoryType'
import { useHistory } from 'routes'

const Container = styled.div`
  width: 100%;
  .isLoading {
    text-align: center;
  }
`
const EditForm = styled.div`
  width: 100%;
  form {
    align-items: center;
  }
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 16px 0 10px;
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    /* display: flex; */
    display: none;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const HeaderComponent = () => {
  return (
    <Header>
      <h3>รายชื่อลูกค้า</h3>
      <div className="hint">
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const ContactEdit: FC = () => {
  let { id } = useParams<{ id: string }>()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { contact, initialized } = useSelector((state: RootState) => state.contacts)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    { path: `/contacts/${contact?.id}`, label: contact?.name || '' },
    {
      label: 'แก้ไขรายชื่อลูกค้า',
    },
  ]

  useEffect(() => {
    dispatch(getContactById(id))
  }, [dispatch, id])

  const removeAvatar = (props: ContactType) => {
    if (typeof props.avatar === 'string') {
      delete props.avatar
    }
  }

  const initialValue: ContactType = useMemo(
    () => ({
      age: contact?.age || '',
      status: contact?.status ? contact?.status : 'รอเสนองาน',
      remark: contact?.remark || '',
      note: contact?.note || '',
      firstName: contact?.firstName || '',
      avatar: contact?.avatar || '',
      lastName: contact?.lastName || '',
      gender: contact?.gender || '',
      nickname: contact?.nickname ? contact.nickname : `${contact?.firstName} ${contact?.lastName}`,
      phoneNumber: contact?.phoneNumber || '',
      email: contact?.email || '',
      name: contact?.name || '',
      birthdate: contact?.birthdate || '',
      maritalStatus: contact?.maritalStatus || '',
      prefixName: contact?.prefixName || '',
      idCard: contact?.idCard || '',
      occupation: contact?.occupation || '',
      position: contact?.position || '',
      company: contact?.company || '',
      facebook: contact?.facebook || '',
      lineId: contact?.lineId || '',
      canDeleted: contact?.canDeleted || true,
      homeAddress: {
        housingNo: contact?.homeAddress?.housingNo || '',
        villageNo: contact?.homeAddress?.villageNo || '',
        building: contact?.homeAddress?.building || '',
        lane: contact?.homeAddress?.lane || '',
        road: contact?.homeAddress?.road || '',
        subDistrict: contact?.homeAddress?.subDistrict || '',
        district: contact?.homeAddress?.district || '',
        province: contact?.homeAddress?.province || '',
        postcode: contact?.homeAddress?.postcode || '',
      },
      currentAddress: {
        housingNo: contact?.currentAddress?.housingNo || '',
        villageNo: contact?.currentAddress?.villageNo || '',
        building: contact?.currentAddress?.building || '',
        lane: contact?.currentAddress?.lane || '',
        road: contact?.currentAddress?.road || '',
        subDistrict: contact?.currentAddress?.subDistrict || '',
        district: contact?.currentAddress?.district || '',
        province: contact?.currentAddress?.province || '',
        postcode: contact?.currentAddress?.postcode || '',
      },
      officeAddress: {
        housingNo: contact?.officeAddress?.housingNo || '',
        villageNo: contact?.officeAddress?.villageNo || '',
        building: contact?.officeAddress?.building || '',
        lane: contact?.officeAddress?.lane || '',
        road: contact?.officeAddress?.road || '',
        subDistrict: contact?.officeAddress?.subDistrict || '',
        district: contact?.officeAddress?.district || '',
        province: contact?.officeAddress?.province || '',
        postcode: contact?.officeAddress?.postcode || '',
      },
      idCardAddress: {
        housingNo: contact?.idCardAddress?.housingNo || '',
        villageNo: contact?.idCardAddress?.villageNo || '',
        building: contact?.idCardAddress?.building || '',
        lane: contact?.idCardAddress?.lane || '',
        road: contact?.idCardAddress?.road || '',
        subDistrict: contact?.idCardAddress?.subDistrict || '',
        district: contact?.idCardAddress?.district || '',
        province: contact?.idCardAddress?.province || '',
        postcode: contact?.idCardAddress?.postcode || '',
      },
      entertainment: contact?.entertainment || {},
      health: contact?.health || {},
      travel: contact?.travel || {},
      food: contact?.food || {},
      isSameHomeAddress: contact?.isSameHomeAddress || false,
      isSameCurrentAddress: contact?.isSameCurrentAddress || false,
      isSameIdCardAddress: contact?.isSameIdCardAddress || false,
      currentAgreement: contact?.currentAgreement || null,
    }),
    [contact],
  )

  return (
    <Container>
      {initialized ? (
        <p className="isLoading">loading...</p>
      ) : (
        <EditForm>
          <HeaderComponent />
          <BreadCrumb links={breadCrumbArr} />
          <Formik
            enableReinitialize
            initialValues={initialValue}
            onSubmit={(values) => {
              const modifiedValues = {
                ...values,
                birthdate: values.birthdate ? values.birthdate.toString() : null,
              }
              removeAvatar(modifiedValues)
              dispatch(
                updateContact(id, modifiedValues, () => {
                  history.push(`/contacts/${id}`)
                }),
              )
            }}
          >
            {({ handleSubmit, isSubmitting, values, submitForm }) => {
              return (
                <ContactForm
                  handleSubmit={handleSubmit}
                  submitForm={submitForm}
                  values={values}
                  isSubmitting={isSubmitting}
                />
              )
            }}
          </Formik>
        </EditForm>
      )}
    </Container>
  )
}

export default ContactEdit
