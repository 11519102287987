import React, { FC, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import api from 'api'
import Task from './Task'
import { DatePickerComponent as DatePicker } from 'components/DatePicker'
import moment from 'moment'
import { Link } from 'routes'
import { useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'

type TaskType = {
  id: number
  name: string
  dueDate: string | Date
  priorityLevel: number
  contactName: string
  contactId: number
  contactAvatar: string
}
type DataType = {
  tasksCurrentDay: Array<TaskType>
  tasksCurrentWeek: Array<TaskType>
  tasksIncoming: Array<TaskType>
  tasksLated: Array<TaskType>
  tasksNextWeek: Array<TaskType>
}
type ContainerType = {
  event: boolean
}
const Container = styled.div<ContainerType>`
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  background: ${(props) => (props.event ? 'var(--agt-background-color-2)' : '')};
`
const HeaderSelection = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  color: var(--agt-tqm-pantone-1);
  background: #ccdcec;
  .event {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .calendar {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .active {
    color: var(--agt-white-color);
    background: var(--agt-tqm-pantone-1);
  }
  :hover {
    cursor: pointer;
  }
`
const DatePickerStyle = styled(DatePicker)`
  .react-datepicker {
    padding: 0px;
    margin-top: 20px;
    border: 0;
  }
`
const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`
const TodoContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
`
const NotingText = styled.div`
  width: 100%;
  margin-top: 40px;

  text-align: center;
  color: var(--agt-secondary-text-color);
`
const MiniCalendar = styled.div`
  width: 100%;
  background: var(--agt-white-color);
  padding: 0 24px;
  border: 1px solid var(--agt-background-color-2);
  border-radius: 0px 0px 8px 8px;
  .calendar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--agt-background-color-2);
    margin-bottom: 14px;
  }
  .calendar .allDetail {
    width: 40%;
    margin-top: 70px;
    height: 200px;
    overflow-y: auto;
  }
  .calendar .allDetail .appointment {
    display: flex;
    width: 100%;
    height: 50px;
    cursor: pointer;
  }
  .calendar .allDetail .appointment .bar {
    width: 10px;
    height: 100%;
    box-sizing: border-box;
  }
  .calendar .allDetail .appointment .agenda {
    padding-left: 16px;
  }
  .calendar .allDetail .appointment .time {
    color: var(--agt-secondary-text-color);
  }
  .calendar .allDetail .appointment .hidden {
    width: 160px;
    font-size: 17px;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .calendar .allDetail .noPlan {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--agt-secondary-text-color);
  }
  .detail {
    min-height: 288px;
  }
  .detail .topic {
    font-weight: bold;
  }
  .detail .hidden {
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail .name {
    margin-bottom: 8px;
  }
  .detail .guest {
    display: flex;
    margin-bottom: 8px;
  }
  .detail .time {
    display: grid;
    grid-template-columns: 85px 115px 55px auto;
    margin-bottom: 8px;
  }
  .detail .address {
    display: grid;
    grid-template-columns: 85px auto;
    margin-bottom: 8px;
  }
  .detail .note {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .detail .note .hidden {
    width: 350px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .detail .note .more {
    align-self: flex-end;
  }
`
type EventType = {
  allDay: boolean
  colorCode: string
  description: string | null
  endDate: string
  eventRelates: Array<{ id: number; value: number; label: string }>
  hasNotEndDate: boolean
  id: number
  location: string | null
  repeatSchedule: string
  startDate: string
  title: string
  typeAlert: string
}
const Landing: FC = (props) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [events, setEvents] = useState<Array<EventType>>([])
  const [appointment, setAppointment] = useState<EventType>() || {}
  const [isLoading, setLoading] = useState<boolean>(false)

  const [data, setData] = useState<DataType>({
    tasksCurrentDay: [],
    tasksCurrentWeek: [],
    tasksIncoming: [],
    tasksLated: [],
    tasksNextWeek: [],
  })
  const {
    tasksCurrentDay = [],
    tasksCurrentWeek = [],
    tasksIncoming = [],
    tasksLated = [],
    tasksNextWeek = [],
  } = data

  const getTasks = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/tasks`)
      const { tasks = {} } = response.data.data
      setData(tasks)
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [])

  const getEvents = useCallback(async (today: string) => {
    try {
      const response = await api.get('/events/', { dateSearch: today.toString() })
      const { events = [] } = response.data.data
      setEvents(events)
    } catch (err) {
      if (err && err.response) {
        alert(err.response.data.message)
      }
      throw err
    }
  }, [])

  useEffect(() => {
    getTasks()
    let today = moment(new Date()).format().split('T')[0]
    getEvents(today)
  }, [getTasks, getEvents])

  const [isSelect, setIsSelect] = useState('event')
  const [startDate, setStartDate] = useState(new Date())
  const [isindex, setindex] = useState<number>(-1)

  useEffect(() => {
    if (data.tasksCurrentDay.length > 0) {
      setindex(2)
    } else if (data.tasksLated.length > 0) {
      setindex(1)
    } else if (data.tasksCurrentWeek.length > 0) {
      setindex(3)
    } else if (data.tasksNextWeek.length > 0) {
      setindex(4)
    } else if (data.tasksIncoming.length > 0) {
      setindex(5)
    }
  }, [data])

  return (
    <Container className="agt-landing-tasks-card__container" event={isSelect === 'event'}>
      <HeaderSelection>
        <div
          className={isSelect === 'event' ? 'event active' : 'event'}
          onClick={() => setIsSelect('event')}
        >
          งานที่ต้องทำ
        </div>
        <div
          className={isSelect === 'calendar' ? 'calendar active' : 'calendar'}
          onClick={() => setIsSelect('calendar')}
        >
          นัดหมาย
        </div>
      </HeaderSelection>
      {isLoading ? (
        <Loading>loading ....</Loading>
      ) : (
        <>
          {isSelect === 'event' ? (
            <TodoContainer>
              {tasksLated.length === 0 &&
                tasksCurrentDay.length === 0 &&
                tasksCurrentWeek.length === 0 &&
                tasksNextWeek.length === 0 &&
                tasksIncoming.length === 0 && <NotingText>ยังไม่มีงานที่ต้องทำ</NotingText>}
              {tasksLated.length > 0 && (
                <Task
                  index={1}
                  header="เกินกำหนด"
                  type="overdue"
                  data={tasksLated}
                  forceExpand={isindex}
                  forPortal={true}
                  setObj={() => {
                    if (isindex !== 1) setindex(1)
                    else setindex(-1)
                  }}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentDay.length > 0 && (
                <Task
                  index={2}
                  header="วันนี้"
                  type="today"
                  data={tasksCurrentDay}
                  forceExpand={isindex}
                  forPortal={true}
                  setObj={() => {
                    if (isindex !== 2) setindex(2)
                    else setindex(-1)
                  }}
                  currentAgent={currentAgent}
                />
              )}
              {tasksCurrentWeek.length > 0 && (
                <Task
                  index={3}
                  header="สัปดาห์นี้"
                  type="incoming"
                  data={tasksCurrentWeek}
                  forceExpand={isindex}
                  forPortal={true}
                  setObj={() => {
                    if (isindex !== 3) setindex(3)
                    else setindex(-1)
                  }}
                  currentAgent={currentAgent}
                />
              )}
              {tasksNextWeek.length > 0 && (
                <Task
                  index={4}
                  header="สัปดาห์หน้า"
                  type="incoming"
                  data={tasksNextWeek}
                  forceExpand={isindex}
                  forPortal={true}
                  setObj={() => {
                    if (isindex !== 4) setindex(4)
                    else setindex(-1)
                  }}
                  currentAgent={currentAgent}
                />
              )}
              {tasksIncoming.length > 0 && (
                <Task
                  index={5}
                  header="สัปดาห์กำลังมาถึง"
                  type="incoming"
                  data={tasksIncoming}
                  forceExpand={isindex}
                  forPortal={true}
                  setObj={() => {
                    if (isindex !== 5) setindex(5)
                    else setindex(-1)
                  }}
                  currentAgent={currentAgent}
                />
              )}
            </TodoContainer>
          ) : (
            <MiniCalendar>
              <div className="calendar">
                <DatePickerStyle
                  value={startDate}
                  onChange={(date) => {
                    setStartDate(date)
                    let today = moment(date).format().split('T')[0]
                    getEvents(today)
                    setAppointment(undefined)
                  }}
                  inline
                  open
                />
                <div className="allDetail">
                  {events.length > 0 ? (
                    events.map((event, index) => {
                      return (
                        <div
                          className="appointment"
                          key={index}
                          onClick={() => setAppointment(event)}
                          style={
                            appointment && appointment.id === event.id
                              ? { background: `${event.colorCode}40` }
                              : {}
                          }
                        >
                          <div className="bar" style={{ background: `${event.colorCode}` }}></div>
                          <div className="agenda">
                            <div className="hidden">{event.title}</div>
                            <small className="time">
                              {event.allDay ? (
                                'ทั้งวัน'
                              ) : (
                                <>
                                  {moment(event.startDate).format('H:mm')}-
                                  {moment(event.endDate).format('H:mm')} น.
                                </>
                              )}
                            </small>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className="noPlan">
                      <small>ยังไม่มีนัดหมายในวันนี้</small>
                    </div>
                  )}
                </div>
              </div>
              {appointment ? (
                <div className="detail">
                  <div className="name">
                    <div className="topic">{appointment.title}</div>
                  </div>
                  <div className="guest">
                    <div className="topic">บุคคลผู้เข้าร่วม : </div>
                    <div className="hidden" style={{ paddingLeft: '4px' }}>
                      {appointment.eventRelates.map((relate, index) => (
                        <label key={index}>
                          {appointment.eventRelates.length - 1 === index
                            ? relate.label
                            : `${relate.label}, `}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="time">
                    <div className="topic">เริ่ม วันที่</div>
                    <div>{moment(appointment.startDate).add(543, 'year').format('ll')}</div>
                    <div>เวลา</div>
                    <div>{moment(appointment.startDate).format('H:mm')} น.</div>
                  </div>
                  <div className="time">
                    <div className="topic">จบ วันที่</div>
                    <div>{moment(appointment.endDate).add(543, 'year').format('ll')}</div>
                    <div>เวลา</div>
                    <div>{moment(appointment.endDate).format('H:mm')} น.</div>
                  </div>
                  <div className="address">
                    <div className="topic">สถานที่</div>
                    <div>{appointment.location ? appointment.location : '-'}</div>
                  </div>
                  <div className="topic" style={{ marginBottom: '8px' }}>
                    รายละเอียดเพิ่มเติม
                  </div>
                  <div className="note">
                    <div className="hidden">
                      {appointment.description ? appointment.description : '-'}
                    </div>
                    <div className="more">
                      <Link to={`/calendar/${appointment.id}`} className="ghost">
                        ดูรายละเอียด
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="detail"
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <small style={{ color: 'var(--agt-secondary-text-color)' }}>
                    เลือกนัดหมายเพื่อแสดงรายละเอียด
                  </small>
                </div>
              )}
            </MiniCalendar>
          )}
        </>
      )}
    </Container>
  )
}

export default Landing
