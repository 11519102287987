import { TaskType } from './index.type'

export const defaultTask: TaskType = {
  id: null,
  name: '',
  dueDate: null,
  priorityLevel: null,
  contactId: null,
  status: null,
  taskDocuments: [],
  description: '',
  opportunity: null,
  isConsented: false,
  currentAgreement: null,
}
