import React, { useState, useEffect, useMemo, useCallback, FC } from 'react'
import { getPositionById } from 'utils/helper'
import Avatar from 'components/Avatar'
import { WebTourType } from './FirstTimeTour.type'
import { Container, Content, DialogBox, Position, Header, Menu } from './FirstTimeTour.styles'

const FirstTimeTour: FC<WebTourType> = (props) => {
  const { visible, setVisible, currentAgent, remainingDays, logout } = props
  const totalPage = 1
  const layout = [
    {
      top: 'calc(64px + 16px)',
      right: 'calc(240px + 16px)',
      width: '332px',
      arrow: 'left',
    },
    {
      top: '0px',
      right: 'calc(240px + 16px)',
      width: '280px',
      arrow: 'left',
    },
  ]
  const [step, setStep] = useState<number>(1)
  const [height, setHeight] = useState<string>('100vh')
  const [addContactPosition, setAddContactPosition] = useState({
    top: '0',
    left: '0',
  })

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setHeight(entries[0].target.clientHeight + 'px')
    })
    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    function getPosition() {
      getPositionById('header__image_user', setAddContactPosition)
      // getPositionById('contact__view-consent', setViewConsentPosition)
    }
    setInterval(() => {
      getPosition()
    }, 100)
    document.addEventListener('scroll', getPosition)
    return () => {
      document.removeEventListener('scroll', getPosition)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setStep(1)

    return () => {
      setStep(1)
    }
  }, [setStep])

  const onNextClick = useCallback(() => {
    setStep(step + 1)
  }, [step, setStep])

  const onClose = useCallback(() => {
    setVisible(false)
    setStep(1)
    localStorage.setItem('isFirstSignIn', 'Y')
  }, [setVisible])

  const LabelSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="top">
          <label>
            ยินดีตอนรับคุณ{' '}
            <b>
              {currentAgent?.firstName || ''} {currentAgent?.lastName || ''}
            </b>
          </label>
          <small>
            คุณมีอายุสิทธิ์ในการทดลองใช้งาน {remainingDays} วัน
            <br />
            สามารถเข้าสู่เมนู <b>สมัครตัวแทน</b> ได้ผ่านทางโปรไฟล์ของคุณ
          </small>
        </div>
      )
    } else return null
  }, [step, currentAgent, remainingDays])

  const ButtonSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <div className="bottom">
          {/* <small>
            {step}/{totalPage}
          </small> */}
          <div />
          <div className="choice end">
            <small className="close" onClick={() => onClose()}>
              ปิดคำแนะนำ
            </small>
          </div>
        </div>
      )
    }
    return (
      <div className="bottom">
        <small>
          {step}/{totalPage}
        </small>
        <div className="choice">
          <small className="next" onClick={() => onNextClick()}>
            ถัดไป
          </small>
          <small className="close" onClick={() => onClose()}>
            ปิดคำแนะนำ
          </small>
        </div>
      </div>
    )
    // eslint-disable-next-line
  }, [step])

  const ContentSection = useMemo(() => {
    if (step === totalPage) {
      return (
        <>
          <Header>
            <div></div>
            <div className="avatar">
              <Avatar
                width="38px"
                height="38px"
                avatar={currentAgent?.avatar || ''}
                type="circle"
              />
              <label>
                {currentAgent?.firstName || ''} {currentAgent?.lastName || ''}
              </label>
            </div>
          </Header>
          <Menu>
            <a href="/profile/recruitment">
              <li>สมัครเป็นตัวแทน</li>
            </a>
            <li onClick={() => logout()}>ออกจากระบบ</li>
          </Menu>
        </>
      )
    }
  }, [step, currentAgent, logout])

  const StyledPosition = () => {
    if (step === 1) {
      return addContactPosition
    }
    return { top: 0, left: 0 }
  }

  return (
    <Container visible={visible} height={height}>
      <Content>
        <Position style={StyledPosition()}>
          {ContentSection}
          <DialogBox
            width={layout[step - 1].width}
            top={layout[step - 1].top}
            right={layout[step - 1].right}
          >
            {LabelSection}
            {ButtonSection}
            <div className={`labelArrow ${layout[step - 1].arrow}`} />
          </DialogBox>
        </Position>
      </Content>
    </Container>
  )
}

export default FirstTimeTour
