import React from 'react'
import { ErrorMessage, Field, useField } from 'formik'
import { DatePickerComponent } from 'components/DatePicker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import CustomAddress from './CustomAddress'
import Icon from 'components/Icon'
import vehicleChassis from '../../../../assets/images/vehicle_chassis.png'
import {
  OtherComponent,
  Selector,
  AddressComponent,
  OptionsError,
  OptionsStyles,
  Error,
} from './DetailForm.styles'

export const OptionsComponent = (props) => {
  const {
    field,
    options,
    className = '',
    label = '',
    disabled = false,
    onChange = () => {},
    placeholder = 'เลือก',
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find(
    (select: { value: string; label: string }) => select.value === value,
  )
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option: { value: string; label: string; details: {} }) => {
          setValue(option.value)
          if (onChange) onChange(option)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder={placeholder}
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const AddressOptionsComponent = (props) => {
  const { field, options, className = '', label = '', disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  const defaultSeletor = (options || []).find((select) => select.label === value)
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <Selector
        value={defaultSeletor ? defaultSeletor : ''}
        onChange={(option) => {
          setValue(option.label)
        }}
        styles={meta.error && meta.touched ? OptionsError : OptionsStyles}
        options={options}
        placeholder="เลือก"
        isDisabled={disabled}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const InputComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    maxlength,
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        maxlength={maxlength}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
    </div>
  )
}

export const ChassisNumberComponent = (props) => {
  const {
    field,
    className = '',
    label = '',
    placeholder = 'โปรดระบุ',
    disabled = false,
    isSuggest = false,
    onSuggest = () => {},
  } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <input
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        disabled={disabled}
        style={meta.error && meta.touched ? { border: '1px solid var(--agt-error-color)' } : {}}
        {...field}
      />
      <ErrorMessage component={Error} name={name} />
      <div onClick={onSuggest}>
        <Icon className="icon" name="I" color="var(--agt-primary-color-1)" />
      </div>
      <div
        className="suggest"
        style={
          isSuggest
            ? {
                position: 'absolute',
                bottom: '60px',
                width: 'fit-content',
                display: 'block',
              }
            : { display: 'none', width: '0px' }
        }
      >
        <div className="label">
          <div>
            <img alt="Vehicle Chassis" src={vehicleChassis} />
          </div>
        </div>
      </div>
    </OtherComponent>
  )
}

export const RedLabelCarComponent = (props) => {
  const { field, label, className = '', cb = () => {}, disabled = false } = props
  const { name } = field
  const [, meta, helpers] = useField(name)
  const { value } = meta
  const { setValue } = helpers
  return (
    <OtherComponent className={className} style={disabled ? { cursor: 'not-allowed' } : {}}>
      <input id="red_label__1" type="checkbox" checked={value} />
      <span
        className="checkmark"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </span>
      <label
        htmlFor="red_label__1"
        onClick={() => {
          if (disabled) {
          } else {
            setValue(!value)
            cb(!value)
          }
        }}
      >
        {label}
      </label>
    </OtherComponent>
  )
}

export const DatePicker = (props) => {
  const { field, label, className, disabled = false, minDate, cb = () => {} } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <div className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <DatePickerComponent
        value={value}
        showSelector
        onChange={(e: string) => {
          setValue(e)
          cb(e)
        }}
        minDate={minDate}
        disabled={disabled}
      />
    </div>
  )
}

export const CarCheckUp = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div>
        <label htmlFor="radio__1" onClick={() => setValue('policy')}>
          <input id="radio__1" type="radio" name={name} checked={value === 'policy'} />
          <span className="checkmark" />
          ใช้ที่อยู่กรมธรรม์
        </label>
        <label htmlFor="radio__2" onClick={() => setValue('policyDelivery')}>
          <input id="radio__2" type="radio" name={name} checked={value === 'policyDelivery'} />
          <span className="checkmark" />
          ใช้ที่อยู่ใบเสร็จรับเงิน
        </label>
        <label htmlFor="radio__3" onClick={() => setValue('receipt')}>
          <input id="radio__3" type="radio" name={name} checked={value === 'receipt'} />
          <span className="checkmark" />
          ใช้ที่อยู่กรมธรรม์
        </label>
      </div>
    </OtherComponent>
  )
}

export const PolicyType = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <label>
        {label} <span>*</span>
      </label>
      <div className="choice">
        <label htmlFor="policy__1" onClick={() => setValue('online')}>
          <input id="policy__1" type="radio" name={name} checked={value === 'online'} />
          <span className="checkmark" />
          กรมธรรม์ออนไลน์
        </label>
        <label htmlFor="policy__2" onClick={() => setValue('original')}>
          <input id="policy__2" type="radio" name={name} checked={value === 'original'} />
          <span className="checkmark" />
          กรมธรรม์ฉบับจริง
        </label>
      </div>
      <ErrorMessage component={Error} name={name} />
    </OtherComponent>
  )
}

export const NoticeComponent = (props) => {
  const { field, label, className } = props
  const { name, value } = field
  const [, , helpers] = useField(name)
  const { setValue } = helpers
  return (
    <OtherComponent className={className}>
      <input id="checkbox__1" type="checkbox" checked={value} />
      <span
        className="checkmark"
        onClick={() => {
          setValue(!value)
        }}
      >
        <FontAwesomeIcon icon={faCheck} className="icon" />
      </span>
      <label
        htmlFor="checkbox__1"
        onClick={() => {
          setValue(!value)
        }}
      >
        {label}
      </label>
    </OtherComponent>
  )
}

export const CustomAddressComponent = (props) => {
  const { label = '', name = '', options, address } = props
  return (
    <AddressComponent>
      <div className="topic">
        <b>{label}</b>
      </div>
      <Field
        label="ใช้ข้อมูลที่อยู่จาก"
        name={`${name}.aaaaaa`}
        className="aaaaaa"
        component={AddressOptionsComponent}
        options={options}
        // disabled
      />
      <Field
        label="เลขที่"
        name={`${name}.housingNo`}
        className="housingNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่"
        name={`${name}.villageNo`}
        className="villageNo"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="หมู่บ้าน/อาคาร"
        name={`${name}.building`}
        className="building"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ซอย/ตรอก"
        name={`${name}.lane`}
        className="land"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <Field
        label="ถนน"
        name={`${name}.road`}
        className="road"
        component={InputComponent}
        disabled={address?.aaaaaa !== 'ระบุเอง'}
      />
      <CustomAddress addressType={name} disabled={address?.aaaaaa !== 'ระบุเอง'} validate />
    </AddressComponent>
  )
}
