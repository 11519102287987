export type ConfirmType = {
  isOpen: boolean
  name: string
  contactName: string
  toState: string
}

const initialConfirm: ConfirmType = {
  isOpen: false,
  name: '',
  contactName: '',
  toState: '',
}

export default initialConfirm
