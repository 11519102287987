import React, { FC, useState, useEffect, useMemo, useCallback } from 'react'
import styled from '@emotion/styled'
import api from 'api'

import Icon from 'components/Icon'
import { Link } from 'routes'
import { currency } from 'utils/helper'
import PagingType from 'types/PagingType'
import { Container, OppotunityTable, FilterType, Loading, Noting } from './utils'
import OpportunityEdit from 'pages/opportunities/modal/Edit'
import Select from 'react-select'

type OpportunitiesType = {
  active?: boolean
  contactId?: number | null
  reload?: boolean
  setReload?: Function
}
type OrdersType = {
  active?: boolean
}
type PolicyStatusProps = {
  status: String
}

const Orders = styled.div<OrdersType>`
  display: flex;
  flex-direction: row;
  path {
    stroke: ${(props) => (props.active ? 'var(--agt-primary-color-1)' : '')};
    fill: ${(props) => (props.active ? 'var(--agt-primary-color-1)' : '')};
  }
`
const IconBag = styled(Icon)`
  margin-right: 8px;
  svg {
    width: 16px;
    height: 16px;
  }
`
const Name = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 225px;
`
const Status = styled.div`
  width: 100px;
  height: 30px;
  color: var(--agt-white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`
const DateTime = styled.div`
  width: 100px;
`
const StyledOpportunityEdit = styled(OpportunityEdit)`
  width: unset;
`
const Selector = styled(Select)`
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const SubComponentStyles = styled.div`
  background: rgba(239, 241, 245, 0.5);
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 27px;
  .orders {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .orders .orderNo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .premium {
    width: 30%;
    color: var(--agt-primary-color-1);
  }
`
const RightIcon = styled(Icon)`
  width: 12px;
  height: 26px;
  transform-origin: center;
  margin-right: 16px;
`
const DownIcon = styled(RightIcon)`
  transform: rotate(90deg);
  path {
    stroke: var(--agt-primary-color-1);
  }
`
const PolicyStatus = styled.small<PolicyStatusProps>`
  margin-left: 24px;
  color: ${(props) =>
    props.status === 'กรมธรรม์อนุมัติ' || props.status === 'บริษัทประกันอนุมัติ'
      ? 'var(--agt-success-color)'
      : props.status === 'ยกเลิก' || props.status === 'พนักงานยกเลิก'
      ? 'var(--agt-error-color)'
      : 'var(--agt-secondary-text-color)'};
`
const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '225px',
    background: 'var( --agt-white-color)',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '200px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '225px',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
}

const updatePipelineState = async (opportunitiesId, value, getOpp) => {
  try {
    await api.put(`/opportunities/${opportunitiesId}/update_pipeline_state`, {
      pipeline_state_id: value,
    })
    await getOpp()
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}

const Opportunities: FC<OpportunitiesType> = (props) => {
  const { active = false, contactId = null, reload = false, setReload = () => {} } = props
  const [opportunities, setOpportunities] = useState([])
  const [pipelineStates, setPipelineStates] = useState([])
  const [isOpportunityOpen, setOpportunity] = useState<boolean>(false)
  const [actionId] = useState<number>(0)
  const [paging, setPaging] = useState<PagingType>({
    page: 1,
    perPage: 10,
    totalCount: 0,
    totalPages: 0,
  })
  const [isLoading, setLoading] = useState<boolean>(true)
  const [searchValue, setSearchValue] = useState<FilterType>({
    contactId: contactId || '',
    dataSearch: '',
    orderBy: 'updated_at',
    order: 'desc',
    page: 1,
    perPage: 10,
  })

  const getOpportunities = useCallback(async (contactId: number) => {
    try {
      setLoading(true)
      const response = await api.get(`/opportunities`, { contactId: contactId })
      const responseProcessing = await api.get(`/opportunities/processing`)
      const { opportunities, paging } = response.data.data
      const { pipelineStates } = responseProcessing.data.data
      setOpportunities(opportunities || [])
      setPipelineStates(pipelineStates || [])
      setPaging(
        paging || {
          page: 1,
          perPage: 10,
          totalCount: 0,
          totalPages: 0,
        },
      )
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [])

  const searchOpportunities = useCallback(async (searchvalue: FilterType) => {
    try {
      setLoading(true)
      if (searchvalue.orderBy === 'topic') {
        searchvalue.orderBy = 'name'
      }
      const response = await api.get(`/opportunities`, searchvalue)
      const { opportunities, paging } = response.data.data
      setOpportunities(opportunities || [])
      setPaging(
        paging || {
          page: 1,
          perPage: 10,
          totalCount: 0,
          totalPages: 0,
        },
      )
    } catch (error) {
      console.error(error.response || error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (contactId) {
      if (reload && setReload !== undefined) {
        setReload(!reload)
      }
      getOpportunities(contactId)
    }
  }, [contactId, getOpportunities, reload, setReload])

  useEffect(() => {
    if (contactId && searchValue) {
      searchOpportunities(searchValue)
    }
  }, [contactId, searchOpportunities, searchValue])

  const modifiedData = useMemo(() => {
    return opportunities.map((opp: any, index: number) => {
      const { name, ...rest } = opp
      return {
        ...rest,
        topic: name,
      }
    })
  }, [opportunities])

  const options = useMemo(() => {
    if (pipelineStates) {
      const array: Array<{ label: string; value: string }> = []
      const pipeline: Array<{
        pipelineStateId: string
        pipelineStateNameTh: string
      }> = pipelineStates
      pipeline.forEach((state) => {
        array.push({ label: state.pipelineStateNameTh, value: state.pipelineStateId })
      })
      return array
    } else {
      return []
    }
  }, [pipelineStates])

  const columns = useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'status',
        canSort: false,
      },
      {
        Header: 'id',
        accessor: 'pipelineStateId',
        canSort: false,
      },
      {
        Header: 'หัวข้อ',
        accessor: 'topic',
        canSort: false,
        Cell: ({ row }: any) => {
          return (
            <div style={{ display: 'flex' }}>
              {row.values['orderCount'] > 0 ? (
                <span {...row.getToggleRowExpandedProps()}>
                  {row.isExpanded ? (
                    <DownIcon name="vector_right" />
                  ) : (
                    <RightIcon name="vector_right" />
                  )}
                </span>
              ) : null}
              <Link
                to={`/opportunities/${row.original.id}`}
                className="ghost"
                style={{ color: 'var(--agt-primary-color-text)' }}
              >
                <Name style={row.values['orderCount'] === 0 ? { paddingLeft: '28px' } : {}}>
                  {row.values['topic']}
                </Name>
              </Link>
            </div>
          )
        },
      },
      {
        Header: 'คำสั่งซื้อ',
        accessor: 'orderCount',
        canSort: true,
        Cell: ({ row }: any) => {
          const value = ((row || {}).values || {}).orderCount
          return (
            <Orders active={row.isExpanded}>
              <IconBag name="bag" />
              <div>{`X ${value}`}</div>
            </Orders>
          )
        },
      },
      {
        Header: 'id',
        accessor: 'premium',
        canSort: false,
      },
      {
        Header: 'อัปเดตล่าสุด',
        accessor: 'updatedAt',
        canSort: true,
        Cell: ({ row }: any) => {
          const value = ((row || {}).values || {}).updatedAt
          if (value) {
            const date = new Date(value)
            return <DateTime>{date.toLocaleDateString('th-TH')}</DateTime>
          }
          return ''
        },
      },
      {
        Header: 'สถานะ',
        accessor: 'id',
        canSort: false,
        Cell: ({ row }: any) => {
          const defaultSeletor = options.find(
            (select) => select.value === row.values.pipelineStateId,
          )
          return row.values.status ? (
            <Status
              style={
                row.values.status === 'won'
                  ? { background: 'var(--agt-primary-color-1)' }
                  : { background: 'var(--agt-error-color)' }
              }
            >
              {row.values.status === 'won' ? 'ขายสำเร็จ' : 'ขายไม่สำเร็จ'}
            </Status>
          ) : (
            <Selector
              value={defaultSeletor}
              onChange={(option) => {
                const value = option.value
                if (contactId) {
                  updatePipelineState(row.values.id, value, () => getOpportunities(contactId))
                }
              }}
              options={options}
              styles={customSelectStyles}
              placeholder="โปรดระบุ"
            />
          )
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [opportunities, options],
  )

  const renderRowSubComponent = useCallback(({ row, rowProps, visibleColumns }) => {
    return (
      <SubComponentStyles>
        <div className="orders">
          {row.original.orders &&
            row.original.orders.map((order, i) => (
              <div className="orderNo" key={i}>
                {order.orderType === 'life' && (
                  <Link to={`/opportunities/${row.original.id}/order-detail/${order.orderNo}`}>
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                )}
                {order.orderType === 'prb' &&
                ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(order.status) ? (
                  <Link to={`/opportunities/${row.original.id}/catalog/act/form/${order.orderId}`}>
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : order.orderType === 'prb' ? (
                  <Link to={`/opportunities/${row.original.id}/catalog/act/${order.orderId}`}>
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : null}
                {order.orderType === 'motor' &&
                ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(order.status) ? (
                  <Link
                    to={`/opportunities/${row.original.id}/motor/droplead/edit/${order.orderId}`}
                  >
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : order.orderType === 'motor' ? (
                  <Link to={`/opportunities/${row.original.id}/motor/droplead/${order.orderId}`}>
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : null}
                {order.orderType === 'ta' && ['รอแจ้งงาน', 'รอแจ้งแนะนำ'].includes(order.status) ? (
                  <Link
                    to={`/opportunities/${row.original.id}/travel/droplead/edit/${order.orderId}`}
                  >
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : order.orderType === 'ta' ? (
                  <Link to={`/opportunities/${row.original.id}/travel/droplead/${order.orderId}`}>
                    <small>รหัสคำสั่งซื้อ #{order.orderNo}</small>
                  </Link>
                ) : null}
                <PolicyStatus status={order.status}>{order.status}</PolicyStatus>
              </div>
            ))}
        </div>
        <div className="premium">เบี้ยรวม {currency(row.original.premium, 2, '฿')}</div>
      </SubComponentStyles>
    )
  }, [])

  return (
    <Container active={active}>
      {opportunities.length === 0 ? (
        <Noting>ยังไม่มีข้อมูลโอกาสในการขาย</Noting>
      ) : (
        <>
          {isOpportunityOpen && contactId && (
            <StyledOpportunityEdit
              isModalOpen={isOpportunityOpen}
              setModalOpen={setOpportunity}
              taskId={actionId}
              getOpportunity={() => getOpportunities(contactId)}
              forContact={true}
            />
          )}
          <OppotunityTable
            columns={columns}
            data={modifiedData || []}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            paging={paging}
            renderRowSubComponent={renderRowSubComponent}
          />
        </>
      )}
      {isLoading && <Loading>Loading ...</Loading>}
    </Container>
  )
}

export default Opportunities
