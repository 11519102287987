import React from 'react'
import ButtonLink from 'components/ButtonLink'
import {
  SearchCustom,
  Header,
  HintIcon,
  InputCustom,
  Input,
  SearchButton,
  SelectCustom,
  ConsentCustom,
} from './index.styles'

export const SearchComponent = ({
  setIsShowHint,
  setTextData,
  setSearchValue,
  searchValue,
  textData,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchValue({
        ...searchValue,
        page: 1,
        dataSearch: textData,
      })
    }
  }
  return (
    <SearchCustom>
      <Header>
        <h3>รายชื่อลูกค้า</h3>
        <div className="hint" onClick={() => setIsShowHint(true)}>
          <HintIcon name="hint" />
          <span>คำแนะนำ</span>
        </div>
      </Header>
      <InputCustom>
        <Input
          type="text"
          placeholder="ชื่อ, เบอร์โทร, สถานะลูกค้า, โอกาสในการขาย, งานถัดไป"
          onChange={(event) => setTextData(event.target.value)}
          onKeyPress={(event) => handleKeyPress(event)}
        />
        <SearchButton
          type="button"
          onClick={() =>
            setSearchValue({
              ...searchValue,
              page: 1,
              dataSearch: textData,
            })
          }
        >
          ค้นหา
        </SearchButton>
      </InputCustom>
    </SearchCustom>
  )
}

export const SelectComponent = ({
  paging,
  currentAgent,
  isAgree,
  setIsAgree,
  haveConsented = false,
}) => {
  return (
    <SelectCustom>
      <div className="total">ทั้งหมด {paging?.totalCount || 0} ชื่อ</div>
      <ButtonLink
        id="contact__add-contact"
        link="/contacts/new"
        label="+ สร้างรายชื่อลูกค้า"
        className="buttomLink"
        disabled={currentAgent?.statusTh === 'ระงับ'}
      />
      {haveConsented && (
        <button
          id="contact__view-consent"
          className={isAgree ? 'consentBtn secondary onClick' : 'consentBtn secondary'}
          type="button"
          onClick={() => {
            setIsAgree(!isAgree)
          }}
        >
          <ConsentCustom name="agree" color={'var(--agt-primary-color-1)'} />
          เฉพาะลูกค้าที่รอการยินยอม
        </button>
      )}
    </SelectCustom>
  )
}
