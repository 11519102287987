import React, { FC, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { Formik } from 'formik'
import { useHistory } from 'routes'

import ContactForm from '../components/ContactForm'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

import HistoryType from 'types/HistoryType'
import { ContactType } from 'types/ContactType'
import { useDispatch, useSelector } from 'react-redux'
import { createContact, setClearContact } from 'store/contactSlice'
import ConsentModal from '../components/ConsentModal'
import { RootState } from 'store/rootReducer'
import SetStateType from 'types/SetStateType'
import Icon from 'components/Icon'
import WebTourNonConsent from './WebTourNonConsent'

const defaultProps: ContactType = {
  id: null,
  avatar: '',
  age: '',
  email: '',
  firstName: '',
  lastName: '',
  name: '',
  gender: '',
  nickname: '',
  phoneNumber: '',
  birthdate: null,
  maritalStatus: '',
  prefixName: '',
  idCard: '',
  occupation: '',
  position: '',
  company: '',
  facebook: '',
  lineId: '',
  entertainment: {},
  health: {},
  travel: {},
  food: {},
  status: 'รอยินยอม',
  remark: '',
  canDeleted: true,
  homeAddress: {
    housingNo: '',
    villageNo: '',
    building: '',
    lane: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
  },
  currentAddress: {
    housingNo: '',
    villageNo: '',
    building: '',
    lane: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
  },
  officeAddress: {
    housingNo: '',
    villageNo: '',
    building: '',
    lane: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
  },
  idCardAddress: {
    housingNo: '',
    villageNo: '',
    building: '',
    lane: '',
    road: '',
    subDistrict: '',
    district: '',
    province: '',
    postcode: '',
  },
  isSameHomeAddress: false,
  isSameCurrentAddress: false,
  isSameIdCardAddress: false,
  updatedAt: '',
  createdAt: '',
}

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 10px;
  }
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const CustomForm = styled.div`
  display: flex;
  justify-content: center;
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>สร้างข้อมูลติดต่อ</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const ContactInfoNew: FC = (props) => {
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const contact = useSelector((state: RootState) => state.contacts.contact)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    { label: 'สร้างข้อมูลติดต่อ' },
  ]
  const [isConsent, setIsConsent] = useState<boolean>(false)
  const [isShowHint, setIsShowHint] = useState<boolean>(false)

  useEffect(() => {
    dispatch(setClearContact())
    return () => {
      dispatch(setClearContact())
    }
  }, [dispatch])

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['contacts']['create'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({
            ...storage,
            contacts: { ...storage.contacts, create: true },
          }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: true, contactById: false },
          tasks: { tasks: false, create: false },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      <WebTourNonConsent visible={isShowHint} setVisible={setIsShowHint} />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <CustomForm>
        {isConsent && contact && contact?.id && (
          <ConsentModal
            isOpen={isConsent}
            onConfirm={() => {
              setIsConsent(false)
              history.push('/contacts')
            }}
            onCancel={() => {
              setIsConsent(false)
              history.push('/contacts')
            }}
            onLater={() => {
              setIsConsent(false)
              history.push('/contacts')
            }}
            values={contact}
          />
        )}
        <Formik
          initialValues={defaultProps}
          onSubmit={(values) => {
            const modifiedValues = {
              ...values,
              birthdate: values.birthdate ? values.birthdate.toString() : null,
            }
            let isFormData = true
            if (typeof modifiedValues.avatar === 'string') {
              delete modifiedValues.avatar
              isFormData = false
            }
            dispatch(createContact(modifiedValues, isFormData, () => setIsConsent(true)))
          }}
        >
          {({ handleSubmit, values, isSubmitting, submitForm }) => (
            <ContactForm
              handleSubmit={handleSubmit}
              submitForm={submitForm}
              values={values}
              isSubmitting={isSubmitting}
            />
          )}
        </Formik>
      </CustomForm>
    </Container>
  )
}
export default ContactInfoNew
