import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import { Link } from 'routes'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { RootState } from 'store/rootReducer'
import authorize, { UnauthorizedAction } from 'utils/authorize'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 58px;

  color: var(--agt-primary-color-1);
  background-color: var(--agt-background-color-3);
`

const Button = styled.button`
  width: 202px;
  margin-left: 30px;

  font-weight: bold;
`

const Recruitment = () => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)

  const today = moment(new Date(), 'MM/DD/YYYY')
  const expiredDate = moment(new Date(currentAgent?.expiredAt || ''), 'MM/DD/YYYY')
  const diffDays = expiredDate.diff(today, 'days') + 1

  const text = useMemo(() => {
    if (currentAgent?.isExpired && currentAgent.agentType === 'guest') {
      return 'สิทธิ์การใช้งานของคุณหมดแล้ว ปลดล็อกการใช้งานถาวร เพียงสมัครเป็นตัวแทนกับเรา'
    } else {
      return `สิทธิ์การใช้งานของคุณจะหมดอายุใน ${diffDays} วัน ปลดล็อกการใช้งานถาวร เพียงสมัครเป็นตัวแทนกับเรา`
    }
  }, [currentAgent, diffDays])

  return (
    <Container>
      <div>{text}</div>
      <Link to="/profile/recruitment">
        <Button>สมัครเป็นตัวแทน</Button>
      </Link>
    </Container>
  )
}

export default authorize(Recruitment, {
  roles: ['guest'],
  params: { unauthorizedAction: UnauthorizedAction.HIDDEN },
})
