import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Api from 'api'
import SetStateType from 'types/SetStateType'
import { ActThunk } from './store'
import {
  order,
  initialStateType,
  LoadingPayloadType,
  actOptions,
  prbInsurance,
} from './actSlice.utils'

const initialState: initialStateType = {
  initialized: false,
  error: null,
  options: null,
  order: null,
  prbInsurance: null,
  loading: {
    options: false,
    submit: false,
  },
}

const actSlice = createSlice({
  name: 'act',
  initialState,
  reducers: {
    setInitialize(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload
    },
    getOptionsSuccess(state, action: PayloadAction<actOptions>) {
      state.options = action.payload
      state.error = null
    },
    getOptionsFailed(state, action) {
      state.error = action.payload
    },
    getOrderSuccess(state, action: PayloadAction<order>) {
      state.order = action.payload
      state.error = null
    },
    getOrderFailed(state, action) {
      state.error = action.payload
    },
    createOrderSuccess(state) {
      state.order = null
      state.error = null
    },
    createOrderFailed(state, action) {
      state.error = action.payload
    },
    getPrbInsurancesSuccess(state, action: PayloadAction<prbInsurance>) {
      state.prbInsurance = action.payload
      state.error = null
    },
    getPrbInsurancesFailed(state, action) {
      state.error = action.payload
    },
    setLoading(state, action: PayloadAction<LoadingPayloadType>) {
      state.loading[action.payload.key] = action.payload.value
    },
  },
})

export const {
  setInitialize,
  getOptionsSuccess,
  getOptionsFailed,
  getOrderSuccess,
  getOrderFailed,
  createOrderSuccess,
  createOrderFailed,
  getPrbInsurancesSuccess,
  getPrbInsurancesFailed,
  setLoading,
} = actSlice.actions

export default actSlice.reducer

export const getActOptions = (
  id: number | string,
  params?: {
    year?: string
    makeCode?: string
    familyCode?: string
  },
): ActThunk => async (dispatch) => {
  dispatch(setLoading({ key: 'options', value: true }))
  try {
    // dispatch(setInitialize(true))
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/options`, params)
    dispatch(getOptionsSuccess(response.data.data))
  } catch (err) {
    dispatch(getOptionsFailed(err.toString()))
  } finally {
    // dispatch(setInitialize(false))
    dispatch(setLoading({ key: 'options', value: false }))
  }
}

export const searchAct = (
  id: number | string,
  params: {
    carUsage: string
    companyCode: string
  },
  setIsBlocked?: SetStateType<boolean>,
  callback?: Function,
): ActThunk => async (dispatch) => {
  let isBlocked: boolean = true
  try {
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/search`, params)
    dispatch(getPrbInsurancesSuccess(response.data.data.prbInsurance))
    if (callback) callback()
    isBlocked = false
  } catch (err) {
    dispatch(getPrbInsurancesFailed(err.toString()))
    alert(err?.response?.data?.message.toString())
  } finally {
    if (setIsBlocked) setIsBlocked(isBlocked)
  }
}

export const getOrder = (id: number | string, orderId: number | string): ActThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get(`/opportunities/${id}/product/prb_insurances/order`, { orderId })
    dispatch(getOrderSuccess(response.data.data))
  } catch (err) {
    dispatch(getOrderFailed(err.toString()))
  } finally {
  }
}

export const createOrder = (id: number | string, values, cb: Function): ActThunk => async (
  dispatch,
) => {
  dispatch(setLoading({ key: 'submit', value: true }))
  try {
    await Api.post(`/opportunities/${id}/product/prb_insurances`, values)
    dispatch(createOrderSuccess())
    cb()
  } catch (err) {
    console.log(err?.response?.data?.message)
    alert(err?.response?.data?.message.toString())
    dispatch(createOrderFailed(err.toString()))
  } finally {
    dispatch(setLoading({ key: 'submit', value: false }))
  }
}
