import React, { FC, useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router'
import queryString from 'querystring'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { getNewOpportunityById } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'
import { get, isEqual } from 'lodash'
import SearchProduct from './SearchProduct'
import { getSearch } from 'store/travelInsuranceSlice'
import { SearchStateType } from 'types/TravelInsuranceType'
import Loader from 'components/BackgroundLoading'
import Compare from '../compare'
import { SearchType, FilterType } from './index.type'
import { Container, Header } from './index.styles'

const InsuranceProduct: FC = () => {
  let { id } = useParams<SearchType>()
  const location = useLocation().search
  const dispatch = useDispatch()
  const { opportunity } = useSelector((state: RootState) => state.opportunities)
  const { travelInsurances, loading, options } = useSelector((state: RootState) => state.travel)

  const [newLocation, setNewLocation] = useState<string>(location)
  const [isCompare, setIsCompare] = useState<boolean>(false)
  const [compareData, setCompareData] = useState<Array<SearchStateType> | []>([])
  const [filter, setFilter] = useState<FilterType>({
    companyCode: null,
    coverCurrent: null,
  })

  const breadCrumbArr: Array<LinkValues> = [
    {
      path: `/opportunities/${id}`,
      label: get(opportunity, 'name', 'โอกาสในการขาย'),
    },
    {
      path: `/opportunities/${id}/catalog`,
      label: 'เลือกหมวดประกัน',
    },
    {
      path: `/opportunities/${id}/catalog/travel`,
      label: 'ประกันเดินทาง',
    },
    {
      label: isCompare ? 'รายละเอียดและเปรียบเทียบ' : 'แสดงแบบประกัน',
    },
  ]

  useEffect(() => {
    dispatch(getNewOpportunityById(id))
  }, [dispatch, id])

  useEffect(() => {
    if (id && newLocation) {
      // console.log('newLocation....', newLocation)
      // console.log(queryString.parse(newLocation.replace('?', '')))
      dispatch(getSearch(id, queryString.parse(newLocation.replace('?', ''))))
    }
  }, [dispatch, id, newLocation])

  const onFilterChange = useCallback(
    (value) => {
      // console.log('onFilterChange....', value)
      // console.log('onFilterChange....filter', filter)
      if (!isEqual(value, filter)) {
        setFilter(value)
        let originalUrl = queryString.parse(location.replace('?', ''))
        const newJsonStr = { ...originalUrl, ...filter }
        console.log('newJsonStr....', newJsonStr)
        setNewLocation(`?${queryString.stringify(newJsonStr)}`)
      }
    }, // eslint-disable-next-line
    [filter],
  )

  const memoFilter = useMemo(() => filter, [filter])

  return (
    <Container>
      <Header>ประกันเดินทาง</Header>
      <BreadCrumb links={breadCrumbArr} />
      {isCompare ? (
        <Compare
          setIsCompare={setIsCompare}
          setCompareData={setCompareData}
          data={compareData}
          location={location}
        />
      ) : (
        <>
          {(loading.options || loading.search) && <Loader />}
          <SearchProduct
            data={travelInsurances}
            options={options}
            filter={memoFilter}
            setFilter={onFilterChange}
            setCompareData={setCompareData}
            compareData={compareData}
            setIsCompare={setIsCompare}
            location={location}
          />
        </>
      )}
    </Container>
  )
}

export default InsuranceProduct
