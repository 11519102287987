import React, { FC, useState, useCallback } from 'react'
import styled from '@emotion/styled'

import { HeaderContainer, ArrowContainer, ArrowLeft, ArrowRight } from './DateSelector'
import { THIS_YEAR, QUARTER_TH, THIS_QUARTER } from './DatePicker.utils'
import { QuarterSelectorType } from 'types/DatePickerType'

const Container = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 0 auto 1.5rem;
  padding-top: 1rem;
`
const QuarterContainer = styled.div`
  > div:last-of-type {
    margin-bottom: 0;
  }
`
const Quarter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 216px;
  height: 42px;
  margin: 0 auto 14px;
  border-radius: 20px;
  cursor: pointer;

  &.selected {
    background-color: var(--agt-primary-color-1);
    color: white;
  }
`

const QuarterSelector: FC<QuarterSelectorType> = ({
  value: { year = THIS_YEAR, quarter = THIS_QUARTER },
  onChange = () => {},
}) => {
  const [selectedYear, setYear] = useState<number>(year)
  const [selectedQuarter, setQuarter] = useState<number>(quarter)

  const decreaseYear = useCallback(() => {
    setYear(selectedYear - 1)
    onChange({ year: selectedYear - 1, quarter: selectedQuarter })
  }, [selectedYear, selectedQuarter, setYear, onChange])

  const increaseYear = useCallback(() => {
    setYear(selectedYear + 1)
    onChange({ year: selectedYear + 1, quarter: selectedQuarter })
  }, [selectedYear, selectedQuarter, setYear, onChange])

  const _handleSelectQuarter = useCallback(
    (index: number) => {
      setQuarter(index)
      onChange({ year: selectedYear, quarter: index })
    },
    [selectedYear, setQuarter, onChange],
  )

  return (
    <Container>
      <HeaderContainer>
        <ArrowContainer onClick={decreaseYear}>
          <ArrowLeft name="vector_left" />
        </ArrowContainer>
        <div>{selectedYear + 543}</div>
        <ArrowContainer onClick={increaseYear}>
          <ArrowRight name="vector_left" />
        </ArrowContainer>
      </HeaderContainer>
      <QuarterContainer>
        {QUARTER_TH.map((quarter, index) => (
          <Quarter
            key={index}
            className={selectedQuarter === index + 1 ? 'selected' : ''}
            onClick={() => _handleSelectQuarter(index + 1)}
          >
            {quarter}
          </Quarter>
        ))}
      </QuarterContainer>
    </Container>
  )
}

export default QuarterSelector
