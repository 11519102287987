import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TasksThunk } from 'store/store'
import Api from 'api'
import { TaskType } from 'types/TaskType'
import SetStateType from 'types/SetStateType'
import { format } from 'date-fns'

type TasksState = {
  initialized: boolean
  error: string | null
  tasks: Array<TaskType> | []
  paging: {
    page: number
    perPage: number
    totalCount: number
    totalPages: number
  }
  task: TaskType | null
}

type OptionsType = {
  value: number
  label: string
}

type TaskByIdType = {
  id: number | null
  name: string
  dueDate: string | null
  priorityLevel: number | null
  contactId: number | null
  status: string | null
  description: string
  taskDocuments: File[]
  contactName?: string
  contactPhoneNumber?: string
  contactEmail?: string
}

type ArraysTaskType = {
  id: number
  name: string
  dueDate: string
  reminderTime: string
  priorityLevel: number | null
  contactId: number | null
  contactName: string | null
  contactAvatar: string
}

type IncompleteTasksType = {
  tasksLated: Array<ArraysTaskType>
  tasksCurrentDay: Array<ArraysTaskType>
  tasksCurrentWeek: Array<ArraysTaskType>
  tasksNextWeek: Array<ArraysTaskType>
  tasksIncoming: Array<ArraysTaskType>
}

type FilterValues = {
  dataSearch: string
  priorityLevel: string | null
  dueDate: Object | null
}

const initialState: TasksState = {
  initialized: false,
  error: null,
  tasks: [],
  paging: {
    page: 1,
    perPage: 10,
    totalCount: 1,
    totalPages: 1,
  },
  task: {
    name: '',
    dueDate: '',
    reminderTime: '',
    priorityLevel: 3,
    contactId: null,
    description: '',
  },
}

const tasksSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    initializeTasks(state) {
      state.initialized = true
    },
    setCurrentTaskSuccess(state, action: PayloadAction<TasksState>) {
      state.task = action.payload.task
      state.error = null
    },
    setCurrentTaskFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
    getTasksSuccess(state, action: PayloadAction<TasksState>) {
      state.tasks = action.payload.tasks
      state.paging = action.payload.paging
      state.error = null
    },
    getTasksFailed(state, action: PayloadAction<string>) {
      state.error = action.payload
    },
  },
})

export const {
  initializeTasks,
  setCurrentTaskSuccess,
  setCurrentTaskFailed,
  getTasksSuccess,
  getTasksFailed,
} = tasksSlice.actions

export default tasksSlice.reducer

export const createTask = (values: TaskType, cb: Function): TasksThunk => async (dispatch) => {
  try {
    const response = await Api.post(`/tasks/`, values)
    dispatch(setCurrentTaskSuccess(response.data.data))
    cb()
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}

export const getTasksOptions = (setOptions: SetStateType<Array<OptionsType>>): TasksThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.get('/tasks/configs')
    dispatch(setCurrentTaskSuccess(response.data.data))
    setOptions(response.data.data.contacts)
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}

export const getTasks = (
  setTasks: SetStateType<Array<ArraysTaskType>>,
  setTasksIncomplete: SetStateType<IncompleteTasksType>,
  viewpoint: string,
  searchValue: FilterValues,
): TasksThunk => async (dispatch) => {
  try {
    const modifiedDate = searchValue.dueDate
      ? format(new Date(searchValue.dueDate.toString()), 'dd/MM/yyyy')
      : null
    const modifiedParams = {
      ...searchValue,
      dueDate: searchValue.dueDate ? modifiedDate : null,
    }
    const response = await Api.get('/tasks/', { ...modifiedParams, viewpoint })
    if (viewpoint === 'incomplete') {
      setTasksIncomplete(response.data.data.tasks)
    } else {
      setTasks(response.data.data.tasks)
    }
    dispatch(setCurrentTaskSuccess(response.data.data))
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}

export const getMoreTasks = (
  setTasksIncomplete: SetStateType<IncompleteTasksType>,
  searchValue: FilterValues,
  incomingWeek: number,
): TasksThunk => async (dispatch) => {
  try {
    const response = await Api.get('/tasks/', { ...searchValue, incomingWeek: incomingWeek })
    setTasksIncomplete(response.data.data.tasks)
    dispatch(setCurrentTaskSuccess(response.data.data))
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}

export const getTaskById = (
  id: string,
  setOptions: SetStateType<TaskByIdType>,
  cb: Function,
): TasksThunk => async (dispatch) => {
  try {
    const response = await Api.get(`/tasks/${id}`)
    setOptions(response.data.data.task)
    dispatch(setCurrentTaskSuccess(response.data.data))
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
    cb()
  }
}

export const updateTask = (id: string, task: TaskByIdType, cb: Function): TasksThunk => async (
  dispatch,
) => {
  try {
    const response = await Api.put(`/tasks/${id}`, task)
    dispatch(setCurrentTaskSuccess(response.data.data))
    cb()
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}

export const deleteTask = (id: string, cb: Function): TasksThunk => async (dispatch) => {
  try {
    await Api.delete(`/tasks/${id}`, {})
    cb()
  } catch (err) {
    dispatch(setCurrentTaskFailed(err.toString()))
  }
}
