import styled from '@emotion/styled'
import React, { FC, useState, useEffect } from 'react'
import { useParams } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { EventType } from 'types/EventType'
import { initialEvent } from './index.utils'
import { getEventById } from 'store/eventsSlice'
import EditEventForm from './EventForm'
import DetailForm from './DetailForm'

type useParamsType = {
  id: string
}

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const EditStyle = styled.div``

const EventDetail: FC = (props) => {
  let { id } = useParams<useParamsType>()
  const dispatch = useDispatch()
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [event, setEvent] = useState<EventType>(initialEvent)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const initialValue: EventType = {
    id: event?.id,
    title: event?.title,
    startDate: event?.startDate,
    endDate: event?.endDate,
    colorCode: event?.colorCode,
    allDay: event?.allDay,
    hasNotEndDate: event?.hasNotEndDate,
    repeatSchedule: event?.repeatSchedule,
    location: event?.location,
    description: event?.description,
    typeAlert: event?.typeAlert,
    eventRelates: event?.eventRelates,
  }

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/calendar', label: 'Calendar' },
    { label: 'นัดหมาย' },
  ]

  useEffect(() => {
    dispatch(getEventById(id, setEvent))
  }, [dispatch, id])

  return (
    <Container>
      <Header>{isEdit ? 'แก้ไขนัดหมาย' : 'ข้อมูลนัดหมาย'}</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      {isEdit ? (
        <EditStyle>
          <EditEventForm event={initialValue} />
        </EditStyle>
      ) : (
        <DetailForm event={event} setIsEdit={setIsEdit} currentAgent={currentAgent} />
      )}
    </Container>
  )
}
export default EventDetail
