import React, { FC } from 'react'
import styled from '@emotion/styled'

import { ContainerType } from './utils'

const Container = styled.div<ContainerType>`
  width: ${(props) => (props.active ? '100%' : '0px')};
  height: ${(props) => (props.active ? '100%' : '0px')};
  overflow: ${(props) => (props.active ? 'auto' : 'hidden')};
`

type FilesType = {
  active?: boolean
  contactId?: number | null
}
const Files: FC<FilesType> = (props) => {
  return <Container active={props.active}>Files</Container>
}

export default Files
