import React, { FC, useMemo } from 'react'
import styled from '@emotion/styled'

import Avatar from 'components/Avatar'

import { DashboardTopProductCardType } from 'types/DashboardType'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 250px;
  padding: 14px 24px;
  border-radius: 8px;
  border: 1px solid var(--agt-background-color-1);

  .order .orderName .logo {
    display: flex;
  }
  .order .orderName .logo .shieldLogo {
    margin-right: 8px;
    margin-left: 14px;
  }

  .order .orderName .name {
    display: flex;
    flex-direction: column;
    small {
      color: var(--agt-secondary-color-1);
      margin-bottom: 8px;
    }
  }
`
const Topic = styled.b`
  color: var(--agt-primary-color-1);
  margin-bottom: 6.5px;
`
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--agt-secondary-color-1);
  margin-bottom: 8px;
`
const Products = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
const Product = styled.div`
  display: flex;
  justify-content: space-between;
`
const Info = styled.div`
  display: flex;
  width: 100%;
`
const Logo = styled.div`
  display: flex;
`
const LogoContainer = styled.div`
  margin-right: 8px;
  margin-left: 14px;
`
const ProductInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
`
const ProductName = styled.label`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const CompanyName = styled.small`
  color: var(--agt-secondary-color-1);
  margin-bottom: 8px;
`
const NumberOfPolicies = styled.b`
  color: var(--agt-primary-color-1);
`
const NoProduct = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: var(--agt-secondary-text-color);
`

const DashboardTopProductCard: FC<DashboardTopProductCardType> = ({ policies = [] }) => {
  const renderPolicies = useMemo(() => {
    if (policies.length === 0) {
      return <NoProduct>ยังไม่มีข้อมูล</NoProduct>
    }

    return policies.map((product, index) => (
      <Product key={index}>
        <Info>
          <Logo>
            <b>{index + 1}</b>
            <LogoContainer>
              <Avatar type="circle" avatar={product.companyLogo} width="24px" height="24px" />
            </LogoContainer>
          </Logo>
          <ProductInfo>
            <ProductName>{product.policyName || '-'}</ProductName>
            <CompanyName>{product.companyName || '-'}</CompanyName>
          </ProductInfo>
        </Info>
        <NumberOfPolicies>{product.policyCount || 0}</NumberOfPolicies>
      </Product>
    ))
  }, [policies])

  return (
    <Container className="agt-dashboard-top-product-card__container">
      <Topic>ผลิตภัณฑ์ที่ขายได้มากที่สุด</Topic>
      <Header>
        <small>อันดับที่</small>
        <small>จำนวนกรมธรรม์</small>
      </Header>
      <Products>{renderPolicies}</Products>
    </Container>
  )
}

export default DashboardTopProductCard
