import React, { useCallback } from 'react'
import styled from '@emotion/styled'
import { useSelector } from 'react-redux'
import { useField } from 'formik'

import { ViewType, ViewFieldType } from 'types/DashboardType'
import { RootState } from 'store/rootReducer'

const ViewContainer = styled.div`
  display: flex;
  width: fit-content;
`
const ViewButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 108px;
  height: 48px;
  border: 1px solid var(--agt-secondary-color-1);
  cursor: pointer;

  &.selected {
    color: var(--agt-primary-color-1);
    background-color: var(--agt-light-sky-color);
    border-color: var(--agt-primary-color-1);
  }

  :first-of-type {
    border-radius: 4px 0 0 4px;
  }
  :last-of-type {
    border-radius: 0 4px 4px 0;
  }
`
const ViewSelectorField = ({ name = '', onChange = () => {} }: ViewFieldType) => {
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const [, meta, helpers] = useField(name)

  const { value } = meta
  const { setValue } = helpers

  const _handleOnChange = useCallback(
    (e: ViewType) => {
      setValue(e)
      onChange(e)
    },
    [setValue, onChange],
  )

  if (currentAgent?.isLeader)
    return (
      <ViewContainer>
        <ViewButton
          className={value === 'personal' ? 'selected' : ''}
          onClick={() => _handleOnChange('personal')}
        >
          ของฉัน
        </ViewButton>
        <ViewButton
          className={value === 'team' ? 'selected' : ''}
          onClick={() => _handleOnChange('team')}
        >
          ทีม
        </ViewButton>
      </ViewContainer>
    )

  return <div />
}

export default ViewSelectorField
