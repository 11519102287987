import styled from '@emotion/styled'
import Icon from 'components/Icon'
import Select from 'react-select'

export const CustomIcon = styled(Icon)`
  div {
    div {
      width: 26px;
      height: 26px;
      margin-right: 8px;
      svg {
        width: 26px;
        height: 26px;
      }
    }
  }
`

export const Error = styled.div`
  margin-top: 8px;
`

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LicenseDelete = styled.div`
  position: absolute;
  top: 5px;
  left: 156px;
  cursor: pointer;
  background: var(--agt-background-color-3);
  width: 48px;
  height: 38px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    left: 134px;
  }
`

export const HoverInfo = styled.div`
  > label {
    margin-right: 12px;
    font-weight: bold;
  }
  .infoI {
    cursor: pointer;
  }
  .suggest {
    opacity: 0;
    transition: all 0.1s;
  }
  .infoI:hover + .suggest {
    opacity: 1;
    cursor: pointer;
  }
`

export const Selector = styled(Select)`
  height: 48px;
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`

export const customSelectStyles = {
  menu: () => ({
    border: 'none',
    width: 'auto',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
    backgroundColor: 'var(--agt-white-color)',
  }),
  menuList: () => ({
    maxHeight: '180px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'var(--agt-white-color)',
    position: 'absolute',
    zIndex: '2',
  }),
  valueContainer: (defaultStyle) => ({
    ...defaultStyle,
    padding: '0 16px',
  }),
}
