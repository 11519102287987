import React, { FC, useState, useEffect } from 'react'
import { Formik } from 'formik'
import { useParams, useHistory } from 'routes'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import InsurancePort from './InsurancePort'
import HistoryType from 'types/HistoryType'
import { InsurancePortType } from 'types/InsurancePortType'
import { go2Top } from 'utils/helper'
import { useDispatch } from 'react-redux'
import { editInsurance, getInsuranceById, getPortOptions } from 'store/contactSlice'
import { Container, Header, SubHeader, Loading } from './index.styles'
import NewPortForm from '../components/NewPortForm'
import { schema } from '../new/index.validation'

const InsurancePortFolio: FC = () => {
  let { id, insuranceId } = useParams<{ id: string; insuranceId: string }>()
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isEditing, setEditing] = useState<boolean>(false)
  const [insurancePort, setInsurancePort] = useState<InsurancePortType>({})
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'ข้อมูลติดต่อ' },
    {
      path: `/contacts/${id}`,
      label: insurancePort?.ownerName || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: `/contacts/${id}/insurance_portfolio`,
      label: 'สรุปกรมธรรม์',
    },
    { label: insurancePort?.name || '' },
  ]

  useEffect(() => {
    dispatch(
      getInsuranceById(setInsurancePort, setIsLoading, id, insuranceId, () => {
        history.push(`/contacts/${id}/insurance_portfolio/`)
      }),
    )
    dispatch(getPortOptions(id, () => {}))
  }, [dispatch, id, insuranceId, history])

  useEffect(() => {
    go2Top()
  }, [isEditing])

  return (
    <Container>
      <Header>รายละเอียดข้อมูลประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <SubHeader>ข้อมูลกรมธรรม์</SubHeader>
      {isLoading ? (
        <Loading>Loading...</Loading>
      ) : insurancePort.id && isEditing ? (
        <Formik
          initialValues={{
            ...insurancePort,
            yieldToMaturityRate: insurancePort?.yieldToMaturityRate || '0',
            payPeriodValue: insurancePort?.payPeriodValue || 0,
            specialFields: {
              ...insurancePort?.specialFields,
              prbInsurance: insurancePort?.specialFields?.prbInsurance || {
                annualPremium: '',
                policyNo: '',
                startPolicyAt: new Date(),
                endPolicyAt: new Date(),
              },
              drivers: insurancePort?.specialFields?.drivers
                ? insurancePort?.specialFields?.drivers
                : [{ firstName: '', lastName: '', occupation: '', birthdate: new Date() }],
              productDetails: {
                ...insurancePort?.specialFields?.productDetails,
                coverEarly: insurancePort?.specialFields?.productDetails?.coverEarly || 0,
                coverCar: insurancePort?.specialFields?.productDetails?.coverCar || 0,
                driverNum:
                  insurancePort?.createdSource === 'tqm' &&
                  insurancePort?.specialFields?.productDetails?.coverAccNum
                    ? 1
                    : insurancePort?.specialFields?.productDetails?.driverNum
                    ? insurancePort?.specialFields?.productDetails?.driverNum
                    : 0,
                passengerNum:
                  insurancePort?.createdSource === 'tqm' &&
                  insurancePort?.specialFields?.productDetails?.coverAccNum
                    ? insurancePort?.specialFields?.productDetails?.coverAccNum - 1
                    : insurancePort?.specialFields?.productDetails?.passengerNum
                    ? insurancePort?.specialFields?.productDetails?.passengerNum
                    : 0,
                coverDriverDeceased:
                  insurancePort?.createdSource === 'tqm' &&
                  insurancePort?.specialFields?.productDetails?.coverAcc
                    ? insurancePort?.specialFields?.productDetails?.coverAcc
                    : insurancePort?.specialFields?.productDetails?.coverDriverDeceased
                    ? insurancePort?.specialFields?.productDetails?.coverDriverDeceased
                    : 0,
                coverDriverDeceasedUnit:
                  insurancePort?.specialFields?.productDetails?.coverDriverDeceasedUnit || 'บาท/คน',
                coverPassengerDeceased:
                  insurancePort?.createdSource === 'tqm' &&
                  insurancePort?.specialFields?.productDetails?.coverAcc
                    ? insurancePort?.specialFields?.productDetails?.coverAcc
                    : insurancePort?.specialFields?.productDetails?.coverPassengerDeceased
                    ? insurancePort?.specialFields?.productDetails?.coverPassengerDeceased
                    : 0,
                coverPassengerDeceasedUnit:
                  insurancePort?.specialFields?.productDetails?.coverPassengerDeceasedUnit ||
                  'บาท/คน',
                coverDriverTemporary:
                  insurancePort?.specialFields?.productDetails?.coverDriverTemporary || 0,
                coverDriverTemporaryUnit:
                  insurancePort?.specialFields?.productDetails?.coverDriverTemporaryUnit ||
                  'บาท/คน/สัปดาห์',
                coverPassengerTemporary:
                  insurancePort?.specialFields?.productDetails?.coverPassengerTemporary || 0,
                coverPassengerTemporaryUnit:
                  insurancePort?.specialFields?.productDetails?.coverPassengerTemporaryUnit ||
                  'บาท/คน/สัปดาห์',
                coverAccPassUnit:
                  insurancePort?.specialFields?.productDetails?.coverAccPassUnit || 'บาท/คน',
                coverLegal: insurancePort?.specialFields?.productDetails?.coverLegal
                  ? insurancePort?.specialFields?.productDetails?.coverLegal
                  : 0,
                coverMoreDetails: insurancePort?.specialFields?.productDetails?.coverMoreDetails
                  ? insurancePort?.specialFields?.productDetails.coverMoreDetails
                  : [],
              },
            },
          }}
          onSubmit={(values) => {
            dispatch(
              editInsurance(id, insuranceId, values, () => {
                history.push(`/contacts/${id}/insurance_portfolio/`)
              }),
            )
          }}
          validationSchema={schema}
        >
          {(props) => {
            return (
              <>
                {/* Life, Motor, TA Form */}
                <NewPortForm
                  handleSubmit={props.handleSubmit}
                  values={props.values}
                  errors={props.errors}
                  touched={props.touched}
                  isSubmitting={props.isSubmitting}
                />
                {/* Life, Motor, TA Form */}
              </>
            )
          }}
        </Formik>
      ) : (
        <InsurancePort instead={insurancePort} onEdit={() => setEditing(true)} />
      )}
    </Container>
  )
}
export default InsurancePortFolio
