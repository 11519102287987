import React, { FC, useEffect } from 'react'
import { Link } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { getProfile, getProileOptions } from 'store/profileSlice'
import UserType, { OptionsType } from 'types/RegisterType'
import { dateFormat } from 'utils/helper'
import { Container, Header, Body, CustomAvatar, InfoContainer } from './index.styles'
import { displayAddress, DisplayValue } from './index.components'

const ProfilePage: FC = () => {
  const dispatch = useDispatch()
  const profile: UserType = useSelector((state: RootState) => state.profile.profile)
  const options: OptionsType = useSelector((state: RootState) => state.profile.options)
  const currentAgent = useSelector((state: RootState) => state.app.currentAgent)
  const breadCrumbArr: Array<LinkValues> = [{ label: 'ข้อมูลส่วนตัว' }]

  useEffect(() => {
    dispatch(getProfile())
    dispatch(getProileOptions())
  }, [dispatch])

  return (
    <Container>
      <Header>ข้อมูลส่วนตัว</Header>
      <BreadCrumb links={breadCrumbArr} />
      <Body>
        <CustomAvatar width="120px" height="120px" type="circle" avatar={profile.avatar} />
        <InfoContainer>
          <DisplayValue
            className="row"
            title={'ชื่อ'}
            values={`${profile?.prefixName || ''} ${profile.firstName} ${profile.lastName}`}
          />
          <DisplayValue
            className=""
            title={'เพศ'}
            values={profile.gender ? (profile.gender === 'M' ? 'ชาย' : 'หญิง') : '-'}
          />
          <DisplayValue
            className=""
            title={'วันเกิด'}
            values={profile.birthdate ? dateFormat(profile.birthdate) : '-'}
          />
          <DisplayValue className="" title={'เลขประจำตัวประชาชน'} values={profile.idCard || '-'} />
          <DisplayValue className="" title={'เบอร์โทรศัพท์'} values={profile.phoneNumber || '-'} />
          <DisplayValue className="" title={'อีเมล'} values={profile.email || '-'} />
          <DisplayValue
            className="row"
            title={'ที่อยู่ตามบัตรประชาชน'}
            values={profile.idCardAddress ? displayAddress(profile.idCardAddress) : '-'}
          />
          <DisplayValue
            className="row"
            title={'ที่อยู่ในการส่งเอกสาร'}
            values={profile.currentAddress ? displayAddress(profile.currentAddress) : '-'}
          />
          <DisplayValue
            className=""
            title={'บริษัทประกันชีวิตต้นสังกัด'}
            values={
              profile.insuranceCompanyName
                ? options.insuranceCompanyNameOptions?.find(
                    (insuranceCompanyNameOption) =>
                      insuranceCompanyNameOption.value === profile.insuranceCompanyName,
                  )?.label
                : '-'
            }
          />
          <div />
          <div className="row">
            <div className="table header">
              <b>เลขที่ใบอนุญาต</b>
              <b>ประเภทใบอนุญาต</b>
              <b>วันหมดอายุ</b>
            </div>
            {profile.agentLicenses?.map((license, index) => (
              <div className="table detail" key={index}>
                <label>{license?.licenseCode || '0144546465646'}</label>
                <label>{license?.licenseType || 'ตัวแทนประกันวินาศภัย'}</label>
                <label>{license?.expiredAt ? dateFormat(license?.expiredAt) : '04/03/2567'}</label>
              </div>
            ))}
          </div>
        </InfoContainer>
      </Body>
      <div className="editBtn">
        <Link to="/profile/edit" className="ghost">
          <button type="button" className="secondary" disabled={currentAgent?.statusTh === 'ระงับ'}>
            แก้ไขข้อมูล
          </button>
        </Link>
      </div>
    </Container>
  )
}

export default ProfilePage
