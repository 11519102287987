import styled from '@emotion/styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const Container = styled.div`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
  border-right: 1px solid var(--agt-secondary-color-1);
  .insurance_title {
    width: 170px;
    text-align: center;
    font-weight: bold;
    color: var(--agt-primary-color-1);
    margin-top: 9px;
  }
  .insurance_age {
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    color: var(--agt-primary-color-1);
  }
  .price_text {
    color: var(--agt-primary-color-1);
    font-weight: bold;
    font-size: 24px;
    align-self: center;
    margin: 8px 0;
  }
  .insurance_company {
    color: var(--agt-primary-color-1);
    margin-bottom: 48px;
    font-weight: bold;
  }
  .insurance_topic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 11px 40px;
    width: 100%;
    background-color: var(--agt-background-color-2);
  }
  .insurance_topic:hover {
    cursor: pointer;
  }
  table {
    width: 100%;
    padding: 1rem 2rem;
    tr {
      max-width: 300px;
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;
    }
    tr th {
      text-align: left;
    }
    tr:last-of-type {
      margin-bottom: 0rem;
    }
  }
`

export const CustomIcon = styled(FontAwesomeIcon)`
  color: var(--agt-secondary-text-color);
`
