import React from 'react'
import { Moment } from 'moment'

export const mockUpAddress = [
  { label: 'ระบุเอง', value: null },
  {
    label: 'ที่อยู่ตามทะเบียนบ้าน',
    value: {
      building: 'dsfad',
      district: 'afdadfadfad',
      housingNo: 'adfafaadf',
      lane: 'asdfaffsaa',
      postcode: 'safafadsad',
      province: 'dsafa',
      road: 'dsfa',
      subDistrict: 'adfasf',
      villageNo: 'dasfaasfaa',
    },
  },
]

export function formatTravelTypeCountry(travelTypeCountry: string) {
  switch (travelTypeCountry) {
    case 'IN':
      return 'ในประเทศ'
    case 'OUT':
      return 'ต่างประเทศ'
    default:
      return '-'
  }
}

export function formatTravelType(travelType: string) {
  switch (travelType) {
    case 'D':
      return 'รายเที่ยว'
    case 'Y':
      return 'รายปี'
    default:
      return '-'
  }
}

export function formatPolicyForm(travelType: string) {
  switch (travelType) {
    case 'online':
      return 'กรมธรรม์ออนไลน์'
    case 'original':
      return 'กรมธรรม์ฉบับจริง'
    default:
      return '-'
  }
}

export function displayAddress(address: {
  housingNo: string
  villageNo: string | null
  building: string | null
  lane: string | null
  road: string | null
  subDistrict: string
  district: string
  province: string
  postcode: string
}) {
  return (
    <>
      {address.housingNo && `${address.housingNo} `}
      {address.villageNo && `หมู่ ${address.villageNo} `}
      {address.building && `${address.building} `}
      {address.lane && `ซอย ${address.lane} `}
      {address.road && `ถนน ${address.road} `}
      {address.subDistrict} {address.district} {address.province} {address.postcode}
    </>
  )
}

export function diffTravelDate(form: Moment, to: Moment) {
  if (to.diff(form, 'days')) return to.diff(form, 'days')
  else return 1
}
