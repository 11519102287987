import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  max-width: 1194px;
  margin: 0 auto;
  padding: 0 37px;
`
export const BodyContainer = styled.div``

export const Body = styled.main`
  width: 100% !important;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 112px);
  margin: auto;
`
