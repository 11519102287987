import React, { FC } from 'react'
import styled from '@emotion/styled'
import DatePickerComponent from 'react-datepicker'

import Icon from 'components/Icon'

import { MONTH_TH } from './DatePicker.utils'
import { DateSelectorType } from 'types/DatePickerType'

export const Container = styled.div`
  display: block;
  width: fit-content;
  margin: 0 auto 1rem;
  padding-top: 1rem;

  > div {
    > div.react-datepicker {
      padding: 0;
      border: none;
    }
  }
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 280px;
  margin: 0 auto 20px;
  padding: 0 20px;
  color: var(--agt-primary-color-1);
`
export const ArrowContainer = styled.div`
  height: 18px;
`
export const ArrowLeft = styled(Icon)`
  height: 18px;
  cursor: pointer;
  svg {
    path {
      stroke: var(--agt-primary-color-1);
    }
  }
`
export const ArrowRight = styled(ArrowLeft)`
  transform-origin: center;
  transform: rotate(180deg);
`

const DateSelector: FC<DateSelectorType> = ({ value, onChange = () => {} }) => {
  return (
    <Container>
      <DatePickerComponent
        inline
        selected={value}
        onChange={(value) => onChange(value)}
        renderCustomHeader={(headerProps) => {
          const { date, decreaseMonth, increaseMonth } = headerProps
          const month = MONTH_TH[date.getMonth()]
          const year = date.getFullYear() + 543

          return (
            <HeaderContainer>
              <ArrowContainer onClick={decreaseMonth}>
                <ArrowLeft name="vector_left" />
              </ArrowContainer>
              <div>
                {month} {year}
              </div>
              <ArrowContainer onClick={increaseMonth}>
                <ArrowRight name="vector_left" />
              </ArrowContainer>
            </HeaderContainer>
          )
        }}
      />
    </Container>
  )
}

export default DateSelector
