import React, { FC } from 'react'
import styled from '@emotion/styled'

import Icon from 'components/Icon'

type ChooseCardType = {
  onClick: Function
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 284px;
  height: 300px;
  padding: 1rem;
  border: 2px solid var(--agt-secondary-text-color);
  border-radius: 0.5rem;
  overflow: hidden;
  color: var(--agt-secondary-text-color);
  font-weight: bold;
  cursor: pointer;
`
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 120px;
  margin-bottom: 0.5rem;
  border-radius: 50%;
  background-color: var(--agt-background-color-2);

  svg {
    width: 12px;
    height: 12px;
    margin-top: 12px;
    transform-origin: center;
    transform: rotate(45deg);
  }

  path {
    fill: var(--agt-secondary-text-color);
  }
`

const ChooseCard: FC<ChooseCardType> = ({ onClick = () => {} }) => {
  return (
    <Container onClick={() => onClick()}>
      <IconContainer>
        <Icon name="cross" />
      </IconContainer>
      <div>
        เลือกแบบประกัน
        <br />
        เพื่อเปรียบเทียบ
      </div>
    </Container>
  )
}

export default ChooseCard
