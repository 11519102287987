import React, { useEffect, useMemo } from 'react'
import { ErrorMessage, Field, Formik, useFormikContext } from 'formik'
import { PrefixComponent } from 'pages/contacts/components/ContactForm'
import { getOptions, updateShortContact } from 'store/contactSlice'
import subDays from 'date-fns/subDays'
import get from 'lodash/get'
import { filterObjByArray } from 'utils/helper'
import { has } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import {
  FormContact,
  SubmitButton,
  StyledGender,
  DatePickerField,
  Error,
} from './ContactOppModal.styles'

export const today = new Date()
export const yesterday = subDays(today, 1)

export const validator = (message) => (value) => {
  let error
  if (!value) {
    error = message || 'กรุณาระบุ'
  }
  return error
}

export const ContactForm = (props) => {
  const { values, isSubmiting = false, forOpps = false } = props
  const dispatch = useDispatch()
  const options = useSelector((state: RootState) => state.contacts.options)
  const { setFieldValue } = useFormikContext()
  useEffect(() => {
    dispatch(getOptions())
  }, [dispatch])
  return (
    <FormContact onSubmit={props.handleSubmit}>
      <div className="groupField">
        <div className="groupRow">
          <div>คำนำหน้าชื่อ</div>
          <PrefixComponent name="prefixName" options={options.prefixNameOptions} />
          <Field hidden name="prefixName" validate={validator('กรุณาระบุคำนำหน้าชื่อ')} />
          <ErrorMessage component={Error} name="prefixName" />
        </div>
        <div className="groupRow">
          <div>ชื่อจริง</div>
          <Field
            name="firstName"
            className="inputField"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุชื่อ')}
          />
          <ErrorMessage component={Error} name="firstName" />
        </div>
        <div className="groupRow">
          <div>นามสกุล</div>
          <Field
            name="lastName"
            className="inputField"
            placeholder="โปรดระบุ"
            validate={validator('กรุณาระบุนามสกุล')}
          />
          <ErrorMessage component={Error} name="lastName" />
        </div>
      </div>
      <div className="groupField">
        <div className="groupRow">
          <div>เพศ</div>
          <StyledGender name="gender" />
          <Field hidden name="gender" validate={validator('กรุณาระบุเพศ')} />
          <ErrorMessage component={Error} name="gender" />
        </div>
        <div className="groupRow">
          <div>วันเกิด</div>
          <DatePickerField
            value={values.birthdate || ''}
            maxDate={yesterday}
            showSelector
            onChange={(value) => setFieldValue('birthdate', value)}
            popperPlacement="auto"
          />
          <Field hidden name="birthdate" validate={validator('กรุณาระบุวันเกิด')} />
          <ErrorMessage component={Error} name="birthdate" />
        </div>
      </div>
      <SubmitButton type="submit" disabled={isSubmiting}>
        {isSubmiting ? 'กำลังดำเนินการ' : forOpps ? 'บันทึกและถัดไป' : 'บันทึก'}
      </SubmitButton>
    </FormContact>
  )
}

export const NewContact = (props) => {
  const dispatch = useDispatch()
  const { contactData = {}, forOpps = false, isOpps, isClose, getContactByIdAgain } = props
  const id: string = useMemo(() => get(contactData, 'id', ''), [contactData])
  const contactForUpdate = useMemo(
    () =>
      filterObjByArray(contactData, [
        'id',
        'firstName',
        'lastName',
        'gender',
        'birthdate',
        'prefixName',
        'nickname',
        'phoneNumber',
      ]),
    [contactData],
  )
  return (
    <Formik
      initialValues={contactForUpdate}
      enableReinitialize
      onSubmit={(values) => {
        const modifiedValues = {
          ...values,
          birthdate: has(values, 'birthdate') ? get(values, 'birthdate').toString() : null,
        }
        if (forOpps) {
          dispatch(updateShortContact(id, modifiedValues))
          setTimeout(() => {
            getContactByIdAgain()
            isClose()
          }, 1500)
          isOpps()
        } else {
          dispatch(updateShortContact(id, modifiedValues))
          setTimeout(() => {
            document.location.reload()
          }, 1500)
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <ContactForm
          handleSubmit={handleSubmit}
          values={contactForUpdate}
          isSubmiting={isSubmitting}
          forOpps={forOpps}
        />
      )}
    </Formik>
  )
}
