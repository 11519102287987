import React, { FC, useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { FormValue } from './Form.styles'
import { formatTravelType, diffTravelDate } from './Form.utils'
import moment from 'moment'
import { DropleadProps } from '../index.type'
import {
  CustomAddressComponent,
  SamePerson,
  OptionsComponent,
  InputComponent,
  DatePicker,
  BeneficiaryType,
  PolicyType,
  InputFlightComponent,
  InputEngNameComponent,
} from './Form.components'
import { isObject } from 'lodash'

const DropleadForm: FC<DropleadProps> = (props) => {
  const { values, handleSubmit, options, optionsByTypeTa, onSaving } = props
  const { setFieldValue } = useFormikContext()

  useEffect(
    () => {
      if (
        [
          'ที่อยู่ตามทะเบียนบ้าน',
          'ที่อยู่ปัจจุบัน',
          'ที่อยู่ที่ทำงาน',
          'ที่อยู่ตามบัตรประชาชน',
        ].includes(values?.order?.taDetails?.policyAddress?.aaaaaa || '')
      ) {
        let anotherKeyAddress = (options?.addressOptions || []).find(
          (e) => e.label === values?.order?.taDetails?.policyAddress?.aaaaaa,
        )?.value
        if (anotherKeyAddress && isObject(anotherKeyAddress))
          setFieldValue('order.taDetails.policyAddress', {
            ...values?.order?.taDetails?.policyAddress,
            ...anotherKeyAddress,
            housingNo: anotherKeyAddress?.housingNo || '',
            villageNo: anotherKeyAddress?.villageNo || '',
            building: anotherKeyAddress?.building || '',
            road: anotherKeyAddress?.road || '',
            lane: anotherKeyAddress?.lane || '',
          })
      }
    }, // eslint-disable-next-line
    [values?.order?.taDetails?.policyAddress?.aaaaaa],
  )

  useEffect(
    () => {
      let isSameOwner = values?.order?.taDetails?.orderPolicyBeneficiary[0]?.isSameOwner || false
      let travelTypeCountry = values?.order?.taDetails?.travelTypeCountry || ''
      if (isSameOwner && options?.contact?.id) {
        if (travelTypeCountry === 'IN') {
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].prefixName',
            options.contact?.prefixName,
          )
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].firstName',
            options.contact?.firstName,
          )
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].lastName',
            options.contact?.lastName,
          )
        } else if (travelTypeCountry === 'OUT') {
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].prefixName',
            options.contact?.prefixNameEn,
          )
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].firstName',
            options.contact?.firstNameEn,
          )
          setFieldValue(
            'order.taDetails.orderPolicyBeneficiary[0].lastName',
            options.contact?.lastNameEn,
          )
        }
        setFieldValue(
          'order.taDetails.orderPolicyBeneficiary[0].birthdate',
          options.contact?.birthdate,
        )
        setFieldValue('order.taDetails.orderPolicyBeneficiary[0].idCard', options.contact?.idCard)
        setFieldValue('order.taDetails.orderPolicyBeneficiary[0].email', options.contact?.email)
        setFieldValue(
          'order.taDetails.orderPolicyBeneficiary[0].phoneNumber',
          options.contact?.phoneNumber,
        )
      }
    },
    // eslint-disable-next-line
    [
      // eslint-disable-next-line
      values?.order?.taDetails?.orderPolicyBeneficiary[0]?.isSameOwner,
      // eslint-disable-next-line
      values?.order?.taDetails?.travelTypeCountry,
    ],
  )

  return (
    <FormValue onSubmit={handleSubmit}>
      <b className="header">กรอกข้อมูลผู้เอาประกันภัย</b>
      {values.order.taDetails.travelTypeCountry === 'OUT' && (
        <div className="detail">
          <b>รายละเอียดการเดินทาง</b>
          <label>การเดินทาง : ต่างประเทศ</label>
          <label>
            ประเทศปลายทาง :{' '}
            {(optionsByTypeTa?.taCountries || []).find(
              (e) => e.value.toString() === values.order.taDetails.countryId.toString(),
            )?.label || '-'}
          </label>
          <label>จำนวนผู้เดินทาง : {values.order.taDetails.travelPassenger}</label>
          <label>ประกันภัยท่องเที่ยว : {formatTravelType(values.order.taDetails.travelType)}</label>
          <label>
            วันที่ออกเดินทาง : {moment(values.order.taDetails.travelDateFrom).format('DD/MM/YYYY')}
          </label>
          <label>
            วันที่กลับถึงไทย : {moment(values.order.taDetails.travelDateTo).format('DD/MM/YYYY')} (
            {diffTravelDate(
              moment(values.order.taDetails.travelDateFrom),
              moment(values.order.taDetails.travelDateTo),
            )}{' '}
            วัน)
          </label>
        </div>
      )}
      {values.order.taDetails.travelTypeCountry === 'IN' && (
        <div className="detail">
          <b>รายละเอียดการเดินทาง</b>
          <label className="domestic">การเดินทาง : ในประเทศ</label>
          <label>จำนวนผู้เดินทาง : {values.order.taDetails.travelPassenger}</label>
          <label>ประกันภัยท่องเที่ยว : {formatTravelType(values.order.taDetails.travelType)}</label>
          <label>
            จังหวัดต้นทาง :{' '}
            {(options?.provinceOptions || []).find(
              (e) => e.value === values.order.taDetails.travelCountryFrom,
            )?.label || '-'}
          </label>
          <label>
            จังหวัดปลายทาง :{' '}
            {(options?.provinceOptions || []).find(
              (e) => e.value === values.order.taDetails.travelCountryTo,
            )?.label || '-'}
          </label>
          <label>
            วันที่ออกเดินทาง : {moment(values.order.taDetails.travelDateFrom).format('DD/MM/YYYY')}
          </label>
          <label>
            วันที่กลับถึงไทย : {moment(values.order.taDetails.travelDateTo).format('DD/MM/YYYY')} (
            {diffTravelDate(
              moment(values.order.taDetails.travelDateFrom),
              moment(values.order.taDetails.travelDateTo),
            )}{' '}
            วัน)
          </label>
        </div>
      )}
      {values.order.taDetails.orderPolicyBeneficiary.map((d, index) => (
        <div className="assured">
          <b>
            ข้อมูลผู้เดินทาง (ผู้เอาประกัน){' '}
            {values.order.taDetails.orderPolicyBeneficiary.length === 1
              ? ``
              : `ลำดับที่ ${index + 1}`}
          </b>
          {index === 0 && (
            <Field
              label="คุณเป็นบุคคลเดียวกับผู้ซื้อ ใช่หรือไม่"
              name={`order.taDetails.orderPolicyBeneficiary[${index}].isSameOwner`}
              className="xxxx"
              component={SamePerson}
            />
          )}
          <Field
            label="คำนำหน้าชื่อ"
            name={`order.taDetails.orderPolicyBeneficiary[${index}].prefixName`}
            className="selector prefix"
            component={OptionsComponent}
            options={
              values.order.taDetails.travelTypeCountry === 'IN'
                ? options?.prefixNameThOptions
                : values.order.taDetails.travelTypeCountry === 'OUT'
                ? options?.prefixNameEnOptions
                : []
            }
            disabled={
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'IN' &&
                !['', null].includes(options?.contact?.prefixName || '')) ||
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'OUT' &&
                !['', null].includes(options?.contact?.prefixNameEn || ''))
            }
          />
          <Field
            label={`ชื่อจริง ${
              values.order.taDetails.travelTypeCountry === 'IN' ? '(ภาษาไทย)' : '(ภาษาอังกฤษ)'
            }`}
            name={`order.taDetails.orderPolicyBeneficiary[${index}].firstName`}
            className="input firstName"
            component={InputEngNameComponent}
            engName={values.order.taDetails.travelTypeCountry === 'OUT' ? true : false}
            disabled={
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'IN' &&
                !['', null].includes(options?.contact?.firstName || '')) ||
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'OUT' &&
                !['', null].includes(options?.contact?.firstNameEn || ''))
            }
          />
          <Field
            label={`นามสกุล ${
              values.order.taDetails.travelTypeCountry === 'IN' ? '(ภาษาไทย)' : '(ภาษาอังกฤษ)'
            }`}
            name={`order.taDetails.orderPolicyBeneficiary[${index}].lastName`}
            className="input lastName"
            component={InputEngNameComponent}
            engName={values.order.taDetails.travelTypeCountry === 'OUT' ? true : false}
            disabled={
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'IN' &&
                !['', null].includes(options?.contact?.lastName || '')) ||
              (index === 0 &&
                values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
                values.order.taDetails.travelTypeCountry === 'OUT' &&
                !['', null].includes(options?.contact?.lastNameEn || ''))
            }
          />
          <Field
            label="วันเกิด"
            name={`order.taDetails.orderPolicyBeneficiary[${index}].birthdate`}
            className="datepicker birthdate"
            component={DatePicker}
            placeholder="โปรดระบุ"
            disabled={
              index === 0 &&
              values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
              !['', null].includes(options?.contact?.birthdate || '')
            }
          />
          <Field
            label={`เลขบัตรประจำตัวประชาชน`}
            name={`order.taDetails.orderPolicyBeneficiary[${index}].idCard`}
            className="input idcard"
            component={InputComponent}
            disabled={
              index === 0 &&
              values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
              !['', null].includes(options?.contact?.idCard || '')
            }
          />
          <Field
            label="สัญชาติ"
            name={`order.taDetails.orderPolicyBeneficiary[${index}].nationality`}
            className="selector national"
            component={OptionsComponent}
            options={options?.nationalityOptions || []}
          />
          <Field
            label={`อีเมล`}
            name={`order.taDetails.orderPolicyBeneficiary[${index}].email`}
            className="input email"
            component={InputComponent}
            disabled={
              index === 0 &&
              values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
              !['', null].includes(options?.contact?.email || '')
            }
          />
          <Field
            label={`เบอร์โทรศัพท์`}
            name={`order.taDetails.orderPolicyBeneficiary[${index}].phoneNumber`}
            className="input phoneNumber"
            component={InputComponent}
            disabled={
              index === 0 &&
              values.order.taDetails.orderPolicyBeneficiary[0].isSameOwner &&
              !['', null].includes(options?.contact?.phoneNumber || '')
            }
          />
          <Field
            label="ผู้รับผลประโยชน์ ทายาทโดยธรรม"
            name={`order.taDetails.orderPolicyBeneficiary[${index}].statutoryHeir`}
            className="beneficiary"
            component={BeneficiaryType}
          />
          {values.order.taDetails.orderPolicyBeneficiary[index].statutoryHeir ? null : (
            <>
              <Field
                label="ผู้รับผลประโยชน์"
                name={`order.taDetails.orderPolicyBeneficiary[${index}].beneficiary`}
                className="input whos"
                component={InputComponent}
              />
              <Field
                label="ความสัมพันธ์"
                name={`order.taDetails.orderPolicyBeneficiary[${index}].relations`}
                className="input relationship"
                component={OptionsComponent}
                options={options?.familyRelationshipOptions || []}
              />
            </>
          )}
        </div>
      ))}
      <CustomAddressComponent
        label="ที่อยู่บนกรมธรรม์"
        name="order.taDetails.policyAddress"
        options={(options?.addressOptions || []).filter(
          (options) => options?.label !== 'เหมือนที่อยู่บนกรมธรรม์',
        )}
        address={values?.order?.taDetails?.policyAddress}
      />
      <Field
        label="รูปแบบกรมธรรม์"
        name={`order.taDetails.policyForm`}
        className="policyType"
        component={PolicyType}
      />
      {values.order.taDetails.travelBy === 'plane' && (
        <div className="flight">
          <b>ข้อมูลเที่ยวบิน (กรณีมี)</b>
          <Field
            label="วันที่เดินทางไป"
            name={`order.taDetails.travelDateFrom`}
            className="row"
            component={DatePicker}
            placeholder="โปรดระบุ"
            disabled
          />
          <Field
            label={`หมายเลขเที่ยวบินขาไป`}
            name={`order.taDetails.outboundFlightNumber`}
            className="row"
            component={InputFlightComponent}
          />
          <Field
            label="วันที่เดินทางกลับ"
            name={`order.taDetails.travelDateTo`}
            className="row"
            component={DatePicker}
            placeholder="โปรดระบุ"
            disabled
          />
          <Field
            label={`หมายเลขเที่ยวบินขากลับ`}
            name={`order.taDetails.returnFlightNumber`}
            className="row"
            component={InputFlightComponent}
          />
        </div>
      )}
      <div className="tqm">
        <label>
          ** บริการแจ้งเตือนเที่ยวบินล่าช้า/ยกเลิก สำหรับลูกค้า TQM
          ที่กรอกหมายเลขเที่ยวบินและวันที่เดินทางครบถ้วน และถูกต้อง TQM
          มีบริการแจ้งเตือนให้แก่ท่านก่อนการเดินทางผ่านช่องทาง SMS, แอปพลิเคชัน TQM24 และ Line
          เพื่ออำนวยความสะดวกให้ท่าน
        </label>
        <u>รายละเอียด</u>
      </div>
      <div className="groupBtn">
        <button
          type="button"
          onClick={() => {
            onSaving(values)
          }}
        >
          บันทึก
        </button>
        <button className="secondary" type="submit">
          แจ้งแนะนำ
        </button>
      </div>
    </FormValue>
  )
}

export default DropleadForm
