import React from 'react'
import { SuccessModal, ModalCustomStyle } from './OTPComponent.styles'
import Modal from 'react-modal'
import Icon from 'components/Icon'
import { SuccessModalComponentType } from './OTPComponent.type'

const SuccessModalComponent = (props: SuccessModalComponentType) => {
  const { isModalSuccess = false } = props

  return (
    <Modal
      id="modal"
      style={ModalCustomStyle}
      isOpen={isModalSuccess}
      onConfirm={() => {}}
      onCancel={() => {}}
    >
      <SuccessModal>
        <p>ยืนยันข้อมูลแล้ว</p>
        <Icon name="Correct" />
      </SuccessModal>
    </Modal>
  )
}

export default SuccessModalComponent
