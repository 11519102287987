import React, { FC, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import IframeResizer from 'iframe-resizer-react'
import { useParams } from 'routes'
import Api from 'api'
import authToken from 'utils/session'

import BreadCrumb, { LinkValues } from 'components/BreadCrumb'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 16px 0;
`
const Header = styled.h3`
  margin-bottom: 10px;
`
const StyledIframe = styled(IframeResizer)`
  width: 100%;
  height: 750px;
  border: none;
`

const SearchProducts = async (id, contact_id, setSearchLink) => {
  try {
    const response = await Api.get(`/opportunities/${id}/product/life_insurances/search`, {
      contact_id: contact_id,
    })
    // let p = response.data.data.redirectUrl
    // setSearchLink(
    //   encodeURIComponent(
    //     p.replace('https://backoffice-dev.tqm.tqld.xyz/', 'http://localhost:52701/'),
    //   ),
    // )
    setSearchLink(encodeURIComponent(response.data.data.redirectUrl))
  } catch (err) {
    if (err && err.response) {
      alert(err.response.data.message)
    }
    throw err
  }
}
type SearchType = {
  id: string
  contact_id: string
}
const SearchProduct: FC = (props) => {
  let { id, contact_id } = useParams<SearchType>()
  var iframeRef = useRef<any>(null)
  const [searchLink, setSearchLink] = useState<string>('')
  const [tqmLink, setTQMLink] = useState<string>('')

  useEffect(() => {
    SearchProducts(id, contact_id, setSearchLink)
  }, [id, contact_id])

  useEffect(() => {
    iframeRef.current = document.getElementById('search_iframe')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef.current])

  useEffect(() => {
    if (searchLink) {
      const url = `${
        process.env.REACT_APP_TQM_LIFE
      }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${searchLink}`
      setTQMLink(url)
    }
  }, [searchLink, setTQMLink])

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/opportunities', label: 'โอกาสในการขาย' },
    { label: 'รายละเอียดคำสั่งซื้อ' },
  ]

  return (
    <Container>
      <Header>ค้นหาแบบประกัน</Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot iframe />
      <StyledIframe
        id="search_iframe"
        ref={iframeRef}
        src={tqmLink}
        log
        autoResize
        inPageLinks
        scrolling
      />
    </Container>
  )
}
export default SearchProduct
