import React, { FC } from 'react'
import styled from '@emotion/styled'
import { Formik, Form, Field, ErrorMessage, useField } from 'formik'
import Modal from 'react-modal'
import { useHistory } from 'routes'
import HistoryType from 'types/HistoryType'

import Icon from 'components/Icon'
import OpportunitysForm from '../components/NewForm'

import Api from 'api'
import { go2Top } from 'utils/helper'
import Select from 'react-select'
import SetStateType from 'types/SetStateType'
import { ContactType } from 'types/ContactType'
import { useDispatch, useSelector } from 'react-redux'
import { quickCreateOpportunity } from 'store/opportunitiesSlice'
import { RootState } from 'store/rootReducer'

type QuickOpportunityByContactType = {
  setIsModalOpen: Function
  setReload: Function
  isModalOpen?: boolean
  contactId?: number | null
}
type OpportunityNewType = {
  isModalOpen: boolean
  setModalOpen: Function
  setOpportunities: Function
  pipelineStateId?: number
}
type ChoiceProps = {
  hiddenButton?: boolean
}
type OpportunityValues = {
  name: string
  note: string
  contactId: number | null
  pipelineStateId?: number | null
  saleImmediately?: boolean
}
type OpportunitySummaryType = {
  isModalOpen: boolean
  setModalOpen: SetStateType<boolean>
  contactId: number | string
}

Modal.setAppElement('#root')

const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minWidth: '20rem',
    minHeight: '10rem',
    width: '80%',
    maxWidth: '44rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}
const ModalOpportunities = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minWidth: '20rem',
    minHeight: '10rem',
    width: '350px',
    boxSizing: ' border-box',
    maxWidth: '44rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '8px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}
const Container = styled.div`
  align-items: center;
`
const CustomForm = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`
const HeaderModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding: 24px 36px;
  border-bottom: 1px solid var(--agt-secondary-color-1);
`
const HeaderOpps = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
  padding-top: 34px;
  color: var(--agt-primary-color-1);
`
const CloseOpps = styled.div`
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
  &:hover {
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #393d43;
      }
    }
  }
`
const BodyOpps = styled.div`
  padding: 0 50px;
  text-align: center;
  margin-bottom: 24px;
`
const CloseButton = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        fill: #393d43;
      }
    }
  }
`
const ChoiceButton = styled.div<ChoiceProps>`
  display: ${(props) => `${props.hiddenButton ? 'none' : 'flex'}`};
  width: 100%;
`
const SubmitButton = styled.button`
  width: 50%;
  height: 56px;
  background-color: var(--agt-primary-color-1);
  border-radius: 0;
`
const CancelButton = styled.button`
  width: 50%;
  height: 56px;
  background-color: var(--agt-white-color);
  color: var(--agt-primary-text-color);
  border-top: 1px solid var(--agt-secondary-color-1);
  border-radius: 0;
  :hover {
    background-color: var(--agt-white-color);
    color: var(--agt-primary-text-color);
  }
`
const ErrorStyled = styled.div`
  font-size: 13px;
  color: var(--agt-error-color);
`
const Selector = styled(Select)`
  background: var(--agt-white-color);
  div[class$='ValueContainer'] {
    padding: 2px 16px;
  }
  div[class$='placeholder'] {
    color: var(--agt-secondary-text-color);
  }
`
const customSelectStyles = {
  option: (provided) => ({
    ...provided,
    padding: '10px 16px',
  }),
  menu: () => ({
    border: 'none',
    width: '100%',
  }),
  control: () => ({
    display: 'flex',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    height: '48px',
  }),
  menuList: () => ({
    maxHeight: '300px',
    overflowY: 'auto',
    borderRadius: '4px',
    width: '100%',
    border: '1px solid var(--agt-secondary-color-1)',
    background: 'white',
    position: 'absolute',
    zIndex: '2',
  }),
  singleValue: () => ({
    color: 'var(--agt-primary-color-text)',
  }),
}

function validateTopic(value) {
  let error
  if (!value) {
    error = 'กรุณาระบุหัวข้อ'
  }
  return error
}

const createOpportunity = async (
  newOpportunity: OpportunityValues,
  setOpportunities: Function,
  setModalOpen: Function,
) => {
  try {
    const response = await Api.post('/opportunities/', newOpportunity)
    setOpportunities(response.data.data.pipelineStates)
    setModalOpen(false)
    go2Top()
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

const createOpportunitySummary = async (
  newOpportunity: OpportunityValues,
  history: HistoryType,
) => {
  try {
    await Api.post('/opportunities/', newOpportunity)
    history.push(`/opportunities`)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

const createQuickOpportunity = async (
  newOpportunity: OpportunityValues,
  setReload: Function,
  setModalOpen: Function,
) => {
  try {
    await Api.post('/opportunities/', newOpportunity)
    setModalOpen(false)
    setReload(true)
    // history.push(`/opportunities`)
  } catch (err) {
    if (err && err.response) {
      return err.response
    }
    throw err
  }
}

const NewOpportunitySummary = ({ contactId }) => {
  let history: HistoryType = useHistory()
  const defaultProps: OpportunityValues = {
    name: '',
    note: '',
    contactId: contactId,
  }
  return (
    <CustomForm>
      <Formik
        initialValues={defaultProps}
        onSubmit={(values) => {
          createOpportunitySummary(values, history)
        }}
      >
        {(props) => (
          <OpportunitysForm
            handleSubmit={props.handleSubmit}
            isDisabledContact={true}
            isSubmitting={props.isSubmitting}
          />
        )}
      </Formik>
    </CustomForm>
  )
}

const NewOpportunityForm = ({ pipelineStateId, setOpportunities, setModalOpen }) => {
  const defaultProps: OpportunityValues = {
    name: '',
    note: '',
    contactId: null,
    pipelineStateId: pipelineStateId,
  }
  return (
    <CustomForm>
      <Formik
        initialValues={defaultProps}
        onSubmit={(values) => {
          createOpportunity(values, setOpportunities, setModalOpen)
        }}
      >
        {(props) => (
          <OpportunitysForm
            handleSubmit={props.handleSubmit}
            isDisabledContact={false}
            isSubmitting={props.isSubmitting}
          />
        )}
      </Formik>
    </CustomForm>
  )
}

export const OpportunitySummary: FC<OpportunitySummaryType> = (props) => {
  const { isModalOpen, setModalOpen, contactId } = props
  return (
    <Container>
      <Modal style={ModalCustomStyle} id="modal" isOpen={isModalOpen}>
        <HeaderModal>
          <h3>สร้างโอกาสในการขาย</h3>
          <CloseButton onClick={() => setModalOpen(false)}>
            <Icon name="close_button" />
          </CloseButton>
        </HeaderModal>
        <NewOpportunitySummary contactId={contactId} />
      </Modal>
    </Container>
  )
}

const OptionsComponent: FC<{
  name: string
  options: Array<{ label: string; value: string }>
}> = (props) => {
  const { name, options } = props
  const [, meta, helpers] = useField(name)
  const { value }: { value: string } = meta
  const { setValue } = helpers
  const defaultSeletor = options.find(
    (select: { label: string; value: string }) => select.value === value,
  )
  return (
    <div style={{ display: 'flex', flexDirection: 'column', rowGap: 4 }}>
      <label>
        เลือกหมวดประกัน <span style={{ color: 'var(--agt-error-color)' }}>*</span>
      </label>
      <Selector
        value={defaultSeletor}
        onChange={(option: { label: string; value: string }) => {
          const value = option.value
          setValue(value)
        }}
        options={options}
        styles={customSelectStyles}
        placeholder="โปรดระบุ"
      />
    </div>
  )
}

export const OpportunityByContact: FC<{
  hiddenButton?: boolean
  isModalOpen: boolean
  setModalOpen: SetStateType<boolean>
  contactId: number
  contactData?: ContactType | null
}> = (props) => {
  const { hiddenButton = false, isModalOpen, contactId, contactData, setModalOpen } = props
  const history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const { options } = useSelector((state: RootState) => state.contacts)

  const defaultProps: {
    name: string
    type: string
    note: string
    contactId: number
    saleImmediately: boolean
  } = {
    name: 'โอกาสในการขาย',
    type: 'life',
    note: '',
    contactId: contactData?.id || contactId,
    saleImmediately: true,
  }

  return (
    <Container>
      <Modal style={ModalOpportunities} id="modal" isOpen={isModalOpen}>
        <HeaderOpps>
          <h4>สร้างโอกาสในการขาย</h4>
          <CloseOpps onClick={() => setModalOpen(false)}>
            <Icon name="close_button" />
          </CloseOpps>
        </HeaderOpps>
        <BodyOpps>
          จดบันทึกการเสนอขายในครั้งนี้ให้กับ
          <br />
          <span style={{ fontWeight: 'bold', color: 'var(--agt-primary-color-1)' }}>
            {contactData?.name || ''}
          </span>
          <br />
          แล้วไปเลือกดูผลิตภัณฑ์ทันที
        </BodyOpps>
        <ChoiceButton hiddenButton={hiddenButton}>
          <Formik
            initialValues={defaultProps}
            enableReinitialize
            onSubmit={(values: {
              name: string
              type: string
              note: string
              contactId: number
              saleImmediately: boolean
            }) => {
              dispatch(
                quickCreateOpportunity(values, (opportunityId: number) => {
                  if (values.type === 'life') {
                    history.push(`/opportunities/${opportunityId}/search-product/${contactId}`)
                  } else if (values.type === 'motor') {
                    history.push(`/opportunities/${opportunityId}/catalog/motor`)
                  } else {
                    history.push(`/opportunities/${opportunityId}/catalog`)
                  }
                }),
              )
            }}
          >
            {({ handleSubmit }) => (
              <Form
                onSubmit={handleSubmit}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    margin: '0 auto 16px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 24,
                  }}
                >
                  <OptionsComponent
                    name="type"
                    options={
                      options?.insuranceOptions || [
                        { label: 'ประกันชีวิต', value: 'life' },
                        { label: 'ประกันรถยนต์', value: 'motor' },
                      ]
                    }
                  />
                  <div>
                    <Field
                      name="name"
                      type="string"
                      placeholder="ระบุชื่อโอกาสในการขาย"
                      style={{ boxSizing: 'border-box', width: '254px' }}
                      validate={validateTopic}
                    />
                    <ErrorMessage component={ErrorStyled} name="name" />
                  </div>
                </div>
                <div>
                  <SubmitButton type="submit">ยืนยัน</SubmitButton>
                  <CancelButton onClick={() => setModalOpen(false)}>ยกเลิก</CancelButton>
                </div>
              </Form>
            )}
          </Formik>
        </ChoiceButton>
      </Modal>
    </Container>
  )
}

const NewQuickOpportunity = ({
  contactId,
  setModalOpen,
  setReload,
}: {
  contactId?: number | null
  setModalOpen: Function
  setReload: Function
}) => {
  const defaultProps: OpportunityValues = {
    name: '',
    note: '',
    contactId: contactId ? contactId : null,
  }

  return (
    <CustomForm>
      <Formik
        initialValues={defaultProps}
        onSubmit={(values) => {
          createQuickOpportunity(values, setReload, setModalOpen)
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <OpportunitysForm
            handleSubmit={handleSubmit}
            isDisabledContact={true}
            isSubmitting={isSubmitting}
          />
        )}
      </Formik>
    </CustomForm>
  )
}

export const QuickOpportunityByContact: FC<QuickOpportunityByContactType> = ({
  setIsModalOpen,
  setReload,
  isModalOpen,
  contactId,
}) => {
  return (
    <Container>
      <Modal style={ModalCustomStyle} id="modal" isOpen={isModalOpen}>
        <HeaderModal>
          <h3>สร้างโอกาสในการขาย</h3>
          <CloseButton onClick={() => setIsModalOpen(false)}>
            <Icon name="close_button" />
          </CloseButton>
        </HeaderModal>
        <NewQuickOpportunity
          contactId={contactId}
          setModalOpen={setIsModalOpen}
          setReload={setReload}
        />
      </Modal>
    </Container>
  )
}

const OpportunityNew: FC<OpportunityNewType> = ({
  isModalOpen,
  setModalOpen,
  pipelineStateId,
  setOpportunities,
}) => {
  return (
    <Container>
      <Modal style={ModalCustomStyle} id="modal" isOpen={isModalOpen}>
        <HeaderModal>
          <h3>สร้างโอกาสในการขาย</h3>
          <CloseOpps onClick={() => setModalOpen(false)}>
            <Icon name="close_button" />
          </CloseOpps>
        </HeaderModal>
        <NewOpportunityForm
          pipelineStateId={pipelineStateId}
          setOpportunities={setOpportunities}
          setModalOpen={setModalOpen}
        />
      </Modal>
    </Container>
  )
}

export default OpportunityNew
