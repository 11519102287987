import {
  ContactType,
  ContactOptionsType,
  ContactStateType,
  PortOptionsType,
} from 'types/ContactType'
import { initialAddress } from './utils'

export const initialContact: ContactType = {
  id: null,
  age: '',
  avatar: '',
  email: '',
  name: '',
  gender: '',
  nickname: '',
  firstName: '',
  lastName: '',
  birthdate: null,
  maritalStatus: '',
  prefixName: '',
  idCard: '',
  phoneNumber: '',
  occupation: '',
  entertainment: {},
  health: {},
  travel: {},
  food: {},
  position: '',
  company: '',
  facebook: '',
  lineId: '',
  canDeleted: true,
  homeAddress: initialAddress,
  currentAddress: initialAddress,
  officeAddress: initialAddress,
  idCardAddress: initialAddress,
  isSameHomeAddress: false,
  isSameCurrentAddress: false,
  isSameIdCardAddress: false,
  updatedAt: '',
  createdAt: '',
}

export const initialOptions: ContactOptionsType = {
  maritalStatusOptions: [],
  prefixNameOptions: [],
  statusOptions: [],
  contacts: [],
  insuranceOptions: [],
}

export const initialPortOptions: PortOptionsType = {
  maritalStatusOptions: [],
  prefixNameOptions: [],
  insuranceCompanyNameOptions: [],
  insurancePatternOptions: [],
  policyTypeOptions: [],
  insuranceTypeOptions: [],
  stateOptions: [],
  paymentTypeOptions: [],
  payPeriodTypeOptions: [],
  purposeOptions: [],
  riderTypeOptions: [],
  accidentExpenseOptions: [],
  medicalExpenseOptions: [],
  vehicleOptions: [],
}

export const initialState: ContactStateType = {
  initialized: false,
  error: null,
  contacts: [],
  options: initialOptions,
  portOptions: initialPortOptions,
  paging: {
    page: 1,
    perPage: 10,
    totalCount: 1,
    totalPages: 1,
  },
  consentCurrentVersion: null,
  lastConsentHistory: null,
  contact: initialContact,
  consentUrl: '',
  agreement: null,
  haveConsented: false,
}
