import Modal from 'react-modal'
import styled from '@emotion/styled'
import React, { FC } from 'react'
import Icon from './Icon'

const CloseButtonDiv = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  border: none;
  outline: none;
  width: 100%;
  height: 90px;
  padding: 0px;
  //background: #000000;
`
const CloseButton = styled.button`
  background: none;
  height: 100%;
  border: none;
  outline: none;
  align-self: center;
  cursor: pointer;
  padding: 0px;
  svg {
    margin: 0px 0px 0px 0px;
    height: 24px;
    width: 24px;
  }
  &:hover {
    svg {
      path {
        fill: #393d43;
      }
    }
    background: #ffffff;
  }
`

const ModalCustomStyle = {
  content: {
    position: 'fixed',
    top: '50%',
    bottom: 'auto',
    left: '50%',
    right: 'auto',
    minHeight: '10rem',
    border: '1px solid #ccc',
    background: '#ffffff',
    overflow: 'hidden',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px',
    transform: 'translate(-50%,-50%)',
    display: 'flex',
    flexDirection: 'column',
    zIndex: '2',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2',
  },
}

const DetailDiv = styled.div`
  flex: 7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

type ModalProps = {
  isOpen: boolean
  onCancel: Function
  onConfirm: Function
}
const InsuranceModal: FC<ModalProps> = (props) => {
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={props.isOpen}>
      <CloseButtonDiv>
        <CloseButton type="button" onClick={() => props.onCancel()}>
          <Icon name="close_button" />
        </CloseButton>
      </CloseButtonDiv>
      <DetailDiv>{props.children}</DetailDiv>
    </Modal>
  )
}
export default InsuranceModal
