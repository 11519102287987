import React from 'react'

import Button from './PrimaryButton'
import { LinkContainer } from './TextLink'

type LinkProps = {
  id?: string
  label?: string
  color?: string
  hoverColor?: string
  link: string | {}
  className?: string
  disabled?: boolean
}

export default (props: LinkProps) => {
  const { id, label, color, hoverColor, link, className, disabled = false } = props

  return (
    <LinkContainer id={id} to={link} className={className}>
      <Button color={color} hoverColor={hoverColor} label={label} disabled={disabled} />
    </LinkContainer>
  )
}
