import React, { FC } from 'react'
import Modal from 'react-modal'
import Icon from '../Icon'
import { ModalProps } from './ContactModal.type'
import { NewContact } from './ContactOppModal.components'
import { ModalCustomStyle, Header, CloseButton } from './ContactOppModal.styles'

const ContactModal: FC<ModalProps> = (props) => {
  const { isOpen, onCancel, contactData } = props
  return (
    <Modal style={ModalCustomStyle} id="modal" isOpen={isOpen}>
      <Header>
        <p>กรอกข้อมูลเพิ่มเติมเพื่อดำเนินงานต่อ</p>
        <CloseButton onClick={() => onCancel(false)}>
          <Icon name="close_button" />
        </CloseButton>
      </Header>
      <NewContact contactData={contactData} />
    </Modal>
  )
}

export default ContactModal
