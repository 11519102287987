import moment from 'moment'

export const convertBirthdate = (date) => {
  return moment(date).add(543, 'years').format('DD MMMM YYYY')
}

export const convertDate = (date) => {
  return moment(date).add(543, 'years').format('DD/MM/YYYY')
}

export const convertInspectionLocation = (value) => {
  switch (value) {
    case 'policy':
      return 'ใช้ที่อยู่กรมธรรม์'
    case 'policy_delivery':
      return 'ใช้ที่อยู่การจัดส่งกรมธรรม์'
    case 'receipt':
      return 'ใช้ที่อยู่ใบเสร็จรับเงิน'
    default:
      return null
  }
}

export const convertPolicyForm = (value) => {
  switch (value) {
    case 'online':
      return 'กรมธรรม์ออนไลน์'
    case 'original':
      return 'กรมธรรม์ฉบับจริง'
    default:
      return null
  }
}

export const convertValueToLabel = (options, value) => {
  return options.find((option) => option.value === value)?.label
}
