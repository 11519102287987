import styled from '@emotion/styled'
import { Link } from 'routes'
import Icon from 'components/Icon'

export const LinkName = styled(Link)`
  text-decoration: none;
  color: var(--agt-primary-text-color);
  :hover {
    color: var(--agt-primary-text-color);
  }
`

export const LinkOrderNo = styled(Link)`
  font-size: 13px;
  margin-bottom: 12px;
`

export const Card = styled.div`
  width: 544px;
  height: fit-content;
  min-height: 240px;
  height: fit-content;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid var(--agt-secondary-color-1);
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`

export const ContainerTop = styled.div`
  display: flex;
  flex-direction: row;
`

export const ContainerBottom = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 18px;
  padding-right: 18px;
`

export const AvartarContainer = styled.div`
  width: 80px;
  padding: 16px;
`

export const Detial = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const InfoLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
`

export const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
  gap: 12px;
  align-items: flex-end;
`

export const Row = styled.div`
  margin-bottom: 4px;
  &.name {
    max-width: 290px;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  &.status {
    margin-bottom: 0px;
  }
  &.orderNo {
    margin-top: -8px;
    margin-bottom: 0px;
  }
  &.policyType {
    display: flex;
    gap: 8px;
    align-self: flex-end;
    margin-bottom: 0;
    font-weight: bold;
  }
  &.policyType.life {
    color: var(--agt-primary-color-1);
  }
  &.policyType.health {
    color: #45daae;
  }
  &.policyType.car {
    color: #3abaf8;
  }
  &.policyType.ta {
    color: #f6915a;
  }
  &.policyType.home {
    color: #7e5fff;
  }
  &.policyType.accident {
    color: #ffd072;
  }
  > span {
    height: 24px;
  }
`

export const StateComplete = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-white-color);
  background: var(--agt-tqm-pantone-1);
  border-radius: 20px;
  text-align: center;
`

export const StateExpired = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-primary-text-color);
  background: var(--agt-background-color-2);
  border-radius: 20px;
  text-align: center;
`

export const StateRejected = styled.div`
  width: 135px;
  height: 26px;
  font-size: 13px;
  color: var(--agt-white-color);
  background: var(--agt-error-color);
  border-radius: 20px;
  text-align: center;
`

export const InfoLeftBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0 14px 26px;
  width: 60%;
  border-top: 1px solid var(--agt-secondary-color-1);
`

export const InfoRightBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 14px 0;
  width: 40%;
  border-top: 1px solid var(--agt-secondary-color-1);
`

export const IconStyles = styled(Icon)`
  width: 22px;
  height: 22px;
  margin-right: 13px;
`
