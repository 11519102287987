import React, { FC, useEffect, useState, useRef } from 'react'
import styled from '@emotion/styled'
import IframeResizer from 'iframe-resizer-react'
import authToken from 'utils/session'

const Container = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
`
const Header = styled.h2`
  margin-bottom: 37px;
  margin-left: 49px;
`
const StyledIframe = styled(IframeResizer)`
  width: 100%;
  height: 750px;
`

const IframeDashboard: FC = (props) => {
  var iframeRef = useRef<any>(null)
  const [tqmLink, setTQMLink] = useState<string>('')

  useEffect(() => {
    const url = `${
      process.env.REACT_APP_TQM_LIFE
    }auth/agt/${authToken.getAuthTqmLifeToken()}?redirectPath=${
      process.env.REACT_APP_TQM_LIFE
    }dashboard`
    setTQMLink(url)
  }, [])

  useEffect(() => {
    iframeRef.current = document.getElementById('dashboard_iframe')
  }, [iframeRef])

  return (
    <Container>
      <Header>Dashboard</Header>
      <StyledIframe
        id="dashboard_iframe"
        ref={iframeRef}
        src={tqmLink}
        log
        autoResize
        inPageLinks
        scrolling
      />
    </Container>
  )
}

export default IframeDashboard
