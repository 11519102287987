import styled from '@emotion/styled'
import IframeResizer from 'iframe-resizer-react'

export const Container = styled.div`
  width: 100%;
`

export const Header = styled.h3`
  margin: 16px 0 10px;
`

export const Loading = styled.div`
  padding: 40px 20px;
  text-align: center;
`

export const CustomIframe = styled(IframeResizer)`
  width: 100%;
  height: 750px;
  border: none;
`
