import styled from '@emotion/styled'
import Icon from 'components/Icon'
import { StatusUploadType } from './UploadFile.type'

export const StatusUpload = styled.div<StatusUploadType>`
  width: ${(props) => (props.small ? '170px' : '200px')};
  height: 48px;
  font-size: 13px;
  .button {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    padding-left: 12px;
  }
  .blank {
    background-color: var(--agt-background-color-1);
    color: var(--agt-secondary-color-1);
  }
  .waiting {
    background-color: var(--agt-warning-color);
    color: var(--agt-white-color);
  }
  .verified {
    background-color: var(--agt-success-color);
    color: var(--agt-white-color);
  }
  .banned {
    background-color: var(--agt-error-color);
    color: var(--agt-white-color);
  }
  @media (max-width: 1024px) {
    width: ${(props) => (props.small ? '150px' : '160px')};
    small {
      font-size: 10px;
    }
  }
  .file {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 48px;
    border: 1px solid var(--agt-background-color-2);
    border-radius: 5px;
    padding-left: 2px;
    padding-right: 16px;
  }
  .file .type {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 58px;
    height: 42px;
    background: var(--agt-background-color-2);
    border-radius: 4px;
    color: var(--agt-white-color);
  }
  .file .text {
    width: 100%;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .DOC {
    border-color: #cadcff;
    .upload__value-type {
      background-color: #cadcff;
    }
  }
  .PNG {
    border-color: #ffe782;
    .upload__value-type {
      background-color: #ffe782;
    }
  }
  .JPG {
    border-color: var(--agt-success-color);
    .upload__value-type {
      background-color: var(--agt-success-color);
    }
  }
  .PDF {
    border-color: #ffb4be;
    .upload__value-type {
      background-color: #ffb4be;
    }
  }
  .XLX {
    border-color: #a2dfc8;
    .upload__value-type {
      background-color: #a2dfc8;
    }
  }
  .PPT {
    border-color: #ffbe85;
    .upload__value-type {
      background-color: #ffbe85;
    }
  }
`

export const CustomIconSM = styled(Icon)`
  div {
    div {
      width: 16px;
      height: 16px;
      margin-right: 10px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`

export const CustomIconType = styled(Icon)`
  div {
    div {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`
