import React, { useState, useEffect, Fragment } from 'react'
import { Link } from 'routes'
import { get } from 'lodash'
import Consent from './Consent'
import Form from 'components/Form/GuestForm'
import GoogleLogin from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

import { ContainerSignIn, IconSignIn, Header, CustomIcon } from './GuestForm.styles'
import { HeaderComponentType, SignInWithSocialType } from './GuestForm.type'

export const SignInWithSocial = ({
  setIsResp,
  setIsSocial,
  waitForCheckAccount,
}: SignInWithSocialType) => {
  return (
    <ContainerSignIn>
      <div className="suggest">
        <hr className="line" />
        หรือ
        <hr className="line" />
      </div>
      <GoogleLogin
        clientId={`${process.env.REACT_APP_APPID_GOOGLE}`}
        render={(renderProps) => (
          <button className="google secondary" type="button" onClick={renderProps.onClick}>
            <IconSignIn name="google" />
            ลงทะเบียนและผูกบัญชีด้วย Google
          </button>
        )}
        onSuccess={(res) => {
          // duplicateEmail(
          //   {
          //     type: 'google',
          //     email: get(res, 'profileObj.email', ''),
          //     token: get(res, 'tokenId', ''),
          //   },
          //   () => {
          //     setIsResp(res)
          //     setIsSocial(1)
          //   },
          // )
          waitForCheckAccount(
            {
              type: 'google',
              email: get(res, 'profileObj.email', ''),
              token: get(res, 'tokenId', ''),
            },
            () => {
              setIsResp(res)
              setIsSocial(1)
            },
          )
        }}
        onFailure={(err) => console.log('onFailure : ', err)}
      />
      <FacebookLogin
        appId={`${process.env.REACT_APP_APPID_FACEBOOK}`}
        autoLoad={false}
        callback={(res) => {
          if (res.userID) {
            // duplicateEmail(
            //   {
            //     type: 'facebook',
            //     email: get(res, 'email', ''),
            //     token: get(res, 'accessToken', ''),
            //   },
            //   () => {
            //     setIsResp(res)
            //     setIsSocial(2)
            //   },
            // )
            waitForCheckAccount(
              {
                type: 'facebook',
                email: get(res, 'email', ''),
                token: get(res, 'accessToken', ''),
              },
              () => {
                setIsResp(res)
                setIsSocial(2)
              },
            )
          }
        }}
        fields="name, email"
        render={(renderProps) => (
          <button className="facebook secondary" type="button" onClick={renderProps.onClick}>
            <IconSignIn name="facebook-signin" />
            ลงทะเบียนและผูกบัญชีด้วย Facebook
          </button>
        )}
      />
    </ContainerSignIn>
  )
}

export const HeaderComponent = ({ isSocial }: HeaderComponentType) => {
  return (
    <Header>
      {isSocial === 1 ? (
        <div className="headerWithSocial">
          <CustomIcon name="google" />
          <h4>ลงทะเบียนและผูกบัญชีด้วย Google</h4>
        </div>
      ) : isSocial === 2 ? (
        <div className="headerWithSocial">
          <CustomIcon name="facebook-signin" />
          <h4>ลงทะเบียนและผูกบัญชีด้วย Facebook</h4>
        </div>
      ) : (
        <div className="headerRegister">
          <Link to="/login" className="secondary">
            <h4>เข้าสู่ระบบ</h4>
          </Link>
          <div className="center" />
          <h4 className="primary">ลงทะเบียน</h4>
        </div>
      )}
    </Header>
  )
}

export const RegisterForm = (props) => {
  const {
    values,
    touched,
    isValid,
    submitForm,
    setFieldValue,
    isSocial,
    setIsSocial,
    termsAndConditions,
    pdpa,
    waitForCheckAccount,
  } = props
  const [isResp, setIsResp] = useState<object | null>({})
  const [isCheckedTerm, setIsCheckedTerm] = useState<boolean>(false)
  const [isCheckedPDPA, setIsCheckedPDPA] = useState<boolean>(false)

  useEffect(() => {
    if (isSocial === 1) {
      setFieldValue('type', 'google')
      setFieldValue('google', {
        email: get(isResp, 'profileObj.email', ''),
        tokenId: get(isResp, 'tokenId', ''),
      })
      if (values.firstName === '') {
        setFieldValue('firstName', get(isResp, 'profileObj.givenName', ''))
      }
      if (values.lastName === '') {
        setFieldValue('lastName', get(isResp, 'profileObj.familyName', ''))
      }
      if (values.email === '') {
        setFieldValue('email', get(isResp, 'profileObj.email', ''))
      }
    } else if (isSocial === 2) {
      setFieldValue('type', 'facebook')
      setFieldValue('facebook', {
        email: get(isResp, 'email', ''),
        accessToken: get(isResp, 'accessToken', ''),
      })
      if (values.firstName === '') {
        let getKey: string = get(isResp, 'name', '')
        setFieldValue('firstName', getKey.trim().split(' ')[0])
      }
      if (values.lastName === '') {
        let getKey: string = get(isResp, 'name', '')
        setFieldValue('lastName', getKey.trim().split(' ')[1])
      }
      if (values.email === '') {
        setFieldValue('email', get(isResp, 'email', ''))
      }
    } else {
      // ...
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocial])

  return (
    <Fragment>
      <Form />
      <Consent
        consent={termsAndConditions}
        type="terms"
        values={values.terms}
        isChecked={isCheckedTerm}
        setIsChecked={setIsCheckedTerm}
      />
      <Consent
        consent={pdpa}
        type="pdpa"
        values={values.pdpa}
        isChecked={isCheckedPDPA}
        setIsChecked={setIsCheckedPDPA}
      />
      <button
        className="submit"
        type="submit"
        disabled={!isValid || Object.keys(touched).length === 0 || !isCheckedTerm || !isCheckedPDPA}
        onClick={submitForm}
      >
        ยืนยันข้อมูล
      </button>
      {!isSocial && (
        <SignInWithSocial
          isSocial={isSocial}
          setIsResp={setIsResp}
          setIsSocial={setIsSocial}
          waitForCheckAccount={waitForCheckAccount}
        />
      )}
    </Fragment>
  )
}
