import React from 'react'
import styled from '@emotion/styled'
import { Field as FormikField, useField } from 'formik'

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`

const Label = styled.label`
  margin-bottom: 4px;
  color: var(--agt-primary-text-color) !important;
`

const FieldValue = styled(FormikField)`
  width: 100%;
  border: ${(props) =>
    props.isError ? '1px solid var(--agt-error-color)' : '1px solid var(--agt-secondary-color-1)'};
`

const Error = styled.div`
  margin-top: 8px;
`

const Input = (props) => {
  const { field, form, ...rest } = props
  return <input {...field} {...rest} />
}

const Field = (props) => {
  const {
    className = '',
    label = '',
    placeholder = '',
    name = '',
    setwidth = false,
    onChange,
    ...rest
  } = props
  const [field, meta] = useField(name)

  return (
    <FieldContainer className={`${className} ${name} ${meta.touched && meta.error && 'error'}`}>
      <Label>{label}</Label>
      <FieldValue
        name={name}
        placeholder={placeholder}
        style={setwidth ? { width: '194px', height: '47px' } : {}}
        component={Input}
        {...rest}
        onChange={(e) => {
          field.onChange(e)
          if (onChange) {
            onChange(e.target.value)
          }
        }}
      />
      {meta.touched && meta.error && <Error className="small error">{meta.error}</Error>}
    </FieldContainer>
  )
}

const GroupAddress = (props) => {
  const { name, disabled = false } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Field
          name={`${name}.housingNo`}
          label="เลขที่"
          placeholder="โปรดระบุ"
          style={disabled ? { width: '352px' } : { width: '352px' }}
          disabled={disabled}
        />
        <Field
          name={`${name}.villageNo`}
          label="หมู่"
          placeholder="โปรดระบุ"
          style={disabled ? { width: '352px' } : { width: '352px' }}
          disabled={disabled}
        />
      </div>
      <Field
        name={`${name}.building`}
        label="หมู่บ้าน/อาคาร"
        placeholder="โปรดระบุ"
        style={disabled ? {} : {}}
        disabled={disabled}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Field
          name={`${name}.lane`}
          label="ซอย/ตรอก"
          placeholder="โปรดระบุ"
          style={disabled ? { width: '352px' } : { width: '352px' }}
          disabled={disabled}
        />
        <Field
          name={`${name}.road`}
          label="ถนน"
          placeholder="โปรดระบุ"
          style={disabled ? { width: '352px' } : { width: '352px' }}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

export default GroupAddress
