import moment from 'moment'
import querystring from 'querystring'
import addressData from '../components/Address/address.min.json'

export const dayOfWeek = (dateStr) => {
  const dateFormat = moment(dateStr).format('dddd')
  return dateFormat
}

export const getGender = (value) => {
  return value === 'M' ? 'ชาย' : value === 'F' ? 'หญิง' : 'ไม่ระบุ'
}

export const dateFormat = (dateStr) => {
  const dateFormat = moment(dateStr)
  const day = dateFormat.format('DD')
  const month = dateFormat.format('MM')
  const year = parseInt(dateFormat.format('YYYY'), 10) + 543
  const newDate = day + '/' + month + '/' + year
  return newDate
}

export const dateFormatThai = (dateStr) => {
  const dateFormat = moment(dateStr)
  const day = dateFormat.format('D')
  const month = dateFormat.format('MMM')
  const year = parseInt(dateFormat.format('YYYY'), 10) + 543
  const newDate = day + ' ' + month + ' ' + year
  return newDate
}

export const phoneNumberFormat = (phoneNumber) => {
  let newPhoneNumber = phoneNumber
    ? phoneNumber?.slice(0, 3) + '-' + phoneNumber?.slice(3, 6) + '-' + phoneNumber?.slice(6, 10)
    : ''

  return newPhoneNumber
}

export function safeParseFloat(number) {
  number = parseFloat(number)
  if (isNaN(number)) {
    number = 0.0
  }
  return number
}

export function currency(number, precision = 2, prefix = '฿') {
  if (number === null || number === undefined) {
    return ''
  }

  number = safeParseFloat(number)
  precision = parseInt(precision)

  return `${prefix}${number.toLocaleString('th', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  })}`
}

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function isEmail(email) {
  if (!email || typeof email !== 'string') return null
  return emailRegex.test(email)
}

export const phoneNumberRegex = /0[0-9]{2}[0-9]{3}[0-9]{4}/i
export function isPhoneNumber(phoneNumber) {
  if (!phoneNumber) return
  return phoneNumberRegex.test(phoneNumber)
}

export function isValidId(id = '') {
  if (id.length !== 13) return false
  var checksum = id.split('').reduce((mem, char, i) => {
    if (i !== 12) {
      return mem + parseInt(char) * (13 - i)
    } else return mem
  }, 0)
  checksum = (11 - (checksum % 11)) % 10
  return checksum === parseInt(id[12])
}

export const filterObjByArray = (object = {}, filter = []) =>
  Object.keys(object)
    .filter((key) => filter.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
export const removefilterObj = (object = {}, filter = []) =>
  Object.keys(object)
    .filter((key) => !filter.includes(key))
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})

export const getAge = (birthdate) =>
  moment().diff(
    typeof birthdate === 'string' && birthdate ? moment(birthdate, 'DD/MM/BBBB') : birthdate,
    'years',
  )

export const go2Top = () => {
  window && window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const url2Obj = (params) => querystring.parse(params)
export const obj2Url = (params) => querystring.stringify(params)

export function emailCensorer(email, startIndex = 1) {
  if (!email || !isEmail(email)) return null
  const splitedEmail = email.split('@')
  const censored = splitedEmail[0]
    .split('')
    .map((char, index) => (index < startIndex ? char : '*'))
    .join('')
  return `${censored}@${splitedEmail[1]}`
}

export const generateAddressStructure = (addressData) => {
  var obj = []
  addressData.map((province) =>
    province.district.map((district) =>
      district.sub_district.map((subDistrict) =>
        subDistrict.postal.map((postal) => {
          var newObj = {
            value: {
              province: province.province_name_th,
              district: district.district_name_th,
              subDistrict: subDistrict.sub_district_name_th,
              postcode: `${postal.postal_value}`,
            },
            label: `${province.province_name_th} >> ${district.district_name_th} >> ${subDistrict.sub_district_name_th} >> ${postal.postal_value}`,
          }
          obj.push(newObj)
          return null
        }),
      ),
    ),
  )
  return obj
}

export const addressStructure = generateAddressStructure(addressData)

export function passwordValidator(text) {
  const lower = /[a-z]/
  const upper = /[A-Z]/
  const digit = /[0-9]/
  return {
    hasLowercase: lower.test(text),
    hasUppercase: upper.test(text),
    hasDigit: digit.test(text),
  }
}

export function getRelativeDocumentPosition(ele) {
  if (!ele) return null

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = ele.top + scrollTop - clientTop
  const left = ele.left + scrollLeft - clientLeft

  return { top: Math.round(top), left: Math.round(left) }
}

export function getPositionById(id, cb) {
  const ele = document.getElementById(id)
  if (ele) {
    const domRect = ele.getBoundingClientRect()
    const { top, left } = getRelativeDocumentPosition(domRect) || { top: 0, left: 0 }
    cb({ top: top + 'px', left: left + 'px' })
  }
}
