import React, { FC, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'routes'
import { Formik } from 'formik'
import styled from '@emotion/styled'

import { createTask } from 'store/taskSlice'
import { TaskType } from 'types/TaskType'
import HistoryType from 'types/HistoryType'

import TasksForm from 'pages/tasks/components/TaskForm'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import Icon from 'components/Icon'
import SetStateType from 'types/SetStateType'
import WebTour from './WebTour'

const Container = styled.div`
  width: 100%;
  padding: 16px 0;
`
const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  h3 {
    margin-bottom: 10px;
  }
  .hint {
    position: absolute;
    top: 48px;
    right: 0px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    cursor: pointer;
  }
  .hint span {
    color: var(--agt-primary-color-1);
  }
`
const HintIcon = styled(Icon)`
  width: 32px;
  height: 32px;
`

const defaultProps: TaskType = {
  name: '',
  dueDate: '',
  reminderTime: '',
  priorityLevel: 3,
  contactId: null,
  description: '',
  opportunity: null,
}

const HeaderComponent = ({ setIsShowHint }: { setIsShowHint: SetStateType<boolean> }) => {
  return (
    <Header>
      <h3>สร้างงานที่ต้องทำ</h3>
      <div className="hint" onClick={() => setIsShowHint(true)}>
        <HintIcon name="hint" />
        <span>คำแนะนำ</span>
      </div>
    </Header>
  )
}

const NewTasks: FC = (props) => {
  let history: HistoryType = useHistory()
  const dispatch = useDispatch()
  const [isShowHint, setIsShowHint] = useState<boolean>(false)

  const breadCrumbArr: Array<LinkValues> = [
    { path: '/tasks', label: 'งานที่ต้องทำ' },
    { label: 'สร้างงานที่ต้องทำ' },
  ]

  useEffect(() => {
    let storageStr = window.localStorage.getItem('webtour')
    if (storageStr) {
      let storage = JSON.parse(storageStr)
      if (storage['tasks']['create'] === false) {
        window.localStorage.setItem(
          'webtour',
          JSON.stringify({ ...storage, tasks: { ...storage.tasks, create: true } }),
        )
        setTimeout(() => {
          setIsShowHint(true)
        }, 1000)
      }
    } else {
      window.localStorage.setItem(
        'webtour',
        JSON.stringify({
          contacts: { contacts: false, create: false, contactById: false },
          tasks: { tasks: false, create: true },
          calendar: { calendar: false, create: false },
          opportunities: {
            opportunities: false,
            create: false,
            opportunityById: false,
            completed: false,
          },
        }),
      )
      setTimeout(() => {
        setIsShowHint(true)
      }, 1000)
    }
  }, [setIsShowHint])

  return (
    <Container>
      <WebTour visible={isShowHint} setVisible={setIsShowHint} />
      <HeaderComponent setIsShowHint={setIsShowHint} />
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Formik
        initialValues={defaultProps}
        onSubmit={(values: TaskType) => {
          dispatch(createTask(values, () => history.push('/tasks')))
        }}
      >
        {(props) => (
          <TasksForm
            handleSubmit={props.handleSubmit}
            isSubmitting={props.isSubmitting}
            values={props.values}
          />
        )}
      </Formik>
    </Container>
  )
}
export default NewTasks
