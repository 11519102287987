import React, { FC, useEffect, useMemo } from 'react'
import { useParams } from 'routes'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/rootReducer'
import { getContactById } from 'store/contactSlice'
import { Container, Header, AssetForm } from './AddAsset.styles'
import BreadCrumb, { LinkValues } from 'components/BreadCrumb'
import { Formik } from 'formik'
import { ContactType } from 'types/ContactType'
import {
  OptionsComponent,
  Column,
  VehicleChassisComponent,
  RedLicencePlateComponent,
} from './EditAsset.components'

const AddAsset: FC = () => {
  let { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const { contact } = useSelector((state: RootState) => state.contacts)
  const breadCrumbArr: Array<LinkValues> = [
    { path: '/contacts', label: 'รายชื่อลูกค้า' },
    {
      path: `/contacts/${contact?.id}`,
      label: contact?.name || 'รายละเอียดข้อมูลติดต่อ',
    },
    {
      path: `/contacts/${contact?.id}/asset`,
      label: 'สินทรัพย์ส่วนตัว',
    },
    {
      label: 'เพิ่มรถยนต์',
    },
  ]

  useEffect(() => {
    if (id) dispatch(getContactById(id))
  }, [dispatch, id])

  const initialValue: ContactType = useMemo(
    () => ({
      age: contact?.age || '',
      status: contact?.status ? contact?.status : 'รอเสนองาน',
      remark: contact?.remark || '',
      note: contact?.note || '',
      firstName: contact?.firstName || '',
      avatar: contact?.avatar || '',
      lastName: contact?.lastName || '',
      gender: contact?.gender || '',
      nickname: contact?.nickname ? contact.nickname : `${contact?.firstName} ${contact?.lastName}`,
      phoneNumber: contact?.phoneNumber || '',
      email: contact?.email || '',
      name: contact?.name || '',
      birthdate: contact?.birthdate || '',
      maritalStatus: contact?.maritalStatus || '',
      prefixName: contact?.prefixName || '',
      idCard: contact?.idCard || '',
      occupation: contact?.occupation || '',
      position: contact?.position || '',
      company: contact?.company || '',
      facebook: contact?.facebook || '',
      lineId: contact?.lineId || '',
      canDeleted: contact?.canDeleted || true,
      homeAddress: {
        housingNo: contact?.homeAddress?.housingNo || '',
        villageNo: contact?.homeAddress?.villageNo || '',
        building: contact?.homeAddress?.building || '',
        lane: contact?.homeAddress?.lane || '',
        road: contact?.homeAddress?.road || '',
        subDistrict: contact?.homeAddress?.subDistrict || '',
        district: contact?.homeAddress?.district || '',
        province: contact?.homeAddress?.province || '',
        postcode: contact?.homeAddress?.postcode || '',
      },
      currentAddress: {
        housingNo: contact?.currentAddress?.housingNo || '',
        villageNo: contact?.currentAddress?.villageNo || '',
        building: contact?.currentAddress?.building || '',
        lane: contact?.currentAddress?.lane || '',
        road: contact?.currentAddress?.road || '',
        subDistrict: contact?.currentAddress?.subDistrict || '',
        district: contact?.currentAddress?.district || '',
        province: contact?.currentAddress?.province || '',
        postcode: contact?.currentAddress?.postcode || '',
      },
      officeAddress: {
        housingNo: contact?.officeAddress?.housingNo || '',
        villageNo: contact?.officeAddress?.villageNo || '',
        building: contact?.officeAddress?.building || '',
        lane: contact?.officeAddress?.lane || '',
        road: contact?.officeAddress?.road || '',
        subDistrict: contact?.officeAddress?.subDistrict || '',
        district: contact?.officeAddress?.district || '',
        province: contact?.officeAddress?.province || '',
        postcode: contact?.officeAddress?.postcode || '',
      },
      idCardAddress: {
        housingNo: contact?.idCardAddress?.housingNo || '',
        villageNo: contact?.idCardAddress?.villageNo || '',
        building: contact?.idCardAddress?.building || '',
        lane: contact?.idCardAddress?.lane || '',
        road: contact?.idCardAddress?.road || '',
        subDistrict: contact?.idCardAddress?.subDistrict || '',
        district: contact?.idCardAddress?.district || '',
        province: contact?.idCardAddress?.province || '',
        postcode: contact?.idCardAddress?.postcode || '',
      },
      entertainment: contact?.entertainment || {},
      health: contact?.health || {},
      travel: contact?.travel || {},
      food: contact?.food || {},
      isSameHomeAddress: contact?.isSameHomeAddress || false,
      isSameCurrentAddress: contact?.isSameCurrentAddress || false,
      isSameIdCardAddress: contact?.isSameIdCardAddress || false,
      currentAgreement: contact?.currentAgreement || null,
    }),
    [contact],
  )

  return (
    <Container>
      <Header>
        <h3>เพิ่มรถยนต์</h3>
      </Header>
      <BreadCrumb links={breadCrumbArr} hasBackToRoot />
      <Formik
        enableReinitialize
        initialValues={{ ...initialValue, year: 2023, redLicencePlate: true }}
        onSubmit={(values) => {
          console.log('test... ', values)
          // dispatch(
          //   sendNewConsent(values, () => {
          //     setIndexAgreement(indexAgreement + 1)
          //     setIsChecked(false)
          //     if (indexAgreement === currentAgent.newAgreements.length - 1) {
          //       setIsOpen(false)
          //     }
          //   }),
          // )
        }}
      >
        {(props) => {
          return (
            <AssetForm onSubmit={props.handleSubmit}>
              <p>รายละเอียดรถยนต์</p>
              <OptionsComponent
                className="xxx"
                topic={'เลือกจากข้อมูลรถยนต์ของลูกค้า'}
                name={'xxx'}
                options={[
                  { label: '2022', value: 2022 },
                  { label: '2023', value: 2023 },
                ]}
                onChange={(e) => {
                  console.log(e)
                }}
              />
              <div className="Row">
                <Column className="name" topic={'ตั้งชื่อ'} name={'name'} />
                <button type="submit" className="Increase secondary" disabled>
                  เพิ่มข้อมูลประกันที่ถืออยู่
                </button>
              </div>
              <div className="Row">
                <OptionsComponent
                  topic={'ปีรถยนต์'}
                  name={'year'}
                  options={[
                    { label: '2022', value: 2022 },
                    { label: '2023', value: 2023 },
                  ]}
                />
                <OptionsComponent
                  topic={'ยี่ห้อรถยนต์'}
                  name={'vehicleBrand'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
              </div>
              <div className="Row">
                <OptionsComponent
                  topic={'รุ่น'}
                  name={'vehicleModel'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
                <OptionsComponent
                  topic={'รุ่นย่อย'}
                  name={'vehicleSubModel'}
                  options={[
                    { label: 'm', value: 0 },
                    { label: 'k', value: 1 },
                  ]}
                />
              </div>
              <div className="Row">
                <Column
                  topic={'เลขทะเบียนรถยนต์ (ตัวอย่าง 1กก8888)'}
                  name={'vehicleLicense'}
                  star={false}
                />
                <Column topic={'จังหวัดที่จดทะเบียน'} name={'vehicleProvince'} star={false} />
              </div>
              <div className="Row">
                <VehicleChassisComponent topic={'เลขตัวถัง'} name={'vehicleChassis'} star={false} />
                <RedLicencePlateComponent
                  name={'redLicencePlate'}
                  label="เป็นรถป้ายแดง"
                  className="redLabel"
                />
              </div>
              <button type="submit" className="Record">
                บันทึก
              </button>
            </AssetForm>
          )
        }}
      </Formik>
    </Container>
  )
}

export default AddAsset
